import React, { useState, useEffect } from "react";
import {
  createStaffing,
  StaffingRequest,
} from "../../Redux/reducers/StaffingReducer";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showFaliureToast, showSuccessToast } from "../../Utilities";
import { Applogger } from "../../Helpers/Logger";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { getCurrentDateAfterSubMonth } from "../../Helpers/commonFunc";
import moment from "moment";
import AppConstants from "../../Helpers/AppConstants";

export default function Copy({ currentStaff }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.staffing);
  const { token } = useSelector((state) => state.AuthenticationReducer);
  const { lng } = useSelector((state) => state.languageReducer);

  const [staffingId, setStaffingId] = useState(
    currentStaff ?? get(data, "[0].rota_id", "")
  );
  const [copyStaffing, setCopyStaffing] = useState(
    AppConstants.copyStaffingInitialState
  );

  const createNewStaffing = (e) => {
    var body = {
      name: copyStaffing.staffingName,
      start_date: copyStaffing.staffingStartDate,
      rota_id: staffingId,
      duration: copyStaffing.duration,
      created_by: 1,
      token: token,
    };

    if (
      moment(copyStaffing.staffingStartDate).isAfter(
        getCurrentDateAfterSubMonth(1)
      )
    ) {
      dispatch(createStaffing(body))
        .then(unwrapResult)
        .then((res) => {
          Applogger("Response at createStaffing", res);
          showSuccessToast("Schedule created Successfully");
          getStaffingsRequest();
          navigate(
            `${AppConstants.routes.staffingSheet}?schedule=${res.rota_id}`
          );
        })
        .catch((err) => {
          Applogger("Error at createStaffing", err);
          if (get(err, "message", "")) {
            showFaliureToast(get(err, "message", ""));
          } else {
            showFaliureToast(err ?? "Failed To Create Schedule");
          }
        });
    } else {
      showFaliureToast("Start can't be less than 1 month of the current date");
    }
  };

  const getStaffingsRequest = () => {
    dispatch(StaffingRequest({ token, lng }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at StaffingRequest", res);
      })
      .catch((err) => {
        Applogger("Error at StaffingRequest", err);
      });
  };

  const handleSelectedSchedule = (scheduleId) => {
    setStaffingId(scheduleId);
    const localDuration = data.find((item) => item.rota_id == scheduleId);
    setCopyStaffing({
      ...copyStaffing,
      duration: get(localDuration, "duration", 0),
    });
  };

  return (
    <div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-6">
          <p
            className="mt-4"
            style={{ fontWeight: "bold", fontSize: 15, margin: 0 }}
          >
            {t("copyAnExistingStaffing")}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-4">
          {data.length > 0 && (
            <select
              value={staffingId}
              defaultValue={staffingId}
              placeholder={t("selectStaffing")}
              onChange={(e) => {
                handleSelectedSchedule(e.target.value);
              }}
              className="filter-form"
            >
              {data
                .filter((val) => val.is_calender_month == 0)
                .map((value, index) => {
                  return (
                    <option key={index} value={value.rota_id}>{`${moment
                      .unix(value.created_at)
                      .format(
                        // "ddd Qo MMM yyyy"
                        AppConstants.dateFormat
                      )} - ${value.name} `}</option>
                  );
                })}
            </select>
          )}

          <label>{t("scheduleName")}</label>
          <input
            type="text"
            value={copyStaffing.staffingName}
            onChange={(e) =>
              setCopyStaffing({ ...copyStaffing, staffingName: e.target.value })
            }
            // onChange={(e) => setStaffingName(e.target.value)}
            placeholder={t("scheduleName")}
            className="filter-form"
          />
          <label className="fs-6 fw-light">{t("startDate")}</label>
          <br />
          <input
            // onChange={(e) => setStaffingStartDate(e.target.value)}
            onChange={(e) =>
              setCopyStaffing({
                ...copyStaffing,
                staffingStartDate: e.target.value,
              })
            }
            type="date"
            value={copyStaffing.staffingStartDate}
            placeholder={t("selectDate")}
            className="filter-form"
            min={getCurrentDateAfterSubMonth(1)}
            max={AppConstants.maxDate}
          />
        </div>
        <div className="row" style={{ padding: 0, marginTop: 10 }}>
          <div>
            <button
              onClick={(e) => createNewStaffing(e)}
              className="btn btn-primary-cs mt-2"
            >
              {t("submit")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

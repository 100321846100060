import React from "react";

// Note: Component commented for now since we do not need to show languages to the user for now

export default function AppSelector({ className, onChange, value, style }) {
  return (
    <div></div>
    // <select
    //   className={className}
    //   style={style}
    //   onChange={onChange}
    //   value={value}
    // >
    //   <option value={"en"}>English</option>
    //   <option value={"ar"}>العربية</option>
    // </select>
  );
}

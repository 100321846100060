import { useTranslation } from "react-i18next";

function useWorkingStatusList() {
  const { t } = useTranslation();
  const workingStatusList = [
    {
      id: 1,
      title: t("noStatusSet"),
      slug: "any",
    },
    {
      id: 2,
      title: t("working_from_home"),
      slug: "working_from_home",
    },
    {
      id: 3,
      title: t("at_another_site"),
      slug: "at_another_site",
    },
    {
      id: 4,
      title: t("on_client_visit"),
      slug: "on_client_visit",
    },
    {
      id: 5,
      title: t("working_from_usual_location"),
      slug: "working_from_usual_location",
    },
    {
      id: 6,
      title: t("not_working"),
      slug: "not_working",
    },
  ];

  return workingStatusList;
}

export default useWorkingStatusList;

import React from "react";
import { get } from "lodash";

function EmplDropDown({
  title = "",
  value = "",
  onChange = () => {},
  placeholder = "",
  dataArray = [],
  displayKey = "",
  valueKey = "",
  isDisabled = false,
  isRequired = false,
  children = null,
}) {
  return (
    <div className="col-lg-5 col-md-12 col-sm-12">
      <div className="d-flex flex-wrap-sm align-items-center">
        <label className="col-sm-12 col-md-4 mb-0">
          {title}
          {isRequired && <span className="text-danger">*</span>}
        </label>
        <div className="col-md-8 col-sm-12">
          <select onChange={onChange} className={`filter-form`}>
            {children}
            {dataArray.length > 0 &&
              dataArray.map((data, index) => {
                return (
                  <option
                    defaultValue={get(data, valueKey, "")}
                    key={index}
                    value={JSON.stringify(data)}
                  >
                    {get(data, displayKey, "")}
                  </option>
                );
              })}
          </select>
        </div>
      </div>
    </div>
    // <div className="d-flex align-items-center justify-content-between flex-wrap-sm">
    //   <label className="mb-0">
    //     {title}
    //     {isRequired && <span className="text-danger">*</span>}
    //   </label>
    //   <select onChange={onChange} className={`filter-form w-50`}>
    //     {children}
    //     {dataArray.map((data, index) => {
    //       return (
    //         <option
    //           defaultValue={get(data, valueKey, "")}
    //           key={index}
    //           value={JSON.stringify(data)}
    //         >
    //           {get(data, displayKey, "")}
    //         </option>
    //       );
    //     })}
    //   </select>
    // </div>
  );
}

export default EmplDropDown;

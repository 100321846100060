import React, { useState } from "react";
import { MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import { Avatar } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import { Applogger } from "../../Helpers/Logger";
import { unwrapResult } from "@reduxjs/toolkit";
import { deletetUserAbsence } from "../../Redux/reducers/ProfileReducers";
import {
  hexToRGB,
  showFaliureToast,
  showSuccessToast,
} from "../../Utilities/index";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";
import moment from "moment";
import AppConstants from "../../Helpers/AppConstants";
import AlertComp from "../Alert";
import { AppImages } from "../../Helpers/AppImages";
import "./styles.css";

export default function AbsenceDetailModal({
  modalState,
  toggleModal,
  selectedEvent,
  deleteAbsence,
  changeStatusRequest,
  role,
  getAbsence,
  canDelete,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { token, user } = useSelector((state) => state.AuthenticationReducer);

  const { exist: calenderUpdateDeleteLeave } = usePrevilagesExist(
    AppConstants.previlages.calenderUpdateDeleteLeave
  );

  const { exist: ApproveDeclinePendingLeave } = usePrevilagesExist(
    AppConstants.previlages.ApproveDeclinePendingLeave
  );

  const [showAlert, setShowAlert] = useState(false);
  const firstName = get(selectedEvent, "firstName", "");
  const profileImage = get(selectedEvent, "profile_image", null);
  const absenceId = get(selectedEvent, "absence_type_id", "");
  const lastName = get(selectedEvent, "lastName", "");
  const currentLoggedUserId = get(user, "user_id", "");
  const currentSelectedUserId = get(selectedEvent, "user_id", "");
  const absenceType = get(selectedEvent, "absenceType", "");
  const mandatoryName = get(selectedEvent, "name", "");
  const absceneTypeName = get(selectedEvent, "absence_type_name", "");
  const startTime = get(selectedEvent, "start_time", 0);
  const endTime = get(selectedEvent, "end_time", 0);
  const startDate = get(selectedEvent, "startDate", "");
  const endDate = get(selectedEvent, "endDate", "");
  const hoursDuration = get(selectedEvent, "duration_hours", 0);
  const minsDeduration = get(selectedEvent, "duration_mins", 0);
  const startType = get(selectedEvent, "start_type", "");
  const colour = get(selectedEvent, "colour", "");
  const absenceLeaveId = get(selectedEvent, "annual_leave_id", "");
  const endType = get(selectedEvent, "end_type", "");
  const startHalfDayType = get(selectedEvent, "start_half_day_type", "");
  const endHalfDayType = get(selectedEvent, "end_half_day_type", "");
  const notes = get(selectedEvent, "notes", "");
  const user_type = get(selectedEvent, "user_type", "");

  const canCancel = () => {
    if (absenceType == "MandatoryLeave") {
      return false;
    } else if (absenceType == "PendingLeave") {
      if (currentLoggedUserId == currentSelectedUserId) {
        return true;
      } else {
        return false;
      }
    } else if (
      role == AppConstants.roleTypes.Admin &&
      absenceType !== "PendingLeave"
    ) {
      return true;
    } else if (canDelete == 1 && absenceType !== "PendingLeave") {
      return true;
    } else {
      return false;
    }
  };

  const handleEdit = () => {
    // navigate(AppConstants.routes.absence, {
    //   state: { absence_id: absenceLeaveId, page: "calendar" },
    // });

    navigate(
      `${AppConstants.routes.absence}?absence_id=${absenceLeaveId}&page=calendar`
    );
  };

  const deleteAbsenceHandler = () => {
    dispatch(
      deletetUserAbsence({
        annual_leave_id: absenceLeaveId,
        bearer_token: token,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        // if (res.status == 200) {
        // getAbsenceData();
        getAbsence();
        toggleModal();
        setShowAlert(false);
        showSuccessToast("Absence Removed succesfully");
        // } else {
        // }
      })
      .catch((err) => {
        Applogger("Error at deletetUserAbsence", err.message);
        setShowAlert(false);

        showFaliureToast("Something Went Wrong");
      });
  };

  const isLeaveTypeSectionRequired = () => {
    return (
      user_type == "days" &&
      absenceType !== AppConstants.absenceTypesNames.toil &&
      absenceType !== AppConstants.absenceTypesNames.lateness
    );
  };

  return (
    <MDBModal centered isOpen={modalState} toggle={toggleModal}>
      <MDBModalHeader
        // toggle={toggleModal}
        className="bg-sec-cs text-light"
        titleClass="d-flex justify-content-between w-100"
      >
        <h4 className="mb-0 text-white fs-6"> {t("absenceRecord")}</h4>
        <img
          onClick={toggleModal}
          src={AppImages.crossIcon}
          className="close-icon-styl"
        />
      </MDBModalHeader>
      <MDBModalBody
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        }}
      >
        {showAlert && (
          <AlertComp
            message={"Are You Sure?"}
            onAcceptPress={deleteAbsenceHandler}
            onDeclinePress={() => setShowAlert(false)}
          />
        )}
        <div
          style={{
            display: "flex",
            background: hexToRGB(colour),
            padding: 10,
            borderRadius: 10,
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
          }}
        >
          {calenderUpdateDeleteLeave && (
            <div
              className="w-100"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <a className="me-2 a" onClick={handleEdit}>
                <i className="ri-edit-line fs-4 text-primary" />
              </a>

              {canCancel() && (
                <a
                  // onClick={deleteAbsenceHandler}
                  onClick={() => setShowAlert(true)}
                // data-mdb-toggle="modal"
                // data-mdb-target="#abenceWarn"
                // onClick={() => handle_delete(annual_leave_id, absence_type)}
                >
                  <i className="ri-delete-bin-line fs-4 text-primary" />
                </a>
              )}
            </div>
          )}

          {profileImage == null ? (
            <Avatar size={80} style={{ background: "#00A6F1" }}>
              <b style={{ textTransform: "uppercase" }}>{`${firstName.slice(
                0,
                1
              )}${lastName.slice(0, 1)}`}</b>
            </Avatar>
          ) : (
            <Avatar size={120} src={profileImage} />
          )}
          <h6
            style={{ fontWeight: "bold" }}
            className="text-primary bold fw-light capitalize  mt-2"
          >
            {firstName + " " + lastName}
          </h6>
          {absenceType == "PendingLeave" && (
            <span className="d-flex align-items-center">
              <h6
                className="font-bold"
                style={{ color: "#A4907C", fontWeight: "bold" }}
              >
                {"Pending Leave"}
              </h6>
            </span>
          )}
          {absceneTypeName &&
            absceneTypeName !== AppConstants.absenceTypesNames.mandatoryLeave ? (
            <span className="d-flex align-items-center">
              <h6
                className="font-bold"
                style={{ color: colour, fontWeight: "bold" }}
              >
                {absceneTypeName}
              </h6>
            </span>
          ) : (
            <span className="d-flex align-items-center">
              <h6
                className="font-bold text-capitalize"
                style={{ color: colour, fontWeight: "bold" }}
              >
                {mandatoryName}
              </h6>
            </span>
          )}
          {notes && (
            <h6
              style={{ fontWeight: "bold" }}
              className="fst-normal text-black-50 text-capitalize "
            >
              {notes}
            </h6>
          )}
          <small className="mb-0 fw-light text-black-50">
            {moment(startDate).format(AppConstants.dateFormat)} -{" "}
            {moment(endDate).format(AppConstants.dateFormat)}
          </small>
          {startTime > 0 && (
            <h6 style={{ fontWeight: "bold" }} className="mt-2 mb-2">{`${startTime > 0
              ? moment.unix(startTime).zone(0).format(AppConstants.timeFormat)
              : ""
              } - ${endTime > 0
                ? moment.unix(endTime).zone(0).format(AppConstants.timeFormat)
                : ""
              }`}</h6>
          )}
          {hoursDuration > 0
            ? `${hoursDuration} hrs ${minsDeduration} mins`
            : ""}
          {isLeaveTypeSectionRequired() && (
            <div style={{ textAlign: "center" }} className=" w-100 mt-2">
              <p style={{ fontWeight: "bold" }}>
                {startType !== "" &&
                  ` ${startType}${startType !== "Full Day" ? `(${startHalfDayType})` : ""
                  }`}
                {endType !== ""
                  ? ` - ${endType}${endType !== "Full Day" ? `(${endHalfDayType})` : ""
                  }`
                  : ""}
              </p>
            </div>
          )}
          {absenceType == "PendingLeave" &&
            ApproveDeclinePendingLeave &&
            currentLoggedUserId != currentSelectedUserId && (
              <div>
                <button
                  onClick={() =>
                    changeStatusRequest({
                      status: "approved",
                      id: absenceLeaveId,
                    })
                  }
                  className="btn btn-primary-outline-cs mt-2"
                >
                  Approve
                </button>
                <button
                  onClick={() =>
                    changeStatusRequest({
                      status: "declined",
                      id: absenceLeaveId,
                    })
                  }
                  className="btn btn-primary-outline-cs mt-2 ml-2"
                >
                  Decline
                </button>
              </div>
            )}
        </div>
      </MDBModalBody>
    </MDBModal>
  );
}

export const validatePassword = (password) => {
  const regex =
    /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?.,./{}|\":<>\[\]\\\' ~_]).{8,}/;
  return regex.test(password);
};

export const validateEmail = (email) => {
  const regex = /^[^\s]+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return regex.test(email);
};

export const getAlphaNumericString = (string) => {
  return string.toLowerCase().replace(/[" ",_-]/g, "");
};

import React, { useState, useEffect } from "react";
import { Modal, Col, Row, Button, DatePicker, Space } from "antd";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { ClockCell } from "./HistoryPanel";
import { Applogger } from "../../Helpers/Logger";
import AppConstants from "../../Helpers/AppConstants";

export default function EditClockOutModal({
  isVisible,
  clockItem,
  onConfirm,
  onCancel,
}) {
  const { t } = useTranslation();

  const [clockOutTime, setClockOutTime] = useState(null);
  const [reason, setReason] = useState(null);

  useEffect(() => {
    setClockOutTime(null);
    setReason(null);
  }, [isVisible]);

  return (
    <Modal
      title={`Edit Clockout Time`}
      onCancel={onCancel}
      visible={isVisible}
      onConfirm={() => onConfirm(clockOutTime, reason)}
      footer={
        <div>
          <Button onClick={onCancel}>{t("cancel")}</Button>
          <Button
            onClick={() => onConfirm(clockOutTime, reason)}
            type="primary"
            disabled={!clockOutTime || !reason}
          >
            {t("save")}
          </Button>
        </div>
      }
    >
      <Row gutter={16}>
        <Col span={12}>
          <ClockCell
            title={t("clockIn")}
            time={get(clockItem, "clock_in", "")}
            date={get(clockItem, "clock_in_date", "")}
          />
        </Col>
        <Col span={12}>
          <ClockCell
            title={t("clockOut")}
            time={
              clockOutTime
                ? Math.floor(new Date(clockOutTime).getTime() / 1000)
                : null
            }
            date={
              clockOutTime
                ? Math.floor(new Date(clockOutTime).getTime() / 1000)
                : null
            }
          />
        </Col>
      </Row>
      <Space />
      <DatePicker
        className="w-100"
        showTime={true}
        placeholder="Select Clock out Date and Time"
        format={`${AppConstants.defaultDateFormat} | hh:mm`}
        onChange={(value, dateString) => {
          setClockOutTime(value);
        }}
        value={clockOutTime}
      />
      <div className="row mt-4">
        <div className="col-12 col-sm-12 p-0">
          <h6>
            {t("reason")}
            <span className="text-danger">*</span>
          </h6>
        </div>
        <div className="col-12 col-sm-12 p-0">
          <textarea
            value={reason}
            onChange={(e) => {
              setReason(e.target.value);
            }}
            className="filter_textarea fw-normal"
          />
        </div>
      </div>
    </Modal>
  );
}

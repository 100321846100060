import { useEffect, useState } from "react";
import { employeesRequest } from "../Redux/reducers/EmployeesReducer";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Applogger } from "./Logger";

export default function UseUsers({ refresh = false }) {
  const dispatch = useDispatch();

  const { token } = useSelector((state) => state.AuthenticationReducer);
  const { allEmployees } = useSelector((state) => state.employees);
  const { lng } = useSelector((state) => state.languageReducer);

  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    getAllEmployess();
  }, []);

  useEffect(() => {
    if (Array.isArray(allEmployees)) {
      setAllUsers(allEmployees);
    }
  }, [allEmployees]);

  useEffect(() => {
    if (refresh) {
      getAllEmployess();
    }
  }, [refresh]);

  const getAllEmployess = () => {
    dispatch(employeesRequest({ token, lng }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Employees received successfully", res);
      })
      .catch((err) => {
        Applogger("Found error while fetching employees", err);
      });
  };
  return { allUsers };
}

import React, { useState, useRef, useEffect } from "react";
import { Modal, Button, Divider, Input, Select, Radio } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Applogger } from "../../Helpers/Logger";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { unwrapResult } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { showFaliureToast, showSuccessToast } from "../../Utilities";
import {
  addRoleRequest,
  deleteRoleRequest,
  getAllRolesRequest,
  updateRoleRequest,
} from "../../Redux/reducers/SettingsReducer";
import Spinner from "../Spinner";
import AppConstants from "../../Helpers/AppConstants";

export default function RoleModal({
  isModalVisible,
  handleOk,
  handleCancel,
  title,
  companyId,
  roleSetting,
  updateRole,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { Option } = Select;

  const [editMode, setEditMode] = useState(false);
  const [disableRole, setDisableRole] = useState(false);
  const [currentRoleId, setCurrentRoleId] = useState("");
  const [roleFormBody, setRoleFormBody] = useState({
    role_id: "",
    approver_id: "",
    location_type_id: "",
    line_manager_id: "",
    annual_leaves_allowance_days: "",
    annual_leaves_allowance_hours: "",
    annual_leaves_allowance_mins: "",
  });

  const [currentUserType, setCurrentUserType] = useState(
    AppConstants.userRoleTypes.daily
  );

  const [localRoleType, setLocalRoleType] = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState(null);

  const { token } = useSelector((state) => state.AuthenticationReducer);
  const { roles, placesOfWork, loading } = useSelector(
    (state) => state.settings
  );

  const inputRef = useRef(null);

  useEffect(() => {
    if (!editMode) {
      setRoleFormBody({
        role_id: "",

        approver_id: "",
        location_type_id: "",
        line_manager_id: "",
        annual_leaves_allowance_days: 0,
        annual_leaves_allowance_hours: 0,
        annual_leaves_allowance_mins: 0,
      });
    }
    setLocalRoleType("");
  }, [isModalVisible, editMode]);

  useEffect(() => {
    if (!editMode) {
      if (Array.isArray(roles) && roles.length > 0) {
        setRoleFormBody({
          ...roleFormBody,
          role_id: roles[roles.length - 1].role_id,
        });
      }
    }
  }, [roles]);

  useEffect(() => {
    if (roleSetting) {
      setEditMode(true);
      // roleFormBody.annual_leaves_allowance_days = roleSetting.
      roleFormBody.annual_leaves_allowance_days =
        roleSetting.annual_leaves_allowance_days;
      roleFormBody.annual_leaves_allowance_hours =
        roleSetting.annual_leaves_allowance_hours;
      roleFormBody.annual_leaves_allowance_mins =
        roleSetting.annual_leaves_allowance_mins;
      roleFormBody.role_id = roleSetting.role_id;
      roleFormBody.approver_id = roleSetting.approver_id ?? "";
      roleFormBody.location_type_id = roleSetting.location_type_id;
      roleFormBody.line_manager_id = roleSetting.line_manager_id ?? "";

      setCurrentUserType(roleSetting.role.role_type);
      setRoleFormBody({ ...roleFormBody });
      setCurrentRoleId(roleSetting.id);
    } else {
      setEditMode(false);
    }
  }, [roleSetting]);

  const onNameChange = (event) => {
    setLocalRoleType(event.target.value);
  };

  const getAllRolesRequestHandler = () => {
    dispatch(getAllRolesRequest({ token, companyId }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Resposne at getAllRolesRequest", res);
      })
      .catch((err) => {
        Applogger("Error at getAllRolesRequest", err);
      });
  };

  const addRoleRequestHandler = () => {
    if (!localRoleType) {
      showFaliureToast("Please enter name");
      return;
    }
    const currentRoleIndex = roles.findIndex(
      (role) => role.name == localRoleType
    );

    if (currentRoleIndex !== -1) {
      showFaliureToast("Role Already Exist");
      return;
    }
    dispatch(addRoleRequest({ token, roleType: localRoleType, companyId }))
      .then(unwrapResult)
      .then((res) => {
        getAllRolesRequestHandler();
        setLocalRoleType("");
        showSuccessToast(res?.data, "Role Successfully Created");
        Applogger("Response at addRoleRequest", res);
      })
      .catch((err) => {
        showFaliureToast(err ?? "Unable to add Role, Please check name again");
        Applogger("Error at addRoleRequest", err);
      });
  };
  const onOKPressHandler = () => {
    if (editMode) {
      updateRole(roleFormBody, currentRoleId, currentUserType);
    } else {
      handleOk(roleFormBody, currentUserType);
    }
  };

  const handleDeleteRole = (roleId) => {
    dispatch(deleteRoleRequest({ token, roleId }))
      .then(unwrapResult)
      .then((res) => {
        getAllRolesRequestHandler();
        showSuccessToast("Role deleted successfully");
        Applogger("Response at deleteRoleRequest", res);
      })
      .catch((err) => {
        showFaliureToast(err ?? "Unable to delete the Role");
        Applogger("Error at deleteRoleRequest", err);
      });
  };

  const handleUpdateRole = () => {
    dispatch(
      updateRoleRequest({
        token,
        roleId: selectedRoleId,
        roleName: localRoleType,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        getAllRolesRequestHandler();
        setSelectedRoleId(null);
        setLocalRoleType("");
        showSuccessToast(res?.data ?? "Role updated successfully");
        Applogger("Response at updateRoleRequest", res);
      })
      .catch((err) => {
        showFaliureToast(err ?? "Unable to update the Role");
        Applogger("Error at updateRoleRequest", err);
      });
  };

  const onClickRoleUpdate = (e, item) => {
    e.stopPropagation();
    setSelectedRoleId(item.role_id);
    setLocalRoleType(item.name);
  };

  const handleUpdateClear = () => {
    setSelectedRoleId(null);
    setLocalRoleType("");
  };

  const re = /^[0-9]*\.?[0-9]*$/;
  const setAllownceDays = (value) => {
    if (re.test(value)) {
      let finalValue = "";
      if (value.includes(".")) {
        const splitedValue = value.split(".");
        const decimalValue = Number(
          get(splitedValue, `[${splitedValue.length - 1}]`, "")
        );
        if (decimalValue) {
          if (decimalValue == 5) {
            finalValue = value;
          } else if (decimalValue > 5) {
            finalValue = Math.ceil(value);
          } else if (decimalValue < 5) {
            finalValue = Math.floor(value);
          } else {
            finalValue = value;
          }
        } else {
          finalValue = value;
        }
      } else {
        finalValue = value;
      }
      setRoleFormBody({
        ...roleFormBody,
        annual_leaves_allowance_days: finalValue,
      });
    }
  };

  const handleRoleChange = (value) => {
    setRoleFormBody({ ...roleFormBody, role_id: value });
    const roleObj = roles.find((item) => item.role_id == value);

    if (get(roleObj, "role_type", null)) {
      setCurrentUserType(get(roleObj, "role_type", ""));
      setDisableRole(true);
    } else {
      setDisableRole(false);
    }
  };

  const handleCloseRoleModal = () => {
    handleCancel();
    setDisableRole(false);
  };

  return (
    <Modal
      visible={isModalVisible}
      onOk={onOKPressHandler}
      onCancel={handleCloseRoleModal}
      width={680}
      destroyOnClose
      // footer={null}
      title={title}
    >
      {loading == "pending" && <Spinner />}
      <div className="row">
        <div className="col-12 col-md-6 col-sm-12 ">
          <div className="center">
            <label>{t("role")}</label>
          </div>
        </div>
        <div className="col-12 col-md-6 col-sm-12 ">
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            disabled={editMode}
            style={{ width: "100%" }}
            className="filter-form-dropdown text-capitalize"
            value={roleFormBody.role_id}
            onChange={
              (e) => handleRoleChange(e) // setRoleFormBody({ ...roleFormBody, role_id: e })
            }
            optionLabelProp="label"
            dropdownRender={(menu) => (
              <div>
                {menu}
                <div>
                  <Divider style={styles.dividerStyl} />
                </div>
                <div style={styles.addRoleConStyl}>
                  <Input
                    placeholder="Enter name"
                    ref={inputRef}
                    value={localRoleType}
                    onChange={onNameChange}
                  />
                </div>
                <div style={styles.addRoleBtnStyl}>
                  <Button
                    style={{
                      width: "100%",
                      marginRight: selectedRoleId ? 2 : 0,
                    }}
                    disabled={!localRoleType}
                    type="primary"
                    onClick={() =>
                      selectedRoleId
                        ? handleUpdateRole()
                        : addRoleRequestHandler()
                    }
                  >
                    {selectedRoleId ? t("updateRole") : t("addRole")}
                  </Button>
                  {selectedRoleId && (
                    <Button
                      style={styles.clearBtn}
                      type="primary"
                      onClick={() => handleUpdateClear()}
                    >
                      {t("clear")}
                    </Button>
                  )}
                </div>
              </div>
            )}
          >
            {roles.map((item) => (
              <Option key={item.role_id} value={item.role_id} label={item.name}>
                <span className="text-capitalize">{item.name}</span>
                <span style={{ float: "right" }}>
                  {item.name != "Owner" && (
                    <>
                      <EditOutlined
                        style={{ marginRight: 12 }}
                        onClick={(e) => {
                          onClickRoleUpdate(e, item);
                        }}
                      />
                      <DeleteOutlined
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteRole(item.role_id);
                        }}
                      />
                    </>
                  )}
                </span>
              </Option>
            ))}
          </Select>
        </div>
      </div>

      <div className="row mt-10">
        <div className="col-12 col-md-6 col-sm-12 ">
          <div className="center">
            <label>{t("locationType")}</label>
          </div>
        </div>
        <div className="col-12 col-md-6 col-sm-12 ">
          <Select
            showSearch
            className="filter-form-dropdown text-capitalize"
            style={{ width: "100%" }}
            value={roleFormBody.location_type_id}
            optionFilterProp="children"
            onChange={(e) =>
              setRoleFormBody({ ...roleFormBody, location_type_id: e })
            }
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={
              Array.isArray(placesOfWork) &&
              placesOfWork
                .filter(
                  (place, index) =>
                    placesOfWork.findIndex(
                      (item) =>
                        get(item, "location_type_name", "") &&
                        get(item, "location_type_name", "") ===
                          get(place, "location_type_name", "")
                    ) == index
                )
                .map((item) => ({
                  label: get(item, "location_type_name", ""),
                  value: get(item, "location_type_id", ""),
                }))
            }
            dropdownStyle={{ textTransform: "capitalize" }}
          />
        </div>
      </div>
      <div className="row mt-10">
        <div className="col-12 col-md-6 col-sm-12 ">
          <div className="center">
            <label>{t("lineManager")}</label>
          </div>
        </div>
        <div className="col-12 col-md-6 col-sm-12 ">
          <Select
            disabled={
              get(roleSetting, "role.name", "") == AppConstants.roleTypes.Owner
            }
            className="filter-form-dropdown text-capitalize"
            value={roleFormBody.line_manager_id}
            style={{ width: "100%" }}
            onChange={(e) =>
              setRoleFormBody({
                ...roleFormBody,
                line_manager_id: e,
                approver_id: e,
              })
            }
            // placeholder="Select or Create Role"
            options={
              Array.isArray(roles) && [
                { label: "No Line Manager", value: "" },
                ...roles.map((item) => ({
                  label: item.name,
                  value: item.role_id,
                })),
              ]
            }
            dropdownStyle={{ textTransform: "capitalize" }}
          />
        </div>
      </div>
      <div className="row mt-10 ">
        <div className="col-12 col-md-6 col-sm-12 ">
          <div className="center">
            <label>{t("userType")}</label>
          </div>
        </div>

        <div className="col-12 col-md-6 col-sm-12 ">
          <Radio.Group
            disabled={editMode || disableRole} // ? true : false}
            style={{ width: "100%" }}
            defaultValue={currentUserType}
            value={currentUserType}
            onChange={(e) => setCurrentUserType(e.target.value)}
            buttonStyle="solid"
          >
            <Radio.Button
              style={{ width: "50%", padding: "0px" }}
              value={AppConstants.userRoleTypes.daily}
            >
              {t("daily")}
            </Radio.Button>
            <Radio.Button
              style={{ width: "50%", padding: "0px" }}
              value={AppConstants.userRoleTypes.hours}
            >
              {t("hourly")}
            </Radio.Button>
          </Radio.Group>
        </div>
      </div>

      {currentUserType == AppConstants.userRoleTypes.daily && (
        <div className="row mt-10 ">
          <div className="col-12 col-md-6 col-sm-12 ">
            <div className="center">
              <label>
                <span>
                  {t("daily")} {t("users")}
                </span>
                <br /> {t("leaveAllowance")} (Days)
              </label>
            </div>
          </div>
          <div className="col-12 col-md-6 col-sm-12 ">
            <Input
              className="filter-fix"
              width={"100%"}
              placeholder="e.g 1, 1.5"
              inputMode="decimal"
              value={roleFormBody.annual_leaves_allowance_days}
              onChange={(e) =>
                setAllownceDays(e.target.value.replace(/^0+/, ""))
              }
            />
            {roleFormBody.annual_leaves_allowance_days ? (
              <span style={{ color: "grey", fontSize: 12 }}>
                {t("allownDesc")}
              </span>
            ) : null}
          </div>
        </div>
      )}
      {currentUserType == AppConstants.userRoleTypes.hours && (
        <React.Fragment>
          <div className="row mt-10 ">
            <div className="col-12 col-md-6 col-sm-12 ">
              <div className="center">
                <label>
                  <span>
                    {t("hourly")} {t("users")}
                  </span>
                  <br /> {t("leaveAllowance")} (HRs)
                </label>
              </div>
            </div>
            <div className="col-12 col-md-6 col-sm-12 ">
              <Input
                className="filter-fix"
                width={"100%"}
                value={roleFormBody.annual_leaves_allowance_hours}
                onChange={(e) =>
                  setRoleFormBody({
                    ...roleFormBody,
                    annual_leaves_allowance_hours: e.target.value.replace(
                      /^0+/,
                      ""
                    ),
                  })
                }
              />
            </div>
          </div>
          <div className="row mt-10 ">
            <div className="col-12 col-md-6 col-sm-12 ">
              <div className="center">
                <label>
                  <span>
                    {t("hourly")} {t("users")}
                  </span>
                  <br /> {t("leaveAllowance")} (Mins)
                </label>
              </div>
            </div>
            <div className="col-12 col-md-6 col-sm-12 ">
              <Input
                className="filter-fix"
                width={"100%"}
                value={roleFormBody.annual_leaves_allowance_mins}
                onChange={(e) =>
                  setRoleFormBody({
                    ...roleFormBody,
                    annual_leaves_allowance_mins: e.target.value.replace(
                      /^0+/,
                      ""
                    ),
                  })
                }
              />
            </div>
          </div>
        </React.Fragment>
      )}
      <div className="row mt-10">
        <div className="col-12 col-md-6 col-sm-12 ">
          <div className="center">
            <label>{t("approver")}</label>
          </div>
        </div>
        <div className="col-12 col-md-6 col-sm-12 ">
          <Select
            disabled={
              get(roleSetting, "role.name", "") == AppConstants.roleTypes.Owner
            }
            className="filter-form-dropdown text-capitalize"
            showSearch
            value={roleFormBody.approver_id}
            onChange={(e) =>
              setRoleFormBody({ ...roleFormBody, approver_id: e })
            }
            style={{
              width: "100%",
            }}
            // placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={
              Array.isArray(roles) && [
                { label: "No Approver", value: "" },
                ...roles.map((val, index) => {
                  return { value: val.role_id, label: val.name };
                }),
              ]
            }
            dropdownStyle={{ textTransform: "capitalize" }}
          />
        </div>
      </div>
    </Modal>
  );
}

export const styles = {
  dividerStyl: { margin: "8px 0" },
  addRoleConStyl: { padding: "0 8px 4px" },
  addRoleBtnStyl: {
    padding: "0 8px 4px",
    display: "flex",
    justifyContent: "space-evenly",
  },
  clearBtn: { width: "100%", marginLeft: 2 },
};

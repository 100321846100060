import { APIConstants } from "../../Config/ApiConstants";
import EventResource from "../../Config/EventResource";
import { Applogger } from "../../Helpers/Logger";
import { get } from "lodash";
import { getHeaders } from "../../Helpers/commonFunc";

function apiGetClockedInAndOutUsersList(payload) {
  return EventResource.get(
    `${APIConstants.timesheet}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

function apiGetUserTimeSheetHistory(payload) {
  const { startDate, endDate } = payload.dateRange;
  return EventResource.get(
    `${APIConstants.timesheetHistory}?lng=${payload.lng}&${
      payload.id ? "user_id=" + payload.id : ""
    }${startDate ? "&from=" + startDate : ""}${
      endDate ? "&to=" + endDate : ""
    }`,
    getHeaders(payload.token)
  );
}

function getUserTimeSheetDetailsRequest(payload) {
  return EventResource.get(
    `${APIConstants.getUserTimeSheetData}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

function toggleBreakRequest(payload) {
  const breakData = new FormData();
  breakData.append("user_id", payload.userId);

  return EventResource.post(
    APIConstants.toggleBreak,
    breakData,
    getHeaders(payload.token)
  );
}

function toggleClockRequest(payload) {
  const clockData = new FormData();
  clockData.append("user_id", payload.userId);
  clockData.append("status", payload.status);
  clockData.append("longitude", get(payload, "longitude", ""));
  clockData.append("latitude", get(payload, "latitude", ""));

  return EventResource.post(
    APIConstants.timesheet,
    clockData,
    getHeaders(payload.token)
  );
}

function apiRequestCustomClockOut(payload) {
  const { body, token } = payload;
  const clockData = new FormData();
  Applogger("Payload at apiRequestCustomClockOut", payload);
  clockData.append("user_id", body.user_id);
  clockData.append("status", body.status);
  clockData.append("clock_out_date", body.clock_out_date);
  clockData.append("clock_out", body.clock_out);
  clockData.append("approval_reason", body.approval_reason);

  return EventResource.post(
    APIConstants.timesheet,
    clockData,
    getHeaders(token)
  );
}

export const TimesheetApiServices = {
  apiGetClockedInAndOutUsersList,
  apiGetUserTimeSheetHistory,
  getUserTimeSheetDetailsRequest,
  toggleBreakRequest,
  toggleClockRequest,
  apiRequestCustomClockOut,
};

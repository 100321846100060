import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Applogger } from "../../../Helpers/Logger";
import { showSuccessToast } from "../../../Utilities";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import {
  getUserProfileEmergencyContacts,
  createEmergencyContact,
  updateEmergencyContact,
  deleteEmergencyContact,
} from "../../../Redux/reducers/ProfileReducers";
import EmergencyForm from "./EmergencyForm";
import usePrevilagesExist from "../../../Helpers/PrivilegesFuncs";
import AppConstants from "../../../Helpers/AppConstants";
import SPProfileButton from "../Components/SPProfileButton";

export default function UserEmergencies() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { lng } = useSelector((state) => state.languageReducer);
  const { countries } = useSelector((state) => state.employees);
  const { token } = useSelector((state) => state.AuthenticationReducer);
  const { emergencyContacts, user } = useSelector((state) => state.profile);

  const { exist: updateContactInfo } = usePrevilagesExist(
    AppConstants.previlages.updateContactInfo
  );

  const [addContactMode, setAddContactMode] = useState(false);
  const [allEmergenciesContacts, setAllEmergenciesContacts] = useState([]);

  const userId = get(user, "user_id", "");
  useEffect(() => {
    if (emergencyContacts) {
      setAllEmergenciesContacts(emergencyContacts);
    }
  }, [emergencyContacts]);

  useEffect(() => {
    getUserEmergencyContacts();
  }, []);

  const getUserEmergencyContacts = () => {
    dispatch(
      getUserProfileEmergencyContacts({
        token: token,
        user_id: userId,
        lng,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getUserProfileEmergencyContacts", res);
      })
      .catch((err) => {
        Applogger("Error at getUserProfileEmergencyContacts", err.message);
      });
  };

  const createEmergencyContacts = (emergencyContactBody) => {
    dispatch(
      createEmergencyContact({
        token,
        emergencyContactBody,
        user_id: userId,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        getUserEmergencyContacts();
        setAddContactMode(false);
        Applogger("Response at createEmergencyContact", res);
        showSuccessToast(
          res?.data ?? "Emergency Contacts Created Successfully "
        );
      })
      .catch((err) => {
        Applogger("Error at createEmergencyContact", err.message);
      });
  };

  const updateEmergencyContactRequest = (emergencyContactBody) => {
    dispatch(updateEmergencyContact({ token, emergencyContactBody }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at updateEmergencyContact", res);
        showSuccessToast(
          res?.data ?? "Emergency Contacts Updated Successfully "
        );
        getUserEmergencyContacts();
      })
      .catch((err) => {
        Applogger("Error at updateEmergencyContact", err.message);
      });
  };

  const deleteEmergencyContactRequsest = (contact_id) => {
    dispatch(deleteEmergencyContact({ token, contact_id }))
      .then(unwrapResult)
      .then((res) => {
        getUserEmergencyContacts();
        showSuccessToast(
          res?.data ?? "Emergency Contacts Deleted Successfully"
        );
        Applogger("Response at deleteEmergencyContact", res);
      })
      .catch((err) => {
        Applogger("Error at deleteEmergencyContact", err.message);
      });
  };

  return (
    <div>
      <p className="ml-2">{t("addEmergencyContact")}</p>
      {!addContactMode && updateContactInfo && (
        <SPProfileButton
          title={t("addNewContact")}
          onClick={() => setAddContactMode(true)}
          disabled={!updateContactInfo}
        />
      )}
      {addContactMode && (
        <EmergencyForm
          canceCreateMode={() => setAddContactMode(false)}
          createContact={createEmergencyContacts}
          editMod
          Status={true}
          Countries={countries}
        />
      )}
      {allEmergenciesContacts.map((contact, index) => {
        const uuid = get(contact, "uuid", "");
        const first_name = get(contact, "first_name", "");
        const id = get(contact, "id", "");
        return (
          <div key={index} className="accordion" id="emergencyformPanel">
            <div className="accordion-item">
              <h2
                className="accordion-header bordered mt-4 text-black-50"
                id={`${id}`}
              >
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-mdb-toggle="collapse"
                  data-mdb-target={`#panelsStayOpen-${uuid}`}
                  aria-expanded="false"
                  aria-controls={`panelsStayOpen-${uuid}`}
                >
                  <div className="emp_head">{first_name}</div>
                </button>
              </h2>
              <div
                id={`panelsStayOpen-${uuid}`}
                className="accordion-collapse collapse "
                aria-labelledby={`${id}`}
              >
                <div className="accordion-body">
                  <EmergencyForm
                    deleteEmergencyContact={deleteEmergencyContactRequsest}
                    updateEmergencyContact={updateEmergencyContactRequest}
                    Countries={countries}
                    Data={contact}
                    editModeStatus={true}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

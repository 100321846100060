import React from "react";
import { AppImages } from "../../Helpers/AppImages";

export default function StaffingCheckboxCard(props) {
  return (
    <div
      onClick={() => props.onChange()}
      key={props.key}
      className="staffingCheckBox col-12 col-sm-12 col-md-4"
    >
      <div
        className={`card text-center sec-cs-border rota-crd position-relative ${
          props.active == true ? "active" : ""
        }`}
      >
        <div className=" position-absolute top-0 right-14 m-2">
          <div className="round">
            <input
              checked={props.active}
              onChange={props.onChange}
              type="checkbox"
              id={props.id}
            />
            <label htmlFor={props.id}></label>
          </div>
        </div>
        <div className="card-body">
          <div className="d-flex flex-column align-items-center">
            <img src={AppImages.def_icon} width={160} />
            <h6>{props.title}</h6>
            <p className="card-text small">{props.subtitle}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import { CalenderApiServices } from "../actions/CalenderActions";
import {
  stopLoaderAndEmptyErrors,
  logoutRequest,
} from "./AuthenticationReducer";

const initialState = {
  error: null,
  loading: "idle",
  absenceLoading: "idle",
  events: [],
  absence: {},
  pendingRequests: [],
  othersPendingRequests: [],
  absenceTypes: [],
  declinedLeaves: [],
  othersDeclinedLeaves: [],
  mandatoryLeaves: [],
  publicHolidays: [],
};

const states = {
  pending: "pending",
  idle: "idle",
};

export const RequestAbsenceData = createAsyncThunk(
  "CalenderReducer/RequestAbsenceData",

  async (payload, { rejectWithValue }) => {
    try {
      const response = await CalenderApiServices.RequestAbsenceData(payload);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const RequestCalenderData = createAsyncThunk(
  "CalenderReducer/RequestCalenderData",

  async (payload, { rejectWithValue }) => {
    try {
      const response = await CalenderApiServices.generateAbsenceReport(payload);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const publicHolidaysRequest = createAsyncThunk(
  "CalenderReducer/publicHolidaysRequest",

  async (payload, { rejectWithValue }) => {
    try {
      const response = await CalenderApiServices.apiPublicHolidaysRequest(
        payload
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getOthersDeclineLeavesRequest = createAsyncThunk(
  "CalenderReducer/getOthersDeclineLeavesRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const response =
        await CalenderApiServices.apiGetOthersDeclineLeavesRequest(payload);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDeclineLeavesRequest = createAsyncThunk(
  "CalenderReducer/getDeclineLeavesRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await CalenderApiServices.getDeclinedLeavesRequest(
        payload
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getOthersPendingRequest = createAsyncThunk(
  "CalenderReducer/getOthersPendingRequest",

  async (payload, { rejectWithValue }) => {
    try {
      const response = await CalenderApiServices.apiGetOthersPendingRequest(
        payload
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCalenderPendingRequest = createAsyncThunk(
  "CalenderReducer/getCalenderPendingRequest",

  async (payload, { rejectWithValue }) => {
    try {
      const response = await CalenderApiServices.getPendingRequest(payload);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAbsenceType = createAsyncThunk(
  "CalenderReducer/getAbsenceType",

  async (payload, { rejectWithValue }) => {
    try {
      const response = await CalenderApiServices.getAbsenceTypeRequest(payload);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const addTimeOff = createAsyncThunk(
  "CalenderReducer/addTimeOff",

  async (payload, { rejectWithValue }) => {
    try {
      const response = await CalenderApiServices.addTimeOffRequest(payload);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const postApprove = createAsyncThunk(
  "CalenderReducer/postApprove",

  async (payload, { rejectWithValue }) => {
    try {
      const response = await CalenderApiServices.postApproveRequest(payload);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateTimeOff = createAsyncThunk(
  "CalenderReducer/updateTimeOff",

  async (payload, { rejectWithValue }) => {
    try {
      const response = await CalenderApiServices.updateTimeOff(payload);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const addMandatoryLeaves = createAsyncThunk(
  "CalenderReducer/addMandatoryLeaves",

  async (payload, { rejectWithValue }) => {
    try {
      const response = await CalenderApiServices.addMandatoryLeaveRequest(
        payload
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMandatoryLeaves = createAsyncThunk(
  "CalenderReducer/getMandatoryLeaves",

  async (payload, { rejectWithValue }) => {
    try {
      const response = await CalenderApiServices.getMandatoryLeaveRequest(
        payload
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteMandatoryLeave = createAsyncThunk(
  "CalenderReducer/deleteMandatoryLeave",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await CalenderApiServices.deleteMandatoryLeaveRequest(
        payload
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const CalendersReducer = createReducer(initialState, {
  [updateTimeOff.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [updateTimeOff.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [updateTimeOff.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },
  [RequestAbsenceData.pending]: (state, action) => {
    return {
      ...state,
      absenceLoading: states.pending,
      error: null,
    };
  },
  [RequestAbsenceData.fulfilled]: (state, action) => {
    return {
      ...state,
      absence: action.payload.data,
      absenceLoading: states.idle,
      error: null,
    };
  },
  [RequestAbsenceData.rejected]: (state, action) => {
    return {
      ...state,
      absenceLoading: states.idle,
      error: action,
    };
  },

  [RequestCalenderData.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [RequestCalenderData.fulfilled]: (state, action) => {
    return {
      ...state,
      events: action.payload.data,
      loading: states.idle,
      error: null,
    };
  },
  [RequestCalenderData.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [publicHolidaysRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [publicHolidaysRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      publicHolidays: action.payload,
      loading: states.idle,
      error: null,
    };
  },
  [publicHolidaysRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [getOthersPendingRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [getOthersPendingRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      othersPendingRequests: action.payload.data,
      loading: states.idle,
      error: null,
    };
  },
  [getOthersPendingRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [getCalenderPendingRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [getCalenderPendingRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      pendingRequests: action.payload.data,
      loading: states.idle,
      error: null,
    };
  },
  [getCalenderPendingRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [addTimeOff.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [addTimeOff.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [addTimeOff.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [getAbsenceType.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [getAbsenceType.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      absenceTypes: action.payload.data,
      error: null,
    };
  },
  [getAbsenceType.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [postApprove.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [postApprove.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      // absenceTypes: action.payload.data,
      error: null,
    };
  },
  [postApprove.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [addMandatoryLeaves.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [addMandatoryLeaves.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [addMandatoryLeaves.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [getMandatoryLeaves.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [getMandatoryLeaves.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      mandatoryLeaves: action.payload.data,
      error: null,
    };
  },
  [getMandatoryLeaves.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [getOthersDeclineLeavesRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [getOthersDeclineLeavesRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      othersDeclinedLeaves: action.payload.data,
      error: null,
    };
  },
  [getOthersDeclineLeavesRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [getDeclineLeavesRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [getDeclineLeavesRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      declinedLeaves: action.payload.data,
      error: null,
    };
  },
  [getDeclineLeavesRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [deleteMandatoryLeave.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [deleteMandatoryLeave.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [deleteMandatoryLeave.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [stopLoaderAndEmptyErrors.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [logoutRequest.fulfilled]: (state, action) => {
    return {
      ...initialState,
    };
  },
});

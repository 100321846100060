import React from "react";
import { get } from "lodash";
import { Select } from "antd";

const AbsenceDropDown = ({
  title,
  isDisabled,
  onChange,
  value,
  valueKey,
  displayKey,
  displayKey2,
  dataArray,
  colorKey,
}) => {
  const { Option } = Select;

  const onSearch = (value) => {};

  return (
    <div className="row align-items-center">
      <div className="col-12 col-md-4 col-sm-12 px-0">
        <h6>{title}</h6>
      </div>
      <div className="col-12 col-md-6 col-sm-12 px-0">
        <Select
          className="filter-form-dropdown"
          disabled={isDisabled}
          showSearch={true}
          value={value}
          onSearch={onSearch}
          onChange={onChange}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
        >
          {Array.isArray(dataArray) &&
            dataArray.map((val, index) => {
              return (
                <Option
                  key={index}
                  value={get(val, valueKey, "")}
                  label={
                    get(val, displayKey, "") + " " + get(val, displayKey2, "")
                  }
                >
                  <div className="d-flex align-content-center align-items-center">
                    <p className="mb-0">
                      {get(val, colorKey, "") && (
                        <span
                          className="col-dot rounded-5 mx-2"
                          style={{
                            backgroundColor: get(val, colorKey, ""),
                          }}
                        />
                      )}
                      {get(val, displayKey, "") +
                        " " +
                        get(val, displayKey2, "")}
                    </p>
                  </div>
                </Option>
              );
            })}
        </Select>
      </div>
    </div>
  );
};

export default AbsenceDropDown;

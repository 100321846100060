import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import { DocumentsApiServices } from "../actions/DocumentsAction";
import {
  stopLoaderAndEmptyErrors,
  logoutRequest,
} from "./AuthenticationReducer";

export const uploadSharedDocument = createAsyncThunk(
  "DocumentReducer/uploadSharedDocument",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await DocumentsApiServices.apiUploadSharedDocument(
        payload
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteSharedDocument = createAsyncThunk(
  "DocumentReducer/deleteSharedDocument",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await DocumentsApiServices.apiDeleteSharedDocument(
        payload
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const editSharedDocument = createAsyncThunk(
  "DocumentReducer/editSharedDocument",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await DocumentsApiServices.apiEditSharedDocument(
        payload
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSharedDocumentsList = createAsyncThunk(
  "DocumentReducer/getSharedDocumentsList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await DocumentsApiServices.apiGetSharedDocumentsList(
        payload
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDocuments = createAsyncThunk(
  "DocumentReducer/fetchDocuments",
  async (payload) => {
    const response = await DocumentsApiServices.RequestDocuments(payload);
    return response;
  }
);

export const createDocuments = createAsyncThunk(
  "DocumentReducer/createDocuments",
  async (payload) => {
    const response = await DocumentsApiServices.createDocumentRequest(payload);
    return response;
  }
);

export const uploadFile = createAsyncThunk(
  "DocumentReducer/uploadFile",
  async (payload) => {
    const response = await DocumentsApiServices.uploadFileRequest(payload);
    return response;
  }
);

export const downloadFile = createAsyncThunk(
  "DocumentReducer/downloadFile",
  async (payload) => {
    const response = await DocumentsApiServices.downloadFileRequest(payload);
    return response;
  }
);

export const onDeleteFolder = createAsyncThunk(
  "DocumentReducer/onDeleteFolder",
  async (payload) => {
    const response = await DocumentsApiServices.apiOnDeleteFolder(payload);
    return response;
  }
);

export const onEditFolder = createAsyncThunk(
  "DocumentReducer/onEditFolder",
  async (payload) => {
    const response = await DocumentsApiServices.apiOnEditFolder(payload);
    return response;
  }
);

export const onDeleteFile = createAsyncThunk(
  "DocumentReducer/onDeleteFile",
  async (payload) => {
    const response = await DocumentsApiServices.apiOnDeleteFile(payload);
    return response;
  }
);

export const onEditFile = createAsyncThunk(
  "DocumentReducer/onEditFile",
  async (payload) => {
    const response = await DocumentsApiServices.apiOnEditFile(payload);
    return response;
  }
);

export const regenerateDocumentsRequest = createAsyncThunk(
  "DocumentReducer/regenerateDocumentsRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await DocumentsApiServices.apiRegenerateDocumentsRequest(
        payload
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  alreadyLoggedIn: false,
  loading: "idle",
  error: null,
  documents: [],
  sharedDocuments: [],
};

const states = {
  pending: "pending",
  idle: "idle",
};

export const DocumentReducer = createReducer(initialState, {
  [getSharedDocumentsList.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },

  [getSharedDocumentsList.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
      sharedDocuments: action.payload,
    };
  },

  [getSharedDocumentsList.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [editSharedDocument.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [editSharedDocument.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },

  [editSharedDocument.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [deleteSharedDocument.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [deleteSharedDocument.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },

  [deleteSharedDocument.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [uploadSharedDocument.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [uploadSharedDocument.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },

  [uploadSharedDocument.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [fetchDocuments.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },

  [fetchDocuments.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
      documents: action.payload.data,
    };
  },

  [fetchDocuments.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [createDocuments.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },

  [createDocuments.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [createDocuments.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [uploadFile.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },

  [uploadFile.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [uploadFile.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [downloadFile.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },

  [downloadFile.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [downloadFile.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [onDeleteFolder.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },

  [onDeleteFolder.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [onDeleteFolder.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [onEditFolder.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },

  [onEditFolder.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [onEditFolder.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [onDeleteFile.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },

  [onDeleteFile.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [onDeleteFile.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [onEditFile.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },

  [onEditFile.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [onEditFile.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [regenerateDocumentsRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [regenerateDocumentsRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [regenerateDocumentsRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [stopLoaderAndEmptyErrors.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [logoutRequest.fulfilled]: (state, action) => {
    return {
      ...initialState,
    };
  },
});

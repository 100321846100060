import { createAsyncThunk, createReducer, current } from "@reduxjs/toolkit";
import { employeesApiServices } from "../actions/EmployeesAction";
import {
  stopLoaderAndEmptyErrors,
  logoutRequest,
} from "./AuthenticationReducer";

export const employeesRequest = createAsyncThunk(
  "EmployeeReducer/employeesRequest",
  async (payload) => {
    const response = await employeesApiServices.getEmployees(payload);
    return response.data;
  }
);

export const createEmployeeRequest = createAsyncThunk(
  "EmployeeReducer/createEmployeeRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await employeesApiServices.createEmploye(payload);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const editEmployeesStates = createAsyncThunk(
  "EmployeeReducer/editEmployeesStates",
  async (payload) => {
    return payload;
  }
);

export const bulkEmployeesStates = createAsyncThunk(
  "EmployeeReducer/bulkEmployeesStates",
  async (payload) => {
    return payload;
  }
);

export const createBulkEmployees = createAsyncThunk(
  "EmployeeReducer/createBulkEmployees",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await employeesApiServices.apiCreateBulkEmployees(
        payload
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const allLocationTypes = createAsyncThunk(
  "EmployeeReducer/allLocationTypes",
  async (payload) => {
    const response = await employeesApiServices.apiAllLocationTypes(payload);
    return response;
  }
);

export const createLocationTypes = createAsyncThunk(
  "EmployeeReducer/createLocationTypes",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await employeesApiServices.apiCreateLocationTypes(
        payload
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateLocationTypeRequest = createAsyncThunk(
  "EmployeeReducer/updateLocationTypeRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await employeesApiServices.apiUpdateLocationTypeRequest(
        payload
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteLocationType = createAsyncThunk(
  "EmployeeReducer/deleteLocationType",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await employeesApiServices.apiDeleteLocationTypes(
        payload
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCountries = createAsyncThunk(
  "EmployeeReducer/getCountries",
  async (payload) => {
    const response = await employeesApiServices.getCountries(payload);
    return response;
  }
);

export const changeEmployeeWorkingStatus = createAsyncThunk(
  "EmployeeReducer/changeEmployeeWorkingStatus",
  async (payload) => {
    const response = await employeesApiServices.ApiChangeEmployeeWorkingStatus(
      payload
    );
    return response;
  }
);

export const checkEmailStatus = createAsyncThunk(
  "EmployeeReducer/checkEmail",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await employeesApiServices.checkEmailRequest(payload);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const downloadBulkCSV = createAsyncThunk(
  "EmployeeReducer/downloadBulkCSV",
  async (payload) => {
    const response = await employeesApiServices.downloadBulkCSVTemplate(
      payload
    );
    return response;
  }
);

// export const uploadBulkCSV = createAsyncThunk(
//   "EmployeeReducer/uploadBulkCSV",
//   async (payload) => {
//     const response = await employeesApiServices.uploadBulkCSVTemplate(payload);
//     return response;
//   }
// );

const initialState = {
  alreadyLoggedIn: false,
  loading: "idle",
  error: null,
  allEmployees: [],
  countries: [],
  locationTypes: [],
  employeesCount: null,
  bulkEmployeesData: [
    {
      first_name: "",
      last_name: "",
      email: "",
      work_start_date: "",
    },
  ],
  editEmployeesData: [],
};

const states = {
  pending: "pending",
  idle: "idle",
};

const updateAllEmployees = (allEmployees, body) => {
  var allEmployeesList = [];
  if (allEmployees.length > 0) {
    allEmployees.forEach((i) => {
      if (i.user_id == body.user_id) {
        allEmployeesList.push({
          ...i,
          working_status: body.working_status,
          active: false,
        });
      } else {
        allEmployeesList.push(i);
      }
    });
  }
  return allEmployeesList;
};

export const EmployeeReducer = createReducer(initialState, {
  [employeesRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [employeesRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      alreadyLoggedIn: true,
      allEmployees: action.payload.users,
      employeesCount: action.payload.count,
      loading: states.idle,
      error: null,
    };
  },
  [employeesRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [changeEmployeeWorkingStatus.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      allEmployees: updateAllEmployees(
        current(state.allEmployees),
        action.meta.arg
      ),
      error: null,
    };
  },
  [changeEmployeeWorkingStatus.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [changeEmployeeWorkingStatus.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [createBulkEmployees.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [createBulkEmployees.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [createBulkEmployees.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [createEmployeeRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [createEmployeeRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [createEmployeeRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [createLocationTypes.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [createLocationTypes.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [createLocationTypes.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [updateLocationTypeRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [updateLocationTypeRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [updateLocationTypeRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [deleteLocationType.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [deleteLocationType.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [deleteLocationType.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [allLocationTypes.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [allLocationTypes.fulfilled]: (state, action) => {
    return {
      ...state,
      locationTypes: action.payload.data,
      loading: states.idle,
      error: null,
    };
  },
  [allLocationTypes.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [getCountries.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [getCountries.fulfilled]: (state, action) => {
    return {
      ...state,
      alreadyLoggedIn: true,
      countries: action.payload.data,
      loading: states.idle,
      error: null,
    };
  },
  [getCountries.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [downloadBulkCSV.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [downloadBulkCSV.fulfilled]: (state, action) => {
    return {
      ...state,

      loading: states.idle,
      error: null,
    };
  },
  [downloadBulkCSV.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },
  [bulkEmployeesStates.fulfilled]: (state, action) => {
    return {
      ...state,
      bulkEmployeesData: action.payload,
    };
  },

  [editEmployeesStates.fulfilled]: (state, action) => {
    return {
      ...state,
      editEmployeesData: action.payload,
    };
  },

  // [uploadBulkCSV.pending]: (state, action) => {
  //   return {
  //     ...state,
  //     loading: states.pending,
  //     error: null,
  //   };
  // },
  // [uploadBulkCSV.fulfilled]: (state, action) => {
  //   return {
  //     ...state,

  //     loading: states.idle,
  //     error: null,
  //   };
  // },
  // [uploadBulkCSV.rejected]: (state, action) => {
  //   return {
  //     ...state,
  //     loading: states.idle,
  //     error: "",
  //   };
  // },

  [stopLoaderAndEmptyErrors.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [logoutRequest.fulfilled]: (state, action) => {
    return {
      ...initialState,
    };
  },
});

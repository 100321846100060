import React, { useState, useEffect, useRef } from "react";
import { InputField } from "../Input/InputField";
import { useTranslation } from "react-i18next";
import { Button, Modal, Select, Divider, Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import {
  isUaeRegion,
  showFaliureToast,
  showSuccessToast,
} from "../../Utilities";
import { useDispatch, useSelector } from "react-redux";
import { getAlphaNumericString } from "../../Helpers/AppValidators";
import { unwrapResult } from "@reduxjs/toolkit";
import { Applogger } from "../../Helpers/Logger";
import { getCountryIDFromName, getCountryNameFromID } from "../../Utilities";
import { get } from "lodash";
import {
  getPlacesOfWork,
  createPlaceOfWork,
  updatePlaceOfWork,
} from "../../Redux/reducers/SettingsReducer";
import { styles } from "../RoleAndPrevilegas/RoleModal";
import {
  getCountries,
  allLocationTypes,
  createLocationTypes,
  deleteLocationType,
} from "../../Redux/reducers/EmployeesReducer";
import "./styles.css";
import $ from "jquery";
import ViewOnGoogle from "../AppButtons/ViewOnGoogle";
import AppConstants from "../../Helpers/AppConstants";
import useLocalisedConstants from "../../customHooks/useLocalisedConstants";

export default function PlaceOfWork({
  isModalVisible,
  handleCancel,
  placeOfWork,
  companyId,
  radiusRequired = true,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const appConstants = useLocalisedConstants();
  const inputRef = useRef(null);
  const selectRef = useRef(null);

  const autoCompleteRef = useRef();
  const addressRef = useRef(null);

  const { Option } = Select;

  const { lng } = useSelector((state) => state.languageReducer);
  const { token } = useSelector((state) => state.AuthenticationReducer);
  const { countries, locationTypes } = useSelector((state) => state.employees);
  const [latlong, setLatLong] = useState({
    latitude: "",
    longitude: "",
  });
  const [localLocationType, setLocalLocationType] = useState("");
  // const [locationTypeId, setLocationTypeId] = useState(null);

  const [editMode, setEditMode] = useState(false);
  const [currentWorkPlaceId, setCurrentWorkPlaceId] = useState("");
  const placeofWorkObj = {
    name: "",
    address_line1: "",
    address_line2: "",
    town_city: "",
    post_code: null,
    country_id: "",
    latitude: "",
    longitude: "",
    location_type_id: "",
    withoutEmployees: true,
    countryName: "",
    acceptableRadius: "",
  };
  const unitedKingdom = "United Kingdom";
  const unitedAEmirates = "United Arab Emirates";
  const england = "England";
  const [newPlaceOfWork, setNewPlaceOfWork] = useState(placeofWorkObj);

  useEffect(() => {
    if (countries.length == 0) {
      getAllCountries();
    }
    if (locationTypes.length == 0) {
      getAllLocationTypes();
    }
  }, []);

  useEffect(() => {
    if (placeOfWork) {
      setEditMode(true);
      newPlaceOfWork.address_line1 = get(placeOfWork, "address_line1", "");
      newPlaceOfWork.address_line2 = get(placeOfWork, "address_line2", "");
      newPlaceOfWork.country_id = get(placeOfWork, "country_id", "");
      newPlaceOfWork.location_type_id = get(
        placeOfWork,
        "location_type_id",
        ""
      );
      newPlaceOfWork.name = get(placeOfWork, "name", "");
      newPlaceOfWork.post_code = get(placeOfWork, "postcode", "");
      newPlaceOfWork.town_city = get(placeOfWork, "town_city", "");
      newPlaceOfWork.acceptableRadius = get(placeOfWork, "radius", "");
      newPlaceOfWork.countryName = getCountryNameFromID(
        newPlaceOfWork.country_id,
        countries
      );
      setNewPlaceOfWork({ ...newPlaceOfWork });
      setCurrentWorkPlaceId(placeOfWork.work_place_id);
      setLatLong({
        latitude: placeOfWork.latitude,
        longitude: placeOfWork.longitude,
      });
    } else {
      setNewPlaceOfWork(placeofWorkObj);
      setEditMode(false);
    }
    setLocalLocationType("");
  }, [isModalVisible, placeOfWork]);

  useEffect(() => {
    if (addressRef.current) {
      addressRef.current.value = newPlaceOfWork.address_line1;
    }
  }, [newPlaceOfWork.address_line1]);

  useEffect(() => {
    if (locationTypes) {
      if (Array.isArray(locationTypes) && locationTypes.length > 0) {
        setNewPlaceOfWork({
          ...newPlaceOfWork,
          location_type_id: locationTypes[locationTypes.length - 1].id,
        });
      }
    }
  }, [locationTypes]);

  const getAutoCompleteLocationHandler = () => {
    $(document).ready(function () {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        addressRef.current,
        AppConstants.addressOptions
      );
      autoCompleteRef.current.addListener("");
      autoCompleteRef.current.addListener("place_changed", async function () {
        const place = await autoCompleteRef.current.getPlace();
        setNewPlaceOfWork({
          ...newPlaceOfWork,
          address_line1: addressRef.current.value,
        });
        fillInAddress(place);
        setLatLong({
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        });
        // autoCompleteRef.current.removeListener("place_changed");
      });
    });
  };

  const updateLocationTypeHandler = (id) => {
    dispatch(updatePlaceOfWork({ token, newPlaceOfWork, id, companyId }))
      .then(unwrapResult)
      .then((res) => {
        callGetWorkPlacesAPI();
        handleCancel();
        showSuccessToast(
          `Location ${appConstants.toastMessages.updatedSuccess}`
        );
        Applogger("Response at updatePlaceOfWork", res);
      })
      .catch((err) => {
        Applogger("Error at updatePlaceOfWork", err);
      });
  };

  function getAllLocationTypes() {
    dispatch(allLocationTypes({ token, lng, companyId }))
      .then(unwrapResult)
      .then(() => {
        Applogger("Location types received successfully");
      })
      .catch(() => {
        Applogger("Found error while fetching location types");
      });
  }

  function handleCreateLocationTypes() {
    if (!localLocationType) {
      showFaliureToast("Please enter location type name");
      return;
    }

    dispatch(
      createLocationTypes({ token, lng, name: localLocationType, companyId })
    )
      .then(unwrapResult)
      .then(() => {
        Applogger("Location types received successfully");
        setLocalLocationType("");
        setTimeout(() => {
          selectRef?.current.blur();
          inputRef.current.blur();
        }, 100);
        showSuccessToast("Location type added successfully");
        getAllLocationTypes();
      })
      .catch((err) => {
        Applogger("Found error while creating location type", err);
        showFaliureToast(
          get(err, "errors.name[0]") ??
            "Unable to add location type, Please check name again"
        );
      });
  }

  function getAllCountries() {
    dispatch(getCountries({ token, lng }))
      .then(unwrapResult)
      .then(() => {
        Applogger("Countries received successfully");
      })
      .catch(() => {
        Applogger("Found error while fetching countries");
      });
  }

  const callCreateWorkPlaceAPI = () => {
    dispatch(createPlaceOfWork({ token, newPlaceOfWork }))
      .then(unwrapResult)
      .then((response) => {
        handleCancel();
        callGetWorkPlacesAPI();
        showSuccessToast("Work place created successfully");
        Applogger("Response at createPlaceOfWork", response);
      })
      .catch((err) => {
        showFaliureToast("Unable to create work place");
        Applogger("Error at createPlaceOfWork", err.message);
      });
  };

  const callGetWorkPlacesAPI = () => {
    dispatch(getPlacesOfWork({ token, lng }))
      .then(unwrapResult)
      .then((response) => {
        Applogger("Response at getPlacesOfWork", response);
      })
      .catch((err) => {
        Applogger("Error at getPlacesOfWork", err.message);
      });
  };

  const onNameChange = (event) => {
    event.preventDefault();
    newPlaceOfWork.name = event.target.value;
    setNewPlaceOfWork({ ...newPlaceOfWork });
  };

  const onAddressLine1Change = (event) => {
    event.preventDefault();
    newPlaceOfWork.address_line1 = event.target.value;
    setNewPlaceOfWork({ ...newPlaceOfWork });
  };

  const onAddressLine2Change = (event) => {
    event.preventDefault();
    newPlaceOfWork.address_line2 = event.target.value;
    setNewPlaceOfWork({ ...newPlaceOfWork });
  };

  const onTownAndCityChange = (event) => {
    event.preventDefault();
    newPlaceOfWork.town_city = event.target.value;
    setNewPlaceOfWork({ ...newPlaceOfWork });
  };

  const onPostalCodeChange = (event) => {
    event.preventDefault();
    newPlaceOfWork.post_code = event.target.value;
    setNewPlaceOfWork({ ...newPlaceOfWork });
  };

  const onAcceptableRadiusChange = (event) => {
    event.preventDefault();
    newPlaceOfWork.acceptableRadius = event.target.value;
    setNewPlaceOfWork({ ...newPlaceOfWork });
  };

  const savePressHandler = () => {
    setNewPlaceOfWork({
      ...newPlaceOfWork,
      country_id: getCountryIDFromName(newPlaceOfWork.countryName, countries),
    });
    if (editMode) {
      updateLocationTypeHandler(currentWorkPlaceId);
    } else {
      callCreateWorkPlaceAPI();
    }
  };

  function fillInAddress(place) {
    let address1 = "";
    let postcode = "";
    let countryName = "";
    let townCity = "";
    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name} ${address1}`;
          break;
        }
        case "route": {
          address1 += component.long_name;
          break;
        }
        case "administrative_area_level_1": {
          countryName = component.short_name;
          break;
        }
        case "country": {
          if (countryName == "") {
            if (
              getAlphaNumericString(component.long_name) ==
              getAlphaNumericString(unitedKingdom)
            ) {
              countryName = england;
            }
          }

          if (isUaeRegion()) {
            countryName = unitedAEmirates;
          }
          break;
        }
        case "administrative_area_level_2": {
          townCity = component.short_name;
          break;
        }
        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          break;
        }
        case "postal_code_prefix": {
          postcode = postcode
            ? `${postcode}-${component.long_name}`
            : component.long_name;
          break;
        }
      }
    }

    setNewPlaceOfWork({
      ...newPlaceOfWork,
      address_line1: place.formatted_address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
      town_city: townCity,
      address_line2: address1,
      post_code: postcode,
      country_id: getCountryIDFromName(countryName, countries),
      countryName: countryName,
    });
  }

  const isDisabled = () => {
    return (
      !newPlaceOfWork.name ||
      !newPlaceOfWork.address_line1 ||
      !newPlaceOfWork.address_line2 ||
      !newPlaceOfWork.town_city ||
      !newPlaceOfWork.post_code ||
      !newPlaceOfWork.country_id ||
      !newPlaceOfWork.location_type_id ||
      !newPlaceOfWork.location_type_id ||
      !newPlaceOfWork.countryName
    );
  };

  // const handleUpdateLocationType = () => {
  //   dispatch(
  //     updateLocationTypeRequest({
  //       token,
  //       locationId: locationTypeId,
  //       lcoationName: localLocationType,
  //     })
  //   )
  //     .then(unwrapResult)
  //     .then((res) => {
  //       getAllLocationTypes();
  //       setLocationTypeId(null);
  //       setLocalLocationType("");

  //       showSuccessToast("Location type updated successfully");
  //       Applogger("Resposne at updateLocationType", res);
  //     })
  //     .catch((err) => {
  //       showFaliureToast(err ?? "Unable to update the Location Type");
  //       Applogger("Error at updateLocationType", err);
  //     });
  // };

  // const onClickLocationTypeUpdate = (e, item) => {
  //   e.stopPropagation();
  //   setUpdateLocationType(true);
  //   setLocationTypeId(item.id);
  //   setLocalLocationType(item.name);
  // };

  // const handleUpdateClear = () => {
  //   setLocationTypeId(null);
  //   setLocalLocationType("");
  // };

  const handleDeleteLocationType = (locationTypeId) => {
    Applogger("LocationTypeId", locationTypeId);

    dispatch(deleteLocationType({ token, locationTypeId, lng }))
      .then(unwrapResult)
      .then((res) => {
        getAllLocationTypes();
        showSuccessToast("Location Type deleted successfully");
        Applogger("Resposne at deleteLocationType", res);
      })
      .catch((err) => {
        showFaliureToast(err ?? "Unable to delete the Location Type");
        Applogger("Error at deleteLocationType", err);
      });
  };

  return (
    <div>
      <Modal
        destroyOnClose={true}
        visible={isModalVisible}
        onOk={savePressHandler}
        onCancel={handleCancel}
        width={786}
        // footer={null}
        preserve={false}
        title={t(placeOfWork ? "updateLocation" : "newPlaceOfWork")}
        okButtonProps={{ disabled: isDisabled() }}
      >
        <div className="px-2">
          <div className="d-flex align-items-center flex-wrap-sm">
            <div className="col-12 col-md-4 col-sm-12">
              <label>{t("locationType")}</label>
            </div>
            <div className="col-12 col-md-8 col-sm-12">
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                disabled={editMode ? true : false}
                style={{
                  width: "100%",
                }}
                className="filter-form-dropdown text-capitalize"
                value={newPlaceOfWork.location_type_id}
                onChange={(_, option) => {
                  setNewPlaceOfWork({
                    ...newPlaceOfWork,
                    location_type_id: option.value,
                  });
                  // setLocalLocationType(option.label);
                }}
                optionLabelProp="label"
                // ref={selectRef}
                // defaultValue={newPlaceOfWork.location_type_id}
                // dropdownRender={(menu) => (
                //   <>
                //     {menu}
                //     <Divider
                //       style={{
                //         margin: "8px 0",
                //       }}
                //     />
                //     <Space
                //       style={{
                //         padding: "0 8px 4px",
                //       }}
                //     >
                //       <Input
                //         placeholder="Enter name"
                //         ref={inputRef}
                //         value={newLocationType}
                //         onChange={locationTypeChangeHandler}
                //       />
                //       <Space />
                //       <Button
                //         disabled={!newLocationType}
                //         type="primary"
                //         onClick={() => handleCreateLocationTypes()}
                //       >
                //         Add Location Type
                //       </Button>
                //     </Space>
                //   </>
                // )}
                // options={
                //   Array.isArray(locationTypes) &&
                //   locationTypes.map((item) => ({
                //     label: item.name,
                //     value: item.id,
                //   }))
                // }

                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <div>
                      <Divider style={styles.dividerStyl} />
                    </div>
                    <div style={styles.addRoleConStyl}>
                      <Input
                        placeholder="Enter name"
                        ref={inputRef}
                        value={localLocationType}
                        onChange={(e) => {
                          setLocalLocationType(e.target.value);
                        }}
                      />
                    </div>
                    <div style={styles.addRoleBtnStyl}>
                      <Button
                        style={{
                          width: "100%",
                          marginRight: 0,
                        }}
                        disabled={!localLocationType}
                        type="primary"
                        onClick={() => handleCreateLocationTypes()}
                      >
                        {"Add Location Type"}
                      </Button>
                    </div>
                    {/* <div style={styles.addRoleBtnStyl}>
                      <Button
                        style={{
                          width: "100%",
                          marginRight: locationTypeId ? 2 : 0,
                        }}
                        disabled={!localLocationType}
                        type="primary"
                        onClick={() =>
                          locationTypeId
                            ? handleUpdateLocationType()
                            : handleCreateLocationTypes()
                        }
                      >
                        {locationTypeId
                          ? "Update Location Type"
                          : "Add Location Type"}
                      </Button>
                      {locationTypeId && (
                        <Button
                          style={styles.clearBtn}
                          type="primary"
                          onClick={() => handleUpdateClear()}
                        >
                          Clear
                        </Button>
                      )}
                    </div> */}
                  </div>
                )}
              >
                {locationTypes.map((item) => (
                  <Option key={item.id} value={item.id} label={item.name}>
                    <span className="text-capitalize">{item.name}</span>
                    <span style={{ float: "right" }}>
                      {item.name != "Owner" && (
                        <>
                          {/* <EditOutlined
                            style={{ marginRight: 12 }}
                            onClick={(e) => {
                              onClickLocationTypeUpdate(e, item);
                            }}
                          /> */}
                          <DeleteOutlined
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteLocationType(item.id);
                            }}
                          />
                        </>
                      )}
                    </span>
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <div className="d-flex flex-column">
            <InputField
              title={t("name")}
              onChange={(e) => onNameChange(e)}
              placeholder={"E.g. HQ or Main depot"}
              isRequired={true}
              value={newPlaceOfWork.name}
              type={"text"}
            />
            <div className="d-flex align-items-center flex-wrap-sm">
              <p className="mb-0 col-md-4 col-sm-12">
                {t("address")}
                <span className="text-danger">*</span>
              </p>
              <div className="col-12 col-md-8 col-sm-12">
                <input
                  className={"filter-form"}
                  ref={addressRef}
                  placeholder={
                    placeOfWork
                      ? newPlaceOfWork.address_line1
                      : "Enter address here..."
                  }
                  onFocus={getAutoCompleteLocationHandler}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setNewPlaceOfWork({
                        ...newPlaceOfWork,
                        address_line1: "",
                      });
                    }
                  }}
                />
              </div>
            </div>
            {newPlaceOfWork.address_line1 && latlong.latitude && (
              <ViewOnGoogle latlong={latlong} />
            )}
            {radiusRequired && (
              <InputField
                title={`${t("acceptableRadius")}`}
                onChange={(e) => onAcceptableRadiusChange(e)}
                placeholder={"500"}
                isRequired={true}
                value={newPlaceOfWork.acceptableRadius}
                type={"text"}
                info={t("radiusInfo")}
              />
            )}
            <InputField
              title={`${t("addressLine")} 1`}
              onChange={(e) => onAddressLine2Change(e)}
              placeholder={""}
              isRequired={true}
              value={newPlaceOfWork.address_line2}
              type={"text"}
            />
            <InputField
              title={t("townCity")}
              onChange={(e) => onTownAndCityChange(e)}
              placeholder={""}
              isRequired={true}
              value={newPlaceOfWork.town_city}
              type={"text"}
            />
            <InputField
              title={t("postcode")}
              onChange={(e) => onPostalCodeChange(e)}
              placeholder={"123456"}
              isRequired={true}
              value={newPlaceOfWork.post_code}
              type={"text"}
            />
            <InputField
              title={`${t("country")}`}
              onChange={(e) => Applogger("Change in country", e)}
              placeholder={""}
              isRequired={true}
              value={newPlaceOfWork.countryName}
              type={"text"}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";
import AppConstants from "../../../Helpers/AppConstants";

export default function SchedulesCard({ shortDescriptionImage }) {
  const { t } = useTranslation();

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <a href={AppConstants.routes.staffing} className="card-link text-primary">
        {t("viewPublishedSchedule")}
      </a>
      <br />
      <img src={shortDescriptionImage} />
    </div>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AppConstants from "../../../Helpers/AppConstants";
import usePrevilagesExist from "../../../Helpers/PrivilegesFuncs";

export default function DocumentsCard() {
  const { t } = useTranslation();

  const { userPrevilages } = useSelector(
    (state) => state.AuthenticationReducer
  );
  const { userPrevilages: moduleUserPrevilages } = useSelector(
    (state) => state.settings
  );

  const { exist: listPersonalDocs } = usePrevilagesExist(
    AppConstants.previlages.listPersonalDocs
  );

  const isModuleAllowed = (module) => {
    return moduleUserPrevilages.some((moduleUserPrevilage) => {
      return (
        moduleUserPrevilage.key === module &&
        moduleUserPrevilage.children.some((child) => {
          return userPrevilages.includes(child.key);
        })
      );
    });
  };

  return (
    <div>
      {listPersonalDocs && (
        <>
          <a href="profile?tab=my_documents" className="card-link text-primary">
            {t("myDocuments")}
          </a>
          <br />
        </>
      )}
      {isModuleAllowed(AppConstants.previlagesModules.sharedDocuments) && (
        <>
          <a
            href={AppConstants.routes.sharedDocuments}
            className="card-link text-primary"
          >
            {t("sharedDocuments")}
          </a>
          <br />
        </>
      )}
      {isModuleAllowed(AppConstants.previlagesModules.documents) && (
        <a
          href={AppConstants.routes.documents}
          className="card-link text-primary"
        >
          {t("companyDocuments")}
        </a>
      )}
    </div>
  );
}

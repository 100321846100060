import React from "react";
import moment from "moment";
import AppConstants from "../../../Helpers/AppConstants";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import usePrevilagesExist from "../../../Helpers/PrivilegesFuncs";

export default function OverTimeCard({ cardData, cancelLodge, mode }) {
  const { exist: updateDeleteOvertimeLog } = usePrevilagesExist(
    AppConstants.previlages.updateDeleteOvertimeLog
  );

  const navigate = useNavigate();
  const { role } = useSelector((state) => state.AuthenticationReducer);
  const { t } = useTranslation();
  const {
    start_date,
    end_date,
    log_id,
    appreoved_by,
    start_hour,
    start_min,
    end_hour,
    end_min,
    overtime_length,
    created_at,
    modified_at,
    time_history,
  } = cardData;

  return (
    <div className="overTimeCard">
      <div className="iconView text-black-50">
        <i className="ri-history-line fs-2 overViewICon" />
      </div>
      <div className="bodyView">
        <div className="bodyHeadingView text-capitalize">
          {time_history && <h6 className="bodyHeading">{time_history}</h6>}
          <span
            className={`statusButtonSpan ${
              mode === "cancel" ? "blueTag" : "greenTag"
            }`}
          >
            {mode === "cancel" ? "CANCELLED" : "APPROVED"}
          </span>
        </div>
        <div className="timeView">
          {start_date == end_date ? (
            <p>{` ${moment.unix(start_date).format(
              AppConstants.dateFormat
              // `ddd DD MMM YYYY,`
            )} ${start_hour}:${start_min} - ${end_hour}:${end_min}`}</p>
          ) : (
            <p>{` ${moment.unix(start_date).format(
              AppConstants.dateFormat
              // `ddd DD MMM YYYY,`
            )} ${start_hour}:${start_min} - ${moment.unix(end_date).format(
              AppConstants.dateFormat
              // `ddd DD MMM YYYY`
            )} ${end_hour}:${end_min}`}</p>
          )}
        </div>

        <div className="overTimeCardStatusPanel">
          <div className="panelCard">
            <h6 className="bodyHeading">{t("approved")}</h6>
            <p>{`${moment.unix(modified_at ? modified_at : created_at).format(
              AppConstants.dateFormat
              // `ddd DD MMM YYYY`
            )} by ${appreoved_by}`}</p>
          </div>
          <div>
            <h6 className="bodyHeading">{`${overtime_length.split(":")[0]}h ${
              overtime_length.split(":")[1]
            }m total`}</h6>
            <p>{t("addedToToilBalance")}</p>
          </div>
        </div>
      </div>
      {updateDeleteOvertimeLog && mode !== "cancel" && (
        <div className="actionButtonView d-flex align-items-center">
          <i
            className="ri-pencil-line overViewICon fs-3 text-black-50"
            onClick={() => {
              navigate(AppConstants.routes.profile_log_overtime, {
                state: { overTimeId: log_id },
              });
            }}
          />
          <button
            data-mdb-toggle="modal"
            data-mdb-target="#overTimeCancel"
            onClick={() => cancelLodge(log_id)}
          >
            <i className="ri-delete-bin-line ml-2 fs-3 text-black-50" />
          </button>
        </div>
      )}
    </div>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";
import EmpDetailsField from "../Components/EmpDetailsField";
import useLocalisedConstants from "../../../../customHooks/useLocalisedConstants";
import EmpAddress from "../Components/EmpAddress";

export default function UserAddress({
  employeeForm,
  setEmployeeForm,
  currentType,
}) {
  const { t } = useTranslation();

  const appConstants = useLocalisedConstants();

  const handlePlacePayload = (place, value) => {

    let address1 = "";
    let postcode = "";
    let countryName = "";
    let townCity = "";
    let politicalCountry = "";

    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name} ${address1}`;
          break;
        }
        case "route": {
          address1 += component.long_name;
          break;
        }
        case "administrative_area_level_1": {
          // if (countryName == "") {
          //   if (
          //     getAlphaNumericString(component.long_name) ==
          //     getAlphaNumericString(unitedKingdom)
          //   ) {
          //     countryName = england;
          //   }
          // }
          countryName = component.long_name;
          break;
        }
        case "country" || "political": {
          politicalCountry = component.long_name;
          break;
        }
        case "administrative_area_level_2": {
          townCity = component.short_name;
          break;
        }
        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          break;
        }
        case "postal_code_prefix": {
          postcode = postcode
            ? `${postcode}-${component.long_name}`
            : component.long_name;
          break;
        }
      }
    }
    setEmployeeForm({
      ...employeeForm,
      address_1: value,
      address_2: address1,
      address_3: townCity,
      address_4: countryName,
      address_5: politicalCountry,
      post_code: postcode,
    });
    // setAddress1(value);
    // setAddress2(address1);
    // setTown(townCity);
    // setPostCode(postcode);
  };

  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-between gap-2 col-md-12 col-sm-12">
        <div className="d-flex gap-2 col-lg-12 col-md-12 col-sm-12 flex-wrap justify-between">
          <EmpAddress
            isRequired={true}
            title={t("address") + " 1"}
            handleSelection={handlePlacePayload}
            value={employeeForm.address_1}
            setValue={(value) =>
              setEmployeeForm({
                ...employeeForm,
                address_1: value,
              })
            }

          />
          <EmpDetailsField
            title={t("address") + " 2"}
            placeholder={t("address") + " 2"}
            value={employeeForm.address_2}
            isRequired={true}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                address_2: e.target.value,
              })
            }
          />
          <EmpDetailsField
            title={t("address") + " 3"}
            placeholder={t("address") + " 3"}
            value={employeeForm.address_3}
            isRequired={false}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                address_3: e.target.value,
              })
            }
          />
          <EmpDetailsField
            title={t("address") + " 4"}
            placeholder={t("address") + " 4"}
            value={employeeForm.address_4}
            isRequired={false}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                address_4: e.target.value,
              })
            }
          />
          <EmpDetailsField
            title={t("address") + " 5"}
            placeholder={t("address") + " 5"}
            value={employeeForm.address_5}
            isRequired={false}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                address_5: e.target.value,
              })
            }
          />
          <EmpDetailsField
            title={t("postCode")}
            placeholder={t("postCode")}
            value={employeeForm.post_code}
            isRequired={true}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                post_code: e.target.value,
              })
            }
          />
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useTitleHandler } from "../../customHooks/useTitleHandler";
import AppConstants from "../../Helpers/AppConstants";

export default function PrevilageNotFound() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  useTitleHandler(t("previlegeNotFoundTitle"));
  return (
    <div className="h-100-vs-cs d-flex justify-content-center align-items-center flex-column">
      <h1 className="not-found-heading">Oops! </h1>
      <h2 className="not-found-desc">401 - {t("previlegeNotFoundTitle")}</h2>
      <p>{t("previlegeNotFoundDesc")}</p>
      <button
        onClick={() => navigate(AppConstants.routes.dashboard)}
        className="btn btn-primary-cs fw-bold"
      >
        {t("goBackTo")} {t("dashboard")}
      </button>
    </div>
  );
}

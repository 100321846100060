import React from "react";

const HoursMinutesField = ({
  value = "",
  title = "",
  onChange = () => {},
  placeholder = "",
  disabled = false,
}) => {
  return (
    <div
      className={`filter-form d-flex filter-form d-flex align-items-center position-relative ${
        value < 0 && "red-border"
      }`}
    >
      <input
        value={value}
        disabled={disabled}
        style={{ outline: "none" }}
        placeholder={placeholder}
        type={"number"}
        onChange={onChange}
      />
      <label className="text-black-50 position-absolute me-2 end-0">
        {title}
      </label>
    </div>
  );
  // return (
  //   <div className={`col-3 col-md-3 col-sm-6 ${addClass}`}>
  //     <div className="d-flex flex-wrap-sm">
  //       <div className="w-100">
  //         <div className="d-flex filter-form d-flex align-items-center position-relative">
  //           <input
  //             value={value}
  //             disabled={disabled}
  //             style={{ outline: "none" }}
  //             maxLength={maxLength}
  //             placeholder={placeholder}
  //             type={"text"}
  //             onChange={onChange}
  //             max={max}
  //           />
  //           <label className="text-black-50 position-absolute me-2 end-0">
  //             {title}
  //           </label>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default HoursMinutesField;

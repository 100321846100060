import React from "react";
import logo from "../../assets/images/logo.png";
import AppConstants from "../../Helpers/AppConstants";
import { useTranslation } from "react-i18next";
import { useTitleHandler } from "../../customHooks/useTitleHandler";

export default function Privacy() {
  // Localisation
  const { t } = useTranslation();

  // Hooks
  useTitleHandler(t("privacyPolicy"));

  return (
    <div>
      <nav dir="ltr" className="navigation">
        <a href={AppConstants.routes.main}>
          <img src={logo} style={{ width: "180px" }} />
        </a>
      </nav>
      <div class="text-container p-5">
        <h2 class="heading">Privacy Policy</h2>

        <br />
        <p>
          <strong>Last updated:</strong> February 08, 2023
        </p>
        <p>
          This Privacy Policy describes our policies and procedures on the
          collection, use and disclosure of your information when you use the
          Service and tells you about your privacy rights and how the law
          protects you.
        </p>
        <p>
          We use your Personal data to provide and improve the Service. By using
          the Service, you agree to the collection and use of information in
          accordance with this Privacy Policy.
        </p>
        <h2 class="heading">Interpretation and Definitions</h2>
        <h3>Interpretation</h3>
        <p>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </p>

        <h3>Definitions</h3>
        <p>For the purposes of this Privacy Policy:</p>
        <p>
          <strong>Account</strong> means a unique account created for you to
          access our Service or parts of our Service.
        </p>
        <p>
          <strong>Application</strong> refers to SpeedyHR, the software program
          provided by the Company.
        </p>
        <p>
          <strong>Company</strong>(referred to as either "the Company", "We",
          "Us" or "Our" in this Agreement) refers to SpeedyHR.
        </p>
        <p>
          <strong>Cookies</strong> are small files that are placed on your
          computer, mobile device or any other device by a website, containing
          the details of your browsing history on that website among its many
          uses.
        </p>
        <p>
          <strong>Country</strong> refers to: United Kingdom
        </p>
        <p>
          <strong>Device</strong> means any device that can access the Service
          such as a computer, a cellphone or a digital tablet.
        </p>
        <p>
          <strong>Personal Data</strong> is any information that relates to an
          identified or identifiable individual.
        </p>
        <p>
          <strong>Service</strong> refers to the Application or the Website or
          both.
        </p>
        <p>
          <strong>Service Provider</strong>means any natural or legal person who
          processes the data on behalf of the Company. It refers to third-party
          companies or individuals employed by the Company to facilitate the
          Service, to provide the Service on behalf of the Company, to perform
          services related to the Service or to assist the Company in analyzing
          how the Service is used.
        </p>
        <p>
          <strong>Usage</strong> Data refers to data collected automatically,
          either generated by the use of the Service or from the Service
          infrastructure itself (for example, the duration of a page visit).
        </p>
        <p>
          <strong>Website</strong> refers to SpeedyHR, accessible from
          http://speedyhr.co.uk/ You means the individual accessing or using the
          Service, or the company, or other legal entity on behalf of which such
          individual is accessing or using the Service, as applicable.
        </p>

        <h2>Collecting and Using Your Personal Data</h2>

        <h3>Types of Data Collected</h3>
        <strong> Personal Data </strong>
        <p>
          While using our Service, We may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you. Personally identifiable information may include, but is
          not limited to:
        </p>
        <p>Email address</p>
        <p>Forename and Surname</p>
        <p>Phone number</p>
        <p>Address, Province, Postal code, City</p>
        <p>Usage Data</p>

        <strong> Usage Data </strong>
        <p>Usage Data is collected automatically when using the Service.</p>
        <p>
          Usage Data may include information such as your Device's Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that you visit, the time and date of your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </p>
        <p>
          When you access the Service by or through a mobile device, we may
          collect certain information automatically, including, but not limited
          to, the type of mobile device you use, your mobile device unique ID,
          the IP address of your mobile device, your mobile operating system,
          the type of mobile internet browser you use, unique device identifiers
          and other diagnostic data. We may also collect information that your
          browser sends whenever you visit our Service or when you access the
          Service by or through a mobile device.
        </p>
        <p>
          Information Collected while Using the Application While using our
          Application, in order to provide features of our Application, we may
          collect, with your prior permission:
        </p>
        <p>
          Information regarding your location Pictures and other information
          from your Device's camera and photo library
        </p>
        <p>
          We use this information to provide features of our Service, to improve
          and customize our Service. The information may be uploaded to the
          Company's servers and/or a Service Provider's server or it may be
          simply stored on your device.
        </p>
        <p>
          You can enable or disable access to this information at any time,
          through your Device settings.
        </p>
        <strong> Tracking Technologies and Cookies </strong>
        <p>
          We use Cookies and similar tracking technologies to track the activity
          on our Service and store certain information.
        </p>
        <p>
          A cookie is a small file placed on your Device. You can instruct your
          browser to refuse all Cookies or to indicate when a Cookie is being
          sent. However, if you do not accept Cookies, you may not be able to
          use some parts of our Service. Unless you have adjusted your browser
          setting so that it will refuse Cookies, our Service may use Cookies.
        </p>
        <strong> Use of Your Personal Data </strong>
        <p>The Company may use Personal Data for the following purposes:</p>
        <p>
          <strong>To provide and maintain our Service,</strong>including to
          monitor the usage of our Service.
        </p>
        <p>
          <strong>To manage Your Account: </strong>
          to manage your registration as a user of the Service. The Personal
          Data You provide can give you access to different functionalities of
          the Service that are available to You as a registered user.
        </p>
        <p></p>
        <p>
          <strong>For the performance of a contract:</strong> the development,
          compliance and undertaking of the purchase contract for the products,
          items or services You have purchased or of any other contract with us
          through the Service.
        </p>
        <p>
          <strong>To contact you:</strong>To contact you by email, telephone
          calls, SMS, or other equivalent forms of electronic communication,
          such as a mobile application's push notifications regarding updates or
          informative communications related to the functionalities, products or
          contracted services, including the security updates, when necessary or
          reasonable for their implementation.
        </p>
        <p>
          <strong>To manage your requests:</strong>To attend and manage your
          requests to us.
        </p>
        <p>
          <strong>For other purposes:</strong>We may use your information for
          other purposes, such as data analysis, identifying usage trends,
          determining the effectiveness of our promotional campaigns and to
          evaluate and improve our Service, products, services, marketing and
          your experience.
        </p>

        <h2>Retention of Your Personal Data</h2>
        <p>
          The Company will retain your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </p>
        <p>
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of our Service, or we are legally obligated
          to retain this data for longer time periods.
        </p>
        <h2>Transfer of Your Personal Data</h2>
        <p>
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of your state, province, country or other governmental jurisdiction
          where the data protection laws may differ than those from your
          jurisdiction.
        </p>
        <p>
          Your consent to this Privacy Policy followed by your submission of
          such information represents your agreement to that transfer.
        </p>
        <p>
          The Company will take all steps reasonably necessary to ensure that
          your data is treated securely and in accordance with this Privacy
          Policy and no transfer of your personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </p>
        <h2>Delete Your Personal Data</h2>
        <p>
          You have the right to delete or request that we assist in deleting the
          Personal Data that we have collected about you.
        </p>
        <p>
          Our Service may give you the ability to delete certain information
          about you from within the Service.
        </p>
        <p>
          You may update, amend, or delete your information at any time by
          signing in to your Account, if you have one, and visiting the account
          settings section that allows you to manage Your personal information.
          You may also contact Us to request access to, correct, or delete any
          personal information that you have provided to Us.
        </p>
        <p>
          Please note, however, that we may need to retain certain information
          when we have a legal obligation or lawful basis to do so.
        </p>
        <h2>Disclosure of Your Personal Data</h2>
        <strong> Business Transactions </strong>
        <p>
          If the Company is involved in a merger, acquisition or asset sale,
          your Personal Data may be transferred. We will provide notice before
          your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </p>
        <strong> Law enforcement </strong>
        <p>
          Under certain circumstances, the Company may be required to disclose
          your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </p>
        <strong> Other legal requirements </strong>
        <p>
          The Company may disclose your Personal Data in the good faith belief
          that such action is necessary to:
        </p>
        <ul>
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of the Company</li>
          <li>
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            Protect the personal safety of Users of the Service or the public
          </li>
          <li>Protect against legal liability</li>
        </ul>
        <h2>Security of Your Personal Data</h2>
        <p>
          The security of your Personal Data is important to us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While we strive to use commercially
          acceptable means to protect your Personal Data, We cannot guarantee
          its absolute security.
        </p>
        <strong> Children's Privacy </strong>
        <p>
          Our Service does not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from anyone
          under the age of 13. Links to Other Websites
        </p>
        <p>
          Our Service may contain links to other websites that are not operated
          by us. If you click on a third party link, you will be directed to
          that third party's site. We strongly advise you to review the Privacy
          Policy of every site you visit.
        </p>
        <p>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third-party sites or services.
        </p>
        <h2>Changes to this Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <p>
          We will let you know via email and/or a prominent notice on our
          Service, prior to the change becoming effective and update the "Last
          updated" date at the top of this Privacy Policy.
        </p>
        <p>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
        <strong> Contact Us </strong>
        <p>
          If you have any questions about this Privacy Policy please contact us.
        </p>
      </div>
    </div>
  );
}

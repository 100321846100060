import React from "react";
import { get } from "lodash";
import moment from "moment";
import AppConstants from "../../Helpers/AppConstants";

export default function PublicHolidayCell({ holiday }) {
  const { name, start_date, end_date, country } = holiday;
  const dateToMatch = moment(new Date()).format(AppConstants.defaultDateFormat);
  const isAvailed = moment(get(holiday, "start_date", "")).isSameOrBefore(
    dateToMatch
  );

  return (
    <div
      className="d-flex flex-wrap b_b_light"
      style={{ backgroundColor: isAvailed ? "#eeee" : "" }}
    >
      <div
        className="col-2 d-flex align-items-center justify-content-center"
        style={{ flexDirection: "column" }}
      >
        <i
          className="ri-sun-line"
          style={{ fontSize: "1.5rem", color: "#3caff6" }}
        />
        <p className="mb-1 text-capitalize" style={{ color: "#3caff6" }}>
          {get(country, "name", "")}
        </p>
      </div>
      <div className="col-8 p_font d-flex justify-content-center flex-column py-4">
        <h5 className="mb-0 fw-bold text-capitalize">{name}</h5>
        <p className="mb-1" style={{ color: "gray" }}>
          {`${moment(start_date).format("dddd")}, ${start_date} - ${moment(
            end_date
          ).format("dddd")}, ${end_date}`}
        </p>
      </div>
    </div>
  );
}

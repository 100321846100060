import React, { useState, useEffect } from "react";
import "./Login.css";
import TextInputField from "../../components/TextInputField";
import Spinner from "../../components/Spinner";
import AppConstants from "../../Helpers/AppConstants";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../Redux/reducers/AuthenticationReducer";
import { unwrapResult } from "@reduxjs/toolkit";
import { useTitleHandler } from "../../customHooks/useTitleHandler";
import {
  showSuccessToast,
  showFaliureToast,
  validatePassword,
} from "../../Utilities";
import { AppImages } from "../../Helpers/AppImages";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { Applogger } from "../../Helpers/Logger";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ResetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  useTitleHandler(t("dtChangePassword"));
  const { token } = useSelector((state) => state.AuthenticationReducer);
  const [params] = useSearchParams();
  const mode = params.get("mode");
  const location = useLocation();
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [userLoading, setUserLoading] = useState(false);
  const [resetPassToken, setResetPassToken] = useState("");

  useEffect(() => {
    getToken();
  }, []);
  const userToken = params.get("token");

  const getToken = () => {
    var token = "";
    var searchToken = location.search;
    var searchArray = searchToken.split("=");
    if (searchArray.length > 0) {
      token = searchArray[1];
    }
    setResetPassToken(userToken);
  };

  // useEffect(() => {
  //   if (token) {
  //     navigate(AppConstants.routes.dashboard);
  //   }
  // }, [token]);

  const resetPasswordHandler = () => {
    if (newPassword === "" || confirmNewPassword === "") {
      showFaliureToast(t("bothFieldsAreRequired"));
    } else if (confirmNewPassword !== newPassword) {
      showFaliureToast(t("passwordDoesNotMatch"));
    } else if (newPassword.length < 8 || confirmNewPassword.length < 8) {
      showFaliureToast(t("passwordRequirement"));
    } else if (!validatePassword(newPassword)) {
      showFaliureToast(t("passValidationMsg"));
    } else {
      setUserLoading(true);
      callResetPasswordAPI();
    }
  };

  const callResetPasswordAPI = () => {
    const body = {
      newPassword: newPassword,
      confirmNewPassword: confirmNewPassword,
      token: resetPassToken,
    };

    dispatch(resetPassword({ body: body }))
      .then(unwrapResult)
      .then((res) => {
        setUserLoading(false);
        showSuccessToast("Password changed successfully");
        navigate(AppConstants.routes.login);
      })
      .catch((err) => {
        setUserLoading(false);
        if (err.message === AppConstants.errorMessages.invalidEmail) {
          showFaliureToast("Invalid Token, Please contact your administrator");
        } else {
          showFaliureToast(err.message.toString());
        }
        Applogger("Error at resetPassword", err.message);
      });
  };

  return (
    <div>
      <div
        style={{ height: "100vh" }}
        className="wrapper login-bg d-flex align-items-center justify-content-center w-100"
      >
        {userLoading && <Spinner size={12} />}
        <div className="d-flex flex-column flex-wrap wrapper-2-cs">
          <div className="navbrand mb-3 mt-2">
            <img src={AppImages.speedyHrIconHd} alt="speedyhr" />
          </div>
          <h3 className="fw-bold text-info">{`${
            mode && mode == "create" ? "Create" : "Reset"
          } Password !`}</h3>
          <div>
            <p className="small mb-0 text-black-50">{t("enterNewPassword")}</p>
            <TextInputField
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required={false}
            />
            <p className="small mb-0 text-black-50">
              {t("confirmNewPassword")}
            </p>
            <TextInputField
              type="password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              required={false}
            />
            <div className="myFlex-cs">
              <button
                className="btn btn-primary-cs mt-2"
                onClick={() => resetPasswordHandler()}
              >
                {`${mode && mode == "create" ? "Create" : "Reset"}`}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";

export default function ViewOnGoogle({
  latlong,
  padding = "",
  addCss = "",
  showLatlong = true,
}) {
  const { t } = useTranslation();
  if (showLatlong) {
    return (
      <div
        className={`d-flex ${padding} ${
          addCss ? addCss : "justify-content-end"
        }`}
      >
        <Tooltip placement="left" title={t("viewOnMap")}>
          <span
            style={{
              fontSize: 12,
              color: "gray",
              alignSelf: "flex-end",
            }}
          >
            <a
              href={`https://maps.google.com/?q=${latlong.latitude},${latlong.longitude}`}
              target="_blank"
            >
              {`(Latitude: ${latlong.latitude}, Longitude: ${latlong.longitude})`}
            </a>
          </span>
        </Tooltip>
      </div>
    );
  } else {
    return (
      <a
        href={`https://maps.google.com/?q=${latlong.latitude},${latlong.longitude}`}
        target="_blank"
        title={t("viewOnMap")}
        className={`d-flex ${padding} ${
          addCss ? addCss : "justify-content-end"
        }`}
      >
        {t("viewOnMap")}
      </a>
    );
  }
}

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Classes } from "./classes";
import { Applogger } from "../../Helpers/Logger";
import { useNavigate } from "react-router-dom";
import { showFaliureToast, showSuccessToast } from "../../Utilities";
import { useTranslation } from "react-i18next";
import {
  deleteStaffingRequest,
  getEvents,
  getRotaShif,
  getViewStaffing,
  renameStaffing,
  updateStaffing,
} from "../../Redux/reducers/StaffingReducer";
import {
  UpdateStaffing,
  callUpdateStaffingAPI,
  StaffingRequest,
  filteredStaffingRequest,
} from "../../Redux/reducers/StaffingReducer";
import StaffingRenameModal from "../CustomModal/StaffingRenameModal";
import StaffingDeleteModal from "../CustomModal/StaffingDeleteModal";
import CustomModal from "../CustomModal/CustomModal";
import StaffingCard from "./StaffingCard";
import StaffingStatusCard from "./StaffingStatusCard";
import EmployeeStaffingCard from "./StaffingEmployeeCard";
import NoStaffingData from "./404Staffing";
import ViewModal from "./ViewModal";
import AppConstants from "../../Helpers/AppConstants";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";

export default function ActiveStaffing(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, role } = useSelector((state) => state.AuthenticationReducer);
  const { data, loading } = useSelector((state) => state.staffing);
  const { t } = useTranslation();
  const { lng } = useSelector((state) => state.languageReducer);

  const { exist: activeCreateStaffingBtn } = usePrevilagesExist(
    AppConstants.previlages.activeCreateStaffingBtn
  );
  const { exist: listStaffing } = usePrevilagesExist(
    AppConstants.previlages.listStaffing
  );

  const { exist: viewStaffing } = usePrevilagesExist(
    AppConstants.previlages.viewStaffing
  );
  const { exist: printStaffing } = usePrevilagesExist(
    AppConstants.previlages.printStaffing
  );

  const { exist: copyStaffing } = usePrevilagesExist(
    AppConstants.previlages.copyStaffing
  );
  const { exist: deleteStaffingPri } = usePrevilagesExist(
    AppConstants.previlages.deleteStaffing
  );
  const { exist: editStaffing } = usePrevilagesExist(
    AppConstants.previlages.editStaffing
  );

  const [showModal, setShowModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [isPublishedExpanded, setIsPublishedExpanded] = useState(false);
  const [isUnPublishedExpanded, setIsUnPublishedExpanded] = useState(false);
  const [publishedStaffing, setPublishedStaffing] = useState([]);
  const [unPublishedStaffing, setUnPublishedStaffing] = useState([]);
  const [showViewModal, setShowViewModal] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
  });

  const [currentStaff, setCurrentStaff] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentStaffInput, setCurrentStaffInput] = useState("");

  const [modalDetails, setModalDetails] = useState({
    modalTitle: "",
    modalDesciption: "",
    buttonTitle: "",
  });

  useEffect(() => {
    ManageStaffingData(data);
  }, [data, loading]);

  function CallData() {
    dispatch(StaffingRequest({ token, lng }))
      .then(unwrapResult)
      .then((response) => {
        Applogger("Response at StaffingRequest", response);
      })
      .catch((err) => {
        Applogger("Error at StaffingRequest", err.message);
      });
  }

  function getFilteredStaffing() {
    dispatch(filteredStaffingRequest({ token, dateRange, lng }))
      .then(unwrapResult)
      .then((response) => {
        if (response.data.length == 0) {
          showFaliureToast("Unable to find schedules with given date range");
        }

        ManageStaffingData(response.data);
      })
      .catch((err) => {
        Applogger("Error at filteredStaffingRequest", err.message);
      });
  }

  const handleModalItems = (isPublish) => {
    if (isPublish) {
      modalDetails.modalTitle =
        "Are you sure you want to unpublish this schedule?";
      modalDetails.modalDesciption =
        "This will hide my schedule from your employees. They will no longer be able to access it or see their shifts and absence conflicts.";
      modalDetails.buttonTitle = "UnPublish";
    } else {
      modalDetails.modalTitle =
        "Are you sure you want to publish this schedule?";
      modalDetails.modalDesciption =
        "This will hide my schedule from your employees. They will no longer be able to access it or see their shifts and absence conflicts.";
      modalDetails.buttonTitle = "Publish";
    }
    setModalDetails({ ...modalDetails });
  };

  const getEventsHandler = (id) => {
    dispatch(getEvents({ token, id: id ? id : "", lng }))
      .then(unwrapResult)
      .then(() => { })
      .catch((err) => {
        Applogger("Error at getEventsHandler", err.message);
      });
  };

  const ManageStaffingData = (staffings) => {
    const published = [];
    const unpublished = [];
    for (let i = 0; i < staffings.length; i++) {
      const element = staffings[i];
      if (element.status === "active") {
        if (element.is_publish === 0) {
          unpublished.push(element);
        } else if (element.is_publish === 1) {
          published.push(element);
        }
      }
    }
    setPublishedStaffing(published);
    setUnPublishedStaffing(unpublished);
  };

  const ChangeStaffingStatusOnServer = (id) => {
    dispatch(callUpdateStaffingAPI({ id: id, token: token, lng }))
      .then(unwrapResult)
      .then((response) => { })
      .catch((err) => {
        Applogger("Error at callUpdateStaffingAPI", err.message);
      });
  };

  function closeModal() {
    setShowModal(false);
  }

  function renameStaffingHandler(value) {
    setCurrentStaff(value);
    setShowRenameModal(true);
  }

  function ChangeStaffingStatus(staffing) {
    setShowModal(true);
    ChangeStaffingStatusOnServer(staffing.rota_id);
    dispatch(UpdateStaffing({ StaffingToUpdate: staffing }))
      .then(unwrapResult)
      .then(() => {
        setShowModal(false);
        getEventsHandler();
      })
      .catch(() => {
        setShowModal(false);
      });
  }

  function renameStaff(staff) {
    dispatch(renameStaffing({ token, id: staff.rota_id, name: staff.name }))
      .then(unwrapResult)
      .then(() => {
        showSuccessToast("Staffing Renamed Successfully");
        CallData();
        setShowRenameModal(false);
      })
      .catch(() => {
        showFaliureToast("Staffing Renamed failed");
      });
  }

  function deleteStaffing(staffing) {
    dispatch(deleteStaffingRequest({ token, id: staffing.rota_id }))
      .then(unwrapResult)
      .then(() => {
        showSuccessToast("Staffing Deleted Successfully");
        setShowDeleteModal(false);
        CallData();
      })
      .catch(() => {
        showFaliureToast("Staffing Deletion Failed");
      });
  }

  const rotaSearchHandler = (e) => {
    setCurrentStaffInput(e.target.value);

    const arrayToFilter = data;

    const paramValue = e.target.value.toLowerCase();
    const filteredItems = arrayToFilter.filter((item) =>
      item.name.toLocaleLowerCase().includes(paramValue)
    );
    const published = [];
    const unpublished = [];
    for (let i = 0; i < filteredItems.length; i++) {
      const element = filteredItems[i];
      if (element.status === "active") {
        if (element.is_publish === 0) {
          unpublished.push(element);
        } else if (element.is_publish === 1) {
          published.push(element);
        }
      }
    }
    setUnPublishedStaffing(unpublished);
    setPublishedStaffing(published);
  };

  function onDeleteClickHandler(value) {
    setCurrentStaff(value);
    setShowDeleteModal(true);
  }

  function copyStaffingClickHandler(staffing) {
    props.copyStaffing(staffing);
  }

  const onDateChangeHandler = (value, toSet) => {
    var selectedDate = value;
    if (toSet == "startDate") {
      setDateRange({ ...dateRange, startDate: selectedDate });
    } else if (toSet == "endDate") {
      setDateRange({ ...dateRange, endDate: selectedDate });
    }
  };

  const isButtonDisabled = () => {
    var disabled = true;
    if (dateRange.startDate == "" || dateRange.endDate == "") {
      disabled = true;
    } else {
      return false;
    }
    return disabled;
  };
  const onEditClickHandler = (currentStaff) => {
    setCurrentStaff(currentStaff);

    navigate(
      `${AppConstants.routes.staffingSheet}?schedule=${currentStaff.rota_id}`
    );

    // getRotaShiftHandler(currentStaff.rota_id);
  };

  // const getRotaShiftHandler = (id) => {
  //   dispatch(getRotaShif({ token, id, lng }))
  //     .then(unwrapResult)
  //     .then((res) => {
  //       Applogger("Response at getRotaShif", res);
  //     })
  //     .catch((err) => {
  //       Applogger("Error at getRotaShif", err);
  //     });
  // };

  const buttonTitle = () => {
    var title = t("search");

    if (!dateRange.startDate || !dateRange.endDate) {
      title = t("selectDate");
    }

    return title;
  };

  const handleClick = (title) => {
    if (title === "Submit") {
      getFilteredStaffing();
    } else {
      CallData();
      setDateRange({ startDate: "", endDate: "" });
    }
  };

  const onPrintClickHandler = (value) => {
    Applogger(value);
  };

  const onViewModalClickHandler = (currentStaffData) => {
    setCurrentStaff(currentStaffData);
    getViewStaffingData(currentStaffData.rota_id);
  };

  const getViewStaffingData = (id) => {
    dispatch(getViewStaffing({ token, id, lng }))
      .then(unwrapResult)
      .then((res) => {
        setShowViewModal(true);
        Applogger("Response at getViewStaffing", res);
      })
      .catch((err) => {
        showFaliureToast("View Staffing Detail Request Failed");
        Applogger("Error at getViewStaffing", err);
      });
  };

  return (
    <div className="animate__animated animate__fadeIn">
      <StaffingCard className={Classes.ActiveRotaContainer}>
        <ViewModal
          modalStatus={showViewModal}
          onCancel={() => setShowViewModal(false)}
        />
        <div className="col-md-9 col-sm-12">
          <div className="myFlex-cs">
            <h5 className="fw-bold mb-0 ">{t("activeStaffing")}</h5>
            {activeCreateStaffingBtn && (
              <button onClick={props.onClick} className="btn btn-sm-dark ml-2">
                {t("createNewSchedule")}
              </button>
            )}
          </div>

          <br />

          <div className="flex-end-column">
            {!isButtonDisabled() && (
              <button
                className={"text-primary"}
                onClick={() => handleClick("Cancel")}
              >
                {t("viewAll")}
              </button>
            )}
            {listStaffing && (
              <>
                <div className="myFlex-cs w-100 gap-4 align-items-center flex-wrap-sm">
                  <input
                    value={dateRange.startDate}
                    onChange={(e) =>
                      onDateChangeHandler(e.target.value, "startDate")
                    }
                    type={"date"}
                    placeholder={t("startDate")}
                    className="filter-form"
                  />
                  <span>{t("to")}</span>
                  <input
                    value={dateRange.endDate}
                    onChange={(e) =>
                      onDateChangeHandler(e.target.value, "endDate")
                    }
                    type={"date"}
                    placeholder="End Date"
                    className="filter-form"
                  />
                  <button
                    className={`btn btn-primary-cs staffing-btn-cs-width ${isButtonDisabled() ? "disabled" : ""
                      }`}
                    onClick={() => handleClick(buttonTitle())}
                  >
                    {buttonTitle()}
                  </button>
                </div>
                <div className="myFlex-cs w-100 gap-4 align-items-center flex-wrap-sm">
                  <input
                    place
                    value={currentStaffInput}
                    onChange={rotaSearchHandler}
                    placeholder={t("scheduleName")}
                    type={"search"}
                    className="filter-form"
                  />
                </div>
              </>
            )}
          </div>
          {showModal && (
            <CustomModal
              showModal={showModal}
              closeModal={() => closeModal()}
              modalTitle={modalDetails.modalTitle}
              modalDesciption={modalDetails.modalDesciption}
              buttonTitle={modalDetails.buttonTitle}
            />
          )}
          <div className="myFlex-cs pt-2">
            <div>
              <h5 className="fw-bold mb-0">
                {t("publishedStaffing")}
                <span className="badge mx-2">{publishedStaffing.length}</span>
              </h5>
            </div>
            <button className="btn shadow-none">
              <a
                className="text-primary"
                data-mdb-toggle="collapse"
                href="#collapseExample"
                role="button"
                aria-controls="collapseExample"
                aria-expanded={isPublishedExpanded.toString()}
                onClick={() => setIsPublishedExpanded(!isPublishedExpanded)}
              >
                {isPublishedExpanded ? t("showLess") : t("showMore")}
              </a>
            </button>
          </div>

          <StaffingRenameModal
            renameStaffing={renameStaff}
            currentStaff={currentStaff}
            modalStatus={showRenameModal}
            onCancel={() => setShowRenameModal(false)}
          />
          <StaffingDeleteModal
            deleteStaffing={deleteStaffing}
            currentStaff={currentStaff}
            modalStatus={showDeleteModal}
            onCancel={() => setShowDeleteModal(false)}
          />
          <div
            className={`row ${publishedStaffing.length > 3
                ? "rbc-row-content-scroll-container"
                : ""
              }`}
          >
            <div className="collapse mt-3" id="collapseExample">
              {publishedStaffing.length > 0 ? (
                publishedStaffing.map((value, index) => {
                  return (
                    <div key={index} onClick={() => handleModalItems(true)}>
                      <StaffingStatusCard
                        enableView={viewStaffing}
                        enableChange={listStaffing}
                        enablePrint={printStaffing}
                        enableCopy={copyStaffing}
                        enableDelete={deleteStaffingPri}
                        enableEdit={editStaffing}
                        onEditClick={() => onEditClickHandler(value)}
                        onViewClick={() => onViewModalClickHandler(value)}
                        onModalClick={() => ChangeStaffingStatus(value)}
                        copyClick={() => copyStaffingClickHandler(value)}
                        onPrintClick={() => onPrintClickHandler(value)}
                        onRenameClick={() => renameStaffingHandler(value)}
                        onDeleteClick={() => onDeleteClickHandler(value)}
                        ispublished={value.is_publish}
                        shift={value.name}
                        users={value.users}
                        days={value.days}
                        Day={value.start_date}
                        currentStaff={value}
                        duration={value.duration}
                        employeeCount={value.employeeCount}
                        modalButtonTitle={t("unpublish")}
                        modalButtonIcon={"ri-indeterminate-circle-line"}
                      />
                    </div>
                  );
                })
              ) : (
                <NoStaffingData />
              )}
            </div>
          </div>
          <div className="myFlex-cs pt-2">
            <div>
              <h5 className="fw-bold mb-0">
                {t("unpublishedStaffing")}
                <span className="badge mx-2">{unPublishedStaffing.length}</span>
              </h5>
            </div>
            <button className="btn shadow-none">
              <a
                className="text-primary"
                data-mdb-toggle="collapse"
                href="#collapseExample2"
                role="button"
                // aria-expanded="false"
                aria-controls="collapseExample"
                aria-expanded={isUnPublishedExpanded.toString()}
                onClick={() => setIsUnPublishedExpanded(!isUnPublishedExpanded)}
              >
                {isUnPublishedExpanded ? t("showLess") : t("showMore")}
              </a>
            </button>
          </div>
          <div
            className={`row ${unPublishedStaffing.length > 3
                ? "rbc-row-content-scroll-container"
                : ""
              }`}
          >
            <div className="collapse mt-3 " id="collapseExample2">
              {unPublishedStaffing.map((value, index) => {
                return (
                  <div key={index} onClick={() => handleModalItems(false)}>
                    <StaffingStatusCard
                      enableView={viewStaffing}
                      enableChange={listStaffing}
                      enablePrint={printStaffing}
                      enableCopy={copyStaffing}
                      enableDelete={deleteStaffingPri}
                      enableEdit={editStaffing}
                      onEditClick={() => onEditClickHandler(value)}
                      onViewClick={() => onViewModalClickHandler(value)}
                      onModalClick={() => ChangeStaffingStatus(value)}
                      copyClick={() => copyStaffingClickHandler(value)}
                      onPrintClick={() => onPrintClickHandler(value)}
                      onRenameClick={() => renameStaffingHandler(value)}
                      onDeleteClick={() => onDeleteClickHandler(value)}
                      ispublished={value.is_publish}
                      shift={value.name}
                      days={value.days}
                      Day={value.start_date}
                      users={value.users}
                      duration={value.duration}
                      employeeCount={value.employeeCount}
                      modalButtonTitle={t("publish")}
                      modalButtonIcon={"ri-add-circle-line"}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* <EmployeeStaffingCard /> */}
      </StaffingCard>
    </div>
  );
}

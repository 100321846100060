import React from "react";
import { useTranslation } from "react-i18next";
import { formattedDate } from "../../../../Utilities";

export default function EmpDetailsField({
  title = "",
  placeholder = "",
  type = "text",
  value = "",
  onChange = () => {},
  validate = false,
  maxLength = 40,
  isRequired = false,
}) {
  const { t } = useTranslation();

  const isFieldDateType = () => {
    return type == "date";
  };

  return (
    <div className="col-lg-5 col-md-12 col-sm-12">
      <div className="d-flex flex-wrap-sm align-items-center">
        <label className="col-sm-12 col-md-4 mb-0">
          {title}
          {isRequired && <span className="text-danger">*</span>}
        </label>
        <div className="col-md-8 col-sm-12">
          <input
            maxLength={maxLength}
            className={`filter-form`}
            placeholder={placeholder}
            type={type}
            value={isFieldDateType() ? formattedDate(value) : value}
            onChange={onChange}
          />
          {validate && (
            <span style={{ color: "#3db0f7" }}>{t("validatingEmail")}</span>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Applogger } from "../../Helpers/Logger";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "antd";
import { get } from "lodash";
import {
  deleteShiftReq,
  shiftHandlerReq,
  getEvents,
} from "../../Redux/reducers/StaffingReducer";
import {
  formattedDateForAPI,
  getTimeDifference,
  showFaliureToast,
  showSuccessToast,
} from "../../Utilities";
import NoStaffingData from "./404Staffing";
import ShiftCard from "./ShiftCard";
import CreateShift from "./CreateShift";
import moment from "moment";
import AppConstants from "../../Helpers/AppConstants";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";

export default function Shifts({ addStyles, currentUserId = null }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { token, role, user } = useSelector(
    (state) => state.AuthenticationReducer
  );
  const { lng } = useSelector((state) => state.languageReducer);
  const { shifts } = useSelector((state) => state.staffing);

  const { exist: updateDeleteShift } = usePrevilagesExist(
    AppConstants.previlages.updateDeleteShift
  );

  const [currentShiftData, setCurrentShiftData] = useState({
    shiftDay: "",
    shiftTimeStart: "",
    shiftTimeEnd: "",
    breakDuration: "",
    notes: "",
    shiftId: "",
    rotaId: "",
    userId: "",
    name: "",
  });

  const [showShiftModal, setShowShiftModal] = useState(false);

  useEffect(() => {
    getShiftsList();
  }, []);

  const getFiltteredShifts = () => {
    // console.log(" ==== shifts  === ", shifts);
    let finalShifts = [...shifts];
    if (currentUserId) {
      finalShifts = finalShifts.filter((shift) => {
        return get(shift, "user_id", "") == currentUserId;
      });
    } else {
      finalShifts = finalShifts.filter((shift) => {
        return get(shift, "user_id", "") == get(user, "user_id", "");
      });
    }

    finalShifts = finalShifts.filter((shift) => {
      return moment.unix(get(shift, "shift_day", "")).isSameOrAfter(moment());
    });
    return finalShifts;
  };

  const getShiftsList = () => {
    dispatch(getEvents({ token, lng }))
      .then(unwrapResult)
      .then((response) => {})
      .catch((err) => {
        Applogger("Error at getEvents", err.message);
      });
  };

  const deleteShiftRequestHandler = () => {
    try {
      dispatch(deleteShiftReq({ token: token, id: currentShiftData.shiftId }))
        .then(unwrapResult)
        .then((res) => {
          Applogger("Response at deleteShiftReq ", res);
          setShowShiftModal(false);
          getShiftsList();
        })
        .catch((err) => {
          Applogger("Error at deleteShiftReq catch", err);
        });
    } catch (err) {
      Applogger("Error at deleteShiftRequestHandler catch", err);
    }
  };

  const showShiftDetails = (shiftData) => {
    currentShiftData.shiftDay = moment
      .unix(shiftData.shift_day)
      .format(AppConstants.defaultDateFormat);
    currentShiftData.userId = shiftData.user_id;
    currentShiftData.rotaId = shiftData.rota_id;
    currentShiftData.name = shiftData.name;
    currentShiftData.shiftTimeStart = moment
      .unix(shiftData.start_time)
      .zone(0)
      .format(AppConstants.timeFormat);
    currentShiftData.shiftTimeEnd = moment
      .unix(shiftData.end_time)
      .zone(0)
      .format(AppConstants.timeFormat);
    currentShiftData.breakDuration = shiftData.break_duration;
    currentShiftData.notes = shiftData.notes;
    currentShiftData.shiftId = shiftData.shift_id;
    setCurrentShiftData({ ...currentShiftData });
    setShowShiftModal(true);
  };

  const updateShiftHandler = () => {
    if (
      currentShiftData.breakDuration !== "" &&
      currentShiftData.shiftTimeStart !== "" &&
      currentShiftData.shiftTimeEnd !== ""
    ) {
      const timeDifference = getTimeDifference(
        currentShiftData.shiftTimeStart,
        currentShiftData.shiftTimeEnd
      );
      const finalShiftData = {
        ...currentShiftData,
        shiftDay: formattedDateForAPI(currentShiftData.shiftDay),
      };
      if (timeDifference > parseInt(currentShiftData.breakDuration)) {
        dispatch(
          shiftHandlerReq({
            token,
            shiftData: finalShiftData,
            userId: currentShiftData.userId,
            rotaId: currentShiftData.rotaId,
          })
        )
          .then(unwrapResult)
          .then((res) => {
            Applogger("Response at shiftHandlerReq", res);
            showSuccessToast(res ?? "Shift Updated Successfully");
            getShiftsList();
            setShowShiftModal(false);
          })
          .catch((err) => {
            Applogger("Error at shiftHandlerReq", err);
            showFaliureToast(err.message ?? "Failed to Update Shift");
          });
      } else {
        showFaliureToast("Break Duration should be less than shift Duration");
      }
    } else {
      showFaliureToast("Please Fill All Data");
    }
  };

  const ModalFooter = () => {
    return (
      <div
        className="d-flex"
        style={{ justifyContent: "flex-end", alignContent: "flex-end" }}
      >
        {currentShiftData.shiftId !== "" && updateDeleteShift && (
          <Button onClick={deleteShiftRequestHandler} danger>
            {t("delete")}
          </Button>
        )}
        <Button onClick={() => setShowShiftModal(false)}>{t("cancel")}</Button>
        {updateDeleteShift && (
          <Button onClick={updateShiftHandler} type="primary">
            {t("update")}
          </Button>
        )}
      </div>
    );
  };

  const headerItems = [
    {
      title: t("assignee"),
    },
    {
      title: t("shiftDay"),
    },
    {
      title: t("shiftStart"),
    },
    {
      title: t("shiftEnd"),
    },
    {
      title: t("staffing"),
    },
  ];

  return (
    <div className="animate__animated animate__fadeIn">
      <div className="row sec-cs-primary py-4">
        <Modal
          centered
          width={900}
          footer={<ModalFooter />}
          title={`${currentShiftData && currentShiftData.name}`}
          visible={showShiftModal}
          onCancel={() => setShowShiftModal(false)}
        >
          <CreateShift
            currentShiftData={currentShiftData}
            role={role}
            setShiftData={setCurrentShiftData}
          />
        </Modal>
        <div className="col-lg-12 col-sm-12">
          <div className="d-flex">
            <h5 className="fw-bold overtime_subhead lexen-font">
              {t("shifts")}
            </h5>
          </div>
          <div
            className="animate__animated animate__backInUp"
            style={addStyles}
          >
            <div
              className="shiftContainer p-3 mb-2"
              style={{ backgroundColor: "#00aff0" }}
            >
              {headerItems.map((headerItem, index) => {
                const { title } = headerItem;
                return (
                  <div
                    key={index}
                    className={`shiftStart ${index == 0 ? "col-3" : "col-2"}`}
                  >
                    <h1
                      className="h6 fw-bold mb-0 w-100 lexen-font"
                      style={{ color: "white" }}
                    >
                      {title}
                    </h1>
                  </div>
                );
              })}
            </div>
            {getFiltteredShifts().length <= 0 ? (
              <div>
                <h4 className="fw-bold my-3 lexen-font">
                  {t("noShiftsToSeeHereYet")}
                </h4>
                <NoStaffingData />
              </div>
            ) : (
              getFiltteredShifts().map((val, index) => {
                return (
                  <ShiftCard
                    key={index}
                    showShiftDetails={showShiftDetails}
                    cardInfo={val}
                    index={index}
                  />
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

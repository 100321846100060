import React from "react";
import { useTranslation } from "react-i18next";
export default function Index() {
  const { t } = useTranslation();
  return (
    <div>
      <div className="alert alert-success" role="alert">
        {t("ourCallService")}
        <strong>0844 892 3927 ({t("option")} 2)</strong>
      </div>
      <button className="btn btn-sm-dark me-2 btn-block-sm mb-2">
        {t("generateReport")} VaccTrak
      </button>
      <button className="btn-lg-light btn btn-primary-outline-cs btn-block-sm font-weight-bold fs-16 mb-2">
        {t("goto")} VaccTrak
      </button>

      <div className="accordion mb-3" id="accordionPanelsStayOpenExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#panelsStayOpen-collapsetwo"
              aria-expanded="true"
              aria-controls="panelsStayOpen-collapsetwo"
            >
              <h5 className="text-black mb-0">
                {t("vaccinated")}
                <span className="badge m-1 circle">0</span>
              </h5>
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapsetwo"
            className="accordion-collapse border-0 collapse show"
            aria-labelledby="headingOne"
          >
            <div className="accordion-body">
              <table className="table table-borderless">
                <thead className="border-0">
                  <tr>
                    <th scope="col">{t("name")}</th>
                    <th scope="col">{t("vaccineOne")}</th>
                    <th scope="col">{t("vaccineTwo")}</th>
                    <th scope="col">{t("booster")}</th>
                    <th scope="col">
                      VaccTrak {t("pass")}
                      <a
                        href="#"
                        data-mdb-toggle="modal"
                        data-mdb-target="#infoModal"
                      >
                        <i className="ri-information-line info-i" />
                      </a>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row"></th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="infoModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header bg-sec-cs text-white">
                <h5 className="modal-title" id="exampleModalLabel">
                  <i className="ri-information-line info-i-light" />
                  {t("vaccTrakPass")}
                </h5>
                <button
                  type="button"
                  className="btn-close text-light"
                  data-mdb-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body modal-cs">
                <p>{t("employeeVaccination")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="accordion" id="accordionPanelsStayOpenExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#panelsStayOpen-collapsethree"
              aria-expanded="true"
              aria-controls="panelsStayOpen-collapsethree"
            >
              <h5 className="text-black mb-0">
                {t("employeeVaccinationStatus")}
                <span className="badge m-1 circle">1</span>
              </h5>
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapsethree"
            className="accordion-collapse border-0 collapse"
            aria-labelledby="headingOne"
          >
            <div className="accordion-body">
              <table className="table table-borderless">
                <thead>
                  <tr className="border-bottom-light">
                    <th scope="col">{t("name")}</th>
                    <th scope="col">{t("vaccineOne")}</th>
                    <th scope="col">{t("vaccineTwo")}</th>
                    <th scope="col">{t("booster")}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="tbl-row-cs border-bottom-light postition-relative">
                    <td scope="row">
                      <div className="profile-sm d-inline-block">
                        <b>KR</b>
                        <span className="position-absolute p-2 translate-25 bg-danger border border-light rounded-circle">
                          <span className="visually-hidden">{t("online")}</span>
                        </span>
                      </div>
                      <span className="ms-4">Kevin Ross</span>
                    </td>
                    <td className="align-middle">{t("none")}</td>
                    <td className="align-middle">{t("none")}</td>
                    <td className="align-middle">{t("none")}</td>
                    <td className="align-middle">
                      <a
                        href="#"
                        data-mdb-toggle="modal"
                        data-mdb-target="#editprofile"
                        className="text-primary"
                      >
                        <i className="ri-edit-line" />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                className="modal fade"
                id="editprofile"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header bg-sec-cs text-white">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Kevin Ross
                      </h5>
                      <button
                        type="button"
                        className="btn-close text-light"
                        data-mdb-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body modal-cs">
                      <form className="mt-2 d-flex justify-content-between">
                        <label className="fs-6">
                          COVID-19 {t("vaccinated")}?
                        </label>
                        <select className="filter-form-sm">
                          <option className="fw-bold">{t("yes")}</option>
                          <option className="fw-bold">{t("no")}</option>
                          <option className="fw-bold">{t("pending")}</option>
                          <option className="fw-bold">{t("exempt")}</option>
                          <option className="fw-bold">
                            {t("preferNotSay")}
                          </option>
                          <option className="fw-bold">
                            {t("notSpecified")}
                          </option>
                        </select>
                      </form>
                      <form className="mt-2">
                        <label className="fs-6 fw-light">{t("addNotes")}</label>
                        <br />
                        <textarea className="filter-form-t-area"></textarea>
                        <br />
                        <small className="text-black-50">
                          {t("employeeNotes")}
                        </small>
                        <br />
                        <button className="btn btn-sm-dark mt-1">
                          {t("save")}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const sensitiveInfoObj = {
  bacs_reference: "",
  bank_account_name: "",
  bank_account_number: "",
  bank_account_type: "",
  bank_address_1: "",
  bank_address_2: "",
  bank_address_3: "",
  bank_address_4: "",
  bank_address_5: "",
  bank_name: "",
  bank_post_code: "",
  building_soc_number: "",
  driving_licence_number: "",
  licence_expiry_date: "",
  // ni_category: "",
  ni_number: "",
  non_uk_worker: 0,
  right_to_work_confirm_status: 0,
  right_to_work_document_expiry_date: "",
  right_to_work_document_type: "",
  right_to_work_document_verified: 0,
  right_to_work_reference: "",
  sort_code: "",
  tax_code: "",
  // wk1mth1_basis: 1,
};

export const salaryInfoObj = {
  payslip_password: "",
  payslip_email_address: "",
  payroll_number: "",
  gross_salary: "",
  payment_frequency: "",
  is_pension: "1",
  is_opted_out: "0",
  employee_contribution: 0,
  employer_contribution: 0,
  enrollment_date: "",
  salary_per_period: "",
  contracted_hours: "",
  contracted_hours_per_period: "",
};

export const contractInfoObj = {
  contract_date: "",
  date_directorship_began: "",
  director_status: "",
  employee_reference: "",
  employment_type: "",
  is_probation: "0",
  is_terminated: "0",
  notice_period_type: "",
  notice_period_value: "",
  probation_notice_period_type: "",
  probation_notice_period_value: "",
  probation_period_end_date: "",
  probation_period_type: "",
  probation_period_value: "",
  reason: "",
  termination_date: "",
  auto_enrolment_exclusion_reason: "",
  first_day_of_civilian_employment: "",
  termination_reason: "",
  works_reference: "",
};

export const personalInfoObj = {
  address_1: "",
  address_2: "",
  address_3: "",
  address_4: "",
  address_5: "",
  country: "1",
  date_of_birth: "",
  first_name: "",
  gender: "",
  initial: "",
  last_name: "",
  marital_status: "",
  middle_name: "",
  post_code: "",
  profile_image: "",
  title: "",
};

export const emergencyContactObj = {
  name: "",
  home_phone: "",
  work_phone: "",
  mobile_phone: "",
  address_one: "",
  address_two: "",
  address_three: "",
  city: "",
  state: "",
  country: "",
  postcode: "",
  relationship: "",
  permission: "0",
  primary: "0",
  id: null,
};

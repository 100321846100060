import { APIConstants } from "../../Config/ApiConstants";
import EventResource from "../../Config/EventResource";
import { getHeaders } from "../../Helpers/commonFunc";

function getDashboardData(payload) {
  return EventResource.get(
    `${APIConstants.Dashboard}?lng=${payload.lng}`,
    getHeaders(payload.token));
}

function getDashboardAbsenceReport(payload) {
  return EventResource.get(
    `${APIConstants.DashboardAbsence}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

function getDashboardAbsenceSummaryReport(payload) {
  return EventResource.get(
    `${APIConstants.DashboardAbsence}/summary?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

function getDashboardWorkingStatus(payload) {
  return EventResource.get(
    `${APIConstants.DashboardAbsence}/working/status?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

function postConsultation(payload) {
  const consulationData = new FormData();
  const { comment, number, email } = payload.consultationData;
  consulationData.append("email", email);
  consulationData.append("phone", number);
  consulationData.append("comments", comment);
  return EventResource.post(APIConstants.postConsultation, consulationData);
}
export const DashboardApiServices = {
  getDashboardData,
  getDashboardAbsenceReport,
  getDashboardAbsenceSummaryReport,
  getDashboardWorkingStatus,
  postConsultation,
};

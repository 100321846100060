import React, { useState, useEffect } from "react";
import { Applogger } from "../../Helpers/Logger";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showFaliureToast, showSuccessToast } from "../../Utilities";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  UpdateStaffing,
  deleteStaffingRequest,
  callUpdateStaffingAPI,
  renameStaffing,
  StaffingRequest,
  filteredStaffingRequest,
  getViewStaffing,
} from "../../Redux/reducers/StaffingReducer";
import StaffingDeleteModal from "../CustomModal/StaffingDeleteModal";
import StaffingRenameModal from "../CustomModal/StaffingRenameModal";
import StaffingStatusCard from "./StaffingStatusCard";
import EmployeeStaffingCard from "./StaffingEmployeeCard";
import ViewModal from "./ViewModal";
import AppConstants from "../../Helpers/AppConstants";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";

export default function OlderStaffing(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lng } = useSelector((state) => state.languageReducer);
  const { data } = useSelector((state) => state.staffing);
  const { token } = useSelector((state) => state.AuthenticationReducer);

  const { exist: activeCreateStaffingBtn } = usePrevilagesExist(
    AppConstants.previlages.activeCreateStaffingBtn
  );
  const { exist: listStaffing } = usePrevilagesExist(
    AppConstants.previlages.listStaffing
  );
  const { exist: viewStaffing } = usePrevilagesExist(
    AppConstants.previlages.viewStaffing
  );
  const { exist: printStaffing } = usePrevilagesExist(
    AppConstants.previlages.printStaffing
  );
  const { exist: copyStaffing } = usePrevilagesExist(
    AppConstants.previlages.copyStaffing
  );
  const { exist: deleteStaffingPri } = usePrevilagesExist(
    AppConstants.previlages.deleteStaffing
  );
  const { exist: statusChangeStaffing } = usePrevilagesExist(
    AppConstants.previlages.statusChangeStaffing
  );

  const { t } = useTranslation();
  const [publishedStaffing, setPublishedStaffing] = useState([]);
  const [unPublishedStaffing, setUnPublishedStaffing] = useState([]);
  const [isPublishedExpanded, setIsPublishedExpanded] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [isUnPublishedExpanded, setIsUnPublishedExpanded] = useState(true);
  const [currentStaff, setCurrentStaff] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [currentStaffInput, setCurrentStaffInput] = useState("");
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
  });
  useEffect(() => {
    ManageStaffingData();
  }, [data]);

  const ChangeStaffingStatusOnServer = (id) => {
    dispatch(callUpdateStaffingAPI({ id: id, token: token, lng }))
      .then(unwrapResult)
      .then(() => { })
      .catch((err) => {
        Applogger("Error at callUpdateStaffingAPI", err.message);
      });
  };
  function CallData() {
    dispatch(StaffingRequest({ token, lng }))
      .then(unwrapResult)
      .then(() => { })
      .catch((err) => {
        Applogger("Error at StaffingRequest", err.message);
      });
  }
  function ChangeStaffingStatus(staffing) {
    ChangeStaffingStatusOnServer(staffing.rota_id);
    dispatch(UpdateStaffing({ StaffingToUpdate: staffing }))
      .then(unwrapResult)
      .then(() => { })
      .catch((err) => {
        Applogger(
          "Error at ChangeStaffingStatus + UpdateStaffing",
          err.message
        );
      });
  }

  const rotaSearchHandler = (e) => {
    setCurrentStaffInput(e.target.value);

    const arrayToFilter = data;

    const paramValue = e.target.value.toLowerCase();
    const filteredItems = arrayToFilter.filter((item) =>
      item.name.toLocaleLowerCase().includes(paramValue)
    );
    const published = [];
    const unpublished = [];
    for (let i = 0; i < filteredItems.length; i++) {
      const element = filteredItems[i];
      if (element.status === "inactive") {
        if (element.is_publish === 0) {
          unpublished.push(element);
        } else if (element.is_publish === 1) {
          published.push(element);
        }
      }
    }
    setUnPublishedStaffing(unpublished);
    setPublishedStaffing(published);
  };
  function renameStaff(staff) {
    dispatch(renameStaffing({ token, id: staff.rota_id, name: staff.name }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at renameStaffing", res);
        showSuccessToast("Staffing Renamed Successfully");
        CallData();
        setShowRenameModal(false);
      })
      .catch((err) => {
        Applogger("Error at renameStaffing", err);
        showFaliureToast("Staffing Renamed failed");
      });
  }

  function renameStaffingHandler(value) {
    setCurrentStaff(value);
    setShowRenameModal(true);
  }

  function ManageStaffingData() {
    const published = [];
    const unpublished = [];
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      if (element.status === "inactive") {
        if (element.is_publish === 0) {
          unpublished.push(element);
        } else if (element.is_publish === 1) {
          published.push(element);
        }
      }
    }

    setPublishedStaffing(published);
    setUnPublishedStaffing(unpublished);
  }

  function deleteStaffing(staffing) {
    dispatch(deleteStaffingRequest({ token, id: staffing.rota_id }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at deleteStaffingRequest", res);
        showSuccessToast("Staffing Deleted Successfully");
        setShowDeleteModal(false);
        CallData();
      })
      .catch((err) => {
        Applogger("Error at deleteStaffingRequest", err);
        showFaliureToast("Staffing Deletion Failed");
      });
  }

  function copyStaffingClickHandler(staffing) {
    props.copyStaffing(staffing);
  }

  function onDeleteClickHandler(value) {
    setCurrentStaff(value);
    setShowDeleteModal(true);
  }
  const onDateChangeHandler = (value, toSet) => {
    var selectedDate = value;
    if (toSet == "startDate") {
      setDateRange({ ...dateRange, startDate: selectedDate });
    } else if (toSet == "endDate") {
      setDateRange({ ...dateRange, endDate: selectedDate });
    }
  };

  const isButtonDisabled = () => {
    var disabled = true;
    if (dateRange.startDate == "" || dateRange.endDate == "") {
      disabled = true;
    } else {
      return false;
    }
    return disabled;
  };
  const onEditClickHandler = (currentStaff) => {
    setCurrentStaff(currentStaff);
    Applogger("Called onEditClickHandler");
    navigate(
      `${AppConstants.routes.staffingSheet}?schedule=${currentStaff.rota_id}`
    );
  };

  const buttonTitle = () => {
    var title = t("search");

    if (!dateRange.startDate || !dateRange.endDate) {
      title = t("selectDate");
    }

    return title;
  };

  function getFilteredStaffing() {
    dispatch(filteredStaffingRequest({ token, dateRange, lng }))
      .then(unwrapResult)
      .then((response) => {
        if (response.data.length == 0) {
          showFaliureToast("Unable to find schedule with given date range");
        }
        ManageStaffingData(response.data);
      })
      .catch((err) => {
        Applogger("Error at filteredStaffingRequest", err.message);
      });
  }

  const handleClick = (title) => {
    if (title === "Submit") {
      getFilteredStaffing();
    } else {
      CallData();
      setDateRange({ startDate: "", endDate: "" });
    }
  };

  // const onPrintClickHandler = (value) => {};

  const onViewModalClickHandler = (currentStaffData) => {
    setCurrentStaff(currentStaffData);
    getViewStaffingData(currentStaffData.rota_id);
  };

  const getViewStaffingData = (id) => {
    dispatch(getViewStaffing({ token, id, lng }))
      .then(unwrapResult)
      .then((res) => {
        setShowViewModal(true);
        Applogger("Response at getViewStaffing", res);
      })
      .catch((err) => {
        showFaliureToast("View Staffing Detail Request Failed");
        Applogger("Error at getViewStaffing", err);
      });
  };

  // const handleCreateShiftHandler = (data) => {
  //   dispatch(
  //     updateStaffing({ token, currentStaff: data, id: currentStaff.rota_id })
  //   )
  //     .then(unwrapResult)
  //     .then((res) => {
  //       if (res.data == "Rota edited sucessfully") {
  //         showSuccessToast("Rota edited sucessfully");
  //         CallData();
  //         setShowEditStaffingModal(false);
  //       }
  //     })
  //     .catch((err) => {
  //       showFaliureToast("Rota Edit Failed");
  //     });
  // };

  // const handleCancel = () => {
  //   Applogger("Called handleCancel");
  //   setShowEditStaffingModal(false);
  //   setCurrentShiftData(null);
  //   setCurrentStaff(null);
  // };

  return (
    <div className="animate__animated animate__fadeIn">
      {/* <EditStaffModal
        createShift={handleCreateShiftHandler}
        currentShiftData={currentShiftData}
        currentStaffingId={currentStaff && currentStaff.rota_id}
        currentStaff={currentStaff}
        editStaffingMode={true}
        onCancel={() => handleCancel()}
        modalStatus={showEditStaffingModal}
        employees={allEmployees.length > 0 ? allEmployees : []}
      /> */}
      <ViewModal
        modalStatus={showViewModal}
        onCancel={() => setShowViewModal(false)}
      />

      <div className="row sec-cs-primary py-4">
        <div className="col-md-9">
          <div className="myFlex-cs flex-wrap">
            <h5 className="fw-bold">{t("olderStaffing")}</h5>
            {activeCreateStaffingBtn && (
              <button onClick={props.onClick} className="btn btn-sm-dark ml-2">
                {t("createNewSchedule")}
              </button>
            )}
          </div>
          <div className="flex-end-column">
            {!isButtonDisabled() && (
              <button
                className={"text-primary"}
                onClick={() => handleClick("Cancel")}
              >
                {t("viewAll")}
              </button>
            )}
            <br />
            {listStaffing && (
              <>
                <div className="myFlex-cs w-100 gap-4 align-items-center flex-wrap-sm">
                  <input
                    value={dateRange.startDate}
                    onChange={(e) =>
                      onDateChangeHandler(e.target.value, "startDate")
                    }
                    type={"date"}
                    placeholder={t("startDate")}
                    className="filter-form"
                  />
                  <span>{t("to")}</span>
                  <input
                    value={dateRange.endDate}
                    onChange={(e) =>
                      onDateChangeHandler(e.target.value, "endDate")
                    }
                    type={"date"}
                    placeholder="End Date"
                    className="filter-form"
                  />
                  <button
                    className={`btn btn-primary-cs staffing-btn-cs-width ${isButtonDisabled() ? "disabled" : ""
                      }`}
                    onClick={() => handleClick(buttonTitle())}
                  >
                    {buttonTitle()}
                  </button>
                </div>
                <div className="myFlex-cs w-100 gap-4 align-items-center flex-wrap-sm">
                  <input
                    place
                    value={currentStaffInput}
                    onChange={rotaSearchHandler}
                    placeholder={t("scheduleName")}
                    type={"search"}
                    className="filter-form"
                  />
                </div>
              </>
            )}
          </div>

          <div className="myFlex-cs flex-wrap pt-2">
            <div>
              <h6 className="fw-bold mb-0">
                {t("publishedStaffing")}{" "}
                <span className="badge mx-2">{publishedStaffing.length}</span>
              </h6>
            </div>
            <button
              className="text-primary btn shadow-none"
              data-mdb-toggle="collapse"
              href="#collapseExample"
              role="button"
              // aria-expanded="false"
              aria-controls="collapseExample"
              aria-expanded={isPublishedExpanded.toString()}
              onClick={() => setIsPublishedExpanded(!isPublishedExpanded)}
            >
              {isPublishedExpanded ? t("showLess") : t("showMore")}
            </button>
          </div>
          <StaffingRenameModal
            renameStaffing={renameStaff}
            currentStaff={currentStaff}
            modalStatus={showRenameModal}
            onCancel={() => setShowRenameModal(false)}
          />
          <StaffingDeleteModal
            deleteStaffing={deleteStaffing}
            currentStaff={currentStaff}
            modalStatus={showDeleteModal}
            onCancel={() => setShowDeleteModal(false)}
          />
          <div
            className={`row ${publishedStaffing.length > 3
              ? "rbc-row-content-scroll-container animate__animated animate__backInUp"
              : ""
              }`}
          >
            <div className="collapse mt-3 " id="collapseExample">
              {publishedStaffing.length > 0 ? (
                publishedStaffing.map((value, index) => {
                  return (
                    <StaffingStatusCard
                      enableView={viewStaffing}
                      enableChange={listStaffing}
                      enablePrint={printStaffing}
                      enableCopy={copyStaffing}
                      enableDelete={deleteStaffingPri}
                      enableEdit={statusChangeStaffing}
                      onModalClick={() => ChangeStaffingStatus(value)}
                      onEditClick={() => onEditClickHandler(value)}
                      onViewClick={() => onViewModalClickHandler(value)}
                      onDeleteClick={() => onDeleteClickHandler(value)}
                      copyClick={() => copyStaffingClickHandler(value)}
                      ispublished={value.is_publish}
                      shift={value.name}
                      days={value.days}
                      users={value.users}
                      onRenameClick={() => renameStaffingHandler(value)}
                      Day={value.start_date}
                      duration={value.duration}
                      employeeCount={value.employeeCount}
                      modalButtonTitle={t("Unpublish")}
                      modalButtonIcon={"ri-indeterminate-circle-line"}
                    />
                  );
                })
              ) : (
                <>
                  <p className="fw-bold mb-0">{t("inProgress")}</p>
                  <p>{t("currentlyActiveStaffing")}</p>

                  <p className="fw-bold mt-4 mb-0">{t("futureStaffing")}</p>
                  <p>{t("futureStaffingAppear")}</p>
                </>
              )}
            </div>
          </div>
          <div className="myFlex-cs pt-2">
            <div>
              <h6 className="fw-bold mb-0">
                {t("unpublishedStaffing")}
                <span className="badge mx-2">{unPublishedStaffing.length}</span>
              </h6>
            </div>
            <button
              className="text-primary btn shadow-none"
              data-mdb-toggle="collapse"
              href="#collapseExample2"
              // aria-expanded="false"
              aria-controls="collapseExample"
              aria-expanded={isUnPublishedExpanded.toString()}
              onClick={() => setIsUnPublishedExpanded(!isUnPublishedExpanded)}
            >
              {!isUnPublishedExpanded ? t("showLess") : t("showMore")}
            </button>
          </div>
          <div
            className={`row ${unPublishedStaffing.length > 3
              ? "rbc-row-content-scroll-container animate__animated animate__backInUp"
              : ""
              }`}
          >
            <div className="collapse mt-3 " id="collapseExample2">
              {unPublishedStaffing.map((value, index) => {
                return (
                  // <StaffingStatusCard
                  //   key={index}
                  //   onClick={() => ChangeStaffingStatus(value)}
                  //   ispublished={value.is_publish}
                  //   shift={value.name}
                  //   days={value.days}
                  //   Day={value.start_date}
                  //   duration={value.duration}
                  //   employeeCount={value.employeeCount}
                  // />
                  <StaffingStatusCard
                    enableView={viewStaffing}
                    enableChange={listStaffing}
                    enablePrint={printStaffing}
                    enableCopy={copyStaffing}
                    enableDelete={deleteStaffingPri}
                    enableEdit={statusChangeStaffing}
                    onModalClick={() => ChangeStaffingStatus(value)}
                    onEditClick={() => onEditClickHandler(value)}
                    onViewClick={() => onViewModalClickHandler(value)}
                    onDeleteClick={() => onDeleteClickHandler(value)}
                    copyClick={() => copyStaffingClickHandler(value)}
                    ispublished={value.is_publish}
                    shift={value.name}
                    users={value.users}
                    days={value.days}
                    Day={value.start_date}
                    onRenameClick={() => renameStaffingHandler(value)}
                    duration={value.duration}
                    employeeCount={value.employeeCount}
                    modalButtonTitle={t("publish")}
                    modalButtonIcon={"ri-add-circle-line"}
                  />
                );
              })}
            </div>
          </div>
        </div>

        {/* <EmployeeStaffingCard /> */}
      </div>
    </div>
  );
}

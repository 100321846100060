import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { get } from "lodash";

export default function StaffingRenameModal({
  currentStaff,
  renameStaffing,
  onCancel,
  modalStatus,
}) {
  const [currentStaffing, setCurrentStaffing] = useState(null);
  const [curentName, setCurrentName] = useState("");
  const { t } = useTranslation();
  const currentStaffingName = get(currentStaffing, "name", "")

  useEffect(() => {
    if (currentStaff) {
      const { name } = currentStaff;
      setCurrentStaffing(currentStaff);
      if (name) {
        setCurrentName(name);
      }
    }
  }, [currentStaff]);
  return (
    <Modal
      title={t("renameStaff")}
      centered
      onCancel={onCancel}
      visible={modalStatus}
      width={500}
      footer={false}
    >
      <div className="modal-body modal-cs px-5 d-flex flex-column gap-2">
        <input
          className="filter-form"
          value={currentStaffing ? currentStaffingName : ""}
          defaultValue={currentStaffing ? currentStaffingName : ""}
          onChange={(e) =>
            setCurrentStaffing({ ...currentStaffing, name: e.target.value })
          }
          placeholder={t("name")}
        />
        <button
          disabled={currentStaffingName === curentName ? true : false}
          data-mdb-dismiss="modal"
          onClick={() => renameStaffing(currentStaffing)}
          className="btn btn-sm-dark btn-block fs-16"
        >
          {t("update")}
        </button>
      </div>
    </Modal>
  );
}

import React, { useState, useEffect } from "react";
import {
  getCountryIDFromName,
  showFaliureToast,
  unixToDateFormater,
  formattedDateForAPI,
} from "../../../Utilities";
import { useTranslation } from "react-i18next";
import { personalInfoObj } from "../Constants";
import { get } from "lodash";
import usePrevilagesExist from "../../../Helpers/PrivilegesFuncs";
import AppConstants from "../../../Helpers/AppConstants";
import SPProfileDropDown from "../Components/SPProfileDropDown";
import SPProfileField from "../Components/SPProfileField";
import SPProfileButton from "../Components/SPProfileButton";
import SPProfileAddress from "../Components/SPProfileAddress";
import useLocalisedConstants from "../../../customHooks/useLocalisedConstants";

export default function PersonalInfoForm({ Countries, updateInfo, Data }) {
  const { exist: updateContactInfo } = usePrevilagesExist(
    AppConstants.previlages.updateContactInfo
  );

  const appConstants = useLocalisedConstants();

  const { t } = useTranslation();

  const [personalInfoBody, setPersonalInfoBody] = useState(personalInfoObj);

  useEffect(() => {
    setPersonalInfoBody({
      address_1: get(Data, "address_1", ""),
      address_2: get(Data, "address_2", ""),
      address_3: get(Data, "address_3", ""),
      address_4: get(Data, "address_4", ""),
      address_5: get(Data, "address_5", ""),
      country: get(Data, "country", ""),
      date_of_birth: unixToDateFormater(
        get(Data, "date_of_birth", ""),
        AppConstants.defaultDateFormat
      ),
      first_name: get(Data, "first_name", ""),
      gender: get(Data, "gender", ""),
      initial: get(Data, "initial", ""),
      last_name: get(Data, "last_name", ""),
      marital_status: get(Data, "marital_status", ""),
      middle_name: get(Data, "middle_name", ""),
      post_code: get(Data, "post_code", ""),
      profile_image: get(Data, "profile_image", ""),
      title: get(Data, "title", ""),
    });
  }, [Data]);

  // Validation Changes
  const updateUserPersonalInformation = () => {
    const {
      address_1,
      address_2,
      title,
      first_name,
      last_name,
      post_code,
      gender,
      marital_status,
      date_of_birth,
    } = personalInfoBody;
    if (
      !address_1 ||
      !address_2 ||
      !title ||
      !first_name ||
      !last_name ||
      !post_code ||
      !gender ||
      !marital_status ||
      !date_of_birth
    ) {
      showFaliureToast(t("fieldsMarkedAs") + "*" + t("areMandatory"));
    } else {
      updateInfo(personalInfoBody);
    }
  };

  const disabledEnable = (exist) => {
    if (exist) {
      return false;
    } else {
      return true;
    }
  };

  const handlePlacePayload = (place, value) => {
    let address1 = "";
    let postcode = "";
    let countryName = "";
    let townCity = "";
    let politicalCountry = "";

    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name} ${address1}`;
          break;
        }
        case "route": {
          address1 += component.long_name;
          break;
        }
        case "administrative_area_level_1": {
          countryName = component.long_name;
          break;
        }
        case "country" || "political": {
          politicalCountry = component.long_name;
          break;
        }
        case "administrative_area_level_2": {
          townCity = component.short_name;
          break;
        }
        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          break;
        }
        case "postal_code_prefix": {
          postcode = postcode
            ? `${postcode}-${component.long_name}`
            : component.long_name;
          break;
        }
      }
    }

    setPersonalInfoBody({
      ...personalInfoBody,
      address_1: value,
      address_2: townCity,
      address_3: address1,
      address_4: countryName,
      address_5: politicalCountry,
      post_code: postcode,
      country: getCountryIDFromName(countryName, Countries),
    });
  };

  return (
    <div className="accordion-body p-0">
      <SPProfileDropDown
        title={t("title")}
        isDisabled={disabledEnable(updateContactInfo)}
        onChange={(e) => {
          setPersonalInfoBody({ ...personalInfoBody, title: e.target.value });
        }}
        value={personalInfoBody.title}
        dataArray={appConstants.titleArray}
        displayKey="title"
        valueKey="value"
        isRequired={true}
      />
      <SPProfileField
        title={t("firstName")}
        type="text"
        disabled={disabledEnable(updateContactInfo)}
        maxLength={15}
        value={personalInfoBody.first_name}
        onChange={(e) => {
          setPersonalInfoBody({
            ...personalInfoBody,
            first_name: e.target.value,
          });
        }}
        placeholder={t("firstName")}
        isRequired={true}
      />
      <SPProfileField
        title={t("middleName")}
        value={personalInfoBody.middle_name}
        maxLength={15}
        onChange={(e) => {
          setPersonalInfoBody({
            ...personalInfoBody,
            middle_name: e.target.value,
          });
        }}
        type="text"
        disabled={disabledEnable(updateContactInfo)}
        placeholder={t("middleName")}
      />
      <SPProfileField
        title={t("lastName")}
        value={personalInfoBody.last_name}
        maxLength={15}
        onChange={(e) => {
          setPersonalInfoBody({
            ...personalInfoBody,
            last_name: e.target.value,
          });
        }}
        type="text"
        disabled={disabledEnable(updateContactInfo)}
        placeholder={t("lastName")}
        isRequired={true}
      />
      <SPProfileField
        title={t("initials")}
        value={personalInfoBody.initial}
        maxLength={15}
        onChange={(e) => {
          setPersonalInfoBody({ ...personalInfoBody, initial: e.target.value });
        }}
        type="text"
        disabled={disabledEnable(updateContactInfo)}
        placeholder={t("initials")}
      />
      <SPProfileField
        title={t("dateOfBirth")}
        value={personalInfoBody.date_of_birth}
        onChange={(e) => {
          setPersonalInfoBody({
            ...personalInfoBody,
            date_of_birth: formattedDateForAPI(e.target.value),
          });
        }}
        disabled={disabledEnable(updateContactInfo)}
        type="date"
        isRequired={true}
      />
      <SPProfileDropDown
        title={t("gender")}
        dataArray={appConstants.genderArray}
        displayKey="title"
        valueKey="value"
        defaultValue={personalInfoBody.gender}
        isDisabled={disabledEnable(updateContactInfo)}
        value={personalInfoBody.gender}
        isRequired={true}
        onChange={(e) => {
          setPersonalInfoBody({ ...personalInfoBody, gender: e.target.value });
        }}
      />
      <SPProfileDropDown
        title={t("maritalStatus")}
        isDisabled={disabledEnable(updateContactInfo)}
        onChange={(e) => {
          setPersonalInfoBody({
            ...personalInfoBody,
            marital_status: e.target.value,
          });
        }}
        value={personalInfoBody.marital_status}
        dataArray={appConstants.maritalStatuses}
        displayKey="title"
        valueKey="value"
        isRequired={true}
      />
      <SPProfileAddress
        isRequired={true}
        title={`${t("addressLine")} 1`}
        handleSelection={handlePlacePayload}
        disabled={disabledEnable(updateContactInfo)}
        onChange={(value) => {
          setPersonalInfoBody({ ...personalInfoBody, address_1: value });
        }}
        value={personalInfoBody.address_1}
      />
      <SPProfileField
        isRequired={true}
        title={`${t("addressLine")} 2`}
        placeholder={`${t("addressLine")} 2`}
        value={personalInfoBody.address_2}
        disabled={disabledEnable(updateContactInfo)}
        onChange={(e) => {
          setPersonalInfoBody({
            ...personalInfoBody,
            address_2: e.target.value,
          });
        }}
      />
      <SPProfileField
        title={`${t("addressLine")} 3`}
        placeholder={`${t("addressLine")} 3`}
        value={personalInfoBody.address_3}
        disabled={disabledEnable(updateContactInfo)}
        onChange={(e) => {
          setPersonalInfoBody({
            ...personalInfoBody,
            address_3: e.target.value,
          });
        }}
      />
      <SPProfileField
        title={`${t("addressLine")} 4`}
        placeholder={`${t("addressLine")} 4`}
        value={personalInfoBody.address_4}
        disabled={disabledEnable(updateContactInfo)}
        onChange={(e) => {
          setPersonalInfoBody({
            ...personalInfoBody,
            address_4: e.target.value,
          });
        }}
      />
      <SPProfileField
        title={`${t("addressLine")} 5`}
        placeholder={`${t("addressLine")} 5`}
        value={personalInfoBody.address_5}
        disabled={disabledEnable(updateContactInfo)}
        onChange={(e) => {
          setPersonalInfoBody({
            ...personalInfoBody,
            address_5: e.target.value,
          });
        }}
      />
      <SPProfileField
        title={t("postcode")}
        isRequired={true}
        placeholder={t("postcode")}
        value={personalInfoBody.post_code}
        disabled={disabledEnable(updateContactInfo)}
        onChange={(e) => {
          setPersonalInfoBody({
            ...personalInfoBody,
            post_code: e.target.value,
          });
        }}
      />
      {/* <SPProfileField
        title={t("country")}
        value={getCountryNameFromID(country, Countries)}
        disabled={true}
        placeholder={t("country")}
      /> */}
      <label className="ml-2 mt-2">
        {t("fieldsMarkedAs")}
        <span className="text-danger">{" * "}</span>
        {t("areMandatory")}
      </label>
      {!disabledEnable(updateContactInfo) && (
        <SPProfileButton
          title={t("save")}
          onClick={() => updateUserPersonalInformation()}
          disabled={disabledEnable(updateContactInfo)}
        />
      )}
    </div>
  );
}

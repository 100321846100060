import React from "react";
import AbsenceChildCard from "./AbsenceChildCard";
import AppConstants from "../../../Helpers/AppConstants";
import { useNavigate } from "react-router-dom";

export default function Absenceinfocard(props) {
  const navigate = useNavigate();

  return (
    <div>
      <h3 className="all_absences">{props.title}</h3>
      <div className="row justify-content-around">
        <AbsenceChildCard
          title={props.getTitle_1}
          time_1={props.gettime_1}
          format_1={props.getformat_1}
          time_2={props.gettime_2_1}
          format_2={props.getformat_2_1}
        />
        <AbsenceChildCard
          title={props.getTitle_2}
          time_1={props.gettime_2}
          format_1={props.getformat_2}
          time_2={props.gettime_2_2}
          format_2={props.getformat_2_2}
        />
      </div>
      {props.isAllowed && (
        <div className="d-block text-center">
          <a
            onClick={() => navigate(AppConstants.routes.absence)}
            className="btn btn-primary-cs"
          >
            {props.btn_val}
          </a>
        </div>
      )}
    </div>
  );
}

import React from "react";

export const EmployeeTypeCard = ({
  key,
  onClick,
  checked,
  title,
  subtitle,
}) => {
  return (
    <div
      key={key}
      className="col-12 col-sm-12 col-md-4"
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      <div
        className={`card text-center sec-cs-border rota-crd position-relative ${
          checked ? "active" : ""
        }`}
      >
        <div className="card-body">
          <div className="d-flex flex-column align-items-center">
            <h6>{title}</h6>
            <p className="card-text small">{subtitle}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

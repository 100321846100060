import React from "react";
import { Squares } from "react-activity";
export default function Spinner(props) {
  return (
    <div
      className="spinnerContainer"
      style={{ backgroundColor: "rgb(255, 255, 255, 0.85)" }}
    >
      <Squares type="dots" color={"#00aeee"} size={36} />
    </div>
  );
}

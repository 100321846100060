import React, { useState, useRef, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppImages } from "../../Helpers/AppImages";
import { useTranslation } from "react-i18next";
import AppConstants from "../../Helpers/AppConstants";
import Spinner from "../Spinner";
import $ from "jquery";
import OBInputField from "../Input/OBInputField";
import ViewOnGoogle from "../AppButtons/ViewOnGoogle";
import { get } from "lodash";
import { Applogger } from "../../Helpers/Logger";
import { getCountries } from "../../Redux/reducers/EmployeesReducer";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  formattedDateForAPI,
  getCountryIDFromName,
  isUaeRegion,
} from "../../Utilities";
import { getAlphaNumericString } from "../../Helpers/AppValidators";
import { DatePicker } from "antd";
import {
  convertDayMonthToDateEnd,
  convertDayMonthToDateStart,
} from "../../Helpers/commonFunc";
import moment from "moment";
// import dayjs from "dayjs";
// import advancedFormat from "dayjs/plugin/advancedFormat";
// import customParseFormat from "dayjs/plugin/customParseFormat";
// import localeData from "dayjs/plugin/localeData";
// import weekday from "dayjs/plugin/weekday";
// import weekOfYear from "dayjs/plugin/weekOfYear";
// import weekYear from "dayjs/plugin/weekYear";

// dayjs.extend(customParseFormat);
// dayjs.extend(advancedFormat);
// dayjs.extend(weekday);
// dayjs.extend(localeData);
// dayjs.extend(weekOfYear);
// dayjs.extend(weekYear);

export default function CompanyDetails({
  currentStep,
  companySettingsObj,
  setCompanySettingsObj,
  isOnboarding = false,
}) {
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch();
  const inputFile = useRef(null);
  const autoCompleteRef = useRef();
  const inputReff = useRef();
  const { t } = useTranslation();
  const dateFormat = AppConstants.defaultDateFormat;

  const { companySettingsOB, loading } = useSelector((state) => state.settings);
  const { token, user, role } = useSelector(
    (state) => state.AuthenticationReducer
  );
  const { countries } = useSelector((state) => state.employees);
  const { lng } = useSelector((state) => state.languageReducer);
  const [latlong, setLatLong] = useState({
    latitude: "",
    longitude: "",
  });

  const [startDate, setStartDate] = useState(
    convertDayMonthToDateStart(
      get(companySettingsOB, "fiscal_start_date", 0),
      get(companySettingsOB, "fiscal_start_month", 0)
    )
  );
  const [endDate, setEndDate] = useState(
    convertDayMonthToDateEnd(
      get(companySettingsOB, "fiscal_end_date", 0),
      get(companySettingsOB, "fiscal_end_month", 0),
      get(companySettingsOB, "fiscal_start_month", 0)
    )
  );

  const unitedKingdom = "United Kingdom";
  const unitedAEmirates = "United Arab Emirates";
  const england = "England";
  const companyId = get(user, "company_id", "");

  useEffect(() => {
    if (!countries.length > 0) {
      getAllCountries();
    }
  }, []);

  function getAllCountries() {
    dispatch(getCountries({ token, lng, companyId }))
      .then(unwrapResult)
      .then((response) => {
        Applogger("Response at getCountries", response);
      })
      .catch((err) => {
        Applogger("Error at getCountries", err.message);
      });
  }

  useEffect(() => {
    if (companySettingsOB) {
      handleInitialValues(companySettingsOB);
    }
  }, [companySettingsOB, currentStep]);

  const getAutoCompleteLocationHandler = () => {
    $(document).ready(function () {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputReff.current,
        AppConstants.addressOptions
      );
      autoCompleteRef.current.addListener("");
      autoCompleteRef.current.addListener("place_changed", async function () {
        const place = await autoCompleteRef.current.getPlace();
        fillInAddress(place);
        // autoCompleteRef.current.removeListener("place_changed");
      });
    });
  };

  function fillInAddress(place) {
    let address1 = "";
    let postcode = "";
    let countryName = "";
    let townCity = "";
    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name} ${address1}`;
          break;
        }
        case "route": {
          address1 += component.long_name;
          break;
        }
        case "administrative_area_level_1": {
          countryName = component.short_name;
          break;
        }
        case "country": {
          if (countryName == "") {
            if (
              getAlphaNumericString(component.long_name) ==
              getAlphaNumericString(unitedKingdom)
            ) {
              countryName = england;
            }
          }

          if (isUaeRegion()) {
            countryName = unitedAEmirates;
          }
          break;
        }
        case "administrative_area_level_2": {
          townCity = component.short_name;
          break;
        }
        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          break;
        }
        case "postal_code_prefix": {
          postcode = postcode
            ? `${postcode}-${component.long_name}`
            : component.long_name;
          break;
        }
      }
    }

    setCompanySettingsObj({
      ...companySettingsObj,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
      hq_address: inputReff.current.value,
      // New Fields
      address_line1: place.formatted_address,
      town_city: townCity,
      address_line2: address1,
      post_code: postcode,
      country_id: getCountryIDFromName(countryName, countries),
      countryName: countryName,
    });
    setLatLong({
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    });
  }

  const handleInitialValues = (compSetting) => {
    if (compSetting) {
      inputReff.current.value = compSetting.hq_address;
      setLatLong({
        latitude: compSetting.latitude,
        longitude: compSetting.longitude,
      });
      setCompanySettingsObj({
        ...companySettingsObj,
        company_title: get(compSetting, "company_title", ""),
        hq_address: get(compSetting, "hq_address", ""),
        description: get(compSetting, "description", "") ?? "",
        company_logo: {
          url: compSetting.company_logo,
          file: null,
        },
        longitude: compSetting.longitude,
        latitude: compSetting.latitude,
        address_line1: get(compSetting, "hq_location.address_line1", ""),
        town_city: get(compSetting, "hq_location.town_city", ""),
        address_line2: get(compSetting, "hq_location.address_line2", ""),
        post_code: get(compSetting, "hq_location.post_code", ""),
        country_id: get(compSetting, "country_id", ""),
        countryName: get(compSetting, "hq_location.countryName", ""),
      });
    }
  };

  const onProfileImageClickHandler = () => {
    // `current` points to the mounted file input element
    document.getElementById("file-image").value = "";
    inputFile.current.click();
  };

  const changeHandler = (event) => {
    let fileName = event.target.files[0];

    setCompanySettingsObj({
      ...companySettingsObj,
      company_logo: { file: fileName, url: URL.createObjectURL(fileName) },
    });
  };

  const deleteCompanyUrl = () => {
    setCompanySettingsObj({
      ...companySettingsObj,
      company_logo: { file: "", url: "" },
    });
  };

  const handleDateRangeNew = (value) => {
    var dateFormat = "YYYY-MM-DD";
    setStartDate(value);
    const IsoDateTo = moment(value, dateFormat).format("YYYY-MM-DD[T]HH:mm:ss");
    var addOneYear = moment(IsoDateTo).add(1, "year").format(dateFormat);
    var convertToIso = moment(addOneYear, dateFormat).format(
      "YYYY-MM-DD[T]HH:mm:ss"
    );
    var removeOneday = moment(convertToIso)
      .subtract(1, "day")
      .format(dateFormat);

    setEndDate(removeOneday);

    setCompanySettingsObj((prev) => ({
      ...prev,
      fiscal_start_date: moment(value, dateFormat).date(),
      fiscal_start_month: moment(value, dateFormat).month() + 1,
      fiscal_end_date: moment(removeOneday, dateFormat).date(),
      fiscal_end_month: moment(removeOneday, dateFormat).month() + 1,
    }));
  };

  return (
    <div className="mainComponentHeight" style={{ overflow: "hidden" }}>
      {loading == "pending" && <Spinner />}
      <div className="compSettingContainer">
        <div className="row w-100 h-100 overflow-cus-class-y align-items-center ">
          <div className="col-12 col-sm-12 col-md-6">
            <div className="profileCont">
              <div className="profile-lg profileImage main-profile-picture d-inline-block position-relative">
                {companySettingsObj.company_logo?.url ? (
                  <img
                    src={companySettingsObj.company_logo?.url}
                    className="main-profile-picture-image"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  loading !== "pending" && (
                    <img
                      src={AppImages.no_image}
                      className="main-profile-picture-image"
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "fill",
                      }}
                    />
                  )
                )}
                <button
                  onClick={onProfileImageClickHandler}
                  className="upload-profile-picture cursor-pointer"
                >
                  <i className="ri-upload-2-line"></i>
                </button>
                <input
                  onChange={changeHandler}
                  accept="image/png, image/jpg, image/jpeg"
                  type="file"
                  id="file-image"
                  ref={inputFile}
                  style={{ display: "none", borderRadius: "50%" }}
                />
              </div>
              {companySettingsObj.company_logo?.url && (
                <div className="d-flex pr-20">
                  <button onClick={deleteCompanyUrl} className="cursor-pointer">
                    <i
                      style={{ color: "red", fontSize: 20 }}
                      class="ri-delete-bin-line"
                    />
                  </button>
                </div>
              )}
            </div>
            <p
              className="text-black-50 mt-2 text-center"
              style={{ fontSize: 12 }}
            >
              {t("recommendedImage")}
            </p>
          </div>
          <div
            className="col-sm-12 col-md-6 col-12"
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <OBInputField
              title={t("name")}
              placeholder="Enter name here..."
              value={companySettingsObj.company_title}
              isRequired={true}
              onChange={(e) =>
                setCompanySettingsObj({
                  ...companySettingsObj,
                  company_title: e.target.value,
                })
              }
            />
            <div
              className="d-flex flex-wrap-sm mb-2"
              style={{ flexDirection: "column" }}
            >
              <p className="mb-0 col-sm-12">
                {t("address")} <span className="text-danger">*</span>
              </p>
              <input
                className={"filter-form"}
                onFocus={getAutoCompleteLocationHandler}
                placeholder="Enter address here..."
                onChange={(e) => {
                  if (e.target.value === "") {
                    setCompanySettingsObj({
                      ...companySettingsObj,
                      hq_address: "",
                    });
                  }
                }}
                ref={inputReff}
              />
              {companySettingsObj.hq_address && latlong.latitude && (
                <ViewOnGoogle latlong={latlong} showLatlong={false} />
              )}
            </div>
            <OBInputField
              title={t("description")}
              placeholder="Enter description here..."
              isRequired={false}
              value={companySettingsObj.description ?? ""}
              onChange={(e) =>
                setCompanySettingsObj({
                  ...companySettingsObj,
                  description: e.target.value,
                })
              }
            />
            <div className="col-12 col-sm-12 col-md-12 px-0 ">
              <div className="mt-2 ">
                <p>
                  {t("leaveYearStartDate")}
                  <span className="text-danger">*</span>
                </p>
              </div>
              <div className="d-flex gap-1 ">
                <input
                  value={formattedDateForAPI(startDate)}
                  onChange={(e) => handleDateRangeNew(e.target.value)}
                  className="filter-form filter-form2 "
                  type={"date"}
                />
                <input
                  disabled={true}
                  value={formattedDateForAPI(endDate)}
                  className="filter-form filter-form2 "
                  type={"date"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

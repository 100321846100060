import React from "react";
import { useTranslation } from "react-i18next";

export default function EmplStatus({
  title = "",
  onClick = () => {},
  isRequired = false,
  isActive = false,
}) {
  const { t } = useTranslation();
  return (
    <div className="col-lg-5 col-md-12 col-sm-12">
      <div className="d-flex flex-wrap-sm align-items-center">
        <label className="col-sm-12 col-md-4 mb-0">
          {title}
          {isRequired && <span className="text-danger">*</span>}
        </label>
        <div className="col-md-8 col-sm-12 row">
          <div
            onClick={() => onClick("1")}
            className={`decisionCard cursor-pointer ${
              isActive ? "active" : ""
            }`}
          >
            {t("yes")}
          </div>
          <div
            onClick={() => onClick("0")}
            className={`decisionCard cursor-pointer ${
              !isActive ? "active" : ""
            }`}
          >
            {t("no")}
          </div>
        </div>
      </div>
    </div>
  );
}

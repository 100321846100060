import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { showInfoToast } from "../../Utilities";
import { useTranslation } from "react-i18next";
import { useTitleHandler } from "../../customHooks/useTitleHandler";
import Header from "../../components/Header/Header";
import OnBoardingStepper from "../../components/OnBoardingComponents/OnBoardingStepper";
import AppConstants from "../../Helpers/AppConstants";

export default function OnBoarding() {
  const navigate = useNavigate();

  const { t } = useTranslation();
  useTitleHandler(t("dtOnboaring"));

  const { token } = useSelector((state) => state.AuthenticationReducer);
  const { companySettingsOB } = useSelector((state) => state.settings);

  useEffect(() => {
    if (!token) {
      navigate(AppConstants.routes.login);
    }
    // if (token && get(companySettingsOB, "is_onboarded", 0) == 1) {
    //   showInfoToast("Company Onboarding is already completed");
    //   navigate(AppConstants.routes.dashboard);
    // }
  }, []);

  return (
    <div className="onBoarding-Main">
      <Header active={"dashboard"} />
      {token && (
        <div className="onBoarding-container">
          <OnBoardingStepper />
        </div>
      )}
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showFaliureToast, showSuccessToast } from "../../Utilities";
import { Applogger } from "../../Helpers/Logger";
import { useTranslation } from "react-i18next";
import {
  getCalenderPendingRequest,
  getDeclineLeavesRequest,
  postApprove,
  RequestCalenderData,
} from "../../Redux/reducers/CalenderReducer";
import Spinner from "../Spinner";
import RequestCard from "./RequestCard";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";
import AppConstants from "../../Helpers/AppConstants";

export default function PendingRequest({ logoutUser }) {
  const dispatch = useDispatch();
  const { token, role } = useSelector((state) => state.AuthenticationReducer);
  const { lng } = useSelector((state) => state.languageReducer);
  const { pendingRequests, loading } = useSelector((state) => state.calender);

  const { exist: ApproveDeclinePendingLeave } = usePrevilagesExist(
    AppConstants.previlages.ApproveDeclinePendingLeave
  );

  const [approveReq, setApproveReq] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (token) {
      if (!pendingRequests.length > 0) {
        getPendingRequests();
      }
    }
  }, [approveReq]);

  const getPendingRequests = () => {
    dispatch(getCalenderPendingRequest({ token, lng: lng }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getCalenderPendingRequest", res);
      })
      .catch((err) => {
        Applogger("Error at getCalenderPendingRequest", err);
      });
  };

  const getDataHandler = () => {
    dispatch(RequestCalenderData({ token, lng: lng }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at RequestCalenderData", res);
      })
      .catch((err) => {
        if (err.message == "Request failed with status code 401") {
          logoutUser();
          showFaliureToast("Please Login To Continue");
        }
        Applogger("Error at RequestCalenderData", err);
      });
  };

  const getDeclinedRequest = () => {
    dispatch(getDeclineLeavesRequest({ token }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getDeclineLeavesRequest", res);
      })
      .catch((err) => {
        Applogger("Error at getDeclineLeavesRequest", err);
      });
  };

  const changeStatusRequest = (val) => {
    var reason = "";
    const { status, id } = val;
    if (val.reason) {
      reason = val.reason;
    }
    dispatch(postApprove({ status, id, token, reason }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at postApprove", res);
        if (res.status === 200) {
          getDeclinedRequest();
          if (val.status == "approved") {
            if (approveReq == true) {
              getPendingRequests();
            } else {
              setApproveReq(true);
            }
          } else if (val.status == "declined") {
            if (approveReq == false) {
              getPendingRequests();
            } else {
              setApproveReq(false);
            }
          }
          getDataHandler();
          showSuccessToast(`Request ${status} successfully`);
        }
      })
      .catch((err) => {
        Applogger("Error at postApprove", err);
        setApproveReq(false);
        showFaliureToast(`Request ${status} failed`);
      });
  };

  return (
    <div>
      {loading == "pending" && <Spinner />}

      <h1 className="mt-3 fw-bold subheading_2">{`${t("pendingRequests")} (${
        pendingRequests.length
      })`}</h1>
      {pendingRequests.length > 0 ? (
        <div className="requestHeader ">
          <div className="d-flex w-50">
            <div className="w-50">{t("request")}</div>
            {/* <div>{t("shouldIapprove")}</div> */}
          </div>
        </div>
      ) : null}

      {pendingRequests.map((leave, index) => {
        return (
          <div key={index}>
            <RequestCard
              role={role}
              ApproveDeclinePendingLeave={ApproveDeclinePendingLeave}
              changeStatusRequest={changeStatusRequest}
              requestData={leave}
              leaveType="own"
            />
          </div>
        );
      })}
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { Pagination } from "antd";
import { showFaliureToast, showSuccessToast } from "../../Utilities";
import { changeEmployeeWorkingStatus } from "../../Redux/reducers/EmployeesReducer";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import { Applogger } from "../../Helpers/Logger";
import EmployeeCard from "./EmployeeCard";
import EmployeeModal from "./EmployeeModal";
import UserStatusModal from "../Profile/profileDetails/UserStatusModal";
import AppConstants from "../../Helpers/AppConstants";
import "./EmployeeCard.css";

export default function Teams(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { token } = useSelector((state) => state.AuthenticationReducer);
  const { allEmployees, employeesCount } = useSelector(
    (state) => state.employees
  );
  const [arrow, setArrow] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [showUserStatusModal, setShowUserStatusModal] = useState(false);
  const [currentEmployees, setAllEmployees] = useState([]);
  const [employeeModalVisible, setEmployeeModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [offSet, setOffSet] = useSearchParams();
  const employeeData = get(props, "employeeData", []);
  const isFiltering = get(props, "isFiltering", false);

  useEffect(() => {
    if (employeeData) {
      setAllEmployees(employeeData);
    }
  }, [userDetails, currentEmployees, employeeData, props, allEmployees]);

  const showUserDetail = (userDetail) => {
    setEmployeeModalVisible(true);
    setShowModal(true);
    setUserDetails(userDetail);
  };

  const setUserStatus = (userStatus) => {
    let payload = {
      token: token,
      working_status: userStatus,
      user_id: userDetails.user_id,
    };
    dispatch(changeEmployeeWorkingStatus(payload))
      .then(unwrapResult)
      .then((res) => {
        showSuccessToast("Employee Working Status Updated Successfully");
        setShowUserStatusModal(false);
      })
      .catch((err) => {
        showFaliureToast("Failed To Update Employee Working Status");
        setShowUserStatusModal(false);
      });
  };

  const onChangeInPagination = (page, pageSize) => {
    setCurrentPage(page);
    const newQueryParameters = new URLSearchParams();
    newQueryParameters.set("offset", page * pageSize - pageSize);
    setOffSet(newQueryParameters);
  };

  return (
    <div className="accordion-item">
      {showModal && userDetails != null && (
        <EmployeeModal
          showStatusModal={() => {
            setShowUserStatusModal(true);
            setEmployeeModalVisible(false);
          }}
          onCancel={() => setEmployeeModalVisible(false)}
          modalStatus={employeeModalVisible}
          data={userDetails}
        />
      )}
      <UserStatusModal
        onCancel={() => setShowUserStatusModal(false)}
        modalStatus={showUserStatusModal}
        setStatus={(status) => setUserStatus(status)}
        changeStatus={(e) => Applogger("Not Need Function")}
        status={get(userDetails, "working_status", "")}
      />
      <div className="accord-nav d-flex justify-content-between w-100 py-2">
        <h5 className="text-black fw-bold lexen-font">
          {t("noOfEmployees")} ({employeeData.length})
        </h5>
        <a
          href="#"
          data-mdb-toggle="collapse"
          data-mdb-target="#panelsStayOpen-collapseOne"
          aria-expanded="true"
          aria-controls="panelsStayOpen-collapseOne"
        >{!arrow ?
          <i className="ri-arrow-up-s-line" onClick={() => setArrow(true)} /> :
          <i className="ri-arrow-down-s-line" onClick={() => setArrow(false)} />
          }

        </a>
      </div>
      <div
        id="panelsStayOpen-collapseOne"
        className="accordion-collapse border-0 collapse show"
        aria-labelledby="headingOne"
      >
        <div className="accordion-body p-0 row">
          {currentEmployees.length == 0 && (
            <div>
              <h4 style={{ textAlign: "center" }}>{t("noEmployeeFound")}</h4>
            </div>
          )}
          <div className="d-flex align-items-center flex-wrap gap-4 team-cards sp-card-grid">
            {Array.isArray(currentEmployees) &&
              currentEmployees.map((val, index) => {
                return (
                  <EmployeeCard
                    key={index}
                    showUserDetail={showUserDetail}
                    val={val}
                  />
                );
              })}
          </div>
        </div>
      </div>
      {currentEmployees.length > 0 && (
        <div className="justify-content-lg-end d-flex mt-4 mx-4">
          <Pagination
            style={{ fontFamily: "Lexen Dex" }}
            pageSize={12}
            responsive={true}
            current={currentPage}
            onChange={onChangeInPagination}
            total={employeesCount}
            itemActiveBg
          />
        </div>
      )}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Tree } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Applogger } from "../../Helpers/Logger";
import { useNavigate } from "react-router-dom";
import { showFaliureToast, showSuccessToast } from "../../Utilities";
import { get } from "lodash";
import {
  assignRolePrevilages,
  getAllSubModulesRequest,
  getRoleAssignedPrevilegesRequest,
  getUserRolePrevilages,
} from "../../Redux/reducers/SettingsReducer";
import Spinner from "../Spinner";
import AppConstants from "../../Helpers/AppConstants";
import "./styles.css";

function Previlegas() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { token, user } = useSelector((state) => state.AuthenticationReducer);
  const {
    userPrevilages,
    roles,
    assignedPrevileges,
    subModulesPrevilages,
    loading,
  } = useSelector((state) => state.settings);

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const [rolesLocal, setRolesLocal] = useState([]);
  const [currentRole, setCurrentRole] = useState("");
  const [userPrevilagesLocal, setUserPrevilagesLocal] = useState([]);
  const companyId = get(user, "company_id", "");
  const [initialRoleAdded, setInitialRoleAdded] = useState(false);

  useEffect(() => {
    getUserRolePrevilagesHandler();
    getAllSubModulesRequestHandler();
  }, []);

  useEffect(() => {
    if (window) {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener("popstate", onBackButtonEvent);
    }
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, [checkedKeys]);

  useEffect(() => {
    if (roles) {
      let tempArray = roles;

      tempArray = tempArray.filter((item) => {
        return item.name != AppConstants.roleTypes.Owner;
      });

      const allRoles = [...tempArray].map((val) => {
        return { ...val, label: val.name, value: val.role_id };
      });

      setRolesLocal(allRoles);
      if (!initialRoleAdded) {
        handleSetInitialRole();
      }
    }
  }, [roles]);

  useEffect(() => {
    if (
      Array.isArray(subModulesPrevilages) &&
      assignedPrevileges.length == 10
    ) {
      const filteredSubModules = subModulesPrevilages.filter(
        (module) => module.is_default == 1
      );

      const filtered = filteredSubModules.map((val) => val.key);
      const allChecked = [...assignedPrevileges];
      const checked = allChecked.concat(filtered);

      let uniqueChars = [];
      checked.forEach((c) => {
        if (!uniqueChars.includes(c)) {
          uniqueChars.push(c);
        }
      });
      setCheckedKeys(uniqueChars);
    } else {
      console.log("heree at else ==== ");
      if (Array.isArray(assignedPrevileges)) {
        setCheckedKeys(assignedPrevileges);
      }
    }
  }, [subModulesPrevilages, assignedPrevileges]);

  useEffect(() => {
    if (userPrevilages) {
      const userPrev = userPrevilages.map((val) => {
        return {
          ...val,
          // key: val.sub_module_id ?? val.module_id,
          title: val.name,
        };
      });
      setUserPrevilagesLocal(userPrev);
    }
  }, [userPrevilages]);

  const getUserRolePrevilagesHandler = () => {
    dispatch(getUserRolePrevilages({ token, companyId }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getUserRolePrevilages", res);
        handleExpansionOfKeys(res.data);
      })
      .catch((err) => {
        Applogger("Error at getUserRolePrevilages", err);
      });
  };

  const handleExpansionOfKeys = (priviliges) => {
    let finalArray = [];

    priviliges.forEach((privilige) => {
      finalArray.push(get(privilige, "key", ""));
    });
    setExpandedKeys(finalArray);
  };

  const onBackButtonEvent = () => {
    if (!savedRoleStatus()) {
      showFaliureToast("You have unsaved privileges");
    } else {
      navigate(-1);
    }
  };

  const onRoleChangeHandler = (e) => {
    if (!savedRoleStatus()) {
      showFaliureToast("You have unsaved privileges");
    } else {
      setCurrentRole(e);
      getUserAssignedPrevilegasHandler(e);
    }
  };

  const getUserAssignedPrevilegasHandler = (id) => {
    dispatch(getRoleAssignedPrevilegesRequest({ token, id, companyId }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getRoleAssignedPrevilegesRequest", res);
      })
      .catch((err) => {
        Applogger("Error at getRoleAssignedPrevilegesRequest", err);
      });
  };

  const getAllSubModulesRequestHandler = () => {
    dispatch(getAllSubModulesRequest({ token, companyId }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getAllSubModulesRequest", res);
      })
      .catch((err) => {
        Applogger("Error at getAllSubModulesRequest", err);
      });
  };

  const handleSetInitialRole = () => {
    const firstElement =
      get(roles, "[0].name", "") != "Owner"
        ? get(roles, "[0]", null)
        : get(roles, "[1]", null);

    const roleId = get(firstElement, "role_id", "");
    if (roleId) {
      setCurrentRole(roleId);
      getUserAssignedPrevilegasHandler(roleId);
      setInitialRoleAdded(true);
    }
  };

  const onExpand = (expandedKeysValue) => {
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onCheck = (checkedKeysValue) => {
    setCheckedKeys(checkedKeysValue);
  };

  const onSelect = (selectedKeysValue, info) => {
    setSelectedKeys(selectedKeysValue);
  };

  const savedRoleStatus = () => {
    const checked = [...checkedKeys].sort();
    const data = [];

    for (let index = 0; index < checked.length; index++) {
      const element = checked[index];
      const currIndex = userPrevilagesLocal.findIndex(
        (val) => val.key == element
      );
      if (currIndex === -1) {
        data.push(element);
      }
    }

    const currentSelected = JSON.stringify(data);
    const assigned = [...assignedPrevileges].sort();
    const main = JSON.stringify(assigned);

    if (currentSelected == main) {
      return true;
    } else {
      return false;
    }
  };

  const clearParentKeys = () => {
    const allChecked = [...checkedKeys];
    const data = [];

    for (let index = 0; index < allChecked.length; index++) {
      const element = allChecked[index];
      const currIndex = userPrevilagesLocal.findIndex(
        (val) => val.key == element
      );
      if (currIndex === -1) {
        data.push(element);
      }
    }
    return data;
  };

  const assignUserPrevilagesRequestHandler = () => {
    const allChecked = clearParentKeys();

    if (!allChecked.length > 0) {
      showFaliureToast("Please select roles to assign");
      return;
    }
    dispatch(
      assignRolePrevilages({
        token: token,
        Previlages: allChecked,
        currentRole: currentRole,
        companyId,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at assignRolePrevilages", res);
        getUserAssignedPrevilegasHandler(currentRole);
        showSuccessToast(res);
      })
      .catch((err) => {
        Applogger("Error at assignRolePrevilages", err);
        showFaliureToast(err?.message ?? "Unable to assign privileges");
      });
  };

  return (
    <div>
      {loading == "pending" && <Spinner />}
      <div
        className="mainComponentHeight"
        style={{ overflow: "hidden", height: "auto" }}
      >
        <div className="row ">
          <div className="col-12 col-md-6 col-sm-12 pl-0">
            <label>{t("roles")}</label>
            <div
              className="role_tree"
              style={{ height: rolesLocal.length > 5 ? "43vh" : "fit-content" }}
            >
              {rolesLocal.map((val, index) => {
                return (
                  <div
                    className={`role-container ${
                      val.role_id == currentRole ? "active" : ""
                    }`}
                    onClick={() => onRoleChangeHandler(val.role_id)}
                    key={index}
                  >
                    <p style={{ marginBottom: 0 }} className="text-capitalize">
                      {val.name}
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      {currentRole == val.role_id ? (
                        <i
                          style={{
                            fontSize: 15,
                            marginLeft: 10,
                            fontWeight: "bold",
                            alignSelf: "center",
                            color: "white",
                          }}
                          class={`ri-checkbox-blank-circle-${
                            savedRoleStatus() ? "fill" : "line"
                          }`}
                        />
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-12 col-md-6 col-sm-12  pl-0">
            <label>{t("privileges")}</label>
            <div className="checkbox d-flex role_tree">
              {currentRole ? (
                <React.Fragment>
                  <Tree
                    checkable
                    onExpand={onExpand}
                    expandedKeys={expandedKeys}
                    autoExpandParent={autoExpandParent}
                    onCheck={onCheck}
                    checkedKeys={checkedKeys}
                    onSelect={onSelect}
                    selectedKeys={selectedKeys}
                    treeData={userPrevilagesLocal ?? []}
                  />
                </React.Fragment>
              ) : (
                <div>{t("noRoleSelected")}</div>
              )}
            </div>
          </div>
        </div>
        {currentRole && (
          <div className="col-12 col-sm-12 pl-0 mt-4 justify-content-end d-flex">
            <button
              className="btn btn-primary-cs mr-3"
              onClick={() => assignUserPrevilagesRequestHandler()}
            >
              {t("save")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Previlegas;

import React from "react";
import { useTranslation } from "react-i18next";

export default function Index() {
  const { t } = useTranslation();
  return (
    <div>
      <button
        className="btn btn-sm-dark"
        data-mdb-toggle="modal"
        data-mdb-target="#addTeam"
      >
        {t("addANewTeam")}
      </button>
      <div
        className="modal fade"
        id="addTeam"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header bg-sec-cs text-white">
              <h5 className="modal-title" id="exampleModalLabel">
                <i className="ri-information-line info-i-light" />
                {t("addANewTeam")}
              </h5>
              <button
                type="button"
                className="btn-close text-light"
                data-mdb-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body modal-cs">
              <form className="mt-2">
                <label className="fs-6 fw-light">{t("name")}</label>
                <br />
                <input
                  type="text"
                  placeholder={t("enterTeamName")}
                  className="filter-form"
                />
              </form>
              <div className="d-flex justify-content-between">
                <button
                  className="btn-lg-light btn btn-primary-outline-cs"
                  data-mdb-dismiss="modal"
                  aria-label="Close"
                >
                  {t("close")}
                </button>
                <button className="btn btn-sm-dark disabled">
                  {t("selectEmployees")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <form className="mt-2">
        <label className="fs-6 fw-light">{t("find")}</label>
        <br />
        <input
          type="text"
          placeholder={t("teamName")}
          className="filter-form-sm"
          style={{ width: "20%" }}
        />
      </form>
    </div>
  );
}

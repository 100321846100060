import React, { useState, useEffect } from "react";
import { Modal } from "antd";

export default function DeclinePopup({
  onCancelHandler,
  modalStatus,
  onDeclinePress,
}) {
  const [reason, setReason] = useState("");

  useEffect(() => {
    return () => {
      setReason("");
    };
  }, []);

  return (
    <Modal
      title={"Reason "}
      centered
      onCancel={() => {
        onCancelHandler();
        setReason("");
      }}
      visible={modalStatus}
      width={800}
      footer={false}
    >
      <p>Please Provide Reason to decline this absence:</p>

      <textarea
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        placeholder="Reason to decline"
        className="filter-form p-2 pt-new-2"
      />

      <button
        disabled={!reason ? true : false}
        onClick={() => onDeclinePress(reason)}
        className="btn btn-primary-outline-cs mt-2"
      >
        Submit
      </button>
    </Modal>
  );
}

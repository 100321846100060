import React, { useState } from "react";
import { Radio } from "antd";
import { useTranslation } from "react-i18next";
import Roles from "./Roles";
import Previlegas from "./Previlegas";

const types = {
  roles: "Roles",
  previlegas: "Previlegas",
};

export default function RoleAndPrevilegas() {
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState(types.roles);

  return (
    <div>
      <div>
        <Radio.Group
          style={{ width: "100%" }}
          defaultValue={selectedType}
          value={selectedType}
          onChange={(e) => setSelectedType(e.target.value)}
          buttonStyle="solid"
        >
          <Radio.Button style={{ width: "50%" }} value={types.roles}>
            {t("roleSettings")}
          </Radio.Button>
          <Radio.Button style={{ width: "50%" }} value={types.previlegas}>
            {t("privileges")}
          </Radio.Button>
        </Radio.Group>
      </div>
      <div style={{ marginTop: "0.75rem" }}>
        {selectedType == types.roles ? <Roles /> : <Previlegas />}
      </div>
    </div>
  );
}

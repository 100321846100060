import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import "./styles.css";

export default function ErrorModal({
  showErrorModal,
  setShowErrorModal,
  errorsList,
  isOnboarding = false,
}) {
  const { t } = useTranslation();

  return (
    <div>
      <Modal
        destroyOnClose={true}
        visible={showErrorModal}
        onOk={() => setShowErrorModal(false)}
        onCancel={() => setShowErrorModal(false)}
        width={680}
        preserve={false}
        title={"Error Details"}
        className="special_modal"
      >
        {isOnboarding && (
          <h5>{"Found errors during the onboarding process"}</h5>
        )}
        <ol style={{ listStyleType: "decimal" }}>
          {errorsList.map((error) => {
            return <li style={{ marginLeft: 15 }}>{error}</li>;
          })}
        </ol>
      </Modal>
    </div>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { AppImages } from "../../Helpers/AppImages";

export default function NoStaffingData() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div className="row sec-cs-card mb-4 py-4  px-2">
        <div className="col-md-2">
          <img src={AppImages.def_icon} className="w-100" alt="def_icon" />
        </div>
        <div className="col-md-10">
          <h5 className="fw-bold">{t("createManage")}</h5>
          <p>{t("addMultipleStaffToShifts")}</p>
        </div>
      </div>
      <div className="row sec-cs-card mb-4 py-4  px-2">
        <div className="col-md-10">
          <h5 className="fw-bold">{t("manageShareWithEmployees")}</h5>
          <p>{t("publishedStaffing")}</p>
        </div>
        <div className="col-md-2">
          <img src={AppImages.def_icon} className="w-100" alt="def_icon" />
        </div>
      </div>
    </div>
  );
}

import React from "react";

import { unixToDateFormaterOld } from "../../../Utilities";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AppConstants from "../../../Helpers/AppConstants";

export default function AbsenceCard({
  cardData,
  handle_delete,
  handle_edit,
  enableDelete,
  disabled,
  enableDeleteProfileAbsence,
}) {
  const {
    start_date,
    end_date,
    absence_type,
    logger_user_name,
    decline_reasons,
    annual_leave_id,
    colour,
    created_at,
  } = cardData;
  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }
  function rgba() {
    return `rgba(${hexToRgb(colour)?.r},${hexToRgb(colour)?.g},${
      hexToRgb(colour)?.b
    },0.1)`;
  }
  // alert(); // "51";
  const { t, i18n } = useTranslation();
  const { role } = useSelector((state) => state.AuthenticationReducer);
  const Sickness = "Sickness Absence";
  const annual_leave = "Annual Leave";
  const other = "Lateness";
  const Toil = "TOIL";
  return (
    <div className="d-flex flex-wrap b_b_light" style={{ background: rgba() }}>
      <div className="col-2 d-flex align-items-center justify-content-center ">
        {absence_type == Sickness && (
          <i style={{ color: colour }} className="h2   ri-first-aid-kit-line" />
        )}
        {absence_type == annual_leave && (
          <i style={{ color: colour }} className="h2 ri-sun-line" />
        )}
        {absence_type == Toil && (
          <i style={{ color: colour }} className="h2 ri-calendar-2-line" />
        )}
        {absence_type == other && (
          <i style={{ color: colour }} className="h2 ri-time-line" />
        )}
        {absence_type !== Sickness &&
          absence_type !== annual_leave &&
          absence_type !== Toil &&
          absence_type !== other && (
            <i style={{ color: colour }} className="h2 ri-rest-time-line" />
          )}
      </div>
      <div className="col-8 p_font d-flex justify-content-center flex-column py-4">
        <h5 className="mb-0 fw-bold mb-2" style={{ color: colour }}>
          {absence_type}
        </h5>
        <p className="h6 fw-bold mb-1">
          {`${unixToDateFormaterOld(
            start_date,
            // "ddd DD MMM YYYY"
            AppConstants.dateFormat
          )} - ${unixToDateFormaterOld(
            end_date,
            // "ddd DD MMM YYYY"
            AppConstants.dateFormat
          )}`}{" "}
        </p>
        {logger_user_name && (
          <p className="fw-bold text-black-50">
            {t("logged")}{" "}
            <small className="small capitialize">
              {t("by")} {logger_user_name}
            </small>
          </p>
        )}
        {decline_reasons && (
          <p className="fw-bold text-black-50">
            Reason:{" "}
            <small className="small capitialize">{decline_reasons}</small>
          </p>
        )}
      </div>
      {role == AppConstants.roleTypes.Admin && !disabled ? (
        <div className="col-2 d-flex align-items-center justify-content-end">
          <a className="me-2" onClick={() => handle_edit(annual_leave_id)}>
            <i className="ri-edit-line fs-4 text-primary" />
          </a>
          <a
            data-mdb-toggle="modal"
            data-mdb-target="#abenceWarn"
            onClick={() => handle_delete(annual_leave_id, absence_type)}
          >
            <i className="ri-delete-bin-line fs-4 text-primary" />
          </a>
        </div>
      ) : enableDeleteProfileAbsence && enableDelete == 0 && !disabled ? (
        <a
          data-mdb-toggle="modal"
          data-mdb-target="#abenceWarn"
          onClick={() => handle_delete(annual_leave_id, absence_type)}
        >
          <i className="ri-delete-bin-line fs-4 text-primary" />
        </a>
      ) : null}
    </div>
  );
}

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Applogger } from "../../../Helpers/Logger";
import { showFaliureToast, showSuccessToast } from "../../../Utilities";
import {
  getUserEmployementContractInfo,
  getUserEmploymentSalaryInformation,
  updateUserEmployementSalaryInformation,
  getUserEmploymentSensitiveInformation,
  updateUserEmploymentSensitiveInformation,
  updateUserContractInfo,
  updateUserEmploymentInfo,
} from "../../../Redux/reducers/ProfileReducers";
import ContractInfoUserEmployement from "./ContractInfoUserEmployement";
import SalaryInfoUserEmployement from "./SalaryInfoUserEmployement";
import SensitiveInfoUserEmployement from "./SensitiveInfoUserEmployement";

export default function UserEmployement({ currentUserId }) {
  const dispatch = useDispatch();

  const { token } = useSelector((state) => state.AuthenticationReducer);
  const { countries } = useSelector((state) => state.employees);
  const { lng } = useSelector((state) => state.languageReducer);
  const { userEmployementSensitiveInfo } = useSelector(
    (state) => state.profile
  );

  const getUserSalaryInfo = () => {
    dispatch(
      getUserEmploymentSalaryInformation({ token, id: currentUserId, lng })
    )
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getUserEmploymentSalaryInformation", res);
      })
      .catch((err) => {
        Applogger("Error at getUserEmploymentSalaryInformation", err);
      });
  };

  const updateUserSalaryInfoRequest = (salaryInfoBody) => {
    dispatch(
      updateUserEmployementSalaryInformation({
        token,
        salaryInfoBody,
        id: currentUserId,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        getUserSalaryInfo();
        showSuccessToast(res?.data ?? "Salary Info Updated Successfully");
        Applogger("Response at updateUserEmployementSalaryInformation", res);
      })
      .catch((err) => {
        showFaliureToast("Salary Info Update Failed");
        Applogger("Error at updateUserEmployementSalaryInformation", err);
      });
  };

  const getUserSensitiveInfoRequest = () => {
    dispatch(
      getUserEmploymentSensitiveInformation({ token, id: currentUserId, lng })
    )
      .then((response) => {
        Applogger(
          "Response at getUserEmploymentSensitiveInformation",
          response
        );
      })
      .catch((err) => {
        Applogger("Error at getUserEmploymentSensitiveInformation", err);
      });
  };

  const updateUserSensitiveInfoRequest = (sensitiveInfo) => {
    dispatch(
      updateUserEmploymentSensitiveInformation({
        token,
        sensitiveInfo,
        id: currentUserId,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        getUserSensitiveInfoRequest();
        showSuccessToast(res?.data ?? "Sensitive Info Updated Successfully");
        Applogger("Response at updateUserEmploymentSensitiveInformation", res);
      })
      .catch((err) => {
        showFaliureToast("Sensitive Info Update Failed");
        Applogger("Error at updateUserEmploymentSensitiveInformation", err);
      });
  };

  const handleUpdateUserEmploymentInfo = (employmentDetails) => {
    dispatch(
      updateUserEmploymentInfo({ token, employmentDetails, id: currentUserId })
    )
      .then(unwrapResult)
      .then((res) => {
        showSuccessToast(
          res?.message ?? "Employment information updated successfully"
        );
        Applogger("Response at updateUserEmploymentInfo", res);
      })
      .catch((err) => {
        showFaliureToast("Failed to update employment information");
        Applogger("Error at updateUserEmploymentInfo", err);
      });
  };

  const getUserContractInfo = () => {
    dispatch(getUserEmployementContractInfo({ token, id: currentUserId, lng }))
      .then(unwrapResult)
      .then((response) => {
        Applogger("Response at getUserEmployementContractInfo", response);
      })
      .catch((err) => {
        Applogger("Error at getUserEmployementContractInfo", err);
      });
  };

  const updateUserContractInfoRequest = (contractInfoBody) => {
    dispatch(
      updateUserContractInfo({ token, contractInfoBody, id: currentUserId })
    )
      .then(unwrapResult)
      .then((res) => {
        showSuccessToast(
          res.message ?? "Contract information updated successfully"
        );
        getUserContractInfo();
        Applogger("Response at updateUserContractInfo", res);
      })
      .catch((err) => {
        showFaliureToast(
          err.message ?? "Failed to update contract information"
        );
        Applogger("Error at updateUserContractInfo", err);
      });
  };

  return (
    <div>
      <div className="d-flex justify-content-around gap-2 flex-wrap-sm animate__animated animate__fadeInUp">
        <div className="col-lg-6 col-sm-12">
          <ContractInfoUserEmployement
            updateUserEmploymentInfo={handleUpdateUserEmploymentInfo} // For Role, Country Workplace
            updateUserContractInfo={updateUserContractInfoRequest}
            updateForm={updateUserContractInfoRequest}
            id={currentUserId}
            updateInfo={updateUserContractInfoRequest}
          />
        </div>
        <div className="col-lg-6 col-sm-12">
          <SalaryInfoUserEmployement
            updateSalaryInfo={updateUserSalaryInfoRequest}
            id={currentUserId}
          />
          <br />
          <SensitiveInfoUserEmployement
            id={currentUserId}
            countries={countries.length > 0 && countries}
            updateSensitiveInfo={updateUserSensitiveInfoRequest}
            sensitiveInfo={
              userEmployementSensitiveInfo !== null &&
              userEmployementSensitiveInfo
            }
          />
        </div>
      </div>
    </div>
  );
}

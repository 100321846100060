import React from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
export default function ClockPopup({
  onCancel,
  modalStatus,
  status,
  user,
  toggleClock,
  clockStatus,
  clockInOutBreak,
}) {
  const { t } = useTranslation();
  const clockStatusHandler = () => {
    if (clockStatus == "clocked_in") {
      return clockInOutBreak ?? false;
    } else {
      return clockInOutBreak ?? false;
    }
  };

  return (
    <Modal
      title={`Are you sure you want to be Clocked ${status}`}
      centered
      onCancel={onCancel}
      visible={modalStatus}
      width={500}
      footer={false}
    >
      {clockStatusHandler() && (
        <button onClick={toggleClock} className="btn btn-primary-cs">
          {t("yes")}
        </button>
      )}
      <button onClick={onCancel} className="btn btn-primary-outline-cs ml-2">
        {t("cancel")}
      </button>
    </Modal>
  );
}

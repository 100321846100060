import React from "react";
import { AppImages } from "../../Helpers/AppImages";
export default function CategoryButton(props) {
  return (
    <div
      onMouseOut={() => props.ResetActive()}
      onClick={props.onClick}
      onMouseOver={() => props.onChange()}
      className="staffingCheckBox col-12 col-sm-12 col-md-4"
    >
      <div
        className={`card text-center sec-cs-border rota-crd position-relative ${
          props.active == true ? "active" : ""
        }`}
      >
        <div className="card-body">
          <div className="d-flex flex-column align-items-center">
            <img src={AppImages.def_icon} width={160} />
            <h6>{props.title}</h6>
            <p className="card-text small">{props.subtitle}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

import EventResource from "../../Config/EventResource";
import { APIConstants } from "../../Config/ApiConstants";
import { getHeaders } from "../../Helpers/commonFunc";

function generateAbsenceReport(payload) {
  const { body, lng } = payload;
  const { token, startDate, endDate, type } = body;
  let data = new FormData();
  data.append("start_date", startDate);
  data.append("end_date", endDate);
  const path = `${APIConstants.absenceReport}${type}?lng=${lng}`;
  return EventResource.post(path, data, getHeaders(token));
}

function generateLatenessReport(payload) {
  const { body, lng } = payload;
  const { token, startDate, endDate, type } = body;
  let data = new FormData();
  data.append("start_date", startDate);
  data.append("end_date", endDate);

  const path = `${APIConstants.latenessReport}${type}?lng=${lng}`;
  return EventResource.post(path, data, getHeaders(token));
}

function generateAnnualLeaveSummaryReport(payload) {
  const { body, lng } = payload;
  const { token, dateRange, type } = body;
  let data = new FormData();
  data.append("date_range", dateRange);
  const path = `${APIConstants.annualLeaveSummaryReport}${type}?lng=${lng}`;
  return EventResource.post(path, data, getHeaders(token));
}

function generateEmployeeDetailsReport(payload) {
  const { body, lng } = payload;
  const { token, emp_id } = body;

  let path = APIConstants.employeeDetailsReport;
  if (emp_id) {
    path += `/${emp_id}`;
  }
  path += `?lng=${lng}`;

  return EventResource.get(path, getHeaders(token));
}

function generateTimeSheetReport(payload) {
  const { body, lng } = payload;
  const { token, emp_id, startDate, endDate } = body;
  let data = new FormData();
  data.append("start_date", startDate);
  data.append("end_date", endDate);
  let path = APIConstants.timesheetReport;
  if (emp_id) {
    path += `/${emp_id}`;
  }
  path += `?lng=${lng}`;

  return EventResource.post(path, data, getHeaders(token));
}

export const ReportsApiServices = {
  generateAbsenceReport,
  generateAnnualLeaveSummaryReport,
  generateEmployeeDetailsReport,
  generateTimeSheetReport,
  generateLatenessReport,
};

import React, { useState, useEffect } from "react";
import { Space, Table } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Applogger } from "../../Helpers/Logger";
import { getCountries } from "../../Redux/reducers/EmployeesReducer";
import {
  getCalculatedTableHeight,
  showFaliureToast,
  showSuccessToast,
} from "../../Utilities";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import {
  getPlacesOfWork,
  deletePlacesOfWorkRequest,
} from "../../Redux/reducers/SettingsReducer";
import Button from "./Button";
import Spinner from "../Spinner";
import PlaceOfWork from "../Modals/PlaceOfWork";
import PeopleListModal from "../Modals/PeopleListModal";
import AppConstants from "../../Helpers/AppConstants";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";
import "./styles.css";

export default function LocationType({ isOnboarding = false }) {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { token, user, role } = useSelector(
    (state) => state.AuthenticationReducer
  );
  const { countries } = useSelector((state) => state.employees);
  const { lng } = useSelector((state) => state.languageReducer);
  const { placesOfWork, loading } = useSelector((state) => state.settings);

  const companyId = get(user, "company_id", "");

  const { exist: createLocation } = usePrevilagesExist(
    AppConstants.previlages.createLocation
  );

  const { exist: updateDeleteLocation } = usePrevilagesExist(
    AppConstants.previlages.updateDeleteLocation
  );
  const { exist: listEmployees } = usePrevilagesExist(
    AppConstants.previlages.listEmployees
  );

  const [currentPlaceData, setCurrentPlaceData] = useState(null);
  const [showLocationModal, setShowEmployeeModal] = useState(false);
  const [showViewEmployeeModal, setShowViewEmployeeModal] = useState(false);

  useEffect(() => {
    // if (placesOfWork.length == 0) {
    callGetWorkPlacesAPI();
    // }
  }, []);

  useEffect(() => {
    if (countries.length === 0) {
      getAllCountries();
    }
  }, [countries]);

  function getAllCountries() {
    dispatch(getCountries({ token, lng, companyId }))
      .then(unwrapResult)
      .then((response) => {
        Applogger("Response at getCountries", response);
      })
      .catch((err) => {
        Applogger("Error at getCountries", err.message);
      });
  }

  const callGetWorkPlacesAPI = () => {
    dispatch(getPlacesOfWork({ token, lng, companyId }))
      .then(unwrapResult)
      .then((response) => {
        Applogger("Response at getPlacesOfWork", response);
      })
      .catch((err) => {
        Applogger("Error at getPlacesOfWork", err.message);
      });
  };

  const deletePlacesOfWork = (id) => {
    try {
      dispatch(deletePlacesOfWorkRequest({ token, id, lng, companyId }))
        .then(unwrapResult)
        .then((res) => {
          callGetWorkPlacesAPI();
          Applogger("Response at deletePlacesOfWorkRequest", res);
          showSuccessToast(res);
        })
        .catch((err) => {
          Applogger("Error at deletePlacesOfWorkRequest", err);
          if (typeof err == "string") {
            showFaliureToast(err);
          }
        });
    } catch (err) {
      Applogger("Error at deletePlacesOfWork", err);
    }
  };

  const columns = [
    {
      title: t("locationType"),
      dataIndex: "location_type_name",
      key: "location_type_name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("address"),
      dataIndex: "address_line1",
      key: "address_line1",
    },
    {
      title: t("postalCode"),
      key: "postcode",
      dataIndex: "postcode",
      render: (text) => <a>{text}</a>,
    },
    {
      width: 350,
      title: t("action"),
      key: "action",
      render: (data) => (
        <Space size="middle">
          <>
            {listEmployees && (
              <Button
                type={"primary"}
                onClick={() => {
                  setCurrentPlaceData(data);
                  setShowViewEmployeeModal(true);
                }}
              >
                {t("viewPeople")}
              </Button>
            )}
            {updateDeleteLocation && (
              <>
                <Button
                  type={"primary"}
                  onClick={() => {
                    setCurrentPlaceData(data);
                    setShowEmployeeModal(true);
                  }}
                >
                  {t("edit")}
                </Button>
                <Button
                  disabled={get(data, "is_hq", 0)}
                  onClick={() =>
                    deletePlacesOfWork(get(data, "work_place_id", ""))
                  }
                >
                  {t("delete")}
                </Button>
              </>
            )}
          </>
        </Space>
      ),
    },
  ];

  return (
    <div>
      {loading == "pending" && <Spinner />}
      <PlaceOfWork
        radiusRequired={!isOnboarding}
        companyId={companyId}
        isModalVisible={showLocationModal}
        placeOfWork={currentPlaceData}
        handleCancel={() => {
          setShowEmployeeModal(false);
          setCurrentPlaceData(null);
        }}
      />
      <PeopleListModal
        isModalVisible={showViewEmployeeModal}
        location={currentPlaceData}
        handleCancel={() => {
          setShowViewEmployeeModal(false);
          setCurrentPlaceData(null);
        }}
      />
      {createLocation && (
        <div className="d-flex justify-content-end my-3 align-items-end">
          <Button
            onClick={() => {
              setCurrentPlaceData(null);
              setShowEmployeeModal(true);
            }}
          >
            {t("addALocation")}
          </Button>
        </div>
      )}
      <Table
        // className="mainComponentHeight height-fit-content"
        columns={columns}
        dataSource={placesOfWork.filter(
          (item) => !AppConstants.placeOfWorkArray.includes(item)
        )}
        // scroll={{ y: isOnboarding ? getCalculatedTableHeight(270) : 400 }}
        scroll={{ x: 400 }}
        pagination={{ pageSize: AppConstants.pagination.size }}
      />
    </div>
  );
}

import EventResource from "../../Config/EventResource";
import { APIConstants } from "../../Config/ApiConstants";
import { getHeaders } from "../../Helpers/commonFunc";
import { Applogger } from "../../Helpers/Logger";
import AppConstants from "../../Helpers/AppConstants";

function apiResendInvitationRequest(payload) {
  const { token, userId } = payload;
  const path = APIConstants.resentInvite + userId;
  return EventResource.post(path, null, getHeaders(token));
}

function getUserAbsence(payload) {
  const { start_date, end_date } = payload.Data;
  const absenceDates = new FormData();
  absenceDates.append("start_date", start_date);
  absenceDates.append("end_date", end_date);

  return EventResource.post(
    `${APIConstants.getUserAbsence}${payload.user_id}/`,
    absenceDates,
    getHeaders(payload.bearer_token)
  );
}

function apiResetUserPassword(payload) {
  const { requestBody, token } = payload;
  const finalBody = new FormData();
  finalBody.append("old_password", requestBody.currentPassword);
  finalBody.append("password", requestBody.newPassword);
  finalBody.append("password_confirm", requestBody.confirmNewPassword);
  return EventResource.post(
    APIConstants.updateUserPassword,
    finalBody,
    getHeaders(token)
  );
}

function deletetUserAbsence(payload) {
  return EventResource.get(
    `${APIConstants.deleteAbsence}${payload.annual_leave_id}?lng=${payload.lng}`,
    getHeaders(payload.bearer_token)
  );
}

function apiGetUserLog(payload) {
  return EventResource.get(
    `${APIConstants.editLog}${payload.log_id}?lng=${payload.lng}`,
    getHeaders(payload.bearer_token)
  );
}
function apiGetUserProfile(payload) {
  return EventResource.get(
    `${APIConstants.userProfile}${
      payload.user_id != null ? payload.user_id : ""
    }?lng=${payload.lng}`,
    getHeaders(payload.bearer_token)
  );
}

function getUserOverTimeDetails(payload) {
  const { start_date, end_date } = payload.Data;
  const absenceDates = new FormData();
  absenceDates.append("start_date", start_date);
  absenceDates.append("end_date", end_date);
  return EventResource.post(
    `${APIConstants.userProfileOverTime}${payload.user_id}/`,
    absenceDates,
    getHeaders(payload.token)
  );
}
function getUserOverTimeOffDetails(payload) {
  const { start_date, end_date } = payload.Data;
  const absenceDates = new FormData();
  absenceDates.append("start_date", start_date);
  absenceDates.append("end_date", end_date);
  return EventResource.post(
    `${APIConstants.logTImeOFF}${payload.user_id}/`,
    absenceDates,
    getHeaders(payload.token)
  );
}

function deleteUserProfileImage(payload) {
  return EventResource.get(
    APIConstants.deleteProfilePicture + payload.id,
    getHeaders(payload.token)
  );
}
function changeUserProfileStatus(payload) {
  let data = new FormData();
  let path = APIConstants.ChangeProfileStatus;
  data.append("working_status", payload.currentStatus);
  if (payload.userId !== null) {
    path += payload.userId;
  }
  return EventResource.post(path, data, getHeaders(payload.token));
}

function getUserProfileContactInfoRequest(payload) {
  return EventResource.get(
    `${APIConstants.getUserProfileContactInfo}${payload.user_id}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

function updateUserProfileContactInfoRequest(payload) {
  const {
    accountEmail,
    homePhone,
    mobilePhone,
    personalEmail,
    workExtension,
    workPhone,
  } = payload.Data;
  const contactData = new FormData();

  contactData.append("email", accountEmail);
  contactData.append("home_phone", homePhone);
  contactData.append("mobile_phone", mobilePhone);
  contactData.append("personal_email", personalEmail);
  contactData.append("work_extension", workExtension);
  contactData.append("work_phone", workPhone);

  return EventResource.post(
    `${APIConstants.updateUserProfileContactInfo}${payload.user_id}/`,
    contactData,
    getHeaders(payload.token)
  );
}

function getUserProfilePersonalInfoRequest(payload) {
  return EventResource.get(
    `${APIConstants.getUserProfilePersonalInfo}${payload.user_id}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

function updateUserProfilePersonalInfoRequest(payload) {
  const { personalInfoBody, user_id, token } = payload;

  const profileData = new FormData();

  for (const [key, value] of Object.entries(personalInfoBody)) {
    if (value) {
      profileData.append(key, value);
    }
  }

  return EventResource.post(
    `${APIConstants.updateUserProfilePersonalInfo}${user_id}/`,
    profileData,
    getHeaders(token)
  );
}

function createEmergencyContactsRequest(payload) {
  const { user_id, token, emergencyContactBody } = payload;
  const emergencyData = new FormData();

  for (const [key, value] of Object.entries(emergencyContactBody)) {
    if (value) {
      emergencyData.append(key, value);
    }
  }

  return EventResource.post(
    `${APIConstants.saveUserProfileEmergencyContact}${user_id}/`,
    emergencyData,
    getHeaders(token)
  );
}

function getEmergencyContactsRequest(payload) {
  return EventResource.get(
    `${APIConstants.getUserEmergencyContacts}${payload.user_id}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

function updateEmergencyContactRequest(payload) {
  const { token, emergencyContactBody } = payload;
  const { id } = emergencyContactBody;
  const emergencyData = new FormData();

  for (const [key, value] of Object.entries(emergencyContactBody)) {
    if (value) {
      emergencyData.append(key, value);
    }
  }

  return EventResource.post(
    `${APIConstants.updateUserProfileEmergencyContact}${id}/`,
    emergencyData,
    getHeaders(token)
  );
}

function getUserMedicalInfoRequest(payload) {
  return EventResource.get(
    `${APIConstants.getUserMedicalInfo}${payload.user_id}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

function updateUserMedicalInfoRequest(payload) {
  const { medicalInfoData, user_id, token } = payload;

  const medicalData = new FormData();

  for (const [key, value] of Object.entries(medicalInfoData)) {
    medicalData.append(key, value);
  }

  return EventResource.post(
    `${APIConstants.updateUserMedicalInfo}${user_id}/`,
    medicalData,
    getHeaders(token)
  );
}

function deleteEmergencyContactRequest(payload) {
  const { contact_id, token } = payload;
  return EventResource.get(
    `${APIConstants.deleteUserEmergencyContact}${contact_id}/`,
    getHeaders(token)
  );
}

function cancelUserOverTimRequest(payload) {
  const formData = new FormData();
  formData.append("reason", payload.data);
  return EventResource.post(
    `${APIConstants.cancelUserOverTime}${payload.currentOverId}/`,
    formData,
    getHeaders(payload.token)
  );
}

function getUserEmployementContractInformationRequest(payload) {
  return EventResource.get(
    `${APIConstants.getUserEmploymentContractInfo}${payload.id}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

// function getUserEmployementRoleInformationRequest(payload) {
//   return EventResource.get(
//     `${APIConstants.getUserEmployementRoleInfo}${payload.id}?lng=${payload.lng}`,
//     getHeaders(payload.token)
//   );
// }

function getUserSalaryInformationRequest(payload) {
  return EventResource.get(
    `${APIConstants.getUserSalaryInfo}${payload.id}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

function updateUserSalaryInformationRequest(payload) {
  const { salaryInfoBody, id, token } = payload;

  const {
    payslip_password,
    payslip_email_address,
    payroll_number,
    gross_salary,
    payment_frequency,
    is_pension,
    is_opted_out,
    employee_contribution,
    employer_contribution,
    enrollment_date,
    salary_per_period,
    contracted_hours,
    contracted_hours_per_period,
  } = salaryInfoBody;

  const salaryInfoData = new FormData();

  salaryInfoData.append("payroll_number", payroll_number);
  salaryInfoData.append("gross_salary", gross_salary);
  salaryInfoData.append("payment_frequency", payment_frequency);
  salaryInfoData.append("is_pension", is_pension);
  salaryInfoData.append("is_opted_out", is_opted_out);
  if (salary_per_period) {
    salaryInfoData.append("salary_per_period", salary_per_period);
  }
  if (contracted_hours) {
    salaryInfoData.append("contracted_hours", contracted_hours);
  }
  if (contracted_hours_per_period) {
    salaryInfoData.append(
      "contracted_hours_per_period",
      contracted_hours_per_period
    );
  }
  if (payslip_password) {
    salaryInfoData.append("payslip_password", payslip_password);
  }
  if (payslip_email_address) {
    salaryInfoData.append("payslip_email_address", payslip_email_address);
  }
  if (is_opted_out == "0") {
    salaryInfoData.append("employee_contribution", employee_contribution);
    salaryInfoData.append("employer_contribution", employer_contribution);
    salaryInfoData.append("enrollment_date", enrollment_date);
  }

  return EventResource.post(
    `${APIConstants.updateUserSalaryInfo}${id}/`,
    salaryInfoData,
    getHeaders(token)
  );
}

function getUserSensitiveInformationRequest(payload) {
  return EventResource.get(
    `${APIConstants.getUserSensitiveInformation}${payload.id}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

function updateUserSensitiveInformationRequest(payload) {
  const { sensitiveInfo } = payload;
  const body = new FormData();
  for (const [key, value] of Object.entries(sensitiveInfo)) {
    if (value || value == 0) {
      body.append(key, value);
    }
  }

  return EventResource.post(
    `${APIConstants.updateUserSensitiveInformation}${payload.id}/`,
    body,
    getHeaders(payload.token)
  );
}

function updateUserContractAnnualLeaveRequest(payload) {
  const AnnualLeaveData = new FormData();
  AnnualLeaveData.append("current_annual_leave_allowance", payload.LeaveValue);
  return EventResource.post(
    `${APIConstants.updateUerEmployementAnnualLeave}${payload.id}/`,
    AnnualLeaveData,
    getHeaders(payload.token)
  );
}

function apiUpdateUserEmploymentInfo(payload) {
  const { employmentDetails, token, id } = payload;
  const { role_id, work_place_id, country_id } = employmentDetails;

  const body = new FormData();
  body.append("role_id", role_id);
  body.append("country_id", country_id);

  const checkWorkPlaceId = AppConstants.placeOfWorkArray.find(
    (item) => item.work_place_id == work_place_id
  );

  if (checkWorkPlaceId) {
    body.append("work_place_type", work_place_id);
  } else {
    body.append("work_place_id", work_place_id);
  }

  const path = `${APIConstants.updateUserEmpInfo}${id}/`;

  return EventResource.post(path, body, getHeaders(token));
}

function updateUserContractInfoRequest(payload) {
  const { contractInfoBody, id, token } = payload;

  const ContractFormData = new FormData();

  for (const [key, value] of Object.entries(contractInfoBody)) {
    let finalValue = value; // Chaipi

    if (typeof value == "number") {
      finalValue = finalValue.toString();
    }
    if (finalValue && finalValue != "N/A" && finalValue != "") {
      ContractFormData.append(key, finalValue);
    }
  }

  return EventResource.post(
    `${APIConstants.updateUserContractInfo}${id}`,
    ContractFormData,
    getHeaders(token)
  );
}

function createUserLogOverTimeRequest(payload) {
  const {
    overTimeData,
    currentTimeStartHour,
    currentTimeStartMin,
    currentDateStart,
    currentTimeEndHour,
    currentTimeEndMin,
    currentDateEnd,
    note,
  } = payload.logData;
  const userOverTimeData = new FormData();
  userOverTimeData.append("start_date", currentDateStart);
  userOverTimeData.append("end_date", currentDateEnd);
  userOverTimeData.append("note", note);
  userOverTimeData.append("start_hour", currentTimeStartHour);
  userOverTimeData.append("start_min", currentTimeStartMin);
  userOverTimeData.append("end_hour", currentTimeEndHour);
  userOverTimeData.append("end_min", currentTimeEndMin);

  userOverTimeData.append("reward", overTimeData.rewardType);

  userOverTimeData.append("overtime_length_hours", overTimeData.overTimeHours);
  userOverTimeData.append("overtime_length_min", overTimeData.overTimeMinutes);
  userOverTimeData.append("pay_rate", overTimeData.payRate);
  userOverTimeData.append("user_id", payload.user_id);

  return EventResource.post(
    APIConstants.createUserOverTime,
    userOverTimeData,
    getHeaders(payload.token)
  );
}
function updateLogOverTime(payload) {
  const {
    overTimeData,
    currentTimeStartHour,
    currentTimeStartMin,
    currentDateStart,
    currentTimeEndHour,
    currentTimeEndMin,
    currentDateEnd,
    note,
  } = payload.logData;
  const userOverTimeData = new FormData();
  userOverTimeData.append("start_date", currentDateStart);
  userOverTimeData.append("end_date", currentDateEnd);
  userOverTimeData.append("note", note);
  userOverTimeData.append("start_hour", currentTimeStartHour);
  userOverTimeData.append("start_min", currentTimeStartMin);
  userOverTimeData.append("end_hour", currentTimeEndHour);
  userOverTimeData.append("end_min", currentTimeEndMin);
  userOverTimeData.append("overtime_length_hours", overTimeData.overTimeHours);
  userOverTimeData.append("overtime_length_min", overTimeData.overTimeMinutes);

  return EventResource.post(
    `${APIConstants.updateOverTime}${payload.log_id}`,
    userOverTimeData,
    getHeaders(payload.token)
  );
}

function updateUserImageRequest(payload) {
  const userImageData = new FormData();
  userImageData.append("profile_image", payload.image);
  return EventResource.post(
    `${APIConstants.updateUserImage}${payload.id}`,
    userImageData,
    getHeaders(payload.token, { "Content-Type": "multipart/form-data" })
  );
}

export const ProfileApiServices = {
  apiResetUserPassword,
  apiResendInvitationRequest,
  apiGetUserProfile,
  changeUserProfileStatus,
  getUserOverTimeDetails,
  getUserProfileContactInfoRequest,
  getUserProfilePersonalInfoRequest,
  updateUserProfileContactInfoRequest,
  updateUserProfilePersonalInfoRequest,
  getEmergencyContactsRequest,
  createEmergencyContactsRequest,
  updateEmergencyContactRequest,
  deleteEmergencyContactRequest,
  cancelUserOverTimRequest,
  getUserEmployementContractInformationRequest,
  getUserSalaryInformationRequest,
  updateUserSalaryInformationRequest,
  getUserSensitiveInformationRequest,
  updateUserSensitiveInformationRequest,
  updateUserContractAnnualLeaveRequest,
  updateUserContractInfoRequest,
  createUserLogOverTimeRequest,
  getUserOverTimeOffDetails,
  apiGetUserLog,
  getUserAbsence,
  deletetUserAbsence,
  updateLogOverTime,
  updateUserImageRequest,
  getUserMedicalInfoRequest,
  updateUserMedicalInfoRequest,
  deleteUserProfileImage,
  apiUpdateUserEmploymentInfo,
};

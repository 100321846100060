import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { unixToDateFormaterOld } from "../../Utilities";
import { useTranslation } from "react-i18next";
import AppConstants from "../../Helpers/AppConstants";

export default function StaffingDeleteModal({
  currentStaff,
  deleteStaffing,
  onCancel,
  modalStatus,
}) {
  const [currentStaffing, setCurrentStaffing] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (currentStaff) {
      setCurrentStaffing(currentStaff);
    }
  }, [currentStaff]);
  return (
    <Modal
      title={t("deleteStaff")}
      centered
      onCancel={onCancel}
      visible={modalStatus}
      width={500}
      footer={false}
    >
      <div className="modal-body modal-cs d-flex flex-column gap-2">
        <p>
          {`${t("confirmStaffDeletion")} ${
            currentStaffing && currentStaffing.name
          } ${t("withStartDate")} ${
            currentStaffing &&
            unixToDateFormaterOld(
              currentStaffing.start_date,
              AppConstants.defaultDateFormat
            )
          }   ? ${t("allocatedEmployees")}`}
        </p>
        <div className="d-flex gap-2">
          <button
            onClick={() => deleteStaffing(currentStaffing)}
            className="btn btn-primary-cs fs-16"
          >
            {t("delete")}
          </button>
          <button
            onClick={onCancel}
            className="btn btn-primary-outline-cs fs-16"
          >
            {t("cancel")}
          </button>
        </div>
      </div>
    </Modal>
  );
}

import React from "react";

export default function OBInputField({
  title = "",
  value = "",
  onChange = () => {},
  placeholder = "",
  isRequired = false,
}) {
  return (
    <div
      className="d-flex flex-wrap-sm mb-2"
      style={{ flexDirection: "column" }}
    >
      {title && (
        <p className="mb-0 col-sm-12">
          {title}
          {isRequired && <span className="text-danger">*</span>}
        </p>
      )}
      <input
        className={"filter-form"}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

import React from "react";
import logo from "../../assets/images/logo.png";
import AppConstants from "../../Helpers/AppConstants";
import { useTranslation } from "react-i18next";
import { useTitleHandler } from "../../customHooks/useTitleHandler";

export default function Terms() {
  // Localisation
  const { t } = useTranslation();

  // Hooks
  useTitleHandler(t("termsAndConditions"));

  return (
    <div>
      <nav dir="ltr" className="navigation">
        <a href={AppConstants.routes.main}>
          <img src={logo} style={{ width: "180px" }} />
        </a>
      </nav>
      <div className="p-5">
        <h2 class="heading"> Terms and Conditions</h2>
        <p> Last updated: February 08, 2023</p>
        <p>
          Please read these terms and conditions carefully before using our
          Service.
        </p>
        <h2> Interpretation and Definitions</h2>
        <h4>Interpretation</h4>
        <p>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural. Definitions For the purposes of these Terms and
          Conditions: Application means the software program provided by the
          Company downloaded by You on any electronic device, named SpeedyHR
          Application Store means the digital distribution service operated and
          developed by Apple Inc. (Apple App Store) or Google Inc. (Google Play
          Store) in which the Application has been downloaded. Country refers
          to: United Kingdom Company (referred to as either "the Company", "We",
          "Us" or "Our" in this Agreement) refers to SpeedyHR. Device means any
          device that can access the Service such as a computer, a cellphone or
          a digital tablet. Service refers to the Application or the Website or
          both. Terms and Conditions (also referred as "Terms") mean these Terms
          and Conditions that form the entire agreement between You and the
          Company regarding the use of the Service. This Terms and Conditions
          agreement has been created with the help of the TermsFeed Terms and
          Conditions Generator. Third-party Social Media Service means any
          services or content (including data, information, products or
          services) provided by a third-party that may be displayed, included or
          made available by the Service. Website refers to SpeedyHR, accessible
          from http://speedyhr.co.uk/ You means the individual accessing or
          using the Service, or the company, or other legal entity on behalf of
          which such individual is accessing or using the Service, as
          applicable.
        </p>
        <h4>Acknowledgment</h4>
        <p>
          These are the Terms and Conditions governing the use of this Service
          and the agreement that operates between you and the Company. These
          Terms and Conditions set out the rights and obligations of all users
          regarding the use of the Service. Your access to and use of the
          Service is conditioned on your acceptance of and compliance with these
          Terms and Conditions. These Terms and Conditions apply to all
          visitors, users and others who access or use the Service. By accessing
          or using the Service you agree to be bound by these Terms and
          Conditions. If You disagree with any part of these Terms and
          Conditions then you may not access the Service. You represent that you
          are over the age of 18. The Company does not permit those under 18 to
          use the Service.
        </p>
        Your access to and use of the Service is also conditioned on your
        acceptance of and compliance with the Privacy Policy of the Company. Our
        Privacy Policy describes our policies and procedures on the collection,
        use and disclosure of your personal information when you use the
        Application or the Website and tells you about your privacy rights and
        how the law protects you. Please read our Privacy Policy carefully
        before using our Service.
        <p> Links to Other Websites</p>
        <p>
          Our Service may contain links to third-party web sites or services
          that are not owned or controlled by the Company. The Company has no
          control over, and assumes no responsibility for, the content, privacy
          policies, or practices of any third party web sites or services. You
          further acknowledge and agree that the Company shall not be
          responsible or liable, directly or indirectly, for any damage or loss
          caused or alleged to be caused by or in connection with the use of or
          reliance on any such content, goods or services available on or
          through any such web sites or services. We strongly advise you to read
          the terms and conditions and privacy policies of any third-party web
          sites or services that you visit. Termination We may terminate or
          suspend your access immediately, without prior notice or liability,
          for any reason whatsoever, including without limitation if you breach
          these Terms and Conditions.
        </p>
        <p>
          Upon termination, your right to use the Service will cease
          immediately.
        </p>
        <h4> Limitation of Liability</h4>
        <p>
          To the maximum extent permitted by applicable law, in no event shall
          the Company or its suppliers be liable for any special, incidental,
          indirect, or consequential damages whatsoever (including, but not
          limited to, damages for loss of profits, loss of data or other
          information, for business interruption, for personal injury, loss of
          privacy arising out of or in any way related to the use of or
          inability to use the Service, third-party software and/or third-party
          hardware used with the Service, or otherwise in connection with any
          provision of this Terms), even if the Company or any supplier has been
          advised of the possibility of such damages and even if the remedy
          fails of its essential purpose. Some states do not allow the exclusion
          of implied warranties or limitation of liability for incidental or
          consequential damages, which means that some of the above limitations
          may not apply. In these circumstances, each party's liability will be
          limited to the greatest extent permitted by law. "AS IS" and "AS
          AVAILABLE" Disclaimer
        </p>
        <p>
          The Service is provided to you "AS IS" and "AS AVAILABLE" and with all
          faults and defects without warranty of any kind. To the maximum extent
          permitted under applicable law, the Company, on its own behalf and on
          behalf of its affiliates and its and their respective licensors and
          service providers, expressly disclaims all warranties, whether
          express, implied, statutory or otherwise, with respect to the Service,
          including all implied warranties of merchantability, fitness for a
          particular purpose, title and non-infringement, and warranties that
          may arise out of course of dealing, course of performance, usage or
          trade practice. Without limitation to the foregoing, the Company
          provides no warranty or undertaking, and makes no representation of
          any kind that the Service will meet your requirements, achieve any
          intended results, be compatible or work with any other software,
          applications, systems or services, operate without interruption, meet
          any performance or reliability standards or be error free or that any
          errors or defects can or will be corrected. Without limiting the
          foregoing, neither the Company nor any of the company's provider makes
          any representation or warranty of any kind, express or implied:
          <ul>
            <br />
            <li>
              (i) as to the operation or availability of the Service, or the
              information, content, and materials or products included thereon;
            </li>
            <br />

            <li>(ii) that the Service will be uninterrupted or error-free;</li>
            <br />

            <li>
              (iii) as to the accuracy, reliability, or currency of any
              information or content provided through the Service; or
            </li>
            <br />

            <li>
              (iv) that the Service, its servers, the content, or e-mails sent
              from or on behalf of the Company are free of viruses, scripts,
              trojan horses, worms, malware, timebombs or other harmful
              components.
            </li>
          </ul>
          <br />
        </p>
        <p>
          Some jurisdictions do not allow the exclusion of certain types of
          warranties or limitations on applicable statutory rights of a
          consumer, so some or all of the above exclusions and limitations may
          not apply to you. But in such a case the exclusions and limitations
          set forth in this section shall be applied to the greatest extent
          enforceable under applicable law. Governing Law
          <br />
          The laws of the Country, excluding its conflicts of law rules, shall
          govern this Terms and your use of the Service. Your use of the
          Application may also be subject to other local, state, national, or
          international laws.
          <h6> Disputes Resolution</h6>
          <p>
            If you have any concern or dispute about the Service, you agree to
            first try to resolve the dispute informally by contacting the
            Company. Severability and Waiver
          </p>
          <h6> Severability</h6>
          <p>
            If any provision of these Terms is held to be unenforceable or
            invalid, such provision will be changed and interpreted to
            accomplish the objectives of such provision to the greatest extent
            possible under applicable law and the remaining provisions will
            continue in full force and effect. Waiver
          </p>
          <p>
            Except as provided herein, the failure to exercise a right or to
            require performance of an obligation under these Terms shall not
            effect a party's ability to exercise such right or require such
            performance at any time thereafter nor shall the waiver of a breach
            constitute a waiver of any subsequent breach. Translation
            Interpretation
          </p>
          <p>
            These Terms and Conditions may have been translated if we have made
            them available to You on our Service. You agree that the original
            English text shall prevail in the case of a dispute.
          </p>
        </p>
        <p>Changes to These Terms and Conditions</p>
        <p>
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material we will make
          reasonable efforts to provide at least 30 days' notice prior to any
          new terms taking effect. What constitutes a material change will be
          determined at our sole discretion. By continuing to access or use our
          Service after those revisions become effective, you agree to be bound
          by the revised terms. If you do not agree to the new terms, in whole
          or in part, please stop using the website and the Service.
        </p>
        <h4>Contact Us</h4>
        <p>
          If you have any questions about these Terms and Conditions, please
          contact us.
        </p>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse, Col, Row, Checkbox } from "antd";
import { getEmployeeName } from "../../Utilities";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function AssignEmployee({
  employeeSelect,
  selectedEmployeees,
  click,
  updated,
}) {
  const { allEmployees } = useSelector((state) => state.employees);
  const { Panel } = Collapse;
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [assignableEmployees, setAssignableEmployees] = useState([]);
  const [inputText, setInputText] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedEmployees([]);
    setAssignableEmployees([]);
  }, [click]);

  useEffect(() => {
    if (allEmployees) {
      assignableEmployeehandler();
    }
    return () => {
      setSelectedEmployees([]);
      setAssignableEmployees([]);
    };
  }, [allEmployees, selectedEmployeees, updated]);

  const assignableEmployeehandler = () => {
    const allEmployeeArray = [...allEmployees];
    for (let i = 0; i < selectedEmployeees.length; i++) {
      const element = selectedEmployeees[i];

      const currentEmployeeIndex = allEmployeeArray.findIndex(
        (employee) => employee.user_id === element.user_id
      );
      if (currentEmployeeIndex !== -1) {
        allEmployeeArray.splice(currentEmployeeIndex, 1);
      }
    }
    setAssignableEmployees(allEmployeeArray);
  };

  const onSelectedEmployeeClick = (employee) => {
    var filteredArray = [];
    if (selectedEmployees.includes(employee)) {
      filteredArray = selectedEmployees.filter(
        (data) => data.user_id != employee.user_id
      );
      setSelectedEmployees(filteredArray);
      employeeSelect(filteredArray);
    }
  };

  const isEmployeeSelected = (employee) => {
    return selectedEmployees.includes(employee);
  };

  const onUserFilterHandler = (event) => {
    setInputText(event.target.value);
    var filteredArray = [];
    const allEmployeeArray = [...allEmployees];
    for (let i = 0; i < selectedEmployeees.length; i++) {
      const element = selectedEmployeees[i];

      const currentEmployeeIndex = allEmployeeArray.findIndex(
        (employee) => employee.user_id === element.user_id
      );
      if (currentEmployeeIndex !== -1) {
        allEmployeeArray.splice(currentEmployeeIndex, 1);
      }
    }

    const filteredUsers = allEmployeeArray.filter(
      (item) =>
        item.first_name.toLocaleLowerCase().includes(event.target.value) ||
        item.last_name.toLocaleLowerCase().includes(event.target.value) ||
        item.email.toLocaleLowerCase().includes(event.target.value)
    );

    setAssignableEmployees(filteredUsers);
  };

  const onAssignableEmployeeClick = (employee) => {
    var filteredArray = [];
    if (selectedEmployees.includes(employee)) {
      filteredArray = selectedEmployees.filter(
        (data) => data.user_id != employee.user_id
      );
      setSelectedEmployees(filteredArray);
      employeeSelect(filteredArray);
    } else {
      setSelectedEmployees((selectedEmployees) => [
        ...selectedEmployees,
        employee,
      ]);
      var employees = [...selectedEmployees];
      employees.push(employee);
      employeeSelect(employees);
    }
  };

  const onAssignableAllClickHandler = (status) => {
    if (status == "all") {
      setSelectedEmployees(assignableEmployees);
    } else {
      setSelectedEmployees([]);
    }
  };

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={["2"]}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      className="site-collapse-custom-collapse"
    >
      {/* <div
        className="site-card-wrapper d-flex align-items-end"
        style={{
          alignContent: "flex-end",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <button
          onClick={() =>
            onAssignableAllClickHandler(
              selectedEmployees.length == assignableEmployees.length
                ? ""
                : "all"
            )
          }
          className="btn btn-primary-outline-cs"
        >
          {selectedEmployees.length == assignableEmployees.length
            ? "Unassign All"
            : "Assign All"}
        </button>
      </div> */}
      <Panel
        header={`${t("selected")} (${selectedEmployees.length})`}
        key="1"
        className="site-collapse-custom-panel"
      >
        <div className="site-card-wrapper">
          <Row gutter={16} className={"w-100"}>
            {selectedEmployees.length > 0 ? (
              selectedEmployees.map((item, index) => {
                return (
                  <EmployeeCell
                    onClick={() => onSelectedEmployeeClick(item)}
                    isSelected={isEmployeeSelected(item)}
                    employee={item}
                    index={index}
                  />
                );
              })
            ) : (
              <div>
                <p>{t("noEmployeeSelected")}</p>
              </div>
            )}
          </Row>
        </div>
      </Panel>
      <div className="row">
        <div className="col-5">
          <label>{t("searchEmployees")}</label>
          <input
            value={inputText}
            onChange={onUserFilterHandler}
            className="filter-form"
            placeholder={t("employeeName")}
          />
        </div>
      </div>

      <Panel
        header={`${t("assignable")} ${assignableEmployees.length}`}
        key="2"
        className="site-collapse-custom-panel"
      >
        <div className="site-card-wrapper">
          {/* <div className="row">
            <div className="col-6">Assign All</div>
          </div> */}
          <Row gutter={16} className={"w-100"}>
            {assignableEmployees.length > 0 ? (
              assignableEmployees.map((item, index) => {
                return (
                  <EmployeeCell
                    onClick={() => onAssignableEmployeeClick(item)}
                    isSelected={isEmployeeSelected(item)}
                    employee={item}
                    index={index}
                  />
                );
              })
            ) : (
              <div>
                <p>{t("noAssignableEmployeeFound")}</p>
              </div>
            )}
          </Row>
        </div>
      </Panel>
    </Collapse>
  );
}

const EmployeeCell = ({ onClick, isSelected, employee, index }) => {
  return (
    <Col span={12} key={index}>
      <div
        onClick={onClick}
        className={`text-start mb-2 p-2 cursor-pointer ${
          isSelected ? "sec-cs-primary" : "border-cs-grey"
        } rounded`}
      >
        <h6 className="fw-bold mt-2">{getEmployeeName(employee)}</h6>
      </div>
    </Col>
  );
};

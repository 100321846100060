import React from "react";
import { Alert, Button, Space } from "antd";

export default function AlertComp({
  message,
  description,
  onAcceptPress,
  onDeclinePress,
}) {
  return (
    <Alert
      message={message}
      description={description}
      type="info"
      action={
        <Space direction="horizontal">
          <Button onClick={onAcceptPress} size="small" type="primary">
            Submit
          </Button>
          <Button onClick={onDeclinePress} size="small" danger type="ghost">
            Cancel
          </Button>
        </Space>
      }
      
    />
  );
}

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { unixToDateFormaterOld } from "../../Utilities";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import DeclinePopup from "./DeclinePopup";
import AppConstants from "../../Helpers/AppConstants";

export default function RequestCard({
  requestData,
  changeStatusRequest,
  role,
  ApproveDeclinePendingLeave,
  leaveType,
}) {
  const { user } = useSelector((state) => state.AuthenticationReducer);

  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [allRequestData, setRequestData] = useState({
    firstName: "",
    lastName: "",
    startDate: "",
    endDate: "",
    id: "",
    isApproved: "",
    createdAt: "",
    absenceType: "",
    absenceTypeName: "",
  });
  const { t } = useTranslation();

  const userType = get(requestData, "user_type", "");
  const absenceType = get(requestData, "absence_type", "");
  const startDate = get(requestData, "start_date", 0);
  const endDate = get(requestData, "end_date", 0);
  const pendingLeaves = get(requestData, "duration", "");
  const startHalfDayType = get(requestData, "start_half_day_type", "");
  const endHalfDayType = get(requestData, "end_half_day_type", "");
  const endType = get(requestData, "end_type", "");
  const startType = get(requestData, "start_type", "");
  const userId = get(requestData, "user_id", "");

  useEffect(() => {
    setUserData();
  }, [requestData]);

  const setUserData = () => {
    if (requestData) {
      setRequestData({
        ...allRequestData,
        firstName: requestData.first_name,
        absenceType: requestData.absence_type,
        absenceTypeName: requestData.absence_type_name,
        lastName: requestData.last_name,
        startDate: requestData.start_date,
        endDate: requestData.end_date,
        createdAt: requestData.created_at,
        id: requestData.annual_leave_id,
      });
    }
  };

  const onDeclinPressHandler = (reason) => {
    changeStatusRequest({
      status: "declined",
      id: allRequestData.id,
      reason: reason,
    });
    setShowDeclineModal(false);
  };

  const PendingLeaveHandler = () => {
    if (userType == "hours") {
      var pendingLeavesHours = Math.trunc(pendingLeaves / 60);
      var pendingLeavesMinutes = pendingLeaves % 60;

      if (pendingLeavesHours > 0 || pendingLeavesMinutes > 0) {
        return (
          <p className="mb-0  fw-bold capitalize mt-2">{`${pendingLeavesHours} hours ${pendingLeavesMinutes} minutes `}</p>
        );
      } else {
        return "";
      }
    } else {
      if (pendingLeaves > 0) {
        return (
          <p className="mb-0  fw-bold capitalize mt-2">
            {" "}
            {`${pendingLeaves} days`}
          </p>
        );
      } else {
        return "";
      }
    }
  };
  return (
    <div className="d-flex w-100 p-2">
      <DeclinePopup
        onCancelHandler={() => setShowDeclineModal(false)}
        onDeclinePress={onDeclinPressHandler}
        modalStatus={showDeclineModal}
      />
      <div className="w-50 d-flex align-items-center">
        <div className="w-10 mx-2">
          <i style={{ color: "#737980" }} className="ri-sun-line fs-2" />
        </div>
        <div className="me-2">
          <h4 className="fw-bold text_link">{`${allRequestData.firstName} ${allRequestData.lastName}`}</h4>
          <p className="mb-0 text-black">{` ${
            allRequestData.absenceTypeName
          } ${unixToDateFormaterOld(
            allRequestData.startDate,
            // "ddd DD MMM YYYY"
            AppConstants.dateFormat
          )}`}</p>
          <p className="mb-0 small text-black-50">{`Requested on  ${unixToDateFormaterOld(
            allRequestData.createdAt,
            // "ddd DD MMM YYYY"
            AppConstants.dateFormat
          )}`}</p>
        </div>
      </div>
      <div
        className="w-50 p-3 mx-2 rounded-1"
        style={{ backgroundColor: "#f5f7fa" }}
      >
        <p className="mb-0">
          Leave Type:{" "}
          <span className="fw-bold">{allRequestData.absenceTypeName}</span>
          {}
        </p>
        <p className="fw-bold text_warning">{t("exceedAllowance")}</p>
        Deducted from Annual Leave Entitlement: <PendingLeaveHandler />
        <p>{t("noConflicts")}</p>
        Starts :{" "}
        <span>
          {unixToDateFormaterOld(startDate, AppConstants.dateFormat)} -{" "}
          {`${
            startType &&
            ` ${startType}${
              startType !== "Full Day" ? `(${startHalfDayType})` : ""
            }`
          }`}
        </span>
        <br />
        Ends :{" "}
        <span>
          {unixToDateFormaterOld(endDate, AppConstants.dateFormat)} -{" "}
          {endType &&
            ` ${endType}${endType !== "Full Day" ? `(${endHalfDayType})` : ""}`}
        </span>
      </div>
      {ApproveDeclinePendingLeave && leaveType != "own" && (
        <div className="w-50 d-flex justify-content-center align-items-center">
          <div>
            <button
              onClick={() =>
                changeStatusRequest({
                  status: "approved",
                  id: allRequestData.id,
                })
              }
              className="btn btn-primary-cs"
            >
              {t("approve")}
            </button>
            <button
              onClick={() => setShowDeclineModal(true)}
              className="btn btn-primary-outline-cs ml-2"
            >
              {t("decline")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

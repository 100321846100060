export const styles = {
  heading: {
    fontSize: 32,
    fontWeight: "bold",
  },
  break: {
    height: 2,
    backgroundColor: "#dddd",
    marginTop: -10,
  },
  breadCrumbView: {
    marginTop: 20,
  },
  generateReportText: {
    fontSize: 17,
    fontWeight: "bolder",
    marginTop: 20,
  },
};

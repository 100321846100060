import { useTranslation } from "react-i18next";

function useEmployeeData() {
  const { t } = useTranslation();

  const EmployeesData = [
    {
      id: 1,
      title: t("downloadcsvtemplate"),
      subtitle: t("csvcontainformat"),
      active: false,
    },
    {
      id: 2,
      title: t("uploadpeopledata"),
      subtitle: t("uploadcsvformultiple"),
      active: false,
    },
  ];
  return EmployeesData;
}

export default useEmployeeData;

import React, { useState, useLayoutEffect } from "react";
import "./navbar.css";
import logo from "../../assets/images/logo.png";
import AppConstants from "../../Helpers/AppConstants";
import { useTranslation } from "react-i18next";
import AppSelector from "../AppSelector/AppSelector";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeLanguage as changeLanguageRedux } from "../../Redux/reducers/LanguageReducer";

export default function Navbar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const { t, i18n } = useTranslation();
  const { lng } = useSelector((state) => state.languageReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useLayoutEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const lang = searchParams.get("lang");
    if (lang) {
      i18n.changeLanguage(lang);
      dispatch(changeLanguageRedux(lang));
    }
  }, []);
  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    dispatch(changeLanguageRedux(newLanguage));
    const params = new URLSearchParams();
    params.append("lang", newLanguage);
    navigate({ search: params.toString() });
  };
  return (
    <nav dir="ltr" className="navigation">
      <a href="#">
        <img src={logo} style={{ width: "180px" }} />
      </a>
      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
      >
        <ul>
          <li>
            <a href={AppConstants.routes.login} className="login-btn-cs">
              <i className="ri-login-circle-line" />
              <span className="font-bold">{t("login")}</span>
            </a>
          </li>
          <li>
            <AppSelector
              className="langBtn app-selector-style"
              onChange={handleLanguageChange}
              value={lng}
            />
          </li>
        </ul>
      </div>
    </nav>
  );
}

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";

export default function LeaveYearDropDownOpts({
  yearFilter,
  onChange,
  currentYear,
}) {
  const { companySettingsOB } = useSelector((state) => state.settings);

  const [startEndDates, setStartEndDates] = useState({
    fiscal_start_date: 0,
    fiscal_start_month: 0,
    fiscal_end_date: 0,
    fiscal_end_month: 0,
  });

  useEffect(() => {
    if (companySettingsOB) {
      setStartEndDates({
        fiscal_start_date: get(companySettingsOB, "fiscal_start_date", ""),
        fiscal_start_month: get(companySettingsOB, "fiscal_start_month", ""),
        fiscal_end_date: get(companySettingsOB, "fiscal_end_date", ""),
        fiscal_end_month: get(companySettingsOB, "fiscal_end_month", ""),
      });
    }
  }, [companySettingsOB]);

  const handleTwoDigits = (date) => {
    return String(date).padStart(2, "0");
  };

  const option1 = `${handleTwoDigits(
    startEndDates.fiscal_start_date
  )}/${handleTwoDigits(startEndDates.fiscal_start_month)}/${
    currentYear - 1
  } - ${handleTwoDigits(startEndDates.fiscal_end_date)}/${handleTwoDigits(
    startEndDates.fiscal_end_month
  )}/${startEndDates.fiscal_start_month > 1 ? currentYear : currentYear - 1}`;

  const option2 = `${handleTwoDigits(
    startEndDates.fiscal_start_date
  )}/${handleTwoDigits(
    startEndDates.fiscal_start_month
  )}/${currentYear} - ${handleTwoDigits(
    startEndDates.fiscal_end_date
  )}/${handleTwoDigits(startEndDates.fiscal_end_month)}/${
    startEndDates.fiscal_start_month > 1 ? currentYear + 1 : currentYear
  }`;

  const option3 = `${handleTwoDigits(
    startEndDates.fiscal_start_date
  )}/${handleTwoDigits(startEndDates.fiscal_start_month)}/${
    currentYear + 1
  } - ${handleTwoDigits(startEndDates.fiscal_end_date)}/${handleTwoDigits(
    startEndDates.fiscal_end_month
  )}/${
    startEndDates.fiscal_start_month > 1 ? currentYear + 2 : currentYear + 1
  }`;

  useEffect(() => {
    if (option2 && startEndDates.fiscal_end_month != 0) {
      onChange(option2);
    }
  }, [option2, startEndDates.fiscal_end_month]);

  return (
    <select
      className="filter-form"
      value={yearFilter}
      onChange={(e) => onChange(e.target.value)}
    >
      <option value={option1} className="fw-bold" selected>
        {option1}
      </option>
      <option value={option2} className="fw-bold">
        {option2}
      </option>
      <option value={option3} className="fw-bold">
        {option3}
      </option>
    </select>
  );
}

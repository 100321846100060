import React, { useState, useEffect } from "react";
import { unixToDateFormater, unixToDateFormaterOld } from "../../../Utilities";
import { useDispatch, useSelector } from "react-redux";
import { getUserEmployementContractInfo } from "../../../Redux/reducers/ProfileReducers";
import { unwrapResult } from "@reduxjs/toolkit";
import { Applogger } from "../../../Helpers/Logger";
import { useTranslation } from "react-i18next";
import { checkIsAdmin } from "../../../Helpers/commonFunc";
import { get } from "lodash";
import SPProfileButton from "../Components/SPProfileButton";
import EditContractModal from "./EditContractModal";
import AppConstants from "../../../Helpers/AppConstants";
import usePrevilagesExist from "../../../Helpers/PrivilegesFuncs";
import SPProfileDropDown from "../Components/SPProfileDropDown";

export default function ContractInfoUserEmployement({
  updateUserEmploymentInfo,
  updateForm,
  id,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user: loggedInUser, token } = useSelector(
    (state) => state.AuthenticationReducer
  );
  const { userEmployementContractInfo, user } = useSelector(
    (state) => state.profile
  );
  const { roles, dailyRoles, hourlyRoles, placesOfWork, rolesSettings } =
    useSelector((state) => state.settings);
  const { countries } = useSelector((state) => state.employees);
  const { lng } = useSelector((state) => state.languageReducer);

  const { exist: UpdateContractInfoPri } = usePrevilagesExist(
    AppConstants.previlages.UpdateContractInfo
  );

  const { exist: updateDeleteLocation } = usePrevilagesExist(
    AppConstants.previlages.updateDeleteLocation
  );

  const { exist: UpdateDeleteRoleSetting } = usePrevilagesExist(
    AppConstants.previlages.UpdateDeleteRoleSetting
  );

  const { exist: updateEmployeeRoleLocationCountry } = usePrevilagesExist(
    AppConstants.previlages.updateEmployeeRoleLocationCountry
  );

  const [showEditModal, setShowEditModal] = useState(false);

  const [employmentDetails, setEmploymentDetails] = useState({
    role_id: "",
    work_place_id: "",
    country_id: "",
  });

  const employeeStartDate = get(userEmployementContractInfo, "start_date", "");

  // console.log("employeeStartDate ==== ", employeeStartDate);

  const contractStartDate = get(
    userEmployementContractInfo,
    "contract_date",
    0
  );

  const terminationStatus = get(
    userEmployementContractInfo,
    "is_terminated",
    0
  );
  const terminationDate = get(
    userEmployementContractInfo,
    "termination_date",
    ""
  );

  const terminationReason = get(
    userEmployementContractInfo,
    "termination_reason",
    ""
  );

  useEffect(() => {
    if (id) {
      getUserContractInfo();
    }
  }, [id]);

  useEffect(() => {
    if (user) {
      handleEmploymentDetails();
    }
  }, [user]);

  const handleEmploymentDetails = () => {
    setEmploymentDetails({
      ...employmentDetails,
      role_id: get(user, "role_id", ""),
      work_place_id: get(user, "work_place_id", "")
        ? get(user, "work_place_id", "")
        : get(placesOfWork[0], "work_place_id", 0),
      country_id: get(user, "country_id", "")
        ? get(user, "country_id", "")
        : get(countries[0], "country_id", 0),
    });
  };

  const getUserContractInfo = () => {
    dispatch(getUserEmployementContractInfo({ token, id: id, lng }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getUserEmployementContractInfo", res);
      })
      .catch((err) => {
        Applogger("Error at getUserEmployementContractInfo", err);
      });
  };

  const getRolesArray = () => {
    if (
      get(loggedInUser, "is_admin", -1) == 1 &&
      get(loggedInUser, "user_id", null) == get(user, "user_id", 0)
    ) {
      return roles;
    } else {
      if (get(user, "user_type", "") == "days") {
        return dailyRoles.filter((item) =>
          rolesSettings.find(
            (settingItem) => settingItem.role_id == item.role_id
          )
        );
      } else {
        return hourlyRoles.filter((item) =>
          rolesSettings.find(
            (settingItem) => settingItem.role_id == item.role_id
          )
        );
      }
    }
  };

  const isRolesDisabled = () => {
    if (get(loggedInUser, "is_admin", -1) == 1) {
      return get(loggedInUser, "user_id", null) == get(user, "user_id", 0);
    } else {
      return !UpdateDeleteRoleSetting;
    }
  };

  const isUpdateDisabled = () => {
    for (const element in employmentDetails) {
      if (!employmentDetails[element] || employmentDetails[element] == "null") {
        return false;
      }
    }
    return true;
  };

  return (
    <div className="px-2 py-4 border-custom rounded">
      <div className="accordion" id="accordionPanelsStayOpenExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button align-items-start"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#Contract"
              aria-expanded="true"
              aria-controls="Contract"
            >
              <div className="pe-2">
                <h4 className="text-black my-2 fw-bold emp_head">
                  {t("annualLeaveInformation")}
                </h4>
                <p className="text-black-50">{t("contractedHoursOfWork")}</p>
              </div>
            </button>
          </h2>
          <div
            id="Contract"
            className="accordion-collapse border-0 collapse show p-2"
            aria-labelledby="headingOne"
          >
            <div className="accordion-body p-0">
              <div className="myFlex-cs flex-wrap-sm">
                <h4 className="col-12 col-sm-12 col-md-8 fw-bold">
                  {t("contractSummary")}
                </h4>
                {UpdateContractInfoPri && (
                  <div className="col-12 col-sm-12 col-md-4 d-flex justify-content-end">
                    <button
                      onClick={() => setShowEditModal(true)}
                      className="ms-2 btn btn-primary-cs"
                    >
                      <span>{t("edit")}</span>
                    </button>
                  </div>
                )}
              </div>
              <br />
              <div>
                <ContractInfoCell
                  title={t("employeeStartDate")}
                  descrtiption={unixToDateFormaterOld(
                    employeeStartDate,
                    AppConstants.defaultDateFormat
                  )}
                />
                <ContractInfoCell
                  title={t("contractStartDate")}
                  descrtiption={unixToDateFormaterOld(
                    contractStartDate,
                    AppConstants.defaultDateFormat
                  )}
                />
                <ContractInfoCell
                  title={t("terminated")}
                  descrtiption={terminationStatus == 1 ? t("yes") : t("no")}
                />
                {terminationStatus == 1 && (
                  <ContractInfoCell
                    title="Termination Date"
                    descrtiption={unixToDateFormater(
                      terminationDate,
                      AppConstants.dateFormat
                    )}
                  />
                )}
                {terminationStatus == 1 && (
                  <ContractInfoCell
                    title="Termination Reason"
                    descrtiption={terminationReason}
                  />
                )}
              </div>
              <br />
              {/* {!checkIsAdmin(loggedInUser, user) && ( */}
              <>
                <div className="myFlex-cs flex-wrap-sm">
                  <h4 className="col-12 col-sm-12 col-md fw-bold">
                    {t("placeOfWork")}
                  </h4>
                </div>
                <div>
                  <SPProfileDropDown
                    title={t("location")}
                    placeholder={"Select Location"}
                    value={employmentDetails.work_place_id}
                    displayKey={"name"}
                    valueKey={"work_place_id"}
                    isDisabled={!updateDeleteLocation}
                    dataArray={placesOfWork}
                    onChange={(e) => {
                      setEmploymentDetails({
                        ...employmentDetails,
                        work_place_id: e.target.value,
                      });
                    }}
                  />
                  <SPProfileDropDown
                    title={t("role")}
                    placeholder={"Select Role"}
                    value={employmentDetails.role_id}
                    displayKey={"name"}
                    valueKey={"role_id"}
                    isDisabled={isRolesDisabled()}
                    dataArray={getRolesArray()}
                    onChange={(e) => {
                      setEmploymentDetails({
                        ...employmentDetails,
                        role_id: e.target.value,
                      });
                    }}
                  />
                  <SPProfileDropDown
                    title={t("country")}
                    placeholder={"Select Country"}
                    value={employmentDetails.country_id}
                    displayKey={"name"}
                    valueKey={"country_id"}
                    isDisabled={!updateEmployeeRoleLocationCountry}
                    dataArray={countries}
                    onChange={(e) => {
                      setEmploymentDetails({
                        ...employmentDetails,
                        country_id: e.target.value,
                      });
                    }}
                  />
                </div>
                <br />
                <SPProfileButton
                  title={t("update")}
                  onClick={() => updateUserEmploymentInfo(employmentDetails)}
                  isVisible={updateEmployeeRoleLocationCountry}
                  disabled={!isUpdateDisabled()}
                />
              </>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
      <EditContractModal
        editFormData={updateForm}
        modalStatus={showEditModal}
        onModalCancel={() => setShowEditModal(false)}
      />
    </div>
  );
}

const ContractInfoCell = ({ title, descrtiption }) => {
  return (
    <div className="myFlex-cs flex-wrap-sm">
      <p className="col-12 col-sm-12 col-md-6">{title}</p>
      <p className="col-12 col-sm-12 col-md-6">{descrtiption}</p>
    </div>
  );
};

import React from "react";

export default function IconButton(props) {
  return (
    <button
      onClick={props.onClick}
      className={`absence_btn ${props.className}`}
    >
      <i className={`${props.iconName}`} />
      <a href="#">
        <span className="ms-2"> {props.count ? props.count : 0}</span>
        <span> {props.title}</span>
      </a>
    </button>
  );
}

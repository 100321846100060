import React from "react";
import { get } from "lodash";
export default function SPProfileDropDown({
  title = "",
  value = "",
  onChange = () => {},
  placeholder = "",
  dataArray = [],
  displayKey = "",
  valueKey = "",
  isDisabled = false,
  isRequired = false,
}) {
  return (
    <div className="myFlex-cs flex-wrap-sm px-2 pt-2">
      <label className="col-12 col-sm-12 col-md-4">
        {title}
        {isRequired && <span className="text-danger">*</span>}
      </label>

      <div className="col-12 col-sm-12 col-md-8 d-flex flex-row align-items-center">
        <select
          disabled={isDisabled}
          style={{ background: isDisabled ? "#dddd" : "" }}
          value={value}
          className="filter-form col-10"
          onChange={onChange}
          placeholder={placeholder}
        >
          {/* <option value={""}>{"Select"}</option> */}
          {Array.isArray(dataArray) &&
            dataArray.map((val, index) => (
              <option key={index} value={get(val, valueKey, "")}>
                {get(val, displayKey, "")}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
}

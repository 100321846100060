import { useSelector } from "react-redux";

const usePrevilagesExist = (currentPrevilege) => {
  const { userPrevilages } = useSelector(
    (state) => state.AuthenticationReducer
  );

  const existStatus = userPrevilages.indexOf(currentPrevilege);

  return { exist: existStatus !== -1 };
};

export default usePrevilagesExist;

// import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";

// const usePrevilagesExist = (currentPrevilege) => {
//   const { userPrevilages } = useSelector(
//     (state) => state.AuthenticationReducer
//   );

//   const [exist, setExist] = useState(true);

//   useEffect(() => {
//     if (Array.isArray(userPrevilages)) {
//       const existStatus = userPrevilages.indexOf(currentPrevilege);
//       if (existStatus !== -1) {
//         setExist(true);
//       } else {
//         setExist(false);
//       }
//     }
//   }, []);

//   return { exist };
// };

// export default usePrevilagesExist;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import { Applogger } from "../../Helpers/Logger";
import { unwrapResult } from "@reduxjs/toolkit";
import { Modal, Select } from "antd";
import { logoutRequest } from "../../Redux/reducers/AuthenticationReducer";
import { getAllRolesRequest } from "../../Redux/reducers/SettingsReducer";
import {
  showFaliureToast,
  showSuccessToast,
  unixToDateFormater,
} from "../../Utilities";
import {
  uploadSharedDocument,
  deleteSharedDocument,
  editSharedDocument,
  getSharedDocumentsList,
} from "../../Redux/reducers/DocumentsReducer";
import Tooltip from "../Tooltip";
import AppConstants from "../../Helpers/AppConstants";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";
import UseLogoutHandler from "../../Helpers/useLogoutHandler";
import { saveAs } from "file-saver";

export default function SharedDocumentsList() {
  // Dispatchers
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Custom Hooks
  UseLogoutHandler();

  // i18n
  const { t } = useTranslation();

  // UI Element
  const { Option } = Select;

  // Reducers
  const { sharedDocuments } = useSelector((state) => state.document);
  const { lng } = useSelector((state) => state.languageReducer);
  const { roles } = useSelector((state) => state.settings);
  const { token, user } = useSelector((state) => state.AuthenticationReducer);
  const { exist: createModifySharedDocuments } = usePrevilagesExist(
    AppConstants.previlages.createModifySharedDocuments
  );

  // Local States
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUploadMode, setFileUploadMode] = useState(false);
  const [fileExists, setFileExists] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredFilesData, setFilteredFilesData] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [sortingType, setSortingType] = useState("any");
  const [itemToEdit, setItemToEdit] = useState(null);
  const [updatedName, setUpdatedName] = useState("");

  // Constants
  const companyId = get(user, "company_id", "");

  const sortingTypes = {
    aToZ: "aToZ",
    zToA: "zToA",
    any: t("any"),
  };

  const selectTypes = {
    all: "all",
  };

  const headerTypes = [
    {
      title: t("name"),
    },
    {
      title: t("type"),
    },
    {
      title: t("dateModified"),
    },
    {
      title: t("roles"),
    },
    {
      title: t("actions"),
    },
  ];

  useEffect(() => {
    if (token) {
      handleGetSharedDocumentsList();
      if (!roles.length > 0) {
        getAllRolesRequestHandler();
      }
    }
  }, []);

  useEffect(() => {
    setFilteredFilesData(sharedDocuments);
  }, [sharedDocuments]);

  useEffect(() => {
    handleSort();
  }, [sortingType]);

  const getAllRolesRequestHandler = () => {
    dispatch(getAllRolesRequest({ token, companyId }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getAllRolesRequest", res);
      })
      .catch((err) => {
        Applogger("Error at getAllRolesRequest", err);
      });
  };

  const logOutRequestHandler = () => {
    dispatch(logoutRequest())
      .then((res) => {
        navigate(AppConstants.routes.login);
      })
      .catch((err) => Applogger("Error at logOutRequestHandler", err));
  };

  const handleGetSharedDocumentsList = () => {
    dispatch(getSharedDocumentsList({ token, lng }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getSharedDocumentsList", res);
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          logOutRequestHandler();
        }
        Applogger("Error at getSharedDocumentsList", err.message);
      });
  };

  const handleUploadSharedDocument = () => {
    if (!selectedRoles.length > 0) {
      showFaliureToast("Please select roles to continue");
      return;
    }
    if (!selectedFile) {
      showFaliureToast("Please select file to continue");
      return;
    }

    dispatch(uploadSharedDocument({ token, selectedFile, selectedRoles }))
      .then(unwrapResult)
      .then((res) => {
        handleGetSharedDocumentsList();
        setSelectedFile(null);
        setSelectedRoles([]);
        setFileUploadMode(false);
        showSuccessToast(res ?? "Shared Document Successfully Created");
        Applogger("Response at uploadSharedDocument", res);
      })
      .catch((err) => {
        showFaliureToast(err.message ?? "Failed to Create Shared Document");
        Applogger("Error at uploadSharedDocument", err);
      });
  };

  const handleDeleteSharedDocument = (fileToDelete) => {
    dispatch(deleteSharedDocument({ token: token, fileId: fileToDelete.id }))
      .then(unwrapResult)
      .then((res) => {
        showSuccessToast(res ?? "Shared Document Successfully Deleted");
        Applogger("Response at deleteSharedDocument", res);
        handleGetSharedDocumentsList();
      })
      .catch((err) => {
        showFaliureToast(err ?? "File Deletion Failed");
        Applogger("Error at deleteSharedDocument", err);
      });
  };

  const handleEditSharedDocument = () => {
    dispatch(
      editSharedDocument({
        token: token,
        fileId: itemToEdit.id,
        name: updatedName,
        selectedRoles: selectedRoles,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at editSharedDocument", res);
        showSuccessToast(res ?? "Shared Document Successfully Renamed");
        handleGetSharedDocumentsList();
        resetEditFlow();
      })
      .catch((err) => {
        Applogger("Error at editSharedDocument", err);
        showFaliureToast(err ?? "Unable to update file name");
      });
  };

  const changeHandler = (event) => {
    setSelectedFile(null);
    let fileName = event.target.files[0].name;
    let allFiles = [...filteredFilesData];
    setSelectedFile(event.target.files[0]);
    let currentFileIndex = allFiles.findIndex((val) => val.name === fileName);
    let currentFile = allFiles[currentFileIndex];
    if (currentFile) {
      setFileExists(false);
    } else {
      setFileExists(true);
    }
  };

  const fileClickHandler = (file) => {
    // window.open(get(file, "upload", ""), "_blank", "noopener,noreferrer");

    var strr = get(file, "upload", "").split("/");
    saveAs(get(file, "upload", ""), strr[strr.length - 1]);
  };

  const searchDocumnet = (val) => {
    setSearchValue(val);

    let value = val.toLowerCase();
    let filesResult = [];

    filesResult = filteredFilesData.filter((fileData) => {
      return fileData.name.toLowerCase().search(value) != -1;
    });
    setFilteredFilesData(filesResult);
  };

  const handleSort = () => {
    sortData(filteredFilesData, setFilteredFilesData);
  };

  const sortData = (getArray, setArray) => {
    const sortedArray = [...getArray].sort((a, b) => {
      if (sortingType === sortingTypes.aToZ) {
        return a.name.localeCompare(b.name);
      } else if (sortingType === sortingTypes.zToA) {
        return b.name.localeCompare(a.name);
      }
    });
    setArray(sortedArray);
  };

  const getModifiedDate = (date) => {
    var finalDate = "-";
    if (date !== null) {
      finalDate = unixToDateFormater(date, "DD-MMM-YY");
    }
    return finalDate;
  };

  const handleEditFile = (fileToEdit) => {
    setItemToEdit(fileToEdit);
    setUpdatedName(get(fileToEdit, "name", ""));
    setSelectedRoles(() => {
      let finalArray = [];
      get(fileToEdit, "roles", []).map((arrayItem) => {
        finalArray.push(arrayItem.role_id);
      });
      return finalArray;
    });
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    resetEditFlow();
  };

  const resetEditFlow = () => {
    setIsModalVisible(false);
    setItemToEdit(null);
    setUpdatedName("");
    setSelectedRoles([]);
  };

  const handleOkPress = () => {
    if (!updatedName) {
      showFaliureToast("Please enter valid name");
      return;
    }
    if (!selectedRoles.length > 0) {
      showFaliureToast("Please select roles to continue");
      return;
    }

    handleEditSharedDocument();
  };

  const getExtensionlessName = (name, ext) => {
    const extension = `.${ext}`;
    let finalName = name;
    if (finalName.includes(extension)) {
      finalName = finalName.replace(extension, "");
    }
    return finalName;
  };

  const onSearch = (value) => {};

  const onSelectRole = (rolesArr) => {
    // Applogger("onSelectRole: ", rolesArr);
    setSelectedRoles(rolesArr);

    if (
      rolesArr.find((item) => item == selectTypes.all) &&
      selectedRoles.length == 2
    ) {
      setSelectedRoles([]);
    }
  };

  const onDeselectRole = (value) => {
    if (value == selectTypes.all) {
      setSelectedRoles([]);
    }
  };

  const onSelectRolesFunction = (roleValue) => {
    // if (rolesArr.find((item) => item == selectTypes.all)) {
    if (roleValue == selectTypes.all) {
      var array = [];
      roles.forEach((element) => {
        if (get(element, "role_id", 0)) {
          array.push(get(element, "role_id", 0));
        }
      });
      setSelectedRoles([...array, selectTypes.all]);
    }
  };

  const RolesSection = () => {
    return (
      <div className="col-12 col-sm-12 col-md">
        <label>{t("selectroles")}:</label>
        <Select
          mode="multiple"
          value={selectedRoles}
          className="filter-form-dropdown height-fit-content text-capitalize "
          showSearch={true}
          onSearch={onSearch}
          onSelect={(value) => onSelectRolesFunction(value)}
          onDeselect={(value) => onDeselectRole(value)}
          onChange={(value) => onSelectRole(value)}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          dropdownStyle={{ textTransform: "capitalize" }}
        >
          <Option value={selectTypes.all} label={t("all")}>
            <div className="d-flex align-content-center align-items-center">
              <p className="mb-0">{t("selectAll")}</p>
            </div>
          </Option>
          {Array.isArray(roles) &&
            roles.map((val, index) => {
              return (
                <Option
                  key={index}
                  value={get(val, "role_id", "")}
                  label={get(val, "name", "")}
                >
                  <div className="d-flex align-content-center align-items-center">
                    <p className="mb-0">{get(val, "name", "")}</p>
                  </div>
                </Option>
              );
            })}
        </Select>
      </div>
    );
  };

  return (
    <div>
      <div className="myFlex-cs flex-wrap-sm gap-3 animate__animated animate__fadeInDown justify-between">
        <form className="col-12 col-sm-12 col-md">
          <label className="fs-6 fw-light fw-normal">{t("search")}</label>
          <input
            className="filter-form"
            placeholder={t("searchFiles")}
            type="search"
            value={searchValue}
            onChange={(e) => searchDocumnet(e.target.value)}
          />
        </form>
        <form className="col-12 col-sm-12 col-md">
          <label className="fs-6 fw-light fw-normal">{t("sortBy")}</label>
          <select
            onChange={(e) => setSortingType(e.target.value)}
            className="filter-form"
          >
            <option value={sortingTypes.any} className="fw-bold">
              {t("name")} (Any)
            </option>
            <option value={sortingTypes.aToZ} className="fw-bold">
              {t("name")} (A-Z)
            </option>
            <option value={sortingTypes.zToA} className="fw-bold">
              {t("name")} (Z-A)
            </option>
          </select>
        </form>
      </div>
      <div className="justify-content-between gap-2">
        {fileUploadMode && (
          <div className={`${fileUploadMode && "animate__backInLeft"}`}>
            <RolesSection />
            <div className="col-12 col-sm-12 col-md-6">
              <input
                type={"file"}
                className={"form-control"}
                style={{ width: "fitContent" }}
                onChange={changeHandler}
              />
              {!fileExists && (
                <p className="text-danger">{t("fileAlreadyExist")}</p>
              )}
              <div className="row align-items-center"></div>
              <div className="d-flex mt-2 gap-2">
                <button
                  onClick={() => handleUploadSharedDocument()}
                  className={`btn ${
                    !fileExists && "disabled"
                  } btn-primary-outline-cs`}
                >
                  {t("uploadFile")}
                </button>
                <button
                  onClick={() => {
                    setSelectedFile(null);
                    setSelectedRoles([]);
                    setFileUploadMode(false);
                    setFileExists(true);
                  }}
                  className="btn btn-primary-outline-cs"
                >
                  {t("cancel")}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {createModifySharedDocuments && (
        <div className="d-flex flex-wrap-sm justify-content-end gap-2 animate__animated animate__fadeInDown">
          <button
            onClick={() => setFileUploadMode(!fileUploadMode)}
            className="btn btn-primary-cs d-flex align-items-center gap-2 fs-6"
          >
            <i className="ri-upload-2-line" />
            <span>{t("upload")}</span>
          </button>
        </div>
      )}
      <div className="row mt-2 rbc-row-content-scroll-container animate__animated animate__backInUp border-custom">
        <table className="table table-cstm table-borderless table-cstm">
          <thead>
            <tr
              className="border-bottom-ligh "
              style={{ backgroundColor: "#00aff0" }}
            >
              {headerTypes.map((headerType, index) => {
                const { title } = headerType;
                return (
                  <th
                    key={index}
                    className="fw-bold"
                    style={{ color: "white" }}
                  >
                    {title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="custom-height">
            {filteredFilesData.map((val, index) => {
              return (
                <tr
                  key={index}
                  className="border-bottom-light tbl-row-cs bg-light align-items-center"
                >
                  <td className="d-flex align-items-center" scope="row">
                    <i className="ri-file-line fs-4 me-2" />
                    <a
                      onClick={() => fileClickHandler(val)}
                      href="#"
                      className="text-primary text-capitalize "
                    >
                      {getExtensionlessName(
                        val.name,
                        get(val, "extension", "")
                      )}
                    </a>
                  </td>
                  <td>{get(val, "extension", "File") ?? "File"}</td>
                  <td>
                    {getModifiedDate(
                      val.updated_at ? val.updated_at : val.created_at
                    )}
                  </td>
                  <td>
                    <Tooltip
                      placement="left"
                      title={
                        <span>
                          {Array.isArray(get(val, "roles", []))
                            ? get(val, "roles", []).map((role) => {
                                const { name } = role;
                                return (
                                  <>
                                    {name}
                                    <br />
                                  </>
                                );
                              })
                            : ""}
                        </span>
                      }
                    >
                      {get(val, "roles", []).length} {t("roles")}
                    </Tooltip>
                  </td>
                  <td>
                    {get(val, "created_by_role", "") ==
                      get(user, "role_id", null) &&
                      createModifySharedDocuments && (
                        <>
                          <button
                            className="dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-mdb-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="ri-more-2-line fs-5 fw-bold" />
                          </button>

                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <li
                              onClick={() => handleEditFile(val)}
                              className="dropdown-item cursor-pointer"
                            >
                              {t("edit")}
                            </li>
                            <li
                              onClick={() => handleDeleteSharedDocument(val)}
                              className="dropdown-item cursor-pointer"
                            >
                              {t("delete")}
                            </li>
                          </ul>
                        </>
                      )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {filteredFilesData.length == 0 && (
          <h2 className="fs-4" style={{ textAlign: "center" }}>
            {t("noFilesFound")}
          </h2>
        )}
        <Modal
          title={t("updateDocument")}
          visible={isModalVisible}
          onCancel={handleCancel}
          onOk={handleOkPress}
        >
          <input
            type="text"
            className="filter-form"
            value={updatedName}
            onChange={(e) => setUpdatedName(e.target.value)}
          />
          <RolesSection />
        </Modal>
      </div>
    </div>
  );
}

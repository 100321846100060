import React, { useState, useEffect } from "react";
import { Modal, Checkbox, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { Applogger } from "../../Helpers/Logger";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import {
  assignEmployeeReq,
  getStaffingById,
  shiftHandlerReq,
  UnAssignEmployeeReq,
  getViewStaffing,
  callUpdateStaffingAPI,
  deleteShiftReq,
} from "../../Redux/reducers/StaffingReducer";
import {
  formattedDateForAPI,
  getTimeDifference,
  showFaliureToast,
  showSuccessToast,
} from "../../Utilities";
import moment from "moment";
import TooltipComponent from "../Tooltip";
import AppConstants from "../../Helpers/AppConstants";
import Header from "./../Header/Header";
import Spinner from "../Spinner";
import CreateShift from "./CreateShfitModal";
import AssignStaffModal from "./AssignStaffModal";
import ViewModal from "./ViewModal";
import PageNotFound from "../../pages/PageNotFound/PageNotFound";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";
import UseLogoutHandler from "../../Helpers/useLogoutHandler";
import UseAbsenceTypes from "../../Helpers/UseAbsenceTypes";
import "./TableView.css";

export default function TableView() {
  UseLogoutHandler();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { getCurrentAbsenceType } = UseAbsenceTypes(false);
  const [params] = useSearchParams();
  const { t } = useTranslation();
  const staffingId = params.get("schedule");

  const { token } = useSelector((state) => state.AuthenticationReducer);
  const { loading, staff } = useSelector((state) => state.staffing);
  const { lng } = useSelector((state) => state.languageReducer);
  const [dateRanges, setDateRanges] = useState([]);
  const [dateRangesToDisplay, setDateRangesToDisplay] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allStaffUsers, setAllStaffUsers] = useState(0);
  const [notFound, setShowFound] = useState(false);
  const [includeBreak, setIncludeBreak] = useState(false);
  const [userFilter, setUserFilter] = useState("");
  const [showViewModal, setShowViewModal] = useState(false);
  const [currentUserId, setCurrentUserId] = useState("");
  const [usersGrid, setUsersGrid] = useState([]);
  const [dataUpdateStatus, setDataUpdateStatus] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [footerData, setFooterData] = useState([]);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [currentStaff, setCurrentStaff] = useState(null);
  const [currentShiftData, setCurrentShiftData] = useState({
    shiftDay: "",
    shiftTimeStart: "",
    shiftTimeEnd: "",
    breakDuration: "",
    notes: "",
    shiftId: "",
  });

  const users = get(staff, "users", []);
  const contactedHours = get(currentStaff, "contacted_duration.hours", "");
  const contactedMins = get(currentStaff, "contacted_duration.mins", "");
  const totalDurationHours = get(currentStaff, "total_duration.hours", "");
  const totalDurationMins = get(currentStaff, "total_duration.mins", "");

  const { exist: assignEmployee } = usePrevilagesExist(
    AppConstants.previlages.assignEmployee
  );
  const { exist: staffingSummary } = usePrevilagesExist(
    AppConstants.previlages.staffingSummary
  );

  const { exist: createShiftPri } = usePrevilagesExist(
    AppConstants.previlages.createShift
  );

  useEffect(() => {
    if (staff) {
      setCurrentStaff(staff);
      if (users.length > 0) {
        setAllStaffUsers(users.length);
      } else {
        setAllStaffUsers(0);
      }
      if (users.length >= 0) {
        currentStaffGridHandler(users);
      } else {
        currentStaffGridHandler([]);
      }
    }
    return () => {
      setCurrentStaff(null);
    };
  }, [staff, includeBreak]);

  useEffect(() => {
    footerArrayHandler();
  }, [includeBreak]);

  useEffect(() => {
    if (currentStaff !== null) {
      const users = get(currentStaff, "users", []);
      // const { users } = currentStaff;
      setShowFound(false);
      footerArrayHandler();
      if (users.length >= 0) {
        currentStaffGridHandler(users);
      } else {
        currentStaffGridHandler([]);
      }
    } else {
      setShowFound(true);
    }
  }, [currentStaff]);

  useEffect(() => {
    if (staffingId) {
      getStaffingRequest();
    }
  }, [staffingId]);

  const footerDataHandler = () => {
    return footerData.map((val, index) => {
      const hours = get(val, "hours.hours", 0);
      const minutes = get(val, "hours.minutes", 0);
      return (
        <div key={index} className="staffing-grid-box active-col">
          <div>
            <p>
              {val.hours !== null
                ? `${hours + "hrs"} ${minutes !== 0 ? minutes + "mins" : ""}`
                : "0 hours"}
            </p>
          </div>
        </div>
      );
    });
  };

  const currentStaffGridHandler = (users) => {
    const staffArray = [];

    if (users.length >= 0) {
      for (let i = 0; i < users.length; i++) {
        // const element = users[i];

        const element = {
          value: <GridSection cellValue={users[i]} array={dateRanges} />,
          employeeSection: (
            <EmployeeSection
              firstName={users[i].full_name}
              employee={users[i]}
            />
          ),
          totalHours: (
            <TotalHours
              total={
                includeBreak ? users[i].contacted_hours : users[i].total_hours
              }
            />
          ),
        };
        staffArray.push(element);
      }
      setUsersGrid(staffArray);
    }
  };
  const getStaffingRequest = () => {
    dispatch(getStaffingById({ token, id: staffingId, lng }))
      .then(unwrapResult)
      .then((res) => {
        if (res.data) {
          getDates(res.data.start_date, res.data.end_date);
          setDataUpdateStatus(!dataUpdateStatus);
        }
      })
      .catch((err) => {});
  };

  function getDates(startDate, stopDate) {
    var dateArray = [];
    var dateArrayDisplay = [];
    var currentDate = moment.unix(startDate);
    var stopDate = moment.unix(stopDate);

    while (currentDate <= stopDate) {
      dateArray.push(
        moment(currentDate).format(AppConstants.defaultDateFormat)
      );
      dateArrayDisplay.push({
        date: moment(currentDate).format(AppConstants.defaultDateFormat),
        day: moment(currentDate).format("dddd"),
      });
      currentDate = moment(currentDate).add(1, "days");
    }
    setDateRanges(dateArray);
    setDateRangesToDisplay(dateArrayDisplay);
    footerArrayHandler();
    return dateArray;
  }

  const onShowEmployeeHandler = () => {
    setShowAssignModal(true);
  };

  const showModal = () => {
    if (createShiftPri) {
      setIsModalOpen(true);
    }
  };

  const handleOk = () => {
    if (
      currentShiftData.breakDuration !== "" &&
      currentShiftData.shiftTimeStart !== "" &&
      currentShiftData.shiftTimeEnd !== ""
    ) {
      const timeDifference = getTimeDifference(
        currentShiftData.shiftTimeStart,
        currentShiftData.shiftTimeEnd
      );
      const finalShiftData = {
        ...currentShiftData,
        shiftDay: formattedDateForAPI(currentShiftData.shiftDay),
      };
      if (timeDifference > parseInt(currentShiftData.breakDuration)) {
        dispatch(
          shiftHandlerReq({
            token,
            shiftData: finalShiftData,
            userId: currentUserId,
            rotaId: staffingId,
          })
        )
          .then(unwrapResult)
          .then((res) => {
            Applogger("Response at shiftHandlerReq", res);
            showSuccessToast(res ?? "Shift Added Successfully");
            getStaffingRequest();
            setIsModalOpen(false);
          })
          .catch((err) => {
            showFaliureToast(err.message ?? "Failed to add shift");
            Applogger("Error at shiftHandlerReq", err);
          });
      } else {
        showFaliureToast(t("breakandShiftDuration"));
      }
      // setIsModalOpen(false);
    } else {
      showFaliureToast("Please fill all Data");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const employeeSelectHandler = (val) => {
    setSelectedEmployees(val);
  };

  const ChangeStaffingStatusOnServer = () => {
    try {
      dispatch(callUpdateStaffingAPI({ id: staffingId, token: token, lng }))
        .then(unwrapResult)
        .then((response) => {
          navigate(AppConstants.routes.staffing);
          getStaffingRequest();
        })
        .catch((err) => {
          Applogger("Error at callUpdateStaffingAPI", err.message);
        });
    } catch (err) {
      Applogger("Error at Call Update Staffing API", err);
    }
  };

  const handleAssignModalOk = () => {
    if (selectedEmployees.length >= 0) {
      dispatch(assignEmployeeReq({ token, selectedEmployees, staffingId }))
        .then(unwrapResult)
        .then((res) => {
          Applogger("Response at assignEmployeeReq", res);
          setShowAssignModal(false);
          getStaffingRequest();
          setSelectedEmployees([]);
          showSuccessToast("Employee assigned to Staffing Successfully");
        })
        .catch((err) => {
          Applogger("Error at assignEmployeeReq", err);
          showFaliureToast("Employee Assign Request Failed");
        });
    } else {
      showFaliureToast("Select an employee to assign first");
    }
  };

  const getViewStaffingData = () => {
    dispatch(getViewStaffing({ token, id: staffingId, lng }))
      .then(unwrapResult)
      .then((res) => {
        setShowViewModal(true);
        Applogger("Response at getViewStaffing", res);
      })
      .catch((err) => {
        showFaliureToast("View Staffing Detail Request Failed");
        Applogger("Error at getViewStaffing", err);
      });
  };

  const removeEmployeeHandler = (employee) => {
    dispatch(
      UnAssignEmployeeReq({ token, staffingId, id: employee.user_id, lng })
    )
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at UnAssignEmployeeReq", res);
        // if (res.data == "User deleted from Rota successfully") {
        showSuccessToast("User Unassigned from Staffing successfully");
        getStaffingRequest();
        // }
      })
      .catch((err) => {
        showFaliureToast("Unassign request failed");
        Applogger("Error at UnAssignEmployeeReq", err);
      });
  };

  const EmployeeSection = ({ firstName, lastName, employee }) => {
    return (
      <div className="left-start-col">
        <div
          className="tableViewEmployeeCard"
          data-mdb-toggle="dropdown"
          aria-expanded="false"
        >
          <div className="profile-sm hide-sm">
            <b>{`${firstName.slice(0, 1)}`}</b>
          </div>
          <div className="ml-2">
            <h6 className="mb-0 capitalize fw-bold">{`${firstName}`}</h6>
          </div>
        </div>
        <ul className="dropdown-menu dropdown-menu-end">
          <li>
            <button
              className="dropdown-item"
              onClick={() => removeEmployeeHandler(employee)}
            >
              {t("removeEmployee")}
            </button>
          </li>
        </ul>
      </div>
    );
  };

  const TotalHours = ({ total }) => {
    return (
      <span>
        {total.hours} {t("hrs")} {total.mins} {t("min")}{" "}
      </span>
    );
  };

  const onClickHandler = (cellValue, userId, currentDate) => {
    if (cellValue) {
      setCurrentShiftData({
        shiftDay: moment
          .unix(cellValue.shift_day)
          .format(AppConstants.defaultDateFormat),
        notes: cellValue.notes,
        shiftTimeStart: moment
          .unix(cellValue.start_time)
          .zone(0)
          .format(AppConstants.timeFormat),
        shiftTimeEnd: moment
          .unix(cellValue.end_time)
          .zone(0)
          .format(AppConstants.timeFormat),
        breakDuration: cellValue.break_duration,
        shiftId: cellValue.shift_id,
      });
      showModal();
    } else {
      setCurrentShiftData({
        shiftDay: currentDate,
        shiftTimeStart: "",
        shiftTimeEnd: "",
        breakDuration: "",
        notes: "",
        shiftId: "",
      });
      showModal();
    }
    setCurrentUserId(userId);
  };

  const CellHandler = ({ currentDate, cellValue }) => {
    const { shifts, leaves } = cellValue;
    if (shifts) {
      const currentShiftIndex = shifts.findIndex(
        (val) =>
          moment.unix(val.shift_day).format(AppConstants.defaultDateFormat) ==
          currentDate
      );
      if (leaves && leaves.length > 0) {
        for (let leavesIndex = 0; leavesIndex < leaves.length; leavesIndex++) {
          const leavesElement = leaves[leavesIndex];

          var dateArray = [];
          var startDate = moment.unix(leavesElement.start_date);
          var stopDate = moment.unix(leavesElement.end_date);

          while (startDate <= stopDate) {
            dateArray.push(
              moment(startDate).format(AppConstants.defaultDateFormat)
            );
            startDate = moment(startDate).add(1, "days");
          }
        }
        for (let dateIndex = 0; dateIndex < dateArray.length; dateIndex++) {
          const dateElement = dateArray[dateIndex];
          if (dateElement == currentDate) {
            if (currentShiftIndex !== -1) {
              const currentShiftDate = moment
                .unix(shifts[currentShiftIndex].shift_day)
                .format(AppConstants.defaultDateFormat);
              if (currentShiftDate == currentDate) {
                const currentShift = shifts[currentShiftIndex];

                return (
                  <div>
                    <div
                      onClick={() =>
                        onClickHandler(
                          currentShift,
                          cellValue.user_id,
                          currentDate
                        )
                      }
                      className="staffing-grid-box "
                    >
                      <div className="gridCard" style={{ cursor: "pointer" }}>
                        <p className="gridCardText">{t("conflict")}</p>
                      </div>
                    </div>{" "}
                  </div>
                );
              }
            } else {
              const type = getCurrentAbsenceType(leaves[0]?.absence_type_id);
              return (
                <div className="staffing-grid-box ">
                  <div
                    className="gridCard"
                    style={{
                      backgroundColor: type?.colour,
                    }}
                  >
                    <p className="gridCradText">{type?.name}</p>
                  </div>
                </div>
              );
            }
          } else {
          }
        }
      }

      if (currentShiftIndex !== -1) {
        const currentShiftDate = moment
          .unix(shifts[currentShiftIndex].shift_day)
          .format(AppConstants.defaultDateFormat);
        const currentShift = shifts[currentShiftIndex];
        if (currentShiftDate == currentDate) {
          return (
            <div
              onClick={() =>
                onClickHandler(currentShift, cellValue.user_id, currentDate)
              }
              className="staffing-grid-box "
            >
              <div className="gridCard" style={{ cursor: "pointer" }}>
                <p className="gridCardText">{`${moment
                  .unix(currentShift.start_time)
                  .zone(0)
                  .format(AppConstants.timeFormat)}-${moment
                  .unix(currentShift.end_time)
                  .zone(0)
                  .format(AppConstants.timeFormat)}`}</p>
              </div>
            </div>
          );
        } else {
          return (
            <div
              onClick={() => onClickHandler()}
              className="staffing-grid-box"
            ></div>
          );
        }
      } else {
        return (
          <div
            onClick={() =>
              onClickHandler(undefined, cellValue.user_id, currentDate)
            }
            className="staffing-grid-box"
          >
            <div className="gridCard" style={{ cursor: "pointer" }}>
              <p className="gridCardText">{t("restDay")}</p>
            </div>
          </div>
        );
      }
    }
  };

  const GridSection = ({ array, cellValue }) => {
    return (
      <div className="main-sheet-row">
        {array.map((val, index) => {
          return (
            <CellHandler key={index} currentDate={val} cellValue={cellValue} />
          );
        })}
      </div>
    );
  };

  function hoursMinutesHandler(time) {
    var hours = Math.trunc(time / 60);
    var minutes = time % 60;
    return { hours, minutes };
  }

  const ModalFooter = () => {
    return (
      <div
        className="d-flex"
        style={{ justifyContent: "flex-end", alignContent: "flex-end" }}
      >
        {currentShiftData.shiftId !== "" && (
          <Button onClick={deleteShiftRequestHandler} danger>
            {t("delete")}
          </Button>
        )}
        <Button onClick={handleCancel}>{t("cancel")}</Button>
        <Button onClick={handleOk} type="primary">
          {t("save")}
        </Button>
      </div>
    );
  };

  const footerArrayHandler = () => {
    const dateElement = [];

    var currentDate = "";
    try {
      for (let i = 0; i < dateRanges.length; i++) {
        const element = dateRanges[i];
        currentDate = element;
        if (currentStaff !== null) {
          const { users } = currentStaff;
          var totalHours = 0;
          var notTotalhours = 0;
          for (let staffIndex = 0; staffIndex < users.length; staffIndex++) {
            const userElement = users[staffIndex];
            const { shifts } = userElement;
            const currentShiftIndex = shifts.findIndex(
              (shift) =>
                moment
                  .unix(shift.shift_day)
                  .format(AppConstants.defaultDateFormat) == element
            );
            if (currentShiftIndex !== -1) {
              totalHours = totalHours + shifts[currentShiftIndex].duration;
              notTotalhours =
                notTotalhours +
                shifts[currentShiftIndex].duration -
                shifts[currentShiftIndex].break_duration;
              var dataObject = {
                date: element,
                hours: hoursMinutesHandler(
                  includeBreak ? totalHours : notTotalhours
                ),
              };
              var dataObjectt = {
                date: element,
                hours: hoursMinutesHandler(
                  includeBreak
                    ? shifts[currentShiftIndex].duration
                    : shifts[currentShiftIndex].duration -
                        shifts[currentShiftIndex].break_duration
                ),
              };

              const currDateIndex = dateElement.findIndex(
                (val) => val.date == element
              );
              if (currDateIndex !== -1) {
                dateElement.splice(currDateIndex, 1);
                dateElement.push(dataObject);
              } else {
                dateElement.push(dataObjectt);
              }
            } else {
              const dateElementIndex = dateElement.findIndex(
                (date) => date.date === element
              );
              if (dateElementIndex !== -1) {
              } else {
                var daaaa = { date: element, hours: null };
                dateElement.push(daaaa);
                totalHours = 0;
                notTotalhours = 0;
              }
            }
          }
        } else {
          var daaaa = { date: element, hours: null };
          dateElement.push(daaaa);
        }
      }
      setFooterData(dateElement);
    } catch (err) {
      Applogger("Error at footerArrayHandler", err);
    }
  };

  const deleteShiftRequestHandler = () => {
    try {
      dispatch(deleteShiftReq({ token: token, id: currentShiftData.shiftId }))
        .then(unwrapResult)
        .then((res) => {
          Applogger("Response at deleteShiftReq");
          setIsModalOpen(false);
          getStaffingRequest();
        })
        .catch((err) => {
          Applogger("Error at deleteShiftReq", err);
        });
    } catch (err) {
      Applogger("Error at deleteShiftRequestHandler", err);
    }
  };

  const onUserFilterHandler = (event) => {
    try {
      const dataToFilter = event.target.value;
      setUserFilter(dataToFilter);
      if (staff !== null) {
        const { users } = staff;
        const filteredUsers = users.filter((item) =>
          item.full_name.toLocaleLowerCase().includes(dataToFilter)
        );
        setCurrentStaff({ ...currentStaff, users: filteredUsers });
      }
    } catch (err) {
      Applogger("Error  at onUserFilterHandler===== ", err);
    }
  };

  return (
    <div>
      {loading == "pending" && <Spinner />}
      <Header />
      {!notFound ? (
        <React.Fragment>
          <br />
          <div className="sp_main">
            <h1 className="top-heading">
              <strong>{t("admin")} </strong>
              <span>{`${
                currentStaff !== null
                  ? moment.unix(currentStaff.start_date).format(
                      AppConstants.dateFormat
                      // "ddd, DD MMM YYYY"
                    )
                  : ""
              } - ${
                currentStaff !== null
                  ? moment.unix(currentStaff.end_date).format(
                      AppConstants.dateFormat
                      // "ddd, DD MMM YYYY"
                    )
                  : ""
              } |`}</span>
              <span>
                {" "}
                {`${
                  currentStaff !== null
                    ? `${currentStaff.duration} ${t("days")}`
                    : ""
                }`}{" "}
              </span>
              <span>
                |{" "}
                {`${
                  currentStaff !== null
                    ? `${allStaffUsers} ${t("staffMembers")}`
                    : ""
                }`}
              </span>

              <span>
                |{" "}
                {`${
                  currentStaff !== null
                    ? `${
                        currentStaff.is_publish == 1
                          ? t("published")
                          : t("notPublished")
                      }`
                    : ""
                }`}{" "}
              </span>
            </h1>
          </div>
          <div className="container_custom">
            <div className="d-flex justify-content-between flex-wrap-sm">
              <div className="d-flex align-items-center gap-4 flex-wrap-sm">
                {assignEmployee && (
                  <>
                    <TooltipComponent
                      placement="left"
                      title={
                        currentStaff != null && currentStaff.is_publish == 1
                          ? `${t("unpublish")} this schedule`
                          : `${t("publish")} this schedule`
                      }
                    >
                      <button
                        onClick={ChangeStaffingStatusOnServer}
                        // className="btn btn-light-cs d-flex align-items-center gap-2"
                        className="btn btn-primary-cs me-2 mb-2"
                      >
                        <span>
                          {currentStaff !== null
                            ? currentStaff.is_publish == 1
                              ? t("unpublish")
                              : t("publish")
                            : ""}
                        </span>
                      </button>
                    </TooltipComponent>
                    <button
                      onClick={onShowEmployeeHandler}
                      className="btn btn-primary-cs me-2 mb-2"
                      type="button"
                      id="dropdownMenu2"
                      data-mdb-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {t("addEmployees")}
                    </button>
                  </>
                )}
                {staffingSummary && (
                  <button
                    onClick={getViewStaffingData}
                    className="btn btn-primary-cs me-2 mb-2"
                    type="button"
                  >
                    {t("summary")}
                  </button>
                )}
              </div>
            </div>
            <br />
            <div className="datesheet-wrapper">
              <div className="main-datasheet">
                <div className="sheet-sidebar">
                  {/* Head */}
                  <div className="staffing-grid-box left-start-col">
                    <input
                      value={userFilter}
                      onChange={onUserFilterHandler}
                      className="filter-form"
                      placeholder={t("jobTitlePlaceholder")}
                      type={"search"}
                    />
                  </div>
                  {/* Main */}
                  {usersGrid.map((val, index) => (
                    <div
                      key={index}
                      className="staffing-grid-box left-start-col"
                    >
                      {val.employeeSection}
                    </div>
                  ))}
                  {/* Footer */}
                  <div className="staffing-grid-box left-start-col">
                    <h6 className="mb-0 fw-bold">{t("dailyTotal")}</h6>
                  </div>
                  {/* Add New */}
                  <div className="staffing-grid-box add-new-employee-sheet">
                    {assignEmployee && (
                      <button onClick={onShowEmployeeHandler}>
                        <i className="i-sm text-primary ri-add-circle-line" />
                        <span className="fs-16 hide-sm">
                          {t("addEmployee")}
                        </span>
                      </button>
                    )}
                  </div>
                </div>
                <div className="sheet-main">
                  {/* Head */}
                  <div className="main-sheet-row">
                    {dateRangesToDisplay.length > 0 &&
                      dateRangesToDisplay.map((val, index) => {
                        const { date, day } = val;
                        return (
                          <div key={index} className="staffing-grid-box">
                            <p className="text-decoration-underline">{day}</p>
                            <p>{date}</p>
                          </div>
                        );
                      })}
                  </div>
                  {/* Main */}
                  {usersGrid.map((val, index) => (
                    <div key={index}>{val.value}</div>
                  ))}
                  {/* Footer */}
                  <div className="main-sheet-row">{footerDataHandler()}</div>
                </div>
                <div className="sheet-right">
                  {/* Head */}
                  <div className="staffing-grid-box active-col">
                    <span>{t("totalHours")}</span>
                    <Checkbox
                      onChange={(e) => setIncludeBreak(e.target.checked)}
                    >
                      {t("incBreak")}
                    </Checkbox>
                  </div>

                  {/* Main */}
                  {usersGrid.map((val, index) => (
                    <div key={index} className="staffing-grid-box active-col">
                      {val.totalHours}
                    </div>
                  ))}
                  {/* Footer */}
                  {currentStaff !== null && (
                    <div className="staffing-grid-box active-col">
                      {includeBreak
                        ? `${contactedHours} hrs ${contactedMins} mins`
                        : `${totalDurationHours} hrs ${totalDurationMins} mins`}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        loading !== "pending" && <PageNotFound />
      )}
      <Modal
        title={t("shift")}
        visible={isModalOpen}
        onOk={handleOk}
        footer={<ModalFooter />}
        onCancel={handleCancel}
      >
        <CreateShift
          noDates={true}
          dateRange={dateRanges}
          currentShiftData={currentShiftData}
          setShiftData={setCurrentShiftData}
        />
      </Modal>
      <Modal
        width={1000}
        title={t("assignEmployee")}
        visible={showAssignModal}
        onOk={handleAssignModalOk}
        onCancel={() => setShowAssignModal(false)}
      >
        <AssignStaffModal
          updated={dataUpdateStatus}
          click={showAssignModal}
          selectedEmployeees={currentStaff !== null && currentStaff.users}
          employeeSelect={employeeSelectHandler}
        />
      </Modal>
      <ViewModal
        modalStatus={showViewModal}
        onCancel={() => setShowViewModal(false)}
      />
    </div>
  );
}

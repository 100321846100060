import EventResource from "../../Config/EventResource";
import { APIConstants } from "../../Config/ApiConstants";
import { Applogger } from "../../Helpers/Logger";
import { getHeaders } from "../../Helpers/commonFunc";

function RequestAbsenceData(payload) {
  return EventResource.get(
    `${APIConstants.RequestAbsenceData}${payload.id}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}
function generateAbsenceReport(payload) {
  return EventResource.get(
    `${APIConstants.getAbsenceCalender}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

function getPendingRequest(payload) {
  return EventResource.get(
    `${APIConstants.getPendingLeave}?type=own&lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

function apiGetOthersPendingRequest(payload) {
  return EventResource.get(
    `${APIConstants.getPendingLeave}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

function postApproveRequest(payload) {
  const approveData = new FormData();
  approveData.append("status", payload.status);
  approveData.append("decline_reasons", payload.reason);
  return EventResource.post(
    `${APIConstants.pendingRequestAction}${payload.id}/`,
    approveData,
    getHeaders(payload.token)
  );
}

function getAbsenceTypeRequest(payload) {
  return EventResource.get(
    `${APIConstants.getAbsenceType}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}
function updateTimeOff(payload) {
  const {
    employee,
    absenceType,
    note,
    hoursDeducted,
    minsDeducted,
    start_date,
    start_time,
    start_type,
    start_half_day_type,
    end_date,
    end_time,
    end_type,
    end_half_day_type,
  } = payload.data;
  const timeOffData = new FormData();
  timeOffData.append("user_id", employee);
  timeOffData.append("absence_type_id", absenceType);
  timeOffData.append("notes", note);
  timeOffData.append("deducted_hours", hoursDeducted);
  timeOffData.append("deducted_mins", minsDeducted);
  timeOffData.append("start_date", start_date);
  timeOffData.append("start_time", start_time);
  timeOffData.append("start_type", start_type);
  timeOffData.append("start_half_day_type", start_half_day_type);
  timeOffData.append("end_date", end_date);
  timeOffData.append("end_time", end_time);
  timeOffData.append("end_type", end_type);
  timeOffData.append("end_half_day_type", end_half_day_type);
  timeOffData.append("annual_leave_type", absenceType);
  return EventResource.post(
    `${APIConstants.updateAbsence}${payload.id}/`,
    timeOffData,
    getHeaders(payload.token)
  );
}

function addTimeOffRequest(payload) {
  const {
    employee,
    absenceType,
    note,
    hoursDeducted,
    minsDeducted,
    start_date,
    start_time,
    start_type,
    start_half_day_type,
    end_date,
    end_time,
    end_type,
    end_half_day_type,
  } = payload.sendingAbsenceData;
  const timeOffData = new FormData();
  timeOffData.append("user_id", employee);
  timeOffData.append("absence_type_id", absenceType);
  timeOffData.append("notes", note);
  timeOffData.append("deducted_hours", hoursDeducted);
  timeOffData.append("deducted_mins", minsDeducted);
  timeOffData.append("start_date", start_date);
  timeOffData.append("start_time", start_time);
  timeOffData.append("start_type", start_type);
  timeOffData.append("start_half_day_type", start_half_day_type);
  timeOffData.append("end_date", end_date);
  timeOffData.append("end_time", end_time);
  timeOffData.append("end_type", end_type);
  timeOffData.append("end_half_day_type", end_half_day_type);
  timeOffData.append("annual_leave_type", absenceType);
  return EventResource.post(
    `${APIConstants.addTimeOff}`,
    timeOffData,
    getHeaders(payload.token)
  );
}

function addMandatoryLeaveRequest(payload) {
  try {
    const { leaveName, leaveStartDate, leaveEndDate, deductEmployee, notes } =
      payload.mandatoryDetails;
    const leaveData = new FormData();

    leaveData.append("name", leaveName);
    leaveData.append("start_date", leaveStartDate);
    leaveData.append("end_date", leaveEndDate);
    leaveData.append("is_deducted", deductEmployee == "yes" ? 1 : 0);
    leaveData.append("notes", notes);

    for (let i = 0; i < payload.selectedEmployees.length; i++) {
      leaveData.append("users[]", payload.selectedEmployees[i].user_id);
    }

    return EventResource.post(
      APIConstants.createMandtoryLeaves,
      leaveData,
      getHeaders(payload.token)
    );
  } catch (err) {
    Applogger("Error at addMandatoryLeaveRequest", err);
  }
}

function getMandatoryLeaveRequest(payload) {
  return EventResource.get(
    `${APIConstants.mandatoryLeaves}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

function deleteMandatoryLeaveRequest(payload) {
  return EventResource.get(
    `${APIConstants.deleteMandatoryLeaves}${payload.id}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

function getDeclinedLeavesRequest(payload) {
  return EventResource.get(
    `${APIConstants.getDeclinedLeave}?type=own`,
    getHeaders(payload.token)
  );
}

function apiGetOthersDeclineLeavesRequest(payload) {
  return EventResource.get(
    `${APIConstants.getDeclinedLeave}`,
    getHeaders(payload.token)
  );
}

function apiPublicHolidaysRequest(payload) {
  return EventResource.get(
    `${APIConstants.publicHolidays}`,
    getHeaders(payload.token)
  );
}
export const CalenderApiServices = {
  generateAbsenceReport,
  getPendingRequest,
  apiGetOthersPendingRequest,
  postApproveRequest,
  addTimeOffRequest,
  getAbsenceTypeRequest,
  RequestAbsenceData,
  updateTimeOff,
  addMandatoryLeaveRequest,
  getMandatoryLeaveRequest,
  deleteMandatoryLeaveRequest,
  getDeclinedLeavesRequest,
  apiGetOthersDeclineLeavesRequest,
  apiPublicHolidaysRequest,
};

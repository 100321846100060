import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Applogger } from "../../Helpers/Logger";
import { unwrapResult } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { Modal, Table, Space } from "antd";
import { get } from "lodash";
import { validateEmail } from "../../Helpers/AppValidators";
import {
  bulkEmployeesStates,
  createBulkEmployees,
} from "../../Redux/reducers/EmployeesReducer";
import {
  showFaliureToast,
  getCalculatedTableHeight,
  showWarningToast,
  formattedDateForAPI,
} from "../../Utilities";
import Button from "./Button";
import Spinner from "../Spinner";
import EmployeeCSV from "./EmployeeCSV";
import moment from "moment";
import UploadSummary from "./UploadSummary";

export default function AddOnboardingEmployee({ isOnboarding = false }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { token, user } = useSelector((state) => state.AuthenticationReducer);
  const { loading } = useSelector((state) => state.settings);
  const { loading: empLoading, bulkEmployeesData } = useSelector(
    (state) => state.employees
  );

  const userObjc = {
    first_name: "",
    last_name: "",
    email: "",
    work_start_date: "",
  };

  const [employeesList, setEmployeesList] = useState(bulkEmployeesData);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [serverResponse, setServerResponse] = useState({
    bulk_users: [],
    errorFE: [],
    error_for_not_created_user: [],
    successFE: "",
    user_created: [],
    user_not_created: [],
  });

  const employeesListRef = useRef(bulkEmployeesData);

  useEffect(() => {
    return () => {
      persistBulkUsers();
    };
  }, []);

  useEffect(() => {
    employeesListRef.current = employeesList;
    if (employeesList.length == 0) {
      setEmployeesList([userObjc]);
    }
  }, [employeesList]);

  const persistBulkUsers = () => {
    if (user) {
      dispatch(bulkEmployeesStates(employeesListRef.current));
    }
  };

  const onNameChangeHandler = (index, value) => {
    const allUsers = [...employeesList];
    allUsers[index] = { ...allUsers[index], first_name: value };
    setEmployeesList(allUsers);
  };

  const onLastNameChangeHandler = (index, value) => {
    const allUsers = [...employeesList];
    allUsers[index] = { ...allUsers[index], last_name: value };
    setEmployeesList(allUsers);
  };

  const onEmailChangeHandler = (index, value) => {
    const allUsers = [...employeesList];
    allUsers[index] = { ...allUsers[index], email: value };
    setEmployeesList(allUsers);
  };

  const onStartDateChangeHandler = (index, value) => {
    const allUsers = [...employeesList];
    allUsers[index] = {
      ...allUsers[index],
      work_start_date: value,
    };
    setEmployeesList(allUsers);
  };

  const onResetPressHandler = (indexToReset) => {
    let allUsers = [...employeesList];
    allUsers[indexToReset] = userObjc;
    setEmployeesList(allUsers);
  };

  const onDeletePressHandler = (indexToDelete) => {
    let allUsers = [...employeesList];
    allUsers = allUsers.filter((_, index) => index !== indexToDelete);
    setEmployeesList(allUsers);
  };

  const getRequestBody = () => {
    let finalArray = [];
    employeesList.forEach((item, index) => {
      finalArray.push({
        ...item,
        row: index + 1,
        work_start_date: moment(get(item, "work_start_date", "")).format(
          "DD/MM/YYYY"
        ),
      });
    });
    return finalArray;
  };

  const isValidData = (requestBody) => {
    for (let i = 0; i < requestBody.length; i++) {
      const objectToValidate = requestBody[i];
      for (const [key, value] of Object.entries(objectToValidate)) {
        if (!value || value == "Invalid date") {
          return false;
        }
      }
    }

    return true;
  };

  const handleCreateBulkEmployees = () => {
    const requestBody = getRequestBody();
    if (requestBody.length > 0) {
      if (getSimilarEmails()) {
        showWarningToast("Looks like you have added duplicate email addresses");
        return;
      }
      if (!isValidData(requestBody)) {
        showFaliureToast(t("pleaseFillAllRequiredFields"));
        return;
      } else {
        dispatch(createBulkEmployees({ requestBody, token }))
          .then(unwrapResult)
          .then((res) => {
            Applogger("Response at createBulkEmployees", res);
            handleServerErrorOrResponse(res);
            handleDataOnUserCreate(get(res, "user_created", ""));
            setShowSummaryModal(true);
          })
          .catch((err) => {
            Applogger("Error at createBulkEmployees", err);
            showFaliureToast(err.message);
            handleServerErrorOrResponse(err);
            setShowSummaryModal(true);
          });
      }
    } else {
      showFaliureToast("Please add at least 1 user to continue");
    }
  };

  const handleServerErrorOrResponse = (object) => {
    setServerResponse({
      ...serverResponse,
      bulk_users: get(object, "bulk_users", []),
      errorFE: get(object, "errorFE", []),
      error_for_not_created_user: get(object, "error_for_not_created_user", []),
      successFE: get(object, "successFE", ""),
      user_created: get(object, "user_created", []),
      user_not_created: get(object, "user_not_created", []),
    });
  };

  const getErrorInRow = (index) => {
    const { error_for_not_created_user } = serverResponse;

    const finalObj = error_for_not_created_user.find(
      (requestItem) => index == get(requestItem, "row", "")
    );
    return finalObj;
  };

  const handleDataOnUserCreate = (createdUsers) => {
    let finalArray = getRequestBody();

    finalArray = finalArray.filter(
      (item) => !createdUsers.some((value) => get(item, "row", "") == value)
    );
    let transformedArray = [];

    finalArray.forEach((item) => {
      transformedArray.push({
        ...item,
        work_start_date: moment(
          get(item, "work_start_date", ""),
          "DD/MM/YYYY"
        ).format("YYYY/MM/DD"),
      });
    });
    setEmployeesList(transformedArray);
  };

  const capitalizeString = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const beautifiedErrorMessage = (errorObj) => {
    let errorMessage = "";

    const _ = [get(errorObj, "error", "")].map((childobj) => {
      Object.entries(childobj).map((errorObj) => {
        errorMessage = `${capitalizeString(get(errorObj, "[0]", ""))} : ${get(
          errorObj,
          "[1]",
          ""
        )}`;
      });
    });

    return errorMessage;
  };

  const getSimilarEmails = (rowdata) => {
    let valueArr = employeesList;
    if (rowdata) {
      valueArr = valueArr.filter(
        (item) => get(rowdata, "email", "") == get(item, "email", "")
      );
    }

    valueArr = valueArr.map(function (item) {
      return get(item, "email", "");
    });

    return valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) != idx;
    });
  };

  const columns = [
    {
      title: t("firstName"),
      dataIndex: "first_name",
      key: "first_name",
      render: (_, record, index) => {
        return (
          <input
            onChange={(e) => onNameChangeHandler(index, e.target.value)}
            value={get(record, "first_name", "")}
            className={`filter-form ${
              !get(record, "first_name", "") && "red-border"
            }`}
          />
        );
      },
    },
    {
      title: t("lastName"),
      dataIndex: "last_name",
      key: "last_name",
      render: (_, record, index) => (
        <input
          onChange={(e) => onLastNameChangeHandler(index, e.target.value)}
          value={get(record, "last_name", "")}
          className={`filter-form ${
            !get(record, "last_name", "") && "red-border"
          }`}
        />
      ),
    },
    {
      title: t("email"),
      dataIndex: "email",
      key: "email",
      render: (_, record, index) => {
        return (
          <input
            onChange={(e) => onEmailChangeHandler(index, e.target.value)}
            value={get(record, "email", "")}
            className={`filter-form ${
              (!validateEmail(get(record, "email", "")) ||
                getSimilarEmails(record)) &&
              "red-border"
            }`}
          />
        );
      },
    },
    {
      title: t("workStartDate"),
      dataIndex: "work_start_date",
      key: "work_start_date",
      render: (_, record, index) => {
        const date = get(record, "work_start_date", "");
        return (
          <input
            onChange={(e) => onStartDateChangeHandler(index, e.target.value)}
            type="date"
            value={date ? formattedDateForAPI(date) : ""}
            className={`filter-form ${
              (!date || date == "Invalid date") && "red-border"
            }`}
          />
        );
      },
    },
    {
      title: t("action"),
      key: "action",
      width: 220,
      render: (_, record, index) => {
        return (
          <Space size="middle">
            <Button onClick={() => onResetPressHandler(index)}>
              {t("reset")}
            </Button>
            {employeesList.length > 1 && (
              <Button
                onClick={() => onDeletePressHandler(index)}
                type="primary"
              >
                {t("delete")}
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      {(loading == "pending" || empLoading == "pending") && <Spinner />}
      <Modal
        open={showBulkModal}
        onCancel={() => {
          setShowBulkModal(false);
        }}
        width={"85%"}
        title={t("addMultipleEmployees")}
        footer={null}
      >
        <EmployeeCSV />
      </Modal>
      <Modal
        open={showSummaryModal}
        onCancel={() => {
          setShowSummaryModal(false);
        }}
        onOk={() => {
          setShowSummaryModal(false);
        }}
        width={"85%"}
        title={"Bulk Employee Summary"}
      >
        <UploadSummary summary={serverResponse} employeesList={employeesList} />
      </Modal>
      <div className="d-flex justify-content-end my-3 align-items-end">
        <Button
          onClick={() => {
            setShowBulkModal(true);
          }}
        >
          {t("addMultipleEmployees")}
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={employeesList}
        scroll={{
          x: 900,
          // y: getCalculatedTableHeight(280)
        }}
        pagination={false}
        style={{ marginBottom: 10 }}
      />
      <div className="d-flex justify-content-between my-3 ">
        <Button
          onClick={() => {
            setShowSummaryModal(true);
          }}
        >
          {"Show Summary"}
        </Button>
        <div className="gap-3 align-items-end d-flex ">
          <Button type={"primary"} onClick={() => handleCreateBulkEmployees()}>
            {t("saveAll")}
          </Button>
          <Button
            onClick={() => {
              setEmployeesList([...employeesList, userObjc]);
            }}
          >
            {"Add Another"}
          </Button>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { Tooltip } from "antd";

export default function TooltipComponent({
  children,
  title,
  placement = "top",
}) {
  return (
    <Tooltip
      // color={"#00A5E9"}
      className="capitalize"
      title={title}
      placement={placement}
    >
      {children}
    </Tooltip>
  );
}

export const employeeFormObj = {
  // Step 1
  user_type: "days",

  // Step 2
  title: "",
  initial: "",
  first_name: "",
  last_name: "",
  email: "",
  gender: "",
  mobile_phone: "",
  date_of_birth: "",
  start_date: "",
  marital_status: "",

  // Step 3
  role: "",
  country_id: "",
  work_place_id: "",
  work_place_type: "",
  job_title: "",
  employment_type: "",
  director_status: "",
  date_directorship_began: "",

  // Step 4
  bank_account_number: "",
  bank_account_name: "",
  bank_account_type: "",
  bacs_reference: "",
  bank_name: "",
  bank_address_1: "",
  bank_address_2: "",
  bank_address_3: "",
  bank_address_4: "",
  bank_address_5: "",
  bank_post_code: "",
  building_soc_number: "",

  // Step 5
  address_1: "",
  address_2: "",
  address_3: "",
  address_4: "",
  address_5: "",
  post_code: "",

  // Contract Details
  employee_reference: "",
  works_reference: "",
  termination_date: "",
  tax_code: "",
  ni_number: "",
  // ni_category: "",
  // wk1mth1_basis: 0,
  payment_frequency: "",
  gross_salary: "",
  salary_per_period: "",
  contracted_hours: "",
  contracted_hours_per_period: "",
  sort_code: "",
  payslip_email_address: "",
  payslip_password: "",
  non_uk_worker: 0,
  right_to_work_confirm_status: "",
  right_to_work_document_type: "",
  right_to_work_document_expiry_date: "",
  right_to_work_reference: "",

  // Emergency Contact
  emergency_contact: "",
  contact_telephone_no: "",
  contact_permission: 1,
  contact_relationship: "",
};

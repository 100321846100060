import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  formattedDateForAPI,
  showFaliureToast,
  unixToDateFormater,
} from "../../../Utilities";
import { Applogger } from "../../../Helpers/Logger";
import { getUserEmploymentSalaryInformation } from "../../../Redux/reducers/ProfileReducers";
import { useTranslation } from "react-i18next";
import { salaryInfoObj } from "../Constants";
import { get } from "lodash";
import AppConstants from "../../../Helpers/AppConstants";
import usePrevilagesExist from "../../../Helpers/PrivilegesFuncs";
import SPProfileField from "../Components/SPProfileField";
import SPProfileButton from "../Components/SPProfileButton";
import SPProfileStatus from "../Components/SPProfileStatus";
import SPProfileDropDown from "../Components/SPProfileDropDown";
import useLocalisedConstants from "../../../customHooks/useLocalisedConstants";

export default function SalaryInfoUserEmployement({ updateSalaryInfo, id }) {
  const dispatch = useDispatch();

  const { lng } = useSelector((state) => state.languageReducer);
  const { token } = useSelector((state) => state.AuthenticationReducer);
  const { userEmployementSalaryInfo: salaryInfo } = useSelector(
    (state) => state.profile
  );

  const { exist: updateSalarySensitiveInfo } = usePrevilagesExist(
    AppConstants.previlages.updateSalarySensitiveInfo
  );

  const appConstants = useLocalisedConstants();

  const re = /^[0-9\b]*$/;

  const [salaryInfoBody, setSalaryInfoBody] = useState(salaryInfoObj);

  const { t } = useTranslation();

  useEffect(() => {
    getUserSalaryInfo();
  }, [id]);

  useEffect(() => {
    setSalaryInfoBody({
      payslip_password: get(salaryInfo, "payslip_password", ""),
      payslip_email_address: get(salaryInfo, "payslip_email_address", ""),
      payroll_number: get(salaryInfo, "payroll_number", ""), // M
      gross_salary: get(salaryInfo, "gross_salary", ""),
      payment_frequency: get(salaryInfo, "payment_frequency", ""), // M
      is_pension: get(salaryInfo, "is_pension", ""), // M
      is_opted_out: get(salaryInfo, "is_opted_out", ""), // M
      employee_contribution: get(salaryInfo, "employee_contribution", ""),
      employer_contribution: get(salaryInfo, "employer_contribution", ""),
      salary_per_period: get(salaryInfo, "salary_per_period", ""),
      contracted_hours: get(salaryInfo, "contracted_hours", ""),
      contracted_hours_per_period: get(
        salaryInfo,
        "contracted_hours_per_period",
        ""
      ),
      enrollment_date: unixToDateFormater(
        get(salaryInfo, "enrollment_date", ""),
        AppConstants.defaultDateFormat
      ),
    });
  }, [salaryInfo]);

  const getUserSalaryInfo = () => {
    dispatch(getUserEmploymentSalaryInformation({ token, id: id, lng }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getUserEmploymentSalaryInformation", res);
      })
      .catch((err) => {
        Applogger("Error at getUserEmploymentSalaryInformation", err.message);
      });
  };

  // Validation Changes
  const updateSalary = () => {
    const { payment_frequency, payroll_number, gross_salary } = salaryInfoBody;
    if (!payment_frequency || !payroll_number || !gross_salary) {
      showFaliureToast(t("fieldsMarkedAs") + "*" + t("areMandatory"));
      return;
    } else {
      updateSalaryInfo(salaryInfoBody);
    }
  };

  return (
    <div className="px-2 py-4 border-custom rounded">
      <div className="accordion" id="accordionPanelsStayOpenExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button align-items-start collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#salaryInformation"
              aria-expanded="true"
              aria-controls="salaryInformation"
            >
              <div className="pe-2">
                <h4 className="text-black my-2 fw-bold emp_head">
                  {t("salaryInformation")}
                </h4>
                <p className="text-black-50">
                  {t("salaryAmountAndPaymentFrequency")}
                </p>
              </div>
            </button>
          </h2>
          <div
            id="salaryInformation"
            className="accordion-collapse border-0 collapse"
            aria-labelledby="headingOne"
          >
            <div className="accordion-body p-0">
              <SPProfileField
                isRequired={true}
                title={t("payrollNumber")}
                onChange={(e) => {
                  if (re.test(e.target.value)) {
                    setSalaryInfoBody({
                      ...salaryInfoBody,
                      payroll_number: e.target.value,
                    });
                  }
                }}
                type={"text"}
                disabled={!updateSalarySensitiveInfo}
                maxLength={6}
                value={salaryInfoBody.payroll_number}
                placeholder={t("payrollNumber")}
              />
              <div className="myFlex-cs flex-wrap-sm px-2 pt-2">
                <div className="col-12 col-sm-12 col-md-4">
                  <label className="col-12 col-sm-12 col-md-4">
                    {t("salary")}
                    <span className="text-danger">*</span>
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-8 d-flex">
                  <input
                    onChange={(e) =>
                      setSalaryInfoBody({
                        ...salaryInfoBody,
                        gross_salary: e.target.value,
                      })
                    }
                    type={"text"}
                    disabled={!updateSalarySensitiveInfo}
                    maxLength={12}
                    className="filter-form w-50 w-100-sm"
                    value={salaryInfoBody.gross_salary}
                    placeholder="0"
                  />
                  &nbsp;&nbsp;
                  <select
                    disabled={!updateSalarySensitiveInfo}
                    defaultValue={salaryInfoBody.payment_frequency}
                    value={salaryInfoBody.payment_frequency}
                    onChange={(e) =>
                      setSalaryInfoBody({
                        ...salaryInfoBody,
                        payment_frequency: e.target.value,
                      })
                    }
                    className="filter-form"
                  >
                    {appConstants.paymentFrequencyList.map((payment, index) => {
                      const { title, value } = payment;
                      return (
                        <option key={index} value={value}>
                          {title}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <SPProfileDropDown
                title={t("salaryPerPeriod")}
                value={salaryInfoBody.salary_per_period}
                displayKey={"title"}
                valueKey={"value"}
                dataArray={appConstants.salaryPerPeriodList}
                onChange={(e) => {
                  setSalaryInfoBody({
                    ...salaryInfoBody,
                    salary_per_period: e.target.value,
                  });
                }}
              />
              <SPProfileField
                title={t("contractedHours")}
                onChange={(e) => {
                  if (re.test(e.target.value)) {
                    setSalaryInfoBody({
                      ...salaryInfoBody,
                      contracted_hours: e.target.value,
                    });
                  }
                }}
                type={"text"}
                disabled={!updateSalarySensitiveInfo}
                value={salaryInfoBody.contracted_hours}
                placeholder={t("contractedHours")}
              />
              <SPProfileDropDown
                title={t("contractedHoursPerPeriod")}
                value={salaryInfoBody.contracted_hours_per_period}
                displayKey={"title"}
                valueKey={"value"}
                dataArray={appConstants.contractedHoursPerPeriodList}
                onChange={(e) => {
                  setSalaryInfoBody({
                    ...salaryInfoBody,
                    contracted_hours_per_period: e.target.value,
                  });
                }}
              />
              <SPProfileField
                title={t("payslipEmailAddress")}
                onChange={(e) =>
                  setSalaryInfoBody({
                    ...salaryInfoBody,
                    payslip_email_address: e.target.value,
                  })
                }
                type={"text"}
                disabled={!updateSalarySensitiveInfo}
                value={salaryInfoBody.payslip_email_address}
                placeholder={t("payslipEmailAddress")}
              />
              <SPProfileField
                title={t("payslipPassword")}
                onChange={(e) =>
                  setSalaryInfoBody({
                    ...salaryInfoBody,
                    payslip_password: e.target.value,
                  })
                }
                type={"text"}
                disabled={!updateSalarySensitiveInfo}
                value={salaryInfoBody.payslip_password}
                placeholder={t("payslipPassword")}
              />
              <h4 className="heading ml-2 mt-2">{t("pension")}</h4>

              <SPProfileStatus
                title={t("pensionEligible")}
                isActive={salaryInfoBody.is_pension == "1"}
                isRequired={true}
                onClick={(value) =>
                  setSalaryInfoBody({
                    ...salaryInfoBody,
                    is_pension: value,
                    is_opted_out:
                      value == "0" ? "1" : salaryInfoBody.is_opted_out,
                  })
                }
              />
              {salaryInfoBody.is_pension == "1" && (
                <SPProfileStatus
                  title={t("optedOut")}
                  isActive={salaryInfoBody.is_opted_out == "1"}
                  isRequired={true}
                  onClick={(value) =>
                    setSalaryInfoBody({
                      ...salaryInfoBody,
                      is_opted_out: value,
                    })
                  }
                />
              )}
              {salaryInfoBody.is_opted_out == "0" && (
                <div>
                  <SPProfileField
                    title={t("employeeContribution")}
                    placeholder="0"
                    maxLength={6}
                    onChange={(e) => {
                      if (re.test(e.target.value)) {
                        setSalaryInfoBody({
                          ...salaryInfoBody,
                          employee_contribution: e.target.value,
                        });
                      }
                    }}
                    value={salaryInfoBody.employee_contribution}
                    type={"text"}
                  />
                  <SPProfileField
                    title={t("employerContribution")}
                    placeholder="0"
                    maxLength={6}
                    onChange={(e) => {
                      if (re.test(e.target.value)) {
                        setSalaryInfoBody({
                          ...salaryInfoBody,
                          employer_contribution: e.target.value,
                        });
                      }
                    }}
                    value={salaryInfoBody.employer_contribution}
                    type={"text"}
                  />
                  <SPProfileField
                    title={t("enrolmentDate")}
                    placeholder="0"
                    maxLength={6}
                    onChange={(e) => {
                      setSalaryInfoBody({
                        ...salaryInfoBody,
                        enrollment_date: formattedDateForAPI(e.target.value),
                      });
                    }}
                    value={salaryInfoBody.enrollment_date}
                    defaultValue={salaryInfoBody.enrollment_date}
                    type={"date"}
                  />
                </div>
              )}
              <label className="ml-2 mt-2">
                {t("fieldsMarkedAs")}
                <span className="text-danger">{" * "}</span>
                {t("areMandatory")}
              </label>
              <br />
              <SPProfileButton
                title={t("save")}
                onClick={() => updateSalary()}
                isVisible={updateSalarySensitiveInfo}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";
import AppConstants from "../../../Helpers/AppConstants";
import usePrevilagesExist from "../../../Helpers/PrivilegesFuncs";
import useLocalisedConstants from "../../../customHooks/useLocalisedConstants";

export default function ReportsCard() {
  const { t } = useTranslation();

  const { exist: generateAbsenceReport } = usePrevilagesExist(
    AppConstants.previlages.generateAbsenceReport
  );

  const { exist: generateEmployeeReport } = usePrevilagesExist(
    AppConstants.previlages.generateEmployeeReport
  );

  const { exist: generateHolidayReport } = usePrevilagesExist(
    AppConstants.previlages.generateHolidayReport
  );

  const { exist: generateTimesheetReport } = usePrevilagesExist(
    AppConstants.previlages.generateTimesheetReport
  );

  const appConstants = useLocalisedConstants();

  const { reportCategories } = appConstants;

  return (
    <div>
      {generateAbsenceReport && (
        <>
          <a
            href={`${AppConstants.routes.analytics_details}?type=${reportCategories.absence}`}
            className="card-link text-primary"
          >
            {t("absenceReport")}
          </a>
          <br />
        </>
      )}
      {generateHolidayReport && (
        <>
          <a
            href={`${AppConstants.routes.analytics_details}?type=${reportCategories.holiday_summary}`}
            className="card-link text-primary"
          >
            {t("annualLeaveReport")}
          </a>
          <br />
        </>
      )}
      {generateTimesheetReport && (
        <>
          <a
            href={`${AppConstants.routes.analytics_details}?type=${reportCategories.blip_timesheet}`}
            className="card-link text-primary"
          >
            {t("timeSheetReport")}
          </a>
          <br />
        </>
      )}
      {generateEmployeeReport && (
        <a
          href={`${AppConstants.routes.analytics_details}?type=${reportCategories.people_details}`}
          className="card-link text-primary"
        >
          {t("empDetailReport")}
        </a>
      )}
    </div>
  );
}

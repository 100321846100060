import React, { useState, useEffect } from "react";
import { unixToDateFormater } from "../../Utilities";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Applogger } from "../../Helpers/Logger";
import { Timeline, Modal, Col, Row, Input, Space, Button, Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getClockedInAndOutUsersList,
  toggleClock,
} from "../../Redux/reducers/TimesheetReducer";
import { get } from "lodash";
import { changeUserProfileStatus } from "../../Redux/reducers/ProfileReducers";
import { ClockCell } from "./HistoryPanel";
import AppConstants from "../../Helpers/AppConstants";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";

export default function WhoseIn({ refreshStatus, reloadPage }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { exist: ClockInOutOtherUsers } = usePrevilagesExist(
    AppConstants.previlages.ClockInOutOtherUsers
  );

  const { exist: listOthersTimesheetHistory } = usePrevilagesExist(
    AppConstants.previlages.listOthersTimesheetHistory
  );

  const { t, i18n } = useTranslation();

  const { token, role } = useSelector((state) => state.AuthenticationReducer);
  const { whoseInUsersList, loading } = useSelector((state) => state.timesheet);
  const { lng } = useSelector((state) => state.languageReducer);

  const [clockedInUsersList, setClockedInUsersList] = useState([]);
  const [clockedOutUsersList, setClockedOutUsersList] = useState([]);
  const [clockInFilteredList, setclockInFilteredList] = useState([]);
  const [clockOutFilteredList, setClockOutFilteredList] = useState([]);

  const [modalVisible, setModalVisible] = useState(false);
  const [clockInModal, setClockInModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserBreaks, setSelectedUserBreaks] = useState([]);
  const { Search } = Input;

  useEffect(() => {
    callClockedInAndOutUsersApi();
  }, []);

  useEffect(() => {
    if (refreshStatus) {
      callClockedInAndOutUsersApi();
      reloadPage(false);
    }
  }, [refreshStatus]);

  useEffect(() => {
    setclockInFilteredList(clockedInUsersList);
    setClockOutFilteredList(clockedOutUsersList);
  }, [clockedInUsersList, clockedOutUsersList]);

  useEffect(() => {
    filterWhoseInOutUsersList();
  }, [whoseInUsersList]);

  const filterWhoseInOutUsersList = () => {
    var clockedInUsers = [];
    var clockedOutUsers = [];
    if (whoseInUsersList.length > 0) {
      whoseInUsersList.forEach((element) => {
        if (element.status == AppConstants.clockStates.in) {
          clockedInUsers.push(element);
        } else {
          clockedOutUsers.push(element);
        }
      });

      setClockedInUsersList(clockedInUsers);
      setClockedOutUsersList(clockedOutUsers);
    }
  };

  const callClockedInAndOutUsersApi = () => {
    if (token) {
      dispatch(getClockedInAndOutUsersList({ token, lng }))
        .then(unwrapResult)
        .then((response) => {})
        .catch((err) => {
          Applogger("Error at getClockedInAndOutUsersList", err.message);
        });
    }
  };

  const handleModal = (userObj) => {
    setModalVisible(true);
    setSelectedUser(userObj);
    filterBreaks(userObj);
  };

  const handleClockInModal = (userObj) => {
    setClockInModal(true);
    setSelectedUser(userObj);
    filterBreaks(userObj);
  };

  const filterBreaks = (userObj) => {
    var breaks = [];
    userObj.break.forEach((element) => {
      if (
        unixToDateFormater(userObj.date, AppConstants.dateFormat) >=
        unixToDateFormater(element.date, AppConstants.dateFormat)
      ) {
        breaks.push(element);
      }
    });
    setSelectedUserBreaks(breaks);
  };

  const handleClockInOutUser = (statusValue) => {
    setModalVisible(false);
    setClockInModal(false);
    dispatch(
      toggleClock({
        token: token,
        status: statusValue,
        userId: selectedUser.user_id,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (statusValue == AppConstants.clockStates.out) {
          setUserStatus(AppConstants.workingStatus.not_working);
        } else {
          setUserStatus(AppConstants.workingStatus.working_from_usual_location);
        }
        reloadPage(true);
        Applogger("Response at toggleClock", res);
      })
      .catch((err) => {
        Applogger("Error at toggleClock ", err.message);
      });
  };

  const setUserStatus = (userStatus) => {
    var userId = selectedUser.user_id;
    let data = {
      token: token,
      currentStatus: userStatus,
      userId: userId,
    };

    dispatch(changeUserProfileStatus(data))
      .then(unwrapResult)
      .then((res) => {
        Applogger(
          "Response at changeUserProfileStatus at Whose In Clock out",
          res
        );
      })
      .catch((err) => {
        Applogger(
          "Error at changeUserProfileStatus at Whose In Clock out",
          err.message
        );
      });
  };

  const onSearchIn = (val) => {
    let value = val.toLowerCase();
    var clockInList = [];

    clockInList = clockedInUsersList.filter((data) => {
      return (
        (data.first_name + " " + data.last_name).toLowerCase().search(value) !=
        -1
      );
    });
    setclockInFilteredList(clockInList);
  };
  const showUserHistoryHandler = () => {
    if (selectedUser !== null) {
      setClockInModal(false);
      setModalVisible(false);
      navigate(
        `${AppConstants.routes.timeSheetHistory}?user=${selectedUser.user_id}`
      );
    }
  };

  const onSearchOut = (val) => {
    let value = val.toLowerCase();
    var clockOutList = [];

    clockOutList = clockedOutUsersList.filter((data) => {
      return (
        (data.first_name + " " + data.last_name).toLowerCase().search(value) !=
        -1
      );
    });
    setClockOutFilteredList(clockOutList);
  };

  const calculatWhoseInPanelHeight = () => {
    if (document && window) {
      const element = document.getElementById("MapAndStatusDiv");
      if (element) {
        return get(element, "offsetHeight", "70vh")
          ? element.offsetHeight + "px"
          : "70vh";
      }
    } else {
      return "70vh";
    }
  };

  return (
    <div
      data-aos-duration="1500"
      data-aos="fade-left"
      className="col-sm-12 col-md"
    >
      <div
        className="whosInCard border-custom overflow-hidden"
        style={{ height: "100%" }}
      >
        <div className="sidebar-head">
          <h3 className="mb-0 p-2">{t("whoIn")}</h3>
          <ul className="nav nav-tabs" id="ex2" role="tablist">
            <li className="nav-item w-50" role="presentation">
              <a
                className="nav-link active"
                id="ex2-tab-1"
                data-mdb-toggle="tab"
                href="#ex2-tabs-1"
                role="tab"
                aria-controls="ex2-tabs-1"
                aria-selected="true"
              >
                {t("clockedIn")}
                <span className="badge1 mx-2">
                  ({clockedInUsersList.length})
                </span>
              </a>
            </li>
            <li className="nav-item w-50" role="presentation">
              <a
                className="nav-link"
                id="ex2-tab-2"
                data-mdb-toggle="tab"
                href="#ex2-tabs-2"
                role="tab"
                aria-controls="ex2-tabs-2"
                aria-selected="false"
              >
                {t("clockedOut")}
                <span className="badge1 mx-2">
                  ({clockedOutUsersList.length})
                </span>
              </a>
            </li>
          </ul>
        </div>

        <div
          className="tab-content p-0"
          id="ex1-content"
          style={{ height: "100%" }}
        >
          <div
            className="tab-pane fade show active p-0"
            id="ex2-tabs-1"
            role="tabpanel"
            aria-labelledby="ex2-tab-1"
          >
            <Space direction="vertical">
              <Search
                className="h-100 w-100 outline-none"
                placeholder={t("filterEmployee")}
                onSearch={onSearchIn}
                onChange={(e) => onSearchIn(e.target.value)}
                allowClear
              />
            </Space>
            <div className="clocked_list p-2">
              <div
                id="hello"
                style={{
                  maxHeight: calculatWhoseInPanelHeight(),
                  overflowY: "auto",
                }}
              >
                {clockInFilteredList.map((user, index) => {
                  return (
                    <TimsheetCell
                      user={user}
                      key={index}
                      onClick={() => handleModal(user)}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade p-0"
            id="ex2-tabs-2"
            role="tabpane2"
            aria-labelledby="ex2-tab-2"
            style={{ height: "100%" }}
          >
            <Space direction="vertical">
              <Search
                className="h-100 w-100 outline-none"
                placeholder={t("filterEmployee")}
                onSearch={onSearchOut}
                onChange={(e) => onSearchOut(e.target.value)}
                allowClear
              />
            </Space>
            <div className="clocked_list p-2" style={{ height: "100%" }}>
              <div
                id="hello"
                style={{
                  maxHeight: calculatWhoseInPanelHeight(),
                  overflowY: "auto",
                }}
              >
                {clockOutFilteredList.map((user, index) => {
                  return (
                    <TimsheetCell
                      user={user}
                      key={index}
                      onClick={() => handleClockInModal(user)}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={selectedUser !== null && getUsername(selectedUser)}
        centered
        onCancel={() => setModalVisible(false)}
        visible={modalVisible}
        footer={
          <div>
            {listOthersTimesheetHistory && (
              <Button onClick={showUserHistoryHandler} type="primary">
                {t("showHistory")}
              </Button>
            )}
            {ClockInOutOtherUsers && (
              <Button
                onClick={() =>
                  handleClockInOutUser(AppConstants.clockStates.out)
                }
                type="primary"
              >
                {t("clockUserOut")}
              </Button>
            )}
            <Button onClick={() => setModalVisible(false)}>
              {t("cancel")}
            </Button>
          </div>
        }
      >
        <Row gutter={16}>
          <Col span={12}>
            {selectedUser && (
              <ClockCell
                title={t("clockIn")}
                time={get(selectedUser, "clock_in", "")}
                date={get(selectedUser, "clock_in", "")}
              />
            )}
          </Col>
          <Col span={12}>
            {selectedUser && (
              <ClockCell
                title={t("clockOut")}
                time={get(selectedUser, "clock_out", "")}
                date={get(selectedUser, "clock_out", "")}
              />
            )}
          </Col>
        </Row>
        <br />
        <hr />
        <br />
        <div style={{ overflowX: "hidden", maxHeight: 200 }}>
          <Timeline mode="left">
            <h2 className="h5">
              {t("breaks")}
              <span className="badge mx-2">{selectedUserBreaks.length}</span>
            </h2>
            <br />
            {selectedUser !== null && selectedUser.break.length > 0 ? (
              selectedUser.break.map((item, index) => {
                if (
                  unixToDateFormater(
                    selectedUser.date,
                    AppConstants.dateFormat
                  ) >= unixToDateFormater(item.date, AppConstants.dateFormat)
                ) {
                  return (
                    <Timeline.Item
                      key={index}
                      label={
                        t("start") +
                        unixToDateFormater(
                          item.time_start,
                          AppConstants.timeFormat
                        )
                      }
                    >
                      {t("end") +
                        unixToDateFormater(
                          item.time_end,
                          AppConstants.timeFormat
                        )}
                    </Timeline.Item>
                  );
                }
              })
            ) : (
              <p style={{ marginLeft: 4 }}>{t("noBreakFound")}</p>
            )}
          </Timeline>
        </div>
      </Modal>
      <Modal
        title={selectedUser !== null && getUsername(selectedUser)}
        centered
        onCancel={() => setClockInModal(false)}
        visible={clockInModal}
        footer={
          <div>
            {listOthersTimesheetHistory && (
              <Button onClick={showUserHistoryHandler} type="primary">
                {t("showHistory")}
              </Button>
            )}
            {ClockInOutOtherUsers && (
              <Button
                onClick={() =>
                  handleClockInOutUser(AppConstants.clockStates.in)
                }
                type="primary"
              >
                {t("clockInUser")}
              </Button>
            )}
            <Button onClick={() => setClockInModal(false)}>Cancel</Button>
          </div>
        }
        okText={t("clockInUser")}
      >
        <Row gutter={16}>
          <Col span={12}>
            <h2 className="h5">{t("clockIn")}</h2>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

const TimsheetCell = ({ onClick, index, user }) => {
  const { get } = require("lodash");

  const profileImage = get(user, "profile_image", null);
  const breakStatus = get(user, "break_status", "");
  const workingStatus = get(user, "working_status", "");
  const { t, i18n } = useTranslation();
  return (
    <div
      onClick={onClick}
      className="d-flex mb-2 animate__animated animate__backInDown align-items-center cursor-pointer"
      key={index}
    >
      {profileImage == null ? (
        <div className="profile me-2">
          <b className="uppercase">{usernameInitials(user)}</b>
        </div>
      ) : (
        <Avatar size={70} className="me-2" src={profileImage} />
      )}
      <div>
        <h6 className="fw-bold capitalize">
          {getUsername(user).slice(0, 20)}
          {breakStatus == "ON" && (
            <span
              style={{
                color: "red",
                fontWeight: "600",
                letterSpacing: 1.2,
                fontSize: 12,
              }}
            >
              {` (${t("onBreak")})`}
            </span>
          )}
        </h6>
        <span className="text-black-50">{t(workingStatus)}</span>
      </div>
    </div>
  );
};

const getUsername = (user) => {
  if (user !== null) {
    const { first_name, last_name } = user;
    return first_name + " " + last_name;
  }
};

const usernameInitials = (user) => {
  if (user !== null) {
    const { first_name, last_name } = user;
    return first_name.slice(0, 1) + last_name.slice(0, 1);
  }
};

import React, { useEffect, useState } from "react";
import { Space, Table } from "antd";
import Button from "../OnBoardingComponents/Button";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import {
  getCalculatedTableHeight,
  showFaliureToast,
  showSuccessToast,
} from "../../Utilities";
import { Applogger } from "../../Helpers/Logger";
import {
  addRoleSettingRequest,
  deleteRoleSettingRequest,
  getAllRolesRequest,
  getRolesSettingRequest,
  updateRoleSettingRequest,
} from "../../Redux/reducers/SettingsReducer";
import RoleModal from "./RoleModal";
import Spinner from "../Spinner";
import AppConstants from "../../Helpers/AppConstants";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";

export default function Roles({ isOnboarding = false }) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { token, user } = useSelector((state) => state.AuthenticationReducer);
  const { rolesSettings, roles, loading } = useSelector(
    (state) => state.settings
  );
  const companyId = get(user, "company_id", "");

  const [currentRoleData, setCurrentRoleData] = useState(null);
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [rolesSettingLocal, setRoleSettingsLocal] = useState([]);

  const { exist: createRoleSetting } = usePrevilagesExist(
    AppConstants.previlages.createRoleSetting
  );

  const { exist: UpdateDeleteRoleSetting } = usePrevilagesExist(
    AppConstants.previlages.UpdateDeleteRoleSetting
  );

  useEffect(() => {
    if (token) {
      // if (rolesSettings.length == 0) {
      getRolesSettingRequestHandler();
      // }
      // if (roles.length == 0) {
      getAllRolesRequestHandler();
      // }
    }
  }, [token]);

  useEffect(() => {
    if (rolesSettings) {
      if (Array.isArray(rolesSettings)) {
        const allRolesSettings = rolesSettings.map((val) => {
          return {
            ...val,
            leave_allowance:
              val.role.role_type == AppConstants.userRoleTypes.daily
                ? val.annual_leaves_allowance_days == 0
                  ? "-"
                  : `${val.annual_leaves_allowance_days} Days`
                : val.annual_leaves_allowance_hours == 0
                ? "-"
                : `${val.annual_leaves_allowance_hours} hours ${val.annual_leaves_allowance_mins} minutes`,
          };
        });
        setRoleSettingsLocal(allRolesSettings);
      }
    }
  }, [rolesSettings]);

  const columns = [
    {
      title: t("role"),
      dataIndex: "role",
      key: "role",
      render: (role) => <a>{role?.name ?? "-"}</a>,
    },
    {
      title: t("locationType"),
      dataIndex: "location_type",
      key: "location_type",
      render: (location) => <a>{location?.name ?? "-"}</a>,
    },
    {
      title: t("lineManager"),
      key: "line_manager",
      dataIndex: "line_manager",
      render: (manager) => <a>{manager?.name ?? "-"}</a>,
    },
    {
      title: t("approver"),
      key: "approver",
      dataIndex: "approver",
      render: (approver) => <a>{approver?.name ?? "-"}</a>,
    },
    {
      title: t("leaveAllowance"),
      key: "leave_allowance",
      dataIndex: "leave_allowance",

      render: (leave) => <a>{leave ?? "-"}</a>,
    },

    {
      width: 250,
      title: t("action"),
      key: "action",
      render: (data) => (
        <Space size="middle">
          {UpdateDeleteRoleSetting && (
            <>
              <Button
                type="primary"
                onClick={() => {
                  setCurrentRoleData(data);
                  setShowRoleModal(true);
                }}
              >
                {t("edit")}
              </Button>
              <Button
                disabled={
                  get(data, "role.name", "") == AppConstants.roleTypes.Owner ||
                  get(data, "role.is_default", 0) == 1
                }
                onClick={() => deleteRoleSettingRequestHandler(data.id)}
              >
                {t("delete")}
              </Button>
            </>
          )}
        </Space>
      ),
    },
  ];

  const getRolesSettingRequestHandler = () => {
    dispatch(getRolesSettingRequest({ token, companyId }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getRolesSettingRequest", res);
      })
      .catch((err) => {
        Applogger("Error at getRolesSettingRequest", err);
      });
  };

  const getAllRolesRequestHandler = () => {
    dispatch(getAllRolesRequest({ token, companyId }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getAllRolesRequest", res);
      })
      .catch((err) => {
        Applogger("Error at getAllRolesRequest", err);
      });
  };

  const addRoleTypeHandler = (roleForm, currentUserType) => {
    const { location_type_id, role_id } = roleForm;

    if (!location_type_id) {
      showFaliureToast("Please select location to continue");
      return;
    } else if (!role_id) {
      showFaliureToast("Please select role to continue");
      return;
    }
    dispatch(
      addRoleSettingRequest({ token, roleForm, companyId, currentUserType })
    )
      .then(unwrapResult)
      .then((res) => {
        setShowRoleModal(false);
        getRolesSettingRequestHandler();
        getAllRolesRequest();
        Applogger("Response at addRoleSettingRequest", res);
        showSuccessToast("Role Added Successfully");
      })
      .catch((err) => {
        showFaliureToast(err?.message ?? "Failed to add this role");
        Applogger("Error at addRoleSettingRequest", err);
      });
  };

  const deleteRoleSettingRequestHandler = (role_id) => {
    dispatch(deleteRoleSettingRequest({ token, role_id, companyId }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at deleteRoleSettingRequest", res);
        showSuccessToast("Role Deleted Successfully");
        getRolesSettingRequestHandler();
        getAllRolesRequest();
      })
      .catch((err) => {
        Applogger("Error at deleteRoleSettingRequest", err);
        if (get(err, "message", "")) showFaliureToast(err?.message);
        else showFaliureToast(err);
      });
  };

  const updateRoleSettingRequestHandler = (
    roleForm,
    roleId,
    currentUserType
  ) => {
    const { location_type_id, role_id } = roleForm;

    if (!location_type_id) {
      showFaliureToast("Please select location to continue");
      return;
    } else if (!role_id) {
      showFaliureToast("Please select role to continue");
      return;
    }

    dispatch(
      updateRoleSettingRequest({
        token,
        roleId,
        roleForm,
        companyId,
        currentUserType,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        Applogger("Role Updated", res);
        showSuccessToast("Role Updated Successfully");
        setShowRoleModal(false);
        getRolesSettingRequestHandler();
        getAllRolesRequest();
        setCurrentRoleData(null);
      })
      .catch((err) => {
        Applogger("Role Update Failed", err);
        showFaliureToast(err?.message ?? "Failed to update this role");
      });
  };

  return (
    // <AssignLineManager />
    <div>
      {loading == "pending" && <Spinner />}
      <RoleModal
        title={t("addRollSetting")}
        companyId={companyId}
        updateRole={updateRoleSettingRequestHandler}
        isModalVisible={showRoleModal}
        handleOk={addRoleTypeHandler}
        handleCancel={() => setShowRoleModal(!showRoleModal)}
        roleSetting={currentRoleData}
      />
      {createRoleSetting && (
        <div
          className={`d-flex ${
            isOnboarding ? "justify-content-between" : "justify-content-end"
          } my-3 align-items-end`}
        >
          {isOnboarding && <p className="mb-0">{t("roleSettingsNote")}</p>}
          <Button
            className={"align-end"}
            onClick={() => {
              setShowRoleModal(true);
              setCurrentRoleData(null);
            }}
          >
            {t("addARole")}
          </Button>
        </div>
      )}
      <Table
        className="mainComponentHeight height-fit-content"
        columns={columns}
        dataSource={rolesSettingLocal}
        // scroll={{ y: isOnboarding ? getCalculatedTableHeight(270) : 350 }}
        scroll={{ x: 400 }}
        pagination={{ pageSize: AppConstants.pagination.size }}
      />
    </div>
  );
}

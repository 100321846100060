import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showFaliureToast, showSuccessToast } from "../../Utilities";
import { Applogger } from "../../Helpers/Logger";
import { useTranslation } from "react-i18next";
import {
  getOthersPendingRequest,
  postApprove,
  RequestCalenderData,
  getOthersDeclineLeavesRequest,
} from "../../Redux/reducers/CalenderReducer";
import Spinner from "../Spinner";
import RequestCard from "./RequestCard";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";
import AppConstants from "../../Helpers/AppConstants";

export default function OthersPendingRequest({ logoutUser }) {
  const dispatch = useDispatch();
  const { token, role } = useSelector((state) => state.AuthenticationReducer);
  const { lng } = useSelector((state) => state.languageReducer);
  const { othersPendingRequests } = useSelector((state) => state.calender);

  const { exist: ApproveDeclinePendingLeave } = usePrevilagesExist(
    AppConstants.previlages.ApproveDeclinePendingLeave
  );

  const [isLoading, setIsLoading] = useState(false);
  const [approveReq, setApproveReq] = useState(false);
  const [pendingRequestLocal, setPendingRequestLocal] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    if (token) {
      setIsLoading(true);
      getPendingRequests();
    }
  }, [approveReq]);

  useEffect(() => {
    if (othersPendingRequests) {
      setPendingRequestLocal(othersPendingRequests);
    }
  }, [othersPendingRequests]);

  const getPendingRequests = () => {
    dispatch(getOthersPendingRequest({ token, lng: lng }))
      .then(unwrapResult)
      .then((res) => {
        setIsLoading(false);
        Applogger("Response at getOthersPendingRequest", res);
      })
      .catch((err) => {
        setIsLoading(false);
        Applogger("Error at getOthersPendingRequest", err);
      });
  };

  const getDataHandler = () => {
    dispatch(RequestCalenderData({ token, lng: lng }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("getDataHandler Response", res);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.message == "Request failed with status code 401") {
          logoutUser();
          showFaliureToast("Please Login To Continue");
        }
        Applogger("Error at getDataHandler", err.message);
      });
  };

  const getDeclinedRequest = () => {
    try {
      dispatch(getOthersDeclineLeavesRequest({ token }))
        .then(unwrapResult)
        .then((res) => {
          Applogger("Response at getOthersDeclineLeavesRequest", res);
        })
        .catch((err) => {
          Applogger("Error at getOthersDeclineLeavesRequest", err);
        });
    } catch (err) {
      Applogger("error at declined request", err);
    }
  };
  const changeStatusRequest = (val) => {
    try {
      var reason = "";
      const { status, id } = val;
      if (val.reason) {
        reason = val.reason;
      }
      dispatch(postApprove({ status, id, token, reason }))
        .then(unwrapResult)
        .then((res) => {
          Applogger("Response at postApprove", res);
          // if (res.status === 200) {
          getDeclinedRequest();
          if (val.status == "approved") {
            if (approveReq == true) {
              getPendingRequests();
            } else {
              setApproveReq(true);
            }
          } else if (val.status == "declined") {
            if (approveReq == false) {
              getPendingRequests();
            } else {
              setApproveReq(false);
            }
          }
          getDataHandler();
          showSuccessToast(`Request ${status} successfully`);
          // }
        })
        .catch((err) => {
          setApproveReq(false);
          showFaliureToast(`Request ${status} failed`);
        });
    } catch (err) {
      Applogger("Error in catch postApprove", err);
    }
  };

  return (
    <div>
      {isLoading && <Spinner />}

      <h1 className="mt-3 fw-bold subheading_2">{`${t(
        "othersPendingRequests"
      )} (${pendingRequestLocal.length})`}</h1>
      {pendingRequestLocal.length > 0 ? (
        <div className="requestHeader ">
          <div className="d-flex w-50">
            <div className="w-50">{t("request")}</div>
            <div>{t("shouldIapprove")}</div>
          </div>
        </div>
      ) : null}

      {pendingRequestLocal.map((leave, index) => {
        return (
          <div key={index}>
            <RequestCard
              role={role}
              ApproveDeclinePendingLeave={ApproveDeclinePendingLeave}
              changeStatusRequest={changeStatusRequest}
              requestData={leave}
            />
          </div>
        );
      })}
    </div>
  );
}

import React, { useEffect } from "react";
import Routes from "../src/Routes";
import configureStores from "../src/Redux/store";
import { PersistGate } from "redux-persist/es/integration/react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useIdleTimer } from "react-idle-timer";
import { Applogger } from "./Helpers/Logger";
import {
  logoutRequest,
  stopLoaderAndEmptyErrors,
} from "./Redux/reducers/AuthenticationReducer";
import "animate.css";
import "react-activity/dist/library.css";
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.min.css";
import "remixicon/fonts/remixicon.css";
import "./style.css";
import "animate.css";
import "./index.css";
import "./i18n";

export default function App() {
  const { persistor, store } = configureStores();

  useEffect(() => {
    store.dispatch(stopLoaderAndEmptyErrors());
  }, []);

  const onIdle = () => {
    Applogger("Logged out because of no activty");
    store.dispatch(logoutRequest());
  };

  useIdleTimer({
    onIdle,
    timeout: 60 * 60 * 1000, // 60 minute idle timeout
  });

  return (
    <>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Routes />
          <ToastContainer limit={5} />
        </PersistGate>
      </Provider>
    </>
  );
}

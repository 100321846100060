import React, { useState, useEffect } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { Applogger } from "../../Helpers/Logger";
import { useTranslation } from "react-i18next";
import { getOthersDeclineLeavesRequest } from "../../Redux/reducers/CalenderReducer";
import Spinner from "../Spinner";
import AbsenceCard from "../Profile/userAbsence/AbsenceCard";

export default function OthersDeclinedAbsence() {
  const dispatch = useDispatch();

  const { token } = useSelector((state) => state.AuthenticationReducer);
  const { othersDeclinedLeaves, isLoading } = useSelector(
    (state) => state.calender
  );
  const [declinedRequests, setDeclinedReqeust] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    if (othersDeclinedLeaves) {
      setDeclinedReqeust(othersDeclinedLeaves);
    }
  }, [othersDeclinedLeaves]);

  useEffect(() => {
    if (token) {
      getDeclinedRequest();
    }
  }, []);

  const getDeclinedRequest = () => {
    try {
      dispatch(getOthersDeclineLeavesRequest({ token }))
        .then(unwrapResult)
        .then((res) => {
          Applogger("Response at getOthersDeclineLeavesRequest", res);
        })
        .catch((err) => {
          Applogger("Error at getOthersDeclineLeavesRequest", err);
        });
    } catch (err) {
      Applogger("error at declined request", err);
    }
  };
  return (
    <div>
      {isLoading && <Spinner />}

      <h1 className="mt-3 fw-bold subheading_2">{`${t(
        "othersDeclinedRequests"
      )} (${declinedRequests.length})`}</h1>
      {declinedRequests.map((absence, index) => {
        return <AbsenceCard key={index} cardData={absence} disabled />;
      })}
    </div>
  );
}

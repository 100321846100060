import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next"
import { get } from "lodash";
export default function StaffingViewModal({
  onCancel,
  modalStatus,
}) {
  const { currentStaffingData } = useSelector((state) => state.staffing);
  const [currentStaffing, setCurrentStaffing] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (currentStaffingData && currentStaffingData.length > 0) {
      setCurrentStaffing(currentStaffingData);
    }
  }, [currentStaffingData]);


  const onCancelHandler = () => {
    setCurrentStaffing([]);
    onCancel();
  };

  return (
    <Modal
      title={t("viewStaff")}
      centered
      onCancel={onCancelHandler}
      visible={modalStatus}
      width={800}
      footer={false}
    >
      <div>

        <br />
        <div className="view-staffing-table">
          <div className="fw-bold">{t("employee")}</div>
          <div className="fw-bold">{t("currentContractedHours")}</div>
          <div className="fw-bold">{t("breaksTotal")}</div>
          <div className="fw-bold">
            {t("totalHours")} <br />
            <span className="me-2">{t("incBreak")}</span>
            {/* <Checkbox onChange={onChangeInclBreaks} /> */}
          </div>

          <hr style={{ gridColumn: "span 4" }} />

          {currentStaffing.map((val, index) => {
            const fullName = get(val, "full_name", "")
            const totalMins = get(val, "total_hours.mins", 0)
            const totalHours = get(val, "total_hours.hours", 0)
            const breakDuration = get(val, "break_duration", 0)
            const contractedHours = get(val, "contacted_hours.hours", 0)
            const contactedMins = get(val, "contacted_hours.mins", 0)

            return (
              <React.Fragment key={index}>
                <p className="mb-0">{fullName}</p>
                <p className="mb-0">
                  {totalHours} {t("hrs")} {totalMins}{" "}
                  {t("min")}
                </p>
                <p className="mb-0">
                  {breakDuration} {t("min")}
                </p>
                <p className="mb-0">{`${contractedHours} ${t(
                  "hrs"
                )} ${contactedMins} ${t("min")}`}</p>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </Modal>
  );
}

import React, { useEffect, useState } from "react";
import Absenceinfocard from "./Absenceinfocard";
import AbsenceCard from "./AbsenceCard";
import AppConstants from "../../../Helpers/AppConstants";
import AbsenceWarnModal from "./AbsenceWarnModal";
import {
  getUserAbsence,
  deletetUserAbsence,
} from "../../../Redux/reducers/ProfileReducers";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { showFaliureToast, showSuccessToast } from "../../../Utilities";
import { Applogger } from "../../../Helpers/Logger";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import usePrevilagesExist from "../../../Helpers/PrivilegesFuncs";
import LeaveYearDropDownOpts from "../../LeaveYear/LeaveYearDropDownOpts";
import moment from "moment";

export default function UserAbsence({ currentUserId }) {
  const navigate = useNavigate();

  const { token, role } = useSelector((state) => state.AuthenticationReducer);
  const { user, profileAbsence } = useSelector((state) => state.profile);
  const { lng } = useSelector((state) => state.languageReducer);

  const { exist: absenceRecordList } = usePrevilagesExist(
    AppConstants.previlages.absenceRecordList
  );

  const { exist: createOvertimeLog } = usePrevilagesExist(
    AppConstants.previlages.createOvertimeLog
  );

  const { t } = useTranslation();

  const absencesTypes = {
    allAbsences: "allAbsences",
    annualLeave: "annualLeave",
    sickness: "sickness",
    lateness: "lateness",
    toil: "toil",
  };

  const currentYear = new Date().getFullYear();
  const [currentValue, setCurrentValue] = useState(absencesTypes.allAbsences);
  const [yearFilter, setYearFilter] = useState("");

  const currentYearEndDate = `31-12-${currentYear}`;
  const currentYearStartDate = `01-01-${currentYear}`;

  const previousYearStartDate = `01-01-${currentYear - 1}`;
  const previousYearEndDate = `31-12-${currentYear - 1}`;

  const futureYearStartDate = `01-01-${currentYear + 1}`;
  const futureYearEndDate = `31-12-${currentYear + 1}`;

  const dispatch = useDispatch();
  const Sickness = "Sickness Absence";
  const annual_leave = "Annual Leave";
  const Latness = "Lateness";
  const Toil = "TOIL";

  const [currentType, setCurrentType] = useState("days");
  const [currentAbsenceData, setCurrent] = useState([]);
  const [absenceHistory, setHistory] = useState([]);
  const [sicknessDataState, setSicknessDataState] = useState([]);
  const [annualLeaveDataState, setAnnualLeaveDataState] = useState([]);
  const [toilDataState, setToilDataState] = useState([]);
  const [otherDataState, setOtherDataState] = useState([]);
  // const [absencesColor, setAbsencesColor] = useState({
  //   latnessColor: "",
  //   annualLeaveColor: "",
  //   sicknessColor: "",
  //   otherColor: "#EC1522",
  //   toilColor: "",
  // });

  const [cancelAbsenceId, setCancelAbsenceId] = useState(0);
  const [cancelAbsenceType, setCancelAbsenecType] = useState("");

  // Colors
  // const latnessColor = get(
  //   profileAbsence,
  //   "lateness_duration.lateness_colour",
  //   ""
  // );
  // const holidaysColor = get(
  //   profileAbsence,
  //   "holidays_duration.holidays_colour",
  //   ""
  // );
  // const absenceColor = get(
  //   profileAbsence,
  //   "absence_duration.absence_colour",
  //   ""
  // );
  // const timeOffColor = get(
  //   profileAbsence,
  //   "timeoff_duration.timeoff_colour",
  //   ""
  // );

  // Holidays Variables
  const holidaysDays = get(
    profileAbsence,
    "holidays_duration.holidays_days",
    ""
  );
  const allowedDayds = get(
    profileAbsence,
    "total_allowed_leaves.total_allowed_days",
    ""
  );
  const holidaysHours = get(
    profileAbsence,
    "holidays_duration.holidays_hours",
    ""
  );
  const holidaysMins = get(
    profileAbsence,
    "holidays_duration.holidays_mins",
    ""
  );
  const totalAllowedHours = get(
    profileAbsence,
    "total_allowed_leaves.total_allowed_hours",
    ""
  );
  const totalAllowedMins = get(
    profileAbsence,
    "total_allowed_leaves.total_allowed_mins",
    ""
  );
  const totalDeclinedLeaves = get(profileAbsence, "declined_leaves", []);
  //Occurrence Variables
  const absenceOccurance = get(
    profileAbsence,
    "absence_duration.absence_occurrence",
    ""
  );
  const latnessOcurrance = get(
    profileAbsence,
    "lateness_duration.lateness_occurrence",
    ""
  );
  const otherOccurance = get(
    profileAbsence,
    "other_duration.other_occurrence",
    ""
  );

  const timeOffDays = get(profileAbsence, "timeoff_duration.timeoff_days", "");
  const timeoffHours = get(
    profileAbsence,
    "timeoff_duration.timeoff_hours",
    ""
  );
  const timeoffMins = get(profileAbsence, "timeoff_duration.timeoff_mins", "");
  const overTimeHours = get(
    profileAbsence,
    "overtime_duration.overtime_hours",
    ""
  );
  const overTimeMins = get(
    profileAbsence,
    "overtime_duration.overtime_mins",
    ""
  );

  const cancelFutureLeave = get(profileAbsence, "cancel_future_leave", "");

  const userType = get(profileAbsence, "user_type", "");
  const userId = get(user, "user_id", "");

  useEffect(() => {
    if (yearFilter) {
      getAbsenceData();
    }
  }, [yearFilter, currentUserId]);

  useEffect(() => {
    if (profileAbsence) {
      setCurrentType(userType);

      // setAbsencesColor({
      //   ...absencesColor,
      //   latnessColor: latnessColor,
      //   annualLeaveColor: holidaysColor,
      //   sicknessColor: absenceColor,
      //   toilColor: timeOffColor,
      // });
    }
  }, [profileAbsence, userType]);

  useEffect(() => {
    setAbsenceData();
  }, [profileAbsence, currentValue]);

  const getAbsenceData = () => {
    var datesOpt = yearFilter.split(" ");
    let filterDates = {
      start_date: null,
      end_date: null,
    };

    filterDates.start_date = moment(
      datesOpt[0],
      AppConstants.defaultDateFormat
    ).format(AppConstants.defaultDateFormatDashed);

    filterDates.end_date = moment(
      datesOpt[2],
      AppConstants.defaultDateFormat
    ).format(AppConstants.defaultDateFormatDashed);

    dispatch(
      getUserAbsence({
        bearer_token: token,
        user_id: currentUserId,
        Data: filterDates,
      })
    )
      .then(unwrapResult)
      .then((response) => {
        Applogger("Response at getAbsenceData", response);
      })
      .catch((err) => {
        Applogger("Error at getUserAbsence", err.message);
      });
  };
  const handleDelete = (id, type) => {
    setCancelAbsenceId(id);
    setCancelAbsenecType(type);
  };
  const handleEdit = (id) => {
    // navigate(AppConstants.routes.absence, {
    // state: { absence_id: id, page: "profile" },
    // });
    navigate(`${AppConstants.routes.absence}?absence_id=${id}&page=profile`);
  };

  const deleteAbsence = () => {
    if (cancelAbsenceId != 0) {
      dispatch(
        deletetUserAbsence({
          annual_leave_id: cancelAbsenceId,
          bearer_token: token,
          lng,
        })
      )
        .then(unwrapResult)
        .then((res) => {
          getAbsenceData();
          showSuccessToast("Absence Removed succesfully");
        })
        .catch((err) => {
          Applogger("Error at deletetUserAbsence", err.message);
          showFaliureToast("Something Went Wrong");
        });
    } else {
      showFaliureToast("Please Select Absence to Delete");
    }
  };

  const setAbsenceData = () => {
    const { leaves } = profileAbsence;
    if (leaves) {
      if (leaves.length > 0) {
        let currentData = [];
        let historyData = [];
        let sicknessData = [];
        let toilData = [];
        let annualLeaveData = [];
        let otherData = [];

        if (currentValue == absencesTypes.allAbsences) {
          for (let i = 0; i < leaves.length; i++) {
            const element = leaves[i];
            if (element.absence_type == Sickness) {
              sicknessData.push(element);
            } else if (element.absence_type == annual_leave) {
              annualLeaveData.push(element);
            } else if (element.absence_type == Latness) {
              otherData.push(element);
            } else if (element.absence_type == Toil) {
              toilData.push(element);
            }
            if (element.time_history == "history") {
              historyData.push(element);
            } else if (element.time_history == "current") {
              currentData.push(element);
            }
          }
        } else if (currentValue == absencesTypes.annualLeave) {
          for (let i = 0; i < leaves.length; i++) {
            const element = leaves[i];
            if (element.absence_type == annual_leave) {
              annualLeaveData.push(element);
              if (element.time_history == "history") {
                historyData.push(element);
              } else if (element.time_history == "current") {
                currentData.push(element);
              }
            }
          }
        } else if (currentValue == absencesTypes.sickness) {
          for (let i = 0; i < leaves.length; i++) {
            const element = leaves[i];
            if (element.absence_type == Sickness) {
              sicknessData.push(element);
              if (element.time_history == "history") {
                historyData.push(element);
              } else if (element.time_history == "current") {
                currentData.push(element);
              }
            }
          }
        } else if (currentValue == absencesTypes.lateness) {
          for (let i = 0; i < leaves.length; i++) {
            const element = leaves[i];
            if (element.absence_type == Latness) {
              otherData.push(element);
              if (element.time_history == "history") {
                historyData.push(element);
              } else if (element.time_history == "current") {
                currentData.push(element);
              }
            }
          }
        } else if (currentValue == absencesTypes.toil) {
          for (let i = 0; i < leaves.length; i++) {
            const element = leaves[i];
            if (element.absence_type == Toil) {
              toilData.push(element);
              if (element.time_history == "history") {
                historyData.push(element);
              } else if (element.time_history == "current") {
                currentData.push(element);
              }
            }
          }
        }

        const data = historyData.sort(function (a, b) {
          return b.start_date - a.start_date;
        });
        const datacurr = currentData.sort(function (a, b) {
          return b.start_date - a.start_date;
        });

        setCurrent(datacurr);
        setHistory(data);
        setSicknessDataState(sicknessData);
        setAnnualLeaveDataState(annualLeaveData);
        setToilDataState(toilData);
        setOtherDataState(otherData);
      } else {
        setCurrent([]);
        setHistory([]);
        setSicknessDataState([]);
        setAnnualLeaveDataState([]);
        setOtherDataState([]);
        setToilDataState([]);
      }
    }
  };

  return (
    <div className="animate__animated animate__fadeInDown">
      <div className="d-flex justify-content-between flex-wrap">
        <form className="mt-2 d-flex flex-col">
          <label className="fs-6 fw-bold">{t("filterAbsences")}</label>
          <select
            onChange={(e) => setCurrentValue(e.target.value)}
            className="filter-form"
          >
            <option
              value={absencesTypes.allAbsences}
              className="fw-bold"
              selected
            >
              {t("allAbsences")}
            </option>
            <option value={absencesTypes.annualLeave} className="fw-bold">
              {t("annualLeave")}
            </option>
            <option value={absencesTypes.sickness} className="fw-bold">
              {t("sickness")}
            </option>
            <option value={absencesTypes.lateness} className="fw-bold">
              {t("lateness")}
            </option>
            <option value={absencesTypes.toil} className="fw-bold">
              {t("toil")}
            </option>
          </select>
        </form>
        <form className="mt-2 d-flex flex-col">
          <label className="fs-6 fw-bold">{t("leaveYear")}</label>
          <LeaveYearDropDownOpts
            yearFilter={yearFilter}
            onChange={(val) => setYearFilter(val)}
            currentYear={currentYear}
          />
        </form>
      </div>

      {currentValue == absencesTypes.allAbsences && (
        <div>
          <h3 className="all_absences">{t("allAbsences")}</h3>
          <div className="row justify-content-center">
            <div className="col-md-3 col-lg-2 col-sm-12 profile_absences text-center">
              <h6 className="fw-bold">{t("annualLeave")}</h6>
              <div className="d-flex justify-content-center">
                {currentType == "days" ? (
                  <div className="me-4">
                    <h4 className="fw-bold">
                      {holidaysDays}/ {allowedDayds}
                    </h4>
                    <p className="fw-light">{t("days")}</p>
                  </div>
                ) : (
                  <div className="me-4">
                    <h5 className="fw-bold">
                      {holidaysHours} {t("hrs")} {holidaysMins} {t("min")}/{" "}
                      {totalAllowedHours} {t("hrs")} {totalAllowedMins}{" "}
                      {t("min")}
                    </h5>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-3 col-lg-2 col-sm-12 profile_absences text-center border-start border-end">
              <h6 className="fw-bold">{t("sickness")}</h6>
              {absenceOccurance && (
                <h4 className="fw-bold">{absenceOccurance}</h4>
              )}
              <p className="fw-light">{t("occurrence")}</p>
            </div>
            <div className="col-md-3 col-lg-2 col-sm-12 profile_absences text-center">
              <h6 className="fw-bold">{t("lateness")}</h6>
              {latnessOcurrance && (
                <h4 className="fw-bold">{latnessOcurrance}</h4>
              )}
              <p className="fw-light">{t("occurrence")}</p>
            </div>
            <div className="col-md-3 col-lg-2 col-sm-12 profile_absences text-center border-start border-end">
              <h6 className="fw-bold">{t("other")}</h6>
              {otherOccurance && <h4 className="fw-bold">{otherOccurance}</h4>}
              <p className="fw-light">{t("occurrence")}</p>
            </div>
            <div className="col-md-3 col-lg-2 col-sm-12 profile_absences text-center">
              <h6 className="fw-bold">{t("toil")}</h6>

              <div className="me-4">
                <h5 className="fw-bold">
                  {timeoffHours} {t("hrs")} {timeoffMins} {t("min")}/
                  {overTimeHours} {t("hrs")} {overTimeMins} {t("min")}
                </h5>
              </div>

              <p className="fw-light">{t("occurrence")}</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="d-flex justify-content-center mt-5">
              {createOvertimeLog && (
                <a
                  onClick={() =>
                    // navigate(`${AppConstants.routes.absence}?user=${userId}`, {
                    //   state: { page: "profile" },
                    // })
                    navigate(
                      `${AppConstants.routes.absence}?user=${userId}&page=profile`
                    )
                  }
                  className="btn btn-primary-outline-cs"
                >
                  {role == AppConstants.roleTypes.Admin ? t("add") : "Request"}
                </a>
              )}
            </div>
          </div>
        </div>
      )}

      {currentValue == absencesTypes.annualLeave && (
        <Absenceinfocard
          type={currentType}
          title={t("annualLeave")}
          btn_val={t("addTimeOff")}
          getTitle_1={"Logged"}
          gettime_1={annualLeaveDataState.length}
          getformat_1={"occurrence"}
          getTitle_2={"Total"}
          gettime_2={currentType == "days" ? holidaysDays : holidaysHours}
          getformat_2={currentType == "days" ? "days" : "hours"}
          gettime_2_2={currentType == "days" ? "" : holidaysMins}
          getformat_2_2={currentType == "days" ? "" : "mins"}
          isAllowed={createOvertimeLog}
        />
      )}

      {currentValue == absencesTypes.sickness && (
        <Absenceinfocard
          type={currentType}
          title={t("sickness")}
          btn_val={"Add Sickness"}
          getTitle_1={"Logged"}
          getTitle_2={"Total"}
          gettime_1={sicknessDataState.length}
          gettime_2={currentType == "days" ? holidaysDays : holidaysHours}
          getformat_2={currentType == "days" ? "days" : "hours"}
          gettime_2_2={currentType == "days" ? "" : holidaysMins}
          getformat_2_2={currentType == "days" ? "" : "mins"}
          isAllowed={createOvertimeLog}
        />
      )}

      {currentValue == absencesTypes.lateness && (
        <Absenceinfocard
          title={t("lateness")}
          type={currentType}
          btn_val={"Request Lateness"}
          getTitle_1={"Logged"}
          gettime_1={otherDataState.length}
          getformat_1={"occurrence"}
          getTitle_2={"Total"}
          gettime_2={currentType == "days" ? holidaysDays : holidaysHours}
          getformat_2={currentType == "days" ? "days" : "hours"}
          gettime_2_2={currentType == "days" ? "" : holidaysMins}
          getformat_2_2={currentType == "days" ? "" : "mins"}
          isAllowed={createOvertimeLog}
        />
      )}

      {currentValue == absencesTypes.toil && (
        <Absenceinfocard
          title={t("toil")}
          btn_val={"Request Toil"}
          getTitle_1={"Logged"}
          gettime_1={toilDataState.length}
          getformat_1={"occurrence"}
          type={currentType}
          getTitle_2={"Total"}
          gettime_2={currentType == "days" ? timeOffDays : timeoffHours}
          getformat_2={currentType == "days" ? "days" : "hours"}
          gettime_2_2={currentType == "days" ? "" : timeoffMins}
          getformat_2_2={currentType == "days" ? "" : "mins"}
          isAllowed={createOvertimeLog}
        />
      )}
      <br />

      <div className="accordion" id="accordionPanelsStayOpenExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button bg_acc collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#currentFuture"
              aria-expanded="true"
              aria-controls="currentFuture"
            >
              <h6 className="text-black my-2">
                {t("currentFuture")} {`(${currentAbsenceData.length})`}
              </h6>
            </button>
          </h2>
          <div
            id="currentFuture"
            className="accordion-collapse border-0 collapse show "
            aria-labelledby="headingOne"
          >
            <div className="accordion-body p-0">
              {currentAbsenceData.length > 0 &&
                currentAbsenceData.map((val, index) => {
                  return (
                    <AbsenceCard
                      key={index}
                      cardData={val}
                      enableDeleteProfileAbsence={absenceRecordList}
                      enableDelete={cancelFutureLeave}
                      handle_delete={handleDelete}
                      handle_edit={handleEdit}
                    />
                  );
                })}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button bg_acc collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#absenceHistory"
              aria-expanded="true"
              aria-controls="absenceHistory"
            >
              <h6 className="text-black my-2">
                {t("absenceHistory")} {`(${absenceHistory.length})`}
              </h6>
            </button>
          </h2>
          <div
            id="absenceHistory"
            className="accordion-collapse border-0 collapse"
            aria-labelledby="headingOne"
          >
            <div className="accordion-body p-0">
              {absenceHistory.map((val, index) => {
                return (
                  <AbsenceCard
                    key={index}
                    cardData={val}
                    handle_delete={handleDelete}
                    handle_edit={handleEdit}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button bg_acc collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#declinedAbsence"
              aria-expanded="true"
              aria-controls="declinedAbsence"
            >
              <h6 className="text-black my-2">
                {t("DeclinedAbsence")} {`(${totalDeclinedLeaves.length})`}
              </h6>
            </button>
          </h2>
          <div
            id="declinedAbsence"
            className="accordion-collapse border-0 collapse show "
            aria-labelledby="headingOne"
          >
            <div className="accordion-body p-0">
              {totalDeclinedLeaves.length > 0 &&
                totalDeclinedLeaves.map((val, index) => {
                  return (
                    <AbsenceCard
                      key={index}
                      cardData={val}
                      disabled
                      enableDelete={cancelFutureLeave}
                      handle_delete={handleDelete}
                      handle_edit={handleEdit}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      <AbsenceWarnModal
        removeAbsence={deleteAbsence}
        heading={cancelAbsenceType}
      />
    </div>
  );
}

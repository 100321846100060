import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function HeaderButton({ className, route, text, icon }) {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  return (
    <li className={className}>
      <button
        id="main-nav"
        className="main-nav"
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => navigate(route)}
      >
        <lord-icon
          colors={`primary:${hover ? "#4FD4FB" : "#1A5B9D"}`}
          src={`https://cdn.lordicon.com/${icon}`}
          trigger={hover ? "loop" : ""}
          style={{ width: 25, height: 25 }}
        />
        &nbsp;
        {text}
      </button>
    </li>
  );
}

import React, { useEffect, useState } from "react";
import { Steps } from "antd";
import { useTranslation } from "react-i18next";
import { Applogger } from "../../Helpers/Logger";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { get } from "lodash";
import { useNavigate } from "react-router-dom";
import {
  showFaliureToast,
  showInfoToast,
  showSuccessToast,
} from "../../Utilities";
import {
  getAllRolesRequest,
  getCompanySettingsOB,
  getPlacesOfWork,
  getRolesSettingRequest,
  updateComapanySettingsOB,
} from "../../Redux/reducers/SettingsReducer";
import LocationType from "./LocationType";
import Button from "./Button";
import Roles from "../../components/RoleAndPrevilegas/Roles";
import EditEmployees from "./EditEmployees";
import CompanyDetails from "./CompanyDetails";
import AddOnboardingEmployee from "./AddOnboardingEmployee";
import "./styles.css";
import AppConstants from "../../Helpers/AppConstants";
import InstructionPara from "./InstructionPara";
import moment from "moment";
import {
  addMonthsInCurrentDate,
  getCurrentDate,
} from "../../Helpers/commonFunc";

export default function OnBoardingStepper() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { token, user } = useSelector((state) => state.AuthenticationReducer);
  const { lng } = useSelector((state) => state.languageReducer);
  const { companySettingsOB, placesOfWork, rolesSettings, roles } = useSelector(
    (state) => state.settings
  );

  const lastStep = 4;
  const dateFormat = AppConstants.defaultDateFormat;
  const fiscalStartDate = get(companySettingsOB, "fiscal_start_date", 0);
  const fiscalStartMonth = get(companySettingsOB, "fiscal_start_month", 0);
  const fiscalEndDate = get(companySettingsOB, "fiscal_end_date", 0);
  const fiscalEndMonth = get(companySettingsOB, "fiscal_end_month", 0);

  const [currentStep, setCurrentStep] = useState(0);
  const [finishPressed, setFinishPressed] = useState(false);
  const [companySettingsObj, setCompanySettingsObj] = useState({
    company_title: "",
    hq_address: "",
    latitude: "",
    longitude: "",
    description: "",
    company_logo: { file: null, url: "" },
    address_line1: "",
    town_city: "",
    address_line2: "",
    post_code: "",
    country_id: "",
    countryName: "",
    fiscal_start_date: fiscalStartDate
      ? fiscalStartDate
      : moment(getCurrentDate(), dateFormat).date(),
    fiscal_start_month: fiscalStartMonth
      ? fiscalStartMonth
      : moment(getCurrentDate(), dateFormat).month() + 1,
    fiscal_end_date: fiscalEndDate
      ? fiscalEndDate
      : moment(addMonthsInCurrentDate(12), dateFormat).date(),
    fiscal_end_month: fiscalEndMonth
      ? fiscalEndMonth
      : moment(addMonthsInCurrentDate(12), dateFormat).month() + 1,
  });

  const companyTitle = get(companySettingsOB, "company_title", "");
  const companyDescription = get(companySettingsOB, "description", "");
  const companyAddress = get(companySettingsOB, "hq_address", "");
  const companyUrl = get(companySettingsOB, "company_logo", "");
  const companyId = get(user, "company_id", "");

  useEffect(() => {
    if (isOnBoarded()) {
      showInfoToast(
        "Your company onboarding is already completed, The changes can be made from settings"
      );
      navigate(AppConstants.routes.dashboard);
    } else {
      getRolesSettingRequestHandler();
      getAllRolesRequestHandler();
      callGetWorkPlacesAPI();
    }
  }, []);

  const isOnBoarded = () => {
    return get(companySettingsOB, "is_onboarded", 0) == 1;
  };

  const companySettingDisableHandler = () => {
    const {
      company_title,
      hq_address,
      company_logo,
      fiscal_start_date,
      fiscal_end_date,
    } = companySettingsObj;
    const { url } = company_logo;
    return (
      !company_title ||
      !hq_address ||
      !url ||
      !fiscal_start_date ||
      !fiscal_end_date
    );
  };

  const companyLocationDisableHandler = () => {
    return !placesOfWork.length > 0;
  };

  const onBoardingDisableHandler = () => {
    if (currentStep == 0) {
      return companySettingDisableHandler();
    } else if (currentStep == 1) {
      return companyLocationDisableHandler();
    } else {
      return false;
    }
  };

  const handleFinish = () => {
    setFinishPressed(true);
    Applogger("Called on Finish at stepper");
  };

  const handleGetCompanySettings = (onBoarded = false) => {
    dispatch(getCompanySettingsOB({ token, company_id: companyId }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Company settings received successfully", res);
      })
      .catch((err) => {
        showFaliureToast("Unable to get comapny settings");
        Applogger("Error at updateComapanySettings", err.message);
      });
  };

  const handleUpdateComapanySettingsOB = () => {
    if (!companySettingsObj.latitude || !companySettingsObj.longitude) {
      showFaliureToast("Please select valid address");
      return;
    }
    const body = companySettingsObj;
    dispatch(updateComapanySettingsOB({ token, body, company_id: companyId }))
      .then(unwrapResult)
      .then((res) => {
        showSuccessToast(res ?? "Company settings updated successfully");
        Applogger("Company settings updated successfully", res);
        handleGetCompanySettings();
        callGetWorkPlacesAPI();
        getRolesSettingRequestHandler();
        getAllRolesRequestHandler();
        setCurrentStep(currentStep + 1);
      })
      .catch((err) => {
        showFaliureToast("Unable to update comapny settings");
        Applogger("Error at updateComapanySettings", err);
      });
  };

  const companySettingHandler = () => {
    const {
      company_logo,
      company_title,
      hq_address,
      description,
      fiscal_start_date,
      fiscal_start_month,
      fiscal_end_date,
      fiscal_end_month,
    } = companySettingsObj;
    if (
      companyTitle == company_title &&
      companyAddress == hq_address &&
      companyDescription == description &&
      company_logo.url == companyUrl &&
      fiscalStartDate == fiscal_start_date &&
      fiscalStartMonth == fiscal_start_month &&
      fiscalEndDate == fiscal_end_date &&
      fiscalEndMonth == fiscal_end_month
    ) {
      return false;
    } else {
      return true;
    }
  };

  const stepperIncreaseHandler = () => {
    if (currentStep == 0) {
      if (companySettingHandler()) {
        handleUpdateComapanySettingsOB();
      } else {
        setCurrentStep(currentStep + 1);
      }
    } else if (currentStep == 2) {
      if (!isValidRoleSettings()) {
        showFaliureToast(
          "Please create role settings for all roles or delete unused roles to continue"
        );
      } else {
        setCurrentStep(currentStep + 1);
      }
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const isValidRoleSettings = () => {
    const unusedRoles = roles.filter(
      (role) =>
        !rolesSettings.some(
          (roleSettings) =>
            get(role, "role_id", null) == get(roleSettings, "role_id", "")
        )
    );
    return !unusedRoles.length > 0;
  };

  const handleSkipPress = () => {
    if (currentStep == 2) {
      if (!isValidRoleSettings()) {
        showFaliureToast(
          "Please create role settings for all roles or delete unused roles"
        );
      } else {
        setCurrentStep(currentStep + 1);
      }
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const callGetWorkPlacesAPI = () => {
    dispatch(getPlacesOfWork({ token, lng, companyId }))
      .then(unwrapResult)
      .then((response) => {
        Applogger("Response at getPlacesOfWork", response);
      })
      .catch((err) => {
        Applogger("Error at getPlacesOfWork", err.message);
      });
  };

  const getRolesSettingRequestHandler = () => {
    dispatch(getRolesSettingRequest({ token, companyId }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getRolesSettingRequest", res);
      })
      .catch((err) => {
        Applogger("Error at getRolesSettingRequest", err);
      });
  };

  const getAllRolesRequestHandler = () => {
    dispatch(getAllRolesRequest({ token, companyId }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getAllRolesRequest", res);
      })
      .catch((err) => {
        Applogger("Error at getAllRolesRequest", err);
      });
  };

  const stepItems = [
    {
      title: t("companyDetails"), // Step 0
      content: (
        <CompanyDetails
          isOnboarding={true}
          companySettingsObj={companySettingsObj}
          setCompanySettingsObj={setCompanySettingsObj}
          currentStep={currentStep}
        />
      ),
      instructions: t("tellUsALittleBitAboutYourOrganisation"),
    },
    {
      title: t("location"), // Step 1
      content: <LocationType isOnboarding={true} />,
      instructions: t("excludingClientSitesTellUsAboutAnyLocations"),
    },
    {
      title: t("roleSettings"), // Step 2
      content: <Roles isOnboarding={true} />,
      instructions: t("tellUsAboutTheRoles"),
    },
    {
      title: t("bulkUploadEmployees"), // Step 3
      content: <AddOnboardingEmployee isOnboarding={true} />,
      instructions: t("addPeopleFromYourOrganisation"),
    },
    {
      title: t("editEmployees"), // Step 4
      content: (
        <EditEmployees
          isOnboarding={true}
          finishPressed={finishPressed}
          setFinishPressed={setFinishPressed}
        />
      ),
      instructions: t("assignPeopleToRolesAndLocation"),
    },
  ];

  const onStepChange = (value) => {
    const { company_title, hq_address, company_logo } = companySettingsObj;
    const { url } = company_logo;
    if (currentStep == 0) {
      if (!company_title || !hq_address || !url) {
        showFaliureToast(
          "Please provide company title, address & logo to continue"
        );
        return;
      }
      if (companySettingHandler()) {
        handleUpdateComapanySettingsOB();
      } else {
        setCurrentStep(value);
      }
    } else if (value > 2) {
      if (!isValidRoleSettings()) {
        showFaliureToast(
          "Please create role settings for all roles or delete unused roles to continue"
        );
        return;
      } else {
        setCurrentStep(value);
      }
    } else {
      setCurrentStep(value);
    }
  };

  const allowSkip = () => {
    return currentStep > 0 && currentStep < lastStep;
  };

  return (
    <div>
      <div className="stepperContainer">
        <div style={{ width: "90%" }} id="onboardingTable">
          <Steps
            className="overflow-x-scroll cus-scroll"
            id={"steps"}
            current={currentStep}
            items={stepItems}
            onChange={onStepChange}
            size="small"
          />
          <div className="d-flex flex-column justify-between h-100 overflow-y-scroll cus-scroll pb-3">
            <div className="mt-2">{stepItems[currentStep].content}</div>
            <div className="d-flex justify-content-between  ">
              <InstructionPara title={stepItems[currentStep].instructions} />
              <div className="alignEnd mb-4  ">
                {currentStep > 0 && (
                  <Button
                    className="btn btn-primary-outline-cs"
                    onClick={() => {
                      setCurrentStep(currentStep - 1);
                    }}
                  >
                    {t("back")}
                  </Button>
                )}
                {allowSkip() && (
                  <Button
                    type={"primary"}
                    className="ml-2"
                    onClick={handleSkipPress}
                  >
                    {t("skip")}
                  </Button>
                )}
                {currentStep < 4 && (
                  <Button
                    disabled={onBoardingDisableHandler()}
                    type={"primary"}
                    className="ml-2"
                    onClick={stepperIncreaseHandler}
                  >
                    {t("next")}
                  </Button>
                )}
                {currentStep == 4 && (
                  <Button
                    disabled={onBoardingDisableHandler()}
                    type={"primary"}
                    className="ml-2"
                    onClick={() => {
                      handleFinish();
                    }}
                  >
                    {t("finish")}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

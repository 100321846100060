import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import useWorkingStatusList from "../../../../customHooks/useWorkingStatusList";

export default function Filters({
  employeesFilters,
  setEmployeesFilters,
  searchQuery,
  setSearchQuery,
}) {
  const { companySettingsOB, roles, placesOfWork } = useSelector(
    (state) => state.settings
  );
  const { t } = useTranslation();
  const workingStatusList = useWorkingStatusList();
  const employeeStatus = get(companySettingsOB, "employee_status", "");

  return (
    <div className="py-2">
      <div className="main-filters animate__animated animate__fadeInDownBig d-flex gap-4 flex-wrap-sm">
        <div className="filter-form-group col-sm-3 col-md-3 col-12">
          <label>{t("find")}</label>
          <input
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            placeholder={t("namePlaceholder")}
            type={"search"}
            className="filter-form"
          />
        </div>
        <div className="filter-form-group col-sm-2 col-md-2 col-12">
          <label>{t("sortBy")}</label>
          <select
            value={employeesFilters.name}
            defaultValue={employeesFilters.name}
            onChange={(e) => {
              setEmployeesFilters({
                ...employeesFilters,
                name: e.target.value,
              });
            }}
            className="filter-form"
          >
            <option value={"any"}>{t("noStatusSet")}</option>
            <option value={"FA-Z"}>{t("firstName")} (A-Z)</option>
            <option value={"FZ-A"}>{t("firstName")} (Z-A)</option>
            <option value={"LA-Z"}>{t("lastName")} (A-Z)</option>
            <option value={"LZ-A"}>{t("lastName")} (Z-A)</option>
          </select>
        </div>
        {employeeStatus == 1 && (
          <div className="filter-form-group col-sm-2 col-md-2 col-12">
            <label>{t("status")}</label>
            <select
              value={employeesFilters.workingStatus}
              defaultValue={employeesFilters.workingStatus}
              onChange={(e) => {
                setEmployeesFilters({
                  ...employeesFilters,
                  workingStatus: e.target.value,
                });
              }}
              className="filter-form"
            >
              {workingStatusList.map((workingStatus, index) => {
                const { title, slug } = workingStatus;
                return (
                  <option value={slug} key={index}>
                    {title}
                  </option>
                );
              })}
            </select>
          </div>
        )}
        <div className="filter-form-group col-sm-2 col-md-2 col-12">
          <label>{t("role")}</label>
          <select
            value={employeesFilters.role}
            defaultValue={employeesFilters.role}
            onChange={(e) => {
              setEmployeesFilters({
                ...employeesFilters,
                role: e.target.value,
              });
            }}
            className="filter-form"
          >
            <option value={"any"}>{t("noStatusSet")}</option>
            {roles.map((role, index) => {
              return (
                <option value={get(role, "role_id", "")} key={index}>
                  {get(role, "name", "")}
                </option>
              );
            })}
          </select>
        </div>
        <div className="filter-form-group col-sm-2 col-md-2 col-12">
          <label>{t("location")}</label>
          <select
            value={employeesFilters.location}
            defaultValue={employeesFilters.location}
            onChange={(e) => {
              setEmployeesFilters({
                ...employeesFilters,
                location: e.target.value,
              });
            }}
            className="filter-form"
          >
            <option value={"any"}>{t("noStatusSet")}</option>
            {placesOfWork.map((place, index) => {
              return (
                <option value={get(place, "work_place_id", "")} key={index}>
                  {get(place, "name", "")}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </div>
  );
}

export const formBodyTypes = {
  hoursEmployeeBody: {
    first_name: "",
    last_name: "",
    email: "",
    start_date: formatDate(),
    user_type: null,
    role: 2, // Default id for hours
    company_working_week_hours: 0,
    company_working_week_minutes: 0,
    country_id: null,
    annual_leave_entitlement_start_date: formatDate(),
    company_full_time_annual_leaves_days_hours: 0,
    annual_leaves_entitlement_days_hours: 0,
    annual_leaves_allowance_days_hours: 0,

    // Addittional Keys N/A In Days
    contract_week_hours: 0,
    contract_week_minutes: 0,
    average_working_day_hours: 0,
    average_working_day_minutes: 0,
    company_full_time_annual_leaves_mins: 0,
    annual_leaves_entitlement_mins: 0,
    annual_leaves_allowance_mins: 0,
  },
  daysEmployeeBody: {
    first_name: "",
    last_name: "",
    email: "",
    start_date: formatDate(),
    user_type: null,
    role: 1, // Default id for days
    company_working_week_hours: 0,
    company_working_week_minutes: 0,
    country_id: null,
    annual_leave_entitlement_start_date: formatDate(),
    company_full_time_annual_leaves_days_hours: 0,
    annual_leaves_entitlement_days_hours: 0,
    annual_leaves_allowance_days_hours: 0,

    // Addittional Keys N/A In Hours
    working_time_pattern_id: "3", // Important Require Design
  },
};

export const employeeTypes = {
  hours: "hours",
  days: "days",
};

export const re_number = /^[0-9\b]*$/;
export const emailRegex = /^[^\s]+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export function formatDate() {
  var d = new Date(),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

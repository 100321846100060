import React, { useEffect, useState } from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse, Col, Row } from "antd";
import { getEmployeeName } from "../../Utilities";
import { useTranslation } from "react-i18next";
import { Applogger } from "../../Helpers/Logger";
import { CheckBox } from "@mui/icons-material";
import { get } from "lodash";

export default function AssignEmployeeMandatory({
  employees,
  employeeSelect,
  rolesWithEmps = [],
}) {
  const { Panel } = Collapse;
  const { t } = useTranslation();
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [assignableEmployees, setAssignableEmployees] = useState([]);
  const [rolesWithEmployees, setRolesWithEmployees] = useState([]);
  const [inputText, setInputText] = useState("");

  useEffect(() => {
    if (employees) {
      setAssignableEmployees(employees);
    }
  }, [employees]);

  useEffect(() => {
    if (rolesWithEmps) {
      setRolesWithEmployees(rolesWithEmps);
    }
  }, [rolesWithEmps]);

  const onSelectedEmployeeClick = (employee) => {
    var filteredArray = [];
    if (selectedEmployees.includes(employee)) {
      filteredArray = selectedEmployees.filter(
        (data) => data.user_id != employee.user_id
      );
      setSelectedEmployees(filteredArray);
      employeeSelect(filteredArray);
    }
  };

  // useEffect(() => {
  //   // Applogger(assignableEmployees);
  // }, [assignableEmployees]);

  const isEmployeeSelected = (employee) => {
    return selectedEmployees.includes(employee);
  };

  const onAssignableAllClickHandler = (status) => {
    if (status == "all") {
      setSelectedEmployees(assignableEmployees);
    } else {
      setSelectedEmployees([]);
    }
  };

  const onAssignableEmployeeClick = (employee) => {
    var filteredArray = [];
    if (selectedEmployees.includes(employee)) {
      filteredArray = selectedEmployees.filter(
        (data) => data.user_id != employee.user_id
      );
      setSelectedEmployees(filteredArray);
      employeeSelect(filteredArray);
    } else {
      addEmployeeToSelectedEmps(employee);
    }
  };

  const addEmployeeToSelectedEmps = (employee) => {
    setSelectedEmployees((selectedEmployees) => [
      ...selectedEmployees,
      employee,
    ]);
    var employees = [...selectedEmployees];
    employees.push(employee);
    employeeSelect(employees);
  };

  const handleAssignAllAccToRoles = (empArray = [], isAllAssigned = false) => {
    var selectedEmps = [];

    empArray.forEach((item) => {
      if (isAllAssigned) {
        if (selectedEmployees.includes(item)) {
          selectedEmps.push(item);
        }
      } else {
        if (
          !selectedEmployees.find((selItem) => selItem.user_id == item.user_id)
        ) {
          addEmployeeToSelectedEmps(item);
        }
      }
    });

    if (selectedEmps.length > 0) {
      var filteredArray = selectedEmployees.filter(
        (data) => !selectedEmps.some((selEmp) => selEmp.user_id == data.user_id)
      );
      setSelectedEmployees(filteredArray);
      employeeSelect(filteredArray);
    }
  };

  const isAllSelectedAccToRole = (roleEmployees = []) => {
    var currentSelectedEmps = selectedEmployees.filter((item) =>
      roleEmployees.some((rolEmp) => rolEmp.user_id == item.user_id)
    );
    return currentSelectedEmps.length == roleEmployees.length;
  };

  const onUserFilterHandler = (event) => {
    setInputText(event.target.value);
    var filteredArray = [];
    const allEmployeeArray = [...employees];
    for (let i = 0; i < selectedEmployees.length; i++) {
      const element = selectedEmployees[i];
      const currentEmployeeIndex = allEmployeeArray.findIndex(
        (employee) => employee.user_id === element.user_id
      );
      if (currentEmployeeIndex !== -1) {
        allEmployeeArray.splice(currentEmployeeIndex, 1);
      }
    }

    const filteredUsers = allEmployeeArray.filter(
      (item) =>
        item.first_name.toLocaleLowerCase().includes(event.target.value) ||
        item.last_name.toLocaleLowerCase().includes(event.target.value) ||
        item.email.toLocaleLowerCase().includes(event.target.value)
    );

    setAssignableEmployees(filteredUsers);
  };

  const handleSearchUser = (event) => {
    setInputText(event.target.value);
    var finalArray = [];
    var allEmployeeArray = [...rolesWithEmps];

    if (event.target.value) {
      allEmployeeArray.forEach((item) => {
        var employeesArray = [...item.employees];
        var filteredUsers = [];
        if (employeesArray) {
          for (let i = 0; i < selectedEmployees.length; i++) {
            const element = selectedEmployees[i];

            const currentEmployeeIndex = employeesArray.findIndex(
              (employee) => employee.user_id == element.user_id
            );
            if (currentEmployeeIndex !== -1) {
              employeesArray.splice(currentEmployeeIndex, 1);
            }
          }

          filteredUsers = employeesArray.filter(
            (item) =>
              item.first_name
                .toLocaleLowerCase()
                .includes(event.target.value) ||
              item.last_name.toLocaleLowerCase().includes(event.target.value) ||
              item.email.toLocaleLowerCase().includes(event.target.value)
          );
        }
        finalArray.push({
          ...item,
          employees: filteredUsers,
        });
      });

      // console.log("search finalArrayyyy  ======== ", finalArray);
      setRolesWithEmployees(finalArray);
    } else {
      setRolesWithEmployees(rolesWithEmps);
    }
  };

  return (
    <React.Fragment>
      <div
        className="site-card-wrapper d-flex align-items-end"
        style={{
          alignContent: "flex-end",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <button
          onClick={() =>
            onAssignableAllClickHandler(
              selectedEmployees.length == assignableEmployees.length
                ? ""
                : "all"
            )
          }
          className="btn btn-primary-outline-cs"
        >
          {selectedEmployees.length == assignableEmployees.length
            ? "Unassign All"
            : "Assign All"}
        </button>
      </div>
      <Collapse
        bordered={false}
        defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        className="site-collapse-custom-collapse"
      >
        <Panel
          header={`Selected (${selectedEmployees.length})`}
          key="1"
          className="site-collapse-custom-panel"
        >
          <div className="site-card-wrapper">
            <Row gutter={16} className={"w-100"}>
              {selectedEmployees.map((item, index) => {
                return (
                  <EmployeeCell
                    onClick={() => onSelectedEmployeeClick(item)}
                    isSelected={isEmployeeSelected(item)}
                    employee={item}
                    index={index}
                  />
                );
              })}
            </Row>
          </div>
        </Panel>
        <div className="row">
          <div className="col-5">
            <label>{t("searchEmployees")}</label>
            <input
              value={inputText}
              onChange={handleSearchUser}
              className="filter-form"
              placeholder={t("employeeName")}
            />
          </div>
        </div>
        <Panel
          header={`Assignable ${
            inputText
              ? ""
              : assignableEmployees.length - selectedEmployees.length
          }`}
          key="2"
          className="site-collapse-custom-panel"
        >
          <div className="site-card-wrapper">
            <Row gutter={16} className={"w-100"}>
              {rolesWithEmployees.map((roleItem, roleIndex) => {
                return (
                  get(roleItem, "employees", []).length > 0 && (
                    <>
                      <div className="mand-asgn-emp-cont">
                        <p style={{}}>{roleItem.name}</p>
                        <button
                          onClick={() => {
                            const allAssigned = isAllSelectedAccToRole(
                              roleItem.employees
                            );
                            handleAssignAllAccToRoles(
                              roleItem.employees,
                              allAssigned
                            );
                          }}
                          className="btn btn-primary-outline-cs"
                        >
                          {isAllSelectedAccToRole(roleItem.employees)
                            ? "Unassign All"
                            : "Assign All"}
                        </button>
                      </div>
                      {roleItem.employees.map((item, index) => {
                        return (
                          item.role_id == roleItem.role_id && (
                            <EmployeeCell
                              onClick={() => onAssignableEmployeeClick(item)}
                              isSelected={isEmployeeSelected(item)}
                              employee={item}
                              index={index}
                            />
                          )
                        );
                      })}
                    </>
                  )
                );
              })}
            </Row>
          </div>
        </Panel>
      </Collapse>
    </React.Fragment>
  );
}

const EmployeeCell = ({ onClick, isSelected, employee, index }) => {
  return (
    <Col span={12} key={index}>
      <div
        onClick={onClick}
        className={`text-start mb-2 p-2 cursor-pointer ${
          isSelected ? "sec-cs-primary" : "border-cs-grey"
        } rounded`}
      >
        <h6 className="fw-bold mt-2">{getEmployeeName(employee)}</h6>
      </div>
    </Col>
  );
};

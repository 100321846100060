import React from "react";
import { useTranslation } from "react-i18next";
import { formattedDate, preventMinus } from "../../Utilities/index";
import AppConstants from "../../Helpers/AppConstants";

export default function CreateShift({ currentShiftData, role, setShiftData }) {
  const { t } = useTranslation();
  return (
    <div className="modal-body modal-cs px-2">
      <div className="d-flex align-items-center justify-content-between ps-2">
        <div className="col-4">{t("shiftDay")}</div>
        <div className="col-8">
          <input
            disabled={role !== AppConstants.roleTypes.Admin}
            value={formattedDate(currentShiftData.shiftDay)}
            onChange={(e) =>
              setShiftData({ ...currentShiftData, shiftDay: e.target.value })
            }
            type={"date"}
            className="filter-form"
          />
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-between ps-2">
        <div className="col-4">{t("shiftTime")}</div>
        <div className="col-8">
          <div className="d-flex align-items-center flex-wrap-sm">
            <input
              disabled={role !== AppConstants.roleTypes.Admin}
              value={currentShiftData.shiftTimeStart}
              onChange={(e) =>
                setShiftData({
                  ...currentShiftData,
                  shiftTimeStart: e.target.value,
                })
              }
              type={"time"}
              className="filter-form"
            />
            <p className="mt-2 ml-1 mr-1 fw-bold">{t("to")}</p>
            <input
              disabled={role !== AppConstants.roleTypes.Admin}
              value={currentShiftData.shiftTimeEnd}
              onChange={(e) =>
                setShiftData({
                  ...currentShiftData,
                  shiftTimeEnd: e.target.value,
                })
              }
              type={"time"}
              className="filter-form"
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between ps-2">
        <div className="col-4">{t("breakDuration")}</div>
        <div className="col-8">
          <div className="d-flex">
            <input
              onKeyPress={preventMinus}
              disabled={role !== AppConstants.roleTypes.Admin}
              value={currentShiftData.breakDuration}
              onChange={(e) =>
                setShiftData({
                  ...currentShiftData,
                  breakDuration: e.target.value,
                })
              }
              placeholder={t("breakDuration")}
              className="filter-form"
              type={"number"}
            />
          </div>
        </div>
      </div>
      <div className="d-flex flex-column ps-2">
        <div className="col-4">
          <p className="mb-0">{t("notes")} </p>
        </div>
        <div>
          <textarea
            className="w-100 filter_textarea"
            disabled={role !== AppConstants.roleTypes.Admin}
            value={currentShiftData.notes}
            onChange={(e) =>
              setShiftData({ ...currentShiftData, notes: e.target.value })
            }
          />
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { get } from "lodash";
import { formattedDate, getEmployeeName } from "../../Utilities";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import AppConstants from "../../Helpers/AppConstants";

function UploadSummary({ summary, employeesList = [] }) {
  const { t } = useTranslation();
  const {
    user_not_created,
    bulk_users,
    error_for_not_created_user,
    successFE,
    errorFE,
  } = summary;

  const beautifiedString = (string) => {
    return (
      string.charAt(0).toUpperCase() +
      string.slice(1).replace(/[^a-zA-Z]/g, " ")
    );
  };

  const columns = [
    {
      title: "Full Name",
      dataIndex: "first_name",
      key: "first_name",
      width: "33%",
      render: (_, record) => {
        return <p className="mb-0">{getEmployeeName(record)}</p>;
      },
    },
    {
      title: t("email"),
      dataIndex: "email",
      key: "email",
      width: "33%",
      render: (_, record) => <p className="mb-0">{get(record, "email", "")}</p>,
    },
    {
      title: t("workStartDate"),
      dataIndex: "start_date",
      key: "start_date",
      width: "33%",
      render: (_, record) => {
        const date = get(record, "start_date", "");
        const finalDate =
          typeof date == "number"
            ? moment.unix(date).format(AppConstants.defaultDateFormat)
            : moment(formattedDate(date)).format(
                AppConstants.defaultDateFormat
              );
        return <p className="mb-0">{finalDate}</p>;
      },
    },
  ];

  const getFailedUserList = () => {
    let finalList = employeesList;
    // finalList = finalList.filter((user) =>
    //   user_not_created.some(
    //     (notCreateduser) => get(user, "row", "") == notCreateduser
    //   )
    // );

    finalList = finalList.map(({ work_start_date: start_date, ...rest }) => {
      return {
        start_date,
        ...rest,
      };
    });

    return finalList;
  };

  const getFailedUserDetails = (userRow) => {
    const failedUser = employeesList.find(
      (failedUser) => get(failedUser, "row", "") == userRow
    );

    return failedUser;
  };

  return (
    <div>
      {successFE && (
        <>
          <h5>Successfully created user</h5>
          <Table
            columns={columns}
            dataSource={bulk_users}
            pagination={{ pageSize: 5 }}
          />
        </>
      )}
      {user_not_created.length > 0 && (
        <>
          <h5>Failed to create users</h5>
          <Table
            columns={columns}
            dataSource={getFailedUserList()}
            pagination={{ pageSize: 5 }}
          />
        </>
      )}
      {!error_for_not_created_user.length > 0 &&
        errorFE.map((error) => {
          return (
            <>
              <h4>Found Errors</h4>
              <p>{error}</p>
            </>
          );
        })}
      {error_for_not_created_user.length > 0 && (
        <>
          <h4>Errors Summary</h4>
          {error_for_not_created_user.map((item) => {
            const row = get(item, "row", "");

            const failedUser = getFailedUserDetails(row);
            if (failedUser) {
              const fullName = getEmployeeName(failedUser);
              return (
                <>
                  <p className="mb-0">
                    {"Errors in creating "}
                    <strong>{fullName}</strong>
                    {" are as follows"}
                  </p>
                  {[get(item, "error", null)].map((childObj) => {
                    return Object.entries(childObj).map((item) => {
                      return (
                        <>
                          {/* <p className="mb-0 font-bold text-danger">
                          {beautifiedString(get(item, "[0]", "")) + ":"}
                        </p> */}
                          <ul
                            style={{ listStyleType: "circle" }}
                            className=" mb-0"
                          >
                            {get(item, "[1]", []).map((string) => {
                              return <li className="ml-5 mr-5">{string}</li>;
                            })}
                          </ul>
                        </>
                      );
                    });
                  })}
                  <hr className="my-2" />
                </>
              );
            } else {
              return null;
            }
          })}
        </>
      )}
    </div>
  );
}

export default UploadSummary;

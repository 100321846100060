import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Absence from "./Absence";
import AppConstants from "../../../Helpers/AppConstants";

export default function SummaryCard() {
  const { absenceSummary } = useSelector((state) => state.DashboardReducer);
  const { t } = useTranslation();
  const {
    total_leaves_left,
    total_allowed_leaves,
    next_leave,
    Sickness_Leave,
    Lateness,
    pending_leaves_duration,
  } = absenceSummary;

  // const currentDate = new Date();

  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    var timer = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div
      data-aos-duration="1500"
      data-aos="fade-left"
      className="col-sm-12 col-md col-12"
      id="SummaryCardDiv"
    >
      <div className="side-bar border-custom overflow-hidden">
        <div className="sidebar-head d-flex justify-content-between align-items-center w-100">
          <h3 className="mb-0 p-2">{t("summaryText")}</h3>
          <p className="text-black mb-0">
            {moment(currentDate).format(AppConstants.dateFormatWithTime)}
          </p>
        </div>
        <div
          className="tab-pane fade show active p-2"
          id="ex2-tabs-1"
          role="tabpanel"
          aria-labelledby="ex2-tab-1"
        >
          <Absence
            nextLeave={next_leave}
            sicknessLeave={Sickness_Leave}
            latness={Lateness}
            allowedDays={total_allowed_leaves}
            pendingLeaves={pending_leaves_duration}
            RemainingDays={total_leaves_left}
          />
        </div>
      </div>
    </div>
  );
}

import React from "react";
import AllFolders from "../../Documents/AllFolders";
import AppConstants from "../../../Helpers/AppConstants";

export default function UserDocuments() {
  return (
    <AllFolders
      document_type={AppConstants.documentTypes.personal}
      showDocuments={true}
    />
  );
}

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ThemeProvider } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { changeLanguage as changeLanguageRedux } from "../Redux/reducers/LanguageReducer";
import Home from "../pages/home/index";
import Terms from "../pages/TermsAndCondition/Terms";
import Login from "../pages/Login/Login";
import Forgot from "../pages/Login/forgot";
import Profile from "../pages/Profile/Profile";
import Reports from "../pages/Reports/Reports";
import Calendar from "../pages/Calendar/Calendar";
import Staffing from "../pages/Staffing/Staffing";
import Settings from "../pages/Settings/Settings";
import HomePage from "../pages/Homepage/Homepage";
import Documents from "../pages/Documents/Documents";
import Employees from "../pages/Employees/Employees";
import TimeSheet from "../pages/TimeSheet/TimeSheet";
import OnBoarding from "../pages/OnBoarding/OnBoarding";
import PageNotFound from "../pages/PageNotFound/PageNotFound";
import ResetPassword from "../pages/Login/ResetPassword";
import PrivacyPolicy from "../pages/PrivacyPolicy/Privacy";
import TimesheetHistory from "../pages/TimeSheet/TimesheetHistory";
import TableView from "../components/Staffing/TableView";
import AddTimeOff from "../components/Calendar/AddTimeOff";
import LogOverTime from "../components/Profile/userOverTime/LogOverTime";
import ReportDetails from "../components/Reports/ReportDetails";
import AppConstants from "../Helpers/AppConstants";
import SharedDocuments from "../pages/SharedDocuments/SharedDocuments";

export default function RouteFile() {
  const { i18n } = useTranslation();

  const dispatch = useDispatch();

  const { lng: newLanguage } = useSelector((state) => state.languageReducer);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const lang = searchParams.get("lang");
    if (lang) {
      i18n.changeLanguage(lang);
      dispatch(changeLanguageRedux(lang));
    }
  }, [newLanguage]);

  return (
    <ThemeProvider dir={i18n.dir()}>
      <Helmet
        htmlAttributes={{
          lang: newLanguage,
          dir: i18n.dir(),
        }}
      />
      <BrowserRouter>
        <Routes>
          <Route path={AppConstants.routes.main} index element={<Home />} />
          <Route path={AppConstants.routes.terms} element={<Terms />} />
          <Route path={AppConstants.routes.login} element={<Login />} />
          <Route
            path={AppConstants.routes.forgot_password}
            element={<Forgot />}
          />
          <Route path={AppConstants.routes.profile} element={<Profile />} />
          <Route path={AppConstants.routes.analytics} element={<Reports />} />
          <Route path={AppConstants.routes.calendar} element={<Calendar />} />
          <Route path={AppConstants.routes.staffing} element={<Staffing />} />
          <Route path={AppConstants.routes.settings} element={<Settings />} />
          <Route path={AppConstants.routes.dashboard} element={<HomePage />} />
          <Route path={AppConstants.routes.documents} element={<Documents />} />
          <Route
            path={AppConstants.routes.sharedDocuments}
            element={<SharedDocuments />}
          />
          <Route path={AppConstants.routes.employees} element={<Employees />} />
          <Route path={AppConstants.routes.timeSheet} element={<TimeSheet />} />
          <Route
            path={AppConstants.routes.timeSheetHistory}
            element={<TimesheetHistory />}
          />
          <Route
            path={AppConstants.routes.onBoarding}
            element={<OnBoarding />}
          />
          <Route
            path={AppConstants.routes.invalid_page}
            element={<PageNotFound />}
          />
          <Route
            path={AppConstants.routes.changePassword}
            element={<ResetPassword />}
          />
          <Route
            path={AppConstants.routes.resetPassword}
            element={<ResetPassword />}
          />
          <Route
            path={AppConstants.routes.privacyPolicy}
            element={<PrivacyPolicy />}
          />
          <Route
            path={AppConstants.routes.staffingSheet}
            element={<TableView />}
          />
          <Route path={AppConstants.routes.absence} element={<AddTimeOff />} />

          <Route
            path={AppConstants.routes.profile_log_overtime}
            element={<LogOverTime />}
          />
          <Route
            path={AppConstants.routes.analytics_details}
            element={<ReportDetails />}
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

import React from "react";
import { DatePicker, Form } from "antd";
import { useTranslation } from "react-i18next";
import AppConstants from "../../Helpers/AppConstants";
import { formattedDateForAPI } from "../../Utilities";

export default function MandatoryDetails({
  mandatoryDetails,
  setMandatoryDetails,
}) {
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;

  return (
    <React.Fragment>
      <div className="d-flex align-items-center justify-content-between ps-2">
        <div className="col-4">{t("nameOfMendatoryLeaves")}</div>
        <div className="col-8">
          <input
            value={mandatoryDetails.leaveName}
            onChange={(e) =>
              setMandatoryDetails({
                ...mandatoryDetails,
                leaveName: e.target.value,
              })
            }
            type={"text"}
            className="filter-form"
          />
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-between ps-2">
        <div className="col-4">{t("leaveDate")}</div>
        <div className="col-8">
          <div className="d-flex align-items-center flex-wrap-sm position-relative">
            <Form.Item name="Picker">
              <RangePicker
                getPopupContainer={(trigger) => trigger.parentElement}
                allowClear={true}
                className="filter-form d-flex"
                format={AppConstants.defaultDateFormat}
                onChange={(date, dateString) =>
                  setMandatoryDetails({
                    ...mandatoryDetails,
                    leaveStartDate: formattedDateForAPI(dateString[0]),
                    leaveEndDate: formattedDateForAPI(dateString[1]),
                  })
                }
              />
            </Form.Item>
          </div>
        </div>
      </div>
      <div className="d-flex mt-2 align-items-center justify-content-between ps-2">
        <div className="col-4">{t("annualLeaveDeduction")}</div>
        <div className="col-8">
          <div className="d-flex">
            <div className="rdo-sec">
              <input
                type="radio"
                name="select"
                id="optn-1"
                className="optn-1"
                onChange={(e) =>
                  setMandatoryDetails({
                    ...mandatoryDetails,
                    deductEmployee: "yes",
                  })
                }
                checked={mandatoryDetails.deductEmployee == "yes"}
              />
              <input
                type="radio"
                name="select"
                id="optn-2"
                className="optn-2"
                onChange={(e) =>
                  setMandatoryDetails({
                    ...mandatoryDetails,
                    deductEmployee: "no",
                  })
                }
                checked={mandatoryDetails.deductEmployee == "no"}
              />
              <label htmlFor="optn-1" className="me-2 option option-1">
                <div className="dot"></div>
                <span>{t("yes")}</span>
              </label>
              <label htmlFor="optn-2" className="me-2 option option-2">
                <div className="dot"></div>
                <span>{t("no")}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column ps-2">
        <div className="col-4">
          <p className="mb-0">{t("notes")}</p>
        </div>
        <div>
          <textarea
            className="w-100 filter_textarea"
            value={mandatoryDetails.notes}
            onChange={(e) =>
              setMandatoryDetails({
                ...mandatoryDetails,
                notes: e.target.value,
              })
            }
          />
        </div>
      </div>
    </React.Fragment>
  );
}

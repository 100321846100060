import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function OverTimeCancelModal(props) {
  const [notes, setNotes] = useState("");
  const { t } = useTranslation();
  const changeData = () => {
    props.cancelRequest(notes);
    setNotes("");
  };
  return (
    <div
      className="modal fade"
      id="overTimeCancel"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content w-100">
          <div className="modal-header bg-sec-cs text-white">
            <h5 className="modal-title" id="exampleModalLabel">
              {t("cancelOvertime")}
            </h5>
            <button
              type="button"
              className="btn-close text-light"
              data-mdb-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body modal-cs">
            <p>{t("permanentAction")}</p>
            <h2 className="h4 fw-bold">{t("reason")}</h2>
            <textarea
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              className="form-control mt-3"
              placeholder={t("pleaseEnterReason")}
            />
          </div>
          <button
            type="button"
            data-mdb-dismiss="modal"
            aria-label="Close"
            onClick={() => changeData()}
            className="btn btn-primary-cs mb-4 mx-3"
          >
            {t("cancelOvertime")}
          </button>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { Space, Table, Radio } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Applogger } from "../../Helpers/Logger";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { showSuccessToast, showFaliureToast } from "../../Utilities";
import {
  getWorkingHoursPatterns,
  deleteWorkingHoursPatterns,
  createOrUpdateWorkingHour,
} from "../../Redux/reducers/SettingsReducer";
import Button from "./Button";
import CreateNewWTPModal from "./CreateNewWTPModal";
import Tooltip from "../Tooltip";
import Spinner from "../Spinner";
import AppConstants from "../../Helpers/AppConstants";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";
export default function WorkingTimePattern() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { lng } = useSelector((state) => state.languageReducer);
  const { token, user } = useSelector((state) => state.AuthenticationReducer);
  const { workingHoursPatterns, loading } = useSelector(
    (state) => state.settings
  );

  const { exist: updateDeleteWTP } = usePrevilagesExist(
    AppConstants.previlages.updateDeleteWTP
  );

  const { exist: enableCreateWorkTimePattern } = usePrevilagesExist(
    AppConstants.previlages.enableCreateWorkTimePattern
  );
  const companyId = get(user, "company_id", "");

  const [hpToUpdate, setHPToUpdate] = useState({});
  const [showWTPModal, setShowWTPModal] = useState(false);
  const [workingDailyData, setWorkingDailyData] = useState([]);
  const [workingHourData, setWorkingHourData] = useState([]);
  const [currentRoleType, setCurrentRoleType] = useState(
    AppConstants.userRoleTypes.daily
  );

  const columns = [
    {
      title: t("name"),
      dataIndex: "pattern_details",
      key: "pattern_details",
    },
    {
      title: "No. of Days", // need to check
      dataIndex: "timeAndBreak",
      key: "timeAndBreak",
      render: (text) => <a>{text}</a>,
    },
    {
      title: t("publicHoliday"),
      dataIndex: "public_holidays",
      key: "public_holidays",
    },
    {
      title: t("roles"),
      key: "roles",
      dataIndex: "roles",

      render: (_, { roles }) => (
        <Tooltip
          placement="left"
          title={
            <span>
              {Array.isArray(roles) && roles.length > 0
                ? roles.map((val) => {
                  return (
                    <>
                      {val.name}
                      <br />
                    </>
                  );
                })
                : t("noRoles")
              }
            </span>
          }
          key={_}
        >
          {roles.length} Roles
        </Tooltip>
      ),
    },
    {
      width: 250,
      title: t("action"),
      key: "action",
      render: (pattern) => (
        <Space size="middle">
          {updateDeleteWTP && (
            <>
              <Button type="primary" onClick={() => handleEditHP(pattern)}>
                {t("edit")}
              </Button>
              <Button onClick={() => handleDeleteHP(pattern)}>
                {t("delete")}
              </Button>
            </>
          )}
        </Space>
      ),
    },
  ];

  const columnsHours = [
    {
      title: t("name"),
      dataIndex: "pattern_details",
      key: "pattern_details",
    },
    {
      title: t("contractedHours"),
      dataIndex: "contracted_week_data",
      key: "contracted_week_data",
    },
    {
      title: t("avgWorkingHours"),
      dataIndex: "average_working_data",
      key: "average_working_data",
      render: (text) => <a>{text}</a>,
    },
    {
      title: t("companyWorkingHours"),
      dataIndex: "companyWorkingData",
      key: "companyWorkingData",
    },
    {
      title: t("roles"),
      key: "roles",
      dataIndex: "roles",

      render: (_, { roles }) => (
        <Tooltip
          placement="left"
          title={
            <span>
              {Array.isArray(roles)
                ? roles.map((val) => {
                  return (
                    <>
                      {val.name}
                      <br />
                    </>
                  );
                })
                : ""}
            </span>
          }
          key={_}
        >
          {roles.length} {t("roles")}
        </Tooltip>
      ),
    },
    {
      width: 250,
      title: t("action"),
      key: "action",
      render: (pattern) => (
        <Space size="middle">
          {updateDeleteWTP && (
            <>
              <Button type="primary" onClick={() => handleEditHP(pattern)}>
                {t("edit")}
              </Button>
              <Button onClick={() => handleDeleteHP(pattern)}>
                {t("delete")}
              </Button>
            </>
          )}
        </Space>
      ),
    },
  ];
  useEffect(() => {
    handleGetWorkingHoursPatterns();
  }, []);

  useEffect(() => {
    handleLocalData();
  }, [workingHoursPatterns]);

  const handleEditHP = (patternToEdit) => {
    Applogger("Pattern To Edit: ", patternToEdit);
    setHPToUpdate(patternToEdit);
    setShowWTPModal(true);
  };

  const handleDeleteHP = (patternToDelete) => {
    Applogger("Pattern To Delete: ", patternToDelete);
    setHPToUpdate(null);
    handleDeleteWorkingHoursPatterns(
      get(patternToDelete, "pattern.working_hours", "")
    );
  };

  function handleGetWorkingHoursPatterns() {
    dispatch(getWorkingHoursPatterns({ token, lng, companyId }))
      .then(unwrapResult)
      .then(() => {
        Applogger("Working hours patterns received successfully");
      })
      .catch(() => {
        Applogger("Found error while fetching Working hour patterns");
      });
  }

  function handleDeleteWorkingHoursPatterns(id) {
    dispatch(
      deleteWorkingHoursPatterns({ token, lng, pattern_id: id, companyId })
    )
      .then(unwrapResult)
      .then((res) => {
        Applogger("Working hours deleted successfully", res);
        showSuccessToast(res);
        handleGetWorkingHoursPatterns();
      })
      .catch((err) => {
        showFaliureToast(err?.message ?? "Unable to delete working hour");
        Applogger("Found error while deleting Working hour ", err);
      });
  }

  const handleCreateOrUpdateWorkingHour = (
    workingTimePattern,
    currentRoleType
  ) => {
    dispatch(
      createOrUpdateWorkingHour({
        token,
        workingTimePattern,
        working_hour_id: get(hpToUpdate, "pattern.working_hours", ""),
        companyId,
        currentRoleType,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        showSuccessToast(res);
        handleGetWorkingHoursPatterns();
        Applogger("Response at createOrUpdateWorkingHour", res);
        setShowWTPModal(false);
      })
      .catch((err) => {
        showFaliureToast(err?.message ?? "Unable to create working hour");
        Applogger("Error at createOrUpdateWorkingHour", err);
      });
  };

  const getCompanyWorkingWeekHoursAndMinutes = (wtp) => {
    const company_working_week_hours = get(
      wtp,
      "company_working_week_hours",
      0
    );
    const company_working_week_minutes = get(
      wtp,
      "company_working_week_minutes",
      0
    );

    return `${company_working_week_hours} hours ${company_working_week_minutes} mins`;
  };
  const getAverageWorkingDataHoursAndMinutes = (wtp) => {
    const average_working_day_hours = get(wtp, "average_working_day_hours", 0);
    const average_working_day_minutes = get(
      wtp,
      "average_working_day_minutes",
      0
    );

    return `${average_working_day_hours} hours ${average_working_day_minutes} mins`;
  };

  const getContractedHoursAndMinutes = (wtp) => {
    const contracted_week_hours = get(wtp, "contract_week_hours", 0);
    const contracted_week_minutes = get(wtp, "contracted_week_minutes", 0);

    return `${contracted_week_hours} hours ${contracted_week_minutes} mins`;
  };

  const handleLocalData = () => {
    let localArray = [];
    let localArrayHours = [];
    let localArrayDaily = [];

    if (Array.isArray(workingHoursPatterns)) {
      for (let index = 0; index < workingHoursPatterns.length; index++) {
        const element = workingHoursPatterns[index];
        const wtp = get(element, "working_time_patterns", null);
        const whp = get(element, "working_hour_patterns", null);
        if (element.type == AppConstants.workingPattersTypes.daily) {
          localArrayDaily.push({
            pattern_details: get(element, "pattern_details", ""),
            timeAndBreak: getTimeAndBreak(wtp),
            public_holidays: get(wtp, "public_holidays", ""),
            roles: get(element, "roles", []),
            pattern: element,
          });
        } else {
          localArrayHours.push({
            contracted_week_data: getContractedHoursAndMinutes(whp),
            average_working_data: getAverageWorkingDataHoursAndMinutes(whp),
            roles: get(element, "roles", []),
            pattern: element,
            pattern_details: get(element, "pattern_details", ""),
            companyWorkingData: getCompanyWorkingWeekHoursAndMinutes(element),
          });
        }
      }
    }
    setWorkingDailyData(localArrayDaily);
    setWorkingHourData(localArrayHours);
  };

  const getTimeAndBreak = (workingTP) => {
    // const startTime = get(workingTP, "start_time", "")
    //   ? moment.unix(get(workingTP, "start_time", "")).format("HH:mm")
    //   : "";
    // const endTime = get(workingTP, "end_time", "")
    //   ? moment.unix(get(workingTP, "end_time", "")).format("HH:mm")
    //   : "";
    // const breakMinutes = get(workingTP, "break", "");
    // const days = get(workingTP, "days", []).length;
    // return `${startTime}-${endTime} | ${breakMinutes} Mins | ${days} Days`;

    return get(workingTP, "working_time_breaks", []).length;
  };

  return (
    <div>
      {loading == "pending" && <Spinner />}
      {enableCreateWorkTimePattern && (
        <CreateNewWTPModal
          companyId={companyId}
          visible={showWTPModal}
          onCancel={() => setShowWTPModal(false)}
          onOkPress={(body, currentRoleType) =>
            handleCreateOrUpdateWorkingHour(body, currentRoleType)
          }
          hpToUpdate={hpToUpdate}
        />
      )}

      <div
        className="d-flex justify-content-between my-3 align-items-end flex-wrap 
      "
      // gap-1
      >
        <Radio.Group
          defaultValue={currentRoleType}
          value={currentRoleType}
          onChange={(e) => setCurrentRoleType(e.target.value)}
          buttonStyle="solid"
        >
          <Radio.Button value={AppConstants.userRoleTypes.daily}>
            {t("daily")}
          </Radio.Button>
          <Radio.Button value={AppConstants.userRoleTypes.hours}>
            {t("hourly")}
          </Radio.Button>
        </Radio.Group>

        {enableCreateWorkTimePattern && (
          <Button
            onClick={() => {
              setHPToUpdate(null);
              setShowWTPModal(true);
            }}
          >
            {t("addWorkTimePattern")}
          </Button>
        )}
      </div>
      {currentRoleType == AppConstants.userRoleTypes.daily ? (
        <Table
          className="mainComponentHeight height-fit-content"
          columns={columns}
          dataSource={workingDailyData}
          scroll={{ y: 400 }}
          scroll={{ x: 400 }}
          pagination={{ pageSize: AppConstants.pagination.size }}
        />
      ) : (
        <Table
          className="mainComponentHeight height-fit-content"
          columns={columnsHours}
          dataSource={workingHourData}
          scroll={{ y: 400 }}
          scroll={{ x: 400 }}
          pagination={{ pageSize: AppConstants.pagination.size }}
        />
      )}
    </div>
  );
}

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AppConstants from "../../../Helpers/AppConstants";

export default function PeoplesHubCard() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [employeeSearchText, setEmployeeSearchText] = useState("");

  return (
    <div className="align-items-center d-flex justify-content-center">
      <input
        className="filter-form"
        value={employeeSearchText}
        onChange={(e) => setEmployeeSearchText(e.target.value)}
        placeholder={t("namePlaceholder")}
      />
      <button
        onClick={() =>
          navigate(
            `${AppConstants.routes.employees}?search=${employeeSearchText}`
          )
        }
        style={{
          aspectRatio: "1",
          height: "2.75rem",
          marginLeft: 10,
        }}
        className="btn-primary-outline-cs rounded"
      >
        <i className="ri-search-line fs-5" />{" "}
      </button>
    </div>
  );
}

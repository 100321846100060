import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { preventMinus } from "../../Utilities/index";

export default function CreateShfitModal({
  currentShiftData,
  setShiftData,
  dateRange,
  noDates,
}) {
  const { t } = useTranslation();

  return (
    <div className="modal-body modal-cs px-2">
      {!noDates && (
        <div className="d-flex align-items-center justify-content-between ps-2">
          <div className="col-4">{t("shiftDay")}</div>
          <div className="col-8">
            <input
              value={currentShiftData.shiftDay}
              max={dateRange.max}
              min={dateRange.min}
              onChange={(e) =>
                setShiftData({ ...currentShiftData, shiftDay: e.target.value })
              }
              type={"date"}
              className="filter-form"
            />
          </div>
        </div>
      )}
      <div className="d-flex align-items-center justify-content-between ps-2">
        <div className="col-4">{t("shiftTime")}</div>
        <div className="col-8">
          <div className="d-flex align-items-center flex-wrap-sm">
            <input
              value={currentShiftData.shiftTimeStart}
              onChange={(e) => {
                setShiftData({
                  ...currentShiftData,
                  shiftTimeStart: e.target.value,
                  shiftTimeEnd: moment(e.target.value, "h:mm:ss a").isAfter(
                    moment(currentShiftData.shiftTimeEnd, "h:mm:ss a")
                  )
                    ? e.target.value
                    : currentShiftData.shiftTimeEnd,
                });
              }}
              type={"time"}
              className="filter-form"
            />
            <p className="mt-2 ml-1 mr-1 fw-bold">{t("to")}</p>
            <input
              value={currentShiftData.shiftTimeEnd}
              onChange={(e) =>
                setShiftData({
                  ...currentShiftData,
                  shiftTimeEnd: e.target.value,
                  shiftTimeStart: moment(e.target.value, "h:mm:ss a").isBefore(
                    moment(currentShiftData.shiftTimeStart, "h:mm:ss a")
                  )
                    ? e.target.value
                    : currentShiftData.shiftTimeStart,
                })
              }
              type={"time"}
              className="filter-form"
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between ps-2">
        <div className="col-4">{t("breakDuration")}</div>
        <div className="col-8">
          <div className="d-flex filter-form d-flex align-items-center position-relative">
            <input
              defaultValue={currentShiftData.breakDuration}
              value={currentShiftData.breakDuration}
              onChange={(e) =>
                setShiftData({
                  ...currentShiftData,
                  breakDuration: e.target.value.replace(/^0+/, ""),
                })
              }
              placeholder={t("breakDuration")}
              className="p-0 border-0"
              type={"number"}
              onKeyPress={preventMinus}
              style={{ outline: "none" }}
            />
            <label className="text-black-50 position-absolute me-2 end-0">
              {t("min")}
            </label>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column ps-2">
        <div className="col-4">
          <p className="mb-0">{t("notes")} </p>
        </div>
        <div>
          <textarea
            className="w-100 filter_textarea"
            defaultValue={currentShiftData.notes}
            value={currentShiftData.notes}
            onChange={(e) =>
              setShiftData({ ...currentShiftData, notes: e.target.value })
            }
          />
        </div>
      </div>
    </div>
  );
}

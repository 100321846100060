import React, { useState, useEffect } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { Applogger } from "../../Helpers/Logger";
import { useTranslation } from "react-i18next";
import { getDeclineLeavesRequest } from "../../Redux/reducers/CalenderReducer";
import Spinner from "../Spinner";
import AbsenceCard from "../Profile/userAbsence/AbsenceCard";

export default function DeclinedAbsence() {
  const dispatch = useDispatch();

  const { token } = useSelector((state) => state.AuthenticationReducer);
  const { declinedLeaves, isLoading } = useSelector((state) => state.calender);
  const [declinedRequests, setDeclinedReqeust] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    if (declinedLeaves) {
      setDeclinedReqeust(declinedLeaves);
    }
  }, [declinedLeaves]);

  useEffect(() => {
    if (token) {
      getDeclinedRequest();
    }
  }, []);

  const getDeclinedRequest = () => {
    try {
      dispatch(getDeclineLeavesRequest({ token }))
        .then(unwrapResult)
        .then((res) => {
          Applogger("Response at getDeclineLeavesRequest", res);
        })
        .catch((err) => {
          Applogger("Error at getDeclineLeavesRequest", err);
        });
    } catch (err) {
      Applogger("error at declined request", err);
    }
  };
  return (
    <div>
      {isLoading && <Spinner />}

      <h1 className="mt-3 fw-bold subheading_2">{`${t("declinedRequests")} (${
        declinedRequests.length
      })`}</h1>
      {declinedRequests.map((absence, index) => {
        return <AbsenceCard key={index} cardData={absence} disabled />;
      })}
    </div>
  );
}

import React, { useState, useEffect } from "react";
import Spinner from "../../components/Spinner";
import TextInputField from "../../components/TextInputField";
import AppConstants from "../../Helpers/AppConstants";
import AppSelector from "../../components/AppSelector/AppSelector";
import { get } from "lodash";
import { useTitleHandler } from "../../customHooks/useTitleHandler";
import { getCompanySettingsOB } from "../../Redux/reducers/SettingsReducer";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import {
  loginRequest,
  logoutRequest,
} from "../../Redux/reducers/AuthenticationReducer";
import { useNavigate } from "react-router-dom";
import {
  showSuccessToast,
  showFaliureToast,
  showInfoToast,
  isUaeRegion,
} from "../../Utilities";
import { AppImages } from "../../Helpers/AppImages";
import { Applogger } from "../../Helpers/Logger";
import { useTranslation } from "react-i18next";
import { changeLanguage as changeLanguageRedux } from "../../Redux/reducers/LanguageReducer";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  useTitleHandler(t("dtLogin"));
  const { token, loading } = useSelector(
    (state) => state.AuthenticationReducer
  );
  const { lng } = useSelector((state) => state.languageReducer);

  const [email, setEmail] = useState(
    // "system@speedyhr.com"
    // "john.dave@speedy.com",
    // "devmanager@imail.com",
    // "john.dave@speedy.com"
    // "devcompany@gmail.com",
    // "farrukh.siliconplex@gmail.com",
    ""
  );

  const [password, setPassword] = useState(
    // "Test@123456"
    // "N@yyer@l!777" // dev company
    // "test123456"
    ""
  );

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (token) {
      navigate(AppConstants.routes.dashboard); // need to  check
    }
  }, []);

  useEffect(() => {
    isUaeRegion();
    checkKeyPress();
  }, []);

  React.useLayoutEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const lang = searchParams.get("lang");
    if (lang) {
      i18n.changeLanguage(lang);
      dispatch(changeLanguageRedux(lang));
    }
  }, []);

  const checkKeyPress = () => {
    try {
      window.addEventListener("keypress", (e) => {
        if (e.key === "Enter") {
          document.getElementById("loginButton").click();
          document.getElementById("loginButton").disabled = true;
        }
      });
    } catch (Err) {
      Applogger(Err);
    }
  };

  const handleGetCompanySettings = (token, companyId, user) => {
    dispatch(getCompanySettingsOB({ token, company_id: companyId, lng: lng }))
      .then(unwrapResult)
      .then((res) => {
        if (get(user, "is_admin", 0) == 0) {
          // For User
          if (get(res, "is_onboarded", 0) == 1) {
            navigate(AppConstants.routes.dashboard);
            showSuccessToast("Login successful");
          } else {
            showInfoToast(
              "Please contact your administrator, company profile is incomplete"
            );
            dispatch(logoutRequest());
          }
        } else {
          // For Admin
          if (get(res, "is_onboarded", 0) == 0) {
            navigate(AppConstants.routes.onBoarding); // need to check
          } else {
            navigate(AppConstants.routes.dashboard); // need to check
          }
          showSuccessToast("Login successful");
        }
        Applogger("Company settings received successfully", res);
      })
      .catch((err) => {
        showFaliureToast("Unable to get company settings");
        Applogger("Error at updateComapanySettings", err.message);
      });
  };

  const changeEmail = (value) => {
    setEmail(value);
  };
  const changePassword = (value) => {
    setPassword(value);
  };

  const LoginReq = (e) => {
    Applogger(email, password);
    e.preventDefault();
    if (email !== "" && password !== "") {
      callLoginAPI();
    } else {
      showFaliureToast(t("bothFieldsAreRequired"));
    }
  };

  const callLoginAPI = () => {
    const body = { email, password };
    dispatch(loginRequest(body))
      .then(unwrapResult)
      .then((res) => {
        if (res.access_token) {
          handleGetCompanySettings(
            get(res, "access_token", ""),
            get(res, "user.company_id", ""),
            get(res, "user", null)
          );
          // showSuccessToast("Login successful");
          Applogger("Login successful", res);
        }
      })
      .catch((err) => {
        if (err.message === AppConstants.errorMessages.invalidEmail) {
          showFaliureToast(AppConstants.errorMessages.invalidEmailMsg);
        } else if (err.message === AppConstants.errorMessages.userNotExist) {
          showFaliureToast(AppConstants.errorMessages.userNotExistMsg);
        } else {
          showFaliureToast(err.message.toString());
        }
        Applogger("Login failed", err.message);
        document.getElementById("loginButton").disabled = false;
      });
  };

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    dispatch(changeLanguageRedux(newLanguage));
    const params = new URLSearchParams();
    params.append("lang", newLanguage);
    navigate({ search: params.toString() });
  };

  return (
    <div>
      <div
        style={{ height: "100vh", overflow: "hidden" }}
        className="wrapper login-bg d-flex align-items-center justify-content-center w-100"
      >
        <div className="sp-selector-main">
          <AppSelector
            className="langBtn app-selector-style"
            onChange={handleLanguageChange}
            value={lng}
          />
        </div>
        <div className="tagline-login">
          <h4>
            {t("yourStrategic")} <br />
            {lng === "ar" ? <div style={{ height: 30 }} /> : ""}
            <span className="text-primary"> {t("hrPartner")}</span>
          </h4>
        </div>
        {loading === "pending" && <Spinner size={12} />}
        <div className="d-flex flex-column flex-wrap wrapper-2-cs">
          <div className="navbrand mb-3 mt-2">
            <img src={AppImages.speedyHrIconHd} alt="speedyhr" />
            {/* <Lottie
              play
              animationData={LogoAnimation}
              loop={false}
              style={{ height: 150 }}
            /> */}
          </div>
          <h3 className="fw-bold text-info">{t("loginToSpeedyHr")}</h3>
          <p className="small mb-0 text-black-50">{t("email")}</p>
          <TextInputField
            value={email}
            type="email"
            onChange={(e) => changeEmail(e.target.value)}
          />
          <p className="small mb-0 text-black-50">{t("password")}</p>
          <div className="d-flex filter-form d-flex align-items-center position-relative">
            <input
              className="w-100 h-100"
              value={password}
              type={showPassword ? "text" : "password"}
              style={{ outline: "none" }}
              onChange={(e) => changePassword(e.target.value)}
            />
            <button onClick={() => setShowPassword(!showPassword)}>
              <i class={!showPassword ? "ri-eye-off-line" : "ri-eye-line"} />
            </button>
          </div>
          {/* <TextInputField

          /> */}
          <div className="myFlex-cs align-items-end">
            <button
              onClick={(e) => LoginReq(e)}
              type="submit"
              id="loginButton"
              className="btn btn-primary-cs mt-2 d-flex"
            >
              {t("login")}
            </button>
            <a
              href={AppConstants.routes.forgot_password}
              className="text-decoration-underline text-primary"
              style={{ marginLeft: 10 }}
            >
              {t("forgotYourPassword")}
            </a>
          </div>
          <div className="terms py-4">
            <small className="small">
              {t("speedyHrAgreement")}
              <a
                href={AppConstants.routes.terms}
                target={"_blank"}
                className="text-primary underline-cs"
              >
                {t("termsOfUse")}
              </a>
              {"  also Check Our "}
              <a
                href={AppConstants.routes.privacyPolicy}
                target={"_blank"}
                className="text-primary underline-cs"
              >
                {t("privacyPolicy")}
              </a>
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

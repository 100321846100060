import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Applogger } from "../../Helpers/Logger";
import { unwrapResult } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { Select, Modal } from "antd";
import { get } from "lodash";
import {
  downloadBulkUsersCSV,
  uploadBulkUsersCSV,
} from "../../Redux/reducers/SettingsReducer";
import { showSuccessToast, showFaliureToast } from "../../Utilities";
import { EmployeeFormButton } from "../Employees/Forms/Components/Butttons";
import Papa from "papaparse";
import UploadSummary from "./UploadSummary";
import MultipleEmployeesCSV from "../Employees/MultipleEmployeesCSV";
import useLocalisedConstants from "../../customHooks/useLocalisedConstants";
import Spinner from "../Spinner";

import { saveAs } from "file-saver";

export default function EmployeeCSV({ customStyle, employeesCreated }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const appConstants = useLocalisedConstants();
  const myRef = useRef(null);

  const { token, user } = useSelector((state) => state.AuthenticationReducer);
  const { lng } = useSelector((state) => state.languageReducer);
  const { loading } = useSelector((state) => state.settings);

  const companyId = get(user, "company_id", "");
  const responseObj = {
    bulk_users: [],
    errorFE: [],
    error_for_not_created_user: [],
    successFE: "",
    user_created: [],
    user_not_created: [],
  };
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [selectedFields, setSelectedFields] = useState([]);
  const [csvUsersList, setCsvUsersList] = useState([]);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [serverResponse, setServerResponse] = useState(responseObj);

  useEffect(() => {
    setSelectedFields(
      appConstants.bulkUploadFields
        .filter((field) => get(field, "disabled", false))
        .map((item) => item.value)
    );
  }, []);

  const onFilePicked = (e) => {
    try {
      let files = e.target.files;
      setUploadedFiles(files);
      let fileReader = new FileReader();
      fileReader.addEventListener("load", () => {});
      fileReader.readAsDataURL(files[0]);
      handleCSVData(files[0]);
    } catch (err) {
      Applogger("Error at onFilePicked", err);
    }
  };

  const changeKeyAsPerRequirement = (keyToChange = "") => {
    return keyToChange
      .replace(/[^a-zA-Z ]/g, "")
      .replace(/[ ]/g, "_")
      .replace("__", "_")
      .toLowerCase();
  };

  const handleCSVData = (file) => {
    // For Displaying error table in summary
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        let parsedUsersList = get(results, "data", []).map((userObjc) => {
          const objectEntries = Object.keys(userObjc).map((key) => {
            const newKey = changeKeyAsPerRequirement(key);
            return { [newKey]: userObjc[key] };
          });
          return Object.assign({}, ...objectEntries);
        });
        let finalList = [];
        parsedUsersList.forEach((item, index) => {
          finalList.push({
            ...item,
            row: index + 1, // To map users in summary table
          });
        });
        setCsvUsersList(finalList);
      },
    });
  };

  const downloadBulkCSVHandler = () => {
    var a = document.createElement("a");
    dispatch(downloadBulkUsersCSV({ token, lng, companyId, selectedFields }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at downloadBulkUsersCSV", res);
        var strr = res.split("/");
        // a.href = res;
        // if (strr) {
        //   a.download = strr[strr.length - 1];
        // }
        // a.target = "_blank";
        // a.click();

        saveAs(res, strr[strr.length - 1]);
        showSuccessToast("File Downloaded Successfully");
      })
      .catch((err) => {
        showFaliureToast(err);
        Applogger("Error at downloadBulkUsersCSV", err);
      });
  };

  const onUploadCSVFileHandler = () => {
    try {
      dispatch(uploadBulkUsersCSV({ token, uploadedFiles, companyId }))
        .then(unwrapResult)
        .then((res) => {
          if (employeesCreated) {
            employeesCreated();
          }
          handleServerErrorOrResponse(get(res, "data", null));
          setShowSummaryModal(true);
        })
        .catch((err) => {
          handleServerErrorOrResponse(err);
          setShowSummaryModal(true);
        });
    } catch (err) {
      Applogger("Error at onUploadCSVFileHandler", err);
    }
  };

  const handleServerErrorOrResponse = (object) => {
    setServerResponse({
      ...serverResponse,
      bulk_users: get(object, "bulk_users", []),
      errorFE: get(object, "errorFE", []),
      error_for_not_created_user: get(object, "error_for_not_created_user", []),
      successFE: get(object, "successFE", ""),
      user_created: get(object, "user_created", []),
      user_not_created: get(object, "user_not_created", []),
    });
  };

  const uploadCSVHandler = (e) => {
    setUploadedFiles(null);
    setCsvUsersList(null);
    document.getElementById("uploadCSV").value = "";
    document.getElementById("uploadCSV").click();
    if (!customStyle) {
      myRef.current?.scrollIntoView();
    }
  };

  const handleChange = (value) => {
    setSelectedFields(value);
  };

  return (
    <div className={`${customStyle}`} style={{ overflow: "auto" }}>
      <Modal
        open={showSummaryModal}
        onCancel={() => {
          setShowSummaryModal(false);
        }}
        onOk={() => {
          setShowSummaryModal(false);
        }}
        width={"85%"}
        title={"Bulk Employee Summary"}
      >
        <UploadSummary summary={serverResponse} employeesList={csvUsersList} />
      </Modal>
      {loading == "pending" && <Spinner />}
      <input
        className="file d-none"
        id="uploadCSV"
        type="file"
        data-show-upload="true"
        data-show-caption="true"
        accept=".csv"
        onChange={(e) => onFilePicked(e)}
      />
      <div className="row my-3 align-items-center">
        <div className="col-12 col-md-3 pl-0">
          <label>{t("selectColumnsOfCsv")}</label>
        </div>
        <div className="col-12 col-md-9 pl-0 pr-0">
          <Select
            mode="multiple"
            className="filter-form-dropdown height-fit-content p-1"
            placeholder="Please select"
            value={selectedFields}
            onChange={handleChange}
            options={appConstants.bulkUploadFields}
          />
        </div>
      </div>
      {uploadedFiles !== null && (
        <div className="d-flex align-items-center mb-3">
          {uploadedFiles[0]?.name}
          <EmployeeFormButton
            addStyle={{ marginLeft: uploadedFiles ? 10 : 0 }}
            onClick={() => {
              setUploadedFiles(null);
              setCsvUsersList(null);
            }}
            title={t("clear")}
          />
          <EmployeeFormButton
            addStyle={{ marginLeft: 10 }}
            onClick={() => onUploadCSVFileHandler()}
            title={t("upload")}
          />
        </div>
      )}
      <MultipleEmployeesCSV
        downloadCSV={downloadBulkCSVHandler}
        uploadCSV={uploadCSVHandler}
      />
    </div>
  );
}

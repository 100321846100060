import React from "react";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import EmpDetailsField from "../Components/EmpDetailsField";
import useLocalisedConstants from "../../../../customHooks/useLocalisedConstants";
import EmplStatus from "../Components/EmplStatus";
import EmplDropDown from "../Components/EmplDropDown";

export default function EmergencyContact({
  employeeForm,
  setEmployeeForm,
  currentType,
}) {
  const { t } = useTranslation();

  const appConstants = useLocalisedConstants();
  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-between gap-2 col-md-12 col-sm-12">
        <div className="d-flex gap-2 col-lg-12 col-md-12 col-sm-12 flex-wrap justify-between">
          <EmpDetailsField
            title={t("emergencyContact")}
            placeholder={t("emergencyContact")}
            value={employeeForm.emergency_contact}
            isRequired={true}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                emergency_contact: e.target.value,
              })
            }
          />
          <EmpDetailsField
            title={t("contactTelephoneNo")}
            placeholder={t("contactTelephoneNo")}
            value={employeeForm.contact_telephone_no}
            isRequired={true}
            type={"tel"}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                contact_telephone_no: e.target.value,
              })
            }
          />

          <EmplDropDown
            title={t("contactRelationship")}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                contact_relationship: get(
                  JSON.parse(e.target.value),
                  "value",
                  ""
                ),
              })
            }
            value={employeeForm.contact_relationship}
            dataArray={appConstants.relationships}
            displayKey="title"
            valueKey="value"
            isRequired={true}
          />
          <EmplStatus
            title={t("contactPermission")}
            isRequired={true}
            isActive={employeeForm.contact_permission == "1"}
            onClick={(value) => {
              setEmployeeForm({
                ...employeeForm,
                contact_permission: value,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import StaffingCheckbox from "./StaffingCheckboxCard";
import Create from "./Create";
import Copy from "./Copy";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";
import AppConstants from "../../Helpers/AppConstants";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Applogger } from "../../Helpers/Logger";

export default function CreateStaffing({ currentStaff, copyModeDone }) {
  const { t } = useTranslation();
  const { data } = useSelector((state) => state.staffing);

  const { exist: activeCreateStaffingBtn } = usePrevilagesExist(
    AppConstants.previlages.activeCreateStaffingBtn
  );
  const [staffingOptions, setStaffingOptions] = useState([]);

  const create = {
    id: 1,
    title: t("createNewStaffingSchedule"),
    subtitle: t("newBespokeStaffingSchedule"),
    active: false,
  };

  const copy = {
    id: 2,
    title: t("copyExistingStaffingSchedule"),
    subtitle: t("staffingNotes"),
    active: false,
  };

  useEffect(() => {
    const option = [];
    if (activeCreateStaffingBtn) {
      if (data.length > 0) {
        option.push(copy);
        option.push(create);
      } else {
        setCurrentStatus("create");
      }
    }
    setStaffingOptions(option);
  }, [activeCreateStaffingBtn]);

  const [currentStatus, setCurrentStatus] = useState("");
  const [currentStaffing, setCurrentStaffing] = useState(
    get(currentStaff, "rota_id", get(data, "[0].rota_id", ""))
  );
  const [StaffingData, setStaffingData] = useState([]);

  useEffect(() => {
    setStaffingData(staffingOptions);
  }, [staffingOptions]);

  useEffect(() => {
    if (currentStaff != null) {
      changeStaffingStatus(StaffingData[1]);
      setCurrentStaffing(
        get(currentStaff, "rota_id", get(data, "[0].rota_id", ""))
      );
      if (currentStatus) {
        copyModeDone();
      }
    }

    if (currentStatus === null) {
      setStaffingData(staffingOptions);
    }
  }, [currentStatus, currentStaff, staffingOptions]);

  function changeStaffingStatus(data) {
    try {
      const state = [...StaffingData];
      for (let i = 0; i < state.length; i++) {
        state[i].active = false;
      }
      const index = state.findIndex((i) => i.id === data.id);
      if (state[index]?.id == 1) {
        setCurrentStatus("create");
      } else {
        setCurrentStatus("copy");
      }
      state[index] = { ...state[index], active: true };
      setStaffingData(state);
    } catch (err) {
      Applogger(err);
    }
  }

  return (
    <div className="animate__animated animate__fadeIn">
      <div className="row sec-cs-primary p-4">
        <div className="col-md-12">
          <div>
            <h5 className="fw-bold">{t("createStaffingSchedule")}</h5>
            <p>{t("createAndManageEmployeeShiftPatterns")}</p>
          </div>

          <h5 className="fw-bold my-3">{t("whatWouldYouLikeToDo")}</h5>
          <div className="d-flex flex-wrap-sm gap-2 animate__animated animate__backInUp">
            {StaffingData.map((i, index) => {
              return (
                <StaffingCheckbox
                  key={index}
                  onChange={(e) => changeStaffingStatus(i)}
                  checked={i.active}
                  active={i.active}
                  title={i.title}
                  id={i.id}
                  subtitle={i.subtitle}
                />
              );
            })}
          </div>
          {currentStatus == "copy" ? (
            <Copy
              currentStaff={currentStaffing}
              setCurrentStatus={setCurrentStatus}
            />
          ) : currentStatus == "create" ? (
            <Create setCurrentStatus={setCurrentStatus} />
          ) : null}
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";

export default function CustomModal(props) {
  const { t } = useTranslation();
  return (
    <div
      className="modal fade"
      id="publish"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header bg-sec-cs text-white">
            <h6 className="modal-title" id="exampleModalLabel">
              {props.modalTitle}
            </h6>
            <button
              type="button"
              onClick={props.closeModal}
              className="btn-close text-light"
              data-mdb-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body modal-cs">
            <p>{props.modalDesciption}</p>
            <button
              onClick={props.handleStaffingStatus}
              data-mdb-dismiss="modal"
              type="button"
              className="btn btn-primary-cs me-2"
            >
              {props.buttonTitle}
            </button>
            <button
              type="button"
              onClick={props.closeModal}
              className="btn btn-primary-outline-cs"
              data-mdb-dismiss="modal"
              aria-label="Close"
            >
              {t("close")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

import EventResource from "../../Config/EventResource";
import { APIConstants } from "../../Config/ApiConstants";
import { getHeaders } from "../../Helpers/commonFunc";

function RequestDocuments(payload) {
  const path = `${APIConstants.getAllFolders}${payload.document_type}/${
    payload.user_id
  }/${payload.id !== undefined && payload.id !== "" ? `${payload.id}/` : ""}`;
  return EventResource.get(
    `${path}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

function createDocumentRequest(payload) {
  const { currentStepId, folderName, user_id } = payload.documentData;
  const folderData = new FormData();
  folderData.append("parent_id", currentStepId);
  folderData.append("name", folderName);
  folderData.append("user_id", user_id);
  const path = `${APIConstants.createFolder}`;
  return EventResource.post(path, folderData, getHeaders(payload.token));
}

function uploadFileRequest(payload) {
  const documentData = new FormData();
  documentData.append("file_upload", payload.selectedFile);
  documentData.append("folder_id", payload.current_step_id);
  const path = `${APIConstants.uploadFile}`;
  return EventResource.post(
    path,
    documentData,
    getHeaders(payload.token, { "Content-Type": "multipart/form-data" })
  );
}

function downloadFileRequest(payload) {
  const data = {};
  const path = `${APIConstants.downlodFile}${payload.currentFileId}/`;
  return EventResource.post(path, data, getHeaders(payload.token));
}

function apiOnDeleteFolder(payload) {
  const folderData = new FormData();
  folderData.append("id", payload.folderId);
  const path = `${APIConstants.folders}delete/${payload.folderId}`;
  return EventResource.post(path, folderData, getHeaders(payload.token));
}

function apiOnEditFolder(payload) {
  const data = { name: payload.name };
  const path = `${APIConstants.folders}edit/${payload.folderId}`;
  return EventResource.post(path, data, getHeaders(payload.token));
}

function apiOnDeleteFile(payload) {
  const path = `${APIConstants.files}delete/${payload.fileId}`;
  const fileData = new FormData();
  fileData.append("id", payload.fileId);
  return EventResource.post(path, fileData, getHeaders(payload.token));
}

function apiOnEditFile(payload) {
  const data = { file_name: payload.name };
  const path = `${APIConstants.files}edit/${payload.fileId}`;
  return EventResource.post(path, data, getHeaders(payload.token));
}

function apiGetSharedDocumentsList(payload) {
  const path = APIConstants.sharedDocument;
  return EventResource.get(path, getHeaders(payload.token));
}

function apiUploadSharedDocument(payload) {
  const documentData = new FormData();
  const { token, selectedFile, selectedRoles } = payload;
  documentData.append("file", selectedFile);

  for (var i = 0; i < selectedRoles.length; i++) {
    documentData.append("roles[]", selectedRoles[i]);
  }

  const path = APIConstants.sharedDocument;
  return EventResource.post(
    path,
    documentData,
    getHeaders(token, { "Content-Type": "multipart/form-data" })
  );
}

function apiDeleteSharedDocument(payload) {
  const { token, fileId } = payload;
  const path = `${APIConstants.sharedDocument}/delete/${fileId}`;
  return EventResource.get(path, getHeaders(token));
}

function apiEditSharedDocument(payload) {
  const { token, fileId, name, selectedRoles } = payload;
  const data = { name: name, roles: selectedRoles };
  const path = `${APIConstants.sharedDocument}/update/${fileId}`;
  return EventResource.post(path, data, getHeaders(token));
}

function apiRegenerateDocumentsRequest(payload) {
  const { token } = payload;
  return EventResource.get(
    `${APIConstants.regenerateDocuments}`,
    getHeaders(token)
  );
}

export const DocumentsApiServices = {
  RequestDocuments,
  createDocumentRequest,
  uploadFileRequest,
  downloadFileRequest,
  apiOnDeleteFolder,
  apiOnEditFolder,
  apiOnDeleteFile,
  apiOnEditFile,
  apiGetSharedDocumentsList,
  apiUploadSharedDocument,
  apiDeleteSharedDocument,
  apiEditSharedDocument,
  apiRegenerateDocumentsRequest,
};

import { useTranslation } from "react-i18next";
import AppConstants from "../Helpers/AppConstants";
import { dashboardCards } from "../pages/Homepage/Constants";

export default function useLocalisedConstants() {
  const { t } = useTranslation();

  const appConstants = {
    rightToWorkDocTypes: [
      { value: "", placeholder: t("pleaseSelect") },
      { value: "UK Passport", placeholder: t("ukPassport") },
      { value: "EU Passport", placeholder: t("euPassport") },
      { value: "EU National ID Card", placeholder: t("euNationalIDCard") },
      {
        value: "Biometric Residence Permit",
        placeholder: t("biometricResidencePermit"),
      },
      {
        value: "Non EEA Passport With Endorsement",
        placeholder: t("nonEEAPassportWithEndorsement"),
      },
      { value: "EEA Residence Permit", placeholder: t("eeaResidencePermit") },
      {
        value: "Other HO Issued Document",
        placeholder: t("otherHOIssuedDocument"),
      },
      {
        value: "Birth Adoption Certification Plus NINO",
        placeholder: t("birthAdoptionCertificationPlusNINO"),
      },
      {
        value: "Certificate Of Registration",
        placeholder: t("certificateOfRegistration"),
      },
      {
        value: "HO Positive Verification Notice",
        placeholder: t("hoPositiveVerificationNotice"),
      },
      { value: "PVN Plus ARC", placeholder: t("pvnPlusARC") },
      { value: "PVN Plus CoA", placeholder: t("pvnPlusCoA") },
      { value: "EU Other", placeholder: t("euOther") },
    ],
    rightToWorkConfirmStatus: [
      { value: "", placeholder: t("pleaseSelect") },
      { value: "No", placeholder: t("no") },
      { value: "Yes", placeholder: t("yes") },
      {
        value: "I am recording this elsewhere",
        placeholder: t("recordingThisElsewhere"),
      },
    ],
    contractTypes: [
      { value: "", placeholder: t("pleaseSelect") },
      { value: "Full Time", placeholder: t("fulltime") },
      { value: "Part Time", placeholder: t("parttime") },
      { value: "Fixed Term", placeholder: t("fixedtime") },
    ],

    relationships: [
      { value: "", title: t("pleaseSelect") },
      { value: "Spouse", title: t("spouse") },
      { value: "Parent", title: t("parent") },
      { value: "Sibling", title: t("sibling") },
      { value: "Child", title: t("child") },
      { value: "Friend", title: t("friend") },
    ],
    paymentFrequencyList: [
      { value: "", title: t("pleaseSelect") },
      { value: "Weekly", title: t("weekly") },
      { value: "Fortnightly", title: t("fortnightly") },
      { value: "Four Weekly", title: t("fourWeekly") },
      { value: "Monthly", title: t("monthly") },
      { value: "Annually", title: t("annually") },
    ],
    salaryPerPeriodList: [
      { value: "", title: t("pleaseSelect") },
      { value: "Week", title: t("week") },
      { value: "Fortnight", title: t("fortnight") },
      { value: "Four Weeks", title: t("fourWeeks") },
      { value: "Month", title: t("month") },
      { value: "Year", title: t("year") },
    ],
    bankAccontTypes: [
      { value: "", title: t("pleaseSelect") },
      { value: "Bank Account", title: t("bankAccount") },
      { value: "Building Society Account", title: t("buildingSociety") },
    ],
    maritalStatuses: [
      { value: "", title: t("pleaseSelect") },
      { value: "Single", title: t("single") },
      { value: "Married", title: t("married") },
      { value: "Civil Partnership", title: t("civilPartnership") },
      { value: "Divorced", title: t("divorced") },
      { value: "Widowed", title: t("widowed") },
      { value: "Other", title: t("other") },
    ],
    noticePeriod: [
      { value: "No Notice Period", name: t("noNoticePeriod") },
      { value: "days", name: t("days") },
      { value: "weeks", name: t("weeks") },
      { value: "months", name: t("months") },
    ],
    probationPeriod: [
      { value: "No Probation Period", name: t("noProbationPeriod") },
      { value: "days", name: t("days") },
      { value: "weeks", name: t("weeks") },
      { value: "months", name: t("months") },
    ],
    reasonsToUpdate: [
      { value: "Please Select", name: t("pleaseSelect") },
      {
        value: "Additional responsibilities",
        name: t("additionalResponsibilities"),
      },
      { value: "Adjustment", name: t("adjustment") },
      { value: "Other", name: t("other") },
    ],
    holidayTypes: {
      deducted: t("deducted"),
      notDeducted: t("notDeducted"),
      WorksPublicHolidays: t("worksPublicHolidays"),
    },
    months: [
      { name: "January" },
      { name: "Febuary" },
      { name: "March" },
      { name: "April" },
      { name: "May" },
      { name: "June" },
      { name: "July" },
      { name: "August" },
      { name: "September" },
      { name: "October" },
      { name: "November" },
      { name: "December" },
    ],

    weekdays: [
      {
        day: "Monday",
        name: "M",
        selected: false,
        fe_id: 1,
      },
      {
        day: "Tuesday",
        name: "T",
        selected: false,
        fe_id: 2,
      },
      {
        day: "Wednesday",
        name: "W",
        selected: false,
        fe_id: 3,
      },
      {
        day: "Thursday",
        name: "T",
        selected: false,
        fe_id: 4,
      },
      {
        day: "Friday",
        name: "F",
        selected: false,
        fe_id: 5,
      },
      {
        day: "Saturday",
        name: "S",
        selected: false,
        fe_id: 6,
      },
      {
        day: "Sunday",
        name: "S",
        selected: false,
        fe_id: 7,
      },
    ],
    terminationReasons: [
      { title: t("pleaseSelect"), value: "" },
      { title: t("compromiseagreement"), value: "Compromise agreement" },
      { title: t("deceased"), value: "Deceased" },
      { title: t("dismissed"), value: "Dismissed" },
      { title: t("endofcontract"), value: "End of contract" },
      { title: t("redundant"), value: "Redundant" },
      { title: t("resigned"), value: "Resigned" },
      { title: t("retired"), value: "Retired" },
    ],
    daysOfWeek: [
      t("sunday"),
      t("monday"),
      t("tuesday"),
      t("wednesday"),
      t("thursday"),
      t("friday"),
      t("saturday"),
    ],
    toastMessages: {
      updatedSuccess: t("updatedsuccess"),
      updatedFailed: t("updatedfailed"),
    },
    bulkUploadFields: [
      { label: t("firstName"), value: "first_name", disabled: true },
      { label: t("lastName"), value: "last_name", disabled: true },
      { label: t("email"), value: "email", disabled: true },
      { label: t("workStartDate"), value: "work_start_date", disabled: true },
      {
        label: t("employeeReference"),
        value: "employee_reference",
        disabled: false,
      },
      { label: t("title"), value: "title", disabled: false },
      { label: t("address") + " 1", value: "address_1", disabled: false },
      { label: t("address") + " 2", value: "address_2", disabled: false },
      { label: t("postCode"), value: "post_code", disabled: false },
      { label: t("phoneNumber"), value: "telephone_number", disabled: false },
      { label: t("gender"), value: "gender", disabled: false },
      { label: t("maritalStatus"), value: "marital_status", disabled: false },
      { label: t("dateOfBirth"), value: "date_of_birth", disabled: false },
      { label: t("niNumber"), value: "ni_number", disabled: false },
      // { label: t("niCategory"), value: "ni_category", disabled: false },
      { label: t("taxCode"), value: "tax_code", disabled: false },
      {
        label: t("paymentFrequency"),
        value: "payment_frequency",
        disabled: false,
      },
      {
        label: t("emergencyContact"),
        value: "emergency_contact",
        disabled: false,
      },
      {
        label: t("contactRelationship"),
        value: "contact_relationship",
        disabled: false,
      },
      {
        label: t("contactTelephoneNo"),
        value: "contact_telephone_no",
        disabled: false,
      },
      {
        label: t("contactPermission"),
        value: "contact_permission",
        disabled: false,
      },
      { label: t("sortCode"), value: "sort_code", disabled: false },
      {
        label: t("bankAccountNumber"),
        value: "bank_account_number",
        disabled: false,
      },
      {
        label: t("bankAccountName"),
        value: "bank_account_name",
        disabled: false,
      },
      {
        label: t("bankName"),
        value: "bank_name",
        disabled: false,
      },
      {
        label: t("rightToWorkConfirmStatus"),
        value: "right_to_work_confirm_status",
        disabled: false,
      },
      { label: t("worksReference"), value: "works_reference", disabled: false },
      { label: t("initials"), value: "inital", disabled: false },
      { label: t("address") + " 3", value: "address_3", disabled: false },
      { label: t("address") + " 4", value: "address_4", disabled: false },
      { label: t("address") + " 5", value: "address_5", disabled: false },
      { label: t("workEndDate"), value: "work_end_date", disabled: false },
      // { label: t("wk1mth1Basis"), value: "wk1mth1_basis", disabled: false },
      { label: t("grossSalary"), value: "gross_salary", disabled: false },
      {
        label: t("salaryPerPeriod"),
        value: "salary_per_period",
        disabled: false,
      },
      {
        label: t("contractedHours"),
        value: "contracted_hours",
        disabled: false,
      },
      {
        label: t("contractedHoursPerPeriod"),
        value: "contracted_hours_per_period",
        disabled: false,
      },

      {
        label: t("firstDayCivilianEmp"),
        value: "first_day_of_civilian_employment",
        disabled: false,
      },
      {
        label: t("autoEnrExReason"),
        value: "auto_enrolment_exclusion_reason",
        disabled: false,
      },

      { label: t("directorStatus"), value: "director_status", disabled: false },
      {
        label: t("dateDirectorshipBegan"),
        value: "date_directorship_began",
        disabled: false,
      },
      {
        label: t("bankAccountType"),
        value: "bank_account_type",
        disabled: false,
      },
      {
        label: t("buildingSocNumber"),
        value: "building_soc_number",
        disabled: false,
      },
      {
        label: t("bankAddress") + "1",
        value: "bank_address_5",
        disabled: false,
      },
      {
        label: t("bankAddress") + "2",
        value: "bank_address_1",
        disabled: false,
      },
      {
        label: t("bankAddress") + "3",
        value: "bank_address_2",
        disabled: false,
      },
      {
        label: t("bankAddress") + "4",
        value: "bank_address_3",
        disabled: false,
      },
      {
        label: t("bankAddress") + "5",
        value: "bank_address_4",
        disabled: false,
      },
      { label: t("BASCReference"), value: "bacs_reference", disabled: false },
      { label: t("bankPostCode"), value: "bank_post_code", disabled: false },
      {
        label: t("employementTypes"),
        value: "employment_type",
        disabled: false,
      },
      {
        label: t("payslipEmailAddress"),
        value: "payslip_email_address",
        disabled: false,
      },
      {
        label: t("payslipPassword"),
        value: "payslip_password",
        disabled: false,
      },
      { label: t("nonUKWorker"), value: "non_uk_worker", disabled: false },
      {
        label: t("rightToWorkDocumentType"),
        value: "right_to_work_document_type",
        disabled: false,
      },
      {
        label: t("rightToWorkDocumentExpiryDate"),
        value: "right_to_work_document_expiry_date",
        disabled: false,
      },
      {
        label: t("rightToWorkReference"),
        value: "right_to_work_reference",
        disabled: false,
      },
    ],
    reportTypes: ["CSV", "PDF"],
    reportDates: [
      // { name: t("today"), value: "today" },
      // { name: t("lastweek"), value: 7 },
      // { name: t("last2week"), value: 14 },
      // { name: t("last3week"), value: 21 },
      { name: t("customDates"), value: "customDates" },
    ],
    reportDateTypes: {
      today: "today",
      lastweek: "lastweek",
      last2weeks: "last2weeks",
      last3weeks: "last3weeks",
      customDates: "customDates",
    },
    reportCategories: {
      absence: "absence",
      people_details: "people_details",
      holiday_summary: "holiday_summary",
      blip_timesheet: "blip_timesheet",
      lateness: "lateness",
    },
    leaveReportCategories: [
      {
        name: t("currentleaveentitlement"),
        value: "currentleaveentitlement",
      },
      {
        name: t("previousleaveentitlement"),
        value: "previousleaveentitlement",
      },
      {
        name: t("nextleaveentitlement"),
        value: "nextleaveentitlement",
      },
    ],
    titleArray: [
      { title: t("pleaseSelect"), value: "" },
      { title: t("mr"), value: "Mr." },
      { title: t("mrs"), value: "Mrs." },
      { title: t("miss"), value: "Miss." },
      { title: t("ms"), value: "Ms." },
      { title: t("dr"), value: "Dr." },
      { title: t("prof"), value: "Prof." },
      { title: t("sir"), value: "Sir." },
      { title: t("theRev"), value: "The Rev." },
      { title: t("theHon"), value: "The Hon." },
    ],
    genderArray: [
      { title: t("pleaseSelect"), value: "" },
      { title: t("male"), value: "Male" },
      { title: t("female"), value: "Female" },
      { title: t("notDisclosed"), value: "Not Disclosed" },
    ],
    employementTypes: [
      { title: t("pleaseSelect"), value: "" },
      { title: t("fulltime"), value: "Full Time" },
      { title: t("parttime"), value: "Part Time" },
      { title: t("temporary"), value: "Temporary" },
      { title: t("contractor"), value: "Contractor" },
      { title: t("expatriate"), value: "Expatriate" },
      { title: t("pension"), value: "Pension" },
    ],
    directorStatus: [
      { title: t("pleaseSelect"), value: "" },
      { title: t("nonDirector"), value: "Non-Director" },
      { title: t("directorAnnualBasis"), value: "Director (Annual basis)" },
      { title: t("directorProRataBasis"), value: "Director (Pro-rata basis)" },
    ],
    aToZList: [
      { title: t("pleaseSelect"), value: "" },
      { title: "A", value: "A" },
      { title: "B", value: "B" },
      { title: "C", value: "C" },
      { title: "D", value: "D" },
      { title: "E", value: "E" },
      { title: "F", value: "F" },
      // { title: "G", value: "G" },
      { title: "H", value: "H" },
      { title: "I", value: "I" },
      { title: "J", value: "J" },
      { title: "K", value: "K" },
      { title: "L", value: "L" },
      { title: "M", value: "M" },
      // { title: "N", value: "N" },
      // { title: "O", value: "O" },
      // { title: "P", value: "P" },
      // { title: "Q", value: "Q" },
      // { title: "R", value: "R" },
      { title: "S", value: "S" },
      // { title: "T", value: "T" },
      // { title: "U", value: "U" },
      { title: "V", value: "V" },
      // { title: "W", value: "W" },
      { title: "X", value: "X" },
      // { title: "Y", value: "Y" },
      { title: "Z", value: "Z" },
    ],
    paymentFrequency: [
      { title: t("selectFrequency"), value: "" },
      { title: t("week"), value: "Week" },
      { title: t("fortnight"), value: "Fortnight" },
      { title: t("fourWeeks"), value: "Four Weeks" },
      { title: t("month"), value: "Month" },
      { title: t("year"), value: "Year" },
    ],
    autoEnrExReasons: [
      { title: t("pleaseSelect"), value: "" },
      { title: t("noExclusion"), value: "No Exclusion" },
      {
        title: t("directorWithoutECon"),
        value: "Director without employment contract",
      },
      {
        title: t("nonSalariedLimited"),
        value: "Non-salaried limited liability partner",
      },
      {
        title: t("maximumAnnualContribution"),
        value: "Maximum annual contribution reached",
      },
      { title: t("receivedWindingUp"), value: "Received winding-up lump sum" },
      { title: t("workingNoticePeriod"), value: "Working Notice Period" },
    ],
    contractedHoursPerPeriodList: [
      { title: t("pleaseSelect"), value: "" },
      { title: t("week"), value: "Week" },
      { title: t("fortnight"), value: "Fortnight" },
      { title: t("fourWeeks"), value: "Four Weeks" },
      { title: t("month"), value: "Month" },
    ],
    yesNoArray: [
      { title: t("pleaseSelect"), value: "" },
      { title: t("no"), value: "No" },
      { title: t("yes"), value: "Yes" },
    ],
    gendersForList: [
      { title: t("pleaseSelect"), value: "" },
      { title: "M", value: "M = Male" },
      { title: "F", value: "F = Female" },
    ],
    dashboardCardsList: [
      {
        title: "analytics",
        primary_link_value: AppConstants.routes.analytics,
        short_description: t("analyticsDesc"),
        key: dashboardCards.reports,
        short_description_image: null,
        module: AppConstants.previlagesModules.analytics,
      },
      {
        title: "calendar",
        primary_link_value: AppConstants.routes.calendar,
        short_description: t("calendarDesc"),
        key: dashboardCards.calendar,
        short_description_image: null,
        module: AppConstants.previlagesModules.calendar,
      },
      {
        title: "employeeHub",
        primary_link_value: AppConstants.routes.employees,
        short_description: t("employeeHubDesc"),
        key: dashboardCards.peoplesHub,
        short_description_image: null,
        module: AppConstants.previlagesModules.peopleHub,
      },
      {
        title: "sharedDocuments",
        primary_link_value: AppConstants.routes.sharedDocuments,
        short_description: t("sharedDocumentsDesc"),
        key: dashboardCards.documents,
        short_description_image: null,
        module: AppConstants.previlagesModules.sharedDocuments,
      },
      {
        title: "whoseIn",
        primary_link_value: AppConstants.routes.timeSheet,
        short_description: t("whoseInDesc"),
        key: dashboardCards.whosIn,
        short_description_image: null,
        module: AppConstants.previlagesModules.timesheet,
      },
      {
        title: "staffing",
        primary_link_value: AppConstants.routes.staffing,
        short_description: t("staffingDesc"),
        key: dashboardCards.schedules,
        short_description_image:
          "https://api-dev.speedyhr.co.uk/images/rota_planning_desc.png",
        module: AppConstants.previlagesModules.schedules,
      },
    ],
  };

  return appConstants;
}

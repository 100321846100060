import React from "react";

import { useTranslation } from "react-i18next";
import { get } from "lodash";

import AppConstants from "../../Helpers/AppConstants";
import { unixToDateFormater, unixToDateFormaterOld } from "../../Utilities";

export default function ShiftCard({ cardInfo, showShiftDetails }) {
  const { t } = useTranslation();

  const startTime = get(cardInfo, "start_time", 0);
  const endTime = get(cardInfo, "end_time", 0);
  const shiftDay = get(cardInfo, "shift_day", 0);
  const firstName = get(cardInfo, "first_name", "");
  const lastName = get(cardInfo, "last_name", "");
  const Name = get(cardInfo, "name", "");

  return (
    <div className="shiftContainer p-3 mb-2">
      <div className="shiftStart col-3">
        <div className="profile-sm me-2" style={{ textTransform: "uppercase" }}>
          {firstName.slice(0, 1)}
          {lastName.slice(0, 1)}
        </div>
        <div className="d-flex justify-content-center flex-column">
          <p className="mb-0 capitalize">
            {firstName} {lastName}
          </p>
          <p
            data-mdb-toggle="modal"
            data-mdb-target="#shiftModal"
            onClick={() => showShiftDetails(cardInfo)}
            className="text-primary mb-0 cursor-pointer"
          >
            {t("shiftDetails")}
          </p>
        </div>
      </div>
      <div className="shiftStart col-2">
        <h1 className="h6 mb-0 w-100">
          {unixToDateFormaterOld(shiftDay, AppConstants.dateFormat)}
        </h1>
      </div>
      <div className="shiftStart col-2">
        <h1 className="h6 mb-0 w-100">
          {unixToDateFormaterOld(startTime, AppConstants.timeFormat)}
        </h1>
      </div>
      <div className="shiftStart col-2">
        <h1 className="h6 mb-0 w-100">
          {unixToDateFormaterOld(endTime, AppConstants.timeFormat)}
        </h1>
      </div>
      <div className="shiftStart col-2">
        <h1 className="h6 mb-0 w-100">{Name} </h1>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { preventMinus } from "../../Utilities/index";
import Tooltip from "../Tooltip";

export const InputField = ({
  title,
  isRequired,
  onChange,
  placeholder,
  value,
  type,
  preventMinusEnable,
  maxLength,
  info = null,
}) => {
  const [currentType, setCurrentType] = useState("");

  useEffect(() => {
    if (type) {
      setCurrentType(type);
    }
  }, [type]);

  return (
    <div className="d-flex align-items-center flex-wrap-sm">
      <p className="mb-0 col-md-4 col-sm-12 d-flex align-items-center">
        {title}
        <Tooltip placement="right" title={info}>
          <span>{info && <i class="ri-information-line fs-5" />}</span>
        </Tooltip>
        {isRequired && <span className="text-danger">*</span>}
      </p>
      <div className="col-12 col-md-8 col-sm-12">
        <input
          type={currentType}
          className={"filter-form"}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          maxLength={maxLength}
          onKeyPress={() => !preventMinusEnable ? preventMinus : ""}
        />
      </div>
    </div>
  );
};

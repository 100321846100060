import React from "react";

export default function SPProfileButton({
  isVisible = true,
  title = "",
  onClick = () => {},
  disabled = false,
  addBtnClass = "",
  addConClass = "",
}) {
  return (
    <div
      className={`col-12 col-sm-12 col-md d-flex justify-content-end pr-2 ${addConClass}`}
    >
      {isVisible && (
        <button
          disabled={disabled}
          onClick={onClick}
          className={`ms-2 btn btn-primary-cs mt-3 ${addBtnClass}`}
        >
          <span>{title}</span>
        </button>
      )}
    </div>
  );
}

export const workingHoursBody = {
  pattern_details: "",
  public_holidays: "",
  working_time_breaks: [],
  roles: [],
  company_working_week_hours: 0,
  company_working_week_minutes: 0,
  contract_week_hours: 0,
  contract_week_minutes: 0,
  average_working_day_hours: 0,
  average_working_day_minutes: 0,
};

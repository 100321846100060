import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAbsenceType } from "../Redux/reducers/CalenderReducer";
import { unwrapResult } from "@reduxjs/toolkit";
import { Applogger } from "./Logger";

export default function UseAbsenceTypes({ refresh = false }) {
  const dispatch = useDispatch();

  const { absenceTypes } = useSelector((state) => state.calender);
  const { token } = useSelector((state) => state.AuthenticationReducer);
  const { lng } = useSelector((state) => state.languageReducer);

  const [allAbsencesTypes, setAllAbsencesTypes] = useState([]);
  const [currentAbsenceType, setCurrentAbsenceType] = useState("");

  useEffect(() => {
    if (Array.isArray(absenceTypes)) {
      setAllAbsencesTypes(absenceTypes);
    }
  }, [absenceTypes]);

  useEffect(() => {
    if (token) {
      if (Array.isArray(absenceTypes) && absenceTypes.length == 0) {
        getAbsenceTypeRequest();
      } else if (refresh) {
        getAbsenceTypeRequest();
      }
    }
  }, [refresh]);

  const getCurrentAbsenceType = (id) => {
    const currentIndex = allAbsencesTypes.findIndex(
      (absence) => absence.absence_type_id == id
    );
    if (currentIndex !== -1) {
      const absence = allAbsencesTypes[currentIndex];

      return absence;
    }
  };

  const getAbsenceTypeRequest = () => {
    dispatch(getAbsenceType({ token, lng }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getAbsenceType", res);
        // currentEmployeeTypeHandler()
      })
      .catch((err) => Applogger("Error at getAbsenceType", err));
  };

  return { allAbsencesTypes, getCurrentAbsenceType };
}

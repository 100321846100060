import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import { SettingsApiServices } from "../actions/SettingsActions";
import {
  stopLoaderAndEmptyErrors,
  logoutRequest,
} from "./AuthenticationReducer";
import { getHourlyRoles, getDailyRoles } from "../../Utilities";
import AppConstants from "../../Helpers/AppConstants";

export const deleteComapnyLogo = createAsyncThunk(
  "EmployeeReducer/deleteComapnyLogo",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await SettingsApiServices.apiDeleteComapnyLogo(payload);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const generateDocument = createAsyncThunk(
  "EmployeeReducer/generateDocument",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await SettingsApiServices.apiGenerateDocument(payload);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const downloadBulkUsersCSV = createAsyncThunk(
  "EmployeeReducer/downloadBulkUsersCSV",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await SettingsApiServices.apiDownloadBulkUsersCSV(
        payload
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadBulkUsersCSV = createAsyncThunk(
  "EmployeeReducer/uploadBulkUsersCSV",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await SettingsApiServices.apiUploadBulkUsersCSV(payload);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const createPlaceOfWork = createAsyncThunk(
  "SettingsReducer/createPlaceOfWork",
  async (payload) => {
    const response = await SettingsApiServices.apiCreatePlaceOfWork(payload);
    return response;
  }
);

export const updatePlaceOfWork = createAsyncThunk(
  "SettingReducer/updatePlaceOfWork",
  async (payload) => {
    const response = await SettingsApiServices.updatePlaceofWork(payload);
    return response;
  }
);
export const getPlacesOfWork = createAsyncThunk(
  "SettingsReducer/getPlacesOfWork",
  async (payload) => {
    const response = await SettingsApiServices.apiGetPlacesOfWork(payload);
    return response;
  }
);

export const updateComapanySettings = createAsyncThunk(
  "SettingsReducer/updateComapanySettings",
  async (payload) => {
    const response = await SettingsApiServices.apiUpdateComapanySettings(
      payload
    );
    return response;
  }
);
export const deletePlacesOfWorkRequest = createAsyncThunk(
  "SettingsReducer/deletePlacesOfWorkRequest",
  // async (payload) => {
  //   const response = await SettingsApiServices.deletePlacesOfwork(payload);
  //   return response;
  // }
  async (payload, { rejectWithValue }) => {
    try {
      const response = await SettingsApiServices.deletePlacesOfwork(payload);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const getRolesSettingRequest = createAsyncThunk(
  "SettingReducer/getRolesSettingRequest",
  async (payload) => {
    const response = await SettingsApiServices.getAllRolesSettings(payload);
    return response;
  }
);
export const getAllRolesRequest = createAsyncThunk(
  "SettingReducer/getAllRolesRequest",
  async (payload) => {
    const response = await SettingsApiServices.getAllRoles(payload);
    return response;
  }
);

export const addRoleRequest = createAsyncThunk(
  "SettingReducer/addRoleRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await SettingsApiServices.addRole(payload);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteRoleRequest = createAsyncThunk(
  "SettingReducer/deleteRoleRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await SettingsApiServices.apiDeleteRoleRequest(payload);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateRoleRequest = createAsyncThunk(
  "SettingReducer/updateRoleRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await SettingsApiServices.apiUpdateRoleRequest(payload);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const addRoleSettingRequest = createAsyncThunk(
  "SettingsReducer/addRoleSettingRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await SettingsApiServices.addRoleSetting(payload);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteRoleSettingRequest = createAsyncThunk(
  "SettingReducer/deleteRoleSettingRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await SettingsApiServices.deleteRoleSetting(payload);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateRoleSettingRequest = createAsyncThunk(
  "SettingReducer/updateRoleSettingRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await SettingsApiServices.updateRoleSetting(payload);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserRolePrevilages = createAsyncThunk(
  "SettingReducer/getUserRolePrevilages",
  async (payload) => {
    const response = await SettingsApiServices.getUserRolePrevilages(payload);
    return response;
  }
);

export const getWorkingHoursPatterns = createAsyncThunk(
  "SettingReducer/getWorkingHoursPatterns",
  async (payload) => {
    const response = await SettingsApiServices.apiGetWorkingHoursPatterns(
      payload
    );
    return response;
  }
);

export const getRoleAssignedPrevilegesRequest = createAsyncThunk(
  "SettingReducer/getRoleAssignedPrevileges",
  async (payload) => {
    const response = await SettingsApiServices.getAssignedRolesPrevilges(
      payload
    );
    return response;
  }
);

export const deleteWorkingHoursPatterns = createAsyncThunk(
  "SettingReducer/deleteWorkingHoursPatterns",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await SettingsApiServices.apiDeleteWorkingHoursPatterns(
        payload
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const createOrUpdateWorkingHour = createAsyncThunk(
  "SettingReducer/createOrUpdateWorkingHour",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await SettingsApiServices.apiCreateOrUpdateWorkingHour(
        payload
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateComapanySettingsOB = createAsyncThunk(
  "SettingReducer/updateComapanySettingsOB",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await SettingsApiServices.apiUpdateComapanySettingsOB(
        payload
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCompanySettingsOB = createAsyncThunk(
  "SettingReducer/getCompanySettingsOB",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await SettingsApiServices.apiGetCompanySettingsOB(
        payload
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllSubModulesRequest = createAsyncThunk(
  "SettingReducer/getAllSubModulesRequest",
  async (payload) => {
    const response = await SettingsApiServices.getAllSubModules(payload);
    return response;
  }
);

export const assignRolePrevilages = createAsyncThunk(
  "SettingReducer/assignRolePrevilages",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await SettingsApiServices.assignUserPrevilages(payload);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCompanyEmployeesRequest = createAsyncThunk(
  "SettingReducer/getCompanyEmployeesRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await SettingsApiServices.getCompanyEmployees(payload);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const saveCompanyUserRequest = createAsyncThunk(
  "SettingReducer/saveCompanyUserRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await SettingsApiServices.saveCompanyUser(payload);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  loading: "idle",
  error: null,
  placesOfWork: [],
  rolesSettings: [],
  roles: [],
  allRoles: [],
  hourlyRoles: [],
  dailyRoles: [],
  userPrevilages: [],
  workingHoursPatterns: [],
  assignedPrevileges: [],
  subModulesPrevilages: [],
  companySettingsOB: null,
  companyEmployees: [],
};

const states = {
  pending: "pending",
  idle: "idle",
};

export const SettingsReducer = createReducer(initialState, {
  [deleteComapnyLogo.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [deleteComapnyLogo.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [deleteComapnyLogo.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },

  [downloadBulkUsersCSV.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [downloadBulkUsersCSV.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [downloadBulkUsersCSV.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },
  [uploadBulkUsersCSV.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [uploadBulkUsersCSV.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [uploadBulkUsersCSV.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },

  [createPlaceOfWork.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [createPlaceOfWork.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [createPlaceOfWork.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },

  [getCompanySettingsOB.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [getCompanySettingsOB.fulfilled]: (state, action) => {
    return {
      ...state,
      companySettingsOB: action.payload,
      loading: states.idle,
      error: null,
    };
  },
  [getCompanySettingsOB.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },

  [updateComapanySettingsOB.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [updateComapanySettingsOB.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [updateComapanySettingsOB.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },

  [getPlacesOfWork.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [getPlacesOfWork.fulfilled]: (state, action) => {
    return {
      ...state,
      placesOfWork: [...action.payload.data, ...AppConstants.placeOfWorkArray],
      loading: states.idle,
      error: null,
    };
  },
  [getPlacesOfWork.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },

  [getUserRolePrevilages.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [getUserRolePrevilages.fulfilled]: (state, action) => {
    return {
      ...state,
      userPrevilages: action.payload.data,
      loading: states.idle,
      error: null,
    };
  },
  [getUserRolePrevilages.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },

  [createOrUpdateWorkingHour.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [createOrUpdateWorkingHour.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [createOrUpdateWorkingHour.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },

  [updateComapanySettings.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },

  [updateComapanySettings.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [updateComapanySettings.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },
  [deletePlacesOfWorkRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [deletePlacesOfWorkRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [deletePlacesOfWorkRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },
  [getRolesSettingRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [getRolesSettingRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      rolesSettings: action.payload.data,
      loading: states.idle,
      error: null,
    };
  },
  [getRolesSettingRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },

  [deleteRoleRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [deleteRoleRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [deleteRoleRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [updateRoleRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [updateRoleRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [updateRoleRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [getRoleAssignedPrevilegesRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [getRoleAssignedPrevilegesRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      assignedPrevileges: action.payload.data,
      loading: states.idle,
      error: null,
    };
  },
  [getRoleAssignedPrevilegesRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },

  [getAllSubModulesRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [getAllSubModulesRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      subModulesPrevilages: action.payload.data,
      loading: states.idle,
      error: null,
    };
  },
  [getAllSubModulesRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },

  [getAllRolesRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [getAllRolesRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      roles: action.payload.data,
      hourlyRoles: getHourlyRoles(action.payload.data),
      dailyRoles: getDailyRoles(action.payload.data),
      loading: states.idle,
      error: null,
    };
  },
  [getAllRolesRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },

  [getCompanyEmployeesRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [getCompanyEmployeesRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      companyEmployees: action.payload.data,
      loading: states.idle,
      error: null,
    };
  },
  [getCompanyEmployeesRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },

  [addRoleSettingRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [addRoleSettingRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [addRoleSettingRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },

  [deleteRoleSettingRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [deleteRoleSettingRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [deleteRoleSettingRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },

  [updateRoleSettingRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [updateRoleSettingRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [updateRoleSettingRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },

  [updatePlaceOfWork.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [updatePlaceOfWork.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [updatePlaceOfWork.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },

  [getWorkingHoursPatterns.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [getWorkingHoursPatterns.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
      workingHoursPatterns: action.payload.data,
    };
  },
  [getWorkingHoursPatterns.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },

  [assignRolePrevilages.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [assignRolePrevilages.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
      assignedPrevileges: action.payload,
    };
  },
  [assignRolePrevilages.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },

  [saveCompanyUserRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [saveCompanyUserRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [saveCompanyUserRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },

  [generateDocument.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [generateDocument.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [generateDocument.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },

  [stopLoaderAndEmptyErrors.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [logoutRequest.fulfilled]: (state, action) => {
    return {
      ...initialState,
    };
  },
});

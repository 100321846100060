import React from 'react'

export default function AbsenceChildCard(props) {
    return (
        <div className='col-md-4 profile_absences flex-col d-flex align-items-center'>
            <h6 className='fw-bold d-inline'>{props.title}</h6>
            <div className='d-flex justify-content-center gap-2'>
              <div className='text-center'>
                    <h4 className='fw-bold'>{props.time_1}</h4>
                    <p className='fw-light'>{props.format_1}</p>
            </div>
            <div className='text-center'>
                    <h4 className='fw-bold'>{props.time_2}</h4>
                    <p className='fw-light'>{props.format_2}</p>
            </div>
            </div>
        </div>
    )
}

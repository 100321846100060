import { APIConstants } from "../../Config/ApiConstants";
import { getHeaders } from "../../Helpers/commonFunc";
import EventResource from "../../Config/EventResource";
import { Applogger } from "../../Helpers/Logger";

function getEmployees(payload) {
  const { token, lng, offset, employeesFilters, type } = payload;

  let path = `${APIConstants.getEmployees}?lng=${lng}`;
  let limit = 12;
  let finalOffset = offset;

  if (employeesFilters) {
    const { searchQuery, name, workingStatus, role, location } =
      employeesFilters;

    if (searchQuery) {
      limit = 200;
      // finalOffset = 0;
      path += `&name=${searchQuery}`;
    }
    if (workingStatus && workingStatus != "any") {
      path += `&working_status=${workingStatus}`;
    }
    if (role && role != "any") {
      path += `&role_id=${role}`;
    }
    if (location && location != "any") {
      path += `&work_place_id=${location}`;
    }
    if (name && name != "any") {
      if (name.includes("FA") || name.includes("FZ")) {
        path += `&sort_by=first_name`;
      }
      if (name.includes("LA") || name.includes("LZ")) {
        path += `&sort_by=last_name`;
      }
      if (name == "FA-Z" || name == "LA-Z") {
        path += `&order=asc`;
      }
      if (name == "LZ-A" || name == "FZ-A") {
        path += `&order=desc`;
      }
    }
  }

  if (finalOffset || finalOffset == 0) {
    path += `&offset=${finalOffset}&limit=${limit}`;
  }
  if (type) {
    path += `&type=${type}`;
  }
  return EventResource.get(path, getHeaders(token));
}

function apiCreateBulkEmployees(payload) {
  const { requestBody, token } = payload;
  Applogger("requestBody", requestBody);
  const path = APIConstants.createBulkEmployee;
  let finalBody = {
    employee: requestBody,
  };
  return EventResource.post(path, finalBody, getHeaders(token));
}

function createEmploye(payload) {
  const { employeeForm } = payload;
  const body = new FormData();
  for (const [key, value] of Object.entries(employeeForm)) {
    if (value) {
      body.append(key, value);
    }
  }

  return EventResource.post(
    APIConstants.createEmployee,
    body,
    getHeaders(payload.token)
  );
}

function getCountries(payload) {
  return EventResource.get(
    `${APIConstants.getCountries}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

function apiCreateLocationTypes(payload) {
  const body = new FormData();
  body.append("name", payload.name);
  return EventResource.post(
    `${APIConstants.locationsTypes}?lng=${payload.lng}`,
    body,
    getHeaders(payload.token)
  );
}

function apiUpdateLocationTypeRequest(payload) {
  const body = new FormData();
  body.append("name", payload.name);
  return EventResource.put(
    `${APIConstants.locationsTypes}?lng=${payload.lng}`,
    body,
    getHeaders(payload.token)
  );
}

function apiDeleteLocationTypes(payload) {
  return EventResource.get(
    `${APIConstants.locationsTypes}/delete/${payload.locationTypeId}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

function apiAllLocationTypes(payload) {
  return EventResource.get(
    `${APIConstants.locationsTypes}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

function ApiChangeEmployeeWorkingStatus(payload) {
  const userId = payload.user_id;
  const param = APIConstants.employeeWorkingStatus + userId;
  const body = new FormData();
  body.append("working_status", payload.working_status);

  return EventResource.post(param, body, getHeaders(payload.token));
}

function checkEmailRequest(payload) {
  const body = new FormData();
  body.append("email", payload.email);

  return EventResource.post(
    APIConstants.checkEmailStatus,
    body,
    getHeaders(payload.token)
  );
}

function downloadBulkCSVTemplate(payload) {
  return EventResource.get(
    `${APIConstants.downloadBulkCSV}${payload.currentUserType}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

// function uploadBulkCSVTemplate(payload) {
//   const bodyData = new FormData();
//   bodyData.append("user_type", payload.currentUserType);
//   bodyData.append(
//     "url",
//     `${window.location.origin + AppConstants.routes.resetPassword}?mode=create`
//   );
//   bodyData.append("employee", payload.uploadedFiles[0]);

//   return EventResource.post(
//     APIConstants.uploadBulkCSV,
//     bodyData,
//     getHeaders(payload.token, { "Content-Type": "multipart/form-data" })
//   );
// }
export const employeesApiServices = {
  getEmployees,
  createEmploye,
  apiCreateBulkEmployees,
  getCountries,
  ApiChangeEmployeeWorkingStatus,
  checkEmailRequest,
  downloadBulkCSVTemplate,
  // uploadBulkCSVTemplate,
  apiAllLocationTypes,
  apiCreateLocationTypes,
  apiUpdateLocationTypeRequest,
  apiDeleteLocationTypes,
};

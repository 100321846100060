import { APIConstants } from "../../Config/ApiConstants";
import { getHeaders } from "../../Helpers/commonFunc";
import { get } from "lodash";
import EventResource from "../../Config/EventResource";
import AppConstants from "../../Helpers/AppConstants";

export function apiDeleteComapnyLogo(payload) {
  return EventResource.get(
    APIConstants.deleteComLogo,
    getHeaders(payload.token)
  );
}

function apiCreatePlaceOfWork(payload) {
  const { token, newPlaceOfWork } = payload;

  const { withoutEmployees } = newPlaceOfWork;
  const path = `${APIConstants.placesToWork}create/`;

  var body = new FormData();

  if (!withoutEmployees) {
    for (var i = 0; i < newPlaceOfWork.employees.length; i++) {
      body.append("employees[]", newPlaceOfWork.employees[i].user_id);
    }
  }
  if (withoutEmployees) {
    body.append("location_type_id", newPlaceOfWork.location_type_id);
  }
  body.append("latitude", newPlaceOfWork.latitude);
  body.append("longitude", newPlaceOfWork.longitude);
  body.append("address_line1", newPlaceOfWork.address_line1);
  body.append("address_line2", newPlaceOfWork.address_line2);
  body.append("country_id", newPlaceOfWork.country_id);
  body.append("name", newPlaceOfWork.name);
  body.append("post_code", newPlaceOfWork.post_code);
  body.append("town_city", newPlaceOfWork.town_city);
  body.append("radius", newPlaceOfWork.acceptableRadius);

  return EventResource.post(path, body, getHeaders(token));
}

function apiGetPlacesOfWork(payload) {
  return EventResource.get(
    `${APIConstants.placesToWork}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

function apiGenerateDocument(payload) {
  return EventResource.get(
    `${APIConstants.generateDocument}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

function apiCreateOrUpdateWorkingHour(payload) {
  const { token, workingTimePattern, working_hour_id, currentRoleType } =
    payload;

  let path = `${APIConstants.workingHoursPattern}create/`;

  if (working_hour_id) {
    path = `${APIConstants.workingHoursPattern}update/${working_hour_id}`;
  }

  // Need to check
  let finalBody = workingTimePattern;

  const rolesArray = [];

  finalBody.roles.forEach((role) => {
    rolesArray.push(get(role, "role_id", ""));
  });
  finalBody.roles = rolesArray;

  return EventResource.post(
    path +
      `?type=${
        currentRoleType == AppConstants.userRoleTypes.daily
          ? "working_time_patterns"
          : "working_hour_patterns"
      }`,
    finalBody,
    getHeaders(token)
  );
}

function apiUpdateComapanySettings(payload) {
  const path = `${APIConstants.companySettingsOB}/update/`;
  var data = new FormData();
  data.append("employee_status", payload.body.employee_status);
  data.append("employee_privacy", payload.body.employee_privacy);
  data.append("annual_leave_carryover", payload.body.annual_leave_carryover);
  data.append("cancel_future_leaves", payload.body.cancel_future_leaves);
  data.append(
    "restricted_rota_permissions",
    payload.body.restricted_rota_permissions
  );
  data.append("hide_shift_labels", payload.body.hide_shift_labels);
  return EventResource.post(
    path,
    data,
    getHeaders(payload.token, { "Content-Type": "multipart/form-data" })
  );
}

function deletePlacesOfwork(payload) {
  return EventResource.get(
    `${APIConstants.deletePlacesOfwork}${payload.id}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

export function getAllRolesSettings(payload) {
  return EventResource.get(
    APIConstants.getRolesSetting,
    getHeaders(payload.token)
  );
}

export function getAllRoles(payload) {
  return EventResource.get(APIConstants.getRoles, getHeaders(payload.token));
}

export function addRole(payload) {
  const roleFormData = new FormData();
  roleFormData.append("name", payload.roleType);
  return EventResource.post(
    APIConstants.getRoles,
    roleFormData,
    getHeaders(payload.token)
  );
}

export function apiDeleteRoleRequest(payload) {
  return EventResource.get(
    `${APIConstants.deleteRole}${payload.roleId}`,
    getHeaders(payload.token)
  );
}

export function apiUpdateRoleRequest(payload) {
  const roleFormData = new FormData();
  roleFormData.append("name", payload.roleName);

  return EventResource.post(
    `${APIConstants.updateRole}${payload.roleId}`,
    roleFormData,
    getHeaders(payload.token)
  );
}

export function addRoleSetting(payload) {
  const {
    annual_leaves_allowance_days,
    annual_leaves_allowance_hours,
    annual_leaves_allowance_mins,
    approver_id,
    line_manager_id,
    location_type_id,
    role_id,
  } = payload.roleForm;
  const roleSettingData = new FormData();
  roleSettingData.append(
    "annual_leaves_allowance_days",
    annual_leaves_allowance_days
  );
  roleSettingData.append(
    "annual_leaves_allowance_hours",
    annual_leaves_allowance_hours
  );
  roleSettingData.append(
    "annual_leaves_allowance_mins",
    annual_leaves_allowance_mins
  );
  roleSettingData.append("approver_id", approver_id);
  roleSettingData.append("line_manager_id", line_manager_id);
  roleSettingData.append("location_type_id", location_type_id);
  roleSettingData.append("role_id", role_id);
  roleSettingData.append("role_type", payload.currentUserType);

  return EventResource.post(
    APIConstants.getRolesSetting,
    roleSettingData,
    getHeaders(payload.token)
  );
}

export function deleteRoleSetting(payload) {
  return EventResource.get(
    APIConstants.deleteRolesSetting + payload.role_id,
    getHeaders(payload.token)
  );
}

export function updateRoleSetting(payload) {
  const {
    annual_leaves_allowance_days,
    annual_leaves_allowance_hours,
    annual_leaves_allowance_mins,
    approver_id,
    line_manager_id,
    location_type_id,
    role_id,
  } = payload.roleForm;
  const roleSettingData = new FormData();
  if (!annual_leaves_allowance_days) {
    roleSettingData.append("annual_leaves_allowance_days", 0);
  } else {
    roleSettingData.append(
      "annual_leaves_allowance_days",
      annual_leaves_allowance_days
    );
  }
  if (!annual_leaves_allowance_hours) {
    roleSettingData.append("annual_leaves_allowance_hours", 0);
  } else {
    roleSettingData.append(
      "annual_leaves_allowance_hours",
      annual_leaves_allowance_hours
    );
  }
  if (!annual_leaves_allowance_mins) {
    roleSettingData.append("annual_leaves_allowance_mins", 0);
  } else {
    roleSettingData.append(
      "annual_leaves_allowance_mins",
      annual_leaves_allowance_mins
    );
  }

  roleSettingData.append("approver_id", approver_id);
  roleSettingData.append("line_manager_id", line_manager_id);
  roleSettingData.append("location_type_id", location_type_id);
  roleSettingData.append("role_id", role_id);
  roleSettingData.append("role_type", payload.currentUserType);

  return EventResource.post(
    APIConstants.updateRolesSetting + payload.roleId,
    roleSettingData,
    getHeaders(payload.token)
  );
}

export const updatePlaceofWork = (payload) => {
  const { token, newPlaceOfWork } = payload;

  const { withoutEmployees } = newPlaceOfWork;
  const path = `${APIConstants.placesToWork}update/${payload.id}`;

  var body = new FormData();

  if (!withoutEmployees) {
    for (var i = 0; i < newPlaceOfWork.employees.length; i++) {
      body.append("employees[]", newPlaceOfWork.employees[i].user_id);
    }
  }
  if (withoutEmployees) {
    body.append("location_type_id", newPlaceOfWork.location_type_id);
    body.append("employees[]", -1);
  }
  body.append("latitude", newPlaceOfWork.latitude);
  body.append("longitude", newPlaceOfWork.longitude);
  body.append("address_line1", newPlaceOfWork.address_line1);
  body.append("address_line2", newPlaceOfWork.address_line2);
  body.append("country_id", newPlaceOfWork.country_id);
  body.append("name", newPlaceOfWork.name);
  body.append("post_code", newPlaceOfWork.post_code);
  body.append("town_city", newPlaceOfWork.town_city);
  body.append("radius", newPlaceOfWork.acceptableRadius);

  return EventResource.post(path, body, getHeaders(token));
};

export const getUserRolePrevilages = (payload) => {
  return EventResource.get(APIConstants.modules, getHeaders(payload.token));
};

export const apiGetWorkingHoursPatterns = (payload) => {
  return EventResource.get(
    APIConstants.workingHoursPattern,
    getHeaders(payload.token)
  );
};

export const getAssignedRolesPrevilges = (payload) => {
  return EventResource.get(
    APIConstants.userPrivileges + `${payload.id}?key=key`,
    getHeaders(payload.token)
  );
};

function apiDeleteWorkingHoursPatterns(payload) {
  const path = `${APIConstants.workingHoursPattern}delete/${payload.pattern_id}`;
  return EventResource.get(path, getHeaders(payload.token));
}

export const apiGetCompanySettingsOB = (payload) => {
  return EventResource.get(
    APIConstants.companySettingsOB,
    getHeaders(payload.token)
  );
};

function apiUpdateComapanySettingsOB(payload) {
  const { body, company_id } = payload;
  const path = `${APIConstants.companySettingsOB}/update/${company_id}`;
  // const path = `${APIConstants.companySettingsOB}/update`;

  var data = new FormData();

  data.append("hq_address", body.hq_address);
  data.append("latitude", body.latitude);
  data.append("longitude", body.longitude);
  data.append("company_title", body.company_title);
  data.append("description", body.description);
  data.append("company_logo", body.company_logo.file ?? "");
  // lelfe
  data.append("address_line1", body.address_line1);
  data.append("town_city", body.town_city);
  data.append("address_line2", body.address_line2);
  data.append("post_code", body.post_code);
  data.append("country_id", body.country_id);
  data.append("countryName", body.countryName);
  data.append("fiscal_start_date", body.fiscal_start_date);
  data.append("fiscal_start_month", body.fiscal_start_month);
  data.append("fiscal_end_date", body.fiscal_end_date);
  data.append("fiscal_end_month", body.fiscal_end_month);
  return EventResource.post(
    path,
    data,
    getHeaders(payload.token, { "Content-Type": "multipart/form-data" })
  );
}

export const getAllSubModules = (payload) => {
  return EventResource.get(
    APIConstants.allPrevilages,
    getHeaders(payload.token)
  );
};

export const assignUserPrevilages = (payload) => {
  const { token, currentRole, Previlages } = payload;
  const allPrev = [...Previlages];
  let previlagesData = new FormData();

  for (let indexx = 0; indexx < allPrev.length; indexx++) {
    const curr = allPrev[indexx];
    previlagesData.append("privileges[]", curr);
  }

  return EventResource.post(
    APIConstants.assignPrevilages + currentRole,
    previlagesData,
    getHeaders(token)
  );
};

function apiDownloadBulkUsersCSV(payload) {
  return EventResource.get(
    APIConstants.dowloadBulkEmp + `?fields=${payload.selectedFields}`,
    getHeaders(payload.token)
  );
}

function apiUploadBulkUsersCSV(payload) {
  const bodyData = new FormData();
  // bodyData.append("user_type", payload.currentUserType);
  // bodyData.append(
  //   "url",
  //   `${window.location.origin + AppConstants.routes.resetPassword}?mode=create`
  // );
  bodyData.append("employee", payload.uploadedFiles[0]);

  return EventResource.post(
    APIConstants.uploadBulkUsersCSV,
    bodyData,
    getHeaders(payload.token, { "Content-Type": "multipart/form-data" })
  );
}

function getCompanyEmployees(payload) {
  return EventResource.get(
    APIConstants.companyEmployees,
    getHeaders(payload.token)
  );
}

function saveCompanyUser(payload) {
  const userData = new FormData();
  const { token, formData, id } = payload;
  const { country, location, name, role, last_name } = formData;
  const checkWorkPlaceId = AppConstants.placeOfWorkArray.find(
    (item) => item.work_place_id == location
  );

  if (checkWorkPlaceId) {
    userData.append("work_place_type", location);
  } else {
    userData.append("work_place_id", location);
  }
  userData.append("role_id", role);
  userData.append("country_id", country);
  userData.append("first_name", name);
  userData.append("last_name", last_name);

  return EventResource.post(
    APIConstants.saveCompanyUser + id,
    userData,
    getHeaders(token)
  );
}
export const SettingsApiServices = {
  apiDeleteComapnyLogo,
  apiGenerateDocument,
  apiCreatePlaceOfWork,
  apiGetPlacesOfWork,
  apiDownloadBulkUsersCSV,
  apiUpdateComapanySettings,
  deletePlacesOfwork,
  getAllRolesSettings,
  getAllRoles,
  addRole,
  apiDeleteRoleRequest,
  apiUpdateRoleRequest,
  addRoleSetting,
  deleteRoleSetting,
  updatePlaceofWork,
  updateRoleSetting,
  getUserRolePrevilages,
  apiGetWorkingHoursPatterns,
  apiDeleteWorkingHoursPatterns,
  apiCreateOrUpdateWorkingHour,
  getAssignedRolesPrevilges,
  getAllSubModules,
  assignUserPrevilages,
  apiGetCompanySettingsOB,
  apiUpdateComapanySettingsOB,
  apiUploadBulkUsersCSV,
  getCompanyEmployees,
  saveCompanyUser,
};

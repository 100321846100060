import React, { useEffect } from 'react';

function ChatWidget(props) {
  useEffect(() => {
    window.zE('webWidget', 'hide'); // Hide the chat widget initially

    window.zE('webWidget', 'setLocale', props.locale); // Set the locale
    window.zE('webWidget', 'setTheme', props.theme); // Set the theme
    window.zE('webWidget', 'setDepartment', props.department); // Set the department

    window.zE('webWidget', 'addEventListener', 'chat:closed', props.onChatClosed); // Handle chat closed event
    window.zE('webWidget', 'addEventListener', 'chat:started', props.onChatStarted); // Handle chat started event

    window.zE('webWidget', 'init', {
      accountKey: props.accountKey,
      authentication: {
        jwtFn: function (callback) {
          callback(props.jwt);
        },
      },
    });

    // Show the chat widget after 1 second
    setTimeout(() => {
      window.zE('webWidget', 'show');
    }, 1000);
  }, []);

  return null; // ChatWidget doesn't render anything
}

export default ChatWidget;

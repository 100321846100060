import React from "react";

export default function Button({
  children,
  type,
  onClick,
  className,
  disabled,
}) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`btn ${
        type == "primary" ? "btn-primary-cs" : "btn-primary-outline-cs"
      } ${className}`}
    >
      {children}
    </button>
  );
}

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTitleHandler } from "../../customHooks/useTitleHandler";
import Spinner from "../../components/Spinner";
import Header from "../../components/Header/Header";
import HistoryPanel from "./HistoryPanel";
import AppConstants from "../../Helpers/AppConstants";

export default function TimesheetHistory() {
  // Dispatcher
  const navigate = useNavigate();

  // Localisation
  const { t } = useTranslation();

  // Hooks
  useTitleHandler(t("timesheetHistory"));

  // Reducer States
  const { loading } = useSelector((state) => state.timesheet);
  const { token } = useSelector((state) => state.AuthenticationReducer);

  useEffect(() => {
    if (!token) {
      navigate(AppConstants.routes.login);
    }
  }, [token]);

  return (
    <div>
      <Header active={""} />
      <br />
      {loading == "pending" && <Spinner />}
      <div className="sp_main">
        <HistoryPanel />
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { Applogger } from "../../../Helpers/Logger";
import { getUserWorkTimePattern } from "../../../Redux/reducers/AuthenticationReducer";
import { unixToDateFormater } from "../../../Utilities";
import moment from "moment";
import AppConstants from "../../../Helpers/AppConstants";

function WorkTimePatternCard() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { token, user, userWorkTimePattern } = useSelector(
    (state) => state.AuthenticationReducer
  );

  const [patternDays, setPatternDays] = useState([]);
  const [hourlyUserPattern, setHourlyUserPattern] = useState(null);

  const workedHours = get(
    hourlyUserPattern,
    "working_hour_patterns.worked_hours",
    0
  );
  const workedMins = get(
    hourlyUserPattern,
    "working_hour_patterns.worked_mins",
    0
  );

  const contractHours = get(
    hourlyUserPattern,
    "working_hour_patterns.contract_week_hours",
    0
  );
  const contractMins = get(
    hourlyUserPattern,
    "working_hour_patterns.contract_week_minutes",
    0
  );

  const averageHours = get(
    hourlyUserPattern,
    "working_hour_patterns.average_working_day_hours",
    0
  );
  const averageMins = get(
    hourlyUserPattern,
    "working_hour_patterns.average_working_day_minutes",
    0
  );

  const companyHours = get(hourlyUserPattern, "company_working_week_hours", 0);
  const companyMins = get(hourlyUserPattern, "company_working_week_minutes", 0);

  useEffect(() => {
    handleGetUserWorkTimePattern();
  }, []);

  useEffect(() => {
    if (get(user, "user_type", "") == "days") {
      setPatternDays(
        get(
          userWorkTimePattern,
          "working_hours.working_time_patterns.working_time_breaks",
          []
        )
      );
    } else {
      setHourlyUserPattern(get(userWorkTimePattern, "working_hours", null));
    }
  }, [userWorkTimePattern]);

  const handleGetUserWorkTimePattern = () => {
    dispatch(
      getUserWorkTimePattern({ token, role_id: get(user, "role_id", "") })
    )
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getUserWorkTimePattern", res);
      })
      .then((err) => {
        Applogger("Error at getUserWorkTimePattern", err);
      });
  };

  const calculatWhoseInPanelHeight = () => {
    if (document && window) {
      const element = document.getElementById("SummaryCardDiv");
      if (element) {
        return get(element, "offsetHeight", "70vh")
          ? element.offsetHeight + "px"
          : "70vh";
      }
    } else {
      return "70vh";
    }
  };

  const isCurrentDay = (day) => {
    return moment().format("dddd") == day;
  };

  return (
    <div
      style={{
        maxHeight: calculatWhoseInPanelHeight(),
        overflowY: "auto",
      }}
      data-aos-duration="1500"
      data-aos="fade-right"
      className={`col-sm-12 col-md-12`}
    >
      {get(user, "user_type", "") == "days" ? (
        <div>
          {patternDays.length > 0 && (
            <div className="">
              {[...patternDays]
                .sort((itemA, _) => {
                  return (
                    new Date() - new Date(get(itemA, "start_time", "") * 1000)
                  );
                })
                .map((workingDay, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        backgroundColor: isCurrentDay(
                          get(workingDay, "day", "")
                        )
                          ? "#00aff0"
                          : "",
                        borderRadius: 10,
                      }}
                      className="d-flex border-custom my-2 align-items-center justify-between"
                    >
                      <div className="w-25 m-2">
                        <PatternDataCell
                          title={get(workingDay, "day", "")}
                          description={""}
                          isCurrentDay={isCurrentDay(
                            get(workingDay, "day", "")
                          )}
                        />
                      </div>
                      <div className="d-flex w-100 justify-around">
                        <PatternDataCell
                          title={t("start") + ":"}
                          description={moment
                            .unix(get(workingDay, "start_time", ""))
                            .format(AppConstants.timeFormat)}
                          isCurrentDay={isCurrentDay(
                            get(workingDay, "day", "")
                          )}
                        />
                        <PatternDataCell
                          title={t("end") + ":"}
                          description={moment
                            .unix(get(workingDay, "end_time", ""))
                            .format(AppConstants.timeFormat)}
                          isCurrentDay={isCurrentDay(
                            get(workingDay, "day", "")
                          )}
                        />
                        <PatternDataCell
                          title={t("break") + ":"}
                          description={get(workingDay, "break", "")}
                          isCurrentDay={isCurrentDay(
                            get(workingDay, "day", "")
                          )}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      ) : (
        <div>
          <div>
            <HourlyPatternDataCell
              height={200}
              title={t("hoursSpent") + ":"}
              hours={workedHours}
              minutes={workedMins}
            />
          </div>
          <div className="d-flex justify-between align-items-center">
            <HourlyPatternDataCell
              title={t("contractedHoursPerWeek") + ":"}
              hours={contractHours}
              minutes={contractMins}
            />
            <HourlyPatternDataCell
              title={t("anAverageWorkingDay") + ":"}
              hours={averageHours}
              minutes={averageMins}
            />
            <HourlyPatternDataCell
              title={t("workingWeekExcludingBreaks") + ":"}
              hours={companyHours}
              minutes={companyMins}
            />
          </div>
        </div>
      )}
      {!patternDays.length > 0 &&
        !hourlyUserPattern &&
        get(user, "role", "Owner") != "Owner" && (
          <p className="text-center mb-0">{t("noWorktimePattern")}</p>
        )}
    </div>
  );
}

export default WorkTimePatternCard;

const PatternDataCell = ({ title, description, isCurrentDay }) => {
  return (
    <p
      className="m-2 lexen-"
      style={{
        color: isCurrentDay ? "#fff" : "",
      }}
    >
      {isCurrentDay ? (
        <strong className="lexen-font fs-6">{title + " " + description}</strong>
      ) : (
        <span className="lexen-font">{title + " " + description}</span>
      )}
    </p>
  );
};

const HourlyPatternDataCell = ({
  title = "",
  hours = "",
  minutes = "",
  height = null,
}) => {
  const { t } = useTranslation();

  return (
    <div className="m-2 align-items-center justify-center d-flex flex-col">
      <p className="mb-0 text-center">
        <span className="lexen-font fs-6">{title}</span>
      </p>
      <div
        className="m-3 d-flex justify-center align-items-center"
        style={{
          backgroundColor: "#00aff0",
          borderRadius: 10,
          height: height,
          width: height,
        }}
      >
        <p
          className="p-2 mb-0 fs-3 font-bold text-center flex-col d-flex"
          style={{ color: "#fff" }}
        >
          <span className="lexen-font">{`${hours} ${t("hrs")}`}</span>
          <span className="lexen-font">{`${minutes} ${t("min")}`}</span>
        </p>
      </div>
    </div>
  );
};

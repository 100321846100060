import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AppConstants from "../../../../Helpers/AppConstants";
import ViewOnGoogle from "../../../AppButtons/ViewOnGoogle";
import $ from "jquery";

export default function EmpAddress({
  isRequired,
  title,
  handleSelection,
  setValue,
  value,
  disabled,
}) {
  const { t } = useTranslation();
  const autoCompleteRef = useRef();
  const addressRef = useRef(null);

  const [latlong, setLatLong] = useState({
    latitude: "",
    longitude: "",
  });

  useEffect(() => {
    addressRef.current.value = value;
  }, [value]);

  const getAutoCompleteLocationHandler = () => {
    $(document).ready(function () {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        addressRef.current,
        AppConstants.addressOptions
      );
      autoCompleteRef.current.addListener("");
      autoCompleteRef.current.addListener("place_changed", async function () {
        const place = await autoCompleteRef.current.getPlace();
        setLatLong({
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        });
        handleSelection(place, addressRef.current.value);
      });
    });
  };

  return (
    <div className="col-lg-5 col-md-12 col-sm-12">
      <div className="d-flex flex-wrap-sm align-items-center">
        <label className="col-sm-12 col-md-4 mb-0">
          {title}
          {isRequired && <span className="text-danger">*</span>}
        </label>
        <div className="col-md-8 col-sm-12">
          <input
            disabled={disabled}
            className="filter-form"
            ref={addressRef}
            placeholder={value ? value : title}
            onFocus={getAutoCompleteLocationHandler}
            onChange={(e) => {
              if (e.target.value === "") {
                setValue("");
              }
            }}
            style={{ background: disabled ? "#dddd" : "" }}
          />
        </div>
      </div>
      {value && latlong.latitude && (
        <ViewOnGoogle latlong={latlong} padding={" px-2"} />
      )}
    </div>
  );
}

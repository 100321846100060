import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { formattedDate } from "../../../Utilities";

export default function SPProfileField({
  title = "",
  placeholder = "",
  maxLength,
  value = "",
  defaultValue = "",
  type = "text",
  disabled = false,
  isRequired = false,
  onChange = () => {},
  onKeyDown = () => {},
  max = () => {},
  addClass = "",
  validate = false,
}) {
  const [isHidden, setIsHidden] = useState(true);

  const { t } = useTranslation();

  const isFieldDateType = () => {
    return type == "date";
  };

  return (
    <div className="myFlex-cs flex-wrap-sm px-2 pt-2">
      <label className="col-12 col-sm-12 col-md-4">
        {title}
        {isRequired && <span className="text-danger">*</span>}
      </label>
      <div className="col-12 col-sm-12 col-md-8 d-flex flex-row align-items-center">
        <input
          className={`filter-form col-10 ${addClass}`}
          type={!isHidden ? "text" : type}
          maxLength={maxLength}
          value={isFieldDateType() ? formattedDate(value) : value}
          onChange={onChange}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          onKeyDown={onKeyDown}
          max={max}
          isRequired={isRequired}
          style={{ background: disabled ? "#dddd" : "" }}
        />
        {type == "password" && (
          <i
            className={`cursor-pointer ${
              !isHidden ? "ri-eye-line" : "ri-eye-off-line"
            }`}
            onClick={() => setIsHidden(!isHidden)}
            style={{ marginLeft: -30 }} // Chaipi
          />
        )}
      </div>
      {validate && (
        <span style={{ color: "#3db0f7" }}>{t("validatingEmail")}</span>
      )}
    </div>
  );
}

import React, { useState } from "react";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import TimePanel from "../../../pages/TimeSheet/TimePanel";
import Shifts from "../../Staffing/Shifts";
import AppConstants from "../../../Helpers/AppConstants";
import usePrevilagesExist from "../../../Helpers/PrivilegesFuncs";
import WorkTimePatternCard from "./WorkTimePatternCard";

export default function OverViewCard() {
  // Navigation
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Local States
  const [reloadPagech, setReloadPage] = useState(false);

  // Previlages
  const { user, role, userPrevilages } = useSelector(
    (state) => state.AuthenticationReducer
  );
  const { shifts } = useSelector((state) => state.staffing);
  const { userPrevilages: moduleUserPrevilages } = useSelector(
    (state) => state.settings
  );
  const { exist: listShift } = usePrevilagesExist(
    AppConstants.previlages.listShift
  );

  const calculatWhoseInPanelHeight = () => {
    if (document && window) {
      const element = document.getElementById("SummaryCardDiv");
      if (element) {
        return get(element, "offsetHeight", "70vh")
          ? element.offsetHeight + "px"
          : "70vh";
      }
    } else {
      return "70vh";
    }
  };

  const isModuleAllowed = (module) => {
    return moduleUserPrevilages.some((moduleUserPrevilage) => {
      return (
        moduleUserPrevilage.key === module &&
        moduleUserPrevilage.children.some((child) => {
          return userPrevilages.includes(child.key);
        })
      );
    });
  };

  const getCurrentUserId = () => {
    if (role == "Owner") {
      return null;
    } else {
      return get(user, "user_id", null);
    }
  };

  const getFiltteredShifts = () => {
    let finalShifts = [...shifts];
    if (getCurrentUserId() && role != "Owner") {
      finalShifts = finalShifts.filter((shift) => {
        return get(shift, "user_id", "") == getCurrentUserId();
      });
    } else {
      finalShifts = finalShifts.filter((shift) => {
        return get(shift, "user_id", "") == get(user, "user_id", "");
      });
    }

    finalShifts = finalShifts.filter((shift) => {
      return moment.unix(get(shift, "shift_day", "")).isSameOrAfter(moment());
    });
    return finalShifts;
  };

  return (
    <div
      style={{
        maxHeight: calculatWhoseInPanelHeight(),
        overflowY: "auto",
      }}
      data-aos-duration="1500"
      data-aos="fade-right"
      className="col-sm-12 col-md-12 col-lg-8 border-custom overflow-hidden"
    >
      {isModuleAllowed(AppConstants.previlagesModules.timesheet) && (
        <div className="m-2">
          <TimePanel
            addClass="col-lg-12"
            mapRequired={false}
            reloadPage={(value) => setReloadPage(value)}
            refreshStatus={reloadPagech}
          />
        </div>
      )}
      {listShift && !getFiltteredShifts().length > 0 && (
        <div className="m-2">
          <WorkTimePatternCard />
        </div>
      )}
      {listShift && getFiltteredShifts().length > 0 && (
        <div className="m-2">
          <Shifts
            addStyles={{ height: 415, overflow: "auto" }}
            currentUserId={getCurrentUserId()}
          />
        </div>
      )}
      {role == "Owner" && !getFiltteredShifts().length > 0 && (
        <button
          onClick={() =>
            navigate(
              `${AppConstants.routes.staffing}?active_tab=create_staffing`
            )
          }
          className="btn btn-sm-dark ml-2"
        >
          {t("createNewSchedule")}
        </button>
      )}
    </div>
  );
}

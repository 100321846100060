import React, { useState, useEffect } from "react";
import { unixToDateFormater, unixToDateFormaterOld } from "../../../Utilities";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Applogger } from "../../../Helpers/Logger";
import {
  getMandatoryLeaves,
  getCalenderPendingRequest,
  publicHolidaysRequest,
} from "../../../Redux/reducers/CalenderReducer";
import moment from "moment";
import AppConstants from "../../../Helpers/AppConstants";

export default function CalendarCard() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { lng } = useSelector((state) => state.languageReducer);
  const { token } = useSelector((state) => state.AuthenticationReducer);
  const { publicHolidays, mandatoryLeaves, pendingRequests } = useSelector(
    (state) => state.calender
  );

  const [localPublicHolidays, setLocalPublicHolidays] = useState([]);

  useEffect(() => {
    getMandatoryLeaveHandler();
    getPublicHolidaysRequest();
    getPendingRequests();
  }, []);

  useEffect(() => {
    filterPublicHolidays();
  }, [publicHolidays]);

  const filterPublicHolidays = () => {
    const dateToMatch = moment(new Date()).format(
      AppConstants.defaultDateFormat
    );
    let finalArray = publicHolidays;
    finalArray = finalArray.filter((holiday) => {
      return moment(get(holiday, "start_date", "")).isSameOrAfter(dateToMatch);
    });
    setLocalPublicHolidays(finalArray);
  };

  const getMandatoryLeaveHandler = () => {
    try {
      dispatch(getMandatoryLeaves({ token, lng }))
        .then(unwrapResult)
        .then((res) => {
          Applogger("Response at getMandatoryLeaves", res);
        })
        .catch((err) => {
          Applogger("Error at getMandatoryLeaves", err);
        });
    } catch (err) {
      Applogger("Error at getMandatoryLeaveHandler", err);
    }
  };

  const getPendingRequests = () => {
    dispatch(getCalenderPendingRequest({ token, lng: lng }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getCalenderPendingRequest", res);
      })
      .catch((err) => {
        Applogger("Error at getCalenderPendingRequest", err);
      });
  };

  const getPublicHolidaysRequest = () => {
    dispatch(publicHolidaysRequest({ token, lng }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at publicHolidaysRequest", res);
      })
      .catch((err) => {
        Applogger("Error at publicHolidaysRequest", err);
      });
  };

  if (localPublicHolidays.length > 0) {
    return (
      <>
        <div className="align-items-center d-flex">
          <span>
            <i
              className="ri-sun-line"
              style={{ fontSize: "1.5rem", color: "#3caff6" }}
            />
          </span>
          <h6 className="mb-0 fw-bold ml-1">{t("upcomingHolidaysMsg")}</h6>
        </div>
        {localPublicHolidays.slice(0, 2).map((holiday, index) => {
          const { name, start_date, end_date } = holiday;
          return (
            <div key={index} className=" align-items-center gap-2 mb-2">
              <div className="align-items-center d-flex">
                <p className="capitalize mb-1 fw-bold">{name}</p>
              </div>
              <p className="mb-1" style={{ color: "gray" }}>
                {`${t("dated")}: ${moment(start_date).format(
                  AppConstants.defaultDateFormat
                )} - ${moment(end_date).format(
                  AppConstants.defaultDateFormat
                )}`}
              </p>
            </div>
          );
        })}
      </>
    );
  } else if (pendingRequests.length > 0) {
    return (
      <>
        <div className="align-items-center d-flex">
          <span>
            <i
              className="ri-sun-line"
              style={{ fontSize: "1.5rem", color: "#3caff6" }}
            />
          </span>
          <h6 className="mb-0 fw-bold ml-1">{t("pendingRequests")}</h6>
        </div>
        {pendingRequests.slice(0, 2).map((holiday, index) => {
          const {
            end_type,
            start_type,
            absence_type_name,
            start_date,
            end_date,
            start_half_day_type,
            end_half_day_type,
          } = holiday;
          return (
            <div key={index} className=" align-items-center gap-2 mb-2">
              <div className="align-items-center d-flex">
                <p className="capitalize mb-1 fw-bold">{absence_type_name}</p>
              </div>
              Starts :{" "}
              <span style={{ color: "gray" }}>
                {unixToDateFormaterOld(start_date, AppConstants.dateFormat)} -{" "}
                {`${
                  start_type &&
                  ` ${start_type}${
                    start_type !== "Full Day" ? `(${start_half_day_type})` : ""
                  }`
                }`}
              </span>
              <br />
              Ends :{" "}
              <span style={{ color: "gray" }}>
                {unixToDateFormaterOld(end_date, AppConstants.dateFormat)} -{" "}
                {end_type &&
                  ` ${end_type}${
                    end_type !== "Full Day" ? `(${end_half_day_type})` : ""
                  }`}
              </span>
            </div>
          );
        })}
      </>
    );
  } else if (mandatoryLeaves.length > 0) {
    return (
      <>
        <div className="align-items-center d-flex">
          <span>
            <i
              className="ri-sun-line"
              style={{ fontSize: "1.5rem", color: "#3caff6" }}
            />
          </span>
          <h6 className="mb-0 fw-bold ml-1">{t("mandatoryLeave")}</h6>
        </div>
        {mandatoryLeaves.slice(0, 2).map((holiday, index) => {
          const { name, start_date, end_date } = holiday;
          return (
            <div key={index} className=" align-items-center gap-2 mb-2">
              <div className="align-items-center d-flex">
                <p className="capitalize mb-1 fw-bold">{name}</p>
              </div>
              <p className="mb-1" style={{ color: "gray" }}>
                {`${t("dated")}: ${unixToDateFormaterOld(
                  start_date,
                  AppConstants.defaultDateFormat
                )} - ${unixToDateFormaterOld(
                  end_date,
                  AppConstants.defaultDateFormat
                )}`}
              </p>
            </div>
          );
        })}
      </>
    );
  } else {
    return <p>{t("noCalendarData")}</p>;
  }
}

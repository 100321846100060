import React, { useEffect } from "react";
import { Applogger } from "../../Helpers/Logger";
import { useSelector, useDispatch } from "react-redux";
import { publicHolidaysRequest } from "../../Redux/reducers/CalenderReducer";
import { unwrapResult } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import PublicHolidayCell from "./PublicHolidayCell";

export default function PublicHolidays() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { lng } = useSelector((state) => state.languageReducer);
  const { token } = useSelector((state) => state.AuthenticationReducer);
  const { publicHolidays } = useSelector((state) => state.calender);

  useEffect(() => {
    if (token) {
      if (!publicHolidays.length > 0) {
        getPublicHolidaysRequest();
      }
    }
  }, []);

  const getPublicHolidaysRequest = () => {
    dispatch(publicHolidaysRequest({ token, lng }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at publicHolidaysRequest", res);
      })
      .catch((err) => {
        Applogger("Error at publicHolidaysRequest", err);
      });
  };

  return (
    <div>
      <div className="d-flex justify-content-between mb-2 flex-wrap">
        <h4 className=" subheading_2 fw-bold text-capitalize ">{`${t(
          "publicHolidays"
        )} (${publicHolidays?.length})`}</h4>
      </div>
      {/* <p>{t("publicHolidaysDesc")}</p> */}
      {/* <hr /> */}
      {Array.isArray(publicHolidays) ? (
        publicHolidays.map((holiday, index) => {
          return <PublicHolidayCell key={index} holiday={holiday} />;
        })
      ) : (
        <h4>{t("noLeaveFound")}</h4>
      )}
    </div>
  );
}

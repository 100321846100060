import React, { useState } from "react";
import { createStaffing } from "../../Redux/reducers/StaffingReducer";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showFaliureToast, showSuccessToast } from "../../Utilities";
import { useTranslation } from "react-i18next";
import { DatePicker } from "antd";
import { Applogger } from "../../Helpers/Logger";
import AppConstants from "../../Helpers/AppConstants";
import moment from "moment";

export default function Create() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { token } = useSelector((state) => state.AuthenticationReducer);

  const calendarMonthID = "15";
  const [staffingName, setStaffingName] = useState("");
  const [staffingDuration, setStaffingDuration] = useState(7);
  const [staffingStartDate, setStaffingStartDate] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  // const [staffingView, setStaffingView] = useState([
  //   {
  //     id: 3,
  //     title: "Table View",
  //     subtitle:
  //       "Set your shift times and easily assign this across employees and dates at once, with a click.",
  //     active: true,
  //   },
  // ]);

  // function changeStaffingView(data) {
  //   const state = [...staffingView];
  //   for (let i = 0; i < state.length; i++) {
  //     state[i].active = false;
  //   }
  //   const index = state.findIndex((i) => i.id === data.id);
  //   state[index] = { ...state[index], active: true };
  //   setStaffingView(state);
  // }

  const createNewStaffing = (e) => {
    e.preventDefault();
    const startValue =
      staffingDuration == calendarMonthID
        ? moment(selectedMonth).format(AppConstants.monthYearFormat)
        : staffingStartDate;
    var body = {
      name: staffingName,
      start_date:
        staffingDuration == calendarMonthID
          ? moment(selectedMonth).format(AppConstants.monthYearFormat)
          : staffingStartDate,
      duration: staffingDuration == calendarMonthID ? "" : staffingDuration,
      created_by: 1,
      is_calender_month: staffingDuration == calendarMonthID ? "1" : "0",
      token: token,
    };
    if (staffingName !== "" && startValue !== "" && staffingDuration !== "") {
      dispatch(createStaffing(body))
        .then(unwrapResult)
        .then((res) => {
          Applogger("Response at createStaffing", res);
          showSuccessToast("Staffing created Successfully");
          navigate(
            `${AppConstants.routes.staffingSheet}?schedule=${res.rota_id}`
          );
        })
        .catch(() => showFaliureToast("Schedule creation failed"));
    } else {
      showFaliureToast("Please fill all the fields");
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-6">
          <p
            className="mt-4"
            style={{ fontWeight: "bold", fontSize: 15, margin: 0 }}
          >
            {t("createNewSchedule")}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-12 col-sm-12">
          <form className="mt-2">
            <label className="fs-6 fw-light">{t("scheduleName")}</label>
            <br />
            <input
              type="text"
              value={staffingName}
              onChange={(e) => setStaffingName(e.target.value)}
              placeholder={t("scheduleName")}
              className="filter-form"
            />

            <label className="fs-6 fw-light">{t("scheduleDuration")}</label>
            <br />
            <select
              defaultValue={7}
              value={staffingDuration}
              onChange={(e) => setStaffingDuration(e.target.value)}
              className="filter-form"
            >
              {staffingDurations.map((item, index) => {
                return (
                  <option key={index} value={item.value}>
                    {item.title}
                  </option>
                );
              })}
            </select>

            <label className="fs-6 fw-light">
              {staffingDuration !== calendarMonthID
                ? t("startDate")
                : "Staffing Start Month"}
            </label>
            <br />
            {staffingDuration !== calendarMonthID && (
              <input
                onChange={(e) => setStaffingStartDate(e.target.value)}
                type="date"
                placeholder={t("selectDate")}
                className="filter-form"
                // min="2050-01-01"
                max={AppConstants.maxDate}
              />
            )}
            {staffingDuration === calendarMonthID && (
              // <input
              //   value={selectedMonth}
              //   onChange={(e) => setSelectedMonth(e.target.value)}
              //   type={"month"}
              //   className="filter-form"
              // />
              <DatePicker
                className="filter-form d-flex"
                onChange={(value) => setSelectedMonth(value)}
                format={AppConstants.monthYearFormat}
                value={selectedMonth}
                picker="month"
              />
            )}
          </form>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-6">
          {/* <h5 className="fw-bold my-3" style={{ margin: 0 }}>
            {t("selectYourStaffingView")}
          </h5> */}
        </div>
      </div>
      <div className="row">
        {/* {staffingView.map((i, index) => {
          return (
            <StaffingCheckbox
              key={index}
              onChange={(e) => changeStaffingView(i)}
              checked={i.active}
              active={i.active}
              title={i.title}
              id={i.id}
              subtitle={i.subtitle}
            />
          );
        })} */}
        <div>
          <button
            id="btn"
            onClick={(e) => createNewStaffing(e)}
            className="btn btn-primary-cs mt-2 d-flex"
          >
            {t("submit")}
          </button>
        </div>
      </div>
    </div>
  );
}

const staffingDurations = [
  {
    title: "1 Day",
    value: 1,
  },
  {
    title: "2 Days",
    value: 2,
  },
  {
    title: "3 Days",
    value: 3,
  },
  {
    title: "4 Days",
    value: 4,
  },
  {
    title: "5 Days",
    value: 5,
  },
  {
    title: "6 Days",
    value: 6,
  },
  {
    title: "7 Days",
    value: 7,
  },
  {
    title: "8 Days",
    value: 8,
  },
  {
    title: "9 Days",
    value: 9,
  },
  {
    title: "10 Days",
    value: 10,
  },
  {
    title: "11 Days",
    value: 11,
  },
  {
    title: "12 Days",
    value: 12,
  },
  {
    title: "13 Days",
    value: 13,
  },
  {
    title: "14 Days",
    value: 14,
  },
  {
    title: "Calender Month",
    value: 15,
  },
];

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeUserProfileStatus,
  deleteUserProfileImage,
  getUserProfile,
  updateUserImage,
} from "../../../Redux/reducers/ProfileReducers";
import { unwrapResult } from "@reduxjs/toolkit";
import { Applogger } from "../../../Helpers/Logger";
import { AppImages } from "../../../Helpers/AppImages";
import { showFaliureToast, showSuccessToast } from "../../../Utilities";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import UserStatusModal from "./UserStatusModal";
import Spinner from "../../Spinner";
import AppConstants from "../../../Helpers/AppConstants";

export default function ProfileDetails({ currentUserId }) {
  const dispatch = useDispatch();
  const inputFile = useRef(null);

  const { token, user } = useSelector((state) => state.AuthenticationReducer);

  const {
    loading,
    user: profileUser,
    userEmployementRoleInfo,
  } = useSelector((state) => state.profile);
  const { lng } = useSelector((state) => state.languageReducer);
  const userId = get(user, "user_id", "");
  const pending = "pending";
  const { t } = useTranslation();
  const [userStatusModal, setUserStatusModal] = useState(false);

  useEffect(() => {
    if (currentUserId) {
      getProfileData(currentUserId);
    } else {
      getProfileData(user.user_id);
    }
  }, [currentUserId]);

  const onProfileImageClickHandler = () => {
    // `current` points to the mounted file input element
    document.getElementById("file-image").value = "";
    inputFile.current.click();
  };
  const getProfileData = (id) => {
    dispatch(getUserProfile({ bearer_token: token, user_id: id, lng }))
      .then(unwrapResult)
      .then((response) => {
        // setCurrentUser(response.data);
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
        }
      });
  };

  const handleClearStatus = () => {
    setUserStatus(t("not_working"));
  };

  const changeHandler = (event) => {
    let fileName = event.target.files[0];

    dispatch(
      updateUserImage({
        token,
        id: currentUserId ? currentUserId : get(profileUser, "user_id", ""),
        image: fileName,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        showSuccessToast("Profile Image uploaded successfully");
        if (currentUserId) {
          getProfileData(currentUserId);
        } else {
          getProfileData(user.user_id);
        }
      })
      .catch((err) => {
        showFaliureToast("Profile Image upload failed");
      });
  };

  const setUserStatus = (userStatus) => {
    var userId = currentUserId !== user.user_id ? currentUserId : null;
    let data = {
      token: token,
      currentStatus: userStatus
        ? userStatus
        : get(profileUser, "working_status", ""),
      userId: userId,
    };

    dispatch(changeUserProfileStatus(data))
      .then(unwrapResult)
      .then((res) => {
        getProfileData(currentUserId);
        setUserStatusModal(false);
      })
      .catch((err) => {
        Applogger("Error at changeUserProfileStatus", err.message);
        setUserStatusModal(false);
      });
  };

  const deleteUserProfileImageHandler = () => {
    dispatch(deleteUserProfileImage({ token, id: currentUserId ?? userId }))
      .then(unwrapResult)
      .then((res) => {
        showSuccessToast(
          res?.data?.message ?? "Profile image removed successfully"
        );
        Applogger("Response at deleteUserProfileImage", res);
        getProfileData(currentUserId);
      })
      .catch((err) => {
        showFaliureToast("Failed to delete profile image");
        Applogger("Erorr at deleteUserProfileImage", err);
      });
  };

  const displayContactNumber = () => {
    if (get(profileUser, "mobile_phone", "")) {
      return (
        <a
          href={`tel: ${get(profileUser, "mobile_phone", "")}`}
          className="text-primary mb-0 d-flex align-items-center justify-content-center"
        >
          <i className="fs-5 ri-phone-line me-2" />
          <p className="text-black-50 mb-0 mx-1">{t("mobilePhone")}:</p>
          {get(profileUser, "mobile_phone", "")}
        </a>
      );
    } else if (get(profileUser, "work_phone", "")) {
      return (
        <a
          href={`tel: ${get(profileUser, "work_phone", "")}`}
          className="text-primary mb-0 d-flex align-items-center justify-content-center"
        >
          <i className="fs-5 ri-phone-line me-2" />
          <p className="text-black-50 mb-0 mx-1">{t("workPhone")}:</p>
          {get(profileUser, "work_phone", "")}
        </a>
      );
    } else if (get(profileUser, "home_phone", "")) {
      return (
        <a
          href={`tel: ${get(profileUser, "home_phone", "")}`}
          className="text-primary mb-0 d-flex align-items-center justify-content-center"
        >
          <i className="fs-5 ri-phone-line me-2" />
          <p className="text-black-50 mb-0 mx-1">{t("homePhone")}:</p>
          {get(profileUser, "home_phone", "")}
        </a>
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      {loading === pending && <Spinner />}
      <div className="border-custom flex-wrap-sm p-4 gap-4">
        <div className="d-flex justify-content-between profile-details-section">
          <div>
            <div className="profile-lg main-profile-picture d-inline-block position-relative">
              {get(profileUser, "profile_image", "") ? (
                <img
                  className="main-profile-picture-image"
                  src={get(profileUser, "profile_image", "")}
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                  alt={`${get(profileUser, "first_name", "")} ${get(
                    profileUser,
                    "last_name",
                    ""
                  )}`}
                />
              ) : (
                loading !== "pending" && (
                  <img
                    src={AppImages.no_image}
                    className="main-profile-picture-image"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                )
              )}
              <button
                onClick={onProfileImageClickHandler}
                className="upload-profile-picture cursor-pointer"
              >
                <i className="ri-upload-2-line" />
              </button>
              <input
                onChange={changeHandler}
                accept="image/png, image/jpg, image/jpeg"
                type="file"
                id="file-image"
                ref={inputFile}
                style={{ display: "none" }}
              />
            </div>
            {get(profileUser, "profile_image", "") && (
              <button
                // style={{backgroundColor:"red"}}
                onClick={deleteUserProfileImageHandler}
                // onClick={onProfileImageClickHandler}
                className="cursor-pointer"
              >
                <i className="ri-delete-bin-line text-danger" />
              </button>
            )}
          </div>
          <div className="d-flex flex-column align-items-center justify-content-end w-100">
            {get(profileUser, "first_name", "") && (
              <h1 className="mb-0" style={{ textAlign: "center" }}>
                {get(profileUser, "first_name", "") +
                  " " +
                  get(profileUser, "last_name", "")}
              </h1>
            )}
            {userEmployementRoleInfo && userEmployementRoleInfo.job_title && (
              <h4>{`${userEmployementRoleInfo.job_title}`}</h4>
            )}
            <div className="profile_name_email">
              <div>
                {displayContactNumber()}
                <a
                  href={`mailto: ${
                    get(profileUser, "work_email", "")
                      ? get(profileUser, "work_email", "")
                      : get(profileUser, "email", "")
                  }`}
                  className="text-primary mb-0 d-flex align-items-center justify-content-center"
                >
                  <i className="fs-5 ri-mail-line me-2" />
                  <p className="text-black-50 mb-0 mx-1">{t("email")}:</p>
                  {get(profileUser, "work_email", "")
                    ? get(profileUser, "work_email", "")
                    : get(profileUser, "email", "")}
                </a>
              </div>
            </div>
            <p className="d-flex align-items-center justify-center">
              <i className="text-pink me-2 ri-home-4-line fs-5" />
              <p className="text-black-50 mb-0 mx-1">{t("status")}:</p>
              {t(get(profileUser, "working_status", ""))}
            </p>
          </div>
          {/* <div className="d-flex align-items-end justify-content-end">
            <div
              className="setStatus_typo rounded  border-1"
              style={{ padding: "0.5rem 1rem", minWidth: "325px" }}
            >
              <p className="d-flex align-items-center justify-center">
                <i className="text-pink fs-4 me-2 ri-home-4-line fs-5" />
                {t(get(profileUser, "working_status", ""))}
              </p>
              <div
                className={`myFlex-cs ${get(profileUser, "working_status", "") ==
                  AppConstants.workingStatus.not_working
                  ? "justify-center"
                  : "justify-between"
                  }`}
              >
                <button
                  className="text-primary"
                  onClick={() => {
                    setUserStatusModal(true);
                  }}
                >
                  {`${get(profileUser, "working_status", "") ==
                    AppConstants.workingStatus.not_working
                    ? t("addStatus")
                    : t("editStatus")
                    } `}
                </button>
                &nbsp;
                {get(profileUser, "working_status", "") &&
                  get(profileUser, "working_status", "") !=
                  AppConstants.workingStatus.not_working && (
                    <p
                      className="mb-0 text-primary cursor-pointer"
                      onClick={() => handleClearStatus()}
                    >
                      {t("clearStatus")}
                    </p>
                  )}
              </div>
            </div>
          </div> */}
          {/*  Chaipi */}
          <div>
            <div className="profile-lg main-profile-picture d-inline-block position-relative empty-box" />
          </div>
        </div>
        <UserStatusModal
          modalStatus={userStatusModal}
          onCancel={() => setUserStatusModal(false)}
          setStatus={(status) => setUserStatus(status)}
          changeStatus={(e) => Applogger("Not Need Function")}
          status={get(profileUser, "working_status", "")}
        />
      </div>
    </div>
  );
}

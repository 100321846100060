import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import { employeeTypes } from "./FormConstants";
import { formattedDateForAPI } from "../../../Utilities";
import EmplDropDown from "./Components/EmplDropDown";
import EmpDetailsField from "./Components/EmpDetailsField";
import useLocalisedConstants from "../../../customHooks/useLocalisedConstants";
import AppConstants from "../../../Helpers/AppConstants";

export default function RoleAndLocation({
  employeeForm,
  setEmployeeForm,
  currentType,
}) {
  const { t } = useTranslation();

  const appConstants = useLocalisedConstants();
  const { roles, hourlyRoles, dailyRoles, placesOfWork, rolesSettings } =
    useSelector((state) => state.settings);
  const { countries } = useSelector((state) => state.employees);

  const [countriesLocal, setCountriesLocal] = useState([]);
  const [localWorkPlaces, setLocalWorkPlaces] = useState([]);

  useEffect(() => {
    if (countries) {
      setCountriesLocal(countries);
    }
  }, [countries]);

  useEffect(() => {
    if (placesOfWork) {
      setLocalWorkPlaces(placesOfWork);
    }
  }, [placesOfWork]);

  const filteredRoles = () => {
    let filtterArray = [...roles];

    if (currentType == employeeTypes.days) {
      filtterArray = dailyRoles.filter((item) =>
        rolesSettings.find((settingItem) => settingItem.role_id == item.role_id)
      );
    }
    if (currentType == employeeTypes.hours) {
      filtterArray = hourlyRoles.filter((item) =>
        rolesSettings.find((settingItem) => settingItem.role_id == item.role_id)
      );
    }
    return filtterArray;
  };

  useEffect(() => {
    if (currentType != null) {
      setEmployeeForm({
        ...employeeForm,
        role: null,
        role_name: null,
      });
    }
  }, [currentType]);

  const handleChangeLocationType = (value) => {
    const workPlaceType = AppConstants.placeOfWorkArray.find(
      (item) => item.work_place_id == get(value, "work_place_id", "")
    );

    setEmployeeForm({
      ...employeeForm,
      work_place_id: workPlaceType ? "" : get(value, "work_place_id", ""),
      work_place_type: workPlaceType ? get(value, "work_place_id", "") : "",
    });
  };

  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-between gap-2 col-md-12 col-sm-12">
        <div className="d-flex gap-2 col-lg-12 col-md-12 col-sm-12 flex-wrap justify-between">
          {/* <EmpDetailsField
            title={t("jobTitle")}
            placeholder={t("jobTitle")}
            type={"text"}
            value={employeeForm.job_title}
            isRequired={true}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                job_title: e.target.value,
              })
            }
          /> */}
          <EmplDropDown
            title={t("selectRoleDesc")}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                role: get(JSON.parse(e.target.value), "role_id", ""),
                role_name: get(JSON.parse(e.target.value), "name", ""),
              })
            }
            dataArray={filteredRoles()}
            displayKey="name"
            valueKey="role_id"
            isRequired={true}
            children={
              <option value={null} selected={true} disabled={true}>
                {t("selectRole")}
              </option>
            }
          />
          <EmplDropDown
            title={t("selectWorkPlaceDesc")}
            onChange={(e) => {
              handleChangeLocationType(JSON.parse(e.target.value));
            }}
            dataArray={localWorkPlaces}
            displayKey="name"
            valueKey="work_place_id"
            isRequired={true}
            children={
              <option value={null} selected={true} disabled={true}>
                {t("selectWorkPlace")}
              </option>
            }
          />
          <EmplDropDown
            title={t("selectCountryDesc")}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                country_id: get(JSON.parse(e.target.value), "country_id", ""),
                country_name: get(JSON.parse(e.target.value), "name", ""),
              })
            }
            dataArray={countriesLocal}
            displayKey="name"
            valueKey="country_id"
            isRequired={true}
            children={
              <option value={null} selected={true} disabled={true}>
                {t("selectCountry")}
              </option>
            }
          />
          <EmplDropDown
            title={t("directorStatus")}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                director_status: get(JSON.parse(e.target.value), "value", ""),
              })
            }
            dataArray={appConstants.directorStatus}
            displayKey="title"
            valueKey="value"
            isRequired={false}
          />
          <EmpDetailsField
            title={t("dateDirectorshipBegan")}
            placeholder={t("dateDirectorshipBegan")}
            type={"date"}
            value={employeeForm.date_directorship_began}
            isRequired={false}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                date_directorship_began: formattedDateForAPI(e.target.value),
              })
            }
          />
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Button from "../../Helper/SimpleButton";
import IconButton from "../../Helper/IconButton";
import AppConstants from "../../../Helpers/AppConstants";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import usePrevilagesExist from "../../../Helpers/PrivilegesFuncs";

export default function Absence({
  latness,
  allowedDays,
  RemainingDays,
  sicknessLeave,
  nextLeave,
}) {
  const navigate = useNavigate();
  const { exist: createAnnualLeave } = usePrevilagesExist(
    AppConstants.previlages.createAnnualLeave
  );

  const { overTimeOff } = useSelector((state) => state.profile);
  const { absenceSummary } = useSelector((state) => state.DashboardReducer);
  const { user } = useSelector((state) => state.AuthenticationReducer);
  const { t } = useTranslation();
  const [hours, setHours] = useState(null);
  const [mins, setMins] = useState(null);
  const [allowedData, setAllowedData] = useState({
    allowedHours: 0,
    allowedMins: 0,
  });

  const [remainingData, setRemainingData] = useState({
    remainigHours: 0,
    remainigMins: 0,
  });

  const [approvedCount, setApprovedCount] = useState(null);

  const userType = get(user, "user_type", "");
  const absenceType = get(nextLeave, "absence_type", "");
  const startDate = get(nextLeave, "start_date", 0);
  const pendingLeaves = get(absenceSummary, "pending_leaves_duration", "");

  useEffect(() => {
    if (overTimeOff) {
      setHours(overTimeOff.hours);
      setMins(overTimeOff.mins);
      setApprovedCount(overTimeOff.count);
    }
  }, [overTimeOff]);

  useEffect(() => {
    if (user) {
      if (user.user_type && user.user_type == "hours") {
        if (allowedDays) {
          const hours = allowedDays / 60;
          const minutes = allowedDays % 60;
          setAllowedData({
            ...allowedData,
            allowedHours: parseInt(hours),
            allowedMins: minutes,
          });
        }

        if (RemainingDays) {
          const hours = RemainingDays / 60;
          const minutes = RemainingDays % 60;
          setRemainingData({
            ...remainingData,
            remainigHours: parseInt(hours),
            remainigMins: minutes,
          });
        }
      } else {
        setAllowedData({
          ...allowedData,
          allowedHours: allowedDays,
          // allowedMins: minutes,
        });
        if (RemainingDays) {
          const hours = RemainingDays;
          const minutes = RemainingDays;
          setRemainingData({
            ...remainingData,
            remainigHours: parseInt(hours),
            remainigMins: minutes,
          });
        }
      }
    }
  }, [user, absenceSummary]);

  const PendingLeaveHandler = () => {
    if (userType == "hours") {
      var pendingLeavesHours = Math.trunc(pendingLeaves / 60);
      var pendingLeavesMinutes = pendingLeaves % 60;

      if (pendingLeavesHours > 0 || pendingLeavesMinutes > 0) {
        return (
          <p className="mb-0  fw-bold capitalize mt-2">{`${pendingLeavesHours} hours ${pendingLeavesMinutes} minutes leaves is pending to be accepted.`}</p>
        );
      } else {
        return "";
      }
    } else {
      if (pendingLeaves > 0) {
        return (
          <p className="mb-0  fw-bold capitalize mt-2">
            {`${pendingLeaves} days leave is pending to be accepted`}
          </p>
        );
      } else {
        return "";
      }
    }
  };
  return (
    <div>
      {createAnnualLeave && (
        <Button
          onClick={() =>
            navigate(
              `${AppConstants.routes.absence}?type=${AppConstants.absenceTypes.time_off}&page=dashboard`
            )
          }
          btnPink
          btnBlock
          text={t("reqTimeOff")}
        />
      )}
      <div className="d-flex py-3 flex-wrap">
        <div className="d-flex justify-content-center align-items-center">
          <div className="box-cs-1">
            <div className="fill" style={{ fontSize: "1.6rem" }}>
              {remainingData.remainigHours}
            </div>
          </div>
        </div>
        <div
          className="w-50 text-black"
          style={{ marginLeft: 10, marginTop: 10 }}
        >
          <p className="text_sm_cs">
            {userType === "hours" ? (
              <strong>{`${
                remainingData.remainigHours && remainingData.remainigHours > 0
                  ? remainingData.remainigHours
                  : 0
              } ${t("hrs")} ${
                remainingData.remainigMins && remainingData.remainigMins > 0
                  ? remainingData.remainigMins
                  : 0
              } ${t("min")} `}</strong>
            ) : (
              <strong>{`${
                remainingData.remainigHours && remainingData.remainigHours > 0
                  ? remainingData.remainigHours
                  : 0
              }  ${t("days")}`}</strong>
            )}{" "}
            {t("remaining")}
          </p>
          <p className="text_sm_cs">
            {userType === "hours" ? (
              <strong>{`${
                allowedData.allowedHours ? allowedData.allowedHours : 0
              } ${t("hrs")} ${
                allowedData.allowedMins ? allowedData.allowedMins : 0
              } ${t("min")} `}</strong>
            ) : (
              <strong>{`${
                allowedData.allowedHours ? allowedData.allowedHours : 0
              } ${t("days")}`}</strong>
            )}{" "}
            {t("allowance")}
          </p>
        </div>
      </div>

      <div className="row  mt-2 ">
        <div className="col-md-12 text-black simple_text_2">
          <p className="mb-0">{`${t("nextUp")} - ${
            absenceType !== "N/A" ? absenceType : `${t("noLeaveFound")}`
          }`}</p>
          <p className="mb-0 text-primary fw-bold">
            {absenceType && (
              <b>
                {startDate !== "N/A"
                  ? moment(startDate).format(AppConstants.dateFormat)
                  : ""}
              </b>
            )}
          </p>
        </div>
        <PendingLeaveHandler />
        <br />
      </div>
      <div className="overtime_subhead mb-2 mt-5">
        <div className="w-10 ps-2 d-flex align-items-start justify-content-center">
          <i className="ri-history-line i-med fw-light text-info me-2" />
        </div>
        <div className="w-75 ms-2">
          <h5 className="font-weight-bold text-black mb-0">{t("timeOff")}</h5>
          <p className="mb-0 font-weight-light fs-18">{`${
            hours != null ? hours : 0
          }h ${mins != null ? mins : 0}m`}</p>
          <p>
            <strong className="small">TOIL {t("taken")}</strong>
          </p>
        </div>
      </div>
      <br />

      <div className="sidebar_alert_bottom">
        <p>
          {t("youHave")}
          <b>{` ${approvedCount !== null ? approvedCount : 0}`}</b>{" "}
          {t("overtimeClaimsApproved")}
        </p>
      </div>
      <br />
      <div className="mt-2 mb-0">
        <p className="text-black simple_text_2">{t("youAlsoTaken")}</p>
      </div>

      <div className="d-flex justify-content-evenly gap-2">
        <IconButton
          count={latness}
          className="lateness"
          iconName="ri-timer-2-line"
          title={t("lateness")}
        />
        <IconButton
          count={sicknessLeave}
          className="sickness"
          iconName="ri-first-aid-kit-line"
          title={t("sickness")}
        />
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import { useSelector } from "react-redux";
import { AppImages } from "../../Helpers/AppImages";
import { get } from "lodash";
import moment from "moment";
import AppConstants from "../../Helpers/AppConstants";

export const ComponentToPrint = React.forwardRef((props, ref) => {
  const [currentData, setCurrentData] = useState({
    Day: "",
    duration: "",
    employeeCount: "",
    status: "",
    days: "",
    rotaId: "",
    name: "",
  });

  const [printData, setPrintData] = useState([]);
  const { t } = useTranslation();
  const { lng: selectedLanguage } = useSelector(
    (state) => state.languageReducer
  );
  const { user, role } = useSelector((state) => state.AuthenticationReducer);
  const { companySettingsOB } = useSelector((state) => state.settings);
  useEffect(() => {
    if (props.printData) {
      const { currentStaff } = props.printData;
      const data = [];
      if (currentStaff) {
        currentData.Day = currentStaff.start_date;
        currentData.duration = currentStaff.duration;
        currentData.employeeCount = props.printData.employeeCount;
        currentData.status = props.printData.modalButtonTitle;
        currentData.days = props.printData.days;
        currentData.name = props.printData.shift;
      }

      data.push(currentData);
      setPrintData(data);
      setCurrentData({ ...currentData });
    }
  }, [props.printData]);

  const firstName = get(user, "first_name", "");
  const lastName = get(user, "last_name", "");

  const companyTitle = get(companySettingsOB, "company_title", "");
  const companyAddress = get(companySettingsOB, "hq_address", "");
  const columns = [
    {
      title: t("rotaName"),
      dataIndex: "name",
      key: "name",
      render: (role) => (
        <a style={{ textTransform: "capitalize" }}>{role ?? "-"}</a>
      ),
    },
    {
      title: t("rotaDay"),
      dataIndex: "Day",
      key: "Day",
      render: (role) => (
        // <a>{`${unixToDateFormater(role, "dddd MMMM YYYY")}`}</a>
        <a>{`${moment.unix(role).format(AppConstants.dateFormat)}`}</a>
      ),
    },
    {
      title: t("rotaDuration"),
      dataIndex: "duration",
      key: "duration",
      render: (role) => <a>{role ?? "-"}</a>,
    },
    {
      // title: t("rotaStatus"),
      title: t("scheduleStatus"),
      dataIndex: "status",
      key: "status",
      render: (role) => (
        <a>{role == "Publish" ? "Un Published" : "Published"}</a>
      ),
    },
  ];

  return (
    <div className="px-5 mt-2 w-100" ref={ref}>
      {/* <h4>{t("roleInfo")}</h4> */}

      <div
        className={`dropdown w-100 ${
          selectedLanguage == "ar" ? "nav-brand-ar" : "nav-brand"
        }`}
        style={{ width: "100%" }}
      >
        <img
          src={AppImages.speedyHrLogo}
          style={{ height: "100%", width: "100%", objectFit: "contain" }}
          className="mt-2"
          alt="SpeedyHR"
        />
      </div>
      <div className="mt-5">
        <h2
          style={{ color: "#3498db", fontWeight: "bold", textAlign: "center" }}
        >
          {/* {t("staffingDetails")} */}
          {t("scheduleDetails")}
        </h2>
      </div>
      <Table
        className="mt-5"
        pagination={false}
        columns={columns}
        dataSource={printData}
      />
      <div className="mt-5">
        <p
          style={{
            fontSize: 15,
            fontWeight: "bold",
            textTransform: "capitalize",
          }}
        >
          <span style={{ color: "#3498db" }}>{`${t("generatedBy")}: `}</span>
          {firstName} {lastName} {role ? `(${role})` : ""}
        </p>
      </div>
      <p
        style={{
          fontSize: 15,
          fontWeight: "bold",
          textTransform: "capitalize",
        }}
      >
        <span style={{ color: "#3498db" }}>{`${t("dated")}: `}</span>
        {moment(new Date()).format(AppConstants.dateFormat)}
      </p>
      <p
        className="mt-2"
        style={{
          fontSize: 15,
          fontWeight: "bold",
          textTransform: "capitalize",
        }}
      >
        <span style={{ color: "#3498db" }}>{` ${t("companyName")}: `}</span>
        {companyTitle}
      </p>
      <p
        style={{
          fontSize: 15,
          fontWeight: "bold",
          textTransform: "capitalize",
        }}
      >
        <span style={{ color: "#3498db" }}>{` ${t("companyAddress")}: `}</span>
        {companyAddress}
      </p>

      <div
        style={{
          height: 100,
          background: "#ebf7ff",
          width: "100%",
          position: "absolute",
          bottom: 0,
          justifyContent: "center",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          right: 0,
        }}
      >
        <p style={{ fontSize: 15, fontWeight: "bold" }}>
          <i style={{ fontSize: 15 }} className="ri-copyright-line mr-2" />
          {`${t("copyright")} SpeedyHR ${moment().format("YYYY")}`}
        </p>
      </div>
    </div>
  );
});

import React, { useState, useEffect } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Applogger } from "../../Helpers/Logger";
import { get } from "lodash";
import { getPlacesOfWork } from "../../Redux/reducers/SettingsReducer";
import {
  getCountries,
  employeesRequest,
} from "../../Redux/reducers/EmployeesReducer";
import {
  getRolesSettingRequest,
  getAllRolesRequest,
} from "../../Redux/reducers/SettingsReducer";
import { useTitleHandler } from "../../customHooks/useTitleHandler";
import AppConstants from "../../Helpers/AppConstants";
import Header from "../../components/Header/Header";
import EmployeeSection from "../../components/Employees/EmployeeSection";
import CovidSection from "../../components/CovidEmployee";
import ManageTeam from "../../components/ManageTeam";
import Spinner from "../../components/Spinner";
import PrevilageNotFound from "../PageNotFound/PrevilageNotFound";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";
import UseLogoutHandler from "../../Helpers/useLogoutHandler";

export default function Employees() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Custom Hooks
  UseLogoutHandler();
  useTitleHandler(t("employeeHub"));

  // Params
  const [params] = useSearchParams();

  // Reducers
  const { lng } = useSelector((state) => state.languageReducer);
  const { token, user } = useSelector((state) => state.AuthenticationReducer);
  const { loading: empLoading, countries } = useSelector(
    (state) => state.employees
  );
  const {
    loading: settingsLoading,
    rolesSettings,
    roles,
    placesOfWork,
  } = useSelector((state) => state.settings);

  // Constants
  const companyId = get(user, "company_id", "");

  // Local States
  const [addEmployee, setAllEmployee] = useState(false);

  // Priviliges
  const { exist: listEmployees } = usePrevilagesExist(
    AppConstants.previlages.listEmployees
  );

  useEffect(() => {
    if (token) {
      if (countries.length == 0) {
        getAllCountries();
      }
      // if (rolesSettings.length == 0) {
      getRolesSettingRequestHandler();
      // }
      // if (roles.length == 0) {
      getAllRolesRequestHandler();
      // }
      if (placesOfWork.length == 0) {
        callGetWorkPlacesAPI();
      }
      if (params.get("search")) {
        getEmployees();
      } else {
        getEmployees();
      }
    }
  }, [token]);

  function getEmployees() {
    dispatch(
      employeesRequest({
        token,
        lng,
        offset: params.get("offset") ? params.get("offset") : 0,
        employeesFilters: {
          searchQuery: params.get("search"),
        },
        type: "list",
      })
    )
      .then(unwrapResult)
      .then((response) => {
        Applogger("Response at employeesRequest", response);
      })
      .catch((err) => {
        Applogger("Error at employeesRequest", err.message);
      });
  }

  const callGetWorkPlacesAPI = () => {
    dispatch(getPlacesOfWork({ token, lng, companyId }))
      .then(unwrapResult)
      .then((response) => { })
      .catch((err) => {
        Applogger("Error at getPlacesOfWork", err.message);
      });
  };

  const getRolesSettingRequestHandler = () => {
    dispatch(getRolesSettingRequest({ token, companyId }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getRolesSettingRequest", res);
      })
      .catch((err) => {
        Applogger("Error at getRolesSettingRequest", err);
      });
  };

  const getAllRolesRequestHandler = () => {
    dispatch(getAllRolesRequest({ token, companyId }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getAllRolesRequest", res);
      })
      .catch((err) => {
        Applogger("Error at getAllRolesRequest", err);
      });
  };

  function getAllCountries() {
    dispatch(getCountries({ token, lng }))
      .then(unwrapResult)
      .then((response) => {
        Applogger("Response at getCountries", response);
      })
      .catch((err) => {
        Applogger("Error at getCountries", err.message);
      });
  }

  return (
    <div>
      {(empLoading == "pending" || settingsLoading == "pending") && <Spinner />}
      <Header active={"employees"} />
      <br />
      <div className="sp_main">
        <div className="myFlex-cs flex-wrap-sm">
          <h1 className="top-heading">{t("employeeHub")}</h1>
        </div>
      </div>
      <div className="sp_main">
        <ul className="nav nav-tabs mb-3" id="ex1" role="tablist">
          <li className="nav-item" role="presentation">
            <a
              className={`nav-link active`}
              id={"ex1-tab-1"}
              data-mdb-toggle="tab"
              href={"#ex1-tabs-1"}
              role="tab"
              aria-controls={"ex1-tab-1"}
              aria-selected={true}
            >
              {t("employees")}
            </a>
          </li>
        </ul>
        {listEmployees ? (
          <div className="tab-content" id="ex1-content">
            <div
              className="tab-pane fade show active"
              id="ex1-tabs-1"
              role="tabpanel"
              aria-labelledby="ex1-tab-1"
            >
              <EmployeeSection
                add={params.get("add")}
                status={params.get("status")}
                searchQuery={params.get("search")}
              />
            </div>
            {
              <div
                className="tab-pane fade show p-2"
                id="ex1-tabs-2"
                role="tabpanel"
                aria-labelledby="ex1-tab-2"
              >
                <CovidSection />
              </div>
            }
            <div
              className="tab-pane fade show p-2"
              id="ex1-tabs-3"
              role="tabpanel"
              aria-labelledby="ex1-tab-3"
            >
              <ManageTeam />
            </div>
          </div>
        ) : (
          <PrevilageNotFound />
        )}
      </div>
    </div>
  );
}

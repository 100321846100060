import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { preventMinus } from "../../../../Utilities/index";

export default function EmpWorkingHoursField({
  title,
  Hoursvalue,
  hoursOnChange,
  minsValue,
  minsOnChange,
  currentType,
}) {
  const { t } = useTranslation();

  const [hoursValueLocal, setHoursValueLocal] = useState("");
  useEffect(() => {
    if (Hoursvalue) {
      setHoursValueLocal(Hoursvalue);
    }
  }, [Hoursvalue]);
  if (Hoursvalue !== null) {
    return (
      <div className="d-flex align-items-center justify-content-between flex-wrap-sm">
        <p className="mb-0">{title}</p>
        <div className="d-flex gap-2 col-sm-12 col-md-6">
          {Hoursvalue !== null && (
            <div className="d-flex filter-form d-flex align-items-center position-relative">
              <input
                value={Hoursvalue}
                maxLength={3}
                style={{ outline: "none" }}
                className="p-0 border-0"
                placeholder={currentType == "hours" ? "Hours" : "Days"}
                type="number"
                onKeyPress={preventMinus}
                onChange={(e) => {
                  if (e.target.value.length <= 3) {
                    hoursOnChange(e);
                  }
                }}
              />
              <label className="text-black-50 position-absolute me-2 end-0">
                {currentType == "hours" ? "Hours" : "Days"}
              </label>
            </div>
          )}
          {minsValue !== null && currentType == "hours" && (
            <div className="d-flex filter-form d-flex align-items-center position-relative">
              <input
                value={minsValue}
                maxLength={3}
                onKeyPress={preventMinus}
                style={{ outline: "none" }}
                placeholder={t("min")}
                type="number"
                onChange={(e) => {
                  if (e.target.value.length <= 3) {
                    minsOnChange(e);
                  }
                }}
              />
              <label className="text-black-50 position-absolute me-2 end-0">
                {t("min")}
              </label>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
}

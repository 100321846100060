import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import usePrevilagesExist from "../../../Helpers/PrivilegesFuncs";
import AppConstants from "../../../Helpers/AppConstants";
import SPProfileField from "../Components/SPProfileField";
import SPProfileButton from "../Components/SPProfileButton";

export default function MedicalInfoForm({ medicalData, updateMedicalData }) {
  const { t } = useTranslation();

  const { exist: updateContactInfo } = usePrevilagesExist(
    AppConstants.previlages.updateContactInfo
  );

  const [medicalInfoData, setMedicalInfoData] = useState({
    medical_condition: "",
    medication_ongoing_treatment: "",
    aids_adaption_needed: "",
  });

  useEffect(() => {
    setMedicalInfoData({
      medical_condition: get(medicalData, "medical_condition", ""),
      medication_ongoing_treatment: get(
        medicalData,
        "medication_ongoing_treatment",
        ""
      ),
      aids_adaption_needed: get(medicalData, "aids_adaption_needed", ""),
    });
  }, [medicalData]);

  const handleDisable = (exist) => {
    if (exist) {
      return false;
    } else {
      return true;
    }
  };

  const isEmptyData = () => {
    return (
      medicalInfoData.medical_condition == "" ||
      medicalInfoData.medication_ongoing_treatment == "" ||
      medicalInfoData.aids_adaption_needed == ""
    );
  };

  return (
    <div className="accordion-body p-0">
      <SPProfileField
        title={t("medicalCondition")}
        disabled={handleDisable(updateContactInfo)}
        value={medicalInfoData.medical_condition}
        defaultValue={medicalInfoData.medical_condition}
        onChange={(e) =>
          setMedicalInfoData({
            ...medicalInfoData,
            medical_condition: e.target.value,
          })
        }
        placeholder={t("medicalConditionNotes")}
      />
      <SPProfileField
        title={t("medicationOngoingTreatment")}
        disabled={handleDisable(updateContactInfo)}
        value={medicalInfoData.medication_ongoing_treatment}
        defaultValue={medicalInfoData.medication_ongoing_treatment}
        onChange={(e) =>
          setMedicalInfoData({
            ...medicalInfoData,
            medication_ongoing_treatment: e.target.value,
          })
        }
        placeholder={t("medicationOngoingTreatmentNotes")}
      />
      <SPProfileField
        title={t("anySpecificAidneeded")}
        disabled={handleDisable(updateContactInfo)}
        value={medicalInfoData.aids_adaption_needed}
        defaultValue={medicalInfoData.aids_adaption_needed}
        onChange={(e) =>
          setMedicalInfoData({
            ...medicalInfoData,
            aids_adaption_needed: e.target.value,
          })
        }
        placeholder={t("notes")}
      />
      <label className="ml-2 mt-2">
        {t("fieldsMarkedAs")}
        <span className="text-danger">{" * "}</span>
        {t("areMandatory")}
      </label>
      {!handleDisable(updateContactInfo) && (
        <SPProfileButton
          title={t("save")}
          disabled={handleDisable(updateContactInfo) || isEmptyData()}
          onClick={() => updateMedicalData(medicalInfoData)}
        />
      )}
    </div>
  );
}

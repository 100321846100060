import { useEffect } from "react";

export const useTitleHandler = (title) => {
  useEffect(() => {
    if (title) {
      document.title = "Speedy HR | " + title;
    } else {
      document.title = "Speedy HR";
    }
  }, [title]);
  return;
};

import React, { useState, useEffect } from "react";
import { styles } from "./styles";
import { Select, DatePicker } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { Applogger } from "../../Helpers/Logger";
import { get } from "lodash";
import { formattedDateForAPI, showFaliureToast } from "../../Utilities";
import {
  generateAnnualLeaveSummaryReport,
  generateAbsenceReport,
  generateEmployeeDetailsReport,
  generateTimeSheetReport,
  generateLatenessReport,
} from "../../Redux/reducers/ReportsReducer";
import moment from "moment";
import AppConstants from "../../Helpers/AppConstants";
import Header from "../Header/Header";
import Spinner from "../Spinner";
import SimpleButton from "../Helper/SimpleButton";
import PageNotFound from "../../pages/PageNotFound/PageNotFound";
import PrevilageNotFound from "../../pages/PageNotFound/PrevilageNotFound";
import useLocalisedConstants from "../../customHooks/useLocalisedConstants";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import "react-nice-dates/build/style.css";
import { saveAs } from "file-saver";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

export default function ReportDetails() {
  const { RangePicker } = DatePicker;
  const { Option } = Select;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const appConstants = useLocalisedConstants();

  const { reportCategories, leaveReportCategories } = appConstants;

  const [params] = useSearchParams();
  const reportCategory = params.get("type");

  const { token, userPrevilages } = useSelector(
    (state) => state.AuthenticationReducer
  );
  const { lng } = useSelector((state) => state.languageReducer);
  const { loading } = useSelector((state) => state.reports);
  const { allEmployees } = useSelector((state) => state.employees);
  const { userPrevilages: moduleUserPrevilages } = useSelector(
    (state) => state.settings
  );

  const dateFormat = AppConstants.defaultDateFormat;

  const [selLeaveType, setSelLeaveType] = useState("currentleaveentitlement");
  const [selEmployee, setSelEmployee] = useState(null);
  const [startDate, setStartDate] = useState(
    moment().subtract(30, "days").format(dateFormat)
  );
  const [endDate, setEndDate] = useState(moment().format(dateFormat));

  useEffect(() => {
    if (!token) {
      navigate(AppConstants.routes.login);
    }
  }, []);

  const handleBacktoReports = (event) => {
    event.preventDefault();
    navigate(AppConstants.routes.analytics);
  };

  const handleDateRange = (dateRange, dateArray) => {
    if (Array.isArray(dateArray)) {
      setStartDate(get(dateArray, "[0]", ""));
      setEndDate(get(dateArray, "[1]", ""));
    }
  };

  const handleGeneratePress = (type) => {
    if (reportCategory == reportCategories.absence) {
      handleGenerateAbsenceReport(type);
    } else if (reportCategory == reportCategories.blip_timesheet) {
      handleGenerateTimeSheetReport();
    } else if (reportCategory == reportCategories.holiday_summary) {
      handleAnnualLeaveSummaryReport(type);
    } else if (reportCategory == reportCategories.people_details) {
      handleGenerateEmployeeDetailsReport(type);
    } else if (reportCategory == reportCategories.lateness) {
      handleGenerateLatenessReport(type);
    } else {
      showFaliureToast("Please select valid report category from analytics");
    }
  };

  const handleAnnualLeaveSummaryReport = (currentType) => {
    var a = document.createElement("a");

    const body = {
      token: token,
      dateRange: selLeaveType,
      type: currentType.toLowerCase(),
    };
    dispatch(generateAnnualLeaveSummaryReport({ body, lng }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at generateAnnualLeaveSummaryReport", res);
        var strr = res.split("/");
        // a.href = res;
        // if (strr) {
        //   a.download = strr[strr.length - 1];
        // }
        // a.target = "_blank";
        // a.click();

        saveAs(res, strr[strr.length - 1]);
      })
      .catch((err) => {
        Applogger("Error at generateAnnualLeaveSummaryReport", err);
        showFaliureToast(err.message ?? "Unable to generate report");
      });
  };

  const handleGenerateAbsenceReport = (currentType) => {
    var a = document.createElement("a");
    const body = {
      token: token,
      startDate: formattedDateForAPI(startDate),
      endDate: formattedDateForAPI(endDate),
      type: currentType.toLowerCase(),
    };
    dispatch(generateAbsenceReport({ body, lng }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at generateAbsenceReport", res);
        var strr = res.split("/");
        // a.href = res;
        // if (strr) {
        //   a.download = strr[strr.length - 1];
        // }
        // a.target = "_blank";
        // a.click();

        saveAs(res, strr[strr.length - 1]);
      })
      .catch((err) => {
        Applogger("Error at generateAbsenceReport", err);
        showFaliureToast(err ?? "Please reselect start and end date");
      });
  };

  const handleGenerateEmployeeDetailsReport = (currentType) => {
    var a = document.createElement("a");
    const body = {
      token: token,
      emp_id: selEmployee,
      type: currentType.toLowerCase(),
    };
    dispatch(generateEmployeeDetailsReport({ body, lng }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at generateEmployeeDetailsReport", res);
        var strr = res.split("/");
        // a.href = res;
        // if (strr) {
        //   a.download = strr[strr.length - 1];
        // }
        // a.target = "_blank";
        // a.click();
        saveAs(res, strr[strr.length - 1]);
      })
      .catch((err) => {
        Applogger("Error at generateEmployeeDetailsReport", err);
        showFaliureToast(err);
      });
  };

  const handleGenerateLatenessReport = (currentType) => {
    var a = document.createElement("a");
    const body = {
      token: token,
      // emp_id: selEmployee,
      type: currentType.toLowerCase(),
      startDate: formattedDateForAPI(startDate),
      endDate: formattedDateForAPI(endDate),
    };
    dispatch(generateLatenessReport({ body, lng }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at generateLatenessReport", res);
        var strr = res.split("/");

        saveAs(res, strr[strr.length - 1]);
      })
      .catch((err) => {
        Applogger("Error at generateLatenessReport", err);
        if (get(err, "message", "")) {
          showFaliureToast(get(err, "message", ""));
        } else {
          showFaliureToast(err);
        }
      });
  };

  const handleGenerateTimeSheetReport = () => {
    var a = document.createElement("a");
    const body = {
      token: token,
      startDate: formattedDateForAPI(startDate),
      endDate: formattedDateForAPI(endDate),
      emp_id: selEmployee,
    };
    dispatch(generateTimeSheetReport({ body, lng }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at generateTimeSheetReport", res);
        var strr = res.split("/");
        // a.href = res;
        // if (strr) {
        //   a.download = strr[strr.length - 1];
        // }
        // a.target = "_blank";
        // a.click();
        saveAs(res, strr[strr.length - 1]);
      })
      .catch((err) => {
        Applogger("Error at generateTimeSheetReport", err);
        showFaliureToast(err ?? "Please reselect start and end date");
      });
  };

  const isDisabled = () => {
    if (reportCategory == reportCategories.absence) {
      return !startDate || !endDate;
    } else if (reportCategory == reportCategories.blip_timesheet) {
      return !startDate || !endDate;
    } else if (reportCategory == reportCategories.holiday_summary) {
      return !selLeaveType;
    } else if (reportCategory == reportCategories.people_details) {
      return false;
    } else if (reportCategory == reportCategories.lateness) {
      return !startDate || !endDate;
    } else {
      return isValidReportCatefory();
    }
  };

  const isEmployeeSecRequired = () => {
    return (
      reportCategory == reportCategories.people_details ||
      reportCategory == reportCategories.blip_timesheet
    );
  };

  const isPeriodSecRequired = () => {
    return (
      reportCategory == reportCategories.blip_timesheet ||
      reportCategory == reportCategories.absence ||
      reportCategory == reportCategories.lateness
    );
  };

  const isLeaveSecRequired = () => {
    return reportCategory == reportCategories.holiday_summary;
  };

  const isDownloadAsSecRequired = () => {
    return (
      reportCategory == reportCategories.holiday_summary ||
      reportCategory == reportCategories.absence ||
      reportCategory == reportCategories.lateness
    );
  };

  const getPageHeading = () => {
    if (reportCategory == reportCategories.absence) {
      return t("absenceReport");
    } else if (reportCategory == reportCategories.blip_timesheet) {
      return t("timeSheetReport");
    } else if (reportCategory == reportCategories.holiday_summary) {
      return t("holidaySummaryReport");
    } else if (reportCategory == reportCategories.people_details) {
      return t("empDetailReport");
    } else if (reportCategory == reportCategories.lateness) {
      return t("lateness");
    } else {
      return t("analytics");
    }
  };

  const isValidReportCatefory = () => {
    return (
      reportCategory == reportCategories.absence ||
      reportCategory == reportCategories.blip_timesheet ||
      reportCategory == reportCategories.holiday_summary ||
      reportCategory == reportCategories.people_details ||
      reportCategory == reportCategories.lateness
    );
  };

  const onSearch = (value) => {};

  const isModuleAllowed = (module) => {
    return moduleUserPrevilages.some((moduleUserPrevilage) => {
      return (
        moduleUserPrevilage.key === module &&
        moduleUserPrevilage.children.some((child) => {
          return userPrevilages.includes(child.key);
        })
      );
    });
  };

  return isModuleAllowed(AppConstants.previlagesModules.analytics) ? (
    <>
      <Header />
      <br />
      <div className="container_custom">
        {loading == "pending" && <Spinner />}
        <h1 className="top-heading">
          {isValidReportCatefory() ? getPageHeading() : t("invalidReportType")}
        </h1>
        <br />
        <hr />
        {isValidReportCatefory() && (
          <>
            <p style={styles.generateReportText}>{t("generateReport")}</p>
            {isEmployeeSecRequired() && (
              <div className="row">
                <div className="col-12 col-sm-12 col-md-3 mt-3 pl-0">
                  <p>{t("searchEmployees")}</p>
                </div>
                <div className="col-12 col-sm-12 col-md-6 px-0">
                  <form>
                    <Select
                      className="filter-form-dropdown text-capitalize "
                      showSearch={true}
                      onSearch={onSearch}
                      onChange={(e) => setSelEmployee(e)}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      defaultValue={t("allEmployees")}
                    >
                      <Option value={null}>{t("allEmployees")}</Option>
                      {allEmployees.map((employee, index) => {
                        const { user_id, first_name, last_name } = employee;
                        return (
                          <Option
                            key={index}
                            value={user_id}
                            label={first_name + " " + last_name}
                          >
                            <span className="text-capitalize">
                              {first_name + " " + last_name}
                            </span>
                          </Option>
                        );
                      })}
                    </Select>
                  </form>
                </div>
              </div>
            )}
            {isPeriodSecRequired() && (
              <div className="row">
                <div className="col-12 col-sm-12 col-md-3 mt-3 pl-0">
                  <p>{t("dateRange")}</p>
                </div>
                <div className="col-12 col-sm-12 col-md-6 px-0">
                  <RangePicker
                    getPopupContainer={(node) => node.parentNode}
                    format={dateFormat}
                    allowClear={false}
                    defaultValue={[
                      dayjs(startDate, dateFormat),
                      dayjs(endDate, dateFormat),
                    ]}
                    className="filter-form d-flex"
                    onChange={handleDateRange}
                  />
                </div>
              </div>
            )}
            {isLeaveSecRequired() && (
              <div className="row">
                <div className="col-12 col-sm-12 col-md-3 mt-3 pl-0">
                  <p>{t("selLeaveType")}</p>
                </div>
                <div className="col-12 col-sm-12 col-md-6 px-0">
                  <form>
                    <select
                      onChange={(e) => setSelLeaveType(e.target.value)}
                      className="filter-form"
                    >
                      {leaveReportCategories.map((leaveType, index) => {
                        const { value, name } = leaveType;
                        return (
                          <option key={index} value={value} className="fw-bold">
                            {name}
                          </option>
                        );
                      })}
                    </select>
                  </form>
                </div>
              </div>
            )}
            <div className="row">
              <p className="mt-4 pl-0">{t("infoToProcess")}</p>
            </div>
          </>
        )}
        <br />
        <div className="d-flex gap-2">
          {isValidReportCatefory() && (
            <>
              <SimpleButton
                disabled={isDisabled()}
                onClick={() => handleGeneratePress("CSV")}
                btnPink={true}
                text={`${t("generate")} CSV`}
              />
              {isDownloadAsSecRequired() && (
                <SimpleButton
                  disabled={isDisabled()}
                  onClick={() => handleGeneratePress("PDF")}
                  btnPink={true}
                  text={`${t("generate")} PDF`}
                />
              )}
            </>
          )}
          <SimpleButton
            onClick={(e) => handleBacktoReports(e)}
            btnOutline={true}
            text={t("backToReports")}
          />
        </div>
      </div>
    </>
  ) : !generateAbsenceReport ? (
    <PrevilageNotFound />
  ) : (
    <PageNotFound />
  );
}

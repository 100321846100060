import React from "react";

const NotesView = ({ title, value, onChange }) => {
  return (
    <div className="row">
      <div className="col-12 col-md-4 col-sm-12 mt-2 px-0">
        <h6>{title}</h6>
      </div>
      <div className="col-12 col-md-6 col-sm-12 px-0">
        <textarea
          value={value}
          onChange={onChange}
          className="filter_textarea fw-normal"
        />
      </div>
    </div>
  );
};

export default NotesView;

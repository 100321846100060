import React from "react";
import { useTranslation } from "react-i18next";
import "./ReportCard.css";

export default function Index(props) {
  const { icon, title, description, Link } = props;
  const { t } = useTranslation();

  return (
    <div className="row w-50-cs sec-cs-card bordered rounded rounded-4  p-2 sp-report-card">
      <div className="col-lg-4 col-sm-12 d-flex justify-content-start align-items-center">
        <img src={icon} className="hide-sm" alt="icon" />
      </div>
      <div className="col-lg-8 col-sm-12 d-flex justify-content-center flex-column">
        <h5 className="fw-bold  lexen-font">{title}</h5>
        <p className="mt-2 mb-2">{description}</p>
        <a href={Link} className="text-primary ">
          {t("generateNewReport")}
        </a>
      </div>
    </div>
  );
}

import React from "react";

const ShiftDropDown = ({ dataArray, value, defaultValue, onChange }) => {
  return (
    <div className="col-12 col-sm col-md p-0">
      <select
        className="filter-form"
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
      >
        {Array.isArray(dataArray) &&
          dataArray.map((item) => {
            return <option value={item}>{item}</option>;
          })}
      </select>
    </div>
  );
};

export default ShiftDropDown;

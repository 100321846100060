import React, { useState, useEffect } from "react";
import clsx from "clsx";
import moment from "moment";
import { useTranslation } from "react-i18next";

function RBCToolbar({ label, date, view, views, onView, onNavigate }) {
  const [month, setMonth] = useState("January");
  const mMonth = moment(date).format("MMMM");
  const { t } = useTranslation();

  useEffect(() => {
    setMonth(mMonth);
  }, [mMonth]);

  const onChange = (event) => {
    const current = event.target.value;
    onNavigate("DATE", moment().month(current).toDate());
    setMonth(current);
  };

  const goToView = (view) => {
    onView(view);
  };

  const goToBack = () => {
    onNavigate("PREV");
  };
  const goToNext = () => {
    onNavigate("NEXT");
  };

  const goToToday = () => {
    onNavigate("TODAY");
  };

  const DayHandler = () => {
    switch (view) {
      case "agenda":
        return t("todaysAgenda");

      case "day":
        return t("today");

      case "week":
        return t("thisWeek");

      case "month":
        return t("thisMonth");

      default:
    }
  };
  return (
    <div className="rbc-toolbar">
      <div className="rbc-btn-group">
        <button onClick={goToToday}>{DayHandler()}</button>
        <button onClick={goToBack}>{t("back")}</button>
        <button onClick={goToNext}>{t("next")}</button>
      </div>
      <div className="rbc-toolbar-label">
        {view === "month" ? (
          <>
            {/* <select className="rbc-dropdown filter-form-" value={month} onChange={onChange}>
              {months?.map((month) => (
                <option
                  value={month}
                  className="rbc-dropdown-option" //custom class
                  key={month}
                  
                >
                  {month}
                </option>
              ))}
            </select> */}
            <span> {month}</span>
            <span className="rbc-year"> {moment(date).format("YYYY")}</span>
          </>
        ) : (
          label
        )}
      </div>
      <div className="rbc-btn-group">
        {views?.map((item) => {
          if (item != "agenda") {
            return (
              <button
                onClick={() => goToView(item)}
                key={item}
                className={clsx({ "rbc-active": view === item })}
                style={{ textTransform: "capitalize" }}
              >
                {item}
              </button>
            );
          }
        })}
      </div>
    </div>
  );
}

export default RBCToolbar;

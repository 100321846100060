import EventResource from "../../Config/EventResource";
import { APIConstants } from "../../Config/ApiConstants";
import { getHeaders } from "../../Helpers/commonFunc";

function getStaffingData(payload) {
  return EventResource.get(
    `${APIConstants.Staffing}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

function getFlteredStaffing(payload) {
  var path = APIConstants.Staffing;
  const { token, dateRange, lng } = payload;
  const { startDate, endDate } = dateRange;
  path = path += `?start_date=${startDate}&end_date=${endDate}&lng=${lng}`;

  return EventResource.get(path, getHeaders(token));
}

function changeStaffingStatus(payload) {
  return EventResource.get(
    `${APIConstants.ChangeStaffingStatus}/${payload.id}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

function createStaffing(payload) {
  var data = new FormData();
  data.append("name", payload.name);
  data.append("start_date", payload.start_date);
  data.append("duration", payload.duration);
  data.append("created_by", payload.created_by);
  if (payload.rota_id !== undefined) {
    data.append("rota_id", payload.rota_id);
  }
  data.append(
    "is_calender_month",
    payload.is_calender_month ? payload.is_calender_month : "0"
  );

  return EventResource.post(
    APIConstants.createStaffing,
    data,
    getHeaders(payload.token)
  );
}

function getAllShiftsRequest(payload) {
  return EventResource.get(
    `${APIConstants.getShifts}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

function createShiftRequest(payload) {
  const { breakDuration, notes, shiftDay, shiftTimeEnd, shiftTimeStart, ids } =
    payload.shiftData;

  var shiftData = new FormData();
  shiftData.append("start_time", shiftTimeStart);
  shiftData.append("end_time", shiftTimeEnd);
  shiftData.append("break_duration", breakDuration);
  shiftData.append("shift_day", shiftDay);
  shiftData.append("notes", notes);

  if (ids.length > 0) {
    for (let i = 0; i < ids.length; i++) {
      const element = ids[i];
      shiftData.append("user_ids[]", element);
    }
  }
  shiftData.append("rota_id", payload.rota_id);

  return EventResource.post(
    APIConstants.createShifts,
    shiftData,
    getHeaders(payload.token)
  );
}

function updateStaffingRequest(payload) {
  const {
    name,
    start_date,
    duration,
    ids,
    breakDuration,
    shiftDay,
    shiftTimeEnd,
    shiftTimeStart,
    notes,
  } = payload.currentStaff;

  var shiftData = new FormData();

  shiftData.append("start_time", shiftTimeStart);
  shiftData.append("end_time", shiftTimeEnd);
  shiftData.append("break_duration", breakDuration);
  shiftData.append("shift_day", shiftDay);
  shiftData.append("start_date", start_date);
  shiftData.append("duration", duration);

  shiftData.append("name", name);
  shiftData.append("notes", notes);

  if (ids.length > 0) {
    for (let i = 0; i < ids.length; i++) {
      const element = ids[i];
      shiftData.append("user_ids[]", element);
    }
  }

  return EventResource.post(
    `${APIConstants.editStaffing}${payload.id}`,
    shiftData,
    getHeaders(payload.token)
  );
}

function updateShiftRequest(payload) {
  const {
    breakDuration,
    shiftTimeEnd,
    notes,
    shiftDay,
    shiftTimeStart,
    currentUserId,
    shiftId,
    rotaId,
  } = payload.shiftData;

  const shiftFormData = new FormData();
  shiftFormData.append("break_duration", breakDuration);
  shiftFormData.append("end_time", shiftTimeEnd);
  shiftFormData.append("notes", notes);
  shiftFormData.append("shift_day", shiftDay);
  shiftFormData.append("start_time", shiftTimeStart);
  shiftFormData.append("user_id", currentUserId);
  shiftFormData.append("rota_id", rotaId);
  return EventResource.post(
    `${APIConstants.updateShift}${shiftId}/`,
    shiftFormData,
    getHeaders(payload.token)
  );
}

function renameStaffingRequest(payload) {
  const staffingFormData = new FormData();

  staffingFormData.append("name", payload.name);

  return EventResource.post(
    `${APIConstants.editRota}${payload.id}/`,
    staffingFormData,
    getHeaders(payload.token)
  );
}

function deleteStaffingRequest(payload) {
  const staffingData = new FormData();
  staffingData.append("id", payload.id);
  return EventResource.post(
    `${APIConstants.deleteRota}${payload.id}/`,
    staffingData,
    getHeaders(payload.token)
  );
}

function getRotaShiftRequest(payload) {
  return EventResource.get(
    `${APIConstants.getShifts}/${payload.id}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

function getViewStaffingRequest(payload) {
  return EventResource.get(
    `${APIConstants.getStaffing}${payload.id}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

function getStaffingByIdRequest(payload) {
  return EventResource.get(
    `${APIConstants.getStaffing2}${payload.id}?lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

function assignEmployeesRequest(payload) {
  const staffingEmployees = new FormData();
  for (let i = 0; i < payload.selectedEmployees.length; i++) {
    const element = payload.selectedEmployees[i];
    staffingEmployees.append("user_ids[]", element.user_id);
  }

  return EventResource.post(
    `${APIConstants.assignStaffEmployees}${payload.staffingId}`,
    staffingEmployees,
    getHeaders(payload.token)
  );
}

function unAssignEmployeesRequest(payload) {
  return EventResource.get(
    `${APIConstants.unAssignStaffEmployee}${payload.staffingId}?user_id=${payload.id}&lng=${payload.lng}`,
    getHeaders(payload.token)
  );
}

function shiftHandlerRequest(payload) {
  const {
    shiftTimeStart,
    shiftTimeEnd,
    breakDuration,
    notes,
    rotaId,
    userId,
    shiftDay,
    shiftId,
  } = payload.shiftData;
  const shiftData = new FormData();
  shiftData.append("start_time", shiftTimeStart);
  shiftData.append("end_time", shiftTimeEnd);
  shiftData.append("break_duration", breakDuration);
  shiftData.append("notes", notes !== null ? notes : "");
  shiftData.append("rota_id", payload.rotaId);
  shiftData.append("user_id", payload.userId);
  shiftData.append("shift_day", shiftDay);

  return EventResource.post(
    `${APIConstants.getShifts}/${shiftId == "" ? "" : shiftId}`,
    shiftData,
    getHeaders(payload.token)
  );
}

function deleteShiftHandlerRequest(payload) {
  const shiftData = new FormData();
  shiftData.append("id", payload.id);
  return EventResource.post(
    `${APIConstants.deleteShifts}${payload.id}`,
    shiftData,
    getHeaders(payload.token)
  );
}

export const StaffingApiServices = {
  getStaffingData,
  getFlteredStaffing,
  changeStaffingStatus,
  createStaffing,
  getAllShiftsRequest,
  createShiftRequest,
  updateShiftRequest,
  renameStaffingRequest,
  deleteStaffingRequest,
  getRotaShiftRequest,
  updateStaffingRequest,
  getViewStaffingRequest,
  getStaffingByIdRequest,
  assignEmployeesRequest,
  unAssignEmployeesRequest,
  shiftHandlerRequest,
  deleteShiftHandlerRequest,
};

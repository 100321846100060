import React from "react";
import { useTranslation } from "react-i18next";
import { getEmployeeName } from "../../../../Utilities";
export default function DoneAddEmployee({
  employeeForm,
  cancelClick,
  addAnotherEmployee,
  goToProfile,
}) {
  const { t } = useTranslation();
  const handleGoToProfile = () => {
    goToProfile();
  };

  const handleAddAnotherEmployee = () => {
    addAnotherEmployee();
  };
  const handleClose = () => {
    cancelClick();
  };

  return (
    <div>
      <h4>
        {" "}
        <strong>{getEmployeeName(employeeForm)}</strong> {t("addedToSpeedyHR")}
      </h4>
      <p>
        {t("richerProfile")} <strong>{t("employee")}</strong>
      </p>

      <ul style={{ listStyleType: "circle" }}>
        <li>
          {t("add")} <strong>{getEmployeeName(employeeForm)}</strong>{" "}
          {t("oneOrMoreTeams")}
        </li>
        <li>{t("addJobTitleOrRole")}</li>
        <li>{t("addEmergencyContacts")}</li>
        <li>{t("addAProfilePicture")}</li>
      </ul>
      <div className="d-flex flex-wrap-sm gap-2">
        <button
          onClick={() => handleGoToProfile()}
          className="btn btn-primary-cs"
        >
          {t("goToProfile")}
        </button>
        <button
          onClick={() => handleAddAnotherEmployee()}
          className="btn btn-primary-outline-cs"
        >
          {t("addAnotherEmployee")}
        </button>
        <button
          onClick={() => handleClose()}
          className="btn btn-primary-outline-cs"
        >
          {t("close")}
        </button>
      </div>
    </div>
  );
}

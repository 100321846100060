import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppConstants from "./AppConstants";

const UseLogoutHandler = () => {
  const navigate = useNavigate();

  const { token } = useSelector((state) => state.AuthenticationReducer);

  useEffect(() => {
    if (!token) {
      return navigate(AppConstants.routes.login);
    }
  }, [token]);

  return;
};

export default UseLogoutHandler;

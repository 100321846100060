import { createAsyncThunk, createReducer, current } from "@reduxjs/toolkit";
import { AuthApiServices } from "../actions/AuthApiServices";
import { getMergedObject } from "../../Utilities";

export const stopLoaderAndEmptyErrors = createAsyncThunk(
  "AuthenticationReducer/stopLoaderAndEmptyErrors",
  async (payload) => {
    return payload;
  }
);

export const updateUserObjectInReducer = createAsyncThunk(
  "AuthenticationReducer/updateUserObjectInReducer",
  async (payload) => {
    return payload;
  }
);

export const getUserWorkTimePattern = createAsyncThunk(
  "AuthenticationReducer/getUserWorkTimePattern",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AuthApiServices.apiGetUserWorkTimePattern(payload);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const loginRequest = createAsyncThunk(
  "AuthenticationReducer/loginRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AuthApiServices.apiLoginRequest(payload);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "AuthenticationReducer/forgotPassword",

  async (payload, { rejectWithValue }) => {
    try {
      const response = await AuthApiServices.forgotPasswordRequest(payload);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "AuthenticationReducer/resetPassword",
  async (payload) => {
    const response = await AuthApiServices.resetPasswordRequest(payload);
    return response;
  }
);
export const logoutRequest = createAsyncThunk(
  "AuthenticationReducer/logoutRequest",
  async (payload) => {}
);

const initialState = {
  alreadyLoggedIn: false,
  loading: null,
  error: null,
  token: null,
  role: "",
  user: null,
  jwt: "",
  userPrevilages: [],
  userWorkTimePattern: null,
};

const states = {
  pending: "pending",
  idle: "idle",
};

export const AuthenticationReducer = createReducer(initialState, {
  [updateUserObjectInReducer.fulfilled]: (state, action) => {
    return {
      ...state,
      user: getMergedObject(current(state.user), action.payload.updatedUser),
    };
  },
  [loginRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [loginRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      alreadyLoggedIn: true,
      loading: states.idle,
      error: null,
      token: action.payload.access_token,
      role: action.payload.role.name,
      userPrevilages: action.payload.privileges,
      jwt: action.payload.jwt,
      user: action.payload.user,
    };
  },
  [loginRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message.toString(),
    };
  },

  [getUserWorkTimePattern.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [getUserWorkTimePattern.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
      userWorkTimePattern: action.payload,
    };
  },

  [getUserWorkTimePattern.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message.toString(),
    };
  },

  [forgotPassword.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message.toString(),
    };
  },

  [resetPassword.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [resetPassword.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [resetPassword.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message.toString(),
    };
  },

  [forgotPassword.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [forgotPassword.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [stopLoaderAndEmptyErrors.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [logoutRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message.toString(),
    };
  },

  [logoutRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [logoutRequest.fulfilled]: (state, action) => {
    return {
      ...initialState,
    };
  },
});

import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import def_icon_1 from "../../Images/def_icon_1.png";
import def_icon_2 from "../../Images/def_icon_2.png";
import def_icon_3 from "../../Images/def_icon_3.png";
import def_icon_4 from "../../Images/def_icon_4.png";
import ReportCard from "../../components/ReportCard";
import AppConstants from "../../Helpers/AppConstants";
import { useNavigate } from "react-router-dom";
import { useTitleHandler } from "../../customHooks/useTitleHandler";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";
import UseUsers from "../../Helpers/useUsers";
import useLocalisedConstants from "../../customHooks/useLocalisedConstants";

export default function () {
  // Dispatcher
  const navigate = useNavigate();

  // Localisation
  const { t } = useTranslation();

  // Hooks
  useTitleHandler(t("analytics"));
  const {} = UseUsers(false);

  // Constants
  const appConstants = useLocalisedConstants();

  const { reportCategories } = appConstants;
  const { routes } = AppConstants;

  // Reducer States
  const { token } = useSelector((state) => state.AuthenticationReducer);

  // Priviliges
  const { exist: generateAbsenceReport } = usePrevilagesExist(
    AppConstants.previlages.generateAbsenceReport
  );

  const { exist: generateHolidayReport } = usePrevilagesExist(
    AppConstants.previlages.generateHolidayReport
  );

  const { exist: generateEmployeeReport } = usePrevilagesExist(
    AppConstants.previlages.generateEmployeeReport
  );

  const { exist: generateTimesheetReport } = usePrevilagesExist(
    AppConstants.previlages.generateTimesheetReport
  );

  // Local States
  const [reportsData, setReportsData] = useState([]);

  useEffect(() => {
    if (!token) {
      navigate(AppConstants.routes.login);
    }
  }, [token]);

  const absence = {
    id: 1,
    title: t("absence"),
    icon: def_icon_1,
    Description: t("upcomingAbsencesPerEmployee"),
    Link: `${routes.analytics_details}?type=${reportCategories.absence}`,
  };
  const holiday = {
    id: 2,
    title: t("holidaySummary"),
    icon: def_icon_2,
    Description: t("breakdownOfAllAnnualLeaves"),
    Link: `${routes.analytics_details}?type=${reportCategories.holiday_summary}`,
  };
  const timeSheet = {
    id: 3,
    title: t("timesheet"),
    icon: def_icon_3,
    Description: t("generateTimesheetReport"),
    Link: `${routes.analytics_details}?type=${reportCategories.blip_timesheet}`,
  };
  const employee = {
    id: 4,
    title: t("employeeDetails"),
    icon: def_icon_4,
    Description: t("personalDetailsInformation"),
    Link: `${routes.analytics_details}?type=${reportCategories.people_details}`,
  };
  const employeeLateness = {
    id: 4,
    title: t("lateness"),
    icon: def_icon_1,
    Description: t("latenessReportText"),
    Link: `${routes.analytics_details}?type=${reportCategories.lateness}`,
  };

  useEffect(() => {
    const reports = [];
    if (generateAbsenceReport) {
      reports.push(absence);
    }

    if (generateHolidayReport) {
      reports.push(holiday);
    }

    if (generateEmployeeReport) {
      reports.push(employee);
    }

    if (generateTimesheetReport) {
      reports.push(timeSheet);
    }
    reports.push(employeeLateness); // change this after adding previlages

    setReportsData(reports);
  }, [
    generateAbsenceReport,
    generateHolidayReport,
    generateEmployeeReport,
    generateTimesheetReport,
  ]);

  return (
    <div>
      <Header active={"Reports"} />
      <br />
      <div className="container_custom">
        <h1 className="top-heading">{t("analytics")}</h1>
        <div className="d-flex flex-wrap gap-4 mt-4 justify-content-between">
          {reportsData.length > 0 ? (
            reportsData.map((report, index) => {
              const { icon, Link, title, Description } = report;
              return (
                <ReportCard
                  key={index}
                  icon={icon}
                  Link={Link}
                  title={title}
                  description={Description}
                />
              );
            })
          ) : (
            <div>No Reports Found</div>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Applogger } from "../../../Helpers/Logger";
import { resetUserPassword } from "../../../Redux/reducers/ProfileReducers";
import { logoutRequest } from "../../../Redux/reducers/AuthenticationReducer";
import {
  showSuccessToast,
  showFaliureToast,
  validatePassword,
} from "../../../Utilities";
import Spinner from "../../Spinner";
import AppConstants from "../../../Helpers/AppConstants";
import SPProfileField from "../Components/SPProfileField";
import SPProfileButton from "../Components/SPProfileButton";

export default function ResetUserPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { token } = useSelector((state) => state.AuthenticationReducer);

  const [requestBody, setRequestBody] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [userLoading, setUserLoading] = useState(false);

  const resetPasswordHandler = () => {
    if (
      requestBody.newPassword === "" ||
      requestBody.confirmNewPassword === ""
    ) {
      showFaliureToast(t("bothFieldsAreRequired"));
    } else if (requestBody.confirmNewPassword !== requestBody.newPassword) {
      showFaliureToast(t("passwordDoesNotMatch"));
    } else if (
      requestBody.newPassword.length < 8 ||
      requestBody.confirmNewPassword.length < 8
    ) {
      showFaliureToast(t("passwordRequirement"));
    } else if (!validatePassword(requestBody.newPassword)) {
      showFaliureToast(t("passValidationMsg"));
    } else {
      setUserLoading(true);
      handleResetPasswordAPI();
    }
  };

  const handleResetPasswordAPI = () => {
    dispatch(resetUserPassword({ requestBody, token }))
      .then(unwrapResult)
      .then((res) => {
        dispatch(logoutRequest());
        showSuccessToast(t("changePassToast"));
        Applogger("Response at resetUserPassword", res);
        setUserLoading(false);
        navigate(AppConstants.routes.login);
      })
      .catch((err) => {
        if (err.message === AppConstants.errorMessages.invalidEmail) {
          showFaliureToast("Invalid Token, Please contact your administrator");
        } else {
          showFaliureToast(err.message.toString());
        }
        setUserLoading(false);
        Applogger("Error at resetPassword", err.message);
      });
  };

  const isDisabled = () => {
    return (
      !requestBody.newPassword ||
      !requestBody.confirmNewPassword ||
      !requestBody.currentPassword
    );
  };

  return (
    <div>
      {userLoading && <Spinner size={12} />}
      <SPProfileField
        title={t("currentPassword")}
        value={requestBody.currentPassword}
        onChange={(e) =>
          setRequestBody({ ...requestBody, currentPassword: e.target.value })
        }
        type="password"
        isRequired={true}
      />
      <SPProfileField
        title={t("enterNewPassword")}
        value={requestBody.newPassword}
        onChange={(e) =>
          setRequestBody({ ...requestBody, newPassword: e.target.value })
        }
        type="password"
        isRequired={true}
      />
      <SPProfileField
        title={t("confirmNewPassword")}
        value={requestBody.confirmNewPassword}
        onChange={(e) =>
          setRequestBody({ ...requestBody, confirmNewPassword: e.target.value })
        }
        type="password"
        isRequired={true}
      />
      <SPProfileButton
        title={t("resetPassword")}
        onClick={() => resetPasswordHandler()}
        disabled={isDisabled()}
      />
      <span style={{ fontSize: 12, color: "gray" }}>
        <span
          style={{ fontSize: 12, color: "red", fontWeight: "bold" }}
          className="pl-2"
        >
          {t("note")}
        </span>
        {": "}
        {t("passValidationMsg")}
      </span>
    </div>
  );
}

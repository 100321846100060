import React from "react";
import { employeeTypes } from "../FormConstants";
import { EmployeeTypeCard } from "../Components/EmployeeTypeCard";
import { useTranslation } from "react-i18next";

export default function EmployeeType({
  userType,
  setCurrentUserType,
  createEmployee,
}) {
  const { t } = useTranslation();

  const employeeTypesList = [
    {
      id: 1,
      title: t("daily"),
      subtitle: t("repeatedWorkingTimePattern"),
      active: false,
      type: employeeTypes.days,
    },
    {
      id: 2,
      title: t("hourly"),
      subtitle: t("employeesOnContract"),
      active: false,
      type: employeeTypes.hours,
    },
  ];

  return (
    <div>
      <div className="col-md-12">
        <div>
          <h5 className="fw-bold">{t("typeOfEmployee")}</h5>
        </div>
        {createEmployee && (
          <div className="d-flex flex-wrap-sm gap-2">
            {employeeTypesList.map((item, index) => {
              return (
                <EmployeeTypeCard
                  key={index}
                  onClick={() => setCurrentUserType(item.type)}
                  checked={userType === item.type}
                  title={item.title}
                  subtitle={item.subtitle}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { showFaliureToast, getCountryIDFromName } from "../../../Utilities";
import { get } from "lodash";
import { emergencyContactObj } from "../Constants";
import SPProfileField from "../Components/SPProfileField";
import SPProfileStatus from "../Components/SPProfileStatus";
import SPProfileAddress from "../Components/SPProfileAddress";
import SPProfileDropDown from "../Components/SPProfileDropDown";
import useLocalisedConstants from "../../../customHooks/useLocalisedConstants";

export default function EmergencyForm({
  Countries,
  Data,
  editModeStatus,
  createContact,
  canceCreateMode,
  updateEmergencyContact,
  deleteEmergencyContact,
}) {
  const { t } = useTranslation();

  const appConstants = useLocalisedConstants();

  const [emergencyContactBody, setEmergencyContactBody] =
    useState(emergencyContactObj);

  useEffect(() => {
    setEmergencyContactBody({
      name: get(Data, "name", ""), // M
      relationship: get(Data, "relationship", ""), // M
      home_phone: get(Data, "home_phone", ""),
      work_phone: get(Data, "work_phone", ""),
      mobile_phone: get(Data, "mobile_phone", ""), // M
      address_1: get(Data, "address_1", ""),
      address_2: get(Data, "address_2", ""),
      address_3: get(Data, "address_3", ""),
      address_4: get(Data, "address_4", ""),
      address_5: get(Data, "address_5", ""),
      post_code: get(Data, "post_code", ""), // M
      permission: get(Data, "permission", "0").toString(), // M
      primary: get(Data, "primary", "0").toString(), // M
      id: get(Data, "id", null), // M
    });
  }, [Data]);

  const createEmergencyContact = () => {
    const { name, relationship, mobile_phone } = emergencyContactBody;
    if (!name || !relationship || !mobile_phone) {
      showFaliureToast(t("fieldsMarkedAs") + "*" + t("areMandatory"));
    } else {
      createContact(emergencyContactBody);
    }
  };

  const updateEmergencyUserInformation = () => {
    const { name, relationship, mobile_phone } = emergencyContactBody;
    if (!name || !relationship || !mobile_phone) {
      showFaliureToast(t("fieldsMarkedAs") + "*" + t("areMandatory"));
    } else {
      updateEmergencyContact(emergencyContactBody);
    }
  };

  const deleteContact = () => {
    deleteEmergencyContact(emergencyContactBody.id);
  };

  const handlePlacePayload = (place, value) => {
    let address1 = "";
    let postcode = "";
    let countryName = "";
    let townCity = "";
    let politicalCountry = "";

    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name} ${address1}`;
          break;
        }
        case "route": {
          address1 += component.long_name;
          break;
        }
        case "administrative_area_level_1": {
          countryName = component.long_name;
          break;
        }
        case "country" || "political": {
          politicalCountry = component.long_name;
          break;
        }
        case "administrative_area_level_2": {
          townCity = component.short_name;
          break;
        }
        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          break;
        }
        case "postal_code_prefix": {
          postcode = postcode
            ? `${postcode}-${component.long_name}`
            : component.long_name;
          break;
        }
      }
    }

    setEmergencyContactBody({
      ...emergencyContactBody,
      address_1: value,
      address_2: townCity,
      address_3: address1,
      address_4: countryName,
      address_5: politicalCountry,
      post_code: postcode,
      country: getCountryIDFromName(countryName, Countries),
    });
  };

  return (
    <div>
      <div className="accordion-body p-0">
        <SPProfileField
          title={t("name")}
          isRequired={true}
          type="text"
          value={emergencyContactBody.name}
          onChange={(e) =>
            setEmergencyContactBody({
              ...emergencyContactBody,
              name: e.target.value,
            })
          }
          placeholder={t("name")}
        />
        <SPProfileDropDown
          title={t("relationship")}
          value={emergencyContactBody.relationship}
          onChange={(e) =>
            setEmergencyContactBody({
              ...emergencyContactBody,
              relationship: e.target.value,
            })
          }
          dataArray={appConstants.relationships}
          displayKey="title"
          valueKey="value"
          isRequired={true}
        />
        <SPProfileField
          title={t("mobilePhone")}
          type="text"
          value={emergencyContactBody.mobile_phone}
          onChange={(e) =>
            setEmergencyContactBody({
              ...emergencyContactBody,
              mobile_phone: e.target.value,
            })
          }
          placeholder={t("mobilePhone")}
          isRequired={true}
        />
        <SPProfileField
          title={t("homePhone")}
          type="text"
          value={emergencyContactBody.home_phone}
          onChange={(e) =>
            setEmergencyContactBody({
              ...emergencyContactBody,
              home_phone: e.target.value,
            })
          }
          placeholder={t("homePhone")}
        />
        <SPProfileField
          title={t("workPhone")}
          type="number"
          value={emergencyContactBody.work_phone}
          onChange={(e) =>
            setEmergencyContactBody({
              ...emergencyContactBody,
              work_phone: e.target.value,
            })
          }
          placeholder={t("workPhone")}
        />
        <SPProfileAddress
          title={`${t("addressLine")} 1`}
          handleSelection={handlePlacePayload}
          setValue={(value) =>
            setEmergencyContactBody({
              ...emergencyContactBody,
              address_1: value,
            })
          }
          value={emergencyContactBody.address_1}
        />
        <SPProfileField
          title={`${t("addressLine")} 2`}
          onChange={(e) =>
            setEmergencyContactBody({
              ...emergencyContactBody,
              address_2: e.target.value,
            })
          }
          value={emergencyContactBody.address_2}
          placeholder={`${t("addressLine")} 2`}
        />
        <SPProfileField
          title={`${t("addressLine")} 3`}
          value={emergencyContactBody.address_3}
          onChange={(e) =>
            setEmergencyContactBody({
              ...emergencyContactBody,
              address_3: e.target.value,
            })
          }
          placeholder={`${t("addressLine")} 3`}
        />
        <SPProfileField
          title={`${t("addressLine")} 4`}
          value={emergencyContactBody.address_4}
          onChange={(e) =>
            setEmergencyContactBody({
              ...emergencyContactBody,
              address_4: e.target.value,
            })
          }
          placeholder={`${t("addressLine")} 4`}
        />
        <SPProfileField
          title={`${t("addressLine")} 5`}
          value={emergencyContactBody.address_5}
          onChange={(e) =>
            setEmergencyContactBody({
              ...emergencyContactBody,
              address_5: e.target.value,
            })
          }
          placeholder={`${t("addressLine")} 5`}
        />
        <SPProfileField
          title={t("postcode")}
          value={emergencyContactBody.post_code}
          onChange={(e) =>
            setEmergencyContactBody({
              ...emergencyContactBody,
              post_code: e.target.value,
            })
          }
          placeholder={t("postcode")}
          isRequired={false}
        />
        <SPProfileStatus
          title={t("contactPermission")}
          isActive={emergencyContactBody.permission == "1"}
          isRequired={true}
          onClick={(value) =>
            setEmergencyContactBody({
              ...emergencyContactBody,
              permission: value.toString(),
            })
          }
        />
        <div className="myFlex-cs flex-wrap-sm px-2 pt-2 my-4">
          <label className="col-12 col-sm-12 col-md-4">
            {t("primaryContact")}
          </label>
          <div className="col-12 col-sm-12 col-md-8 ">
            <label className="switch">
              <input
                checked={emergencyContactBody.primary == "1"}
                onChange={(e) =>
                  setEmergencyContactBody({
                    ...emergencyContactBody,
                    primary: e.target.checked ? "1" : "0",
                  })
                }
                type="checkbox"
              />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        <label className="ml-2 mt-2">
          {t("fieldsMarkedAs")}
          <span className="text-danger">{" * "}</span>
          {t("areMandatory")}
        </label>
        <div className="col-12 col-sm-12 col-md d-flex justify-content-end pr-2">
          <button
            onClick={() =>
              editModeStatus
                ? updateEmergencyUserInformation()
                : createEmergencyContact()
            }
            className={`btn btn-primary-cs mt-3`}
          >
            {t("save")}
          </button>
          {!editModeStatus && (
            <button
              className="btn btn-primary-outline-cs mt-3 ml-2"
              onClick={() => {
                canceCreateMode();
                setEmergencyContactBody(emergencyContactObj);
              }}
            >
              {t("cancel")}
            </button>
          )}
          {editModeStatus && (
            <button
              className="btn btn-danger mt-3 ml-2"
              onClick={() => deleteContact()}
            >
              {t("delete")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useRef, useState, useEffect } from "react";
import AppConstants from "../../../Helpers/AppConstants";
import ViewOnGoogle from "../../AppButtons/ViewOnGoogle";
import $ from "jquery";

export default function SPProfileAddress({
  isRequired,
  title,
  handleSelection,
  setValue,
  value,
  disabled,
}) {
  const autoCompleteRef = useRef();
  const addressRef = useRef(null);

  const [latlong, setLatLong] = useState({
    latitude: "",
    longitude: "",
  });

  useEffect(() => {
    addressRef.current.value = value;
  }, [value]);

  const getAutoCompleteLocationHandler = () => {
    $(document).ready(function () {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        addressRef.current,
        AppConstants.addressOptions
      );
      autoCompleteRef.current.addListener("");
      autoCompleteRef.current.addListener("place_changed", async function () {
        const place = await autoCompleteRef.current.getPlace();
        setLatLong({
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        });
        handleSelection(place, addressRef.current.value);
      });
    });
  };

  return (
    <>
      <div className="myFlex-cs flex-wrap-sm px-2 pt-2">
        <label className="col-12 col-sm-12 col-md-4">
          {title}
          {isRequired && <span className="text-danger">*</span>}
        </label>
        <div className="col-12 col-sm-12 col-md-8 ">
          <input
            disabled={disabled}
            className="filter-form col-10"
            ref={addressRef}
            placeholder={value ? value : "Enter address here..."}
            onFocus={getAutoCompleteLocationHandler}
            onChange={(e) => {
              if (e.target.value === "") {
                setValue("");
              }
            }}
            style={{ background: disabled ? "#dddd" : "" }}
          />
        </div>
      </div>
      {value && latlong.latitude && (
        <ViewOnGoogle latlong={latlong} padding={" px-2"} />
      )}
    </>
  );
}

import React from "react";
import GoogleMapReact from "google-map-react";
import Marker from "../Marker/Marker";
import { get } from "lodash";
import { useTranslation } from "react-i18next";

export default function Map({ coords, isPermission }) {
  const { t } = useTranslation();
  return (
    <div
      style={{ height: "400px", width: "100%", marginTop: "2%" }}
      data-aos-duration="1500"
      data-aos="fade-in"
    >
      {isPermission ? (
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDgz7IoSN_2Lyjk0Kt38Mkah8pW2LkJakI" }}
          center={coords}
          yesIWantToUseGoogleMapApiInternals
          defaultZoom={15}
        >
          <Marker
            lat={get(coords, "lat", "")}
            lng={get(coords, "lng", "")}
            name={t("myMarker")}
            color="#00aff0"
          />
        </GoogleMapReact>
      ) : (
        <div className="d-flex align-items-center justify-content-center">
          <h5>{t("locationPermissionMsg")}</h5>
        </div>
      )}
    </div>
  );
}

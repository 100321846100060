import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Applogger } from "./Logger";
import { getCompanySettingsOB } from "../Redux/reducers/SettingsReducer";
import { useNavigate } from "react-router-dom";
import { get } from "lodash";
import { showInfoToast } from "../Utilities";
import AppConstants from "./AppConstants";

export default function UseCompanySettings({ refresh }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { companySettingsOB } = useSelector((state) => state.calender);
  const { token, user } = useSelector((state) => state.AuthenticationReducer);
  const { lng } = useSelector((state) => state.languageReducer);
  const isAdmin = get(user, "is_admin", 0);
  const [companySettingsLocal, setCompanySettingsLocal] = useState(null);

  useEffect(() => {
    if (companySettingsOB && companySettingsOB !== null) {
      setCompanySettingsLocal(companySettingsOB);
    }
  }, []);

  useEffect(() => {
    if (companySettingsOB && companySettingsOB !== null) {
      getCompanySettingsRequest();
    } else if (refresh) {
      getCompanySettingsRequest();
    }
  }, [refresh]);

  const getCompanySettingsRequest = () => {
    dispatch(getCompanySettingsOB({ token, lng }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getAbsenceType", res);
        if (isAdmin == 1) {
          if (get(res, "is_onboarded", 0) !== 1) {
            navigate(AppConstants.routes.onBoarding);
            showInfoToast("Please complete company onboarding");
          }
        }
        // currentEmployeeTypeHandler()
      })
      .catch((err) => Applogger("Error at getAbsenceType", err));
  };

  return { companySettingsLocal };
}

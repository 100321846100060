import React from "react";

export const EmployeeFormButton = ({
  onClick,
  title = "",
  addClass = "",
  addStyle = {},
  disabled,
}) => {
  return (
    <button
      className={`btn btn-primary-cs btn-outline ${addClass}`}
      style={addStyle}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

export const EmployeeBackButton = ({ onClick, title = "", addClass = "" }) => {
  return (
    <button
      className={`btn btn-primary-outline-cs ${addClass}`}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export const CreateShiftNextButton = ({ onClick, title = "", disabled }) => {
  return (
    <button
      disabled={disabled}
      className={"btn btn-primary-cs btn-outline "}
      onClick={onClick}
    >
      {title}
    </button>
  );
};
export const CreateShiftBackButton = ({ onClick, title = "", disabled }) => {
  return (
    <button
      disabled={disabled}
      className={"btn btn-primary-outline-cs"}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

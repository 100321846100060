import React from "react";
import { useTranslation } from "react-i18next";
import { TerminationStatusButton } from "../userEmployment/EditContractModal";

export default function SPProfileStatus({
  title = "",
  onClick = () => {},
  isRequired = false,
  isActive = false,
}) {
  const { t } = useTranslation();

  return (
    <div className="d-flex align-items-center justify-content-between ml-2 mt-4">
      <div className="col-md-4">
        <label className="mb-0">
          {title}
          {isRequired && <span className="text-danger">*</span>}
        </label>
      </div>
      <div className="col-md-8 d-flex">
        <TerminationStatusButton
          title={t("yes")}
          isActive={isActive}
          onClick={() => onClick("1")}
        />
        <TerminationStatusButton
          title={t("no")}
          isActive={!isActive}
          onClick={() => onClick("0")}
        />
      </div>
    </div>
  );
}

import React from "react";
import EmployeeCSV from "../OnBoardingComponents/EmployeeCSV";

export default function AddMultipleEmployees({
  employeeCreated,
  employeesCall,
}) {
  return (
    <React.Fragment>
      <EmployeeCSV
        employeesCall={employeesCall}
        employeesCreated={employeeCreated}
        customStyle={{
          backgroundColor: "white",
        }}
      />
    </React.Fragment>
  );
}

import React, { useState } from "react";
import { MultiStepForm, Step } from "react-multi-form";
import { useNavigate } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Applogger } from "../../Helpers/Logger";
import { emailRegex } from "./Forms/FormConstants";
import { employeeFormObj } from "./Constants";
import {
  checkEmailStatus,
  createEmployeeRequest,
} from "../../Redux/reducers/EmployeesReducer";
import {
  showFaliureToast,
  showSuccessToast,
  showWarningToast,
} from "../../Utilities";
import {
  EmployeeFormButton,
  EmployeeBackButton,
} from "./Forms/Components/Butttons";
import Summary from "./Forms/StepsComponents/Summary";
import EmployeeDetails from "./Forms/StepsComponents/EmployeeDetails";
import AppConstants from "../../Helpers/AppConstants";
import EmployeeType from "./Forms/Hours/EmployeeType";
import DoneAddEmployee from "./Forms/Hours/DoneAddEmployee";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";
import RoleAndLocation from "./Forms/RoleAndLocation";
import BankDetails from "./Forms/StepsComponents/BankDetails";
import UserAddress from "./Forms/StepsComponents/UserAddress";
import ContractDetails from "./Forms/StepsComponents/ContractDetails";
import EmergencyContact from "./Forms/StepsComponents/EmergencyContact";

export default function AddEmployee(props) {
  const { setShowAddEmployee, employeeCreated, cancelClick } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { token } = useSelector((state) => state.AuthenticationReducer);

  const { exist: createEmployee } = usePrevilagesExist(
    AppConstants.previlages.createEmployee
  );

  const [isValidatingEmail, setIsValidatingEmail] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [currentId, setCurrentId] = useState("");
  const [employeeForm, setEmployeeForm] = useState(employeeFormObj);

  const addAnotherEmployeeHandler = () => {
    cancelClick();
    setTimeout(() => {
      setShowAddEmployee(true); // Chaipi
    }, 300);
  };

  const goToProfileHandler = () => {
    navigate(AppConstants.routes.profile + `?id=${currentId}`);
  };

  const onSubmitClick = () => {
    dispatch(createEmployeeRequest({ employeeForm, token }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at createEmployeeRequest", res);
        showSuccessToast(res?.message ?? "Employee Created Successfully");
        setCurrentStep(8);
        employeeCreated();
        if (res.id) {
          setCurrentId(res.id);
        }
      })
      .catch((err) => {
        showFaliureToast(
          err?.message ?? "Failed to create user please check details"
        );
        Applogger("Error at createEmployeeRequest", err);
      });
  };

  const validateEmail = () => {
    setIsValidatingEmail(true);
    dispatch(checkEmailStatus({ email: employeeForm.email, token: token }))
      .then(unwrapResult)
      .then((res) => {
        setIsValidatingEmail(false);
        setCurrentStep(3);
        showSuccessToast("Email validated successfully");
        Applogger("Response at validate email: ", res);
      })
      .catch((err) => {
        setIsValidatingEmail(false);
        showFaliureToast(err);
        Applogger("Error at validate email: ", err);
      });
  };

  const validateEmployeeType = () => {
    if (!employeeForm.user_type) {
      showWarningToast("Please select employee type");
    } else {
      setCurrentStep(2);
    }
  };

  const validateEmployeeDetails = () => {
    const {
      title,
      first_name,
      last_name,
      email,
      mobile_phone,
      gender,
      date_of_birth,
      marital_status,
      start_date,
    } = employeeForm;
    if (
      !title ||
      !first_name ||
      !last_name ||
      !email ||
      !mobile_phone ||
      !gender ||
      !date_of_birth ||
      !marital_status ||
      !start_date
    ) {
      showWarningToast("Please fill all mandatory fields");
      return;
    } else if (employeeForm.email) {
      if (emailRegex.test(employeeForm.email)) {
        validateEmail();
      } else {
        showWarningToast("Please enter valid email address");
      }
    }
  };

  const validateRoleAndLocation = () => {
    const { role, work_place_id, work_place_type, country_id } = employeeForm;
    if (!role || !country_id || (!work_place_id && !work_place_type)) {
      showWarningToast("Please fill all mandatory fields");
      return;
    } else {
      setCurrentStep(4);
    }
  };

  const validateBankDetails = () => {
    const { bank_account_number, bank_name, bank_account_name, sort_code } =
      employeeForm;
    if (
      !bank_account_number ||
      !bank_name ||
      !bank_account_name ||
      !sort_code
    ) {
      showWarningToast("Please fill all mandatory fields");
      return;
    } else {
      setCurrentStep(5);
    }
  };

  const validateUserAddress = () => {
    const { address_1, address_2, post_code } = employeeForm;
    if (!address_1 || !address_2 || !post_code) {
      showWarningToast("Please fill all mandatory fields");
      return;
    } else {
      setCurrentStep(6);
    }
  };

  const validateContractDetails = () => {
    const {
      employee_reference,
      tax_code,
      ni_number,
      // ni_category,
      payment_frequency,
      // sort_code,
      right_to_work_confirm_status,
    } = employeeForm;
    if (
      !employee_reference ||
      !tax_code ||
      !ni_number ||
      // !ni_category ||
      !payment_frequency ||
      // !sort_code ||
      !right_to_work_confirm_status
    ) {
      showWarningToast("Please fill all mandatory fields");
      return;
    } else {
      setCurrentStep(7);
    }
  };

  const validateEmergencyContact = () => {
    const { emergency_contact, contact_telephone_no, contact_relationship } =
      employeeForm;
    if (!emergency_contact || !contact_telephone_no || !contact_relationship) {
      showWarningToast("Please fill all mandatory fields");
      return;
    } else {
      onSubmitClick();
    }
  };

  const onBackClick = () => {
    setCurrentStep(currentStep - 1);
  };

  const onNextClick = () => {
    Applogger("Employee Form", employeeForm);
    if (currentStep === 1) {
      validateEmployeeType();
    } else if (currentStep === 2) {
      validateEmployeeDetails();
    } else if (currentStep === 3) {
      validateRoleAndLocation();
    } else if (currentStep === 4) {
      validateBankDetails();
    } else if (currentStep === 5) {
      validateUserAddress();
    } else if (currentStep === 6) {
      validateContractDetails();
    } else if (currentStep === 7) {
      validateEmergencyContact();
    } else {
      Applogger("Reached To Summary Tab");
    }
  };

  return (
    <div>
      <div>
        <MultiStepForm
          class={"sda"}
          accentColor={"#3DB0F7"}
          activeStep={currentStep}
        >
          <Step label={t("employeeType")}>
            <EmployeeType
              createEmployee={createEmployee}
              userType={employeeForm.user_type}
              setCurrentUserType={(value) => {
                setEmployeeForm({ ...employeeForm, user_type: value });
              }}
            />
          </Step>
          <Step label={t("employeeDetails")}>
            <EmployeeDetails
              currentType={employeeForm.user_type}
              employeeForm={employeeForm}
              setEmployeeForm={setEmployeeForm}
              isValidatingEmail={isValidatingEmail}
            />
          </Step>
          <Step label={t("roleAndLocation")}>
            <RoleAndLocation
              currentType={employeeForm.user_type}
              employeeForm={employeeForm}
              setEmployeeForm={setEmployeeForm}
            />
          </Step>
          <Step label={t("bankDetails")}>
            <BankDetails
              currentType={employeeForm.user_type}
              employeeForm={employeeForm}
              setEmployeeForm={setEmployeeForm}
            />
          </Step>
          <Step label={t("address")}>
            <UserAddress
              currentType={employeeForm.user_type}
              employeeForm={employeeForm}
              setEmployeeForm={setEmployeeForm}
            />
          </Step>
          <Step label={t("contractDetails")}>
            <ContractDetails
              currentType={employeeForm.user_type}
              employeeForm={employeeForm}
              setEmployeeForm={setEmployeeForm}
            />
          </Step>
          <Step label={t("emergencyContact")}>
            <EmergencyContact
              currentType={employeeForm.user_type}
              employeeForm={employeeForm}
              setEmployeeForm={setEmployeeForm}
            />
          </Step>
          <Step label={t("done")}>
            <DoneAddEmployee
              currentType={employeeForm.user_type}
              cancelClick={() => cancelClick()}
              goToProfile={() => goToProfileHandler()}
              addAnotherEmployee={() => addAnotherEmployeeHandler()}
              employeeForm={employeeForm}
            />
          </Step>
        </MultiStepForm>
      </div>
      {currentStep != 1 && currentStep != 8 && (
        <label className="col-sm-12 col-md-4 mb-0 mt-4">
          {t("fieldsMarkedAs")}
          <span className="text-danger">{" * "}</span>
          {t("areMandatory")}
        </label>
      )}
      {currentStep !== 8 && (
        <div className="d-flex gap-2 my-4">
          {currentStep !== 1 && (
            <EmployeeBackButton
              onClick={() => onBackClick()}
              title={t("back")}
            />
          )}
          {currentStep !== 7 && (
            <EmployeeFormButton
              onClick={() => onNextClick()}
              title={t("nextStep")}
            />
          )}
          {currentStep === 7 && (
            <EmployeeFormButton
              onClick={() => onSubmitClick()}
              title={t("submit")}
              disabled={
                !employeeForm.emergency_contact ||
                !employeeForm.contact_telephone_no ||
                !employeeForm.contact_relationship
              }
            />
          )}
          <EmployeeBackButton onClick={cancelClick} title={t("cancel")} />
        </div>
      )}
    </div>
  );
}

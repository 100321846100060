import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  createLogOverTime,
  getUserLog,
  updateLogOverTime,
} from "../../../Redux/reducers/ProfileReducers";
import {
  showFaliureToast,
  showSuccessToast,
  unixToDateFormater,
} from "../../../Utilities";
import { unwrapResult } from "@reduxjs/toolkit";
import { Applogger } from "../../../Helpers/Logger";
import { useTitleHandler } from "../../../customHooks/useTitleHandler";
import { preventMinus } from "../../../Utilities/index";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import Header from "../../../components/Header/Header";
import moment from "moment";
import Spinner from "../../Spinner";
import AppConstants from "../../../Helpers/AppConstants";

export default function Setting() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useTitleHandler("Log Overtime");

  const { token } = useSelector((state) => state.AuthenticationReducer);
  const { user, loading } = useSelector((state) => state.profile);
  const { lng } = useSelector((state) => state.languageReducer);
  const { t } = useTranslation();
  const [overTimeData, setOverTimeData] = useState({
    rewardType: "TOIL",
    overTimeHours: "",
    overTimeMinutes: "",
    payRate: "1.0 (standard rate)",
  });
  const [currentTimeStart, setCurrentTimeStart] = useState("");
  const [currentTimeStartVisible, setCurrentTimeStartVisible] = useState("");
  const [currentTimeStartHour, setCurrentTimeStartHour] = useState("");
  const [currentTimeStartMin, setCurrentTimeStartMin] = useState("");
  const [currentDateStart, setCurrentDateStart] = useState("");
  const [currentTimeEnd, setCurrentTimeEnd] = useState("");
  const [currentTimeEndVisible, setCurrentTimeEndVisible] = useState("");

  const [currentTimeEndHour, setCurrentTimeEndHour] = useState("");
  const [currentTimeEndMin, setCurrentTimeEndMin] = useState("");
  const [editChecker, setEditChecker] = useState(false);
  const [currentDateEnd, setCurrentDateEnd] = useState("");
  const [note, setNote] = useState("");

  const locationState = get(location, "state", null);

  useEffect(() => {
    if (locationState !== null) {
      setEditChecker(true);
      getLogOverTime();
    } else {
      setEditChecker(false);
    }
  }, [locationState]);

  const dateToTimestamp = (date, hour, min) => {
    let result = unixToDateFormater(date, AppConstants.defaultDateFormat);
    result = result + " " + hour + ":" + min + ":" + "00";
    return moment(result).format();
  };

  const settingLogData = (data) => {
    setCurrentTimeStart(
      unixToDateFormater(
        data.start_date,
        `${AppConstants.defaultDateFormat} ${AppConstants.timeFormat}:ss`
      )
    );
    setCurrentTimeStartHour(data.start_hour);
    setCurrentTimeStartMin(data.start_min);
    setCurrentDateStart(
      unixToDateFormater(data.start_date, AppConstants.defaultDateFormat)
    );
    setCurrentTimeEnd(
      unixToDateFormater(
        data.end_date,
        `${AppConstants.defaultDateFormat} ${AppConstants.timeFormat}:ss`
      )
    );
    setCurrentTimeEndHour(data.end_hour);
    setCurrentTimeEndMin(data.end_min);
    setCurrentDateEnd(
      unixToDateFormater(data.end_date, AppConstants.defaultDateFormat)
    );

    const start_date = dateToTimestamp(
      data.start_date,
      data.start_hour,
      data.start_min
    );
    const end_date = dateToTimestamp(
      data.end_date,
      data.end_hour,
      data.end_min
    );
    setCurrentTimeStartVisible(start_date.slice(0, 19));
    setCurrentTimeEndVisible(end_date.slice(0, 19));

    setNote(data.notes);
    setOverTimeData({
      ...overTimeData,
      overTimeHours: data.overtime_length_hours,
      overTimeMinutes: data.overtime_length_min,
    });
  };

  const getLogOverTime = () => {
    dispatch(
      getUserLog({
        bearer_token: token,
        log_id: location.state.overTimeId,
        lng,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        settingLogData(res.data);
      })
      .catch((err) => {
        Applogger("Error at getUserLog", err.message);
      });
  };

  const createLog = () => {
    const logData = {
      overTimeData,
      currentTimeStartHour,
      currentTimeStartMin,
      currentDateStart,
      currentTimeEndHour,
      currentTimeEndMin,
      currentDateEnd,
      note,
    };
    if (editChecker) {
      dispatch(
        updateLogOverTime({ token, logData, log_id: location.state.overTimeId })
      )
        .then(unwrapResult)
        .then((res) => {
          if (res.status === 200) {
            showSuccessToast("Log Updated Successfully");
            navigate(AppConstants.routes.profile);
          }
        })
        .catch(() => {
          showFaliureToast("Update Log Failed");
        });
    } else {
      dispatch(createLogOverTime({ token, logData, user_id: user.user_id }))
        .then(unwrapResult)
        .then((res) => {
          if (res.status === 200) {
            showSuccessToast("Log Created Successfully");
            navigate(AppConstants.routes.profile);
          }
        })
        .catch(() => {
          showFaliureToast("Create Log Failed");
        });
    }
  };

  const gettingOverTimeStart = (data) => {
    setCurrentTimeStartVisible(data);
    setCurrentTimeStart(
      moment(data).format(
        `${AppConstants.defaultDateFormatMandatoryLeave} ${AppConstants.timeFormat}:ss`
      )
    );
    setCurrentTimeStartHour(moment(data).format("H"));
    setCurrentTimeStartMin(moment(data).format("m"));
    setCurrentDateStart(
      moment(data).format(AppConstants.defaultDateFormatMandatoryLeave)
    );
    data = data.replace("T", " ");
    let temp = moment(data).format(
      `${AppConstants.defaultDateFormatMandatoryLeave} ${AppConstants.timeFormat}:ss`
    );
    if (currentTimeEnd != "") {
      var ms = moment(
        currentTimeEnd,
        `${AppConstants.defaultDateFormatMandatoryLeave} ${AppConstants.timeFormat}:ss`
      ).diff(
        moment(
          temp,
          `${AppConstants.defaultDateFormatMandatoryLeave} ${AppConstants.timeFormat}:ss`
        )
      );
      var d = moment.duration(ms);
      setOverTimeData({
        ...overTimeData,
        overTimeHours: Math.floor(d.asHours()),
        overTimeMinutes: moment.utc(ms).format("m"),
      });
    }
  };
  const gettingOverTimeEnd = (data) => {
    setCurrentTimeEndVisible(data);
    setCurrentTimeEnd(
      moment(data).format(
        `${AppConstants.defaultDateFormatMandatoryLeave} ${AppConstants.timeFormat}:ss`
      )
    );
    setCurrentTimeEndHour(moment(data).format("H"));
    setCurrentTimeEndMin(moment(data).format("m"));
    setCurrentDateEnd(
      moment(data).format(AppConstants.defaultDateFormatMandatoryLeave)
    );
    data = data.replace("T", " ");
    let temp = moment(data).format(
      `${AppConstants.defaultDateFormatMandatoryLeave} ${AppConstants.timeFormat}:ss`
    );
    if (currentTimeStart != "") {
      var ms = moment(
        temp,
        `${AppConstants.defaultDateFormatMandatoryLeave} ${AppConstants.timeFormat}:ss`
      ).diff(
        moment(
          currentTimeStart,
          `${AppConstants.defaultDateFormatMandatoryLeave} ${AppConstants.timeFormat}:ss`
        )
      );
      var d = moment.duration(ms);
      setOverTimeData({
        ...overTimeData,
        overTimeHours: Math.floor(d.asHours()),
        overTimeMinutes: moment.utc(ms).format("m"),
      });
    }
  };

  const handleProfileNavigation = () => {
    navigate(AppConstants.routes.profile + `?id=${user.user_id}`);
    // navigate(AppConstants.routes.profile, { state: { data: user.user_id } });
  };

  return (
    <div>
      {loading == "pending" && <Spinner />}
      <Header active={"Settings"} />
      <br />
      {user && (
        <div className="container_custom">
          <h1 className="top-heading">
            {t("LogOvertimeFor")}
            {` ${user.first_name} ${user.last_name}`}{" "}
          </h1>

          <div className="d-flex mt-3">
            <h4>{t("overtimeStart")}</h4>
          </div>
          <div className="d-flex">
            <div className="w-50">
              <input
                value={currentTimeStartVisible}
                onChange={(e) => gettingOverTimeStart(e.target.value)}
                type={"datetime-local"}
                className="filter-form"
              />
            </div>
          </div>
          <div className="d-flex mt-3">
            <h4>{t("overtimeEnd")}</h4>
          </div>
          <div className="d-flex">
            <div className="w-50">
              <input
                value={currentTimeEndVisible}
                onChange={(e) => gettingOverTimeEnd(e.target.value)}
                type={"datetime-local"}
                className="filter-form"
              />
            </div>
          </div>
          <div className="d-flex mt-3">
            <h4>{t("notes")}</h4>
          </div>
          <div className="d-flex mt-2">
            <div className="w-50">
              <textarea
                value={note}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
                className="filter-form"
              />
            </div>
          </div>
          <div
            className={`mt-3 logOverTimeSelectCard  h-100 ${editChecker == true ? "disabled" : ""
              }`}
          >
            <div className="d-flex pt-2 ml-2">
              <div className="ml-2">
                <div>{t("overtimeLength")}</div>
                <div className="d-flex w-50">
                  <input
                    value={overTimeData.overTimeHours}
                    disabled={editChecker}
                    onChange={(e) =>
                      setOverTimeData({
                        ...overTimeData,
                        overTimeHours: e.target.value.replace(/^0+/, ""),
                      })
                    }
                    type={"number"}
                    onKeyPress={preventMinus}
                    placeholder={t("hours")}
                    className="form-control mt-2 w-50"
                  />
                  <input
                    value={overTimeData.overTimeMinutes}
                    disabled={editChecker}
                    onChange={(e) =>
                      setOverTimeData({
                        ...overTimeData,
                        overTimeMinutes: e.target.value.replace(/^0+/, ""),
                      })
                    }
                    type={"number"}
                    onKeyPres={preventMinus}
                    placeholder={t("min")}
                    className="form-control mt-2 ml-2 w-50"
                  />
                </div>
              </div>
              {overTimeData.rewardType === "Payable" && (
                <div className="ml-2">
                  <div>{t("payRate")}</div>
                  <div>
                    <select
                      onChange={(e) =>
                        setOverTimeData({
                          ...overTimeData,
                          payRate: e.target.value,
                        })
                      }
                      className=" form-control w-100"
                    >
                      <option value={"1.0 (standard rate)"}>
                        {" "}
                        1.0(standard rate)
                      </option>
                    </select>
                  </div>
                </div>
              )}
            </div>
            <div className="logOverTimeSelectCardFooter mt-4 h-">
              {`${overTimeData.overTimeHours ? overTimeData.overTimeHours : "0"
                } hours ${overTimeData.overTimeMinutes
                  ? overTimeData.overTimeMinutes
                  : "00"
                } minutes`}
            </div>
          </div>

          <div className="d-flex">
            <button
              onClick={() => createLog()}
              className="btn-primary-cs btn"
            >{`${editChecker ? "Update Overtime" : t("logOvertime")}`}</button>
            <button
              onClick={() => handleProfileNavigation()}
              className="btn-primary-outline-cs btn ml-2"
            >
              {t("backToProfile")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

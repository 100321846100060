import React, { useState, useEffect } from "react";
import { get } from "lodash";
import { useTitleHandler } from "../../customHooks/useTitleHandler";
import { useDispatch, useSelector } from "react-redux";
import { Applogger } from "../../Helpers/Logger";
import { styled } from "@mui/material/styles";
import { getCountries } from "../../Redux/reducers/EmployeesReducer";
import { useSearchParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import {
  getWorkingHoursPatterns,
  getPlacesOfWork,
  getAllRolesRequest,
} from "../../Redux/reducers/SettingsReducer";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Header from "../../components/Header/Header";
import UserAbsence from "../../components/Profile/userAbsence/UserAbsence";
import UserOverTime from "../../components/Profile/userOverTime/UserOvertime";
import UserPersonal from "../../components/Profile/userPersonal/userPersonal";
import ProfileDetails from "../../components/Profile/profileDetails/ProfileDetails";
import ProfileDocuments from "../../components/Profile/userDocuments/UserDocuments";
import ResetUserPassword from "../../components/Profile/ResetUserPassword/ResetUserPassword";
import ProfileEmployement from "../../components/Profile/userEmployment/UserEmployement";
import ResendRegistrationEmail from "../../components/Profile/ResendRegistrationEmail/ResendRegistrationEmail";
import AppConstants from "../../Helpers/AppConstants";
import UseLogoutHandler from "../../Helpers/useLogoutHandler";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";

import "./Profile.css";

export default function Profile() {
  const dispatch = useDispatch();

  const [params] = useSearchParams();

  const { t } = useTranslation();

  useTitleHandler(t("dtProfile"));

  const type = params.get("tab");

  const { exist: listPersonalDocs } = usePrevilagesExist(
    AppConstants.previlages.listPersonalDocs
  );
  const { exist: createOvertimeLog } = usePrevilagesExist(
    AppConstants.previlages.createOvertimeLog
  );
  const { exist: listOvertimeLog } = usePrevilagesExist(
    AppConstants.previlages.listOvertimeLog
  );
  const { roles, placesOfWork, workingHoursPatterns } = useSelector(
    (state) => state.settings
  );
  const { countries } = useSelector((state) => state.employees);
  const { lng } = useSelector((state) => state.languageReducer);
  const { user: loggedInUser, token } = useSelector(
    (state) => state.AuthenticationReducer
  );
  const { user } = useSelector((state) => state.profile);

  const currentUserId = params.get("id");

  const [value, setValue] = useState("0");
  const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      minWidth: 0,
      [theme.breakpoints.up("sm")]: {
        minWidth: 0,
      },
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(1),
      color: "rgba(0, 0, 0, 0.85)",
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:hover": {
        color: "#40a9ff",
        opacity: 1,
      },
      "&.Mui-selected": {
        color: "#1890ff",
        fontWeight: theme.typography.fontWeightMedium,
      },
      "&.Mui-focusVisible": {
        backgroundColor: "#d1eaff",
      },
    })
  );
  const companyId = get(user, "company_id", "");

  useEffect(() => {
    if (token) {
      if (countries.length === 0) {
        getAllCountries();
      }
      // if (roles.length === 0) {
      getAllRolesRequestHandler();
      // }
      // if (placesOfWork.length === 0) {
      callGetWorkPlacesAPI();
      // }
      if (workingHoursPatterns.length == 0) {
        handleGetWorkingHoursPatterns();
      }
    }
  }, []);

  useEffect(() => {
    if (type && type == "my_documents") {
      setValue("5");
    }
  }, [type]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getCurrentUserId = () => {
    return currentUserId ? currentUserId : user?.user_id;
  };

  function handleGetWorkingHoursPatterns() {
    dispatch(getWorkingHoursPatterns({ token, lng, companyId }))
      .then(unwrapResult)
      .then(() => {
        Applogger("Working hours patterns received successfully");
      })
      .catch(() => {
        Applogger("Found error while fetching Working hour patterns");
      });
  }

  function getAllCountries() {
    dispatch(getCountries({ token, lng }))
      .then(unwrapResult)
      .then((response) => {
        Applogger("Countries received successfully", response);
      })
      .catch((err) => {
        Applogger("Found error while fetching countries", err);
      });
  }
  const callGetWorkPlacesAPI = () => {
    dispatch(getPlacesOfWork({ token, lng, companyId }))
      .then(unwrapResult)
      .then((response) => {})
      .catch((err) => {
        Applogger("Error at getPlacesOfWork", err.message);
      });
  };

  const getAllRolesRequestHandler = () => {
    dispatch(getAllRolesRequest({ token, companyId }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getAllRolesRequest", res);
      })
      .catch((err) => {
        Applogger("Error at getAllRolesRequest", err);
      });
  };

  return (
    <div>
      <Header />
      {token && (
        <div className="container_custom animate__animated animate__backInUp">
          <ProfileDetails currentUserId={getCurrentUserId()} />
          <br />
          <div className="border-cs-grey rounded">
            <Box sx={{ width: "100%" }}>
              <TabContext value={`${value}`}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <AntTab label={t("employmentInfo")} value={"0"} />
                    <AntTab label={t("personalInfo")} value={"1"} />
                    <AntTab label={t("absenceRecord")} value={"2"} />
                    {listOvertimeLog && createOvertimeLog && (
                      <AntTab label={t("overtimeLog")} value={"4"} />
                    )}
                    {listPersonalDocs && (
                      <AntTab label={t("myDocuments")} value={"5"} />
                    )}
                    {currentUserId == get(loggedInUser, "user_id", "") && (
                      <AntTab label={t("resetPassword")} value={"6"} />
                    )}
                    {getCurrentUserId() != get(loggedInUser, "user_id", "") && (
                      <AntTab label={t("invite")} value={"7"} />
                    )}
                  </TabList>
                </Box>
              </TabContext>
              <TabPanel value={value} index={"0"}>
                <ProfileEmployement currentUserId={getCurrentUserId()} />
              </TabPanel>
              <TabPanel value={value} index={"1"}>
                <UserPersonal currentUserId={getCurrentUserId()} />
              </TabPanel>
              <TabPanel value={value} index={"2"}>
                <UserAbsence currentUserId={getCurrentUserId()} />
              </TabPanel>
              {listOvertimeLog && createOvertimeLog && (
                <TabPanel value={value} index={"4"}>
                  <UserOverTime currentUserId={getCurrentUserId()} />
                </TabPanel>
              )}
              {listPersonalDocs && (
                <TabPanel value={value} index={"5"}>
                  <ProfileDocuments currentUserId={getCurrentUserId()} />
                </TabPanel>
              )}
              {currentUserId == get(loggedInUser, "user_id", "") && (
                <TabPanel value={value} index={"6"}>
                  <ResetUserPassword currentUserId={getCurrentUserId()} />
                </TabPanel>
              )}
              {getCurrentUserId() != get(loggedInUser, "user_id", "") && (
                <TabPanel value={value} index={"7"}>
                  <ResendRegistrationEmail currentUserId={getCurrentUserId()} />
                </TabPanel>
              )}
            </Box>
          </div>
        </div>
      )}
    </div>
  );
}

function TabPanel(props) {
  UseLogoutHandler();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { Applogger } from "../../Helpers/Logger";
import { useTranslation } from "react-i18next";
import { get, times } from "lodash";
import {
  getCalenderPendingRequest,
  postApprove,
  RequestCalenderData,
} from "../../Redux/reducers/CalenderReducer";
import {
  showFaliureToast,
  showSuccessToast,
  unixToDateFormater,
} from "../../Utilities";
import {
  deletetUserAbsence,
  getUserAbsence,
} from "../../Redux/reducers/ProfileReducers";
import CustomToolBar from "./CustomToolbar";
import moment from "moment";
import AppConstants from "../../Helpers/AppConstants";
import AbsenceDetailModal from "./AbsenceDetailModal";
import "../../bigCalender.css";

export default function CalendarSection({ logoutUser, onModalPopupChange }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const localizer = momentLocalizer(moment);

  const { lng } = useSelector((state) => state.languageReducer);
  const { token, user, role } = useSelector(
    (state) => state.AuthenticationReducer
  );
  const { profileAbsence } = useSelector((state) => state.profile);
  const { events, absenceTypes } = useSelector((state) => state.calender);

  const [selectedEvent, setSelectedEvent] = useState(undefined);
  const [modalState, setModalState] = useState(false);
  const [userAbsenceTypes, setUserAbsenceTypes] = useState([]);
  const [eventsData, setEventData] = useState([]);

  const cancelFutureLeave = get(profileAbsence, "cancel_future_leave", "");
  const userId = get(user, "user_id", "");

  useEffect(() => {
    if (token) {
      getAbsenceData();
      getDataHandler();
    }
  }, []);

  useEffect(() => {
    if (absenceTypes && absenceTypes.length > 0) {
      setUserAbsenceTypes(absenceTypes);
    }
  }, [absenceTypes]);

  useEffect(() => {
    setEventsLocally();
  }, [events]);

  useEffect(() => {
    onModalPopupChange(modalState);
  }, [modalState]);

  const convertTimeStampToDate = (timeStamp) => {
    var convertedTimeStamp = moment
      .unix(timeStamp)
      .zone(0)
      .format("DD MMM YYYY");
    const finalDate = new Date(convertedTimeStamp);
    return finalDate;
  };

  const getAbsenceData = () => {
    dispatch(getUserAbsence({ bearer_token: token, user_id: userId }))
      .then(unwrapResult)
      .then((response) => {
        Applogger("Response at getAbsenceData", response);
      })
      .catch((err) => {
        Applogger("Error at getUserAbsence", err.message);
      });
  };

  const setEventsLocally = () => {
    let tempData = [];
    var startDate = "";
    var endDate = "";
    var eventEndDate = "";

    if (Array.isArray(events) && events.length > 0) {
      events.forEach((element) => {
        startDate = convertTimeStampToDate(element.start_date);
        endDate = convertTimeStampToDate(element.end_date);
        eventEndDate = convertTimeStampToDate(element.end_date + 24 * 60 * 60);
        tempData.push({
          startDate: startDate,
          endDate: endDate,
          eventEndDate: eventEndDate,
          title: element.first_name + " " + element.last_name,
          firstName: element.first_name,
          lastName: element.last_name,
          isAbsence: element.is_absence_going,
          absenceType: element.absence_type,
          colour: element.colour,
          is_other: element.is_other,
          absence_type_id: element.absence_type_id,
          start_time: element.start_time,
          end_time: element.end_time,
          duration_mins: element.duration_mins,
          duration_hours: element.duration_hours,
          start_type: element.start_type,
          annual_leave_id: element.annual_leave_id,
          notes: element.notes,
          absence_type_name: element.absence_type_name,
          start_half_day_type: element.start_half_day_type,
          end_half_day_type: element.end_half_day_type,
          end_type: element.end_type,
          name: element.name,
          user_id: element.user_id,
          user_type: element.user_type,
        });
      });

      setEventData(tempData);
    }
  };

  const getDataHandler = () => {
    dispatch(RequestCalenderData({ token, lng }))
      .then(unwrapResult)
      .then((response) => {})
      .catch((err) => {
        if (err.message == "Request failed with status code 401") {
          logoutUser();
          showFaliureToast("Please Login To Continue");
        }
        Applogger("Error at getDataHandler", err.message);
      });
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    let backgroundColor;

    try {
      if (event.is_other == 1) {
        backgroundColor = "#EC1522";
      } else {
        if (userAbsenceTypes.length > 0) {
          const currentEventIndex = userAbsenceTypes.findIndex(
            (absence) => absence.absence_type_id == event.absence_type_id
          );
          if (currentEventIndex !== -1) {
            backgroundColor = event.colour;
          }
        }
      }
    } catch (err) {
      Applogger("Error at eventStyleGetter", err);
    }

    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "0px",
    };
    return {
      style: style,
    };
  };

  const handleSelectedEvent = (event) => {
    setSelectedEvent(event);
    setModalState(true);
  };

  const toggleModal = () => {
    setModalState(!modalState);
  };

  const handleSync = () => {
    getDataHandler();
    setEventsLocally();
  };

  const getPendingRequests = () => {
    dispatch(getCalenderPendingRequest({ token, lng: lng }))
      .then(unwrapResult)
      .then((res) => {
        // setIsLoading(false);
      })
      .catch((err) => {
        // setIsLoading(false);
      });
  };

  const changeStatusRequest = (val) => {
    try {
      var reason = "";
      const { status, id } = val;
      if (val.reason) {
        reason = val.reason;
      }
      dispatch(postApprove({ status, id, token, reason }))
        .then(unwrapResult)
        .then((res) => {
          Applogger("Response at postApprove", res);
          getDataHandler();
          getPendingRequests();
          setModalState(false);
          showSuccessToast(`Request ${status} successfully`);
        })
        .catch((err) => {
          showFaliureToast(`Request ${status} failed`);
        });
    } catch (err) {
      Applogger("Error in postApprove catch", err);
    }
  };

  const deleteAbsenceHandler = (id) => {
    dispatch(
      deletetUserAbsence({
        annual_leave_id: id,
        bearer_token: token,
        lng,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        getDataHandler();
        setEventsLocally();
        setModalState(false);
        showSuccessToast("Absence Removed succesfully");
        Applogger("Response at deletetUserAbsence", res);
      })
      .catch((err) => {
        Applogger("Error at deletetUserAbsence", err.message);
        showFaliureToast("Something Went Wrong");
      });
  };
  // const CustomToolBar = () => {
  //   return (
  //     <div>
  //       <span>
  //         <button
  //           className="btn-lg-light btn btn-primary-outline-cs"
  //           type="button"
  //         >
  //           Prev
  //         </button>
  //       </span>
  //       <button className="btn-lg-light btn btn-primary-cs ml-2">
  //         This Month
  //       </button>
  //       <span>
  //         <button
  //           className="btn-lg-light btn btn-primary-outline-cs ml-2"
  //           type="button"
  //         >
  //           Next
  //         </button>
  //       </span>
  //     </div>
  //   );
  // };

  return (
    <div>
      <div className="d-flex  flex-wrap justify-content-between border border-1 p-2 rounded-2">
        <div className="animate__animated animate__fadeInRight  p-0 d-flex align-items-center flex-wrap">
          <h4 className="fw-bolder mb-0">
            {moment(new Date()).format(AppConstants.dateFormat)}
          </h4>
        </div>
        <div className="animate__animated animate__fadeInRight  d-flex justify-content-end">
          <button
            onClick={() => navigate(AppConstants.routes.absence)}
            className="btn btn-sm-dark m-2"
          >
            {t("requestLeave")}
          </button>
          <button
            className="btn-lg-light btn btn-primary-outline-cs font-weight-bold m-2"
            onClick={() => handleSync()}
          >
            {t("sync")}
          </button>
        </div>
      </div>
      <br />
      <div className="d-flex gap-2 flex-wrap mb-2 animate__animated animate__fadeInDown">
        <div>
          <span
            className="col-dot rounded-5"
            style={{ backgroundColor: "#A4907C" }}
          ></span>
          <p className="d-inline fs-16">{t("othersPendingRequests")}</p>
        </div>
        {userAbsenceTypes?.length > 0 &&
          userAbsenceTypes?.map((val, index) => {
            return (
              val.is_other == 0 && (
                <div key={index}>
                  <span
                    className="col-dot  rounded-5"
                    style={{ backgroundColor: val.colour }}
                  ></span>
                  <p className="d-inline fs-16">{val.name}</p>
                </div>
              )
            );
          })}

        <div>
          <span
            className="col-dot rounded-5"
            style={{ backgroundColor: "#EC1522" }}
          ></span>
          <p className="d-inline fs-16">{t("other")}</p>
        </div>
      </div>

      <br />

      <AbsenceDetailModal
        deleteAbsence={deleteAbsenceHandler}
        modalState={modalState}
        getAbsence={getDataHandler}
        toggleModal={toggleModal}
        changeStatusRequest={changeStatusRequest}
        role={role}
        canDelete={cancelFutureLeave}
        selectedEvent={selectedEvent}
      />
      <div className="animate__animated animate__fadeInUp">
        <Calendar
          localizer={localizer}
          events={eventsData}
          rtl={lng == "ar"}
          startAccessor="startDate"
          endAccessor="eventEndDate"
          selectable={true}
          onSelectEvent={(e) => handleSelectedEvent(e)}
          components={{ toolbar: CustomToolBar }}
          style={{ height: "60vh" }}
          popup={true}
          eventPropGetter={(event, start, end, isSelected) =>
            eventStyleGetter(event, start, end, isSelected)
          }
        />
      </div>
    </div>
  );
}

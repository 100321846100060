import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { changeEmployeeWorkingStatus } from "../../Redux/reducers/EmployeesReducer";
import { showSuccessToast, showFaliureToast } from "../../Utilities";
import { Applogger } from "../../Helpers/Logger";
import UserStatusModal from "../Profile/profileDetails/UserStatusModal";
import EmployeeModal from "../Employees/EmployeeModal";
import EmployeeCard from "../Employees/EmployeeCard";
import AppConstants from "../../Helpers/AppConstants";

export default function PeopleListModal({
  isModalVisible,
  handleCancel,
  location,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { allEmployees } = useSelector((state) => state.employees);
  const { token } = useSelector((state) => state.AuthenticationReducer);

  const [employeeModalVisible, setEmployeeModalVisible] = useState(false);
  const [showUserStatusModal, setShowUserStatusModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  const showUserDetail = (userDetail) => {
    setEmployeeModalVisible(true);
    setShowModal(true);
    setUserDetails(userDetail);
  };

  const setUserStatus = (status) => {
    let payload = {
      token: token,
      working_status: status,
      user_id: userDetails.user_id,
    };
    dispatch(changeEmployeeWorkingStatus(payload))
      .then(unwrapResult)
      .then((res) => {
        showSuccessToast("Employee Working Status Updated Successfully");
        setShowUserStatusModal(false);
      })
      .catch((err) => {
        showFaliureToast("Failed To Update Employee Working Status");
        setShowUserStatusModal(false);
      });
  };

  return (
    <div>
      <Modal
        destroyOnClose={true}
        visible={isModalVisible}
        onOk={handleCancel}
        onCancel={handleCancel}
        width={900}
        preserve={false}
        title={get(location, "location_type_name", "")}
      >
        {showModal && userDetails != null && (
          <EmployeeModal
            showStatusModal={() => {
              setShowUserStatusModal(true);
              setEmployeeModalVisible(false);
            }}
            onCancel={() => setEmployeeModalVisible(false)}
            modalStatus={employeeModalVisible}
            data={userDetails}
          />
        )}
        <UserStatusModal
          onCancel={() => setShowUserStatusModal(false)}
          modalStatus={showUserStatusModal}
          setStatus={(status) => setUserStatus(status)}
          changeStatus={(e) => Applogger("Not Need Function")}
          status={get(userDetails, "working_status", "")}
        />
        <div className="d-flex align-items-center flex-wrap gap-4 team-cards sp-card-grid">
          {allEmployees
            .filter(
              (employee) =>
                get(location, "work_place_id", null) ==
                get(employee, "work_place_id", "")
            )
            .map((val, index) => {
              return (
                <EmployeeCard
                  key={index}
                  showUserDetail={showUserDetail}
                  val={val}
                />
              );
            })}
        </div>
      </Modal>
    </div>
  );
}

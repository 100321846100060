import { createReducer, combineReducers } from "@reduxjs/toolkit";
import { AuthenticationReducer } from "../reducers/AuthenticationReducer";
import { DashbordReducer } from "../reducers/DashboardReducer";
import { StaffingReducer } from "../reducers/StaffingReducer";
import { EmployeeReducer } from "../reducers/EmployeesReducer";
import { ReportReducer } from "../reducers/ReportsReducer";
import { ProfileReducer } from "../reducers/ProfileReducers";
import { CalendersReducer } from "../reducers/CalenderReducer";
import { DocumentReducer } from "../reducers/DocumentsReducer";
import { TimesheetReducer } from "../reducers/TimesheetReducer";
import { SettingsReducer } from "../reducers/SettingsReducer";
import { languageReducer as LanguageReducer } from "../reducers/LanguageReducer";

const appState = {
  name: "SpeedyHR",
  url: "",
  version: "1.0.0",
};

const appReducer = createReducer(appState, (_) => {
  return appState;
});

export const rootReducer = combineReducers({
  app: appReducer,
  AuthenticationReducer: AuthenticationReducer,
  DashboardReducer: DashbordReducer,
  staffing: StaffingReducer,
  employees: EmployeeReducer,
  reports: ReportReducer,
  profile: ProfileReducer,
  calender: CalendersReducer,
  document: DocumentReducer,
  timesheet: TimesheetReducer,
  settings: SettingsReducer,
  languageReducer: LanguageReducer,
});

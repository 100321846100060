import React, { useState } from "react";
import TextInputField from "../../components/TextInputField";
import Spinner from "../../components/Spinner";
import AppConstants from "../../Helpers/AppConstants";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../Redux/reducers/AuthenticationReducer";
import { unwrapResult } from "@reduxjs/toolkit";
import { showSuccessToast, showFaliureToast } from "../../Utilities";
import { AppImages } from "../../Helpers/AppImages";
import { validateEmail } from "../../Helpers/AppValidators";
import { useTitleHandler } from "../../customHooks/useTitleHandler";
import { useTranslation } from "react-i18next";
import { Applogger } from "../../Helpers/Logger";
import "./Login.css";

export default function Forgot() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  useTitleHandler(t("forgotPassword"));

  const forgotUserPasswordHandler = (e) => {
    e.preventDefault();
    if (userEmail == "") {
      showFaliureToast("Please enter email to continue");
    } else if (!validateEmail(userEmail)) {
      showFaliureToast("Please enter valid email address to continue");
    } else {
      callForgotPasswordAPI();
    }
  };

  const callForgotPasswordAPI = () => {
    setIsLoading(true);
    dispatch(
      forgotPassword({
        email: userEmail,
        url: window.location.origin + AppConstants.routes.resetPassword,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at forgotPassword", res);
        showSuccessToast("Reset Password E-mail Sent Successfully");
        setUserEmail("");
        navigate(AppConstants.routes.login);

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        showFaliureToast(err);
        Applogger("Error at forgotPassword", err);
      });
  };

  return (
    <div>
      {isLoading && <Spinner size={36} />}
      <div
        style={{ height: "100vh" }}
        className="wrapper login-bg d-flex align-items-center justify-content-center w-100"
      >
        <div className="d-flex flex-column flex-wrap wrapper-2-cs">
          <a href={AppConstants.routes.login} className="navbrand mb-3 mt-2">
            <img src={AppImages.speedyHrIconHd} alt="speedyhr" />
          </a>
          <h3 className="fw-bold text-info">{t("forgotPassword")}</h3>
          <p className="small mb-0 text-black-50">
            {t("enterYourEmailAddress")}
          </p>
          <div>
            <TextInputField
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              type="email"
              placeholder={t("emailAddress")}
            />
            <div className="myFlex-cs">
              <button
                onClick={forgotUserPasswordHandler}
                className="btn btn-primary-cs mt-2"
              >
                {t("send")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTitleHandler } from "../../customHooks/useTitleHandler";
import Spinner from "../../components/Spinner";
import Header from "../../components/Header/Header";
import UseLogoutHandler from "../../Helpers/useLogoutHandler";
import AppConstants from "../../Helpers/AppConstants";
import SharedDocumentsList from "../../components/Documents/SharedDocumentsList";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";

export default function SharedDocuments() {
  const { loading } = useSelector((state) => state.document);
  const { t } = useTranslation();
  useTitleHandler(t("sharedDocuments"));
  UseLogoutHandler();

  const { exist: listSharedDocuments } = usePrevilagesExist(
    AppConstants.previlages.listSharedDocuments
  );

  return (
    <div>
      {loading == "pending" && <Spinner />}
      <Header active={"Shared Documents"} />
      <br />
      <div className="sp_main">
        <div className="myFlex-cs flex-wrap-sm">
          <h1 className="top-heading">{t("sharedDocuments")}</h1>
        </div>
      </div>
      <div className="sp_main">
        {listSharedDocuments && <SharedDocumentsList />}
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { useSelector } from "react-redux";
import {
  formattedDateForAPI,
  showFaliureToast,
  unixToDateFormater,
} from "../../../Utilities";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import { preventMinus } from "../../../Utilities/index";
import { contractInfoObj } from "../Constants";
import useLocalisedConstants from "../../../customHooks/useLocalisedConstants";
import AppConstants from "../../../Helpers/AppConstants";

export default function EditContractModal({
  modalStatus,
  onModalCancel,
  editFormData,
}) {
  // Reducer
  const { userEmployementContractInfo: contractInfo } = useSelector(
    (state) => state.profile
  );
  // Localized
  const { t } = useTranslation();
  const appConstants = useLocalisedConstants();

  // Constants
  const noNoticePeriod = "No Notice Period";
  const noProbationPeriod = "No Probation Period";

  // Local States
  const [contractInfoBody, setContractInfoBody] = useState(contractInfoObj);

  useEffect(() => {
    if (modalStatus || contractInfo) {
      setContractInfoBody({
        contract_date: unixToDateFormater(
          get(contractInfo, "contract_date", ""),
          AppConstants.defaultDateFormat
        ), // Done M
        date_directorship_began: unixToDateFormater(
          get(contractInfo, "date_directorship_began", ""),
          AppConstants.defaultDateFormat
        ), // Done
        director_status: get(contractInfo, "director_status", ""), // Done
        employee_reference: get(contractInfo, "employee_reference", ""), // Done M
        employment_type: get(contractInfo, "employment_type", ""), // Done
        is_probation: get(contractInfo, "is_probation", "0"), // Done M
        is_terminated: get(contractInfo, "is_terminated", "0"), // Done M
        probation_notice_period_type: get(
          contractInfo,
          "probation_notice_period_type",
          noNoticePeriod
        ), // Done M
        probation_notice_period_value: get(
          contractInfo,
          "probation_notice_period_value",
          ""
        ), // Done M
        probation_period_end_date: unixToDateFormater(
          get(contractInfo, "probation_period_end_date", ""),
          AppConstants.defaultDateFormat
        ), // Done M
        probation_period_type: get(
          contractInfo,
          "probation_period_type",
          noProbationPeriod
        ), // Done M
        probation_period_value: get(contractInfo, "probation_period_value", ""), // Done M
        reason: get(contractInfo, "reason", ""), // Done M
        termination_date: unixToDateFormater(
          get(contractInfo, "termination_date", ""),
          AppConstants.defaultDateFormat
        ), // Done M
        termination_reason: get(contractInfo, "termination_reason", ""), // Done M
        first_day_of_civilian_employment: unixToDateFormater(
          get(contractInfo, "first_day_of_civilian_employment", "")
        ), // Done
        auto_enrolment_exclusion_reason: get(
          contractInfo,
          "auto_enrolment_exclusion_reason",
          ""
        ), // Done
        works_reference: get(contractInfo, "works_reference", ""), // Done
      });
    }
  }, [modalStatus, contractInfo]);

  const isModuleAllowed = () => {
    return (
      contractInfoBody.probation_period_type &&
      contractInfoBody.probation_period_type !== noProbationPeriod
    );
  };

  const isNPModuleAllowed = () => {
    return (
      contractInfoBody.probation_notice_period_type &&
      contractInfoBody.probation_notice_period_type !== noNoticePeriod
    );
  };

  const disableHandler = () => {
    const { contractType, reasonForUpdateStatus } = contractInfoBody;

    if (contractType == "" || reasonForUpdateStatus == "") {
      return true;
    } else {
      return false;
    }
  };

  const submitEditForm = () => {
    const {
      contract_date,
      employee_reference,
      reason,
      is_terminated,
      probation_notice_period_type,
      probation_notice_period_value,
      probation_period_end_date,
      probation_period_type,
      probation_period_value,
      termination_date,
      termination_reason,
    } = contractInfoBody;

    if (!contract_date || !employee_reference || !reason) {
      showFaliureToast(t("fieldsMarkedAs") + "*" + t("areMandatory"));
    } else if (
      is_terminated == "1" &&
      (!termination_date || !termination_reason)
    ) {
      showFaliureToast(t("fieldsMarkedAs") + "*" + t("areMandatory"));
    } else if (
      probation_period_type != noProbationPeriod &&
      (!probation_period_value || !probation_period_end_date)
    ) {
      showFaliureToast(t("fieldsMarkedAs") + "*" + t("areMandatory"));
    } else if (
      probation_period_type != noProbationPeriod &&
      probation_notice_period_type != noNoticePeriod &&
      !probation_notice_period_value
    ) {
      showFaliureToast(t("fieldsMarkedAs") + "*" + t("areMandatory"));
    } else {
      editFormData(contractInfoBody);
    }
  };

  const handleProbationPeriod = (value) => {
    if (value && value == noProbationPeriod) {
      setContractInfoBody({
        ...contractInfoBody,
        is_probation: "0",
        probation_period_type: value,
        probation_period_value: "",
        probation_period_end_date: "",
        probation_notice_period_value: "",
        probation_notice_period_type: noNoticePeriod,
      });
    } else {
      setContractInfoBody({
        ...contractInfoBody,
        probation_period_type: value,
        is_probation: "1",
      });
    }
  };

  return (
    <Modal
      title={t("editContractInfo")}
      centered
      width={800}
      footer={false}
      visible={modalStatus}
      onCancel={onModalCancel}
    >
      <div className="row">
        <div className="modal-body modal-cs ">
          <div className="d-flex flex-column gap-2">
            <div className="myFlex-cs flex-wrap-sm w-100 ">
              <div className="col-12 col-sm-12 col-md-6">
                <label className="mb-0">{t("contractStartDate")}</label>
                <span className="text-danger">*</span>
              </div>
              <div className="col-12 col-sm-12 col-md-6">
                <input
                  value={contractInfoBody.contract_date}
                  onChange={(e) =>
                    setContractInfoBody({
                      ...contractInfoBody,
                      contract_date: e.target.value,
                    })
                  }
                  type={"date"}
                  className="filter-form"
                />
              </div>
            </div>

            <div className="myFlex-cs flex-wrap-sm w-100 ">
              <div className="col-12 col-sm-12 col-md-6">
                <label>{t("reasonForUpdate")}</label>
                <span className="text-danger">*</span>
              </div>
              <div className="col-12 col-sm-12 col-md-6">
                <select
                  defaultValue={contractInfoBody.reason}
                  value={contractInfoBody.reason}
                  onChange={(e) =>
                    setContractInfoBody({
                      ...contractInfoBody,
                      reason: e.target.value,
                    })
                  }
                  className="filter-form"
                >
                  {appConstants.reasonsToUpdate.map((val, index) => {
                    return (
                      <option value={val.name} key={index}>
                        {val.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="myFlex-cs flex-wrap-sm w-100 ">
              <div className="col-12 col-sm-12 col-md-6">
                <label className="mb-0">{t("firstDayCivilianEmp")}</label>
              </div>
              <div className="col-12 col-sm-12 col-md-6">
                <input
                  value={contractInfoBody.first_day_of_civilian_employment}
                  onChange={(e) =>
                    setContractInfoBody({
                      ...contractInfoBody,
                      first_day_of_civilian_employment: e.target.value,
                    })
                  }
                  type={"date"}
                  className="filter-form"
                />
              </div>
            </div>
            <div className="myFlex-cs flex-wrap-sm w-100">
              <div className="col-12 col-sm-12 col-md-6">
                <label>{t("autoEnrExReason")}</label>
              </div>
              <div className="col-12 col-sm-12 col-md-6">
                <select
                  defaultValue={
                    contractInfoBody.auto_enrolment_exclusion_reason
                  }
                  value={contractInfoBody.auto_enrolment_exclusion_reason}
                  onChange={(e) =>
                    setContractInfoBody({
                      ...contractInfoBody,
                      auto_enrolment_exclusion_reason: e.target.value,
                    })
                  }
                  className="filter-form"
                >
                  {appConstants.autoEnrExReasons.map((val, index) => {
                    const { title, value } = val;
                    return (
                      <option key={index} value={value}>
                        {title}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="myFlex-cs flex-wrap-sm w-100 ">
              <div className="col-12 col-sm-12 col-md-6">
                <label className="mb-0">{t("dateDirectorshipBegan")}</label>
              </div>
              <div className="col-12 col-sm-12 col-md-6">
                <input
                  value={contractInfoBody.date_directorship_began}
                  onChange={(e) =>
                    setContractInfoBody({
                      ...contractInfoBody,
                      date_directorship_began: e.target.value,
                    })
                  }
                  type={"date"}
                  className="filter-form"
                />
              </div>
            </div>
            <div className="myFlex-cs flex-wrap-sm w-100">
              <div className="col-12 col-sm-12 col-md-6">
                <label>{t("directorStatus")}</label>
              </div>
              <div className="col-12 col-sm-12 col-md-6">
                <select
                  defaultValue={contractInfoBody.director_status}
                  value={contractInfoBody.director_status}
                  onChange={(e) =>
                    setContractInfoBody({
                      ...contractInfoBody,
                      director_status: e.target.value,
                    })
                  }
                  className="filter-form"
                >
                  {appConstants.directorStatus.map((val, index) => {
                    const { title, value } = val;
                    return (
                      <option key={index} value={value}>
                        {title}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="myFlex-cs flex-wrap-sm w-100 ">
              <div className="col-12 col-sm-12 col-md-6">
                <label className="mb-0">{t("employeeReference")}</label>
                <span className="text-danger">*</span>
              </div>
              <div className="col-12 col-sm-12 col-md-6">
                <input
                  value={contractInfoBody.employee_reference}
                  onChange={(e) =>
                    setContractInfoBody({
                      ...contractInfoBody,
                      employee_reference: e.target.value,
                    })
                  }
                  type={"text"}
                  className="filter-form"
                />
              </div>
            </div>
            <div className="myFlex-cs flex-wrap-sm w-100 ">
              <div className="col-12 col-sm-12 col-md-6">
                <label className="mb-0">{t("worksReference")}</label>
              </div>
              <div className="col-12 col-sm-12 col-md-6">
                <input
                  value={contractInfoBody.works_reference}
                  onChange={(e) =>
                    setContractInfoBody({
                      ...contractInfoBody,
                      works_reference: e.target.value,
                    })
                  }
                  type={"text"}
                  className="filter-form"
                />
              </div>
            </div>
            <div className="myFlex-cs flex-wrap-sm w-100 ">
              <div className="col-12 col-sm-12 col-md-6">
                <label className="mb-0">{t("terminated")}</label>
                <span className="text-danger">*</span>
              </div>
              <div className="col-12 col-sm-12 col-md-6 d-flex my-2">
                <TerminationStatusButton
                  title={t("yes")}
                  isActive={contractInfoBody.is_terminated == "1"}
                  onClick={() =>
                    setContractInfoBody({
                      ...contractInfoBody,
                      is_terminated: "1",
                    })
                  }
                />
                <TerminationStatusButton
                  title={t("no")}
                  isActive={contractInfoBody.is_terminated == "0"}
                  onClick={() =>
                    setContractInfoBody({
                      ...contractInfoBody,
                      is_terminated: "0",
                      termination_date: "",
                      termination_reason: "",
                    })
                  }
                />
              </div>
            </div>
            {contractInfoBody.is_terminated == "1" && (
              <>
                <div className="myFlex-cs flex-wrap-sm w-100 ">
                  <div className="col-12 col-sm-12 col-md-6">
                    <label className="mb-0"> {t("terminationDate")}</label>
                    <span className="text-danger">*</span>
                  </div>

                  <div className="col-12 col-sm-12 col-md-6">
                    <input
                      value={contractInfoBody.termination_date}
                      onChange={(e) =>
                        setContractInfoBody({
                          ...contractInfoBody,
                          termination_date: e.target.value,
                        })
                      }
                      type={"date"}
                      className="filter-form"
                    />
                  </div>
                </div>
                <div className="myFlex-cs flex-wrap-sm w-100 ">
                  <div className="col-12 col-sm-12 col-md-6">
                    <label className="mb-0"> {t("terminationReason")}</label>
                    <span className="text-danger">*</span>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6">
                    <select
                      value={contractInfoBody.termination_reason}
                      onChange={(e) =>
                        setContractInfoBody({
                          ...contractInfoBody,
                          termination_reason: e.target.value,
                        })
                      }
                      className="filter-form"
                    >
                      {appConstants.terminationReasons.map((val, index) => {
                        const { value, title } = val;
                        return (
                          <option key={index} value={value}>
                            {title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </>
            )}
            <div className="myFlex-cs flex-wrap-sm w-100">
              <div className="col-12 col-sm-12 col-md-6">
                <label>{t("employementTypes")}</label>
              </div>
              <div className="col-12 col-sm-12 col-md-6">
                <select
                  defaultValue={contractInfoBody.employment_type}
                  value={contractInfoBody.employment_type}
                  onChange={(e) =>
                    setContractInfoBody({
                      ...contractInfoBody,
                      employment_type: e.target.value,
                    })
                  }
                  className="filter-form"
                >
                  {appConstants.employementTypes.map((val, index) => {
                    const { title, value } = val;
                    return (
                      <option key={index} value={value}>
                        {title}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="myFlex-cs flex-wrap-sm w-100 ">
              <div className="col-12 col-sm-12 col-md-6">
                <label className="mb-0">{t("probationPeriod")}</label>
                <span className="text-danger">*</span>
              </div>
              <div className="col-12 col-sm-12 col-md-6">
                <div className="d-flex justify-between flex-wrap-sm">
                  {isModuleAllowed() && (
                    <div className="col-12 col-sm-12 col-md-3">
                      <input
                        value={contractInfoBody.probation_period_value}
                        defaultValue={contractInfoBody.probation_period_value}
                        type={"number"}
                        maxLength={5}
                        onKeyPress={preventMinus}
                        onChange={(e) => {
                          setContractInfoBody({
                            ...contractInfoBody,
                            probation_period_value: e.target.value,
                          });
                        }}
                        className="filter-form"
                      />
                    </div>
                  )}
                  <div
                    className={`col-12 col-sm-12 col-md-8 ${!isModuleAllowed() && "col-md-12"
                      }`}
                  >
                    <select
                      defaultValue={contractInfoBody.probation_period_type}
                      value={contractInfoBody.probation_period_type}
                      onChange={(e) => handleProbationPeriod(e.target.value)}
                      className="filter-form"
                    >
                      {appConstants.probationPeriod.map((val, index) => {
                        return (
                          <option key={index} value={val.value}>
                            {val.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {isModuleAllowed() && (
              <>
                <div className="myFlex-cs flex-wrap-sm w-100 ">
                  <div className="col-12 col-sm-12 col-md-6">
                    <label className="mb-0">
                      {t("probationPeriodEndDate")}
                    </label>
                    <span className="text-danger">*</span>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6">
                    <input
                      value={contractInfoBody.probation_period_end_date}
                      onChange={(e) =>
                        setContractInfoBody({
                          ...contractInfoBody,
                          probation_period_end_date: e.target.value,
                        })
                      }
                      type={"date"}
                      className="filter-form"
                    />
                  </div>
                </div>
                <div className="myFlex-cs flex-wrap-sm w-100 ">
                  <div className="col-12 col-sm-12 col-md-6">
                    <label className="mb-0">{t("noticeProbationPeriod")}</label>
                    <span className="text-danger">*</span>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6">
                    <div className="d-flex justify-between flex-wrap-sm">
                      {isNPModuleAllowed() && (
                        <div className="col-12 col-sm-12 col-md-3">
                          <input
                            value={
                              contractInfoBody.probation_notice_period_value
                            }
                            defaultValue={
                              contractInfoBody.probation_notice_period_value
                            }
                            type={"number"}
                            onKeyPress={preventMinus}
                            maxLength={5}
                            onChange={(e) =>
                              setContractInfoBody({
                                ...contractInfoBody,
                                probation_notice_period_value: e.target.value,
                              })
                            }
                            className="filter-form"
                          />
                        </div>
                      )}
                      <div
                        className={`col-12 col-sm-12 col-md-8 ${!isNPModuleAllowed() && "col-md-12"
                          }`}
                      >
                        <select
                          defaultValue={
                            contractInfoBody.probation_notice_period_type
                          }
                          value={contractInfoBody.probation_notice_period_type}
                          onChange={(e) =>
                            setContractInfoBody({
                              ...contractInfoBody,
                              probation_notice_period_type: e.target.value,
                            })
                          }
                          className="filter-form"
                        >
                          {appConstants.noticePeriod.map((val, index) => {
                            return (
                              <option key={index} value={val.value}>
                                {val.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <label className="mt-2">
            {t("fieldsMarkedAs")}
            <span className="text-danger">{" * "}</span>
            {t("areMandatory")}
          </label>
        </div>
        <div className="col-12 mt-3">
          <div>
            <button
              disabled={disableHandler()}
              onClick={() => submitEditForm()}
              data-mdb-dismiss="modal"
              className="btn btn-primary-cs"
            >
              {t("save")}
            </button>
            <button
              onClick={() => setContractInfoBody(contractInfoObj)}
              className="btn btn-primary-outline-cs ml-2"
            >
              {t("resetForm")}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export const TerminationStatusButton = ({ title, onClick, isActive }) => {
  return (
    <div
      onClick={onClick}
      className={`decisionCard cursor-pointer ${isActive ? "active" : ""}`}
    >
      {title}
    </div>
  );
};

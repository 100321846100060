import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import { DashboardApiServices } from "../actions/DashboardActions";
import {
  stopLoaderAndEmptyErrors,
  logoutRequest,
} from "./AuthenticationReducer";

export const DashboardRequest = createAsyncThunk(
  "DashboardReducer/DashboardRequest",
  async (payload) => {
    const response = await DashboardApiServices.getDashboardData(payload);
    return response;
  }
);

export const DashboardAbsenceRequest = createAsyncThunk(
  "DashboardReducer/DashboardAbsenceRequest",
  async (payload) => {
    const response = await DashboardApiServices.getDashboardAbsenceReport(
      payload
    );
    return response;
  }
);

export const DashboardAbsenceSummaryReport = createAsyncThunk(
  "DashboardReducer/DashboardAbsenceSummaryReport",
  async (payload) => {
    const response =
      await DashboardApiServices.getDashboardAbsenceSummaryReport(payload);
    return response;
  }
);

export const getDashboardWorkingStatus = createAsyncThunk(
  "DashboardReducer/getDashboardWorkingStatus",
  async (payload) => {
    const response = await DashboardApiServices.getDashboardWorkingStatus(
      payload
    );
    return response;
  }
);

export const postConsultationRequest = createAsyncThunk(
  "DashboardReducer/postConsultationRequest",
  async (payload) => {
    const response = await DashboardApiServices.postConsultation(payload);
    return response;
  }
);
const initialState = {
  alreadyLoggedIn: false,
  loading: "idle",
  error: null,
  data: [],
  absenceData: [],
  absenceSummary: [],
  workingStatus: [],
};

const states = {
  pending: "pending",
  idle: "idle",
};

export const DashbordReducer = createReducer(initialState, {
  [DashboardRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [DashboardRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      alreadyLoggedIn: true,
      data: action.payload.data,
      loading: states.idle,
      error: null,
    };
  },
  [DashboardRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [DashboardAbsenceRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [DashboardAbsenceRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
      absenceData: action.payload.data,
    };
  },
  [DashboardAbsenceRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [DashboardAbsenceSummaryReport.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [DashboardAbsenceSummaryReport.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
      absenceSummary: action.payload.data,
    };
  },
  [DashboardAbsenceSummaryReport.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [postConsultationRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [postConsultationRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [postConsultationRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [getDashboardWorkingStatus.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [getDashboardWorkingStatus.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
      workingStatus: action.payload.data,
    };
  },
  [getDashboardWorkingStatus.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [stopLoaderAndEmptyErrors.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [logoutRequest.fulfilled]: (state, action) => {
    return {
      ...initialState,
    };
  },
});

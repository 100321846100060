import React from "react";
import { Avatar } from "antd";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AppConstants from "../../Helpers/AppConstants";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";
import "./EmployeeCard.css";

export default function EmployeeCard({ val, showUserDetail }) {
  const { t } = useTranslation();

  const profileImage = get(val, "profile_image", null);
  const firstName = get(val, "first_name", "");
  const lastName = get(val, "last_name", "");
  const workingStatus = get(val, "working_status", "");

  const { roles, placesOfWork } = useSelector((state) => state.settings);

  const { exist: viewOtherProfile } = usePrevilagesExist(
    AppConstants.previlages.viewOtherProfile
  );

  const getRoleNameByID = (roleID) => {
    let finalRole = "N/A";
    roles.forEach((role) => {
      if (get(role, "role_id", "") == roleID) {
        finalRole = get(role, "name", "N/A");
      }
    });
    return finalRole;
  };

  const getLocationNameByID = (locationID) => {
    let finalLocation = "N/A";
    placesOfWork.forEach((location) => {
      if (get(location, "work_place_id", "") == locationID) {
        finalLocation = get(location, "name", "N/A");
      }
    });
    return finalLocation;
  };

  const employeeRole = getRoleNameByID(get(val, "role_id", ""));

  const workPlaceType = AppConstants.placeOfWorkArray.find(
    (item) => item.work_place_id == get(val, "work_place_type", "")
  );

  const employeeLocation = get(val, "work_place_id", "")
    ? getLocationNameByID(get(val, "work_place_id", ""))
    : get(workPlaceType, "name", "");

  return (
    <div
      className="shadow-md animate__animated animate__backInUp team-crd "
      style={{
        borderWidth: employeeRole == "N/A" ? 1 : 0,
        borderColor: employeeRole == "N/A" ? "red" : "",
        width: "30%",
      }}
    >
      <div className="employee-card">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <div style={{ alignSelf: "center" }}>
              {profileImage == null ? (
                <div className="profile">
                  <b style={{ textTransform: "uppercase" }}>
                    {`${firstName.slice(0, 1)}${lastName.slice(0, 1)}`}
                  </b>
                </div>
              ) : (
                <Avatar size={70} src={profileImage} />
              )}
            </div>
            <div
              className="ml-2 mt-2 text-container"
              style={{ wordBreak: "break-all" }}
            >
              <h6
                className="fw-bold"
                style={{ textTransform: "capitalize", marginBottom: 0 }}
              >
                {`${firstName} ${lastName}`}{" "}
                <span className="text-black-50">
                  <small className="small">{`(${employeeRole})`}</small>
                </span>
              </h6>
              {employeeLocation && (
                <p className="small" style={{ marginBottom: 0 }}>
                  {t("location")}
                  {`: ${employeeLocation}`}
                </p>
              )}
              {viewOtherProfile && (
                <>
                  <Link
                    className="text-pink fw-bold underline-cs"
                    to={AppConstants.routes.profile + `?id=${val.user_id}`}
                  >
                    {t("viewFullProfile")}
                  </Link>
                  <br />
                </>
              )}
              {workingStatus && (
                <span className="text-black-50 d-flex align-items-center">
                  <i className="ri-home-5-line me-2" />
                  <small className="small " style={{ paddingRight: "10px" }}>
                    {t(workingStatus)}
                  </small>
                </span>
              )}
            </div>
          </div>
          <button className="top-50" onClick={() => showUserDetail(val)}>
            <i className="ri-eye-line text-info fs-4" />
          </button>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { useSelector } from "react-redux";
import { Modal, Avatar } from "antd";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import AppConstants from "../../Helpers/AppConstants";

export default function EmployeeModal({
  data,
  showStatusModal,
  onCancel,
  modalStatus,
}) {
  const { t } = useTranslation();
  const { role } = useSelector((state) => state.AuthenticationReducer);

  const firstName = get(data, "first_name", "");
  const lastName = get(data, "last_name", "");
  const profileImage = get(data, "profile_image", null);
  const email = get(data, "email", "");
  const workPhone = get(data, "work_phone", null);
  const homePhone = get(data, "home_phone", "");
  const mobilePhone = get(data, "mobile_phone", "");
  const workingStatus = get(data, "working_status", "");

  const displayContactNumber = () => {
    if (mobilePhone) {
      return (
        <a
          href={`tel: ${mobilePhone}`}
          className="text-primary mb-0 d-flex align-items-center justify-content-center"
        >
          <i className="fs-5 ri-phone-line me-2" />
          <p className="text-black-50 mb-0 mx-1">{t("mobilePhone")}:</p>
          {mobilePhone}
        </a>
      );
    } else if (workPhone) {
      return (
        <a
          href={`tel: ${workPhone}`}
          className="text-primary mb-0 d-flex align-items-center justify-content-center"
        >
          <i className="fs-5 ri-phone-line me-2" />
          <p className="text-black-50 mb-0 mx-1">{t("workPhone")}:</p>
          {workPhone}
        </a>
      );
    } else if (homePhone) {
      return (
        <a
          href={`tel: ${homePhone}`}
          className="text-primary mb-0 d-flex align-items-center justify-content-center"
        >
          <i className="fs-5 ri-phone-line me-2" />
          <p className="text-black-50 mb-0 mx-1">{t("homePhone")}:</p>
          {homePhone}
        </a>
      );
    } else {
      return null;
    }
  };

  return (
    <Modal
      title={firstName + " " + lastName}
      centered
      onCancel={onCancel}
      visible={modalStatus}
      width={650}
      footer={false}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          flexDirection: "column",
        }}
      >
        {profileImage == null ? (
          <Avatar size={100} style={{ background: "#00A6F1" }}>
            <b style={{ textTransform: "uppercase" }}>{`${firstName.slice(
              0,
              1
            )}${lastName.slice(0, 1)}`}</b>
          </Avatar>
        ) : (
          <Avatar size={150} src={profileImage} />
        )}
        {/* <h6 className="fs-4 fw-light bold capitalize text-primary mt-2">
          {" "}
          {firstName + " " + lastName}
        </h6>
        {email && (
          <span className="d-flex align-items-center">
            <i className="ri-mail-line" />
            <a href={`mailto:${email}`} className="p-2 text-info">
              {email}
            </a>
          </span>
        )}
        {workPhone != null && (
          <span className="d-flex align-items-center">
            <i className="ri-phone-fill" />
            <a href={`tel:${workPhone}`} className="p-2 text-info">
              {workPhone}
            </a>
          </span>
        )} */}
        <div className="profile_name_email mt-4">
          <div>
            {firstName && (
              <h6 className="fs-4 fw-light bold capitalize text-primary text-center">
                {`${firstName} ${lastName} `}
              </h6>
            )}
            {displayContactNumber()}
            {email && (
              <a
                href={`mailto: ${email}`}
                className="text-primary mb-0 d-flex align-items-center justify-content-center"
              >
                <i className="fs-5 ri-mail-line me-2" />
                <p className="text-black-50 mb-0 mx-1">{t("email")}:</p>
                {email}
              </a>
            )}
          </div>
        </div>
        {workingStatus && (
          <div>
            <p className="d-flex align-items-center gap-1">
              <i className="text-primary ri-home-5-line fs-5" />
              {t(workingStatus)}
            </p>
            {role === AppConstants.roleTypes.Admin && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  className="btn btn-primary-cs me-2 mb-2"
                  onClick={showStatusModal}
                >
                  {t("setStatus")}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { get } from "lodash";
import { Applogger } from "../../../Helpers/Logger";
import { useTranslation } from "react-i18next";
import { showFaliureToast, showSuccessToast } from "../../../Utilities";
import {
  getUserProfileContactInfo,
  updateUserProfileContactInfo,
  getUserProfilePersonalInfo,
  updateUserProfilePersonalInfo,
  getUserMedicalInfo,
  updateUserMedicalInfo,
  updateProfileUserObjectInReducer,
} from "../../../Redux/reducers/ProfileReducers";
import { updateUserObjectInReducer } from "../../../Redux/reducers/AuthenticationReducer";
import ContactForm from "./ContactInfoForm";
import MedicalInfo from "./MedicalInfoForm";
import UserEmergencies from "../userEmergency/userEmergencies";
import PersonalInfoForm from "./PersonalInfoForm";

export default function UserPersonal({ currentUserId }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { countries } = useSelector((state) => state.employees);
  const { token, user: loggedInUser } = useSelector(
    (state) => state.AuthenticationReducer
  );
  const { contactInfo, personalInfo, user, userMedicalInfo } = useSelector(
    (state) => state.profile
  );
  const { lng } = useSelector((state) => state.languageReducer);
  const userId = get(user, "user_id", "");

  useEffect(() => {
    getUserContactInformation();
    getUserPersonalInformation();
    getUserMedicalInformation();
  }, [currentUserId]);

  const getUserContactInformation = (dataUpdated = false) => {
    dispatch(getUserProfileContactInfo({ token: token, user_id: userId, lng }))
      .then(unwrapResult)
      .then((response) => {
        if (dataUpdated) {
          handleUserInReducer(response.data);
        }
        Applogger("Response at getUserProfileContactInfo", response);
      })
      .catch((err) => {
        Applogger("Error at getUserProfileContactInfo", err.message);
      });
  };

  const updateUserContactInformation = (data) => {
    dispatch(
      updateUserProfileContactInfo({
        token: token,
        Data: data,
        user_id: userId,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        getUserContactInformation(true);
        showSuccessToast(res?.data ?? "Information Updated Successfully");
        Applogger("Response at updateUserProfileContactInfo", res);
      })
      .catch((err) => {
        showFaliureToast(err.message ?? "Information Updated Successfully");
        Applogger("Error at updateUserProfileContactInfo", err.message);
      });
  };

  const getUserPersonalInformation = (dataUpdated = false) => {
    try {
      dispatch(
        getUserProfilePersonalInfo({ token: token, user_id: userId, lng })
      )
        .then(unwrapResult)
        .then((response) => {
          if (dataUpdated) {
            handleUserInReducer(response.data);
          }
          Applogger("Response at getUserProfilePersonalInfo", response);
        })
        .catch((err) => {
          Applogger("Error at getUserProfilePersonalInfo", err);
        });
    } catch (err) {
      Applogger("Error at getUserPersonalInformation", err);
    }
  };

  const handleUserInReducer = (userData) => {
    if (currentUserId == get(loggedInUser, "user_id", "")) {
      handleUpdateUserObjectInReducer(userData);
      handleUpdateProfileUserObjectInReducer(userData);
    } else {
      handleUpdateProfileUserObjectInReducer(userData);
    }
  };

  const handleUpdateUserObjectInReducer = (userData) => {
    dispatch(updateUserObjectInReducer({ updatedUser: userData }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at updateUserObjectInReducer", res);
      })
      .catch((err) => {
        Applogger("Error at updateUserObjectInReducer", err.message);
      });
  };

  const handleUpdateProfileUserObjectInReducer = (userData) => {
    dispatch(updateProfileUserObjectInReducer({ updatedUser: userData }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at updateProfileUserObjectInReducer", res);
      })
      .catch((err) => {
        Applogger("Error at updateProfileUserObjectInReducer", err.message);
      });
  };

  const updateUserPersonaleInformation = (data) => {
    const Data = { token: token, personalInfoBody: data, user_id: userId };
    dispatch(updateUserProfilePersonalInfo(Data))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at updateUserProfilePersonalInfo", res);
        getUserPersonalInformation(true);
        showSuccessToast(
          res?.data ?? "Personal Information Updated Successfully"
        );
      })
      .catch((err) => {
        Applogger("Error at updateUserProfilePersonalInfo", err.message);
        showFaliureToast("Failed to update user personal info");
      });
  };

  const getUserMedicalInformation = () => {
    const Data = { token: token, user_id: userId, lng };

    dispatch(getUserMedicalInfo(Data))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getUserMedicalInfo", res);
      })
      .catch((err) => {
        Applogger("Error at getUserMedicalInfo", err);
      });
  };

  const updateUserMedicalInformation = (data) => {
    const Data = { token: token, user_id: userId, medicalInfoData: data };

    dispatch(updateUserMedicalInfo(Data))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at updateUserMedicalInfo", res);
        showSuccessToast(res?.data ?? "Update Medical Info Successfull");
      })
      .catch((err) => {
        Applogger("Error at updateUserMedicalInfo", err);
        showFaliureToast("Update Medical Info Failure");
      });
  };
  return (
    <div className="animate__animated animate__fadeInUp ">
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          <button
            className="accordion-button"
            type="button"
            data-mdb-toggle="collapse"
            data-mdb-target="#Contact"
            aria-expanded="true"
            aria-controls="Contact"
          >
            <h4 className="text-black fw-bold profile_head_2 my-2">
              {t("contactInformation")}
            </h4>
          </button>
        </h2>
        <div
          id="Contact"
          className="accordion-collapse border-0 collapse show"
          aria-labelledby="headingOne"
        >
          <ContactForm
            Data={contactInfo && contactInfo}
            updateInfo={updateUserContactInformation}
          />
        </div>
      </div>

      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          <button
            className="accordion-button collapsed"
            type="button"
            data-mdb-toggle="collapse"
            data-mdb-target="#Personal"
            aria-expanded="false"
            aria-controls="Personal"
          >
            <h4 className="text-black fw-bold profile_head_2 my-2">
              {t("personalInformation")}
            </h4>
          </button>
        </h2>
        <div
          id="Personal"
          className="accordion-collapse border-0 collapse"
          aria-labelledby="headingOne"
        >
          <PersonalInfoForm
            Data={personalInfo && personalInfo}
            updateInfo={updateUserPersonaleInformation}
            Countries={countries}
          />
        </div>
      </div>

      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          <button
            className="accordion-button collapsed"
            type="button"
            data-mdb-toggle="collapse"
            data-mdb-target="#Medical"
            aria-expanded="false"
            aria-controls="Medical"
          >
            <h4 className="text-black fw-bold profile_head_2 my-2">
              {t("medicalInformation")}
            </h4>
          </button>
        </h2>
        <div
          id="Medical"
          className="accordion-collapse border-0 collapse"
          aria-labelledby="headingOne"
        >
          <MedicalInfo
            medicalData={userMedicalInfo}
            updateMedicalData={updateUserMedicalInformation}
          />
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingThree">
          <button
            className="accordion-button collapsed"
            type="button"
            data-mdb-toggle="collapse"
            data-mdb-target="#Emergency"
            aria-expanded="false"
            aria-controls="Emergency"
          >
            <h4 className="text-black fw-bold profile_head_2 my-2">
              {t("emergencyDetails")}
            </h4>
          </button>
        </h2>
        <div
          id="Emergency"
          className="accordion-collapse border-0 collapse"
          aria-labelledby="headingThree"
        >
          <UserEmergencies />
        </div>
      </div>
    </div>
  );
}

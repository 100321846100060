import React, { useState, useEffect } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { get } from "lodash";
import {
  showFaliureToast,
  unixToDateFormater,
  formattedDateForAPI,
} from "../../../Utilities";
import { Applogger } from "../../../Helpers/Logger";
import { useDispatch, useSelector } from "react-redux";
import { getUserEmploymentSensitiveInformation } from "../../../Redux/reducers/ProfileReducers";
import { useTranslation } from "react-i18next";
import { sensitiveInfoObj } from "../Constants";
import SPProfileButton from "../Components/SPProfileButton";
import SPProfileField from "../Components/SPProfileField";
import usePrevilagesExist from "../../../Helpers/PrivilegesFuncs";
import AppConstants from "../../../Helpers/AppConstants";
import SPProfileAddress from "../Components/SPProfileAddress";
import SPProfileDropDown from "../Components/SPProfileDropDown";
import useLocalisedConstants from "../../../customHooks/useLocalisedConstants";
import SPProfileStatus from "../Components/SPProfileStatus";

export default function SensitiveInfoUserEmployement({
  updateSensitiveInfo,
  id,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { lng } = useSelector((state) => state.languageReducer);
  const { token } = useSelector((state) => state.AuthenticationReducer);
  const { userEmployementSensitiveInfo: userSensitiveInfo } = useSelector(
    (state) => state.profile
  );
  // console.log("userSensitiveInfoReducerValue+++", userSensitiveInfo);
  const { exist: updateSalarySensitiveInfo } = usePrevilagesExist(
    AppConstants.previlages.updateSalarySensitiveInfo
  );

  const appConstants = useLocalisedConstants();

  const [sensitiveInfo, setSensitiveInfo] = useState(sensitiveInfoObj);
  // console.log("userSensitiveInfoReducerValueUSeSTate+++", sensitiveInfo);
  useEffect(() => {
    getUserSensitiveInfoRequest();
  }, [id]);

  useEffect(() => {
    setSensitiveInfo({
      bacs_reference: get(userSensitiveInfo, "bacs_reference", ""), // done
      bank_account_name: get(userSensitiveInfo, "bank_account_name", ""), // done
      bank_account_number: get(userSensitiveInfo, "bank_account_number", ""), // done
      bank_account_type: get(userSensitiveInfo, "bank_account_type", ""), // done
      bank_address_1: get(userSensitiveInfo, "bank_address_1", ""), // done
      bank_address_2: get(userSensitiveInfo, "bank_address_2", ""), // done
      bank_address_3: get(userSensitiveInfo, "bank_address_3", ""), // done
      bank_address_4: get(userSensitiveInfo, "bank_address_4", ""), // done
      bank_address_5: get(userSensitiveInfo, "bank_address_5", ""), // done
      bank_name: get(userSensitiveInfo, "bank_name", ""), // done
      bank_post_code: get(userSensitiveInfo, "bank_post_code", ""), // done
      driving_licence_number: get(
        userSensitiveInfo,
        "driving_licence_number",
        ""
      ), // done
      licence_expiry_date: unixToDateFormater(
        get(userSensitiveInfo, "licence_expiry_date", ""),
        AppConstants.defaultDateFormat
      ), // done
      // ni_category: get(userSensitiveInfo, "ni_category", ""), // done
      ni_number: get(userSensitiveInfo, "ni_number", ""), // done
      right_to_work_confirm_status: get(
        userSensitiveInfo,
        "right_to_work_confirm_status",
        ""
      ), // done
      right_to_work_document_expiry_date: unixToDateFormater(
        get(userSensitiveInfo, "right_to_work_document_expiry_date", ""),
        AppConstants.defaultDateFormat
      ), // done
      right_to_work_document_type: get(
        userSensitiveInfo,
        "right_to_work_document_type",
        ""
      ), // done
      right_to_work_document_verified: get(
        userSensitiveInfo,
        "right_to_work_document_verified",
        ""
      ), // done
      right_to_work_reference: get(
        userSensitiveInfo,
        "right_to_work_reference",
        ""
      ), // done
      sort_code: get(userSensitiveInfo, "sort_code", ""), // done
      tax_code: get(userSensitiveInfo, "tax_code", ""), // done
      // wk1mth1_basis: get(userSensitiveInfo, "wk1mth1_basis", ""),
      non_uk_worker: get(userSensitiveInfo, "non_uk_worker", ""),
      building_soc_number: get(userSensitiveInfo, "building_soc_number", ""),
    });
  }, [userSensitiveInfo]);

  const updateUserSensitiveData = () => {
    const {
      tax_code,
      ni_number,
      // ni_category,
      right_to_work_confirm_status,
      sort_code,
      bank_account_name,
      bank_account_number,
      bank_name,
      driving_licence_number,
      licence_expiry_date,
    } = sensitiveInfo;
    if (
      !tax_code ||
      !ni_number ||
      // !ni_category ||
      !right_to_work_confirm_status ||
      !sort_code ||
      !bank_account_name ||
      !bank_account_number ||
      !driving_licence_number ||
      !licence_expiry_date ||
      !bank_name
    ) {
      showFaliureToast(t("fieldsMarkedAs") + "*" + t("areMandatory"));
    } else {
      updateSensitiveInfo(sensitiveInfo);
    }
  };

  const findLicenseMaxValue = () => {
    // Return today's date and time
    var currentTime = new Date();

    // returns the month (from 0 to 11)
    var month = currentTime.getMonth() + 1;

    // returns the day of the month (from 1 to 31)
    var day = currentTime.getDate();

    // returns the year (four digits)
    var year = currentTime.getFullYear() + 14;

    return `${day}/${month}/${year}`;
    // write output MM/dd/yyyy
  };

  const getUserSensitiveInfoRequest = () => {
    dispatch(getUserEmploymentSensitiveInformation({ token, id: id, lng }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getUserEmploymentSensitiveInformation", res);
      })
      .catch((err) => {
        Applogger(
          "Error at getUserEmploymentSensitiveInformation",
          err.message
        );
      });
  };

  const re = /^[0-9\b]*$/;

  const val_license = (e) => {
    if (re.test(e.target.value)) {
      setSensitiveInfo({
        ...sensitiveInfo,
        driving_licence_number: e.target.value,
      });
    }
  };

  function formattedNINumber(value) {
    if (value) {
      let str = value;
      str = str.replace(/\s/g, "");
      var ret = [];

      for (let i = 0; i < str.length; i = i + 2) {
        ret.push(str.substr(i, 2));
      }
      return ret.join(" ");
    } else {
      return value;
    }
  }

  const handlePlacePayload = (place, value) => {
    let address1 = "";
    let postcode = "";
    let countryName = "";
    let townCity = "";
    let politicalCountry = "";

    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name} ${address1}`;
          break;
        }
        case "route": {
          address1 += component.long_name;
          break;
        }
        case "administrative_area_level_1": {
          countryName = component.long_name;
          break;
        }
        case "country" || "political": {
          politicalCountry = component.long_name;
          break;
        }
        case "administrative_area_level_2": {
          townCity = component.short_name;
          break;
        }
        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          break;
        }
        case "postal_code_prefix": {
          postcode = postcode
            ? `${postcode}-${component.long_name}`
            : component.long_name;
          break;
        }
      }
    }
    setSensitiveInfo({
      ...sensitiveInfo,
      bank_address_1: value,
      bank_address_2: townCity,
      bank_address_3: address1,
      bank_address_4: countryName,
      bank_address_5: politicalCountry,
      bank_post_code: postcode,
    });
  };

  return (
    <div className="px-2 py-4 border-custom rounded">
      <div className="accordion" id="accordionPanelsStayOpenExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button align-items-start collapsed"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#sensitiveInfo"
              aria-expanded="true"
              aria-controls="sensitiveInfo"
            >
              <div className="pe-2">
                <h4 className="text-black my-2 fw-bold emp_head">
                  {t("sensitiveInformation")}
                </h4>
                <p className="text-black-50">{t("eligibilityInformation")}</p>
              </div>
            </button>
          </h2>
          <div
            id="sensitiveInfo"
            className="accordion-collapse border-0 collapse"
            aria-labelledby="headingOne"
          >
            <div className="accordion-body p-0">
              <h4 className="heading ml-2 mt-2">{t("tax")}</h4>
              <SPProfileField
                title={t("taxCode")}
                value={sensitiveInfo.tax_code}
                onChange={(e) =>
                  setSensitiveInfo({
                    ...sensitiveInfo,
                    tax_code: e.target.value,
                  })
                }
                maxLength={20}
                disabled={!updateSalarySensitiveInfo}
                type={"text"}
                placeholder={t("taxCode")}
                isRequired={true}
              />
              <SPProfileField
                title={t("niNumber")}
                value={formattedNINumber(sensitiveInfo.ni_number)}
                onChange={(e) =>
                  setSensitiveInfo({
                    ...sensitiveInfo,
                    ni_number: e.target.value,
                  })
                }
                disabled={!updateSalarySensitiveInfo}
                type={"text"}
                placeholder={t("niNumberPH")}
                maxLength={14}
                isRequired={true}
              />
              {/* <SPProfileDropDown
                title={t("niCategory")}
                isDisabled={!updateSalarySensitiveInfo}
                onChange={(e) =>
                  setSensitiveInfo({
                    ...sensitiveInfo,
                    ni_category: e.target.value,
                  })
                }
                value={sensitiveInfo.ni_category}
                dataArray={appConstants.aToZList}
                displayKey="title"
                valueKey="value"
                isRequired={true}
              /> */}
              <br />
              <h4 className="heading ml-2 mt-2">{t("rightToWork")}</h4>
              <SPProfileDropDown
                title={t("rightToWorkConfirmStatus")}
                isDisabled={!updateSalarySensitiveInfo}
                onChange={(e) =>
                  setSensitiveInfo({
                    ...sensitiveInfo,
                    right_to_work_confirm_status: e.target.value,
                  })
                }
                value={sensitiveInfo.right_to_work_confirm_status}
                dataArray={appConstants.rightToWorkConfirmStatus}
                displayKey="placeholder"
                valueKey="value"
                isRequired={true}
              />
              <SPProfileDropDown
                title={t("rightToWorkDocumentType")}
                isDisabled={!updateSalarySensitiveInfo}
                onChange={(e) =>
                  setSensitiveInfo({
                    ...sensitiveInfo,
                    right_to_work_document_type: e.target.value,
                  })
                }
                value={sensitiveInfo.right_to_work_document_type}
                dataArray={appConstants.rightToWorkDocTypes}
                displayKey="placeholder"
                valueKey="value"
              />
              <SPProfileField
                title={t("rightToWorkDocumentExpiryDate")}
                value={sensitiveInfo.right_to_work_document_expiry_date}
                defaultValue={sensitiveInfo.right_to_work_document_expiry_date}
                onKeyDown={(e) => e.preventDefault()}
                disabled={!updateSalarySensitiveInfo}
                type={"date"}
                onChange={(e) =>
                  setSensitiveInfo({
                    ...sensitiveInfo,
                    right_to_work_document_expiry_date: formattedDateForAPI(
                      e.target.value
                    ),
                  })
                }
              />
              <SPProfileField
                title={t("rightToWorkReference")}
                placeholder={t("rightToWorkReference")}
                value={sensitiveInfo.right_to_work_reference}
                onChange={(e) =>
                  setSensitiveInfo({
                    ...sensitiveInfo,
                    right_to_work_reference: e.target.value,
                  })
                }
                disabled={!updateSalarySensitiveInfo}
                type={"text"}
              />
              <SPProfileStatus
                title={t("documentVerified")}
                isActive={sensitiveInfo.right_to_work_document_verified == 1}
                isRequired={true}
                onClick={(value) => {
                  setSensitiveInfo({
                    ...sensitiveInfo,
                    right_to_work_document_verified: value,
                  });
                }}
              />
              <br />
              <h4 className="heading ml-2 mt-2">{t("drivingLicence")}</h4>
              {/* {console.log(
                "VALUEUEUEIUIEHIUEUEHJUEGUEGUEE+++++++",
                sensitiveInfo
              )} */}
              <SPProfileField
                title={t("licenceNumber")}
                value={sensitiveInfo.driving_licence_number}
                onChange={(e) => val_license(e)}
                maxLength={20}
                type={"text"}
                placeholder={t("licenceNumber")}
                disabled={!updateSalarySensitiveInfo}
                isRequired={true}
              />
              <SPProfileField
                title={t("dateOfExpiry")}
                value={sensitiveInfo.licence_expiry_date}
                disabled={!updateSalarySensitiveInfo}
                defaultValue={sensitiveInfo.licence_expiry_date}
                onChange={(e) =>
                  setSensitiveInfo({
                    ...sensitiveInfo,
                    licence_expiry_date: formattedDateForAPI(e.target.value),
                  })
                }
                max={() => findLicenseMaxValue()}
                onKeyDown={(e) => e.preventDefault()}
                type={"date"}
                placeholder={t("dateOfExpiry")}
                isRequired={true}
              />
              <br />
              <h4 className="heading ml-2 mt-2">{t("bankDetails")}</h4>
              <SPProfileField
                title={t("bankAccountNumber")}
                value={sensitiveInfo.bank_account_number}
                onChange={(e) =>
                  setSensitiveInfo({
                    ...sensitiveInfo,
                    bank_account_number: e.target.value,
                  })
                }
                maxLength={20}
                type={"text"}
                placeholder={t("bankAccountNumber")}
                disabled={!updateSalarySensitiveInfo}
                isRequired={true}
              />
              <SPProfileField
                title={t("sortCode")}
                value={sensitiveInfo.sort_code}
                onChange={(e) =>
                  setSensitiveInfo({
                    ...sensitiveInfo,
                    sort_code: e.target.value,
                  })
                }
                disabled={!updateSalarySensitiveInfo}
                type={"text"}
                placeholder={t("sortCode")}
                maxLength={14}
                isRequired={true}
              />
              <SPProfileField
                title={t("bankAccountName")}
                value={sensitiveInfo.bank_account_name}
                onChange={(e) =>
                  setSensitiveInfo({
                    ...sensitiveInfo,
                    bank_account_name: e.target.value,
                  })
                }
                type={"text"}
                placeholder={t("bankAccountName")}
                disabled={!updateSalarySensitiveInfo}
                isRequired={true}
              />
              <SPProfileDropDown
                title={t("bankAccountType")}
                isDisabled={!updateSalarySensitiveInfo}
                onChange={(e) =>
                  setSensitiveInfo({
                    ...sensitiveInfo,
                    bank_account_type: e.target.value,
                  })
                }
                value={sensitiveInfo.bank_account_type}
                dataArray={appConstants.bankAccontTypes}
                displayKey="title"
                valueKey="value"
                isRequired={false}
              />
              <SPProfileField
                title={t("BASCReference")}
                value={sensitiveInfo.bacs_reference}
                onChange={(e) =>
                  setSensitiveInfo({
                    ...sensitiveInfo,
                    bacs_reference: e.target.value,
                  })
                }
                type={"text"}
                placeholder={t("BASCReference")}
                disabled={!updateSalarySensitiveInfo}
              />
              <SPProfileField
                title={t("bankName")}
                value={sensitiveInfo.bank_name}
                onChange={(e) =>
                  setSensitiveInfo({
                    ...sensitiveInfo,
                    bank_name: e.target.value,
                  })
                }
                type={"text"}
                placeholder={t("bankName")}
                disabled={!updateSalarySensitiveInfo}
                isRequired={true}
              />
              <SPProfileAddress
                isRequired={false}
                title={t("bankAddress") + "1"}
                handleSelection={handlePlacePayload}
                disabled={!updateSalarySensitiveInfo}
                setValue={(value) =>
                  setSensitiveInfo({
                    ...sensitiveInfo,
                    bank_address_1: value,
                  })
                }
                value={sensitiveInfo.bank_address_1}
              />
              <SPProfileField
                title={t("bankAddress") + "2"}
                placeholder={t("bankAddress") + "2"}
                value={sensitiveInfo.bank_address_2}
                onChange={(e) =>
                  setSensitiveInfo({
                    ...sensitiveInfo,
                    bank_address_2: e.target.value,
                  })
                }
                disabled={!updateSalarySensitiveInfo}
              />
              <SPProfileField
                title={t("bankAddress") + "3"}
                placeholder={t("bankAddress") + "3"}
                value={sensitiveInfo.bank_address_3}
                onChange={(e) =>
                  setSensitiveInfo({
                    ...sensitiveInfo,
                    bank_address_3: e.target.value,
                  })
                }
                disabled={!updateSalarySensitiveInfo}
              />
              <SPProfileField
                title={t("bankAddress") + "4"}
                placeholder={t("bankAddress") + "4"}
                value={sensitiveInfo.bank_address_4}
                onChange={(e) =>
                  setSensitiveInfo({
                    ...sensitiveInfo,
                    bank_address_4: e.target.value,
                  })
                }
                disabled={!updateSalarySensitiveInfo}
              />
              <SPProfileField
                title={t("bankAddress") + "5"}
                placeholder={t("bankAddress") + "5"}
                value={sensitiveInfo.bank_address_5}
                onChange={(e) =>
                  setSensitiveInfo({
                    ...sensitiveInfo,
                    bank_address_5: e.target.value,
                  })
                }
                disabled={!updateSalarySensitiveInfo}
              />
              <SPProfileField
                title={t("bankPostCode")}
                value={sensitiveInfo.bank_post_code}
                onChange={(e) =>
                  setSensitiveInfo({
                    ...sensitiveInfo,
                    bank_post_code: e.target.value,
                  })
                }
                type={"text"}
                placeholder={t("bankPostCode")}
                disabled={!updateSalarySensitiveInfo}
              />
              <br />
              <h4 className="heading ml-2 mt-2">{t("other")}</h4>
              {/* <SPProfileStatus
                title={t("wk1mth1Basis")}
                isActive={sensitiveInfo.wk1mth1_basis == 1}
                isRequired={false}
                onClick={(value) => {
                  setSensitiveInfo({
                    ...sensitiveInfo,
                    wk1mth1_basis: value,
                  });
                }}
              /> */}
              <SPProfileStatus
                title={t("nonUKWorker")}
                isActive={sensitiveInfo.non_uk_worker == 1}
                isRequired={false}
                onClick={(value) => {
                  setSensitiveInfo({
                    ...sensitiveInfo,
                    non_uk_worker: value,
                  });
                }}
              />
              <SPProfileField
                title={t("buildingSocNumber")}
                placeholder={t("buildingSocNumber")}
                value={sensitiveInfo.building_soc_number}
                onChange={(e) =>
                  setSensitiveInfo({
                    ...sensitiveInfo,
                    building_soc_number: e.target.value,
                  })
                }
                disabled={!updateSalarySensitiveInfo}
              />
              <br />
              <label className="ml-2 mt-2">
                {t("fieldsMarkedAs")}
                <span className="text-danger">{" * "}</span>
                {t("areMandatory")}
              </label>
              <br />
              <SPProfileButton
                title={t("save")}
                onClick={() => updateUserSensitiveData()}
                isVisible={updateSalarySensitiveInfo}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { get } from "lodash";
import moment from "moment";
import AppConstants from "./AppConstants";

export const indexFinder = (id, Array) => {
  const FoundIndex = Array.findIndex((Data) => Data.id === id);
  return FoundIndex;
};
export const getHeaders = (token, other) => {
  return {
    headers: { Authorization: `Bearer ${token}`, ...other },
  };
};

export const checkIsAdmin = (loggedInUser, user) => {
  if (get(loggedInUser, "is_admin", -1) == 1) {
    return get(loggedInUser, "user_id", null) == get(user, "user_id", 0);
  }
};

export const getCurrentDateAfterSubMonth = (months = 0) => {
  return moment().subtract(months, "months").format("YYYY-MM-DD");
};

export const addMonthsInCurrentDate = (months = 0) => {
  return moment().add(months, "months").format(AppConstants.defaultDateFormat);
};

export const getCurrentDate = () => {
  return moment().format(AppConstants.defaultDateFormat);
};

export const convertDayMonthToDateStart = (startOfDay = 0, startMonth = 0) => {
  return `${startOfDay}/${startMonth}/${new Date().getFullYear()}`;
};

export const convertDayMonthToDateEnd = (
  endDate = 0,
  endMonth = 0,
  startMonth = 0
) => {
  return `${endDate}/${endMonth}/${
    startMonth > 1
      ? parseInt(new Date().getFullYear()) + 1
      : new Date().getFullYear()
  }`;
};

export const setItemLocalStorage = (fieldName, value) => {
  localStorage.setItem(fieldName, JSON.stringify(value));
};

export const getItemLocalStorage = (fieldName) => {
  return JSON.parse(localStorage.getItem(fieldName));
};

import React, { useState, useEffect, useRef } from "react";
import { TimePicker, Card, Modal, Radio } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { InputField } from "../Input/InputField";
import { Applogger } from "../../Helpers/Logger";
import { useTranslation } from "react-i18next";
import { getAllRolesRequest } from "../../Redux/reducers/SettingsReducer";
import { employeeTypes } from "../Employees/Forms/FormConstants";
import { workingHoursBody } from "./Constants";
import { showFaliureToast } from "../../Utilities";
import { get } from "lodash";
import EmpWorkingHoursField from "../Employees/Forms/Components/EmpWorkingHoursField";
import AppConstants from "../../Helpers/AppConstants";
import moment from "moment";
import RoleCard from "./RoleCard";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Spinner from "../Spinner";
import useLocalisedConstants from "../../customHooks/useLocalisedConstants";

dayjs.extend(customParseFormat);

export default function CreateNewWTPModal({
  onCancel,
  visible,
  onOkPress,
  hpToUpdate,
  companyId,
}) {
  const dispatch = useDispatch();
  const appConstants = useLocalisedConstants();
  const { t } = useTranslation();

  const ref = useRef(null);

  const { token } = useSelector((state) => state.AuthenticationReducer);
  const { lng } = useSelector((state) => state.languageReducer);
  const { roles, rolesSettings, loading } = useSelector(
    (state) => state.settings
  );

  const [totalWeekHours, setTotalWeekHours] = useState(0);
  const [localRoles, setLocalRoles] = useState([]);
  const [currentType, setCurrentType] = useState("");
  const [workingHour, setWorkingHour] = useState(workingHoursBody);
  const [workingHourMatcher, setWorkingHourMatcher] = useState(null);
  const [currentRoleType, setCurrentRoleType] = useState(
    AppConstants.userRoleTypes.daily
  );
  const [selectedDays, setSelectedDays] = useState([]);
  const maxBreakCount = 120;

  useEffect(() => {
    if (visible) {
      handlesRolesLocally();
      handleInitialValues();
      setCurrentRoleType(AppConstants.userRoleTypes.daily);
    }
  }, [visible]);

  useEffect(() => {
    handleGetRoles();
    // setCurrentType(employeeTypes.hours);
  }, []);

  useEffect(() => {
    handlesRolesLocally();
  }, [roles, rolesSettings]);

  useEffect(() => {
    calculateTotalWeekHours();
  }, [selectedDays]);

  useEffect(() => {
    loadIniitalValues();
  }, [hpToUpdate, visible]);

  const loadIniitalValues = () => {
    if (hpToUpdate) {
      handleInitialValues();
      getCurrentRoles(get(hpToUpdate, "pattern.roles", []));
    } else {
      setWorkingHour(workingHoursBody);
    }
  };

  const handlesRolesLocally = () => {
    let localArray = [];
    rolesSettings.forEach((role) => {
      localArray.push({ ...role.role, selected: false });
    });
    setLocalRoles(localArray);
  };

  const calculateTotalWeekHours = () => {
    let totalHours = 0;
    selectedDays.forEach((currentDay) => {
      if (
        get(currentDay, "start_time", null) &&
        get(currentDay, "end_time", null)
      ) {
        let time = getTimeDifference(
          currentDay.start_time,
          currentDay.end_time
        );
        if (currentDay.break) {
          time = time - currentDay.break;
        }
        totalHours += time / 60;
      } else {
        totalHours += 0;
      }
    });
    setTotalWeekHours(totalHours);
  };

  const handleInitialValues = () => {
    const pattern = get(hpToUpdate, "pattern", null);
    const wtPattern = get(pattern, "working_time_patterns", null);
    const whPattern = get(pattern, "working_hour_patterns", null);

    const finalObject = {
      ...workingHour,
      pattern_details: get(pattern, "pattern_details", null),
      public_holidays: get(whPattern, "public_holidays", null)
        ? get(whPattern, "public_holidays", "")
        : get(wtPattern, "public_holidays", ""),
      working_time_breaks: get(wtPattern, "working_time_breaks", []),
      roles: get(pattern, "roles", []),
      company_working_week_hours: get(pattern, "company_working_week_hours", 0),
      company_working_week_minutes: get(
        pattern,
        "company_working_week_minutes",
        0
      ),
      contract_week_hours: get(whPattern, "contract_week_hours", 0),
      contract_week_minutes: get(whPattern, "contract_week_minutes", 0),
      average_working_day_hours: get(whPattern, "average_working_day_hours", 0),
      average_working_day_minutes: get(
        whPattern,
        "average_working_day_minutes",
        0
      ),
    };
    setWorkingHour(finalObject);
    setWorkingHourMatcher(finalObject);
    setSelectedDays(get(wtPattern, "working_time_breaks", []));
    if (pattern?.type == AppConstants.workingPattersTypes.daily) {
      setCurrentRoleType(AppConstants.userRoleTypes.daily);
    } else {
      setCurrentRoleType(AppConstants.userRoleTypes.hours);
    }
  };

  const getCurrentRoles = (receivedRoles) => {
    const mergedArray = localRoles.map((obj1) => {
      const found = receivedRoles.some((obj2) => obj1.role_id === obj2.role_id);
      return { ...obj1, selected: found };
    });
    setLocalRoles(mergedArray);
  };

  function handleGetRoles() {
    dispatch(getAllRolesRequest({ token, lng, companyId }))
      .then(unwrapResult)
      .then(() => {
        setCurrentRoleType(AppConstants.userRoleTypes.daily);
        Applogger("Roles received successfully");
      })
      .catch(() => {
        Applogger("Found error while fetching roles");
      });
  }

  function getTimeDifference(startTime, endTime) {
    const start = moment.unix(startTime);
    const end = moment.unix(endTime);

    const difference = end.diff(start); // Difference in milliseconds

    // Convert milliseconds to hours, minutes, and seconds
    const duration = moment.duration(difference);

    const hours = duration.hours();
    let minutes = duration.minutes();
    minutes += hours * 60;
    const seconds = duration.seconds();

    // return { hours, minutes, seconds };
    return minutes;
  }

  const onPatternNameChange = (name) => {
    setWorkingHour({ ...workingHour, pattern_details: name });
  };

  const handleDaysSelection = (selectedDay) => {
    var filteredArray = [...workingHour.working_time_breaks];
    var selectedArray = [...selectedDays];

    const currentIndex = selectedArray.findIndex(
      (val) => val.day == selectedDay.day
    );
    if (currentIndex !== -1) {
      selectedArray.splice(currentIndex, 1);
    } else {
      selectedArray.push({
        start_time: "",
        end_time: "",
        break: "",
        day: selectedDay.day,
        fe_id: selectedDay.fe_id,
      });
    }

    if (filteredArray.includes(selectedDay.day)) {
      filteredArray = filteredArray.filter((data) => data != selectedDay.day);
    } else {
      filteredArray.push(selectedDay.day);
    }
    setWorkingHour({ ...workingHour, working_time_breaks: filteredArray });

    selectedArray = selectedArray.sort((patterA, patternB) => {
      return patterA.fe_id > patternB.fe_id ? 1 : -1;
    });
    setSelectedDays(selectedArray);
    setWorkingHour({ ...workingHour, working_time_breaks: selectedArray });
  };

  const handleHolidaySelection = (holidayType) => {
    setWorkingHour({ ...workingHour, public_holidays: holidayType });
  };

  const calculateNumOfHours = (currentDay) => {
    if (
      get(currentDay, "start_time", null) &&
      get(currentDay, "end_time", null)
    ) {
      let time = getTimeDifference(currentDay.start_time, currentDay.end_time);

      if (currentDay.break) {
        time = time - currentDay.break;
      }
      return time / 60;
    } else {
      return 0;
    }
  };

  const isItemSelected = (selectedDay) => {
    const selectedDayIndex = workingHour.working_time_breaks.findIndex(
      (val) => {
        return val.day == selectedDay.day;
      }
    );

    return selectedDayIndex != -1;
  };

  const onRoleClick = (value) => {
    const allRoles = [...localRoles];
    const currentRoleIndex = allRoles.findIndex(
      (val) => val.role_id == value.role_id
    );
    if (currentRoleIndex !== -1) {
      allRoles[currentRoleIndex] = {
        ...allRoles[currentRoleIndex],
        selected: !allRoles[currentRoleIndex].selected,
      };
      setLocalRoles(allRoles);
    }

    const filtteredArray = allRoles.filter((role) => {
      return role.selected;
    });

    setWorkingHour({ ...workingHour, roles: filtteredArray });
  };

  const isButtonDisabled = () => {
    if (currentRoleType == AppConstants.userRoleTypes.daily) {
      if (totalWeekHours.toFixed(2) < 0) {
        return true;
      }
      if (
        !workingHour.pattern_details ||
        !workingHour.public_holidays ||
        !workingHour.roles.length > 0 ||
        !isValidWorkingHours()
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        !workingHour.pattern_details ||
        !workingHour.public_holidays ||
        !workingHour.roles.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const isValidWorkingHours = () => {
    if (workingHour.working_time_breaks.length > 0) {
      const array = workingHour.working_time_breaks;
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        for (const [key, value] of Object.entries(element)) {
          if (!value) {
            return false;
          }
          if (key.toLowerCase() == "break") {
            if (value > maxBreakCount) {
              return false;
            }
          }
        }
      }
      return true;
    } else {
      return false;
    }
  };

  const breaksAndTimeChangeHandler = (day, value, type) => {
    // const allWTP = [...selectedDays];
    var allWTP = [...selectedDays];
    const currentIndex = selectedDays.findIndex((val) => val.day == day.day);

    if (currentIndex !== -1) {
      const unixTime = Date.parse(value) / 1000; // moment(value).unix();

      if (type === AppConstants.timePickerTypes.startTime) {
        allWTP[currentIndex] = {
          ...allWTP[currentIndex],
          start_time: unixTime,
        };
      } else if (type === AppConstants.timePickerTypes.endTime) {
        allWTP[currentIndex] = {
          ...allWTP[currentIndex],
          end_time: unixTime,
        };
      } else if (type === AppConstants.timePickerTypes.breakTime) {
        allWTP[currentIndex] = {
          ...allWTP[currentIndex],
          break: value.replace(/^0+/, ""),
        };
      } else {
        Applogger("Invalid Type Selected");
      }
    }
    setSelectedDays(allWTP);
    setWorkingHour({ ...workingHour, working_time_breaks: allWTP });
  };

  const WTPStatus = (wtp) => {
    const startTime = get(wtp, "start_time", "");
    const endTime = get(wtp, "end_time", "");
    const breakTime = get(wtp, "break", "");

    if (startTime && endTime && breakTime) {
      return (
        <i
          style={{ fontSize: 20, fontWeight: "bold", color: "#3db0f7" }}
          class="ri-checkbox-blank-circle-fill"
        />
      );
    } else {
      return (
        <i
          style={{ fontSize: 20, fontWeight: "bold", color: "#a1a9b3" }}
          className="ri-checkbox-blank-circle-line"
        />
      );
    }
  };

  const re = /^[0-9\b]*$/;

  return (
    <Modal
      title={hpToUpdate ? t("updateWTPTitle") : t("createWTPTitle")}
      width={"75%"}
      visible={visible}
      onCancel={onCancel}
      destroyOnClose
      onOk={() => {
        if (
          JSON.stringify(workingHourMatcher) === JSON.stringify(workingHour)
        ) {
          showFaliureToast(t("nothingToUpdate"));
        } else {
          onOkPress(workingHour, currentRoleType);
        }
      }}
      ref={ref}
      okButtonProps={{ disabled: isButtonDisabled() }}
    >
      {loading == "pending" && <Spinner />}
      <div style={{ height: 500, overflow: "auto" }}>
        <div className="main-wrapper">
          <div className="row mt-10 ">
            <div className="col-12 col-md-4 col-sm-12 pl-0 ">
              <div className="center">
                <label>{t("roleType")}</label>
              </div>
            </div>
            <div className="col-12 col-md-8 col-sm-12 ">
              <Radio.Group
                disabled={hpToUpdate ? true : false}
                style={{ width: "100%" }}
                defaultValue={AppConstants.userRoleTypes.daily}
                value={currentRoleType}
                onChange={(e) => {
                  setSelectedDays([]);
                  loadIniitalValues();
                  handlesRolesLocally();
                  setCurrentRoleType(e.target.value);
                }}
                buttonStyle="solid"
              >
                <Radio.Button
                  style={{ width: "50%" }}
                  value={AppConstants.userRoleTypes.daily}
                >
                  {t("daily")}
                </Radio.Button>
                <Radio.Button
                  style={{ width: "50%" }}
                  value={AppConstants.userRoleTypes.hours}
                >
                  {t("hourly")}
                </Radio.Button>
              </Radio.Group>
            </div>
          </div>
          <br />
          <h5 className="h5 fw-bold">{t("patternDetails")}</h5>
          <InputField
            preventMinusEnable={true}
            title={t("patternName")}
            onChange={(e) => onPatternNameChange(e.target.value)}
            placeholder={`${t("patternName")} e.g Mon-Fri`}
            isRequired={true}
            value={workingHour.pattern_details}
            type={"text"}
            maxLength={25}
          />
          <br />
          {currentRoleType == AppConstants.userRoleTypes.daily && (
            <React.Fragment>
              <hr className="pr-hr" />
              <br />
              <h5 className="h5 fw-bold">{t("timesAndBreaks")}</h5>
              <p>{t("startAndEndTime")}</p>
              <div className="myFlex-cs flex-wrap-sm gap-2">
                <span>
                  {t("repeat")}
                  <span className="text-danger">*</span>
                </span>
                <div className="day-select">
                  {appConstants.weekdays.map((item, index) => {
                    return (
                      <span
                        onClick={() => handleDaysSelection(item)}
                        className={isItemSelected(item) ? "active" : ""}
                        key={index}
                      >
                        {item.name}
                      </span>
                    );
                  })}
                </div>
              </div>
              <br />
              <hr className="pr-hr" />
              <div className="row w-100">
                {selectedDays.map((day, index) => (
                  <>
                    <div
                      className="pl-0"
                      style={{ display: "flex", alignItems: "center" }}
                      key={index}
                    >
                      <div className="col-sm-12 col-md-3 center mt-2">
                        <div className="center">
                          <span>{WTPStatus(day)} </span>&nbsp;
                          <h6 className="mt-2">{day.day}</h6>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-3 center mt-2 position-relative">
                        <span>{t("start")} </span>&nbsp;
                        <TimePicker
                          getPopupContainer={(trigger) => trigger.parentElement}
                          defaultValue={
                            day.start_time
                              ? dayjs.unix(day.start_time, "HH:mm")
                              : ""
                          }
                          value={
                            day.start_time
                              ? dayjs.unix(day.start_time, "HH:mm")
                              : ""
                          }
                          onCancel={() =>
                            breaksAndTimeChangeHandler(
                              day,
                              "",
                              AppConstants.timePickerTypes.startTime
                            )
                          }
                          placeholder="00:00"
                          format={"HH:mm"}
                          onChange={(timeStamp) => {
                            breaksAndTimeChangeHandler(
                              day,
                              timeStamp,
                              AppConstants.timePickerTypes.startTime
                            );
                          }}
                          // onClick={() =>
                          //   setCurrentType(
                          //     AppConstants.timePickerTypes.startTime
                          //   )
                          // }
                        />
                      </div>
                      <div className="col-sm-12 col-md-3 center mt-2 position-relative">
                        <span>{t("end")} </span>&nbsp;
                        <TimePicker
                          getPopupContainer={(trigger) => trigger.parentElement}
                          defaultValue={
                            day.end_time
                              ? dayjs.unix(day.end_time, "HH:mm")
                              : ""
                          }
                          value={
                            day.end_time
                              ? dayjs.unix(day.end_time, "HH:mm")
                              : ""
                          }
                          onCancel={() =>
                            breaksAndTimeChangeHandler(
                              day,
                              "",
                              AppConstants.timePickerTypes.endTime
                            )
                          }
                          format={"HH:mm"}
                          placeholder="00:00"
                          onChange={(timeStamp) =>
                            breaksAndTimeChangeHandler(
                              day,
                              timeStamp,
                              AppConstants.timePickerTypes.endTime
                            )
                          }
                          // onClick={() =>
                          //   setCurrentType(AppConstants.timePickerTypes.endTime)
                          // }
                        />
                      </div>
                      <div className="col-sm-12 col-md-2 center">
                        <span>{t("break")} </span> &nbsp;
                        <div
                          className={`filter-form myFlex-cs position-relative ${
                            day.break > maxBreakCount
                              ? "red-border"
                              : "red-default"
                          }`}
                        >
                          <input
                            value={day.break}
                            onChange={(e) =>
                              breaksAndTimeChangeHandler(
                                day,
                                e.target.value,
                                AppConstants.timePickerTypes.breakTime
                              )
                            }
                            defaultValue={0}
                            type="number"
                            maxLength={3}
                            min={0}
                            max={maxBreakCount}
                            // onClick={() =>
                            //   setCurrentType(
                            //     AppConstants.timePickerTypes.breakTime
                            //   )
                            // }
                            className="p-0 border-0"
                            style={{ outline: "none" }}
                          />
                          <label className="text-black-50 position-absolute me-2 end-0">
                            {t("min")}
                          </label>
                        </div>
                      </div>
                    </div>
                    <p
                      className={`my-2 pl-0 ${
                        calculateNumOfHours(day).toFixed(2) < 0 && "red-border"
                      }`}
                    >
                      {t("selectedTotalling")}{" "}
                      <b>
                        {calculateNumOfHours(day).toFixed(2)} {t("hours")}
                      </b>
                      , {t("excludingBreaks")}
                    </p>
                    {index != selectedDays.length - 1 && <hr />}
                  </>
                ))}
              </div>
              {selectedDays.length > 0 && (
                <>
                  <br />
                  <hr />
                  <p className="my-2 pl-0">
                    {t("selectedWorkingHour")}{" "}
                    <b>
                      {totalWeekHours.toFixed(2)} {t("hours")}
                    </b>
                    {t("excludingBreaks")}
                  </p>
                  <hr className="pr-hr" />
                </>
              )}
            </React.Fragment>
          )}
          {currentRoleType == AppConstants.userRoleTypes.hours && (
            <>
              <hr className="pr-hr" />
              <br />
              <EmpWorkingHoursField
                title={t("contractedHoursPerWeek")}
                Hoursvalue={workingHour.contract_week_hours}
                currentType={employeeTypes.hours}
                hoursOnChange={(e) => {
                  setWorkingHour({
                    ...workingHour,
                    contract_week_hours: e.target.value.replace(/^0+/, ""),
                  });
                }}
                minsValue={workingHour.contract_week_minutes}
                minsOnChange={(e) => {
                  setWorkingHour({
                    ...workingHour,
                    contract_week_minutes: e.target.value.replace(/^0+/, ""),
                  });
                }}
              />
              <EmpWorkingHoursField
                title={t("anAverageWorkingDay")}
                Hoursvalue={workingHour.average_working_day_hours}
                currentType={employeeTypes.hours}
                hoursOnChange={(e) => {
                  setWorkingHour({
                    ...workingHour,
                    average_working_day_hours: e.target.value.replace(
                      /^0+/,
                      ""
                    ),
                  });
                }}
                minsValue={workingHour.average_working_day_minutes}
                minsOnChange={(e) => {
                  setWorkingHour({
                    ...workingHour,
                    average_working_day_minutes: e.target.value.replace(
                      /^0+/,
                      ""
                    ),
                  });
                }}
              />
              <EmpWorkingHoursField
                title={t("workingWeekExcludingBreaks")}
                Hoursvalue={workingHour.company_working_week_hours}
                currentType={employeeTypes.hours}
                hoursOnChange={(e) => {
                  setWorkingHour({
                    ...workingHour,
                    company_working_week_hours: e.target.value.replace(
                      /^0+/,
                      ""
                    ),
                  });
                }}
                minsValue={workingHour.company_working_week_minutes}
                minsOnChange={(e) => {
                  setWorkingHour({
                    ...workingHour,
                    company_working_week_minutes: e.target.value.replace(
                      /^0+/,
                      ""
                    ),
                  });
                }}
              />
              <br />
              <hr className="pr-hr" />
            </>
          )}
          <div className="my-4">
            <h5 className="h5 fw-bold">{t("publicHolidays")}</h5>
            <p>{t("annualLeaveEntitlement")}</p>
            <div>
              <p className="fw-bold">
                {t("publicHolidays")}
                <span className="text-danger">*</span>
              </p>
              <div className="myFlex-cs justify-content-start flex-wrap-sm gap-2">
                <HolidayCard
                  onClick={() =>
                    handleHolidaySelection(AppConstants.holidayTypes.deducted)
                  }
                  title={t("deducted")}
                  description={t("yearlyHolidayEntitlement")}
                  isActive={
                    workingHour.public_holidays ===
                    appConstants.holidayTypes.deducted
                  }
                />
                <HolidayCard
                  onClick={() =>
                    handleHolidaySelection(
                      appConstants.holidayTypes.notDeducted
                    )
                  }
                  title={t("notDeducted")}
                  description={t("dayOffForAnyPublicHolidays")}
                  isActive={
                    workingHour.public_holidays ===
                    appConstants.holidayTypes.notDeducted
                  }
                />
                <HolidayCard
                  onClick={() =>
                    handleHolidaySelection(
                      appConstants.holidayTypes.WorksPublicHolidays
                    )
                  }
                  title={t("worksPublicHolidays")}
                  description={t("abilityToWorkOnPublicHolidays")}
                  isActive={
                    workingHour.public_holidays ===
                    appConstants.holidayTypes.WorksPublicHolidays
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <br />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <p className="fw-bold">
            {t("selectroles")} <span className="text-danger">*</span>
          </p>
          <p className="fw-bold">
            {`${get(workingHour, "roles", []).length} `}
            {t("rolesselected")}
          </p>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {localRoles
            .filter((val) => val?.role_type == currentRoleType)
            .map((val, index) => (
              <RoleCard
                onClick={() => onRoleClick(val)}
                selected={val.selected}
                name={val.name}
                key={index}
              />
            ))}
        </div>
      </div>
    </Modal>
  );
}

const HolidayCard = ({ title, description, isActive, onClick }) => {
  return (
    <Card
      onClick={onClick}
      className={`holiday-card cursor-pointer ${isActive && "active"}`}
    >
      <b>{title}</b>
      <p>{description}</p>
    </Card>
  );
};

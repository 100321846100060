import { createAsyncThunk, createReducer, current } from "@reduxjs/toolkit";
import { ProfileApiServices } from "../actions/ProfileActions";
import {
  stopLoaderAndEmptyErrors,
  logoutRequest,
} from "./AuthenticationReducer";
import {
  updateUserStatus,
  handletPayableData,
  handletToilData,
  getMergedObject,
} from "../../Utilities";
export const updateProfileUserObjectInReducer = createAsyncThunk(
  "ProfileReducer/updateProfileUserObjectInReducer",
  async (payload) => {
    return payload;
  }
);

export const resendInvitationRequest = createAsyncThunk(
  "ProfileReducer/resendInvitationRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await ProfileApiServices.apiResendInvitationRequest(
        payload
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetUserPassword = createAsyncThunk(
  "ProfileReducer/resetUserPassword",
  async (payload) => {
    const response = await ProfileApiServices.apiResetUserPassword(payload);
    return response;
  }
);

export const getUserAbsence = createAsyncThunk(
  "ProfileReducer/getUserAbsence",
  async (payload) => {
    const response = await ProfileApiServices.getUserAbsence(payload);
    return response;
  }
);
export const deletetUserAbsence = createAsyncThunk(
  "ProfileReducer/deletetUserAbsence",
  async (payload) => {
    const response = await ProfileApiServices.deletetUserAbsence(payload);
    return response;
  }
);
export const getUserLog = createAsyncThunk(
  "ProfileReducer/getUserLog",
  async (payload) => {
    const response = await ProfileApiServices.apiGetUserLog(payload);
    return response;
  }
);

export const getUserProfile = createAsyncThunk(
  "ProfileReducer/getUserProfile",
  async (payload) => {
    const response = await ProfileApiServices.apiGetUserProfile(payload);
    return response;
  }
);

export const changeUserProfileStatus = createAsyncThunk(
  "ProfileReducer/changeUserProfileStatus",
  async (payload) => {
    const response = await ProfileApiServices.changeUserProfileStatus(payload);
    return response;
  }
);

export const deleteUserProfileImage = createAsyncThunk(
  "ProfileReducer/deleteUserProfileImage",
  async (payload) => {
    const response = await ProfileApiServices.deleteUserProfileImage(payload);
    return response;
  }
);

export const getUserProfileOverTime = createAsyncThunk(
  "ProfileReducer/getUserProfileOverTime",
  async (payload) => {
    const response = await ProfileApiServices.getUserOverTimeDetails(payload);
    return response;
  }
);
export const getUserProfileOverTimeOff = createAsyncThunk(
  "ProfileReducer/getUserProfileOverTimeOff",
  async (payload) => {
    const response = await ProfileApiServices.getUserOverTimeOffDetails(
      payload
    );
    return response;
  }
);
export const cancelUserProfileOverTime = createAsyncThunk(
  "ProfileReducer/cancelUserProfileOverTime",
  async (payload) => {
    const response = await ProfileApiServices.cancelUserOverTimRequest(payload);
    return response;
  }
);

export const getUserProfileContactInfo = createAsyncThunk(
  "ProfileReducer/getUserProfileContactInfo",
  async (payload) => {
    const response = await ProfileApiServices.getUserProfileContactInfoRequest(
      payload
    );
    return response;
  }
);

export const updateUserProfileContactInfo = createAsyncThunk(
  "ProfileReducer/updateUserProfileContactInfo",
  async (payload, { rejectWithValue }) => {
    try {
      const response =
        await ProfileApiServices.updateUserProfileContactInfoRequest(payload);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserProfilePersonalInfo = createAsyncThunk(
  "ProfileReducer/getUserProfilePersonalInfo",
  async (payload) => {
    const response = await ProfileApiServices.getUserProfilePersonalInfoRequest(
      payload
    );
    return response;
  }
);

export const updateUserProfilePersonalInfo = createAsyncThunk(
  "ProfileReducer/updateUserProfilePersonalInfo",
  async (payload) => {
    const response =
      await ProfileApiServices.updateUserProfilePersonalInfoRequest(payload);
    return response;
  }
);

export const getUserProfileEmergencyContacts = createAsyncThunk(
  "ProfileReducer/getUserProfileEmergencyContacts",
  async (payload) => {
    const response = await ProfileApiServices.getEmergencyContactsRequest(
      payload
    );
    return response;
  }
);

export const getUserMedicalInfo = createAsyncThunk(
  "ProfileReducer/getUserMedicalInfo",
  async (payload) => {
    const response = await ProfileApiServices.getUserMedicalInfoRequest(
      payload
    );
    return response;
  }
);

export const updateUserMedicalInfo = createAsyncThunk(
  "ProfileReducer/updateUserMedicalInfo",
  async (payload) => {
    const response = await ProfileApiServices.updateUserMedicalInfoRequest(
      payload
    );
    return response;
  }
);
export const createEmergencyContact = createAsyncThunk(
  "ProfileReducer/createEmergencyContact",
  async (payload) => {
    const response = await ProfileApiServices.createEmergencyContactsRequest(
      payload
    );
    return response;
  }
);

export const updateEmergencyContact = createAsyncThunk(
  "ProfileReducer/updateEmergencyContact",
  async (payload) => {
    const response = await ProfileApiServices.updateEmergencyContactRequest(
      payload
    );
    return response;
  }
);

export const deleteEmergencyContact = createAsyncThunk(
  "ProfileReducer/deleteEmergencyContact",
  async (payload) => {
    const response = await ProfileApiServices.deleteEmergencyContactRequest(
      payload
    );
    return response;
  }
);

export const getUserEmployementContractInfo = createAsyncThunk(
  "ProfileReducer/getUserEmployementContractInfo",
  async (payload) => {
    const response =
      await ProfileApiServices.getUserEmployementContractInformationRequest(
        payload
      );
    return response;
  }
);

// export const getUserEmployementRoleInfo = createAsyncThunk(
//   "ProfileReducer/getUserEmployementRoleInfo",
//   async (payload) => {
//     const response =
//       await ProfileApiServices.getUserEmployementRoleInformationRequest(
//         payload
//       );
//     return response;
//   }
// );

export const getUserEmploymentSensitiveInformation = createAsyncThunk(
  "ProfileReducer/getUserEmploymentSensitiveInformation",
  async (payload) => {
    const response =
      await ProfileApiServices.getUserSensitiveInformationRequest(payload);
    return response;
  }
);

export const updateUserEmploymentSensitiveInformation = createAsyncThunk(
  "ProfileReducer/updateUserEmploymentSensitiveInformation",
  async (payload) => {
    const response =
      await ProfileApiServices.updateUserSensitiveInformationRequest(payload);
    return response;
  }
);

export const getUserEmploymentSalaryInformation = createAsyncThunk(
  "ProfileReducer/getUserEmploymentSalaryInformation",
  async (payload) => {
    const response = await ProfileApiServices.getUserSalaryInformationRequest(
      payload
    );
    return response;
  }
);

export const updateUserEmployementSalaryInformation = createAsyncThunk(
  "ProfileReducer/updateUserEmployementSalaryInformation",
  async (payload) => {
    const response =
      await ProfileApiServices.updateUserSalaryInformationRequest(payload);
    return response;
  }
);

export const updateUserContractAnnualLeave = createAsyncThunk(
  "ProfileReducer/updateUserContractAnnualLeave",
  async (payload) => {
    const response =
      await ProfileApiServices.updateUserContractAnnualLeaveRequest(payload);
    return response;
  }
);
export const updateUserEmploymentInfo = createAsyncThunk(
  "ProfileReducer/updateUserEmploymentInfo",
  async (payload) => {
    const response = await ProfileApiServices.apiUpdateUserEmploymentInfo(
      payload
    );
    return response;
  }
);

export const updateUserContractInfo = createAsyncThunk(
  "ProfileReducer/updateUserContractInfo",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await ProfileApiServices.updateUserContractInfoRequest(
        payload
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const createLogOverTime = createAsyncThunk(
  "ProfileReducer/createLogOverTime",
  async (payload) => {
    const response = await ProfileApiServices.createUserLogOverTimeRequest(
      payload
    );
    return response;
  }
);
export const updateLogOverTime = createAsyncThunk(
  "ProfileReducer/updateLogOverTime",
  async (payload) => {
    const response = await ProfileApiServices.updateLogOverTime(payload);
    return response;
  }
);

export const updateUserImage = createAsyncThunk(
  "ProfileReducer/updateUserImage",

  async (payload, { rejectWithValue }) => {
    try {
      const response = await ProfileApiServices.updateUserImageRequest(payload);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
const states = {
  pending: "pending",
  idle: "idle",
};

const initialState = {
  user: null,
  loading: "idle",
  // loading: "idle",
  // loading: "idle",
  // loading: "idle",
  // loading: "idle",
  // loading: "idle",
  // loading: "idle",
  // loading: "idle",
  overTimeDetails: [],
  overTimeOff: [],
  logOverTime: null,
  contactInfo: null,
  personalInfo: null,
  emergencyContacts: [],
  toilData: [],
  payableData: [],
  profileAbsence: [],
  userEmployementContractInfo: null,
  userMedicalInfo: null,
  userEmployementRoleInfo: null,
  userEmployementSensitiveInfo: null,
  userEmployementSalaryInfo: null,
};
export const ProfileReducer = createReducer(initialState, {
  [updateProfileUserObjectInReducer.fulfilled]: (state, action) => {
    return {
      ...state,
      user: getMergedObject(current(state.user), action.payload.updatedUser),
    };
  },

  [resendInvitationRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    };
  },
  [resendInvitationRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },
  [resendInvitationRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },

  [deletetUserAbsence.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    };
  },
  [deletetUserAbsence.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },
  [deletetUserAbsence.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },

  [getUserAbsence.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    };
  },
  [getUserAbsence.fulfilled]: (state, action) => {
    return {
      ...state,
      profileAbsence: action.payload.data,
      loading: states.idle,
    };
  },
  [getUserAbsence.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },

  [getUserLog.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    };
  },
  [getUserLog.fulfilled]: (state, action) => {
    return {
      ...state,
      logOverTime: action.payload.data,
      loading: states.idle,
    };
  },
  [getUserLog.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },

  [getUserProfile.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    };
  },
  [getUserProfile.fulfilled]: (state, action) => {
    return {
      ...state,
      user: action.payload.data,
      loading: states.idle,
      loading: states.idle,
    };
  },
  [getUserProfile.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },

  [changeUserProfileStatus.pending]: (state, action) => {
    return {
      ...state,
      user: updateUserStatus(
        current(state.user),
        action.meta.arg.currentStatus
      ),
    };
  },
  [changeUserProfileStatus.fulfilled]: (state, action) => {
    return {
      ...state,
    };
  },
  [changeUserProfileStatus.rejected]: (state, action) => {
    return {
      ...state,
    };
  },

  [getUserProfileOverTime.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    };
  },
  [getUserProfileOverTime.fulfilled]: (state, action) => {
    return {
      ...state,
      overTimeDetails: action.payload.data,
      toilData: handletToilData(action.payload.data),
      payableData: handletPayableData(action.payload.data),
      loading: states.idle,
    };
  },
  [getUserProfileOverTime.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },
  [getUserProfileOverTimeOff.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    };
  },
  [getUserProfileOverTimeOff.fulfilled]: (state, action) => {
    return {
      ...state,
      overTimeOff: action.payload.data,
      loading: states.idle,
    };
  },
  [getUserProfileOverTimeOff.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },
  [cancelUserProfileOverTime.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    };
  },
  [cancelUserProfileOverTime.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },
  [cancelUserProfileOverTime.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },

  [getUserProfileContactInfo.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    };
  },
  [getUserProfileContactInfo.fulfilled]: (state, action) => {
    return {
      ...state,
      contactInfo: action.payload.data,
      loading: states.idle,
    };
  },
  [getUserProfileContactInfo.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },

  [updateUserProfileContactInfo.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    };
  },
  [updateUserProfileContactInfo.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },
  [updateUserProfileContactInfo.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },

  [getUserProfilePersonalInfo.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    };
  },
  [getUserProfilePersonalInfo.fulfilled]: (state, action) => {
    return {
      ...state,
      personalInfo: action.payload.data,
      loading: states.idle,
    };
  },
  [getUserProfilePersonalInfo.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },

  [updateUserProfilePersonalInfo.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    };
  },
  [updateUserProfilePersonalInfo.fulfilled]: (state, action) => {
    return {
      ...state,

      loading: states.idle,
    };
  },
  [updateUserProfilePersonalInfo.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },

  [getUserProfileEmergencyContacts.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    };
  },
  [getUserProfileEmergencyContacts.fulfilled]: (state, action) => {
    return {
      ...state,
      emergencyContacts: action.payload.data,
      loading: states.idle,
    };
  },
  [getUserProfileEmergencyContacts.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },

  [deleteUserProfileImage.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    };
  },
  [deleteUserProfileImage.fulfilled]: (state, action) => {
    return {
      ...state,

      loading: states.idle,
    };
  },
  [deleteUserProfileImage.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },
  [createEmergencyContact.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    };
  },
  [createEmergencyContact.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },
  [createEmergencyContact.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },

  [updateEmergencyContact.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    };
  },
  [updateEmergencyContact.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },
  [updateEmergencyContact.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },

  [updateUserMedicalInfo.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    };
  },
  [updateUserMedicalInfo.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },
  [updateUserMedicalInfo.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },

  [getUserMedicalInfo.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    };
  },
  [getUserMedicalInfo.fulfilled]: (state, action) => {
    return {
      ...state,
      userMedicalInfo: action.payload.data,
      loading: states.idle,
    };
  },
  [getUserMedicalInfo.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },

  [deleteEmergencyContact.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    };
  },
  [deleteEmergencyContact.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },
  [deleteEmergencyContact.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },

  [getUserEmployementContractInfo.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    };
  },
  [getUserEmployementContractInfo.fulfilled]: (state, action) => {
    return {
      ...state,
      userEmployementContractInfo: action.payload.data,
      loading: states.idle,
    };
  },
  [getUserEmployementContractInfo.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },

  // [getUserEmployementRoleInfo.pending]: (state, action) => {
  //   return {
  //     ...state,
  //     loading: states.pending,
  //   };
  // },
  // [getUserEmployementRoleInfo.fulfilled]: (state, action) => {
  //   return {
  //     ...state,
  //     userEmployementRoleInfo: action.payload.data,
  //     loading: states.idle,
  //   };
  // },
  // [getUserEmployementRoleInfo.rejected]: (state, action) => {
  //   return {
  //     ...state,
  //     loading: states.idle,
  //   };
  // },

  [getUserEmploymentSensitiveInformation.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      userEmployementSensitiveInfo: null,
    };
  },
  [getUserEmploymentSensitiveInformation.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      userEmployementSensitiveInfo: action.payload.data,
    };
  },
  [getUserEmploymentSensitiveInformation.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },

  [updateUserEmploymentSensitiveInformation.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    };
  },
  [updateUserEmploymentSensitiveInformation.fulfilled]: (state, action) => {
    // console.log(" ==== actionaction ", action);
    return {
      ...state,
      loading: states.idle,
      userEmployementSensitiveInfo: action.meta.arg.sensitiveInfo,
    };
  },
  [updateUserEmploymentSensitiveInformation.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },

  [getUserEmploymentSalaryInformation.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    };
  },
  [getUserEmploymentSalaryInformation.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      userEmployementSalaryInfo: action.payload.data,
    };
  },
  [getUserEmploymentSalaryInformation.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },

  [updateUserEmployementSalaryInformation.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    };
  },
  [updateUserEmployementSalaryInformation.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },
  [updateUserEmployementSalaryInformation.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },

  [updateUserContractAnnualLeave.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    };
  },
  [updateUserContractAnnualLeave.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },
  [updateUserContractAnnualLeave.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },

  [updateUserEmploymentInfo.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    };
  },
  [updateUserEmploymentInfo.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },
  [updateUserEmploymentInfo.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },

  [updateUserContractInfo.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    };
  },
  [updateUserContractInfo.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },
  [updateUserContractInfo.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },

  [createLogOverTime.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    };
  },
  [createLogOverTime.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },
  [createLogOverTime.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },
  [updateLogOverTime.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    };
  },
  [updateLogOverTime.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },
  [updateLogOverTime.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },

  [updateUserImage.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    };
  },
  [updateUserImage.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },
  [updateUserImage.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
    };
  },

  [stopLoaderAndEmptyErrors.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [logoutRequest.fulfilled]: (state, action) => {
    return {
      ...initialState,
    };
  },
});

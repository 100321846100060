import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Collapse } from "antd";
import StaffingCheckbox from "./CategoryButton";
import useEmployeeData from "../../customHooks/useEmployeeData";
import AppConstants from "../../Helpers/AppConstants";
import useLocalisedConstants from "../../customHooks/useLocalisedConstants";

export default function MultipleEmployeesCSV({ downloadCSV, uploadCSV }) {
  const { t } = useTranslation();
  const { Panel } = Collapse;
  const [formattedData, setFormattedData] = useState([]);

  const EmployeesData = useEmployeeData();
  const appConstants = useLocalisedConstants();

  useEffect(() => {
    setFormattedData(EmployeesData);
  }, []);

  function changeStaffingStatus(data) {
    const state = [...formattedData];
    for (let i = 0; i < state.length; i++) {
      state[i].active = false;
    }
    const index = state.findIndex((i) => i.id === data.id);
    state[index] = { ...state[index], active: true };
    setFormattedData(state);
  }

  function resetActive() {
    const state = [...formattedData];
    for (let i = 0; i < state.length; i++) {
      state[i].active = false;
    }
    setFormattedData(state);
  }
  const onCardClickHandler = (data) => {
    const state = [...formattedData];
    const index = state.findIndex((i) => i.id === data.id);
    if (state[index].id == 1) {
      downloadCSV();
    } else {
      uploadCSV();
    }
  };
  return (
    <div>
      <div className="d-flex flex-wrap-sm gap-2">
        {formattedData.map((i, index) => {
          return (
            <StaffingCheckbox
              onClick={() => onCardClickHandler(i)}
              ResetActive={() => resetActive()}
              key={index}
              onChange={(e) => changeStaffingStatus(i)}
              checked={i.active}
              active={i.active}
              title={i.title}
              id={i.id}
              subtitle={i.subtitle}
            />
          );
        })}
      </div>
      <Collapse className="my-4">
        <Panel
          key="1"
          className="site-collapse-custom-panel"
          header={t("guideToCSV")}
        >
          <ul className="mx-4 list-disc">
            <li className="text-danger">
              <strong>{t("commaException")}</strong>
            </li>
            <ListItem title={t("downloadCsvTemplateFromAbove")} />
            <ListItem title={t("fileShouldBeInCsvFormat")} />
            <ListItem title={t("emailShouldBeUnique")} />
            <li>
              {t("makeSureYouUse")}{" "}
              <strong>{AppConstants.defaultDateFormat}</strong>{" "}
              {t("formatInDates")}
            </li>
          </ul>
          <strong>{t("optionsFor") + ":"}</strong>
          <ul style={{ listStyleType: "circle" }}>
            <UnorderedList
              title={t("title")}
              dataArray={appConstants.titleArray}
              concatenated={false}
            />
            {/* <UnorderedList
              title={t("niCategory")}
              dataArray={appConstants.aToZList}
              concatenated={false}
            /> */}
            <UnorderedList
              title={t("gender")}
              dataArray={appConstants.gendersForList}
              concatenated={false}
            />
            <UnorderedList
              title={t("maritalStatus")}
              dataArray={appConstants.maritalStatuses}
              concatenated={false}
            />
            <UnorderedList
              title={t("paymentFrequency")}
              dataArray={appConstants.paymentFrequencyList}
              concatenated={false}
            />
            <UnorderedList
              title={t("salaryPerPeriod")}
              dataArray={appConstants.salaryPerPeriodList}
              concatenated={false}
            />
            <UnorderedList
              title={t("contractedHoursPerPeriod")}
              dataArray={appConstants.contractedHoursPerPeriodList}
              concatenated={false}
            />
            <UnorderedList
              title={t("bankAccountType")}
              dataArray={appConstants.bankAccontTypes}
              concatenated={false}
            />
            <UnorderedList
              title={t("employementTypes")}
              dataArray={appConstants.employementTypes}
              concatenated={false}
            />
            {/* <UnorderedList
              title={t("wk1mth1Basis")}
              dataArray={appConstants.yesNoArray}
            /> */}
            <UnorderedList
              title={t("contactPermission")}
              dataArray={appConstants.yesNoArray}
            />
            <UnorderedList
              title={t("nonUKWorker")}
              dataArray={appConstants.yesNoArray}
            />
            <UnorderedList
              title={t("rightToWorkConfirmStatus")}
              dataArray={appConstants.rightToWorkConfirmStatus}
            />
            <UnorderedList
              title={t("directorStatus")}
              dataArray={appConstants.directorStatus}
            />
            <UnorderedList
              title={t("contactRelationship")}
              dataArray={appConstants.relationships}
            />
            <UnorderedList
              title={t("rightToWorkDocumentType")}
              dataArray={appConstants.rightToWorkDocTypes}
            />
            <UnorderedList
              title={t("autoEnrExReason")}
              dataArray={appConstants.autoEnrExReasons}
            />
          </ul>
        </Panel>
      </Collapse>
    </div>
  );
}

const UnorderedList = ({ title = "", dataArray = [], concatenated = true }) => {
  const { Panel } = Collapse;

  return (
    <Collapse className="mt-4">
      <Panel key="1" className="site-collapse-custom-panel" header={title}>
        {/* <strong>{title + ":"}</strong> */}
        <ul className="mx-4 list-disc">
          {dataArray.map((item, index) => {
            const { value } = item;
            if (value) {
              return (
                <ListItem
                  title={concatenated ? `${index - 1} = ${value}` : value}
                  key={index}
                />
              );
            }
          })}
        </ul>
      </Panel>
    </Collapse>
  );
};

const ListItem = ({ title }) => {
  return (
    <li>
      <p className="mb-0">{title}</p>
    </li>
  );
};

export const AppImages = {
  placeholder: require("../Images/placeholder.jpg"),
  cardTwo: require("../Images/card-2.png"),
  login_img: require("../Images/login.png"),
  // brightHrLogo: require("../Images/logo.png"),
  speedyHrLogo: require("../assets/images/logo.png"),
  speedyHrIconHd: require("../Images/icon-hd-login-page.png"),
  // playStore: "/static/media/playstore.b9d2a034359f2355fbee.png",
  // appStore: "/static/media/appstore.eff3c9eecd57221cf7fd.png",
  playStore: require("../Images/playstore.png"),
  appStore: require("../Images/appstore.png"),
  side_img: require("../Images/sidebar-img_2.png"),
  def_icon: require("../Images/def_icon.png"),
  sidebar_img: require("../Images/sidebar-img.png"),
  logo: require("../assets/images/logo.png"),
  blueDots: require("../assets/images/blue-dots.svg"),
  lft_sec_1: require("../assets/images/sects/1.png"),
  lft_sec_2: require("../assets/images/sects/2.png"),
  lft_sec_3: require("../assets/images/sects/3.png"),
  lft_sec_6: require("../assets/images/sects/6.png"),
  sum_home: require("../Images/sum_home.svg").default,
  sum_work: require("../Images/sum_work.svg").default,
  sum_other: require("../Images/sum_other.svg").default,
  no_image: require("../assets/images/noImage.jpg"),
  crossIcon: require("../assets/images/cross-icon.png"),
};

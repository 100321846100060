import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import {
  updateComapanySettingsOB,
  getCompanySettingsOB,
} from "../../Redux/reducers/SettingsReducer";
import { employeesRequest } from "../../Redux/reducers/EmployeesReducer";
import { useSearchParams } from "react-router-dom";
import { Applogger } from "../../Helpers/Logger";
import { get } from "lodash";
import { useTitleHandler } from "../../customHooks/useTitleHandler";
import { showFaliureToast, showSuccessToast } from "../../Utilities";
import Header from "../../components/Header/Header";
import AppConstants from "../../Helpers/AppConstants";
import PrevilageNotFound from "../PageNotFound/PrevilageNotFound";
import RoleAndPrevilegas from "../../components/RoleAndPrevilegas/RoleAndPrevilegas";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";
import WorkingTimePattern from "../../components/OnBoardingComponents/WorkingTimePattern";
import LocationType from "../../components/OnBoardingComponents/LocationType";
import CompanyDetails from "../../components/OnBoardingComponents/CompanyDetails";
import UseLogoutHandler from "../../Helpers/useLogoutHandler";
import {
  addMonthsInCurrentDate,
  getCurrentDate,
} from "../../Helpers/commonFunc";
import moment from "moment";

export default function Setting() {
  // Reducers
  const { lng } = useSelector((state) => state.languageReducer);
  const { companySettingsOB } = useSelector((state) => state.settings);
  // Params
  const [params] = useSearchParams();
  useEffect(() => {
    getEmployees();
  }, []);

  // Dispatcher
  const dispatch = useDispatch();

  // Localisation
  const { t } = useTranslation();

  // Hooks
  UseLogoutHandler();
  useTitleHandler(t("settings"));

  const dateFormat = AppConstants.defaultDateFormat;
  // Priviliges
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { exist: listWTP } = usePrevilagesExist(
    AppConstants.previlages.listWTP
  );

  const { exist: listLocation } = usePrevilagesExist(
    AppConstants.previlages.listLocation
  );

  const { exist: listRoleSetting } = usePrevilagesExist(
    AppConstants.previlages.listRoleSetting
  );

  const { token, role, user } = useSelector(
    (state) => state.AuthenticationReducer
  );

  // Constants
  const companyId = get(user, "company_id", "");

  // Local States
  const [companySettingsObj, setCompanySettingsObj] = useState({
    company_title: "",
    hq_address: "",
    latitude: "",
    longitude: "",
    description: "",
    company_logo: { file: null, url: "" },
    fiscal_start_date:
      get(companySettingsOB, "fiscal_start_date", 0) ??
      moment(getCurrentDate(), dateFormat).date(),
    fiscal_start_month:
      get(companySettingsOB, "fiscal_start_month", 0) ??
      moment(getCurrentDate(), dateFormat).month() + 1,
    fiscal_end_date:
      get(companySettingsOB, "fiscal_end_date", 0) ??
      moment(addMonthsInCurrentDate(12), dateFormat).date(),
    fiscal_end_month:
      get(companySettingsOB, "fiscal_end_month", 0) ??
      moment(addMonthsInCurrentDate(12), dateFormat).month() + 1,
  });

  const handleUpdateComapanySettingsOB = () => {
    if (!companySettingsObj.latitude || !companySettingsObj.longitude) {
      showFaliureToast("Please select valid address");
      return;
    }
    const body = companySettingsObj;
    dispatch(updateComapanySettingsOB({ token, body, company_id: companyId }))
      .then(unwrapResult)
      .then((res) => {
        showSuccessToast(res ?? "Company settings updated successfully");
        Applogger("Company settings updated successfully", res);
        handleGetCompanySettings();
      })
      .catch((err) => {
        showFaliureToast("Unable to update comapny settings");
        Applogger("Error at updateComapanySettings", err);
      });
  };
  function getEmployees() {
    dispatch(
      employeesRequest({
        token,
        lng,
        offset: params.get("offset") ? params.get("offset") : 0,
        employeesFilters: {
          searchQuery: params.get("search"),
        },
        type: "list",
      })
    )
      .then(unwrapResult)
      .then((response) => {
        Applogger("Response at employeesRequest", response);
      })
      .catch((err) => {
        Applogger("Error at employeesRequest", err.message);
      });
  }
  const handleGetCompanySettings = () => {
    dispatch(getCompanySettingsOB({ token, company_id: companyId }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Company settings received successfully", res);
      })
      .catch((err) => {
        showFaliureToast("Unable to get comapny settings");
        Applogger("Error at updateComapanySettings", err.message);
      });
  };

  const companySettingDisableHandler = () => {
    const { company_title, hq_address } = companySettingsObj;
    return !company_title || !hq_address;
  };

  return (
    <div>
      <Header active={"Settings"} />
      <br />
      <div className="container_custom">
        <h1 className="top-heading">{t("settings")}</h1>
      </div>
      <div className="container_custom animate__animated animate__fadeIn">
        <ul className="nav nav-tabs mb-3 " id="ex1" role="tablist">
          <TabItemsButton
            id="ex1-tab-1"
            href="#ex1-tabs-1"
            ariaControl="ex1-tabs-1"
            title={t("companyDetails")}
            ariaSelected={selectedIndex == 0}
            onClick={() => setSelectedIndex(0)}
          />
          {listWTP && (
            <TabItemsButton
              id="ex1-tab-3"
              href="#ex1-tabs-3"
              ariaControl="ex1-tabs-3"
              title={t("workingTimePatterns")}
              ariaSelected={selectedIndex == 2}
              onClick={() => setSelectedIndex(2)}
            />
          )}
          {listLocation && (
            <TabItemsButton
              id="ex1-tab-4"
              href="#ex1-tabs-4"
              ariaControl="ex1-tabs-4"
              title={t("location")}
              ariaSelected={selectedIndex == 3}
              onClick={() => setSelectedIndex(3)}
            />
          )}
          {listRoleSetting && (
            <TabItemsButton
              id="ex1-tab-5"
              href="#ex1-tabs-5"
              ariaControl="ex1-tabs-5"
              title={t("rolesAndPrevilages")}
              ariaSelected={selectedIndex == 4}
              onClick={() => setSelectedIndex(4)}
            />
          )}
        </ul>
        <div className="tab-content" id="ex1-content">
          <div
            className="tab-pane fade show active"
            id="ex1-tabs-1"
            role="tabpanel"
            aria-labelledby="ex1-tab-1"
          >
            <CompanyDetails
              companySettingsObj={companySettingsObj}
              setCompanySettingsObj={setCompanySettingsObj}
              currentStep={null}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                disabled={companySettingDisableHandler()}
                className="btn btn-primary-cs "
                onClick={() => handleUpdateComapanySettingsOB()}
              >
                {t("save")}
              </button>
            </div>
          </div>
          {listWTP && (
            <div
              className="tab-pane fade show"
              id="ex1-tabs-3"
              role="tabpanel"
              aria-labelledby="ex1-tab-3"
            >
              <WorkingTimePattern />
            </div>
          )}
          {listLocation && (
            <div
              className="tab-pane fade show"
              id="ex1-tabs-4"
              role="tabpanel"
              aria-labelledby="ex1-tab-4"
            >
              <LocationType />
            </div>
          )}
          {listRoleSetting && (
            <div
              className="tab-pane fade show"
              id="ex1-tabs-5"
              role="tabpanel"
              aria-labelledby="ex1-tab-5"
            >
              <RoleAndPrevilegas />
            </div>
          )}
          {/* No Details of this view */}
          {/* <div
            className="tab-pane animate__animated animate__backInUp fade show p-2"
            id="ex1-tabs-2"
            role="tabpanel"
            aria-labelledby="ex1-tab-2"
          >
            <Permission />
          </div> */}
          {/* No Details of this view */}
          {/* <div
            className="tab-pane fade show p-2"
            id="ex1-tabs-4"
            role="tabpanel"
            aria-labelledby="ex1-tab-4"
          >
            <Notifications />
          </div> */}
        </div>
      </div>
    </div>
  );
}

const TabItemsButton = (props) => {
  return (
    <li className="nav-item " role="presentation">
      <a
        className={`nav-link ${props.ariaSelected ? "active" : ""}`}
        data-mdb-toggle="tab"
        role="tab"
        aria-selected="false"
        id={props.id}
        href={props.href}
        aria-controls={props.ariaControl}
        onClick={props.onClick}
      >
        {props.title}
      </a>
    </li>
  );
};

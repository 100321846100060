import React from "react";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import { formattedDateForAPI } from "../../../../Utilities/index";
import EmpDetailsField from "../Components/EmpDetailsField";
import useLocalisedConstants from "../../../../customHooks/useLocalisedConstants";
import EmplStatus from "../Components/EmplStatus";
import EmplDropDown from "../Components/EmplDropDown";

export default function ContractDetails({
  employeeForm,
  setEmployeeForm,
  currentType,
}) {
  const { t } = useTranslation();

  const appConstants = useLocalisedConstants();
  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-between gap-2 col-md-12 col-sm-12">
        <div className="d-flex gap-2 col-lg-12 col-md-12 col-sm-12 flex-wrap justify-between">
          <EmpDetailsField
            title={t("employeeReference")}
            placeholder={t("employeeReference")}
            value={employeeForm.employee_reference}
            isRequired={true}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                employee_reference: e.target.value,
              })
            }
          />
          <EmpDetailsField
            title={t("worksReference")}
            placeholder={t("worksReference")}
            value={employeeForm.works_reference}
            isRequired={false}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                works_reference: e.target.value,
              })
            }
          />
          <EmpDetailsField
            title={t("workEndDate")}
            placeholder={t("workEndDate")}
            value={employeeForm.termination_date}
            type={"date"}
            isRequired={false}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                termination_date: formattedDateForAPI(e.target.value),
              })
            }
          />
          <EmpDetailsField
            title={t("taxCode")}
            placeholder={t("taxCode")}
            value={employeeForm.tax_code}
            isRequired={true}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                tax_code: e.target.value,
              })
            }
          />
          <EmpDetailsField
            title={t("niNumber")}
            placeholder={t("niNumber")}
            value={employeeForm.ni_number}
            isRequired={true}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                ni_number: e.target.value,
              })
            }
          />
          {/* <EmplDropDown
            title={t("niCategory")}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                ni_category: get(JSON.parse(e.target.value), "value", ""),
              })
            }
            value={employeeForm.ni_category}
            dataArray={appConstants.aToZList}
            displayKey="title"
            valueKey="value"
            isRequired={true}
          /> */}
          {/* <EmplStatus
            title={t("wk1mth1Basis")}
            isRequired={false}
            isActive={employeeForm.wk1mth1_basis == "1"}
            onClick={(value) => {
              setEmployeeForm({
                ...employeeForm,
                wk1mth1_basis: value,
              });
            }}
          /> */}

          <EmplDropDown
            title={t("paymentFrequency")}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                payment_frequency: get(JSON.parse(e.target.value), "value", ""),
              })
            }
            value={employeeForm.payment_frequency}
            dataArray={appConstants.paymentFrequencyList}
            displayKey="title"
            valueKey="value"
            isRequired={true}
          />

          <EmpDetailsField
            title={t("grossSalary")}
            placeholder={t("grossSalary")}
            value={employeeForm.gross_salary}
            isRequired={false}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                gross_salary: e.target.value,
              })
            }
          />
          <EmplDropDown
            title={t("salaryPerPeriod")}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                salary_per_period: get(JSON.parse(e.target.value), "value", ""),
              })
            }
            value={employeeForm.salary_per_period}
            dataArray={appConstants.salaryPerPeriodList}
            displayKey="title"
            valueKey="value"
            isRequired={false}
          />
          <EmpDetailsField
            title={t("contractedHours")}
            placeholder={t("contractedHours")}
            value={employeeForm.contracted_hours}
            isRequired={false}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                contracted_hours: e.target.value,
              })
            }
          />

          <EmplDropDown
            title={t("contractedHoursPerPeriod")}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                contracted_hours_per_period: get(
                  JSON.parse(e.target.value),
                  "value",
                  ""
                ),
              })
            }
            value={employeeForm.contracted_hours_per_period}
            dataArray={appConstants.contractedHoursPerPeriodList}
            displayKey="title"
            valueKey="value"
            isRequired={false}
          />
          {/*  */}
          {/* <EmpDetailsField
            title={t("sortCode")}
            placeholder={t("sortCode")}
            value={employeeForm.sort_code}
            isRequired={true}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                sort_code: e.target.value,
              })
            }
          /> */}
          <EmpDetailsField
            title={t("payslipEmailAddress")}
            placeholder={t("payslipEmailAddress")}
            value={employeeForm.payslip_email_address}
            isRequired={false}
            type={"email"}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                payslip_email_address: e.target.value,
              })
            }
          />
          <EmpDetailsField
            title={t("payslipPassword")}
            placeholder={t("payslipPassword")}
            value={employeeForm.payslip_password}
            isRequired={false}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                payslip_password: e.target.value,
              })
            }
          />

          <EmplStatus
            title={t("nonUKWorker")}
            isRequired={false}
            isActive={employeeForm.non_uk_worker == "1"}
            onClick={(value) => {
              setEmployeeForm({
                ...employeeForm,
                non_uk_worker: value,
              });
            }}
          />
          <EmplDropDown
            title={t("rightToWorkConfirmStatus")}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                right_to_work_confirm_status: get(
                  JSON.parse(e.target.value),
                  "value",
                  ""
                ),
              })
            }
            value={employeeForm.right_to_work_confirm_status}
            dataArray={appConstants.rightToWorkConfirmStatus}
            displayKey="placeholder"
            valueKey="value"
            isRequired={true}
          />
          <EmplDropDown
            title={t("rightToWorkDocumentType")}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                right_to_work_document_type: get(
                  JSON.parse(e.target.value),
                  "value",
                  ""
                ),
              })
            }
            value={employeeForm.right_to_work_document_type}
            dataArray={appConstants.rightToWorkDocTypes}
            displayKey="placeholder"
            valueKey="value"
            isRequired={false}
          />
          <EmpDetailsField
            title={t("rightToWorkDocumentExpiryDate")}
            placeholder={t("rightToWorkDocumentExpiryDate")}
            value={employeeForm.right_to_work_document_expiry_date}
            type={"date"}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                right_to_work_document_expiry_date: formattedDateForAPI(
                  e.target.value
                ),
              })
            }
            isRequired={false}
          />
          <EmpDetailsField
            title={t("rightToWorkReference")}
            placeholder={t("rightToWorkReference")}
            value={employeeForm.right_to_work_reference}
            isRequired={false}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                right_to_work_reference: e.target.value,
              })
            }
          />
          <EmplDropDown
            title={t("autoEnrExReason")}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                auto_enrolment_exclusion_reason: get(
                  JSON.parse(e.target.value),
                  "value",
                  ""
                ),
              })
            }
            value={employeeForm.auto_enrolment_exclusion_reason}
            dataArray={appConstants.autoEnrExReasons}
            displayKey="title"
            valueKey="value"
            isRequired={false}
          />
          <EmpDetailsField
            title={t("firstDayCivilianEmp")}
            placeholder={t("firstDayCivilianEmp")}
            value={employeeForm.first_day_of_civilian_employment}
            type={"date"}
            isRequired={false}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                first_day_of_civilian_employment: formattedDateForAPI(
                  e.target.value
                ),
              })
            }
          />
        </div>
      </div>
    </div>
  );
}

import React from "react";

export default function SimpleButton(props) {
  return (
    <button
      style={{ width: props.width }}
      disabled={props.disabled}
      onClick={props.onClick}
      className={`
            ${props.className}
             btn-lg-light btn 
             ${props.btnPink ? "btn-primary-cs" : null}
             ${props.btnOutline ? "btn-primary-outline-cs" : null}
             ${props.textWhite ? "text-white" : ""}
             ${props.btnBlock ? "btn-block" : null}
             `}
    >
      <span>{props.text}</span>
      {props.children}
    </button>
  );
}

import { toast } from "react-toastify";
import { Applogger } from "../Helpers/Logger";
import { get } from "lodash";
import { employeeTypes } from "../components/Employees/Forms/FormConstants";
import AppConstants from "../Helpers/AppConstants";
import moment from "moment";

export const isUserLineManager = (user) => {
  return get(user, "is_approver", false);
};

export const updateStaffingStatus = (oldStaffing, payload) => {
  var newData = [];
  oldStaffing.forEach((element) => {
    if (element.rota_id == payload.StaffingToUpdate.rota_id) {
      newData.push({
        ...element,
        is_publish: payload.StaffingToUpdate.is_publish == 1 ? 0 : 1,
      });
    } else {
      newData.push(element);
    }
  });

  return newData;
};

export const updateUserStatus = (user, response) => {
  return Object.assign(
    {},
    {
      ...user,
      working_status: response,
    }
  );
};

export const handletPayableData = (response) => {
  var payableData = [];

  if (response.length > 0) {
    for (let i = 0; i < response.length; i++) {
      const element = response[i];
      if (element.reward != "TOIL") {
        payableData.push(element);
      }
    }
  }
  return payableData;
};

export const handletToilData = (response) => {
  var toilData = [];

  if (response.length > 0) {
    for (let i = 0; i < response.length; i++) {
      const element = response[i];
      if (element.reward == "TOIL") {
        toilData.push(element);
      }
    }
  }
  return toilData;
};

export const showFaliureToast = (message) => {
  toast.dismiss();
  const options = {
    // style: { backgroundColor: "red" },
  };
  toast.error(message, options);
};

export const showInfoToast = (message) => {
  toast.dismiss();
  const options = {
    // style: { backgroundColor: "red" },
  };
  toast.info(message, options);
};

export const showWarningToast = (message) => {
  toast.dismiss();
  const options = {
    // style: { backgroundColor: "red" },
  };
  toast.warning(message, options);
};

export const showSuccessToast = (message) => {
  toast.dismiss();

  const options = {
    // style: { backgroundColor: "yellow" },
  };
  toast.success(message, options);
};

export const validatePassword = (password) => {
  const re =
    /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?.,./{}|\":<>\[\]\\\' ~_]).{8,}/;
  return re.test(password);
};

export const getEmployeeName = (employee) => {
  var employeeName = "";
  const { first_name, last_name } = employee;
  if (first_name != null && first_name != undefined) {
    employeeName = first_name;
  }
  if (last_name != null && first_name != last_name) {
    employeeName = employeeName + " " + last_name;
  }
  return employeeName;
};

export const indexFinder = (id, Array) => {
  try {
    if (Array.length > 0) {
      const FoundIndex = Array.findIndex((Data) => Data.id === id);
      return FoundIndex;
    }
  } catch (err) {
    Applogger("Error at indexFinder", err);
  }
};

export const unixToDateFormaterOld = (
  unix,
  format = AppConstants.defaultDateFormat
) => {
  if (unix) {
    const formatedDate = moment.unix(unix).zone(0).format(format);
    return formatedDate;
  } else {
    return "";
  }
};

export const unixToDateFormater = (
  unix,
  format = AppConstants.defaultDateFormat
) => {
  if (unix) {
    const formatedDate = moment.unix(unix).zone(0).format(format);
    return formattedDateForAPI(formatedDate);
  } else {
    return "";
  }
};

export const getTimeFromDateTimestamp = (unix, format) => {
  return moment(moment.unix(unix).zone(0).format(format)).format(
    AppConstants.timeFormat
  );
};

export const getTimeDifference = (startTime, endTime) => {
  try {
    var diff = Math.abs(
      new Date(`2011/10/09 ${startTime}`) - new Date(`2011/10/09 ${endTime}`)
    );

    var minutes = Math.floor(diff / 1000 / 60);
    return minutes;
  } catch (err) {
    Applogger("Error at getTimeDifference", err);
  }
};

export const enableOnlyNumbers = (e) => {
  e = e ? e : window.event;
  var charCode = e.which ? e.which : e.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    e.preventDefault();
  }
};

export const preventMinus = (e) => {
  if (e.code === "Minus") {
    e.preventDefault();
  }
};

export const differenceInHours = (date1, date2) => {
  var diff = Date.parse(date2.valueOf()) - Date.parse(date1.valueOf());
  var diffInHours = diff / 1000 / 60 / 60; //
  return diffInHours;
};

export const hexToRGB = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
      result[3],
      16
    )},0.1)`
    : null;
};

export const getDailyRoles = (roles) => {
  let filtterArray = [];

  filtterArray = [...roles].filter((role) => {
    return (
      get(role, "role_type", "") == employeeTypes.days &&
      get(role, "name", "") !== AppConstants.roleTypes.Owner
    );
  });

  return filtterArray;
};

export const getHourlyRoles = (roles) => {
  let filtterArray = [];

  filtterArray = [...roles].filter((role) => {
    return (
      get(role, "role_type", "") == employeeTypes.hours &&
      get(role, "name", "") !== AppConstants.roleTypes.Owner
    );
  });

  return filtterArray;
};

export const getCountryIDFromName = (name, countries) => {
  let finalCountry = "";
  if (Array.isArray(countries)) {
    countries.forEach((country) => {
      if (get(country, "name", "") == name) {
        finalCountry = get(country, "country_id", "");
      }
    });
  }

  return finalCountry;
};

export const getCountryNameFromID = (countryId, countries) => {
  let finalCountry = "";
  if (Array.isArray(countries)) {
    countries.forEach((country) => {
      if (get(country, "country_id", "") == countryId) {
        finalCountry = get(country, "name", "");
      }
    });
  }

  return finalCountry;
};

export const getMergedObject = (orignalObject, objectToMerge) => {
  return { ...orignalObject, ...objectToMerge };
};

export const formattedDate = (inputDate) => {
  // Check if the inputDate matches the dd-mm-yyyy, dd/mm/yyyy, yyyy-mm-dd, or yyyy/mm/dd format
  if (inputDate) {
    const match = inputDate.match(
      /(\d{1,2})[-/](\d{1,2})[-/](\d{4})|(\d{4})[-/](\d{1,2})[-/](\d{1,2})/
    );

    if (match) {
      if (match[1]) {
        // Input is in dd-mm-yyyy or dd/mm/yyyy format
        const day = match[1];
        const month = match[2];
        const year = match[3];

        // Reformat the date to yyyy-MM-dd format
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      } else {
        // Input is in yyyy-mm-dd or yyyy/mm/dd format
        const year = match[4];
        const month = match[5];
        const day = match[6];

        // Reformat the date to yyyy-MM-dd format
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const formattedDateForAPI = (inputDate) => {
  // Check if the inputDate matches different date formats
  const match = inputDate.match(
    /(\d{1,2})[-/](\d{1,2})[-/](\d{4})|(\d{4})[-/](\d{1,2})[-/](\d{1,2})/
  );

  if (match) {
    if (match[1]) {
      // Input is in dd-mm-yyyy or dd/mm/yyyy format
      const day = match[1];
      const month = match[2];
      const year = match[3];

      // Reformat the date to yyyy-MM-dd format
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    } else {
      // Input is in yyyy-mm-dd or yyyy/mm/dd format
      const year = match[4];
      const month = match[5];
      const day = match[6];

      // Reformat the date to yyyy-MM-dd format
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  } else {
    return "";
  }
};

export const getTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const isUaeRegion = () => {
  return getTimeZone().toLowerCase().includes("asia");
  // return false; // using this for testing onboarding in the local server
};

export const getCalculatedTableHeight = (heightToMinus = 270) => {
  if (document && window) {
    const element = document.getElementById("onboardingTable");
    if (element) {
      return get(element, "offsetHeight", "30")
        ? element.offsetHeight - heightToMinus + "px"
        : "30vh";
    }
  } else {
    return "30vh";
  }
};

import React, { useEffect, useState, useRef } from "react";
import { Applogger } from "../../Helpers/Logger";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { unwrapResult } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { Space, Table, Tooltip, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { getCalculatedTableHeight, showFaliureToast } from "../../Utilities";
import {
  saveCompanyUserRequest,
  getCompanyEmployeesRequest,
  generateDocument,
  getCompanySettingsOB,
} from "../../Redux/reducers/SettingsReducer";
import { editEmployeesStates } from "../../Redux/reducers/EmployeesReducer";
import UploadSummary from "./UploadSummary";
import ErrorModal from "../Modals/ErrorModal";
import Spinner from "../Spinner";
import AppConstants from "../../Helpers/AppConstants";
import Button from "../OnBoardingComponents/Button";
import SPProfileButton from "../Profile/Components/SPProfileButton";

export default function EditEmployees({
  setFinishPressed = () => {},
  finishPressed = false,
  isOnboarding = false,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companyEmployees, roles, rolesSettings, placesOfWork, loading } =
    useSelector((state) => state.settings);
  const { token, user } = useSelector((state) => state.AuthenticationReducer);
  const { editEmployeesData } = useSelector((state) => state.employees);
  const { lng } = useSelector((state) => state.languageReducer);
  const { t } = useTranslation();
  const companyId = get(user, "company_id", "");
  const countryId = get(user, "country_id", "");

  const [errorUsers, setErrorUsers] = useState([]);
  const [successUsers, setSuccessUsers] = useState([]);
  const [errorsList, setErrorsList] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [isFinishCompleted, setIsFinishCompleted] = useState(false);
  const [employeesList, setEmployeesList] = useState(editEmployeesData);
  const [employeesListDup, setEmployeesListDup] = useState(editEmployeesData);
  const [allRoles, setAllRoles] = useState([]);
  const [serverResponse, setServerResponse] = useState({
    bulk_users: [],
    errorFE: [],
    error_for_not_created_user: [],
    successFE: "",
    user_created: [],
    user_not_created: [],
  });
  const employeesListRef = useRef(editEmployeesData);

  useEffect(() => {
    if (finishPressed) {
      setFinishPressed(false);
      if (!isSaveAllDisabled()) {
        showFaliureToast(t("pleaseFillAllRequiredFields"));
      } else {
        if (employeesList.length > 0) {
          setErrorUsers([]);
          setSuccessUsers([]);
          handleSaveAll();
        } else {
          handleGenerateDocument();
        }
      }
    }
  }, [finishPressed]);

  useEffect(() => {
    getCompanyAllEmployeesRequest();
    return () => {
      persistEditEmployeesData();
    };
  }, []);

  useEffect(() => {
    if (isFinishCompleted) {
      filterUnSavedUsers();
    }
  }, [isFinishCompleted]);

  useEffect(() => {
    setEmployeesList(filteredEmployees());
    setEmployeesListDup(filteredEmployees());
  }, [companyEmployees]);

  useEffect(() => {
    setAllRoles(filteredRoles(roles));
  }, [roles]);

  useEffect(() => {
    employeesListRef.current = employeesList;
  }, [employeesList]);

  const getCompanyAllEmployeesRequest = () => {
    dispatch(getCompanyEmployeesRequest({ token }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getCompanyEmployeesRequest", res);
      })
      .catch((err) => {
        Applogger("Error at getCompanyEmployeesRequest", err);
      });
  };

  const persistEditEmployeesData = () => {
    if (user) {
      setTimeout(() => {
        dispatch(editEmployeesStates(employeesListRef.current));
      }, 500);
    }
  };

  const getCountryIDFromLocation = (location) => {
    let finalCountry = "";
    placesOfWork.forEach((place) => {
      if (get(place, "work_place_id", "") == location) {
        finalCountry = get(place, "country_id", "");
      }
    });

    return finalCountry;
  };

  const getOwnerID = () => {
    let ownerId = "";
    roles.forEach((role) => {
      if (get(role, "name", "") == "Owner") {
        ownerId = get(role, "role_id", "");
      }
    });

    return ownerId;
  };

  const onLocationChangeHandler = (employee, value) => {
    const allUsers = [...employeesList];
    const currentEmployeeIndex = allUsers.findIndex(
      (user) => user.user_id == employee
    );
    if (currentEmployeeIndex !== -1) {
      allUsers[currentEmployeeIndex] = {
        ...allUsers[currentEmployeeIndex],
        work_place_id: value,
      };
    }
    setEmployeesList(allUsers);
  };

  const handleGenerateDocument = () => {
    dispatch(generateDocument({ token, lng, company_id: companyId }))
      .then(unwrapResult)
      .then((res) => {
        callGetCompanySettingsAPI();
        Applogger("Response at generateDocument", res);
      })
      .catch((err) => {
        setErrorsList(get(err, "messages", []));
        setShowErrorModal(true);
        Applogger("Error at generateDocument", err);
        showFaliureToast(
          err?.message ??
            "Found issues in the verification, Please verify added data again"
        );
      });
  };

  const callGetCompanySettingsAPI = () => {
    dispatch(getCompanySettingsOB({ token, lng }))
      .then(unwrapResult)
      .then((res) => {
        setTimeout(() => {
          navigate(AppConstants.routes.dashboard);
        }, 500);
        Applogger("Response at getCompanySettingsOB", res);
      })
      .catch((err) => {
        Applogger("Error at getCompanySettingsOB", err.message);
      });
  };

  const onNameChangeHandler = (employee, value) => {
    const allUsers = [...employeesList];
    const currentEmployeeIndex = allUsers.findIndex(
      (user) => user.user_id == employee
    );
    if (currentEmployeeIndex !== -1) {
      allUsers[currentEmployeeIndex] = {
        ...allUsers[currentEmployeeIndex],
        first_name: value,
      };
    }
    setEmployeesList(allUsers);
  };

  const onLastNameChangeHandler = (employee, value) => {
    const allUsers = [...employeesList];
    const currentEmployeeIndex = allUsers.findIndex(
      (user) => user.user_id == employee
    );
    if (currentEmployeeIndex !== -1) {
      allUsers[currentEmployeeIndex] = {
        ...allUsers[currentEmployeeIndex],
        last_name: value,
      };
    }
    setEmployeesList(allUsers);
  };

  const onRoleChangehandler = (employee, value) => {
    const allUsers = [...employeesList];
    const currentEmployeeIndex = allUsers.findIndex(
      (user) => user.user_id == employee
    );
    if (currentEmployeeIndex !== -1) {
      allUsers[currentEmployeeIndex] = {
        ...allUsers[currentEmployeeIndex],
        role_id: value,
      };
    }
    setEmployeesList(allUsers);
  };

  const filteredEmployees = () => {
    let finalArray = companyEmployees;

    finalArray = finalArray.filter((employee) => {
      return get(employee, "role_id", "") != getOwnerID();
    });

    finalArray.map((item, index) => {
      let persistUser = editEmployeesData.find(
        (persistedUser) =>
          get(item, "user_id", null) == get(persistedUser, "user_id", "")
      );

      const combinedSettings = Object.assign({}, item, persistUser);
      finalArray[index] = combinedSettings;
    });

    if (placesOfWork.length == 1) {
      let workPlaceArray = [];
      finalArray.map((item) => {
        workPlaceArray.push({
          ...item,
          work_place_id: get(placesOfWork, "[0].work_place_id", ""),
        });
      });

      finalArray = workPlaceArray;
    }

    return finalArray;
  };

  const filteredRoles = (roles) => {
    return roles.filter((role) => {
      return (
        get(role, "name", "") != "Owner" &&
        rolesSettings.some(
          (roleSettings) =>
            get(role, "role_id", null) == get(roleSettings, "role_id", "")
        )
      );
    });
  };

  const onSavePressHandler = (employee, index) => {
    const currentEmployeeIndex = employeesList.findIndex(
      (user) => user.user_id == employee
    );

    if (currentEmployeeIndex !== -1) {
      const data = employeesList[currentEmployeeIndex];
      const {
        work_place_id: location,
        first_name: name,
        role_id: role,
        last_name: last_name,
      } = data;
      if (!name || !role || !last_name) {
        showFaliureToast("Kindly fill all details");
        return;
      }
      const checkWorkPlaceId = AppConstants.placeOfWorkArray.find(
        (item) => item.work_place_id == location
      );
      const formData = {
        country: checkWorkPlaceId
          ? countryId
          : getCountryIDFromLocation(location),
        location,
        name,
        role,
        last_name,
      };
      dispatch(
        saveCompanyUserRequest({
          formData,
          token,
          id: employee,
          companyId,
        })
      )
        .then(unwrapResult)
        .then((res) => {
          Applogger("Response at saveCompanyUserRequest", res);
          // showSuccessToast(name + " details updated successfully");
          setSuccessUsers(
            (prev) => [...prev, get(res, "data", null)],
            (updatedState) => updatedState
          );
          if (index == employeesList.length - 1) {
            setTimeout(() => {
              setIsFinishCompleted(true);
            }, 500);
          }
        })
        .catch((err) => {
          setErrorUsers(
            (prev) => [...prev, data],
            (updatedState) => updatedState
          );
          if (index == employeesList.length - 1) {
            setTimeout(() => {
              setIsFinishCompleted(true);
            }, 500);
          }
          // showFaliureToast("Failed to update " + name + " Details");
          Applogger("Error at saveCompanyUserRequest", err);
        });
    }
  };

  const filterUnSavedUsers = () => {
    if (errorUsers.length > 0) {
      let finalArray = [...employeesList];
      finalArray = finalArray.filter(
        (item) =>
          !successUsers.some(
            (successItem) =>
              get(successItem, "user_id", "") == get(item, "user_id", null)
          )
      );
      setIsFinishCompleted(false); // reset for clicking again
      setEmployeesList(finalArray);
      setServerResponse({
        ...serverResponse,
        successFE: successUsers.length > 0 ? "Users Created" : "",
        bulk_users: successUsers,
        user_not_created: finalArray.map((_, index) => index),
      });
      setShowSummaryModal(true);
    } else {
      handleGenerateDocument();
    }
  };

  const onResetPressHandler = (employee) => {
    let allUsers = [...employeesList];
    let allUserDup = [...employeesListDup];

    const currentEmployeeIndex = allUsers.findIndex(
      (user) => user.user_id == employee
    );

    const currentDupEmployeeIndex = allUserDup.findIndex(
      (user) => user.user_id === employee
    );

    if (currentDupEmployeeIndex !== -1 && currentEmployeeIndex !== -1) {
      allUsers[currentEmployeeIndex] = allUserDup[currentDupEmployeeIndex];
    }

    setEmployeesList(allUsers);
  };

  const isSaveAllDisabled = () => {
    if (employeesList.length > 0) {
      const array = employeesList;
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        for (const [key, value] of Object.entries(element)) {
          if (
            key == "work_place_id" ||
            key == "role_id" ||
            key == "first_name" ||
            key == "last_name"
          ) {
            if (!value) {
              return false;
            }
          }
        }
      }
      return true;
    } else {
      return true;
    }
  };

  const columns = [
    {
      title: t("firstName"),
      dataIndex: "first_name",
      key: "first_name",
      render: (name, user) => (
        <input
          onChange={(e) => onNameChangeHandler(user.user_id, e.target.value)}
          value={name}
          className={`filter-form ${!name && "red-border"}`}
        />
      ),
    },
    {
      title: t("lastName"),
      dataIndex: "last_name",
      key: "last_name",
      render: (name, user) => (
        <input
          onChange={(e) =>
            onLastNameChangeHandler(user.user_id, e.target.value)
          }
          value={name}
          className={`filter-form ${!name && "red-border"}`}
        />
      ),
    },
    {
      title: t("location"),
      key: "work_place_id",
      dataIndex: "work_place_id",
      render: (location, user, index) => {
        let value = location;
        if (placesOfWork.length == 1) {
          value = get(placesOfWork, "[0].work_place_id", "");
        }
        // console.log("work place  idd", value);
        return (
          <select
            id={`location-cell-${index}`}
            value={value}
            className={`filter-form ${
              !getValueFromDocID(`location-cell-${index}`) && "red-border"
            }`}
            onChange={(e) => {
              e.preventDefault();
              onLocationChangeHandler(user.user_id, e.target.value);
            }}
          >
            {placesOfWork.length > 1 && (
              <option selected={value ? "" : "selected"} value={""}>
                {t("noLocationAssigned")}
              </option>
            )}
            {Array.isArray(placesOfWork) &&
              placesOfWork.map((val, index) => (
                <option key={index} value={val.work_place_id}>
                  {val.name}
                </option>
              ))}
          </select>
        );
      },
    },
    {
      title: t("role"),
      key: "role",
      dataIndex: "role_id",
      render: (role, user, index) => {
        var value = role;
        if (allRoles.length == 1) {
          value = get(allRoles, "[0].role_id", "");
        }
        // console.log("role idd", value);
        return (
          <select
            id={`role-cell-${index}`}
            value={value}
            className={`filter-form ${
              !getValueFromDocID(`role-cell-${index}`) && "red-border"
            }`}
            onChange={(e) => {
              e.preventDefault();
              onRoleChangehandler(user.user_id, e.target.value);
            }}
          >
            <option selected={value ? "" : "selected"} value={""}>
              {t("noRoleAssigned")}
            </option>
            {Array.isArray(allRoles) &&
              allRoles.map((val, index) => (
                <option key={index} value={val.role_id}>
                  {val.name}
                </option>
              ))}
          </select>
        );
      },
    },

    {
      title: t("action"),
      key: "action",
      width: 125,
      render: (data) => (
        <Space size="middle">
          {data?.role?.name !== AppConstants.roleTypes.Owner && (
            <Button onClick={() => onResetPressHandler(data.user_id)}>
              {t("reset")}
            </Button>
          )}
        </Space>
      ),
    },
  ];

  function getValueFromDocID(id) {
    var e = document.getElementById(id);
    return e?.value;
  }

  const handleSaveAll = () => {
    setErrorUsers([]);
    setSuccessUsers([]);

    employeesList.forEach((employee, index) => {
      onSavePressHandler(get(employee, "user_id", ""), index);
    });
  };

  return (
    <div>
      {loading == "pending" && <Spinner />}
      <ErrorModal
        isOnboarding={isOnboarding}
        showErrorModal={showErrorModal}
        setShowErrorModal={setShowErrorModal}
        errorsList={errorsList}
      />
      <Modal
        open={showSummaryModal}
        onCancel={() => {
          setSuccessUsers([]);

          setShowSummaryModal(false);
        }}
        onOk={() => {
          setSuccessUsers([]);

          setShowSummaryModal(false);
        }}
        width={"85%"}
        title={"Bulk Employee Summary"}
      >
        <UploadSummary summary={serverResponse} employeesList={employeesList} />
      </Modal>
      {!isOnboarding && (
        <Tooltip title={t("pleaseSelectAllFields")} placement="left">
          <SPProfileButton
            onClick={() => handleSaveAll()}
            title={t("saveAll")}
            disabled={!isSaveAllDisabled()}
            addBtnClass="mt-0"
            addConClass="pr-0"
          />
        </Tooltip>
      )}
      <Table
        className="mt-3 "
        columns={columns}
        dataSource={employeesList}
        scroll={{
          x: 900,
          // y: getCalculatedTableHeight(220),
        }}
        pagination={{ pageSize: AppConstants.pagination.size }}
        style={{ marginBottom: 10 }}
      />
    </div>
  );
}

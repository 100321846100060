import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";

export const changeLanguage = createAsyncThunk(
  "languageReducer/changeLanguage",
  async (payload) => {
    return payload;
  }
);

const initialState = {
  lng: "en",
};

export const languageReducer = createReducer(initialState, {
  [changeLanguage]: (state, action) => {
    return {
      ...state,
      lng: action.payload,
    };
  },
});

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logoutRequest } from "../../Redux/reducers/AuthenticationReducer";
import { Applogger } from "../../Helpers/Logger";
import { useSearchParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { useTitleHandler } from "../../customHooks/useTitleHandler";
import { getUserAbsence } from "../../Redux/reducers/ProfileReducers";
import AppConstants from "../../Helpers/AppConstants";
import PublicHolidays from "../../components/Calendar/PublicHolidays";
import MandatoryLeave from "../../components/Calendar/MandatoryLeave";
import DeclinedAbsence from "../../components/Calendar/DeclinedAbsence";
import OthersDeclinedAbsence from "../../components/Calendar/OthersDeclinedAbsence";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";
import PendingRequest from "../../components/Calendar/PendingRequest";
import OthersPendingRequest from "../../components/Calendar/OthersPendingRequest";
import Header from "../../components/Header/Header";
import CalendarSection from "../../components/Calendar/Calendar";
import Spinner from "../../components/Spinner";
import UseLogoutHandler from "../../Helpers/useLogoutHandler";
import "react-big-calendar/lib/css/react-big-calendar.css";

export default function Calender() {
  UseLogoutHandler();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useTitleHandler(t("dtCalendar"));

  const currentYear = new Date().getFullYear();
  const { token, user } = useSelector((state) => state.AuthenticationReducer);
  const { pendingRequests, loading: calenderLoading } = useSelector(
    (state) => state.calender
  );

  const { exist: listPublicLeaves } = usePrevilagesExist(
    AppConstants.previlages.listPublicLeaves
  );

  const { exist: listDeclined } = usePrevilagesExist(
    AppConstants.previlages.listDeclined
  );

  const { exist: listAnnualLeavesCalendar } = usePrevilagesExist(
    AppConstants.previlages.listAnnualLeavesCalendar
  );

  const { exist: ApproveDeclinePendingLeave } = usePrevilagesExist(
    AppConstants.previlages.ApproveDeclinePendingLeave
  );

  const { exist: showPendingTab } = usePrevilagesExist(
    AppConstants.previlages.showPendingTab
  );
  const { exist: showMandatoryTab } = usePrevilagesExist(
    AppConstants.previlages.showMandatoryTab
  );
  const [pendingStateLocal, setPendingStateLocal] = useState([]);
  const [params] = useSearchParams();
  const type = params.get("tab");

  const [modalState, setModalState] = useState(false);
  const userId = get(user, "user_id", "");

  const currentYearEndDate = `31-12-${currentYear}`;
  const currentYearStartDate = `01-01-${currentYear}`;

  useEffect(() => {
    // getEmployees();
    getAbsenceData();
  }, []);

  useEffect(() => {
    if (pendingRequests) {
      setPendingStateLocal(pendingRequests);
    }
  }, [pendingRequests]);

  useEffect(() => {
    try {
      if (type && type == "pending") {
        document.getElementById("ex1-tab-2").click();
      }
    } catch (err) {
      Applogger("This is error at useeffect toggle");
    }
  }, []);

  const logOutRequestHandler = () => {
    dispatch(logoutRequest())
      .then((res) => {
        navigate(AppConstants.routes.login);
      })
      .catch((err) => {
        Applogger("Error at logoutRequest", err.message);
      });
  };

  const handleModalChange = (item) => {
    setModalState(item);
  };

  const getAbsenceData = () => {
    let filterDates = {
      start_date: null,
      end_date: null,
    };
    filterDates.start_date = currentYearStartDate;
    filterDates.end_date = currentYearEndDate;

    dispatch(
      getUserAbsence({
        bearer_token: token,
        user_id: userId,
        Data: filterDates,
      })
    )
      .then(unwrapResult)
      .then((response) => {
        Applogger("Response at getAbsenceData", response);
      })
      .catch((err) => {
        Applogger("Error at getUserAbsence", err.message);
      });
  };
  return (
    <div>
      {calenderLoading == "pending" && <Spinner />}
      <Header modalPopup={modalState} active={t("dtCalendar")} />
      <br />
      <div className="container_custom">
        <h1 className="top-heading">{t("dtCalendar")}</h1>
      </div>
      <div className="container_custom">
        <ul className="nav nav-tabs mb-3" id="ex1" role="tablist">
          {/* {listAnnualLeavesCalendar && ( */}
          <li className="nav-item" role="presentation">
            <a
              className="nav-link active"
              id="ex1-tab-1"
              data-mdb-toggle="tab"
              href="#ex1-tabs-1"
              role="tab"
              aria-controls="ex1-tabs-1"
              aria-selected="true"
            >
              {t("dtCalendar")}
            </a>
          </li>
          {/* )} */}
          {/* {showPendingTab && ( */}
          <li className="nav-item" role="presentation">
            <a
              className="nav-link mb-2"
              id="ex1-tab-2"
              data-mdb-toggle="tab"
              href="#ex1-tabs-2"
              role="tab"
              aria-controls="ex1-tabs-2"
              aria-selected="false"
            >
              {t("pendingRequests")}
            </a>
          </li>
          {/* )} */}
          {ApproveDeclinePendingLeave && (
            <li className="nav-item" role="presentation">
              <a
                className="nav-link mb-2"
                id="ex1-tab-3"
                data-mdb-toggle="tab"
                href="#ex1-tabs-3"
                role="tab"
                aria-controls="ex1-tabs-3"
                aria-selected="false"
              >
                {t("othersPendingRequests")}
              </a>
            </li>
          )}
          {/* {listPublicLeaves && ( */}
          <li className="nav-item" role="presentation">
            <a
              className="nav-link"
              id="ex1-tab-4"
              data-mdb-toggle="tab"
              href="#ex1-tabs-4"
              role="tab"
              aria-controls="ex1-tabs-4"
              aria-selected="false"
            >
              {t("publicHolidays")}
            </a>
          </li>
          {/* )} */}
          {/* {showMandatoryTab && ( */}
          <li className="nav-item" role="presentation">
            <a
              className="nav-link"
              id="ex1-tab-5"
              data-mdb-toggle="tab"
              href="#ex1-tabs-5"
              role="tab"
              aria-controls="ex1-tabs-5"
              aria-selected="false"
            >
              {t("mandatoryLeave")}
            </a>
          </li>
          {/* )} */}
          {/* {listDeclined && ( */}
          <li className="nav-item" role="presentation">
            <a
              className="nav-link "
              id="ex1-tab-6"
              data-mdb-toggle="tab"
              href="#ex1-tabs-6"
              role="tab"
              aria-controls="ex1-tabs-6"
              aria-selected="false"
            >
              {t("declinedRequests")}
            </a>
          </li>
          {/* )} */}
          {listDeclined && (
            <li className="nav-item" role="presentation">
              <a
                className="nav-link "
                id="ex1-tab-7"
                data-mdb-toggle="tab"
                href="#ex1-tabs-7"
                role="tab"
                aria-controls="ex1-tabs-7"
                aria-selected="false"
              >
                {t("othersDeclinedRequests")}
              </a>
            </li>
          )}
        </ul>
        <div className="tab-content" id="ex1-content">
          {/* {listAnnualLeavesCalendar && ( */}
          <div
            className="tab-pane fade show active"
            id="ex1-tabs-1"
            role="tabpanel"
            aria-labelledby="ex1-tab-1"
          >
            <CalendarSection
              onModalPopupChange={(item) => handleModalChange(item)}
              logoutUser={logOutRequestHandler}
            />
          </div>
          {/* )} */}
          {/* {showPendingTab && ( */}
          <div
            className="tab-pane fade"
            id="ex1-tabs-2"
            role="tabpanel"
            aria-labelledby="ex1-tab-2"
          >
            <PendingRequest logoutUser={logOutRequestHandler} />
          </div>
          {/* )} */}
          {ApproveDeclinePendingLeave && (
            <div
              className="tab-pane fade"
              id="ex1-tabs-3"
              role="tabpanel"
              aria-labelledby="ex1-tab-3"
            >
              <OthersPendingRequest logoutUser={logOutRequestHandler} />
            </div>
          )}
          {/* {listPublicLeaves && ( */}
          <div
            className="tab-pane fade"
            id="ex1-tabs-4"
            role="tabpanel"
            aria-labelledby="ex1-tab-4"
          >
            <PublicHolidays logoutUser={logOutRequestHandler} />
          </div>
          {/* )} */}
          {/* {showMandatoryTab && ( */}
          <div
            className="tab-pane fade"
            id="ex1-tabs-5"
            role="tabpanel"
            aria-labelledby="ex1-tab-5"
          >
            <MandatoryLeave logoutUser={logOutRequestHandler} />
          </div>
          {/* )} */}
          {/* {listDeclined && ( */}
          <div
            className="tab-pane fade"
            id="ex1-tabs-6"
            role="tabpanel"
            aria-labelledby="ex1-tab-6"
          >
            <DeclinedAbsence />
          </div>
          {/*  )} */}
          {listDeclined && (
            <div
              className="tab-pane fade"
              id="ex1-tabs-7"
              role="tabpanel"
              aria-labelledby="ex1-tab-7"
            >
              <OthersDeclinedAbsence />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

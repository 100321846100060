import EventResource from "../../Config/EventResource";
import { APIConstants } from "../../Config/ApiConstants";
import { getHeaders } from "../../Helpers/commonFunc";

function apiLoginRequest(payload) {
  const form = new FormData();
  form.append("email", payload.email);
  form.append("password", payload.password);
  return EventResource.post(`/auth/`, form);
}

function forgotPasswordRequest(payload) {
  const requestFormData = new FormData();
  requestFormData.append("email", payload.email);
  requestFormData.append("url", payload.url);
  return EventResource.post(APIConstants.forgotPassword, requestFormData);
}

function resetPasswordRequest(payload) {
  const { newPassword, confirmNewPassword, token } = payload.body;
  const requestFormData = new FormData();
  requestFormData.append("password", newPassword);
  requestFormData.append("password_confirm", confirmNewPassword);
  requestFormData.append("token", token);
  return EventResource.post(APIConstants.resetPassword, requestFormData);
}
function apiGetUserWorkTimePattern(payload) {
  const { token, role_id } = payload;
  const path = APIConstants.userWorkTimePattern + role_id;
  return EventResource.get(path, getHeaders(token));
}

export const AuthApiServices = {
  apiLoginRequest,
  forgotPasswordRequest,
  resetPasswordRequest,
  apiGetUserWorkTimePattern,
};

import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Applogger } from "../../../Helpers/Logger";
import { unwrapResult } from "@reduxjs/toolkit";
import { get } from "lodash";
import { showFaliureToast, showSuccessToast } from "../../../Utilities";
import { resendInvitationRequest } from "../../../Redux/reducers/ProfileReducers";

export default function ResendRegistrationEmail() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { token } = useSelector((state) => state.AuthenticationReducer);
  const { user: profileUser } = useSelector((state) => state.profile);

  const handleResendEmail = () => {
    dispatch(
      resendInvitationRequest({
        token,
        userId: get(profileUser, "user_id", ""),
      })
    )
      .then(unwrapResult)
      .then((res) => {
        showSuccessToast(res ?? "Email Invitation Sent Successfully");
        Applogger("Response at resendInvitationRequest", res);
      })
      .catch((err) => {
        showFaliureToast(err);
        Applogger("Error at resendInvitationRequest", err);
      });
  };

  return (
    <div className="px-2 py-4 border-custom rounded">
      <div className="accordion" id="accordionPanelsStayOpenExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button align-items-start"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#roleInformation"
              aria-expanded="true"
              aria-controls="roleInformation"
            >
              <div className="pe-2">
                <h4 className="text-black my-2 fw-bold emp_head">
                  {t("resendRegistrationEmail")}
                </h4>
              </div>
            </button>
          </h2>
          <div
            id="roleInformation"
            className="accordion-collapse border-0 collapse show"
            aria-labelledby="headingOne"
          >
            <div className="accordion-body p-0">
              <div className="head-cs">
                <div className="myFlex-cs flex-wrap-sm px-2 pt-3">
                  <div className="col-12  d-flex justify-content-start">
                    <button
                      onClick={() => {
                        handleResendEmail();
                      }}
                      className="ms-2 btn btn-primary-cs"
                    >
                      <span>{t("resend")}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

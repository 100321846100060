import React from "react";
import { useTranslation } from "react-i18next";
import { dashboardCards } from "../../../pages/Homepage/Constants";
import WhosInCard from "../SmallCard/WhosInCard";
import ReportsCard from "../SmallCard/ReportsCard";
import CalendarCard from "../SmallCard/CalendarCard";
import PeoplesHubCard from "../SmallCard/PeoplesHubCard";
import DocumentsCard from "../SmallCard/DocumentsCard";
import SchedulesCard from "../SmallCard/SchedulesCard";

export default function DashboardCard({
  title,
  link,
  shortDescription,
  cardType,
  shortDescriptionImage,
}) {
  const { t } = useTranslation();

  const getCardData = () => {
    if (cardType == dashboardCards.whosIn) {
      return <WhosInCard />;
    } else if (cardType == dashboardCards.reports) {
      return <ReportsCard />;
    } else if (cardType == dashboardCards.calendar) {
      return <CalendarCard />;
    } else if (cardType == dashboardCards.peoplesHub) {
      return <PeoplesHubCard />;
    } else if (cardType == dashboardCards.documents) {
      return <DocumentsCard />;
    } else if (cardType == dashboardCards.schedules) {
      return <SchedulesCard shortDescriptionImage={shortDescriptionImage} />;
    } else {
      return null;
    }
  };

  return (
    <div
      data-aos-duration="1500"
      data-aos="fade-up"
      className="card card-sm border-cs-grey"
      style={{ minHeight: 360 }}
    >
      <div className="card-body position-relative">
        <h5 className="card-title fw-bold">{title}</h5>
        <p className="card-title ">{shortDescription}</p>
        <div className="d-flex flex-column">{getCardData()}</div>
        <a
          href={link}
          className="card-link position-absolute bottom-0 mb-2 text-primary"
        >
          {t("seeMore")}
        </a>
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import { Avatar } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Applogger } from "../../../Helpers/Logger";
import { getClockedInAndOutUsersList } from "../../../Redux/reducers/TimesheetReducer";
import { get } from "lodash";

export default function WhosInCard() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { lng } = useSelector((state) => state.languageReducer);
  const { token } = useSelector((state) => state.AuthenticationReducer);
  const { whoseInUsersList } = useSelector((state) => state.timesheet);

  useEffect(() => {
    getTimeSheetResponse();
  }, []);

  const getTimeSheetResponse = () => {
    dispatch(getClockedInAndOutUsersList({ token, lng }))
      .then(unwrapResult)
      .then((response) => {
        Applogger("response at getClockedInAndOutUsersList", response);
      })
      .catch((err) => {
        Applogger("Error at getClockedInAndOutUsersList", err.message);
      });
  };

  const usersList = () => {
    var clockedUser = [];

    if (whoseInUsersList && whoseInUsersList.length > 0) {
      for (let i = 0; i < whoseInUsersList.length; i++) {
        const element = whoseInUsersList[i];
        if (element.status == "IN") {
          clockedUser.push(element);
        }
      }
    }
    return clockedUser;
  };

  return (
    <div>
      <div className="mt-3">
        {usersList().length > 0
          ? usersList()
              .slice(0, 3)
              .map((val, index) => (
                <div
                  key={index}
                  className="d-flex align-items-center gap-2 mb-2"
                >
                  {get(val, "profile_image", null) == null ? (
                    <div className="profile-sm">{`${get(
                      val,
                      "first_name",
                      ""
                    ).slice(0, 1)}${get(val, "last_name", "").slice(
                      0,
                      1
                    )}`}</div>
                  ) : (
                    <Avatar size={40} src={get(val, "profile_image", "")} />
                  )}
                  <span className="capitalize">{`${get(
                    val,
                    "first_name",
                    ""
                  )} ${get(val, "last_name", "")} ${
                    get(val, "break_status", "") == "ON" ? "(On Break)" : ""
                  }`}</span>
                </div>
              ))
          : t("noClockedInUsersMsg")}
      </div>
    </div>
  );
}

import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTitleHandler } from "../../customHooks/useTitleHandler";
import Spinner from "../../components/Spinner";
import Header from "../../components/Header/Header";
import AllFolders from "../../components/Documents/AllFolders";
import UseLogoutHandler from "../../Helpers/useLogoutHandler";
import AppConstants from "../../Helpers/AppConstants";

export default function Documents() {
  const { loading } = useSelector((state) => state.document);
  const { t } = useTranslation();
  useTitleHandler(t("documents"));
  UseLogoutHandler();

  return (
    <div>
      {loading == "pending" && <Spinner />}
      <Header active={"Documents"} />
      <br />
      <div className="sp_main">
        <div className="myFlex-cs flex-wrap-sm">
          <h1 className="top-heading">{t("documents")}</h1>
        </div>
      </div>
      <div className="sp_main">
        <AllFolders document_type={AppConstants.documentTypes.company} />
      </div>
    </div>
  );
}

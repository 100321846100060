import React from "react";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import EmpDetailsField from "../Components/EmpDetailsField";
import EmplDropDown from "../Components/EmplDropDown";
import EmpAddress from "../Components/EmpAddress";
import useLocalisedConstants from "../../../../customHooks/useLocalisedConstants";

export default function BankDetails({
  employeeForm,
  setEmployeeForm,
  currentType,
}) {
  const { t } = useTranslation();

  const appConstants = useLocalisedConstants();

  const handlePlacePayload = (place, value) => {
    let address1 = "";
    let postcode = "";
    let countryName = "";
    let townCity = "";
    let politicalCountry = "";

    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name} ${address1}`;
          break;
        }
        case "route": {
          address1 += component.long_name;
          break;
        }
        case "administrative_area_level_1": {
          // if (countryName == "") {
          //   if (
          //     getAlphaNumericString(component.long_name) ==
          //     getAlphaNumericString(unitedKingdom)
          //   ) {
          //     countryName = england;
          //   }
          // }
          countryName = component.long_name;
          break;
        }
        case "country" || "political": {
          politicalCountry = component.long_name;
          break;
        }
        case "administrative_area_level_2": {
          townCity = component.short_name;
          break;
        }
        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          break;
        }
        case "postal_code_prefix": {
          postcode = postcode
            ? `${postcode}-${component.long_name}`
            : component.long_name;
          break;
        }
      }
    }
    setEmployeeForm({
      ...employeeForm,
      bank_address_1: value,
      bank_address_2: townCity,
      bank_address_3: address1,
      bank_address_4: countryName,
      bank_address_5: politicalCountry,
      bank_post_code: postcode,
    });
    // setAddress1(value);
    // setAddress2(address1);
    // setTown(townCity);
    // setPostCode(postcode);
  };

  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-between gap-2 col-md-12 col-sm-12">
        <div className="d-flex gap-2 col-lg-12 col-md-12 col-sm-12 flex-wrap justify-between">
          <EmpDetailsField
            title={t("bankAccountNumber")}
            placeholder={t("bankAccountNumber")}
            type={"number"}
            value={employeeForm.bank_account_number}
            isRequired={true}
            maxLength={8}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                bank_account_number: e.target.value,
              })
            }
          />
          <EmpDetailsField
            title={t("sortCode")}
            placeholder={t("sortCode")}
            value={employeeForm.sort_code}
            isRequired={true}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                sort_code: e.target.value,
              })
            }
          />
          <EmpDetailsField
            title={t("bankAccountName")}
            placeholder={t("bankAccountName")}
            type={"text"}
            value={employeeForm.bank_account_name}
            isRequired={true}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                bank_account_name: e.target.value,
              })
            }
          />
          <EmplDropDown
            title={t("bankAccountType")}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                bank_account_type: get(JSON.parse(e.target.value), "value", ""),
              })
            }
            value={employeeForm.bank_account_type}
            dataArray={appConstants.bankAccontTypes}
            displayKey="title"
            valueKey="value"
            isRequired={false}
          />
          <EmpDetailsField
            title={t("BASCReference")}
            placeholder={t("BASCReference")}
            value={employeeForm.bacs_reference}
            isRequired={false}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                bacs_reference: e.target.value,
              })
            }
          />
          <EmpDetailsField
            title={t("bankName")}
            placeholder={t("bankName")}
            value={employeeForm.bank_name}
            isRequired={true}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                bank_name: e.target.value,
              })
            }
          />
          <EmpAddress
            isRequired={false}
            title={t("bankAddress") + "1"}
            handleSelection={handlePlacePayload}
            setValue={(value) =>
              setEmployeeForm({
                ...employeeForm,
                bank_address_1: value,
              })
            }
            value={employeeForm.bank_address_1}
          />
          <EmpDetailsField
            title={t("bankAddress") + "2"}
            placeholder={t("bankAddress") + "2"}
            value={employeeForm.bank_address_2}
            isRequired={false}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                bank_address_2: e.target.value,
              })
            }
          />
          <EmpDetailsField
            title={t("bankAddress") + "3"}
            placeholder={t("bankAddress") + "3"}
            value={employeeForm.bank_address_3}
            isRequired={false}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                bank_address_3: e.target.value,
              })
            }
          />
          <EmpDetailsField
            title={t("bankAddress") + "4"}
            placeholder={t("bankAddress") + "4"}
            value={employeeForm.bank_address_4}
            isRequired={false}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                bank_address_4: e.target.value,
              })
            }
          />
          <EmpDetailsField
            title={t("bankAddress") + "5"}
            placeholder={t("bankAddress") + "5"}
            value={employeeForm.bank_address_5}
            isRequired={false}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                bank_address_5: e.target.value,
              })
            }
          />
          <EmpDetailsField
            title={t("bankPostCode")}
            placeholder={t("bankPostCode")}
            value={employeeForm.bank_post_code}
            isRequired={false}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                bank_post_code: e.target.value,
              })
            }
          />
          <EmpDetailsField
            title={t("buildingSocNumber")}
            placeholder={t("buildingSocNumber")}
            type={"text"}
            value={employeeForm.building_soc_number}
            isRequired={false}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                building_soc_number: e.target.value,
              })
            }
          />
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import useWorkingStatusList from "../../../customHooks/useWorkingStatusList";
import AppConstants from "../../../Helpers/AppConstants";

export default function UserStatusModal({
  status,
  changeStatus,
  setStatus,
  onCancel,
  modalStatus,
}) {
  const { t } = useTranslation();
  const workingStatusList = useWorkingStatusList();
  const [localTitle, setLocaltitle] = useState(status);

  useEffect(() => {
    setLocaltitle(status);
  }, [status]);

  return (
    <Modal
      title={t("selectYourCurrentStatus")}
      centered
      onCancel={onCancel}
      visible={modalStatus}
      width={500}
      footer={false}
    >
      <div className="modal-body modal-cs d-flex flex-column gap-2">
        {workingStatusList.map((workingStatus, index) => {
          const { title, slug, icon } = workingStatus;
          if (slug != AppConstants.workingStatus.any) {
            return (
              <div
                key={index}
                onClick={() => setLocaltitle(slug)}
                className={`setStatus d-flex align-items-center rounded hover-shadow ${
                  localTitle === slug && "active"
                }`}
              >
                <i className="ri-home-5-line fs-5" />
                <span className="p-2">{title}</span>
              </div>
            );
          }
        })}
        <button
          data-mdb-dismiss="modal"
          onClick={() => setStatus(localTitle)}
          className="btn btn-sm-dark btn-block fs-16"
        >
          {t("setStatus")}
        </button>
      </div>
    </Modal>
  );
}

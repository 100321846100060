import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { logoutRequest } from "../../Redux/reducers/AuthenticationReducer";
import { useNavigate } from "react-router-dom";
import { Applogger } from "../../Helpers/Logger";
import { Avatar } from "antd";
import { useTranslation } from "react-i18next";
import { changeLanguage as changeLanguageRedux } from "../../Redux/reducers/LanguageReducer";
import { AppImages } from "../../Helpers/AppImages";
import { get } from "lodash";
import { showInfoToast } from "../../Utilities";
import AppConstants from "../../Helpers/AppConstants";
import HeaderButton from "./HeaderButton";
import AppSelector from "../AppSelector/AppSelector";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";
import UseLogoutHandler from "../../Helpers/useLogoutHandler";
import $ from "jquery";
import "./Header.css";

export default function Header({ active, modalPopup }) {
  UseLogoutHandler();

  const { exist: createEmployee } = usePrevilagesExist(
    AppConstants.previlages.createEmployee
  );

  const [currentHeight, setCurrentHeight] = useState(150);
  const { user, userPrevilages, role } = useSelector(
    (state) => state.AuthenticationReducer
  );
  const { companySettingsOB, userPrevilages: moduleUserPrevilages } =
    useSelector((state) => state.settings);

  // Previlages
  const { exist: createAnnualLeave } = usePrevilagesExist(
    AppConstants.previlages.createAnnualLeave
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const elementRef = useRef(null);
  const { t, i18n } = useTranslation();

  const { lng: selectedLanguage } = useSelector(
    (state) => state.languageReducer
  );

  const profileImage = get(user, "profile_image", null);
  const firstName = get(user, "first_name", "");
  const userId = get(user, "user_id", "");
  const lastName = get(user, "last_name", "");
  const clientHeight = get(elementRef, "current.clientHeight", "");

  useEffect(() => {
    if (!isOnBoarded()) {
      if (get(user, "is_admin", null) == 0) {
        showInfoToast(
          "Please contact your administrator, your company profile is incomplete"
        );
        navigate(AppConstants.routes.login);
        logOutRequest();
      } else {
        showInfoToast("Please complete company onboarding");
        navigate(AppConstants.routes.onBoarding);
      }
    }
  }, []);

  useLayoutEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const lang = searchParams.get("lang");
    if (lang) {
      i18n.changeLanguage(lang);
      dispatch(changeLanguageRedux(lang));
    }
  }, []);

  const logOutRequest = () => {
    try {
      dispatch(logoutRequest())
        .then((res) => {
          navigate(AppConstants.routes.login);
        })
        .catch((err) => {
          Applogger("Error at logoutRequest", err.message);
        });
    } catch (err) {
      Applogger("Error at logOutRequest", err);
    }
  };

  useEffect(() => {
    if (clientHeight) {
      setCurrentHeight(clientHeight);

      $(window).resize(function () {
        setCurrentHeight(clientHeight);
      });
    }
  }, [clientHeight]);

  const isOnBoarded = () => {
    return get(companySettingsOB, "is_onboarded", 0) == 1;
  };

  const handleFullProfile = (event) => {
    event.preventDefault();
    navigate(AppConstants.routes.profile + `?id=${userId}`);
    // navigate(AppConstants.routes.profile, { state: { data: userId } });
  };

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    dispatch(changeLanguageRedux(newLanguage));
    const params = new URLSearchParams();
    params.append("lang", newLanguage);
    navigate({ search: params.toString() });
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const isModuleAllowed = (module) => {
    return moduleUserPrevilages.some((moduleUserPrevilage) => {
      return (
        moduleUserPrevilage.key === module &&
        moduleUserPrevilage.children.some((child) => {
          return userPrevilages.includes(child.key);
        })
      );
    });
  };

  const headerItems = [
    {
      // isModuleAllowed: isModuleAllowed(
      //   AppConstants.previlagesModules.dashboard
      // ),
      isModuleAllowed: true,
      component: (
        <HeaderButton
          route={AppConstants.routes.dashboard}
          text={t("dashboard")}
          icon={"osuxyevn.json"}
          className={`${active === "dashboard" ? "active" : ""}`}
        />
      ),
    },
    {
      // isModuleAllowed: isModuleAllowed(AppConstants.previlagesModules.calendar),
      isModuleAllowed: true,
      component: (
        <HeaderButton
          route={AppConstants.routes.calendar}
          text={t("calendar")}
          icon={"qjuahhae.json"}
          className={`${active === "Calendar" ? "active" : ""}`}
        />
      ),
    },
    {
      isModuleAllowed: isModuleAllowed(
        AppConstants.previlagesModules.peopleHub
      ),
      component: (
        <HeaderButton
          route={`${AppConstants.routes.employees}?offset=0`}
          text={t("myEmployees")}
          icon={"kipaqhoz.json"}
          className={`${active === "employees" ? "active" : ""}`}
        />
      ),
    },
    {
      isModuleAllowed: isModuleAllowed(
        AppConstants.previlagesModules.timesheet
      ),
      component: (
        <HeaderButton
          className={`dropdown-nav nav-item dropdown ${active === "TimeSheet" ? "active" : ""
            }`}
          route={AppConstants.routes.timeSheet}
          text={t("timesheet")}
          icon={"reltuksl.json"}
        />
      ),
    },
    {
      isModuleAllowed: isModuleAllowed(
        AppConstants.previlagesModules.schedules
      ),
      component: (
        <HeaderButton
          route={AppConstants.routes.staffing}
          text={t("staffing")}
          icon={"isugonwi.json"}
          className={`${active === "Staffing" ? "active" : ""}`}
        />
      ),
    },
    {
      isModuleAllowed: isModuleAllowed(
        AppConstants.previlagesModules.documents
      ),
      component: (
        <HeaderButton
          route={AppConstants.routes.documents}
          text={t("companyDocs")}
          icon={"fpmskzsv.json"}
          className={`dropdown-nav nav-item dropdown ${active === "Documents" ? "active" : ""
            }`}
        />
      ),
    },
    {
      isModuleAllowed: isModuleAllowed(
        AppConstants.previlagesModules.sharedDocuments
      ),
      component: (
        <HeaderButton
          route={AppConstants.routes.sharedDocuments}
          text={t("sharedDocuments")}
          icon={"iiixgoqp.json"}
          className={`dropdown-nav nav-item dropdown ${active === "Shared Documents" ? "active" : ""
            }`}
        />
      ),
    },
    {
      isModuleAllowed: isModuleAllowed(
        AppConstants.previlagesModules.analytics
      ),
      component: (
        <HeaderButton
          route={AppConstants.routes.analytics}
          text={t("analytics")}
          icon={"icxqolmx.json"}
          className={`${active === "Reports" ? "active" : ""}`}
        />
      ),
    },
    {
      isModuleAllowed: isModuleAllowed(AppConstants.previlagesModules.settings),
      component: (
        <HeaderButton
          route={AppConstants.routes.settings}
          text={t("settings")}
          icon={"hwuyodym.json"}
          className={`${active === "Settings" ? "active" : ""}`}
        />
      ),
    },
  ];

  return (
    <div>
      <header
        ref={elementRef}
        className={`${modalPopup == true ? "main-head-index" : "main-head"}`}
      >
        <div className="container_custom pb-0">
          <div className="row upper-nav d-flex">
            {isOnBoarded() ? (
              <a
                href={AppConstants.routes.dashboard}
                className={`dropdown ${selectedLanguage == "ar" ? "nav-brand-ar" : "nav-brand"
                  }`}
              >
                <img src={AppImages.speedyHrLogo} width="184" alt="SpeedyHR" />
              </a>
            ) : (
              <div
                className={`dropdown ${selectedLanguage == "ar" ? "nav-brand-ar" : "nav-brand"
                  }`}
              >
                <img src={AppImages.speedyHrLogo} width="184" alt="SpeedyHR" />
              </div>
            )}
            <div className="right-upper-nav d-flex align-items-center p-0">
              <div
                className=" mb-0 p-0 w-25-px d-flex justify-content-end"
                style={{ minWidth: "184px!important" }}
              >
                <AppSelector
                  className="langBtn  btn-action"
                  style={{ background: "none" }}
                  onChange={handleLanguageChange}
                  value={selectedLanguage}
                />
                {/* {isOnBoarded() &&
                  (isUserLineManager(user) || role == "Owner") && (
                    <div
                      className={`dropdown ${
                        selectedLanguage == "ar" ? "mr-2" : "ml-2"
                      }`}
                    >
                      <button
                        className="btn btn-action d-flex"
                        type="button"
                        id="dropdownMenuButton"
                        data-mdb-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="ri-add-line" />
                        <span className="ms-2 act-btn">
                          {t("quickActions")}
                        </span>
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <li className="px-2 py-4" style={{ width: "35rem" }}>
                          <div className="row">
                            {createAnnualLeave && (
                              <div className="d-flex flex-column gap-2 col-md-6 border-right">
                                <h6 className="fw-bold">{t("addAbsence")}</h6>
                                <button
                                  onClick={() =>
                                    navigate(
                                      `${AppConstants.routes.absence}?type=${AppConstants.absenceTypes.annual_leave}`
                                    )
                                  }
                                  className="flex_vert_align btn quick_act_btn shadow-none"
                                >
                                  <i className="ri-first-aid-kit-line fs-5" />
                                  {t("addAnnualLeave")}
                                </button>
                                {(isUserLineManager(user) ||
                                  role == "Owner") && (
                                  <button
                                    onClick={() =>
                                      navigate(
                                        `${AppConstants.routes.absence}?type=${AppConstants.absenceTypes.sickness_absence}`
                                      )
                                    }
                                    className="flex_vert_align btn quick_act_btn shadow-none"
                                  >
                                    <i className="ri-sun-line fs-5" />
                                    {t("addSicknessAbsence")}
                                  </button>
                                )}

                                {(isUserLineManager(user) ||
                                  role == "Owner") && (
                                  <button
                                    onClick={() =>
                                      navigate(
                                        `${AppConstants.routes.absence}?type=${AppConstants.absenceTypes.toil}`
                                      )
                                    }
                                    className="flex_vert_align btn quick_act_btn shadow-none"
                                  >
                                    <i className="ri-calendar-2-line fs-5" />
                                    {t("addTimeoff")}
                                  </button>
                                )}

                                <button
                                  onClick={() =>
                                    navigate(
                                      `${AppConstants.routes.absence}?type=${AppConstants.absenceTypes.lateness}`
                                    )
                                  }
                                  className="flex_vert_align btn quick_act_btn shadow-none"
                                >
                                  <i className="ri-timer-2-line fs-5" />
                                  {t("addLateness")}
                                </button>
                              </div>
                            )}
                          {createEmployee && (
                            <div className="d-flex flex-column gap-2 col-md-6 ">
                              <React.Fragment>
                                <h6 className="fw-bold">{t("staffTeams")}</h6>
                                <React.Fragment>
                                  <button
                                    onClick={() =>
                                      navigate(
                                        `${AppConstants.routes.employees}?add=add_people`
                                      )
                                    }
                                    className="flex_vert_align btn quick_act_btn shadow-none"
                                  >
                                    <i className="ri-user-line fs-5" />
                                    {t("addEmployees")}
                                  </button>
                                  <button
                                    onClick={() =>
                                      navigate(
                                        `${AppConstants.routes.employees}?add=add_people_in_bulk`
                                      )
                                    }
                                    className="flex_vert_align btn quick_act_btn shadow-none"
                                  >
                                    <i className="ri-user-line fs-5" />
                                    {t("bulkAddEmployees")}
                                  </button>
                                </React.Fragment>
                              </React.Fragment>
                            </div>
                          )}
                          </div>
                        </li>
                      </ul>
                    </div>
                  )} */}
              </div>
            </div>
          </div>
          <div className="row lower-nav d-flex bg-white">
            <div className="lower-left d-flex">
              {isOnBoarded() && (
                <ul className="main-ul">
                  {headerItems.map((item, index) => {
                    const { isModuleAllowed, component } = item;
                    if (isModuleAllowed) {
                      return <div key={index}>{component}</div>;
                    }
                  })}
                  <li className="d-none menu-sm-cs">
                    <button className="main-nav" onClick={showModal}>
                      <i className="ri-more-2-fill me-2" />
                      {t("menu")}
                    </button>
                  </li>
                </ul>
              )}
            </div>
            <div className="lower-right">
              <div className="drp-cs" style={{ width: "100%" }}>
                <button
                  className="d-flex justify-content-center w-100 h-100 align-items-center"
                  id="dropdownMenuButton"
                  data-mdb-toggle="dropdown"
                  aria-expanded="false"
                >
                  <strong className="d-flex uppercase text-primary-cs">{`${user && user.first_name ? user.first_name.slice(0, 1) : ""
                    }${user && user.last_name ? user.last_name.slice(0, 1) : ""
                    }`}</strong>
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                  style={{ width: "20rem" }}
                >
                  <li>
                    <div className="d-flex align-items-center dropdown-item gap-4">
                      {profileImage == null ? (
                        <Avatar size={50} style={{ background: "#00A6F1" }}>
                          <b
                            style={{ textTransform: "uppercase" }}
                          >{`${firstName.slice(0, 1)}${lastName.slice(
                            0,
                            1
                          )}`}</b>
                        </Avatar>
                      ) : (
                        <Avatar size={50} src={profileImage} />
                      )}
                      <div className="mb-0 text-capitalize">
                        {`${firstName.slice(0, 10)} ${lastName.slice(0, 10)}`}
                      </div>
                    </div>
                  </li>
                  {isOnBoarded() && (
                    <li>
                      <button
                        onClick={(e) => handleFullProfile(e)}
                        className="dropdown-item text-primary mt-2"
                        style={{ alignItems: "center", display: "flex" }}
                      >
                        <i className="ri-external-link-line fs-5 me-4" />
                        {t("myAccount")}
                      </button>
                    </li>
                  )}
                  <li>
                    <button
                      className="dropdown-item text-primary"
                      onClick={() => logOutRequest()}
                      style={{ alignItems: "center", display: "flex" }}
                    >
                      <i className="ri-logout-box-r-line fs-5 me-4" />
                      {t("logout")}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div style={{ height: currentHeight }} className={"mb-2"}></div>

      <Modal
        title={t("menu")}
        visible={isModalVisible}
        footer={false}
        onCancel={handleCancel}
      >
        {isOnBoarded() && (
          <ul className="main-ul-mobile">
            {headerItems.map((item, index) => {
              const { isModuleAllowed, component } = item;
              if (isModuleAllowed) {
                return <div key={index}>{component}</div>;
              }
            })}
          </ul>
        )}
      </Modal>
    </div>
  );
}

import React from 'react'
import "./styles.css"

export default function InstructionPara({ title = "" }) {
    return (
        <div className={` d-flex flex-wrap `} >
            <p className="mb-0 instruct-para"><strong>Note:</strong> {title}</p>
        </div>
    )
}

import React from "react";
import { useTranslation } from "react-i18next";

export default function AbsenceWarnModal({ removeAbsence, heading }) {
  const { t } = useTranslation();
  return (
    <div
      className="modal fade"
      id="abenceWarn"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content w-100">
          <div className="modal-header bg-sec-cs text-white">
            <h5 className="modal-title" id="exampleModalLabel">
              {heading && heading}
            </h5>
            <button
              type="button"
              className="btn-close text-light"
              data-mdb-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body modal-cs">
            <p>
              {t("aboutToRemove")} {heading} {t("confirmation")}
            </p>
          </div>
          <div className="d-flex">
            <button
              type="button"
              data-mdb-dismiss="modal"
              aria-label="Close"
              className="btn btn-primary-cs mb-4 mx-3"
              onClick={() => removeAbsence()}
            >
              {t("yesRemove")}
            </button>
            <button
              type="button"
              data-mdb-dismiss="modal"
              aria-label="Close"
              className="btn btn-primary-outline-cs mb-4"
            >
              {t("cancel")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

import AppConstants from "../../Helpers/AppConstants";

export const dashboardCards = {
  whosIn: "WHOS_IN",
  reports: "REPORTS",
  calendar: "CALENDAR",
  peoplesHub: "EMPLOYEE_HUB",
  documents: "DOCUMENTS",
  schedules: "ROTA_PLANNING",
};

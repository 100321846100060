import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { showFaliureToast } from "../../../Utilities";
import AppConstants from "../../../Helpers/AppConstants";
import usePrevilagesExist from "../../../Helpers/PrivilegesFuncs";
import SPProfileField from "../Components/SPProfileField";
import SPProfileButton from "../Components/SPProfileButton";

export default function ContactInfoForm({ updateInfo, Data }) {
  const { role } = useSelector((state) => state.AuthenticationReducer);

  const { exist: updateContactInfo } = usePrevilagesExist(
    AppConstants.previlages.updateContactInfo
  );

  const { t } = useTranslation();

  const [accountEmail, setAccountEmail] = useState("");
  const [personalEmail, setPersonalEmail] = useState("");
  const [homePhone, setHomePhone] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [workPhone, setWorkPhone] = useState("");
  const [workExtension, setWorkExtension] = useState("");

  const reEmail = /\S+@\S+\.\S+/;
  const reNumb = /^[0-9 +\b]*$/;
  // /^([a-zA-Z0-9 _-]+)$/;

  useEffect(() => {
    getUserContactInfo();
  }, [Data]);

  const updateConfactInfo = () => {
    if (reEmail.test(accountEmail)) {
      const contactData = {
        accountEmail,
        personalEmail,
        homePhone,
        mobilePhone,
        workPhone,
        workExtension,
      };
      updateInfo(contactData);
    } else {
      showFaliureToast("Please Provide Correct Email");
    }
  };

  const getUserContactInfo = () => {
    if (Data) {
      const {
        email,
        personal_email,
        home_phone,
        mobile_phone,
        work_phone,
        work_extension,
      } = Data;

      setAccountEmail(email);
      setPersonalEmail(personal_email);
      setHomePhone(home_phone);
      setMobilePhone(mobile_phone);
      setWorkPhone(work_phone);
      setWorkExtension(work_extension);
    }
  };

  const val_homePhone = (e) => {
    if (reNumb.test(e.target.value)) {
      setHomePhone(e.target.value);
    }
  };

  const val_mobilePhone = (e) => {
    if (reNumb.test(e.target.value)) {
      setMobilePhone(e.target.value);
    }
  };
  const val_workPhone = (e) => {
    if (reNumb.test(e.target.value)) {
      setWorkPhone(e.target.value);
    }
  };

  const val_workExt = (e) => {
    if (reNumb.test(e.target.value)) {
      setWorkExtension(e.target.value);
    }
  };

  const handleDisable = () => {
    if (role == AppConstants.roleTypes.Owner) {
      return false;
    } else {
      if (updateContactInfo) {
        return false;
      } else {
        return true;
      }
    }
  };

  const isEmptyData = () => {
    return !accountEmail || !mobilePhone;
  };

  return (
    <div className="accordion-body p-0">
      <SPProfileField
        title={t("accountEmail")}
        disabled={handleDisable()}
        value={accountEmail}
        onChange={(e) => setAccountEmail(e.target.value)}
        type="email"
        placeholder={t("email")}
        isRequired={true}
      />
      <SPProfileField
        title={t("personalEmail")}
        disabled={handleDisable()}
        type="email"
        value={personalEmail}
        onChange={(e) => setPersonalEmail(e.target.value)}
        placeholder={t("personalEmail")}
      />
      <SPProfileField
        title={t("homePhone")}
        value={homePhone}
        onChange={(e) => val_homePhone(e)}
        disabled={handleDisable()}
        maxLength={14}
        type="tel"
        placeholder={t("homePhone")}
      />
      <SPProfileField
        title={t("mobilePhone")}
        value={mobilePhone}
        onChange={(e) => val_mobilePhone(e)}
        disabled={handleDisable()}
        maxLength={14}
        type="tel"
        placeholder={t("mobilePhone")}
        isRequired={true}
      />
      <SPProfileField
        title={t("workPhone")}
        value={workPhone}
        onChange={(e) => val_workPhone(e)}
        disabled={handleDisable()}
        maxLength={14}
        type="tel"
        placeholder={t("workPhone")}
      />
      <SPProfileField
        title={t("workExtension")}
        value={workExtension}
        onChange={(e) => val_workExt(e)}
        maxLength={6}
        disabled={handleDisable()}
        type="tel"
        placeholder={t("workExtension")}
      />
      <label className="ml-2 mt-2">
        {t("fieldsMarkedAs")}
        <span className="text-danger">{" * "}</span>
        {t("areMandatory")}
      </label>
      {!handleDisable() && (
        <SPProfileButton
          title={t("save")}
          onClick={() => updateConfactInfo()}
          disabled={handleDisable() || isEmptyData()}
        />
      )}
    </div>
  );
}

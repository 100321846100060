import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next"
import { get } from "lodash";
export default function PayableCard({
  heading,
  firstHeading,
  secondHeading,
  thirdHeading,
  cardData,
  mode,
  overtimeCount,
  overtimeOff,
}) {
  const {
    totalToilLoggedHour,
    totalToilLoggedMins,
    totalPayableLoggedHour,
    totalPayableLoggedMins,
    toilTakenHour,
    toilTakenMin,
  } = cardData;
  const { overTimeOff } = useSelector((state) => state.profile);
  const [availableToilHour, setAvailableToilHour] = useState(0);
  const [availableToilMin, setAvailableToilMin] = useState(0);
  const { t } = useTranslation();
  useEffect(() => {
    availableToil();
  }, [overTimeOff, cardData]);

  const overTimeHours = get(overTimeOff, "hours", 0)
  const overTimeMins = get(overTimeOff, "mins", 0)


  const availableToil = () => {
    let extraMins = 0;
    const oneMin = 60;
    if (totalToilLoggedMins < overTimeMins) {
      setAvailableToilHour(totalToilLoggedHour - overTimeHours - 1);
      extraMins = overTimeOff.mins - totalToilLoggedMins;
      setAvailableToilMin(oneMin - extraMins);
    } else {
      setAvailableToilHour(totalToilLoggedHour - overTimeHours);
      setAvailableToilMin(totalToilLoggedMins - overTimeMins);
    }
  };

  return (
    <div className="col-lg-12 col-sm-12">
      <div className="card">
        <div className="card-header h5 bg-light-cs fw-bold">{heading}</div>
        <div className="card-body">
          <div className="d-flex justify-content-evenly flex-wrap text-center center-sm">
            <div className="toil my-4 ">
              <h6 className="fw-bold">{firstHeading}</h6>
              {mode == "TOIL" ? (
                <h4 className="number_lg fw-bold">{`${
                  totalToilLoggedHour && totalToilLoggedHour
                }h ${totalToilLoggedMins && totalToilLoggedMins}m`}</h4>
              ) : (
                <h4 className="number_lg fw-bold">{`${
                  totalPayableLoggedHour && totalPayableLoggedHour
                }h ${totalPayableLoggedMins && totalPayableLoggedMins}m`}</h4>
              )}
              <p className="fw-light text-black-50 small">{`${
                overtimeCount == 0
                  ? t("noApprovedcClaims")
                  : overtimeCount + " approved claims"
              } `}</p>
            </div>
            <div className="toil my-4">
              <h6 className="fw-bold">{secondHeading}</h6>
              {mode == "TOIL" ? (
                <h4 className="number_lg fw-bold">{`${
                  overTimeOff && overTimeHours
                }h ${overTimeOff && overTimeMins}m`}</h4>
              ) : (
                <h4 className="number_lg fw-bold">{`${0}h ${0}m`}</h4>
              )}
              {overtimeOff != null ? (
                <p className="fw-light text-black-50 small">
                  {`${overtimeOff !== 0 ? overtimeOff : "No"}`}{" "}
                  {t("toilAbsences")}
                </p>
              ) : (
                <p className="fw-light text-black-50 small">
                  {t("noToilAbsences")}
                </p>
              )}
            </div>
            <div className="toil my-4">
              <h6 className="fw-bold">{thirdHeading}</h6>
              {mode == "TOIL" ? (
                <h4 className="number_lg fw-bold">{`${availableToilHour}h ${availableToilMin}m`}</h4>
              ) : (
                <h4 className="number_lg fw-bold">{`${
                  totalPayableLoggedHour - toilTakenHour
                }h ${totalPayableLoggedMins - toilTakenMin}m`}</h4>
              )}
              <p className="fw-light text-black-50 small">
                {t("availableToTake")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
